import axios from 'axios';
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import sourceUrl from "../../api/Source.api";

const WaitListedUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem('token');

  const fetchAllUsers = async () => {
    try {
      const res = await axios.get(`${sourceUrl}/admin/waitlist`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (res.data) {
        setUsers(res.data.data.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllUsers();
  }, []);

  return (
    <div className="card" style={{ height: '100%' }}>
      <div className="card-body">
        <div className="d-flex justify-content-between mb-3">
          <h5 className="card-title">{users?.length > 0 ? 'Total Waitlisted Requests:' :'No requests found'}</h5>
          <h5 className="card-title text-primary">{users?.length}</h5>
        </div>
        <div className="overflow-auto" style={{ maxHeight: '400px' }}>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          ) : (
            <ul className="list-group">
              {users?.map((ele, i) => (
                <li key={i} className="list-group-item">
                  {ele}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default WaitListedUsers;
import React from 'react'
import CallingAgent from './CallingAgent'

const NewLandingPage = () => {
  return (
    <div>
        <CallingAgent place={"india"}/>
    </div>
  )
}

export default NewLandingPage
import React, {useEffect, useRef, useState} from "react";
import LoaderImage from '../../assets/LoadingImages/ModalX_logo.png'
import LoaderImage1 from '../../assets/LoadingImages/ModalX_logo1.png'
import LoaderImage2 from '../../assets/LoadingImages//ModalX_logo2.png'
import LoaderImage3 from '../../assets/LoadingImages//ModalX_logo3.png'
import LoaderImage4 from '../../assets/LoadingImages//ModalX_logo4.png'
import LoaderImage5 from '../../assets/LoadingImages//ModalX_logo5.png'
import LoaderImage6 from '../../assets/LoadingImages//ModalX_logo6.png'
import LoaderImage7 from '../../assets/LoadingImages//ModalX_logo7.png'
import LoaderImage8 from '../../assets/LoadingImages//ModalX_logo8.png'
import LoaderImage9 from '../../assets/LoadingImages//ModalX_logo9.png'
import LoaderImage10 from '../../assets/LoadingImages//ModalX_logo10.png'
import styled, { keyframes } from "styled-components";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from "react-responsive";

export function CommanLoaderImage({value}){

    const initialSentences = ["Getting started", 'Gathering assets', 'Preparing content for processing', 'Painting pixels with precision...', 'Bringing your vision to life...', 'Perfecting colors, shapes, and textures...', 'Putting the final touches on your images—almost there!']
    const repeatedSentences  = ['Painting pixels with precision...', 'Bringing your vision to life...', 'Perfecting colors, shapes, and textures...', 'Putting the final touches on your images—almost there!']
    
    const loadingImages = [
      {imageUrl : LoaderImage, percentage:0},
      {imageUrl : LoaderImage1, percentage:10},
      {imageUrl : LoaderImage2, percentage:20},
      {imageUrl : LoaderImage3, percentage:30},
      {imageUrl : LoaderImage4, percentage:40},
      {imageUrl : LoaderImage5, percentage:50},
      {imageUrl : LoaderImage6, percentage:60},
      {imageUrl : LoaderImage7, percentage:70},
      {imageUrl : LoaderImage8, percentage:80},
      {imageUrl : LoaderImage9, percentage:90},
      {imageUrl : LoaderImage10, percentage:100},
     ]
  
  
    const [loaderCurrentText, setLoaderCurrentText] = useState(initialSentences[0])
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentImage, setCurrentImage] = useState(loadingImages[0]);
  
    useEffect(() => {
      const sentences = [...initialSentences, ...repeatedSentences]; // Combine initial and repeated sentences
      const startOfRepeats = initialSentences.length; // Starting index of repeated sentences
  
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => {
          const nextIndex = prevIndex + 1;
          if (nextIndex < sentences.length) {
            return nextIndex;
          } else {
            return startOfRepeats; // Loop back to repeated sentences
          }
        });
      }, 3000); // Change text every 2 seconds
  
      return () => clearInterval(interval); // Cleanup on unmount
    }, [initialSentences, repeatedSentences]);
  
    useEffect(() => {
      const sentences = [...initialSentences, ...repeatedSentences];
      setLoaderCurrentText(sentences[currentIndex]);
    }, [currentIndex, initialSentences, repeatedSentences]);
  
    useEffect(()=>{
      if(value <= 0){
        setCurrentImage(loadingImages[0])
      }else if(value > 0 && value <= 10){
        setCurrentImage(loadingImages[1])
      }else if(value > 10 && value <= 20){
        setCurrentImage(loadingImages[2])
      }else if(value > 20 && value <= 30){
        setCurrentImage(loadingImages[3])
      }else if(value > 30 && value <= 40){
        setCurrentImage(loadingImages[4])
      }else if(value > 40 && value <= 50){
        setCurrentImage(loadingImages[5])
      }else if(value > 50 && value <= 60){
        setCurrentImage(loadingImages[6])
      }else if(value > 60 && value <= 70){
        setCurrentImage(loadingImages[7])
      }else if(value > 70 && value <= 80){
        setCurrentImage(loadingImages[8])
      }else if(value > 80 && value <= 100){
        setCurrentImage(loadingImages[9])
      }
    },[value])
  
  
    const blink = keyframes`
    0% {
      background-color: #282D51;
    }
    50% {
      background-color: #1F233E;
    }
    100% {
      background-color: #282D51;
    }`;

  const Dot = styled.div`
  width: 350px;
  height: 350px;
  border-radius: 8px;
  background-color: #282d51;
  margin: 0px 5px;
  animation: ${blink} 3s infinite alternate; /* Apply the blinking animation */
  position: relative; /* For positioning child elements */
  flex-shrink: 0; /* Prevent shrinking */
`;

// InnerContent component to prevent blinking
const InnerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  align-items: center;
  justify-content: center;
  padding: 20px;
  position: absolute; /* Positioning to avoid being affected by animation */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  marginTop:"85px";
  `;

  
  
    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width='350px' height="350px" style={{width: '350px',height: '350px', flexShrink: 0, }}>
          {/* Dot component with an image inside */}
          <Dot >
          <InnerContent>
            <img
              src={currentImage?.imageUrl}
              alt="Loading"
              style={{ 
                width: '100px', 
                height: '100px',
                marginBottom: "10px", // Space below the image
                flexShrink: 0, // Prevent the image from shrinking
               }}
            />
          <Typography style={{display:"flex",textAlign:"center", fontFamily:"Inter", fontSize:"16px", fontStyle:"normal", fontWeight:400, color:"rgba(255, 255, 255, 0.80)", minHeight: "50px",alignItems: "center", }} >{loaderCurrentText}</Typography>
          </InnerContent>
          </Dot>
        </Box>
  
    )
  }
  
export  function CommanLoaderPresentation({value}){
  const isUnder1400px = useMediaQuery({ minWidth:1400});
  
    const initialSentences = ["Getting started", 'Gathering assets', 'Preparing content for processing', 'Preparing your input for the perfect deck...', 'Building your presentation, one slide at a time...', 'Adjusting layour, text and visuals...', 'Fine-tuning fonts, colors, and images...-almost there!']
    const repeatedSentences  = ['Preparing your input for the perfect deck...', 'Building your presentation, one slide at a time...', 'Adjusting layour, text and visuals...', 'Fine-tuning fonts, colors, and images...-almost there!']
    
    const loadingImages = [
      {imageUrl : LoaderImage, percentage:0},
      {imageUrl : LoaderImage1, percentage:10},
      {imageUrl : LoaderImage2, percentage:20},
      {imageUrl : LoaderImage3, percentage:30},
      {imageUrl : LoaderImage4, percentage:40},
      {imageUrl : LoaderImage5, percentage:50},
      {imageUrl : LoaderImage6, percentage:60},
      {imageUrl : LoaderImage7, percentage:70},
      {imageUrl : LoaderImage8, percentage:80},
      {imageUrl : LoaderImage9, percentage:90},
      {imageUrl : LoaderImage10, percentage:100},
     ]
  
  
    const [loaderCurrentText, setLoaderCurrentText] = useState(initialSentences[0])
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentImage, setCurrentImage] = useState(loadingImages[0]);
  
    useEffect(() => {
      const sentences = [...initialSentences, ...repeatedSentences]; // Combine initial and repeated sentences
      const startOfRepeats = initialSentences.length; // Starting index of repeated sentences
  
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => {
          const nextIndex = prevIndex + 1;
          if (nextIndex < sentences.length) {
            return nextIndex;
          } else {
            return startOfRepeats; // Loop back to repeated sentences
          }
        });
      }, 3000); // Change text every 2 seconds
  
      return () => clearInterval(interval); // Cleanup on unmount
    }, [initialSentences, repeatedSentences]);
  
    useEffect(() => {
      const sentences = [...initialSentences, ...repeatedSentences];
      setLoaderCurrentText(sentences[currentIndex]);
    }, [currentIndex, initialSentences, repeatedSentences]);
  
    useEffect(()=>{
      if(value <= 0){
        setCurrentImage(loadingImages[0])
      }else if(value > 0 && value <= 10){
        setCurrentImage(loadingImages[1])
      }else if(value > 10 && value <= 20){
        setCurrentImage(loadingImages[2])
      }else if(value > 20 && value <= 30){
        setCurrentImage(loadingImages[3])
      }else if(value > 30 && value <= 40){
        setCurrentImage(loadingImages[4])
      }else if(value > 40 && value <= 50){
        setCurrentImage(loadingImages[5])
      }else if(value > 50 && value <= 60){
        setCurrentImage(loadingImages[6])
      }else if(value > 60 && value <= 70){
        setCurrentImage(loadingImages[7])
      }else if(value > 70 && value <= 80){
        setCurrentImage(loadingImages[8])
      }else if(value > 80 && value <= 100){
        setCurrentImage(loadingImages[9])
      }
    },[value])
  
  
    const blink = keyframes`
    0% {
      background-color:#282D51
    }
    100% {
      background-color:#1F233E;
    }
  `;
  
  const Dot = styled.div`
    width:${isUnder1400px ? "720px" : "100%"} ;
    height: 240px;
    border-radius: 8px;
    background-color: #282d51;
    margin: 0 5px;
    animation: ${blink} 3s ease-in-out; /* Apply the blinking animation */
    position: relative; /* For positioning child elements */
  `;
  
  // InnerContent component to prevent blinking
  const InnerContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 36px;
    align-items: center;
    justify-content: center;
    padding: 20px;
    position: absolute; /* Positioning to avoid being affected by animation */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    `;
  
  
  
    return (
        <Box display="flex" flexDirection="column" alignItems="start" justifyContent="center" marginBottom={"20px"}>
          {/* Dot component with an image inside */}
          <Dot >
          <InnerContent>
            <img
              src={currentImage?.imageUrl}
              alt="Loading"
              style={{ width: '100px', height: '100px' }}
            />
          <Typography style={{display:"flex",textAlign:"center", fontFamily:"Inter", fontSize:"16px", fontStyle:"normal", fontWeight:400, color:"rgba(255, 255, 255, 0.80)"}}>{loaderCurrentText}</Typography>
          </InnerContent>
          </Dot>
        </Box>
  
    )
  }
  
export  function CommanLoaderAudio({value}){
  const isUnder1400px = useMediaQuery({ minWidth:1400});
  
    const initialSentences = ["Getting started", 'Gathering assets', 'Preparing content for processing', 'Loading your input into the voice studio..', 'Transforming text into a natural voice..', 'Fine-tuning pronunciation and tone...', 'Optimizing clarity and expression...—almost there!']
    const repeatedSentences  = ['Loading your input into the voice studio..', 'Transforming text into a natural voice..', 'Fine-tuning pronunciation and tone...', 'Optimizing clarity and expression...—almost there!']
    
    const loadingImages = [
      {imageUrl : LoaderImage, percentage:0},
      {imageUrl : LoaderImage1, percentage:10},
      {imageUrl : LoaderImage2, percentage:20},
      {imageUrl : LoaderImage3, percentage:30},
      {imageUrl : LoaderImage4, percentage:40},
      {imageUrl : LoaderImage5, percentage:50},
      {imageUrl : LoaderImage6, percentage:60},
      {imageUrl : LoaderImage7, percentage:70},
      {imageUrl : LoaderImage8, percentage:80},
      {imageUrl : LoaderImage9, percentage:90},
      {imageUrl : LoaderImage10, percentage:100},
     ]
  
  
    const [loaderCurrentText, setLoaderCurrentText] = useState(initialSentences[0])
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentImage, setCurrentImage] = useState(loadingImages[0]);
  
    useEffect(() => {
      const sentences = [...initialSentences, ...repeatedSentences]; // Combine initial and repeated sentences
      const startOfRepeats = initialSentences.length; // Starting index of repeated sentences
  
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => {
          const nextIndex = prevIndex + 1;
          if (nextIndex < sentences.length) {
            return nextIndex;
          } else {
            return startOfRepeats; // Loop back to repeated sentences
          }
        });
      }, 3000); // Change text every 2 seconds
  
      return () => clearInterval(interval); // Cleanup on unmount
    }, [initialSentences, repeatedSentences]);
  
    useEffect(() => {
      const sentences = [...initialSentences, ...repeatedSentences];
      setLoaderCurrentText(sentences[currentIndex]);
    }, [currentIndex, initialSentences, repeatedSentences]);
  
    useEffect(()=>{
      if(value <= 0){
        setCurrentImage(loadingImages[0])
      }else if(value > 0 && value <= 10){
        setCurrentImage(loadingImages[1])
      }else if(value > 10 && value <= 20){
        setCurrentImage(loadingImages[2])
      }else if(value > 20 && value <= 30){
        setCurrentImage(loadingImages[3])
      }else if(value > 30 && value <= 40){
        setCurrentImage(loadingImages[4])
      }else if(value > 40 && value <= 50){
        setCurrentImage(loadingImages[5])
      }else if(value > 50 && value <= 60){
        setCurrentImage(loadingImages[6])
      }else if(value > 60 && value <= 70){
        setCurrentImage(loadingImages[7])
      }else if(value > 70 && value <= 80){
        setCurrentImage(loadingImages[8])
      }else if(value > 80 && value <= 100){
        setCurrentImage(loadingImages[9])
      }
    },[value])
  
  
    const blink = keyframes`
    0% {
      background-color:#282D51
    }
    100% {
      background-color:#1F233E;
    }
  `;
  
  const Dot = styled.div`
    width:${isUnder1400px ? "720px" : "100%"} ;
    height: 240px;
    border-radius: 8px;
    background-color: #282d51;
    margin: 0 5px;
    animation: ${blink} 3s infinite alternate; /* Apply the blinking animation */
    position: relative; /* For positioning child elements */
  `;
  
  // InnerContent component to prevent blinking
  const InnerContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 36px;
    align-items: center;
    justify-content: center;
    padding: 20px;
    position: absolute; /* Positioning to avoid being affected by animation */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    `;
  
  
  
    return (
        <Box display="flex" flexDirection="column" alignItems="start" justifyContent="center" marginBottom={"20px"}>
          {/* Dot component with an image inside */}
          <Dot >
          <InnerContent>
            <img
              src={currentImage?.imageUrl}
              alt="Loading"
              style={{ width: '100px', height: '100px' }}
            />
          <Typography style={{display:"flex",textAlign:"center", fontFamily:"Inter", fontSize:"16px", fontStyle:"normal", fontWeight:400, color:"rgba(255, 255, 255, 0.80)"}}>{loaderCurrentText}</Typography>
          </InnerContent>
          </Dot>
        </Box>
  
    )
  }
  
export  function CommanLoaderVideo({value}){
  const isUnder1400px = useMediaQuery({ minWidth:1400});
  
    const initialSentences = ["Getting started", 'Gathering assets', 'Preparing content for processing', 'Assembling the pixels for your perfect scene', 'Aligning visuals and sound—it’s nearly showtime', 'Putting the final touches on your video—almost there!']
    const repeatedSentences  = ['Assembling the pixels for your perfect scene', 'Aligning visuals and sound—it’s nearly showtime', 'Putting the final touches on your video—almost there!']
    
    const loadingImages = [
      {imageUrl : LoaderImage, percentage:0},
      {imageUrl : LoaderImage1, percentage:10},
      {imageUrl : LoaderImage2, percentage:20},
      {imageUrl : LoaderImage3, percentage:30},
      {imageUrl : LoaderImage4, percentage:40},
      {imageUrl : LoaderImage5, percentage:50},
      {imageUrl : LoaderImage6, percentage:60},
      {imageUrl : LoaderImage7, percentage:70},
      {imageUrl : LoaderImage8, percentage:80},
      {imageUrl : LoaderImage9, percentage:90},
      {imageUrl : LoaderImage10, percentage:100},
     ]
  
  
    const [loaderCurrentText, setLoaderCurrentText] = useState(initialSentences[0])
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentImage, setCurrentImage] = useState(loadingImages[0]);
  
    useEffect(() => {
      const sentences = [...initialSentences, ...repeatedSentences]; // Combine initial and repeated sentences
      const startOfRepeats = initialSentences.length; // Starting index of repeated sentences
  
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => {
          const nextIndex = prevIndex + 1;
          if (nextIndex < sentences.length) {
            return nextIndex;
          } else {
            return startOfRepeats; // Loop back to repeated sentences
          }
        });
      }, 3000); // Change text every 2 seconds
  
      return () => clearInterval(interval); // Cleanup on unmount
    }, [initialSentences, repeatedSentences]);
  
    useEffect(() => {
      const sentences = [...initialSentences, ...repeatedSentences];
      setLoaderCurrentText(sentences[currentIndex]);
    }, [currentIndex, initialSentences, repeatedSentences]);
  
    useEffect(()=>{
      if(value <= 0){
        setCurrentImage(loadingImages[0])
      }else if(value > 0 && value <= 10){
        setCurrentImage(loadingImages[1])
      }else if(value > 10 && value <= 20){
        setCurrentImage(loadingImages[2])
      }else if(value > 20 && value <= 30){
        setCurrentImage(loadingImages[3])
      }else if(value > 30 && value <= 40){
        setCurrentImage(loadingImages[4])
      }else if(value > 40 && value <= 50){
        setCurrentImage(loadingImages[5])
      }else if(value > 50 && value <= 60){
        setCurrentImage(loadingImages[6])
      }else if(value > 60 && value <= 70){
        setCurrentImage(loadingImages[7])
      }else if(value > 70 && value <= 80){
        setCurrentImage(loadingImages[8])
      }else if(value > 80 && value <= 100){
        setCurrentImage(loadingImages[9])
      }
    },[value])
  
  
    const blink = keyframes`
    0% {
      background-color:#282D51
    }
    100% {
      background-color:#1F233E;
    }
  `;
  
  const Dot = styled.div`
    width: ${isUnder1400px ?  "720px" :   "100%"  };
    height: 428px;
    border-radius: 8px;
    background-color: #282d51;
    margin: 0 5px;
    animation: ${blink} 3s infinite alternate; /* Apply the blinking animation */
    position: relative; /* For positioning child elements */
    flex-shrink: 0; /* Prevent shrinking */
  `;
  
  // InnerContent component to prevent blinking
  const InnerContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 36px;
    align-items: center;
    justify-content: center;
    padding: 20px;
    position: absolute; /* Positioning to avoid being affected by animation */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    `;
  
  
  
    return (
        <Box display="flex" flexDirection="column" alignItems="start" justifyContent="center" marginBottom={"20px"}>
          {/* Dot component with an image inside */}
          <Dot >
          <InnerContent>
            <img
              src={currentImage?.imageUrl}
              alt="Loading"
              style={{ width: '100px', height: '100px' }}
            />
          <Typography style={{display:"flex",textAlign:"center", fontFamily:"Inter", fontSize:"16px", fontStyle:"normal", fontWeight:400, color:"rgba(255, 255, 255, 0.80)"}}>
                {loaderCurrentText}
            </Typography>
          </InnerContent>
          </Dot>
        </Box>
  
    )
  }
  
export  function CommanLoader({value}){
  
    const initialSentences = ["Getting started", 'Gathering assets', 'Preparing content for processing', 'Assembling the pixels for your perfect scene', 'Aligning visuals and sound—it’s nearly showtime', 'Putting the final touches on your video—almost there!']
    const repeatedSentences  = ['Assembling the pixels for your perfect scene', 'Aligning visuals and sound—it’s nearly showtime', 'Putting the final touches on your video—almost there!']
    
    const loadingImages = [
      {imageUrl : LoaderImage, percentage:0},
      {imageUrl : LoaderImage1, percentage:10},
      {imageUrl : LoaderImage2, percentage:20},
      {imageUrl : LoaderImage3, percentage:30},
      {imageUrl : LoaderImage4, percentage:40},
      {imageUrl : LoaderImage5, percentage:50},
      {imageUrl : LoaderImage6, percentage:60},
      {imageUrl : LoaderImage7, percentage:70},
      {imageUrl : LoaderImage8, percentage:80},
      {imageUrl : LoaderImage9, percentage:90},
      {imageUrl : LoaderImage10, percentage:100},
     ]
  
  
    const [loaderCurrentText, setLoaderCurrentText] = useState(initialSentences[0])
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentImage, setCurrentImage] = useState(loadingImages[0]);
  
    useEffect(() => {
      const sentences = [...initialSentences, ...repeatedSentences]; // Combine initial and repeated sentences
      const startOfRepeats = initialSentences.length; // Starting index of repeated sentences
  
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => {
          const nextIndex = prevIndex + 1;
          if (nextIndex < sentences.length) {
            return nextIndex;
          } else {
            return startOfRepeats; // Loop back to repeated sentences
          }
        });
      }, 3000); 
  
      return () => clearInterval(interval); // Cleanup on unmount
    }, [initialSentences, repeatedSentences]);
  
    useEffect(() => {
      const sentences = [...initialSentences, ...repeatedSentences];
      setLoaderCurrentText(sentences[currentIndex]);
    }, [currentIndex, initialSentences, repeatedSentences]);
  
    useEffect(()=>{
      if(value <= 0){
        setCurrentImage(loadingImages[0])
      }else if(value > 0 && value <= 10){
        setCurrentImage(loadingImages[1])
      }else if(value > 10 && value <= 20){
        setCurrentImage(loadingImages[2])
      }else if(value > 20 && value <= 30){
        setCurrentImage(loadingImages[3])
      }else if(value > 30 && value <= 40){
        setCurrentImage(loadingImages[4])
      }else if(value > 40 && value <= 50){
        setCurrentImage(loadingImages[5])
      }else if(value > 50 && value <= 60){
        setCurrentImage(loadingImages[6])
      }else if(value > 60 && value <= 70){
        setCurrentImage(loadingImages[7])
      }else if(value > 70 && value <= 80){
        setCurrentImage(loadingImages[8])
      }else if(value > 80 && value <= 100){
        setCurrentImage(loadingImages[9])
      }
    },[value])
  
  
  const blink = keyframes`
  0% {
    background-color: #282D51;
  }
  50% {
    background-color: #1F233E;
  }
  100% {
    background-color: #282D51;
  }`;
  
  const Dot = styled.div`
    width: 100%;
    height: 428px;
    border-radius: 8px;
    background-color: #282d51;
    margin: 0 5px;
    animation: ${blink} 3s ease-in-out infinite; /* Ensure smooth easing */
    position: relative; /* For positioning child elements */
    `;
  
  // InnerContent component to prevent blinking
  const InnerContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 36px;
    align-items: center;
    justify-content: center;
    padding: 20px;
    position: absolute; /* Positioning to avoid being affected by animation */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    `;
  
  
  
    return (
        <Box display="flex" flexDirection="column" alignItems="start" justifyContent="center" marginBottom={"20px"}>
          {/* Dot component with an image inside */}
          <Dot >
          <InnerContent>
            <img
              src={currentImage?.imageUrl}
              alt="Loading"
              style={{ width: '100px', height: '100px' }}
            />
            <Typography style={{display:"flex",textAlign:"center", fontFamily:"Inter", fontSize:"16px", fontStyle:"normal", fontWeight:400, color:"rgba(255, 255, 255, 0.80)"}}>
                {loaderCurrentText}
            </Typography>
          </InnerContent>
          </Dot>
        </Box>
  
    )
  }
import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

const AddInvestorModal = ({ show, handleClose, handleSave }) => {
  const [investorData, setInvestorData] = useState({
    investor_id: "",
    email: "",
    firstname: "",
    lastname: "",
    contact_type: "",
    favorite_url: "",
    urls: "",
    favorite_phone: "",
    companies: "",
    facebook_link: "",
    founding_year: "",
    fund_description: "",
    fund_focus: "",
    fund_stage: "",
    fund_type: "",
    linkedin_company_page: "",
    location: "",
    number_of_exits: 0,
    number_of_investments: 0,
    portfolio_companies: "",
    twitter_link: "",
    linkedin_link: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedValue =
      name === "id" || name === "number_of_exits" || name === "number_of_investments"
        ? parseInt(value, 10) || 0
        : value;
    setInvestorData({ ...investorData, [name]: updatedValue });
  };

  const handleSubmit = () => {
    handleSave(investorData);
  };

  // Define the required fields
  const requiredFields = ["firstname", "email", "location"];

  // Check if all required fields are filled
  const isFormValid = requiredFields.every((field) => investorData[field].trim() !== "");

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Add New Investor</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "60vh", overflowY: "auto" }}>
        <Form>
          {Object.keys(investorData).map((key) => (
            <Form.Group className="mb-3" key={key}>
              <Form.Label>{key.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())}</Form.Label>
              <Form.Control
                type={typeof investorData[key] === "number" ? "number" : "text"}
                name={key}
                value={investorData[key]}
                onChange={handleChange}
                required={requiredFields.includes(key)} // Mark required fields
              />
              {requiredFields.includes(key) && investorData[key].trim() === "" && (
                <Form.Text className="text-danger">This field is required</Form.Text>
              )}
            </Form.Group>
          ))}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled={!isFormValid}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddInvestorModal;
import React, { useEffect } from "react";
import { Box, Typography, CardMedia, Card, Button, Col } from "@mui/material";
import image1 from "../assets/BlogPageImage/image1.png";
import image2 from "../assets/BlogPageImage/image2.png";
import image3 from "../assets/BlogPageImage/image3.png";
import logo from "../assets/BlogPageImage/logo.png";
import LinkedInIcon from "../assets/BlogPageImage/linkdinIcon.png";
import InsatgramIcon from "../assets/BlogPageImage/InstagramIcon.png";
import { useMediaQuery } from "react-responsive";
import { selected_chat_bg_color } from "../constants/colors";
import { useLocation, useParams } from "react-router-dom";
import PageLayout from "../components/navFoot/PageLayout";

const BlogPage = () => {
  const isBelow991 = useMediaQuery({ maxWidth: 991 });
  const { blogTitle } = useParams();
  const location = useLocation();
  const blog = location.state?.blog;

  // Scroll to top when the page loads
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]); // Runs only when pathname changes

  if (!blog) {
    return (
      <Box
        sx={{
          maxWidth: "62.5%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          margin: "50px auto",
        }}
      >
        <h2>Blog not found!</h2>
      </Box>
    );
  }

  return (
    <PageLayout>
    <Box
      sx={{
        maxWidth: isBelow991 ? "90%" : "62.5%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: isBelow991 ? "40px auto" : "50px auto",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          rowGap: "20px",
        }}
      >
        <CardMedia
          sx={{
            backgroundColor: "rgba(40, 45, 81, 1)",
          }}
          component="img"
          image={blog?.image ? blog?.image : image1}
          alt="log"
        />
        <Typography
          sx={{
            fontFamily: "Inter",
            fontWeight: "400px",
            fontSize: "16px",
            lineHeight: "19.2px",
            textAlign: "center",
            letterSpacing: "-0.32px",
            color: "rgba(255, 255, 255, 0.8)",
            "@media (max-width : 991px)": {
              fontSize: "16px",
            },
          }}
        >
          Made by ModalX
        </Typography>
      </Box>

      <Box
        sx={{
          width: "100%",
          marginTop: "40px",
          gap: "28px",
          opacity: "0px",
        }}
      >
        {" "}
        <Typography
          sx={{
            // width: "50%",
            fontFamily: "Inter",
            fontWeight: 500,
            fontSize: isBelow991 ? "28px" : "48px",
            lineHeight: isBelow991 ? "33.6px" : "57.6px",
            textAlign: "left",
            letterSpacing: "-0.32px",
            color: "rgba(255, 255, 255, 1)",
            opacity: 0.8,
            margin: "auto",
            "@media(max-width : 991px)": {
              padding: "0px, 2.2% 0px 2.2%",
            },
          }}
        >
          {blogTitle
            ? blogTitle?.toString()
            : "The Rise of AI Agents: Shaping the Future of Work"}
        </Typography>
      </Box>

      <Box
        sx={{
          display: isBelow991 ? "flex" : "none",
          flexDirection: "row",
          columnGap: "12px",
          marginTop: isBelow991 && "26.5px",
        }}
      >
        <Box
          sx={{
            display: isBelow991 ? "" : "none",
            // width: "127px",
            height: "24px",
            // gap : '12px',
            opacity: "0px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Inter",
              fontWeight: "400px",
              fontSize: "16px",
              lineHeight: "24px",
              textAlign: "center",
              letterSpacing: "-.32px",
              color: "rgba(255, 255, 255, 0.8)",
              "@media (max-width : 991px)": {
                fontSize: "16px",
              },
            }}
          >
            {blog ? blog?.title3 : "July 27, 2024"}
          </Typography>
        </Box>

        <Box
          sx={{
            display: isBelow991 ? "" : "none",
            height: "24px",
            bgcolor: "rgba(255, 255, 255, 0.20)",
            width: "1px",
          }}
        ></Box>

        <Box
          sx={{
            display: isBelow991 ? "" : "none",
            // width: "127px",
            height: "24px",
            // gap : '12px',
            opacity: "0px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Inter",
              fontWeight: "400px",
              fontSize: "16px",
              lineHeight: "24px",
              textAlign: "center",
              letterSpacing: "-.32px",
              color: "rgba(255, 255, 255, 0.8)",
              "@media (max-width : 991px)": {
                fontSize: "16px",
              },
            }}
          >
            3 minute read
          </Typography>
        </Box>
      </Box>
      {/* Box for date time */}
      <Box
        sx={{
          borderBottom: "1px solid rgba(255, 255, 255, 0.20)",
          paddingBottom: "20px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "40px",
        }}
      >
        <Box
          sx={{
            width: "479px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            color: "#FFFFFF",
            opacity: "80%",
            "@media (max-width : 991px )": {
              width: "350px",
              gap: "0px",
            },
          }}
        >
          <Box
            sx={{
              width: "108px",
              gap: "12px",
              opacity: "0px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              "@media (max-width : 991px)": {
                width: "97px",
              },
            }}
          >
            <Box
              sx={{
                width: "40px",
                borderRadius: "50%",
                margin: "0px",
              }}
            >
              <CardMedia
                sx={{
                  borderRadius: "50%",
                }}
                component="img"
                image={logo}
                alt="log"
              />
            </Box>

            <Box
              sx={{
                // width: "70px",
                height: "24px",
                margin: "0px",
              }}
            >
              {" "}
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontWeight: "400px",
                  fontSize: "16px",
                  lineHeight: "24px",
                  textAlign: "center",
                  letterSpacing: "-.32px",
                  color: "rgba(255, 255, 255, 0.8)",
                  "@media (max-width : 991px)": {
                    fontSize: "16px",
                  },
                }}
              >
                ModalX
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: isBelow991 ? "none" : "",
              height: "24px",
              bgcolor: "rgba(255, 255, 255, 0.20)",
              width: "1px",
            }}
          ></Box>

          <Box
            sx={{
              display: isBelow991 ? "none" : "",
              // width: "127px",
              height: "24px",
              // gap : '12px',
              opacity: "0px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Inter",
                fontWeight: "400px",
                fontSize: "16px",
                lineHeight: "24px",
                textAlign: "center",
                letterSpacing: "-.32px",
                color: "rgba(255, 255, 255, 0.8)",
                "@media (max-width : 991px)": {
                  fontSize: "16px",
                },
              }}
            >
              {blog ? blog?.title3 : "July 27, 2024"}
            </Typography>
          </Box>

          <Box
            sx={{
              display: isBelow991 ? "none" : "",
              height: "24px",
              bgcolor: "rgba(255, 255, 255, 0.20)",
              width: "1px",
            }}
          ></Box>

          <Box
            sx={{
              display: isBelow991 ? "none" : "",
              // width: "127px",
              height: "24px",
              // gap : '12px',
              opacity: "0px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Inter",
                fontWeight: "400px",
                fontSize: "16px",
                lineHeight: "24px",
                textAlign: "center",
                letterSpacing: "-.32px",
                color: "rgba(255, 255, 255, 0.8)",
                "@media (max-width : 991px)": {
                  fontSize: "16px",
                },
              }}
            >
              3 minute read
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "row", columnGap: "12px" }}>
          <Box
            onClick={() => {
              window.open(blog.link, "_blank");
            }}
            sx={{
              width: "34px",
              height: "34px",
              cursor: "pointer",
            }}
          >
            <CardMedia component="img" image={LinkedInIcon} alt="log" />
          </Box>

          <Box
            sx={{
              width: "34px",
              height: "34px",
              cursor: "pointer",
            }}
          >
            <CardMedia component="img" image={InsatgramIcon} alt="log" />
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: isBelow991 ? "20px" : "40px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            gap: "0px",
            opacity: "0px",
            "@media(max-width : 901px)": {
              width: "100%",
            },
          }}
        >
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "16px",
              fontWeight: "400px",
              lineHeight: "28.8px",
              letterSpacing: "-0.32",
              textAlign: "left",
              color: "rgba(255, 255, 255, 1)",
            }}
          >
            Imagine a world where your daily tasks are managed by smart
            machines, freeing you to focus on big ideas and strategic goals.
            This isn't science fiction—thanks to AI agents, specialized programs
            designed to handle specific tasks, it's a reality that's
            transforming workplaces everywhere.
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          width: "100%",
          marginTop: "36px",
          opacity: "0px",
        }}
      >
        {" "}
        <Typography
          sx={{
            width: "100%",
            fontFamily: "Inter",
            fontWeight: "600px",
            fontSize: isBelow991 ? "20px" : "24px",
            lineHeight: "43.2px",
            textAlign: "left",
            letterSpacing: "-0.32px",
            color: "rgba(255, 255, 255, 1)",
            margin: "auto",
          }}
        >
          How does this change the future of work?
        </Typography>
      </Box>

      <Box
        sx={{
          width: "100%",
          marginTop: "24px",
          gap: "0px",
          opacity: "0px",
          "@media(max-width : 991px)": {
            width: "100%",
          },
        }}
      >
        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: "16px",
            fontWeight: "400px",
            lineHeight: "28.8px",
            letterSpacing: "-0.32",
            textAlign: "left",
            color: "rgba(255, 255, 255, 1)",
            "@media(max-width : 901px)": {
              fontSize: "16px",
            },
          }}
        >
          From boosting productivity to changing job roles, let's explore the
          exciting ways AI agents are revolutionizing our work lives.
        </Typography>
      </Box>

      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          rowGap: "20px",
          marginTop: "40px",
        }}
      >
        <CardMedia
          sx={{
            backgroundColor: "rgba(40, 45, 81, 1)",
          }}
          component="img"
          image={image2}
          alt="log"
        />
        <Typography
          sx={{
            fontFamily: "Inter",
            fontWeight: "400px",
            fontSize: "16px",
            lineHeight: "19.2px",
            textAlign: "center",
            letterSpacing: "-0.32px",
            color: "rgba(255, 255, 255, 0.8)",
            "@media (max-width : 991px)": {
              fontSize: "16px",
            },
          }}
        >
          Made by ModalX
        </Typography>
      </Box>

      <Box
        sx={{
          width: "100%",
          marginTop: "40px",
          "@media(max-width : 991px)": {
            width: "100%",
          },
        }}
      >
        <Typography
          sx={{
            width: "100%",
            fontFamily: "Inter",
            fontWeight: "600px",
            fontSize: isBelow991 ? "20px" : "24px",
            lineHeight: "43.2px",
            textAlign: "left",
            letterSpacing: "-0.32px",
            color: "rgba(255, 255, 255, 1)",
            margin: "auto",
          }}
        >
          AI Agents: Transforming the Workplace
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: isBelow991 ? "20px" : "40px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            gap: "0px",
            opacity: "0px",
            "@media(max-width : 991px)": {
              width: "100%",
            },
          }}
        >
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "16px",
              fontWeight: "400px",
              lineHeight: "28.8px",
              letterSpacing: "-0.32",
              textAlign: "left",
              color: "rgba(255, 255, 255, 1)",
              "@media(max-width : 901px)": {
                fontSize: "16px",
              },
            }}
          >
            AI agents are now found in various fields, from customer service to
            healthcare, tackling tasks previously done by people. They can
            process data, make decisions, and even create content. Companies
            like Google DeepMind , IBM's Watson, and Microsoft AutogenAI are
            leading the charge in implementing these technologies. These digital
            assistants help businesses cut costs and boost efficiency. By
            automating routine, data-heavy tasks, AI allows human employees to
            focus on more creative and impactful work. This shift means that the
            boring, time-consuming parts of jobs are being handled by machines,
            giving employees the chance to engage in strategic planning and
            creative problem-solving, where human intuition and creativity
            excel. Thanks to AI agents, industries are breaking new grounds of
            innovation by utilizing the tireless capabilities of these digital
            helpers.
          </Typography>
        </Box>
      </Box>

      {/* Response Box of Message */}
      <Box
        xs="auto"
        style={{
          backgroundColor: selected_chat_bg_color,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          marginTop: "40px",
          // height:'auto',
          borderRadius: "16px",
          padding: "20px",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400px",
            lineHeight: "36px",
            letterSpacing: "-0.32",
            textAlign: "left",
            color: "rgba(255, 255, 255, 0.8)",
            "@media(max-width : 901px)": {
              fontSize: "16px",
            },
          }}
        >
          How would you feel about a machine handling your routine tasks,
          allowing you more time for creativity and strategy?
        </Typography>
      </Box>

      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          rowGap: "20px",
          marginTop: "40px",
        }}
      >
        <CardMedia
          sx={{
            backgroundColor: "rgba(40, 45, 81, 1)",
          }}
          component="img"
          image={image3}
          alt="log"
        />
        <Typography
          sx={{
            fontFamily: "Inter",
            fontWeight: "400px",
            fontSize: "16px",
            lineHeight: "19.2px",
            textAlign: "center",
            letterSpacing: "-0.32px",
            color: "rgba(255, 255, 255, 0.8)",
            "@media (max-width : 991px)": {
              fontSize: "16px",
            },
          }}
        >
          Made by ModalX
        </Typography>
      </Box>

      <Box
        sx={{
          width: "100%",
          marginTop: "40px",
          "@media(max-width : 991px)": {
            width: "100%",
          },
        }}
      >
        <Typography
          sx={{
            width: "100%",
            fontFamily: "Inter",
            fontWeight: "600px",
            fontSize: "24px",
            lineHeight: "43.2px",
            textAlign: "left",
            letterSpacing: "-0.32px",
            color: "rgba(255, 255, 255, 1)",
            margin: "auto",
            "@media(max-width : 991px)": {
              textAlign: "center",
              fontSize: "20px",
            },
          }}
        >
          Boosting Productivity with Automation
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            marginTop: "24px",
            gap: "0px",
            opacity: "0px",
            "@media(max-width : 901px)": {
              width: "100%",
            },
          }}
        >
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "16px",
              fontWeight: "400px",
              lineHeight: "28.8px",
              letterSpacing: "-0.32",
              textAlign: "left",
              color: "rgba(255, 255, 255, 1)",
              "@media(max-width : 901px)": {
                fontSize: "16px",
              },
            }}
          >
            Imagine having an assistant that never sleeps and works around the
            clock. That's the magic of AI agents. Amazon uses AI customer
            service chatbots, like those developed by Intercom AI, that can
            manage numerous customer questions at once, ensuring quick and
            correct answers. This not only satisfies customers but also lets
            human staff tackle more complicated issues. AI's capability to
            analyze vast amounts of data quickly provides insights that shape
            company strategies. Companies today, such as Salesforce and SAP, use
            AI to make smart decisions without the need for manual data
            analysis. This productivity boost gives forward-thinking companies
            the edge to stay competitive in the market. The impact of AI on
            productivity isn't just about customer service. In manufacturing, AI
            agents optimize production processes by identifying inefficiencies
            and suggesting solutions. In marketing, AI tools like those from
            Copy AI help in predicting what consumers prefer, creating
            personalized campaigns that boost engagement and sales.
          </Typography>
        </Box>
      </Box>
    </Box>
    </PageLayout>
  );
};

export default BlogPage;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import { BrowserClient } from "@sentry/browser"; 
import 'inter-ui/inter.css';
import GlobalStyle from "./GlobalStyle";
import ErrorPage from "./components/Common_Components/ErrorPage";
import WorkspaceProvider from "./contexts/WorkspaceContext";
import './constants/setupInterceptors'

const root = ReactDOM.createRoot(document.getElementById("root"));
// Initialize Sentry
const sentryDsn = process.env.REACT_APP_SENTRY_URL;
const sourceUrl = process.env.REACT_APP_SOURCE_URL;
if(sourceUrl){
  Sentry.init({
    dsn: sentryDsn,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ], // Use BrowserClient
    tracesSampleRate: 1.0, // Adjust as needed for production
  });
}

root.render(
  <Sentry.ErrorBoundary  fallback={<ErrorPage/>}> 
    <GlobalStyle />
    <WorkspaceProvider>
    <App />
    </WorkspaceProvider>
  </Sentry.ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

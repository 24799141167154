// import React, { useState } from 'react';
// import { Box, Button, IconButton } from '@mui/material';
// import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import { New_Project_Button_bg_color, SelectedChat_bg_color } from '../../Provider/Color_Provider';
// import Linkidin_Image from "../../assets/LandingPage_Items/Testimonials/linkedin.png";
// import Avatar_Image from "../../assets/LandingPage_Items/Testimonials/Avatar.png";
// import { hovered_chat_bg_color_dm, selected_chat_bg_color_dm } from '../../constants/colors';

// const Testimonials = () => {
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const totalBoxes = 5; // Total number of boxes in the carousel
//   const visibleBoxes = 3; // Number of boxes visible at once
//   const boxWidth = 386; // Width of each box in pixels (including margin)

//   const handlePrevClick = () => {
//     if (currentIndex > 0) {
//       setCurrentIndex((prevIndex) => prevIndex - 1);
//     }
//   };

//   const handleNextClick = () => {
//     if (currentIndex < totalBoxes - visibleBoxes) {
//       setCurrentIndex((prevIndex) => prevIndex + 1);
//     }
//   };

//   const features = [
//     {
//       id: '1',
//       name: 'Cameron Williamson',
//       description: '“We have increased our web presence through videos with amazing quality. It has freed up hours for my employees and has allowed them to focus on our core business”.',
//     },
//     {
//       id: '2',
//       name: 'Cameron Williamson',
//       description: '“We have increased our web presence through videos with amazing quality. It has freed up hours for my employees and has allowed them to focus on our core business”.',
//     },
//     {
//       id: '3',
//       name: 'Cameron Williamson',
//       description: '“We have increased our web presence through videos with amazing quality. It has freed up hours for my employees and has allowed them to focus on our core business”.',
//     },
//     {
//       id: '4',
//       name: 'Cameron Williamson',
//       description: '“We have increased our web presence through videos with amazing quality. It has freed up hours for my employees and has allowed them to focus on our core business”.',
//     },
//     {
//       id: '5',
//       name: 'Cameron Williamson',
//       description: '“We have increased our web presence through videos with amazing quality. It has freed up hours for my employees and has allowed them to focus on our core business”.',
//     }
//   ];

//   return (
//     <div>
//       <p style={{ textAlign: 'center', color: '#F6BE6B' }}>Testimonials</p>
//       <div style={{ maxWidth: '1072px', margin: 'auto', textAlign: 'center' }}>
//         <p style={{ fontSize: '48px', marginTop: '80px' }}>
//             Don’t just take our word for it        
//         </p>
//         <div style={{width:'704px',height:'48px',margin:'auto',color:'rgba(255,255,255,0.8)'}}>
//             <p>Our diverse clients share a common theme: success. They’ve used our tools to streamline workflows, enhance creativity, and achieve remarkable results</p>
//         </div>
        
//       </div>
//       <Box display="flex" alignItems="center" justifyContent="center" mt={4} sx={{ marginTop: '138px' }}>
//         <Box
//           overflow="hidden"
//           width={`${visibleBoxes * boxWidth}px`}
//           display="flex"
//           position="relative"
//         >
//           <Box
//             display="flex"
//             transition="transform 0.5s ease"
//             style={{
//               transform: `translateX(-${currentIndex * boxWidth}px)`
//             }}
//           >
//             {features.map((feature, index) => (
//               <Box
//                 key={feature.id}
//                 width="386px"
//                 height="262px"
//                 bgcolor={SelectedChat_bg_color}
//                 borderRadius="12px"
//                 marginRight="30px"
//                 boxShadow={2}
//                 flexShrink={0}
//                 display="flex"
//                 justifyContent="center"
//                 alignItems="center"
//                 flexDirection='column'
//                 p={2}
//               >
//                 <div style={{width:'318px',height:'194px',display:'flex',flexDirection:'column',justifyContent:'space-between'}}>
//                     <div style={{height:'40px',display:'flex',justifyContent:'space-between'}}>
//                         <div style={{display:'flex',height:'40px',maxWidth:'210px',justifyContent:'space-between'}}>
//                             <img src={Avatar_Image}/>
//                             <div style={{height:'40px',lineHeight:'10px',marginLeft:'10px'}}>
//                                 <p>{feature.name}</p>
//                                 <p style={{color:'#8F9BB7'}}>COO @ ShareWise</p>
//                             </div>
//                         </div>
//                         <img src={Linkidin_Image} style={{height:'24px',width:'24px'}}/>
//                     </div>
//                     <p>{feature.description}</p>
//                 </div>
//               </Box>
//             ))}
//           </Box>
//         </Box>
//       </Box>
//       <Box display="flex" justifyContent="center" sx={{maxWidth:'112px',height:'48px',display:'flex',justifyContent:'space-between',margin:'48px auto'}}>
//           <IconButton
//             onClick={handlePrevClick}
//             disabled={currentIndex === 0}
//             disableRipple
//             sx={{
//               bgcolor: '#1F233E',
//               color: 'white', // Set the icon color
//               '&:hover': {
//                 backgroundColor: '#1B1E36',
//               },
//               '&:disabled': {
//                 bgcolor: '#1F233E', // Maintain the same background color when disabled
//                 color: 'grey', // Change color to indicate disabled state
//               },
//               width:'48px',
//               height:'49px'
//             }}
//           >
//             <ChevronLeftIcon />
//           </IconButton>
//           <IconButton
//             onClick={handleNextClick}
//             disabled={currentIndex >= totalBoxes - visibleBoxes}
//             disableRipple
//             sx={{
//               bgcolor: '#1F233E',
//               color: 'white', // Set the icon color
//               '&:hover': {
//                 backgroundColor: '#1B1E36',
//               },
//               '&:disabled': {
//                 bgcolor: '#1F233E', // Maintain the same background color when disabled
//                 color: 'grey', // Change color to indicate disabled state
//               },
//               width:'48px',
//               height:'49px'
//             }}
//           >
//             <KeyboardArrowRightIcon />
//           </IconButton>
//         </Box>

//     </div>
//   );
// };

// export default Testimonials;


import React, { useState } from 'react';
import { Box, IconButton, Typography, useMediaQuery } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { SelectedChat_bg_color } from '../../Provider/Color_Provider';
import Linkidin_Image from "../../assets/LandingPage_Items/Testimonials/linkedin.png";
import Avatar_Image from "../../assets/LandingPage_Items/Testimonials/Avatar.png";
import { darkTheme_bg_color, selected_chat_bg_color_dm } from '../../constants/colors';

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalBoxes = 6; // Total number of boxes in the carousel
  const visibleBoxes = 3; // Number of boxes visible at once
  const boxWidth = 386; // Width of each box in pixels (including margin)
  const isBelow991 = useMediaQuery("(max-width:991px)");

  const handlePrevClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (currentIndex < totalBoxes - visibleBoxes) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const features = [
    {
      id: '1',
      name: 'John Smith',
      position:"CEO of Tech Innovators Inc.",
      description: '“ModalX has revolutionized the way we do business. The seamless integration with our existing systems has not only streamlined our processes but also improved our overall efficiency. Our team can now focus more on strategic tasks rather than getting caught up in mundane activities. This platform has dramatically accelerated our growth trajectory.”',
    },
    {
      id: '2',
      name: 'Emily Carter',
      position:"Operations Manager at GreenCo",
      description: `“Implementing ModalX was one of the best decisions we've made. The real-time analytics and data insights have empowered us to make informed decisions quickly. We're witnessing significant improvements in our operational efficiency, which has directly impacted our bottom line. The growth in our business is a testament to the effectiveness of ModalX.”`,
    },
    {
      id: '3',
      name: 'Mark Johnson',
      position:"Head of Distribution at Global Supply Networks",
      description: `“Before ModalX, managing the complexities of our supply chain was a daunting task. With ModalX, we've seen a drastic reduction in errors and an increase in visibility across all channels. It's like having another team working around the clock, ensuring everything runs smoothly. The impact on our business growth has been phenomenal.”`,
    },
    {
      id: '4',
      name: 'Sarah Lee',
      position:"Chief Marketing Officer at Urban Trends",
      description: `“Thanks to ModalX, we've been able to harness data-driven insights to tailor our marketing strategies effectively. The precision in targeting and engagement has led to a notable increase in our customer base. The platform has truly been a game-changer in making our campaigns more dynamic and results-oriented.”`,
    },
    {
      id: '5',
      name: 'David Brown',
      position:"Finance Director at Apex Financial Services",
      description: `“ModalX has provided us with the tools to better manage our financial operations with utmost precision. The accuracy and speed of the financial reports have enhanced our decision-making process. We've experienced notable growth in our revenue, attributing a large part of this success to the capabilities offered by ModalX.”`,
    }
  ];

  return (
    <Box style={{paddingTop:isBelow991?'80px':'125px' ,background: `linear-gradient(180deg, #1F233E 16.27%, #171A2D 75.09%)`}}>
        <Typography sx={{fontFamily:"Inter",fontSize: isBelow991?'12px':"16px" , fontStyle:"normal", fontWeight:600, lineHeight:"150%", textAlign:"center", color:"#F6BE6B"}}>
            Testimonials
        </Typography>
        <Box maxWidth={isBelow991?'90%':"1072px"} margin="auto" marginTop='48px' textAlign="center">
            <Typography sx={{margin: isBelow991 ? "24px 0px 16px 0px" :"48px 0px 28px 0px", fontFamily:"Inter", fontStyle:"normal", fontSize:isBelow991?'28px':"48px", fontWeight:500, lineHeight:"120%", letterSpacing:"-0.32px" }}>
            Don’t just take our word for it        
            </Typography>
            <Box sx={{maxWidth:"704px",}} height="48px" mx="auto" color="rgba(255,255,255,0.8)">
            <Typography sx={{ fontFamily:"Inter", fontStyle:"normal", fontSize:isBelow991?'14px':'18px', fontWeight:500, lineHeight:"24px", letterSpacing:"0.1px"}}>
                Our diverse clients share a common theme: success. They’ve used our tools to streamline workflows, enhance creativity, and achieve remarkable results
            </Typography>
            </Box>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" mt={4} sx={{ marginTop: isBelow991?'88px':'80px' }}>
            <Box
            overflow="auto"
            width={`${visibleBoxes * boxWidth+162}px`}
            display="flex"
            position="relative"
            sx={{
              scrollBehavior: 'smooth', 
              '&::-webkit-scrollbar': {
                display: 'none', 
              },
              '-ms-overflow-style': 'none', 
              'scrollbar-width': 'none', 
            }}
            >
            <Box
                display="flex"
                transition="transform 0.5s ease-in-out" // Changed the transition to ease-in-out
                sx={{
                transform: `translateX(-${currentIndex * boxWidth}px)`,
                transitionProperty: 'transform',
                transitionDuration: '0.5s',
                transitionTimingFunction: 'ease-in-out', // Smooth transition
                
                }}
            >
                {features.map((feature) => (
                <Box
                    key={feature.id}
                    width={isBelow991?'358px':"386px"}
                    // height={isBelow991?'226px':"262px"}
                    bgcolor={selected_chat_bg_color_dm}
                    borderRadius="12px"
                    marginRight={isBelow991?'40px':"81px"}
                    boxShadow={2}
                    flexShrink={0}
                    display="flex"
                    // justifyContent="center"
                    alignItems="flex-start"
                    flexDirection='column'
                    p={3}
                    sx={{
                      "&:hover": {
                      backgroundColor: "#282D51CC",
                      cursor: "pointer",
                    },
                    rowGap:"20px"
                    }}
                >
                    {/* <Box width="318px" height="" display="flex" flexDirection="column" justifyContent="space-between" border="1px solid red"> */}
                    <Box display="flex" justifyContent="" alignItems="start">
                        <Box display="flex" height="40px" justifyContent="flex-start" >
                        {/* <img src={Avatar_Image} alt="avatar"/> */}
                        <Box sx={{display:"flex", flexDirection:"column", rowGap:"12px"}}>
                            <Typography sx={{fontFamily:"Inter", fontSize:"16px", fontStyle:"normal", fontWeight:500, lineHeight:"12px", color:"rgba(246, 246, 247, 1)"}}>{feature.name}</Typography>
                            <Typography sx={{fontFamily:"Inter", fontSize:"14px", fontStyle:"normal", fontWeight:400, lineHeight:"16px", color:"rgba(143, 155, 183, 1)"}}>{feature.position}</Typography>
                        </Box>
                        </Box>
                        {/* <img src={Linkidin_Image} alt="LinkedIn" style={{ height: '24px', width: '24px' }} /> */}
                    </Box>
                    <Box>
                    <Typography sx={{color:"rgba(255, 255, 255, 0.8)", fontFamily:"Inter", fontStyle:"normal",fontSize:"14px", fontWeight:400, lineHeight:"24px", opacity:0.8 }}>{feature.description}</Typography>
                    </Box>
                </Box>
                ))}
            </Box>
            </Box>
        </Box>
        <Box display="flex" justifyContent="center" sx={{maxWidth:'112px',height:'48px',display:'flex',justifyContent:'space-between',margin:'48px auto 120px auto'}}>
            <IconButton
            onClick={handlePrevClick}
            disabled={currentIndex === 0}
            disableRipple
            sx={{
                bgcolor: '#1F233E',
                color: 'white',
                '&:hover': {
                backgroundColor: '#1B1E36',
                },
                '&:disabled': {
                bgcolor: '#1F233E',
                color: 'grey',
                },
                width: '48px',
                height: '49px',
            }}
            >
            <ChevronLeftIcon />
            </IconButton>
            <IconButton
            onClick={handleNextClick}
            disabled={currentIndex >= totalBoxes - visibleBoxes}
            disableRipple
            sx={{
                bgcolor: '#1F233E',
                color: 'white',
                '&:hover': {
                backgroundColor: '#1B1E36',
                },
                '&:disabled': {
                bgcolor: '#1F233E',
                color: 'grey',
                },
                width: '48px',
                height: '49px',
            }}
            >
            <KeyboardArrowRightIcon />
            </IconButton>
        </Box>
    </Box>
  );
};

export default Testimonials;

// import React, { useState } from "react";
// import { Modal, Button, Form, Row, Col } from "react-bootstrap";
// import { useAuth } from "../../contexts/AuthContext";
// import Notification from "../Notification";
// import { auth, createUserWithEmailAndPassword, sendEmailVerification, signInWithPopup, GoogleAuthProvider } from "../../config/firebase.config";

// function SignUp({
//   showSignUpModal,
//   setShowSignUpModal,
//   setShowLoginModal,
//   showLoginModal,
//   setEmailSent
// }) {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");
//   const [showNotif, setShowNotif] = useState(false);
//   const [notifVariant, setNotifVariant] = useState("");
//   const [notifMsg, setNotifMsg] = useState("");
//   const [passwordCriteria, setPasswordCriteria] = useState({
//     minLength: false,
//     uppercase: false,
//     lowercase: false,
//     number: false,
//     symbol: false
//   });
//   const [passwordTouched, setPasswordTouched] = useState(false);

//   const { setUser } = useAuth();

//   const handlePasswordChange = (e) => {
//     const newPassword = e.target.value;
//     setPassword(newPassword);
//     setPasswordTouched(true);

//     // Validate password criteria
//     const criteria = {
//       minLength: newPassword.length >= 8,
//       uppercase: /[A-Z]/.test(newPassword),
//       lowercase: /[a-z]/.test(newPassword),
//       number: /[0-9]/.test(newPassword),
//       symbol: /[!@#$%^&*(),.?":{}|<>]/.test(newPassword)
//     };
//     setPasswordCriteria(criteria);
//   };

//   const handleSignUp = async () => {
//     try {
//       const userCredential = await createUserWithEmailAndPassword(auth, email, password);
//       await sendVerificationEmail();
//     } catch (error) {
//       console.error("Error signing up:", error);
//       setNotifMsg(`Error signing up: ${error.message}`);
//       setNotifVariant("danger");
//       setShowNotif(true);
//     }
//   };

//   const sendVerificationEmail = async () => {
//     try {
//       await sendEmailVerification(auth.currentUser);
//       setEmailSent(true);
//       setNotifMsg("Verification email sent. Please verify your email address.");
//       setNotifVariant("info");
//       setShowNotif(true);
//     } catch (error) {
//       console.error("Error sending verification email:", error);
//       throw error;
//     }
//   };

//   const handleFormSubmit = (e) => {
//     e.preventDefault();
//     if (password === confirmPassword) {
//       handleSignUp();
//     } else {
//       setNotifMsg("Passwords do not match.");
//       setNotifVariant("danger");
//       setShowNotif(true);
//     }
//   };

//   const handleGoogleSignUp = async () => {
//     try {
//       const provider = new GoogleAuthProvider();
//       const result = await signInWithPopup(auth, provider);
//       // Redirect to .workspace after successful signup

//       if(result.user.emailVerified){
//         window.location.href = "/workspace";
//         localStorage.setItem("isLoggedIn",true)
//       }

//       // window.location.href = "/workspace"; // Alternatively, use react-router-dom for routing
//     } catch (error) {
//       console.error("Error signing up with Google:", error);
//       setNotifMsg(`Error signing up with Google: ${error.message}`);
//       setNotifVariant("danger");
//       setShowNotif(true);
//     }
//   };

//   const handleCloseSignUpModal = () => {
//     setShowSignUpModal(false);
//   };

//   const handleOpenLoginModal = () => {
//     setShowSignUpModal(false);
//     setShowLoginModal(true);
//   };

//   return (
//     <>
//       <Notification
//         showNotif={showNotif}
//         setShowNotif={setShowNotif}
//         variant={notifVariant}
//       >
//         {notifMsg}
//       </Notification>

//       <Modal
//         centered
//         show={showSignUpModal}
//         onHide={handleCloseSignUpModal}
//       >
//         <Modal.Header className="justify-content-center">
//           <Modal.Title>Sign up</Modal.Title>
//         </Modal.Header>
//         <Modal.Body className="my-3">
//           <Row className="justify-content-center">
//             <Col md={10}>
//               <Form onSubmit={handleFormSubmit}>
//                 <Form.Group controlId="formBasicEmail" className="mb-3">
//                   <Form.Label>Email address</Form.Label>
//                   <Form.Control
//                     type="email"
//                     placeholder="Enter email"
//                     value={email}
//                     onChange={(e) => setEmail(e.target.value)}
//                     required
//                   />
//                 </Form.Group>
//                 <Form.Group controlId="formBasicPassword" className="mb-3">
//                   <Form.Label>Password</Form.Label>
//                   <Form.Control
//                     type="password"
//                     placeholder="Password"
//                     value={password}
//                     onChange={handlePasswordChange}
//                     required
//                   />
//                   {passwordTouched && (
//                     <div className="text-muted mt-1">
//                       <ul style={{ listStyleType: "none", padding: 0 }}>
//                         <li>
//                           {passwordCriteria.minLength ? (
//                             <span className="text-success">&#10003; Minimum 8 characters</span>
//                           ) : (
//                             <span className="text-danger">&#10005; Minimum 8 characters</span>
//                           )}
//                         </li>
//                         <li>
//                           {passwordCriteria.uppercase ? (
//                             <span className="text-success">&#10003; At least one uppercase letter</span>
//                           ) : (
//                             <span className="text-danger">&#10005; At least one uppercase letter</span>
//                           )}
//                         </li>
//                         <li>
//                           {passwordCriteria.lowercase ? (
//                             <span className="text-success">&#10003; At least one lowercase letter</span>
//                           ) : (
//                             <span className="text-danger">&#10005; At least one lowercase letter</span>
//                           )}
//                         </li>
//                         <li>
//                           {passwordCriteria.number ? (
//                             <span className="text-success">&#10003; At least one number</span>
//                           ) : (
//                             <span className="text-danger">&#10005; At least one number</span>
//                           )}
//                         </li>
//                         <li>
//                           {passwordCriteria.symbol ? (
//                             <span className="text-success">&#10003; At least one symbol</span>
//                           ) : (
//                             <span className="text-danger">&#10005; At least one symbol</span>
//                           )}
//                         </li>
//                       </ul>
//                     </div>
//                   )}
//                 </Form.Group>

//                 <Form.Group controlId="formBasicConfirmPassword" className="mb-3">
//                   <Form.Label>Confirm Password</Form.Label>
//                   <Form.Control
//                     type="password"
//                     placeholder="Confirm Password"
//                     value={confirmPassword}
//                     onChange={(e) => setConfirmPassword(e.target.value)}
//                     required
//                   />
//                 </Form.Group>
//                 <Button variant="primary" type="submit" className="w-100">
//                   Sign up
//                 </Button>
//                 <Button variant="info" className="w-100 mt-3" onClick={handleGoogleSignUp} style={{display:'flex',gap:'10px',alignItems:'center',justifyContent:'center'}}>
//                   <img style={{width:'5%'}} src={require(`../../assets/transparent-google-logo-google-logo-with-multicolored-g-and-1710875781697.png`)}/>
//                   Sign up with Google
//                 </Button>
//               </Form>
//             </Col>
//           </Row>
//         </Modal.Body>
//         <Modal.Footer className="justify-content-center">
//           <p>
//             Already a member?{" "}
//             <Button variant="link" onClick={handleOpenLoginModal}>
//               Login
//             </Button>
//           </p>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// }

// export default SignUp;

//================================ Vishnu Last Code for Signup Start =============================

// import React, { useState, useEffect } from "react";
// import { Modal, Button, Form, Row, Col, CloseButton } from "react-bootstrap";
// import { useAuth } from "../../contexts/AuthContext";
// import Notification from "../Notification";
// import {
//   auth,
//   createUserWithEmailAndPassword,
//   sendEmailVerification,
//   signInWithPopup,
//   GoogleAuthProvider,
// } from "../../config/firebase.config";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import { disposableEmailProviders } from "../../constants/disposableEmailDomains";
// import sourceUrl from "../../api/Source.api";

// function SignUp({
//   showSignUpModal,
//   setShowSignUpModal,
//   setShowLoginModal,
//   showLoginModal,
//   setEmailSent,
// }) {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");
//   const [showNotif, setShowNotif] = useState(false);
//   const [notifVariant, setNotifVariant] = useState("");
//   const [notifMsg, setNotifMsg] = useState("");
//   const [showVerificationMsg, setShowVerificationMsg] = useState(false);
//   const [verified, setVerified] = useState(false);
//   const [pageReloaded, setPageReloaded] = useState(false); // State to keep track of whether the page has been reloaded
//   const { setUser } = useAuth();
//   const token = localStorage.getItem("token");
//   const [passwordCriteria, setPasswordCriteria] = useState({
//     minLength: false,
//     uppercase: false,
//     lowercase: false,
//     number: false,
//     symbol: false,
//   });
//   const navigate = useNavigate();
//   const [passwordTouched, setPasswordTouched] = useState(false);
//   useEffect(() => {
//     const unsubscribe = auth.onAuthStateChanged((user) => {
//       if (user && user.emailVerified) {
//         setVerified(true);
//       }
//     });

//     return () => {
//       unsubscribe();
//     };
//   }, []);
//   useEffect(() => {
//     validatePassword(password);
//   }, [password]);

//   const validatePassword = (password) => {
//     const criteria = {
//       minLength: password.length >= 8,
//       uppercase: /[A-Z]/.test(password),
//       lowercase: /[a-z]/.test(password),
//       number: /[0-9]/.test(password),
//       symbol: /[!@#$%^&*(),.?":{}|<>]/.test(password),
//     };
//     setPasswordCriteria(criteria);
//   };

//   useEffect(() => {
//     if (verified && !pageReloaded) {
//       setShowLoginModal(false);
//       setPageReloaded(true);
//     }
//   }, [verified, pageReloaded]);

//   const isDisposableEmail = (email) => {
//     const emailDomain = email.split("@")[1];
//     return disposableEmailProviders.includes(emailDomain);
//   };

//   const verifyUser = async (email) => {
//     const token = localStorage.getItem("token");
//     try {
//       const response = await axios.post(
//         `${sourceUrl}/login/verify`,
//         { email },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       if (response.data.status === false) {
//         throw new Error("Only invited users will be able to use Modalx.ai");
//       }
//       return true;
//     } catch (error) {
//       setNotifMsg(error.message || "Verification failed");
//       setNotifVariant("danger");
//       setShowNotif(true);
//       return false;
//     }
//   };

//   const handleSignUp = async () => {
//     if (isDisposableEmail(email)) {
//       setNotifMsg(
//         "We do not accept temporary or disposable email addresses. Please use a permanent email."
//       );
//       setNotifVariant("danger");
//       setShowNotif(true);
//       return;
//     }

//     try {
//       const userCredential = await createUserWithEmailAndPassword(
//         auth,
//         email,
//         password
//       );
//       await sendVerificationEmail();
//       console.log("user_credentials", userCredential);
//       await axios.post(
//         `${sourceUrl}/profile`,
//         {},
//         {
//           headers: {
//             Authorization: `Bearer ${userCredential._tokenResponse.idToken}`,
//           },
//         }
//       );
//     } catch (error) {
//       console.error("Error signing up:", error);
//       setNotifMsg(`Error signing up: ${error.message}`);
//       setNotifVariant("danger");
//       setShowNotif(true);
//     }
//   };

//   const sendVerificationEmail = async () => {
//     try {
//       await sendEmailVerification(auth.currentUser);
//       setEmailSent(true);
//       handleOpenLoginModal();
//       setNotifMsg("Verification email sent. Please verify your email address.");
//       setNotifVariant("info");
//       setShowNotif(true);
//       setShowVerificationMsg(true);
//     } catch (error) {
//       console.error("Error sending verification email:", error);
//       throw error;
//     }
//   };

//   const handleAccountCreation = async () => {
//     try {
//       setShowVerificationMsg(true);
//     } catch (error) {
//       console.error("Error completing account creation:", error);
//       setNotifMsg(`Error completing account creation: ${error.message}`);
//       setNotifVariant("danger");
//       setShowNotif(true);
//     }
//   };

//   const handleGoogleSignUp = async () => {
//     try {
//       const provider = new GoogleAuthProvider();
//       const result = await signInWithPopup(auth, provider);

//       // ===================== Invited users check =====================
//       // const isVerified = await verifyUser(result.user.email);
//       // if (!isVerified) return;

//       // ===============================================================
//       if (result.user.emailVerified) {
//         {
//           result.user &&
//             result.user.accessToken &&
//             (await axios.post(
//               `${sourceUrl}/profile`,
//               {},
//               {
//                 headers: {
//                   Authorization: `Bearer ${result.user.accessToken}`,
//                 },
//               }
//             ));
//         }

//         localStorage.setItem("token", result.user.accessToken);
//         const isVerified = await verifyUser(result.user.email);
//         if (!isVerified) {
//           setShowSignUpModal(true);
//           await axios.post(
//             `${sourceUrl}/profile`,
//             {},
//             {
//               headers: {
//                 Authorization: `Bearer ${result.user.accessToken}`,
//               },
//             }
//           );
//           return;
//         }
//         localStorage.setItem("current_thread_id", "");
//         localStorage.setItem("current_history_id", "");
//         setUser(result.user.emailVerified);
//         navigate("/workspace");
//         localStorage.setItem("isLoggedIn", true);
//         localStorage.setItem("userEmail", result.user.email);
//         setShowLoginModal(false);
//         window.location.reload();
//       } else {
//         setShowLoginModal(true);
//         localStorage.setItem("isLoggedIn", "");
//       }
//     } catch (error) {
//       console.error("Error signing up with Google:", error);
//       setNotifMsg(`Error signing up with Google: ${error.message}`);
//       setNotifVariant("danger");
//       setShowNotif(true);
//     }
//   };

//   const handleFormSubmit = (e) => {
//     e.preventDefault();
//     if (password === confirmPassword) {
//       handleSignUp();
//     } else {
//       setNotifMsg("Passwords do not match.");
//       setNotifVariant("danger");
//       setShowNotif(true);
//     }
//   };

//   const handleCloseSignUpModal = () => {
//     setShowSignUpModal(false);
//   };

//   const handleCloseLoginModal = () => {
//     setShowLoginModal(false);
//   };

//   const handleOpenSignUpModal = () => {
//     setShowSignUpModal(true);
//     setShowLoginModal(false);
//   };

//   const handleOpenLoginModal = () => {
//     setShowSignUpModal(false);
//     setShowLoginModal(true);
//   };

//   return (
//     <>
//       <Notification
//         showNotif={showNotif}
//         setShowNotif={setShowNotif}
//         variant={notifVariant}
//       >
//         {notifMsg}
//       </Notification>

//       <Modal centered show={showSignUpModal} onHide={handleCloseSignUpModal}>
//         <Modal.Header className="justify-content-center">
//           <Modal.Title>Sign up</Modal.Title>
//           <CloseButton onClick={handleCloseSignUpModal} />
//         </Modal.Header>
//         <Modal.Body className="my-3">
//           {/* {showVerificationMsg && (
//           <p className="text-center mt-3" style={{color:'red'}}>Please verify your email address.</p>
//         )} */}
//           <Row className="justify-content-center">
//             <Col md={10}>
//               <Form
//                 onSubmit={handleFormSubmit}
//                 style={{ width: "75%", margin: "auto" }}
//               >
//                 <Form.Group controlId="formBasicEmail" className="mb-3">
//                   <Form.Label>Email address</Form.Label>
//                   <Form.Control
//                     type="email"
//                     placeholder="Enter email"
//                     value={email}
//                     onChange={(e) => setEmail(e.target.value)}
//                     required
//                   />
//                 </Form.Group>
//                 <Form.Group controlId="formBasicPassword" className="mb-3">
//                   <Form.Label>Password</Form.Label>
//                   <Form.Control
//                     type="password"
//                     placeholder="Password"
//                     value={password}
//                     onChange={(e) => setPassword(e.target.value)}
//                     required
//                   />
//                 </Form.Group>
//                 <Form.Group
//                   controlId="formBasicConfirmPassword"
//                   className="mb-3"
//                 >
//                   <Form.Label>Confirm Password</Form.Label>
//                   <Form.Control
//                     type="password"
//                     placeholder="Confirm Password"
//                     value={confirmPassword}
//                     onChange={(e) => setConfirmPassword(e.target.value)}
//                     required
//                   />
//                 </Form.Group>
//                 {password && (
//                   <div className="text-muted mt-1">
//                     <ul style={{ listStyleType: "none", padding: 0 }}>
//                       <li>
//                         {passwordCriteria.minLength ? (
//                           <span className="text-success">
//                             &#10003; Minimum 8 characters
//                           </span>
//                         ) : (
//                           <span className="text-danger">
//                             &#10005; Minimum 8 characters
//                           </span>
//                         )}
//                       </li>
//                       <li>
//                         {passwordCriteria.uppercase ? (
//                           <span className="text-success">
//                             &#10003; At least one uppercase letter
//                           </span>
//                         ) : (
//                           <span className="text-danger">
//                             &#10005; At least one uppercase letter
//                           </span>
//                         )}
//                       </li>
//                       <li>
//                         {passwordCriteria.lowercase ? (
//                           <span className="text-success">
//                             &#10003; At least one lowercase letter
//                           </span>
//                         ) : (
//                           <span className="text-danger">
//                             &#10005; At least one lowercase letter
//                           </span>
//                         )}
//                       </li>
//                       <li>
//                         {passwordCriteria.number ? (
//                           <span className="text-success">
//                             &#10003; At least one number
//                           </span>
//                         ) : (
//                           <span className="text-danger">
//                             &#10005; At least one number
//                           </span>
//                         )}
//                       </li>
//                       <li>
//                         {passwordCriteria.symbol ? (
//                           <span className="text-success">
//                             &#10003; At least one symbol
//                           </span>
//                         ) : (
//                           <span className="text-danger">
//                             &#10005; At least one symbol
//                           </span>
//                         )}
//                       </li>
//                     </ul>
//                   </div>
//                 )}
//                 <div>
//                   <Button variant="primary" type="submit" className="w-100">
//                     Sign up
//                   </Button>
//                   <Button
//                     variant="info"
//                     className="w-100 mt-3"
//                     onClick={handleGoogleSignUp}
//                     style={{
//                       display: "flex",
//                       gap: "10px",
//                       alignItems: "center",
//                       justifyContent: "center",
//                       backgroundColor: "#d95959",
//                       color: "white",
//                       border: "none",
//                     }}
//                   >
//                     <img
//                       style={{ width: "5%" }}
//                       src={require(`../../assets/transparent-google-logo-google-logo-with-multicolored-g-and-1710875781697.png`)}
//                     />
//                     Sign up with Google
//                   </Button>
//                   {/* <Button variant="info" className="w-100 mt-3" onClick={handleGoogleSignUp} style={{display:'flex',gap:'10px',alignItems:'center',justifyContent:'center',backgroundColor:'#d95959',color:'white',border:'none'}}>
//                   <img style={{width:'5%'}} src={require(`../../assets/—Pngtree—facebook social media icon facebook_3654772.png`)}/>
//                   Sign up with Facebook
//                 </Button> */}
//                 </div>
//               </Form>
//             </Col>
//           </Row>
//         </Modal.Body>
//         <Modal.Footer className="justify-content-center">
//           <p>
//             Already a member?{" "}
//             <Button variant="link" onClick={handleOpenLoginModal}>
//               Login
//             </Button>
//           </p>
//         </Modal.Footer>
//       </Modal>

//       <Modal centered show={showLoginModal} onHide={handleCloseLoginModal}>
//         <Modal.Header className="justify-content-center">
//           <Modal.Title>Login</Modal.Title>
//         </Modal.Header>
//         <Modal.Body className="my-3">{/* Your login form here */}</Modal.Body>
//         <Modal.Footer className="justify-content-center">
//           <p>
//             Not a member?{" "}
//             <Button variant="link" onClick={handleOpenSignUpModal}>
//               Sign up
//             </Button>
//           </p>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// }

// export default SignUp;

//========================Vishnu Last Code for Signup End=====================================

//========================Shaban Code for Signup Start=====================================

import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  FormControl,
  TextField,
  FormHelperText,
  useMediaQuery,MenuItem,CardMedia,Select,
  Input,
} from "@mui/material";
import { New_Project_Button_bg_color } from "../../Provider/Color_Provider";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { disposableEmailProviders } from "../../constants/disposableEmailDomains";
import {
  button_bg_color_for_New_Project_dm,
  uploadFiles_button_default_bg_color,
  uploadFiles_button_hovered_bg_color,
} from "../../constants/colors";

import { useAuth } from "../../contexts/AuthContext";
import {
  auth,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "../../config/firebase.config";
import sourceUrl from "../../api/Source.api";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import SignupLeftSide from "./SignupLeftSide";
import success from "../../assets/signup/success.png";
import error from "../../assets/signup/error.png";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VerifyEmailModal from "./VerifyEmailModal";
import { useTheme } from "@mui/material/styles";
import india from '../../assets/Flag/india.png'
import us from '../../assets/Flag/us.png'
import canada from '../../assets/Flag/canada.png'
import france from '../../assets/Flag/france.png'
import australia from '../../assets/Flag/australia.png'
import uae from '../../assets/Flag/united arab emirates.png'
import uk from '../../assets/Flag/united kingdom.png'
import { ErrorEvent, SignUpEvent } from "../../config/analytics";
import CommonToast from "../Common_Components/CommonToast";
import ModalXLogo from '../../assets/signup/loginPageLogo.png'
import SignUpLogInLayout from "./SignUpLogInLayout";
import { useWorkspace } from "../../contexts/WorkspaceContext";
import mailIcon from "../../assets/signup/mail.png";
import countryData from "../../constants/countryName.json";
import GetCountryFlagByName from "../workspace/settingBar/VideoGenSettings/GetCountryFlagByName";
import SearchIcon from "@mui/icons-material/Search";


const countryCodes = [
  { id: 1, code: '+61', countryName: 'Australia', flag: australia },
  { id: 2, code: '+1', countryName: 'Canada', flag: canada },
  { id: 3, code: '+33', countryName: 'France', flag: france },
  { id: 4, code: '+91', countryName: 'India', flag: india },
  { id: 5, code: '+971', countryName: 'UAE', flag: uae },
  { id: 6, code: '+44', countryName: 'UK', flag: uk },
  { id: 7, code: '+1', countryName: 'US', flag: us },
];



const countryDatas = [
  { id: 1, name: "Afghanistan", code: "AF", mobileCode: "+93" },
  { id: 2, name: "Albania", code: "AL", mobileCode: "+355" },
  { id: 3, name: "Algeria", code: "DZ", mobileCode: "+213" },
  { id: 4, name: "Andorra", code: "AD", mobileCode: "+376" },
  { id: 5, name: "Angola", code: "AO", mobileCode: "+244" },
  // { id: 6, name: "Antigua and Barbuda", code: "AG", mobileCode: "+1-268" },
  { id: 7, name: "Argentina", code: "AR", mobileCode: "+54" },
  { id: 8, name: "Armenia", code: "AM", mobileCode: "+374" },
  { id: 9, name: "Australia", code: "AU", mobileCode: "+61" },
  { id: 10, name: "Austria", code: "AT", mobileCode: "+43" },
  { id: 11, name: "Azerbaijan", code: "AZ", mobileCode: "+994" },
  { id: 12, name: "Bahamas", code: "BS", mobileCode: "+1-242" },
  { id: 13, name: "Bahrain", code: "BH", mobileCode: "+973" },
  { id: 14, name: "Bangladesh", code: "BD", mobileCode: "+880" },
  { id: 15, name: "Barbados", code: "BB", mobileCode: "+1-246" },
  { id: 16, name: "Belarus", code: "BY", mobileCode: "+375" },
  { id: 17, name: "Belgium", code: "BE", mobileCode: "+32" },
  { id: 18, name: "Belize", code: "BZ", mobileCode: "+501" },
  { id: 19, name: "Benin", code: "BJ", mobileCode: "+229" },
  { id: 20, name: "Bhutan", code: "BT", mobileCode: "+975" },
  { id: 21, name: "Bolivia", code: "BO", mobileCode: "+591" },
  { id: 22, name: "Bosnia and Herzegovina", code: "BA", mobileCode: "+387" },
  { id: 23, name: "Botswana", code: "BW", mobileCode: "+267" },
  { id: 24, name: "Brazil", code: "BR", mobileCode: "+55" },
  { id: 25, name: "Brunei", code: "BN", mobileCode: "+673" },
  { id: 26, name: "Bulgaria", code: "BG", mobileCode: "+359" },
  { id: 27, name: "Burkina Faso", code: "BF", mobileCode: "+226" },
  { id: 28, name: "Burundi", code: "BI", mobileCode: "+257" },
  { id: 29, name: "Cabo Verde", code: "CV", mobileCode: "+238" },
  { id: 30, name: "Cambodia", code: "KH", mobileCode: "+855" },
  { id: 31, name: "Cameroon", code: "CM", mobileCode: "+237" },
  { id: 32, name: "Canada", code: "CA", mobileCode: "+1" },
  { id: 33, name: "Central African Republic", code: "CF", mobileCode: "+236" },
  { id: 34, name: "Chad", code: "TD", mobileCode: "+235" },
  { id: 35, name: "Chile", code: "CL", mobileCode: "+56" },
  { id: 36, name: "China", code: "CN", mobileCode: "+86" },
  { id: 37, name: "Colombia", code: "CO", mobileCode: "+57" },
  { id: 38, name: "Comoros", code: "KM", mobileCode: "+269" },
  { id: 39, name: "Congo (Congo-Brazzaville)", code: "CG", mobileCode: "+242" },
  { id: 40, name: "Congo (Congo-Kinshasa)", code: "CD", mobileCode: "+243" },
  { id: 41, name: "Costa Rica", code: "CR", mobileCode: "+506" },
  { id: 42, name: "Croatia", code: "HR", mobileCode: "+385" },
  { id: 43, name: "Cuba", code: "CU", mobileCode: "+53" },
  { id: 44, name: "Cyprus", code: "CY", mobileCode: "+357" },
  { id: 45, name: "Czech Republic", code: "CZ", mobileCode: "+420" },
  { id: 46, name: "Denmark", code: "DK", mobileCode: "+45" },
  { id: 47, name: "Djibouti", code: "DJ", mobileCode: "+253" },
  { id: 48, name: "Dominica", code: "DM", mobileCode: "+1-767" },
  { id: 49, name: "Dominican Republic", code: "DO", mobileCode: "+1-809" },
  { id: 50, name: "Ecuador", code: "EC", mobileCode: "+593" },
  { id: 51, name: "Egypt", code: "EG", mobileCode: "+20" },
  { id: 52, name: "El Salvador", code: "SV", mobileCode: "+503" },
  { id: 53, name: "Equatorial Guinea", code: "GQ", mobileCode: "+240" },
  { id: 54, name: "Eritrea", code: "ER", mobileCode: "+291" },
  { id: 55, name: "Estonia", code: "EE", mobileCode: "+372" },
  { id: 56, name: "Eswatini (Swaziland)", code: "SZ", mobileCode: "+268" },
  { id: 57, name: "Ethiopia", code: "ET", mobileCode: "+251" },
  { id: 58, name: "Fiji", code: "FJ", mobileCode: "+679" },
  { id: 59, name: "Finland", code: "FI", mobileCode: "+358" },
  { id: 60, name: "France", code: "FR", mobileCode: "+33" },
  { id: 61, name: "Gabon", code: "GA", mobileCode: "+241" },
  { id: 62, name: "Gambia", code: "GM", mobileCode: "+220" },
  { id: 63, name: "Georgia", code: "GE", mobileCode: "+995" },
  { id: 64, name: "Germany", code: "DE", mobileCode: "+49" },
  { id: 65, name: "Ghana", code: "GH", mobileCode: "+233" },
  { id: 66, name: "Greece", code: "GR", mobileCode: "+30" },
  { id: 67, name: "Grenada", code: "GD", mobileCode: "+1-473" },
  { id: 68, name: "Guatemala", code: "GT", mobileCode: "+502" },
  { id: 69, name: "Guinea", code: "GN", mobileCode: "+224" },
  { id: 70, name: "Guinea-Bissau", code: "GW", mobileCode: "+245" },
  { id: 71, name: "Guyana", code: "GY", mobileCode: "+592" },
  { id: 72, name: "Haiti", code: "HT", mobileCode: "+509" },
  { id: 73, name: "Honduras", code: "HN", mobileCode: "+504" },
  { id: 74, name: "Hungary", code: "HU", mobileCode: "+36" },
  { id: 75, name: "Iceland", code: "IS", mobileCode: "+354" },
  { id: 76, name: "India", code: "IN", mobileCode: "+91" },
  { id: 77, name: "Indonesia", code: "ID", mobileCode: "+62" },
  { id: 78, name: "Iran", code: "IR", mobileCode: "+98" },
  { id: 79, name: "Iraq", code: "IQ", mobileCode: "+964" },
  { id: 80, name: "Ireland", code: "IE", mobileCode: "+353" },
  { id: 81, name: "Israel", code: "IL", mobileCode: "+972" },
  { id: 82, name: "Italy", code: "IT", mobileCode: "+39" },
  { id: 83, name: "Jamaica", code: "JM", mobileCode: "+1-876" },
  { id: 84, name: "Japan", code: "JP", mobileCode: "+81" },
  { id: 85, name: "Jordan", code: "JO", mobileCode: "+962" },
  { id: 86, name: "Kazakhstan", code: "KZ", mobileCode: "+7" },
  { id: 87, name: "Kenya", code: "KE", mobileCode: "+254" },
  { id: 88, name: "Kiribati", code: "KI", mobileCode: "+686" },
  { id: 89, name: "Kuwait", code: "KW", mobileCode: "+965" },
  { id: 90, name: "Kyrgyzstan", code: "KG", mobileCode: "+996" },
  { id: 91, name: "Laos", code: "LA", mobileCode: "+856" },
  { id: 92, name: "Latvia", code: "LV", mobileCode: "+371" },
  { id: 93, name: "Lebanon", code: "LB", mobileCode: "+961" },
  { id: 94, name: "Lesotho", code: "LS", mobileCode: "+266" },
  { id: 95, name: "Liberia", code: "LR", mobileCode: "+231" },
  { id: 96, name: "Libya", code: "LY", mobileCode: "+218" },
  { id: 97, name: "Liechtenstein", code: "LI", mobileCode: "+423" },
  { id: 98, name: "Lithuania", code: "LT", mobileCode: "+370" },
  { id: 99, name: "Luxembourg", code: "LU", mobileCode: "+352" },
  { id: 100, name: "Madagascar", code: "MG", mobileCode: "+261" },
  { id: 101, name: "Malawi", code: "MW", mobileCode: "+265" },
  { id: 102, name: "Malaysia", code: "MY", mobileCode: "+60" },
  { id: 103, name: "Maldives", code: "MV", mobileCode: "+960" },
  { id: 104, name: "Mali", code: "ML", mobileCode: "+223" },
  { id: 105, name: "Malta", code: "MT", mobileCode: "+356" },
  { id: 106, name: "Marshall Islands", code: "MH", mobileCode: "+692" },
  { id: 107, name: "Mauritania", code: "MR", mobileCode: "+222" },
  { id: 108, name: "Mauritius", code: "MU", mobileCode: "+230" },
  { id: 109, name: "Mexico", code: "MX", mobileCode: "+52" },
  { id: 110, name: "Micronesia", code: "FM", mobileCode: "+691" },
  { id: 111, name: "Moldova", code: "MD", mobileCode: "+373" },
  { id: 112, name: "Monaco", code: "MC", mobileCode: "+377" },
  { id: 113, name: "Mongolia", code: "MN", mobileCode: "+976" },
  { id: 114, name: "Montenegro", code: "ME", mobileCode: "+382" },
  { id: 115, name: "Morocco", code: "MA", mobileCode: "+212" },
  { id: 116, name: "Mozambique", code: "MZ", mobileCode: "+258" },
  { id: 117, name: "Myanmar", code: "MM", mobileCode: "+95" },
  { id: 118, name: "Namibia", code: "NA", mobileCode: "+264" },
  { id: 119, name: "Nauru", code: "NR", mobileCode: "+674" },
  { id: 120, name: "Nepal", code: "NP", mobileCode: "+977" },
  { id: 121, name: "Netherlands", code: "NL", mobileCode: "+31" },
  { id: 122, name: "New Zealand", code: "NZ", mobileCode: "+64" },
  { id: 123, name: "Nicaragua", code: "NI", mobileCode: "+505" },
  { id: 124, name: "Niger", code: "NE", mobileCode: "+227" },
  { id: 125, name: "Nigeria", code: "NG", mobileCode: "+234" },
  { id: 126, name: "North Korea", code: "KP", mobileCode: "+850" },
  { id: 127, name: "North Macedonia", code: "MK", mobileCode: "+389" },
  { id: 128, name: "Norway", code: "NO", mobileCode: "+47" },
  { id: 129, name: "Oman", code: "OM", mobileCode: "+968" },
  { id: 130, name: "Pakistan", code: "PK", mobileCode: "+92" },
  { id: 131, name: "Palau", code: "PW", mobileCode: "+680" },
  { id: 132, name: "Panama", code: "PA", mobileCode: "+507" },
  { id: 133, name: "Papua New Guinea", code: "PG", mobileCode: "+675" },
  { id: 134, name: "Paraguay", code: "PY", mobileCode: "+595" },
  { id: 135, name: "Peru", code: "PE", mobileCode: "+51" },
  { id: 136, name: "Philippines", code: "PH", mobileCode: "+63" },
  { id: 137, name: "Poland", code: "PL", mobileCode: "+48" },
  { id: 138, name: "Portugal", code: "PT", mobileCode: "+351" },
  { id: 139, name: "Qatar", code: "QA", mobileCode: "+974" },
  { id: 140, name: "Romania", code: "RO", mobileCode: "+40" },
  { id: 141, name: "Russia", code: "RU", mobileCode: "+7" },
  { id: 142, name: "Rwanda", code: "RW", mobileCode: "+250" },
  { id: 143, name: "Saint Kitts and Nevis", code: "KN", mobileCode: "+1-869" },
  { id: 144, name: "Saint Lucia", code: "LC", mobileCode: "+1-758" },
  { id: 145, name: "Saint Vincent and the Grenadines", code: "VC", mobileCode: "+1-784" },
  { id: 146, name: "Samoa", code: "WS", mobileCode: "+685" },
  { id: 147, name: "San Marino", code: "SM", mobileCode: "+378" },
  { id: 148, name: "Sao Tome and Principe", code: "ST", mobileCode: "+239" },
  { id: 149, name: "Saudi Arabia", code: "SA", mobileCode: "+966" },
  { id: 150, name: "Senegal", code: "SN", mobileCode: "+221" },
  { id: 151, name: "Serbia", code: "RS", mobileCode: "+381" },
  { id: 152, name: "Seychelles", code: "SC", mobileCode: "+248" },
  { id: 153, name: "Sierra Leone", code: "SL", mobileCode: "+232" },
  { id: 154, name: "Singapore", code: "SG", mobileCode: "+65" },
  { id: 155, name: "Slovakia", code: "SK", mobileCode: "+421" },
  { id: 156, name: "Slovenia", code: "SI", mobileCode: "+386" },
  { id: 157, name: "Solomon Islands", code: "SB", mobileCode: "+677" },
  { id: 158, name: "Somalia", code: "SO", mobileCode: "+252" },
  { id: 159, name: "South Africa", code: "ZA", mobileCode: "+27" },
  { id: 160, name: "South Korea", code: "KR", mobileCode: "+82" },
  { id: 161, name: "South Sudan", code: "SS", mobileCode: "+211" },
  { id: 162, name: "Spain", code: "ES", mobileCode: "+34" },
  { id: 163, name: "Sri Lanka", code: "LK", mobileCode: "+94" },
  { id: 164, name: "Sudan", code: "SD", mobileCode: "+249" },
  { id: 165, name: "Suriname", code: "SR", mobileCode: "+597" },
  { id: 166, name: "Sweden", code: "SE", mobileCode: "+46" },
  { id: 167, name: "Switzerland", code: "CH", mobileCode: "+41" },
  { id: 168, name: "Syria", code: "SY", mobileCode: "+963" },
  { id: 169, name: "Tajikistan", code: "TJ", mobileCode: "+992" },
  { id: 170, name: "Tanzania", code: "TZ", mobileCode: "+255" },
  { id: 171, name: "Thailand", code: "TH", mobileCode: "+66" },
  { id: 172, name: "Timor-Leste", code: "TL", mobileCode: "+670" },
  { id: 173, name: "Togo", code: "TG", mobileCode: "+228" },
  { id: 174, name: "Tonga", code: "TO", mobileCode: "+676" },
  { id: 175, name: "Trinidad and Tobago", code: "TT", mobileCode: "+1-868" },
  { id: 176, name: "Tunisia", code: "TN", mobileCode: "+216" },
  { id: 177, name: "Turkey", code: "TR", mobileCode: "+90" },
  { id: 178, name: "Turkmenistan", code: "TM", mobileCode: "+993" },
  { id: 179, name: "Tuvalu", code: "TV", mobileCode: "+688" },
  { id: 180, name: "Uganda", code: "UG", mobileCode: "+256" },
  { id: 181, name: "Ukraine", code: "UA", mobileCode: "+380" },
  { id: 182, name: "United Arab Emirates", code: "AE", mobileCode: "+971" },
  { id: 183, name: "United Kingdom", code: "GB", mobileCode: "+44" },
  { id: 184, name: "United States", code: "US", mobileCode: "+1" },
  { id: 185, name: "Uruguay", code: "UY", mobileCode: "+598" },
  { id: 186, name: "Uzbekistan", code: "UZ", mobileCode: "+998" },
  { id: 187, name: "Vanuatu", code: "VU", mobileCode: "+678" },
  { id: 188, name: "Vatican City", code: "VA", mobileCode: "+379" },
  { id: 189, name: "Venezuela", code: "VE", mobileCode: "+58" },
  { id: 190, name: "Vietnam", code: "VN", mobileCode: "+84" },
  { id: 191, name: "Yemen", code: "YE", mobileCode: "+967" },
  { id: 192, name: "Zambia", code: "ZM", mobileCode: "+260" },
  { id: 193, name: "Zimbabwe", code: "ZW", mobileCode: "+263" }
];




const Signup = () => {
  const [phone , setPhone] = useState('')
  const [password, setPassword] = useState("");
  const [maskedPassword, setMaskedPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showNotif, setShowNotif] = useState(false);
  const [notifVariant, setNotifVariant] = useState("");
  const [notifMsg, setNotifMsg] = useState("");
  const [showVerificationMsg, setShowVerificationMsg] = useState(false);
  const [verified, setVerified] = useState(false);
  const [pageReloaded, setPageReloaded] = useState(false);
  const { setUser } = useAuth();
  const token = localStorage.getItem("token");
  const [passwordCriteria, setPasswordCriteria] = useState({
    minLength: false,
    uppercase: false,
    lowercase: false,
    number: false,
    symbol: false,
  });
  const navigate = useNavigate();
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  // const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isPhoneNumber , setIsPhoneNumber] = useState(null)
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [open, setOpen] = React.useState(false);
  const isBelow991 = useMediaQuery("(max-width:991px)");
  const { setShowLoginModal, setShowSignUpModal,showVerificationModal,setShowVerificationModal, currentWindow, setCurrentWindow} = useAuth()
   const { email, setEmail} = useWorkspace()
   const [canResend, setCanResend] = useState(true);
  const [timer, setTimer] = useState(0);
  const [selectedCountryCode, setSelectedCountryCode] = useState("+1"); 
  const [mobileNumber , setMobileNumber] = useState('')
  const [selectedCountryObject, setSelectedCountryObject] = useState({ id: 32, name: "Canada", code: "CA", mobileCode: "+1" },);
  const [searchCountry, setSearchCountry] = useState("");
  const [allFilterCountry, setAllFilterCountry] = useState();

  useEffect(()=>{
    setAllFilterCountry(countryDatas)
  },[])

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user && user.emailVerified) {
        setVerified(true);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);
  useEffect(() => {
    validatePassword(password);
  }, [password]);
  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else {
      setCanResend(true);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const validatePasswords = (pwd, confirmPwd) => {
    if (confirmPwd.length === 0) {
      setPasswordsMatch(true);
    } else {
      setPasswordsMatch(pwd === confirmPwd);
    }
  };

  const handlePasswordChange = (event) => {
    const input = event.target.value;
    let updatedPassword;
  
    if (showPassword) {
      updatedPassword = input;
    } else {
      if (input.length < password.length) {
        updatedPassword = password.slice(0, input.length);
      } else {
        updatedPassword = password + input[input.length - 1];
      }
    }
  
    setPassword(updatedPassword);
    setMaskedPassword('*'.repeat(updatedPassword.length));
    validatePasswords(updatedPassword, confirmPassword);
  };

  const validatePassword = (password) => {
    const criteria = {
      minLength: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      symbol: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    };
    setPasswordCriteria(criteria);
  };

  const handleToggleVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (verified && !pageReloaded) {
      setPageReloaded(true);
    }
  }, [verified, pageReloaded]);

  const verifyUser = async (email) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${sourceUrl}/login/verify`,
        { email },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status === false) {
        throw new Error("Only invited users will be able to use Modalx.ai");
      }
      return true;
    } catch (error) {
      setNotifMsg(error.message || "Verification failed");
      setNotifVariant("error");
      setShowNotif(true);
      setOpen(true);
      return false;
    }
  };

  const isDisposableEmail = (email) => {
    const emailDomain = email.split("@")[1];
    return disposableEmailProviders.includes(emailDomain);
  };

  const handleEmailChange = (e) =>{
    const newEmail = e.target.value;
    setEmail(newEmail);
        if (isDisposableEmail(newEmail)) {
          setIsInvalidEmail(true);
        } else {
          setIsInvalidEmail(false);
        }
  }

  const CheckPhoneNumber = (number) =>{
    const phoneNumberRegex = /^\+?[1-9]\d{1,14}$/;
    return phoneNumberRegex.test(number)
  }

  // const [toggle, setToggle] = useState(false); // Toggle state for showing InputProps
  // const onMousEnter = () =>{
  //   setToggle(true)
  // }
  // const onMouseLeve = () =>{
  //   setToggle(false)
  // }
  
  const handleNumberChange = (event) => {
    setIsPhoneNumber(null)
    let value = event.target.value;
    // Remove any existing country code from the input and re-apply the selected country code
    // if (value.startsWith(selectedCountryCode)) {
    //   value = value.slice(selectedCountryCode.length);
    // }
    // setPhone(`${selectedCountryCode}${value}`);
    
    setMobileNumber(value)
    setPhone(`${selectedCountryObject?.mobileCode}${value}`);
  };

  console.log("phone", phone)
  // console.log(phone)

    // Handle country code change
    const [hide , setHide] = useState(false)
    
    // const handleCountryCodeChange = (event) => {
    //   const CountryObject = event.target.value;
    //   console.log("countryObject", CountryObject)
    //   setSelectedCountryObject(CountryObject)
    //   const newCode = CountryObject.code;
    //   setHide(true)
    //   // Update selected country code and adjust phone number accordingly
    //   if (phone.startsWith(selectedCountryCode)) {
    //     setPhone(newCode + phone.slice(selectedCountryCode.length));
    //   } else {
    //     setPhone(newCode + phone);
    //   }
    //   setSelectedCountryCode(newCode);
    // };

    const handleCountryCodeChange = (country) =>{
      // const CountryObject = event.target.value;
      // console.log("CountryObject", country)
      setSelectedCountryObject(country)
      setSelectedCountryCode(country.mobileCode)
    }
    // const handleClick = () => {
    //   if (!phone.startsWith(selectedCountryCode)) {
    //     setPhone(`${selectedCountryCode}${phone}`);
    //   }
    // };

  const handleSignUp = async () => {
    if (isDisposableEmail(email)) {
      setNotifMsg(
        "We do not accept temporary or disposable email addresses. Please use a permanent email."
      );
      setNotifVariant("error");
      setShowNotif(true);
      setOpen(true);
      return;
    }
    if(phone== "" || !CheckPhoneNumber(phone) || phone.length <=10){
      setIsPhoneNumber(true)
      setNotifMsg("Mobile number is required and must be valid.");
      setNotifVariant("error");
      setShowNotif(true);
      setOpen(true);
      return;
    }
    if(password == "" ){
      setIsPhoneNumber(true)
      setNotifMsg("Password is required and must be valid.");
      setNotifVariant("error");
      setShowNotif(true);
      setOpen(true);
      return;
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
       await sendVerificationEmail();
      // console.log("user_credentials", userCredential);
       const res = await axios.post(`${sourceUrl}/profile`,
        {
          mobile_number : phone,
          firstName : firstName,
          lastName : lastName,  
        },
        {
          headers: {
            Authorization: `Bearer ${userCredential._tokenResponse.idToken}`,
          },
        }
      );
      SignUpEvent("user_signup", "signup_with_email")
    } catch (error) {
      console.log(error, "error");
      ErrorEvent(error.status,error.message);
      console.error("Error signing up:", error);
      setNotifMsg(error.message);
      setShowLoginModal(true);
      setNotifVariant("error");
      setShowNotif(true);
      setOpen(true);
    }
  };

  const handleOpenVerificationModal = () =>{
    setShowVerificationModal(true);
    setShowSignUpModal(false)
  }

  const sendVerificationEmail = async () => {
    try {
      await sendEmailVerification(auth.currentUser);
      // setEmailSent(true);
      // handleOpenLoginModal();
      setCanResend(false);
      setTimer(30);
      handleOpenVerificationModal();
      setNotifMsg("Verification email sent. Please verify your email address.");
      setNotifVariant("success");
      setShowNotif(true);
      setOpen(true)
      setCurrentWindow(3);
    } catch (error) {
      ErrorEvent(error, error.message);
      console.error("Error sending verification email:", error.message);
      setNotifMsg(error.message);
      setNotifVariant("error");
      setShowNotif(true);
      setOpen(true)
      throw error;
    }
  };

  const handleAccountCreation = async () => {
    try {
      setShowVerificationMsg(true);
    } catch (error) {
      console.error("Error completing account creation:", error);
      setNotifMsg(`Error completing account creation: ${error.message}`);
      setNotifVariant("error");
      setShowNotif(true);
      setOpen(true);
    }
  };

  const handleCloseToast = () => {
    setOpen(false);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if(firstName === "" ){
      setFirstNameError(true)
      return
    }else if(lastName === ""){
      setLastNameError(true)
      return
    }else if(mobileNumber === ""){
      setIsPhoneNumber(true)
      return
    } else {
      handleSignUp();
    }
  };

  const handleOpenLoginModal = () => {
    setCurrentWindow(1)
    navigate('/login')
  };

  // const handleCloseSignUpModal = () => {
  //   if(!isBelow991){
  //     setShowSignUpModal(false);
  //   }
  // };
  const customTextFieldStyles = {
    "& .MuiInputLabel-root": {
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
      letterSpacing: "0.5px",
      color: "rgba(255, 255, 255, 0.80)",
      "&.Mui-focused": {
        color: "rgba(255, 255, 255, 0.80) !important",
      },
    },
    "& .MuiInputBase-input": {
      color: "#FFFFFF",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(255, 255, 255, 0.80)",
      },
      "&:hover fieldset": {
        borderColor: "rgba(255, 255, 255, 0.80)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(255, 255, 255, 0.80) !important",
      },
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiInputLabel-root": {
      color: "rgba(255, 255, 255, 0.80) !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(255, 255, 255, 0.80) !important",
    },
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "rgba(255, 255, 255, 0.40)",
    },
    "& .MuiInputLabel-root.Mui-disabled": {
      color: "rgba(255, 255, 255, 0.40)", // New color
    },
  };

  const customTextFieldStylesForInvalid = (isInvalid) => {
  return ( 
    {"& .MuiInputLabel-root": {
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0.5px",
    color:  "rgba(255, 255, 255, 0.80)",
    "&.Mui-focused": {
      color: isInvalid ? "#F2B8B5 !important" : "rgba(255, 255, 255, 0.80)",
    },
  },
  "& .MuiInputBase-input": {
    color: "#FFFFFF",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: isInvalid ? "#F2B8B5" : "rgba(255, 255, 255, 0.80)",
    },
    "&:hover fieldset": {
      borderColor: isInvalid ? "#F2B8B5" : "rgba(255, 255, 255, 0.80)",
    },
    "&.Mui-focused fieldset": {
      borderColor: isInvalid ? "#F2B8B5 !important" : "rgba(255, 255, 255, 0.80) ",
    },
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiInputLabel-root": {
    color: isInvalid ? "#F2B8B5 !important" : "rgba(255, 255, 255, 0.80) ",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: isInvalid ? "#F2B8B5 !important" : "rgba(255, 255, 255, 0.80) ",},
  "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "rgba(255, 255, 255, 0.40)",
  },
  })
  }

  // const [countryCode, setCountryCode] = useState('in'); // initialize with empty string or default

  // useEffect(() => {
  //   axios.get('https://ipinfo.io?token=<your_ipinfo_token>').then(response => {
  //     const country = response.data.country.toLowerCase(); // returns country code, e.g., "us"
  //     setCountryCode(country);
  //   }).catch(error => {
  //     console.log('Error fetching country code', error);
  //   });
  // }, []);

   const HandleGoBackClickOnSignUpPage = () =>{
    setCurrentWindow(1);
   }

   const handleUseDiffrentButtonClick = () =>{
    setEmail("");
    setPassword("");
    setFirstName("")
    setLastName("")
    setPhone('')
    setMobileNumber('')
    setCurrentWindow(1);
   }

   const handleResendEmailClick = async () =>{
    //write a lofic to resend verfication link again
    try{
      const res = await sendVerificationEmail();
      // console.log('Verification email sent', res)
    }catch(error){
      console.error("Error resending verification email:", error.message);
      setNotifMsg(`Error resending verification email`);
      setNotifVariant("error");
      setShowNotif(true);
    }
   }

   const handleSearch = (e) => {
    e.stopPropagation()
    const value = e.target.value.toLowerCase();
    setSearchCountry(value);
    const filtered = countryDatas.filter(country => 
      country.name.toLowerCase().includes(value)
    );
    setAllFilterCountry(filtered);
};

  return (
    <>
     {/* <div style={{position: isBelow991 ? "" :"absolute", zIndex:50, top: isBelow991 ? "0%" : "50%", marginTop: isBelow991 ? "0%" :"-40px"  , left:isBelow991 ? "0%" :"50%",transform: isBelow991 ? "" : "translate(-50%, -0%)",  backgroundColor:"#171A2D"}}> */}
      {
        <div
          style={{
            display: "flex",
            width: "100vw",
            height: isBelow991 ? "" : "100vh",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            zIndex: 10,
          }}
        >
          <Box
            onClick={(event) => event.stopPropagation()}
            sx={{
              display: "flex",
              flexDirection: isBelow991 ? "column-reverse" : "row",
              height: "100%",
              width: "100%",
              // width:isBelow991 ? "100%" : "90%",
              // maxWidth:isBelow991 ? "342px" : "1200px",
              // height: "40.875rem",
              // borderRadius: "8px",
              // border: isBelow991 ? "" : "1px solid #CAC4D0",
            }}
          >
          <Box
              sx={{
                // backgroundColor: isBelow991 ? "#171A2D" : "#282D51",
                backgroundColor: "#171A2D",
                width: isBelow991 ? "100%": "50%",
                height: "100%",
                borderRadius: "0px 8px 8px 0px ",
                display: "flex",
                flexDirection: "column",
                // justifyContent: "center",
                alignItema: "center",
                padding: {
                  xs: "0px 0px",
                  sm: "0px 0px",
                  md: "0px 0px",
                  lg: "0px 141px",
                  xl: "0px 141px",
                },
              }}
            >
            {
            currentWindow === 1 &&
           <SignUpLogInLayout setCurrentWindow={setCurrentWindow} route={"signup"}/>
            }
            {
              currentWindow === 2 && 
              <div style={{ maxWidth: "340px", display:"flex", flexDirection:"column", alignSelf:"center", }}>
                  <Box sx={{display:"flex", justifyContent:"center", alignItems:"center",marginTop: isBelow991 ? "40px" : "60px", marginBottom: isBelow991 ? "40px" : "80px"}}>
                    <img src={ModalXLogo} alt={"logo"} style={{width:"163px", height:"51px"}}/>
                  </Box>
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    fontFamily: "Inter",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "20px",
                    color: "#FFFFFF",
                    marginBottom: "40px",
                    alignSelf: "stretch",
                    whiteSpace: "nowrap",
                  }}
                >
                  Create an account
                </Typography>
                <FormControl
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                
                  <TextField disabled={true}
                    type="email"
                    value={email}
                    id="outlined-basic"
                    label="Email address*"
                    variant="outlined"
                    sx={customTextFieldStyles}
                    style={{
                      width: "100%", 
                      maxWidth :  "340px",
                      height: "56px",
                      color: "#FFFFFF",
                      backgroundColor: "#232746",
                      borderRadius: "8px",
                      boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.40)",
                      placeholderfontsize: "16px",
                    }}
                  />
                  <FormHelperText
                    sx={{
                      color: isInvalidEmail ? "#F2B8B5" : "var(--M3-sys-dark-on-surface-variant, var(--Schemes-Outline-Variant, #CAC4D0))",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "16px",
                      // margin:  isBelow991 ?  "4px 0px 14px 16px":"4px 0px 14px 16px",
                      alignSelf: "start",
                      marginBottom: "40px"
                    }}
                  >
                    {isInvalidEmail ? "Email address is invalid" : ""}
                  </FormHelperText>


                  <Box sx={{display:"flex", flexDirection:"row", gap:"15px", marginBottom: firstNameError || lastNameError ? "10px" : "40px"}}>
                  <Box>
                  <TextField
                    type="text"
                    onChange={(e)=>{setFirstName(e.target.value); setFirstNameError(false)}}
                    value={firstName}
                    id="outlined-basic"
                    label="First Name*"
                    variant="outlined"
                    sx={()=>customTextFieldStylesForInvalid(firstNameError)}
                    style={{
                      width: "100%", 
                      maxWidth :  isBelow991 ? "100%" : "300px",
                      height: "56px",
                      color: "#FFFFFF",
                      backgroundColor: "#232746",
                      borderRadius: "8px",
                      boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.40)",
                      placeholderfontsize: "16px",
                    }}
                  />
                  <FormHelperText
                    sx={{
                      color: firstNameError ? "#F2B8B5" : "var(--M3-sys-dark-on-surface-variant, var(--Schemes-Outline-Variant, #CAC4D0))",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "16px",
                      margin:  isBelow991 ? ( firstNameError ? "4px 0px 14px 16px" : "4px 0px 0px 16px" ):(firstNameError ? "4px 0px 14px 16px" : "4px 0px 0px 16px"),
                      alignSelf: "start",
                    }}
                  >
                    {firstNameError ? "Enter First Name" : ""}
                  </FormHelperText>
                  </Box>
                  <Box>
                <TextField
                    type="text"
                    onChange={(e)=>{setLastName(e.target.value); setLastNameError(false)}}
                    value={lastName}
                    id="outlined-basic"
                    label="Last Name*"
                    variant="outlined"
                    sx={()=>customTextFieldStylesForInvalid(lastNameError)}
                    style={{
                      width: "100%", 
                      maxWidth :  isBelow991 ? "100%" : "300px",
                      height: "56px",
                      color: "#FFFFFF",
                      backgroundColor: "#232746",
                      borderRadius: "8px",
                      boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.40)",
                      placeholderfontsize: "16px",
                    }}
                  />
                  <FormHelperText
                    sx={{
                      color: lastNameError ? "#F2B8B5" : "var(--M3-sys-dark-on-surface-variant, var(--Schemes-Outline-Variant, #CAC4D0))",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "16px",
                      margin:  isBelow991 ?  ( lastNameError ? "4px 0px 14px 16px" : "4px 0px 0px 16px" ):(lastNameError ? "4px 0px 14px 16px" : "4px 0px 0px 16px"),
                      alignSelf: "start",
                    }}
                  >
                    {lastNameError ? "Enter Last Name" : ""}
                  </FormHelperText>
                  </Box>
                </Box>

                  
                  <TextField
                    type="tel"
                    onChange={handleNumberChange}
                    // onMouseEnter={onMousEnter}
                    // onMouseLeave={onMouseLeve}
                    // onTouchStart={onMousEnter} // For mobile touch interaction
                    // onTouchEnd={onMouseLeve}   // For mobile touch interaction
                    value={mobileNumber}
                    // onClick={handleClick} // Ensure country code is prepended when clicked
                    label="Phone number*"
                    variant="outlined"
                    sx={()=>customTextFieldStylesForInvalid(isPhoneNumber)}
                    placeholder="Phone number*"
                    // InputProps={toggle ? { 
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //       <TextField  
                    //       // onChange={()=>{setSelectedCountryObject(country)}}
                    //         select
                    //         value={selectedCountryCode}
                    //         onChange={handleCountryCodeChange}
                    //         variant="standard"
                    //         style={{ width: '50px' }} // Adjust width to accommodate flags
                    //         SelectProps={{
                    //           MenuProps: {
                    //             PaperProps: {
                    //               sx: {
                    //                 bgcolor: '#282D51',
                    //                 '& .MuiMenuItem-root.Mui-selected': {
                    //                   bgcolor: '#373E6D',
                    //                   color: 'white',
                    //                   '&:hover': {
                    //                     bgcolor: '#393F69',
                    //                   },
                    //                 },
                    //               },
                    //             },
                    //           },
                    //         }}
                    //       > 
                    //           {countryCodes.map((country) => (
                                
                    //           <MenuItem key={country.id} 
                    //             value={country.code} 
                    //             sx={{
                    //             bgcolor : '#282D51',
                    //             marginTop : '-8px',
                    //             marginBottom : '-8px',
                    //             width : '200px',
                    //             height : '56px',
                    //             '&:hover' :{
                    //               bgcolor : '#393F69'
                    //             },
                    //             '&:selected':{
                    //               bgcolor:"green"
                    //             }                  
                    //           }}>
                    //             <Box  sx={{display : 'flex'}}>
                    //             <Box sx={{
                    //               display : 'flex',
                    //               flexDirection:"row",
                    //               width : '24px',
                    //               height : '24px',
                    //               marginRight : '12px',
                    //               columnGap:"10px"
                    //             }}>
                    //             <CardMedia value={country.flag} 
                    //               component="img" // Can also be "video" or other media types        
                    //               image={country.flag} // Replace with your image URL
                    //               alt="flag"
                    //             />
                    //             </Box>
                    //             <Typography sx={{
                    //             display : hide ? 'none' : 'block',
                    //             color : "white", 
                    //             fontFamily : 'Inter',
                    //             fontSize : '14px',
                    //             textAlign : 'left',
                    //             opacity : '80%'
                    //             }}>{country.countryName} </Typography>
                    //             <Typography component='span'
                    //             sx={{
                    //             color : "white", 
                    //             fontFamily : 'Inter',
                    //             fontSize : '14px',
                    //             textAlign : 'left',
                    //             opacity : '80%',
                    //             marginLeft : '5px'
                    //             }}>{country.code}</Typography>
                    //             </Box>
                    //           </MenuItem>
                    //         ))}
                    //       </TextField>
                    //     </InputAdornment>
                    //   )
                    // } : null}
                    InputProps={{ 
                      startAdornment: (
                        <InputAdornment position="start" sx={{padding:"0px"}}>
                          <Select
                          // onChange={()=>{setSelectedCountryObject(country)}}
                            select
                            value={selectedCountryObject?.id || ''}
                            // onChange={(e)=>handleCountryCodeChange(e)}
                            variant="standard"
                            style={{ width: '80px', height:"40px", padding:"0px" }} // Adjust width to accommodate flags
                            renderValue={(selectedId) => {
                              const country = countryDatas.find(c => c.id === selectedId);
                              console.log("country", country)
                              return (
                                <Box sx={{display:"flex", flexDirection:"row", columnGap:"10px", alignItems:"center", textAlign:"center"}}>
                                {/* <img 
                                  src={country.flag} 
                                  alt="flag" 
                                  style={{ width: '24px', height: '24px' }} 
                                /> */}
                                  <GetCountryFlagByName countryName={country?.name} />
                                <p style={{margin:"0px", color:"rgba(255, 255, 255, 0.80)"}}>{country?.mobileCode}</p>
                                </Box>
                              );
                            }}
                            sx={{
                              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                              },
                              '&:hover:not(.Mui-disabled):before': {
                                border: 'none',
                              },
                              '&::before': {
                                borderBottom: 'none',
                              },
                              '&::after': {
                                borderBottom: 'none',
                              },
                            }}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  backgroundColor: '#282D51',
                                  color: 'white',
                                  // Add other custom styles here
                                },
                              },
                            }}
                            SelectProps={{
                              MenuProps: {
                                PaperProps: {
                                  sx: {
                                    bgcolor:  '#282D51',
                                    padding:"0px",
                                    '& .MuiMenuItem-root.Mui-selected': {
                                      bgcolor: '#282D51',
                                      color: 'white',
                                      // width:"200px",
                                      '&:hover': {
                                        bgcolor: '#393F69',
                                      },
                                    },
                                  },
                                },
                                onClose: (event) => {
                                  // Prevent closing if the user clicked inside the search input
                                  if (event?.target?.id === "search-country-input") {
                                      event.stopPropagation();
                                  }
                              }
                              },
                            }}
                          > 
                          <Box sx={{border:"", height:"180px", width:"220px", overflowX:"hidden" , overflowY:"scroll", color:"#282D51", bgcolor:"#282D51", padding:"0px"}}>
                              {/* {countryCodes.map((country) => ( */}
                            <Box sx={{ p: 1, borderBottom: "1px solid rgba(255,255,255,0.1)"}}>
                    <Input
                        value={searchCountry}
                        onChange={handleSearch}
                        placeholder="Search country"
                        onMouseDown={(e) => e.stopPropagation()}
                        onClick={(e) => e.stopPropagation()} 
                        startAdornment={
                            <InputAdornment position="start">
                                <SearchIcon sx={{ color: "rgba(255,255,255,0.7)" }} />
                            </InputAdornment>
                        }
                        sx={{
                            color: "white",
                            '&:before, &:after': { borderBottom: 'none' },
                            '&:hover:not(.Mui-disabled):before': { borderBottom: 'none' },
                        }}
                        disableUnderline={false}
                    />
                </Box>
                              {allFilterCountry?.map((country) => (
                                
                              <MenuItem onClick={()=>handleCountryCodeChange(country)}
                                key={country?.id} 
                                value={country} 
                                sx={{
                                bgcolor : country?.id == selectedCountryObject?.id ? "#373E6D" : '#282D51',
                                // marginTop : '-8px',
                                // marginBottom : '-8px',
                                width : '220px',
                                height : '48px',
                                alignItems:"center",
                                '&:hover' :{
                                  bgcolor : '#393F69'
                                },
                                "&.Mui-selected": {
                                  backgroundColor: "#373E6D !important", 
                                  color: "white",
                                },                  
                              }}>
                                <Box  sx={{display : 'flex', alignItems:"center"}}>
                                <Box sx={{
                                  display : 'flex',
                                  flexDirection:"row",
                                  width : '24px',
                                  height : '21px',
                                  // marginRight : '12px',
                                  columnGap:"10px",
                                  alignItems:"center",
                                }}>
                                {/* <CardMedia value={country.flag} 
                                  component="img" // Can also be "video" or other media types        
                                  image={country.flag} // Replace with your image URL
                                  alt="flag"
                                /> */}
                                <Box sx={{display:"flex", height:"21px", alignItems:"center", marginBottom:"2px"}}>
                                 <GetCountryFlagByName countryName={country?.name} />
                                </Box>
                                <Typography sx={{
                                display : hide ? 'none' : 'flex',
                                color : "white", 
                                fontFamily : 'Inter',
                                fontSize : '14px',
                                textAlign : 'left',
                                opacity : '80%',
                                height:"21px",
                                alignItems:"center",
                                }}>{country.name} </Typography>
                                <Typography component='span'
                                sx={{
                                color : "white", 
                                fontFamily : 'Inter',
                                fontSize : '14px',
                                textAlign : 'left',
                                opacity : '80%',
                                marginLeft : '5px',
                                height:"21px",
                                alignItems:"center",
                                }}>{country.mobileCode}</Typography>
                                </Box>
                                </Box>
                              </MenuItem>
                            ))}
                          </Box>
                          </Select>
                        </InputAdornment>
                      )
                    }}
                    style={{
                      width: "100%", 
                      maxWidth :  "340px",
                      height: "56px",
                      color: "#FFFFFF",
                      backgroundColor: "#232746",
                      borderRadius: "8px",
                      boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.40)",
                      placeholderfontsize: "16px",
                      // marginBottom : '20px'
                    }}
                  />
                  <FormHelperText
                    sx={{
                      color: isPhoneNumber ? "#F2B8B5" : "var(--M3-sys-dark-on-surface-variant, var(--Schemes-Outline-Variant, #CAC4D0))",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "16px",
                      margin:  isBelow991 ?  ( isPhoneNumber ? "4px 0px 14px 16px" : "4px 0px 0px 16px" ):(isPhoneNumber ? "4px 0px 14px 16px" : "4px 0px 0px 16px"),
                      alignSelf: "start",
                    }}
                  >
                    {isPhoneNumber ? "Please enter a valid phone number." : ""}
                  </FormHelperText>

                
                <Box sx={{width:"340px", marginTop:isPhoneNumber ? "10px" : "40px"}}>
                  <TextField
                    type={"text"} 
                    onChange={handlePasswordChange}
                    value={showPassword ? password : maskedPassword} 
                    id="outlined-basic"
                    label="Create a password*"
                    variant="outlined"
                    sx={ customTextFieldStylesForInvalid(false)}        
                    style={{
                      width: "100%", 
                      maxWidth :  "340px",
                      height: "56px",
                      color: "#FFFFFF",
                      backgroundColor: "#232746",
                      borderRadius: "8px",
                      boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.40)",
                      placeholderfontsize: "16px",
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleToggleVisibility}
                            edge="end"
                            aria-label="toggle password visibility"
                            tabIndex={-1}
                            
                          >
                            {showPassword ?  <Visibility sx={{color:"#CAC4D0"}}/> : <VisibilityOff  sx={{color:"#CAC4D0"}}/> }
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  /> 
                  { 
                     password ? 
                      <Box style={{padding:"4px 16px 0px 16px",display:"flex", flexDirection:"column", alignSelf:"flex-start"}}>
                                          <ul style={{ listStyleType: "none", padding: 0,  display:"flex", flexDirection:"column", gap:"4px",color:"#FFFFFF", fontSize:"12px", fontWeight:400, lineHeight:"16px" }}>
                                            <li >
                                              {passwordCriteria.minLength ? (
                                                <div style={{display:"flex", flexDirection:"row", gap:"4px", height:"16px"}}>
                                                  <img width="14px" height="14px" src={success} alt=" "/>
                                                  <span>8 characters minimum</span>
                                                </div>
                                              ) : (
                                                <div style={{display:"flex", flexDirection:"row", gap:"4px", alignItems:"center",height:"16px"}}>
                                                   <img  width="14px" height="14px" src={error} alt=" "/>
                                                   <span>8 characters minimum</span>
                                                </div>
                                              )}
                                            </li>

                                            <li>
                                              {passwordCriteria.uppercase ? (
                                                <div style={{display:"flex", flexDirection:"row", gap:"4px",  alignItems:"center", height:"16px"}}>
                                                  <img width="14px" height="14px" src={success} alt=" "/>
                                                  <span>At least one uppercase letter</span>
                                                </div>
                                              ) : (
                                                <div style={{display:"flex", flexDirection:"row", gap:"4px",  alignItems:"center", height:"16px"}}>
                                                   <img  width="14px" height="14px" src={error} alt=" "/>
                                                   <span>At least one uppercase letter</span>
                                                </div>
                                              )}
                                            </li>

                                            <li>
                                              {passwordCriteria.lowercase ? (
                                                <div style={{display:"flex", flexDirection:"row", gap:"4px",alignItems:"center"}}>
                                                  <img width="14px" height="14px" src={success} alt=" "/>
                                                  <span>At least one lowercase letter</span>
                                                </div>
                                              ) : (
                                                <div style={{display:"flex", flexDirection:"row", gap:"4px",  alignItems:"center"}}>
                                                   <img  width="14px" height="14px" src={error} alt=" "/>
                                                   <span>At least one lowercase letter</span>
                                                </div>
                                              )}
                                            </li>

                                            <li>
                                              {passwordCriteria.number ? (
                                                <div style={{display:"flex", flexDirection:"row", gap:"4px",  alignItems:"center"}}>
                                                  <img width="14px" height="14px" src={success} alt=" "/>
                                                  <span>At least one number</span>
                                                </div>
                                              ) : (
                                                <div style={{display:"flex", flexDirection:"row", gap:"4px", alignItems:"center"}}>
                                                   <img  width="14px" height="14px" src={error} alt=" "/>
                                                   <span>At least one number</span>
                                                </div>
                                              )}
                                            </li>

                                            <li>
                                              {passwordCriteria.symbol ? (
                                                <div style={{display:"flex", flexDirection:"row", gap:"4px",  alignItems:"center"}}>
                                                  <img width="14px" height="14px" src={success} alt=" "/>
                                                  <span>At least one symbol</span>
                                                </div>
                                              ) : (
                                                <div style={{display:"flex", flexDirection:"row", gap:"4px", alignItems:"center"}}>
                                                   <img  width="14px" height="14px" src={error} alt=" "/>
                                                   <span>At least one symbol</span>
                                                </div>
                                              )}
                                            </li>
                                          </ul>
                      </Box>
                     
                      : 
                    <FormHelperText
                    sx={{
                      color:
                        "var(--M3-sys-dark-on-surface-variant, var(--Schemes-Outline-Variant, #CAC4D0))",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "16px",
                      alignSelf: "start",
                      margin:  isBelow991 ?  "4px 16px 40px 16px":"4px 16px 40px 16px",
                    }}
                  >
                    8 characters minimum
                  </FormHelperText>
                  }
                </Box>

                  <Box sx={password !=="" ? {marginBottom:"27px"} : {marginBottom:"50px"}}>
                    <Button
                      onClick={handleFormSubmit}
                      variant="outlined"
                      // onClick={handleNewChat}
                      // disabled={!isLoggedIn}
                      sx={{
                        gap: "8px",
                        padding: "10px 24px 10px 16px",
                        fontSize: "16px",
                        width: "340px", 
                        textTransform: "none",
                        bgcolor: '#F6BE6B',
                        color: "black",
                        fontWeight: "600",
                        height: "40px",
                        fontFamily: "Inter, sans-serif",
                        marginBottom:"28px",
                        "&:hover": {
                          bgcolor: "#ffd9aa",
                          border : 'none'
                        },
                        border : 'none'
                      }}
                    >
                      Continue
                    </Button>
                    <Typography onClick={HandleGoBackClickOnSignUpPage}
                      sx={{
                        display: "flex",
                        fontFamily: "Inter",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "20px",
                        color: "rgba(255, 255, 255, 1);",
                        padding: "auto auto",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        cursor:"pointer",
                        '&:hover': {
                          color:"rgba(255, 255, 255, 0.8)"
                        }
                      }}
                    >
                      Go Back
                    </Typography>
                    <FormHelperText
                  sx={{
                    display: isBelow991 ? "flex" :"flex",
                    justifyContent: isBelow991 ? "center" :"center",
                    width: "100%", 
                    maxWidth :  isBelow991 ? "100%" : "300px",
                    color:
                      "var(--M3-sys-dark-on-surface-variant, var(--Schemes-Outline-Variant, #CAC4D0))",
                    fontFamily: "Inter",
                    fontSize: '16px',
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "20px",
                    alignSelf: "center",
                    textAlign: "center",
                    whiteSpace:"nowrap",
                    marginTop : password ? "38px" : "60px"

                  }}
                >
                  Already have an account?{" "}
                  <span
                    onClick={handleOpenLoginModal}
                    style={{
                      textDecorationLine: "underline",
                      cursor: "pointer",
                      
                    }}
                  >
                    Log in
                  </span>

                </FormHelperText>
                  </Box>
                 
                </FormControl>

              </div>
            }
            {
              currentWindow === 3 && 
              <Box sx={{ marginTop: isBelow991 ? "40px" : "60px", display: "flex", flexDirection:"column", alignItems:"center", justifyContent:"center" }}>
                 <Box sx={{display:"flex", justifyContent:"center", alignItems:"center", marginBottom: isBelow991 ? "40px" : "80px"}}>
                    <img src={ModalXLogo} alt={"logo"} style={{width:"163px", height:"51px"}}/>
                  </Box>
                <Box sx={{maxWidth:"340px",}}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent:  "center",
                  }}
                >
                  <Box
                    style={{
                      width: "80px",
                      height: "80px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50%",
                      backgroundColor: "rgba(255, 255, 255,0.05)",
                      opacity: 0.8,
                    }}
                  >
                    <img src={mailIcon} alt="mailIcon" style={{width:"40px", height:"40px"}}/>
                  </Box>
                </Box>
      
                <Box
                  sx={{
                    width: "100%",
                    marginTop: "40px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "20px",
                      fontWeight: 600,
                      lineHeight: "20px",
                      alignSelf: "stretch",
                      textAlign:  "center",
                      margin: "0px 0px",
                    }}
                  >
                    Verify your Email address
                  </Typography>
                </Box>
      
                <Box
                  sx={{
                    marginTop: "24px",
                    width: "300px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "20px",
                      textAlign:  "center",
                    }}
                  >
                    {`We have sent a verification link on ${email}. If you don't receive it shortly, please click Resend Mail.`}
                  </Typography>
                </Box>
      
                <Box
                  sx={{
                    marginTop: "48px",
                    width: "100%",
                  }}
                >
                 <Button disabled = {!canResend}
                    variant="contained"
                    sx={{
                      bgcolor: canResend ? New_Project_Button_bg_color : "rgba(230, 224, 233, 0.12)",
                      color: canResend ?  "rgba(23, 24, 44, 1)" : "rgba(230, 224, 233, 1)",
                      padding: "10px 24px 10px 24px",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: 600,
                      lineHeight: "20px",
                      letterSpacing: "0.1px",
                      textAlign: "center",
                      width: "100%",
                      textTransform:"none",
                      "&:hover": {
                        bgcolor: "#ffd9aa",
                         border : 'none'
                      }, 
                      '&:disabled':{
                        color:"rgba(230, 224, 233, 1)",
                        bgcolor:"rgba(230, 224, 233, 0.12)"
                      } 

                    }}
                    onClick={handleResendEmailClick}
                  >
                      {canResend ?  "Resend Email" : `Resend email (${String(timer).padStart(2, '0')})`}
                  </Button>
                </Box>
    
            <Box
              onClick={()=>{handleUseDiffrentButtonClick()}}
              sx={{
                width: "100%",
                marginTop: "24px",
                cursor: "pointer",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: 600,
                  lineHeight: "20px",
                  alignSelf: "stretch",
                  textAlign: "center",
                }}
              >
                Use a different email address
              </Typography>
            </Box>
            {/* <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  alignSelf: "stretch",
                  textAlign: "center",
                  marginTop:"20px"
                }}
              >
               {`Remaining Time: 00 : ${String(timer).padStart(2, '0')}`}

              </Typography> */}
            <FormHelperText
                  sx={{
                    display: isBelow991 ? "flex" :"flex",
                    justifyContent: isBelow991 ? "center" :"center",
                    width: "100%", 
                    maxWidth :  isBelow991 ? "100%" : "300px",
                    color:
                      "var(--M3-sys-dark-on-surface-variant, var(--Schemes-Outline-Variant, #CAC4D0))",
                    fontFamily: "Inter",
                    fontSize: '16px',
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "20px",
                    alignSelf: "center",
                    textAlign: "center",
                    whiteSpace:"nowrap",
                    marginTop : '80px',
                    marginBottom:"20px"
                  }}
                >
                  Verified email successfully?{" "}
                  <span
                    onClick={handleOpenLoginModal}
                    style={{
                      textDecorationLine: "underline",
                      cursor: "pointer",
                      
                    }}
                  >
                    Log in
                  </span>

                </FormHelperText>
            </Box>
    
              </Box>
            }
          </Box>
            <Box
                sx={{
                  backgroundColor:isBelow991 ? "" : "#1F233E",
                  width: isBelow991 ? "100%": "50%",
                  borderRadius: "16px 16px 16px 16px ",
                  display:isBelow991 ? currentWindow === 1 ? "flex" : "none" : "flex",
                  justifyContent:"center",
                  alignItems:"center",
                  margin: isBelow991? "0px" : "20px 20px 20px 20px ",
                }}
              >
               <SignupLeftSide /> 
            </Box>
          </Box>
        </div>
      }
      <CommonToast 
        open={open}
        onClose={handleCloseToast}
        content={notifMsg}
        bgColor="#282D51"
        fontColor="#fff"
        fontSize="14px"
        fontWeight="500"
        autoHideDuration={3000}
        iconType = {notifVariant}
      />
      {/* </div> */}
    </>
  );
};
export default Signup;

//========================Shaban Code for Signup End=====================================

// import React, { useState, useEffect } from "react";
// import { Modal, Button, Form, Row, Col } from "react-bootstrap";
// import { useAuth } from "../../contexts/AuthContext";
// import Notification from "../Notification";
// import { auth, createUserWithEmailAndPassword, sendEmailVerification, signInWithPopup, GoogleAuthProvider } from "../../config/firebase.config";

// function SignUp({
//   showSignUpModal,
//   setShowSignUpModal,
//   setShowLoginModal,
//   showLoginModal
// }) {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");
//   const [showNotif, setShowNotif] = useState(false);
//   const [notifVariant, setNotifVariant] = useState("");
//   const [notifMsg, setNotifMsg] = useState("");
//   const [showVerificationMsg, setShowVerificationMsg] = useState(false);
//   const [verified, setVerified] = useState(false);
//   const [pageReloaded, setPageReloaded] = useState(false); // State to keep track of whether the page has been reloaded
//   const { setUser } = useAuth();

//   useEffect(() => {
//     const unsubscribe = auth.onAuthStateChanged((user) => {
//       if (user && user.emailVerified) {
//         setVerified(true);
//       }
//     });

//     return () => {
//       unsubscribe();
//     };
//   }, []);

//   useEffect(() => {
//     if (verified && !pageReloaded) {
//       setShowLoginModal(true);
//       setPageReloaded(true);
//     }
//   }, [verified, pageReloaded]);

//   const handleSignUp = async () => {
//     try {
//       const userCredential = await createUserWithEmailAndPassword(auth, email, password);
//       await sendVerificationEmail();
//       setNotifMsg("Verification email sent. Please verify your email address.");
//       setNotifVariant("info");
//       setShowNotif(true);
//       setShowVerificationMsg(true);
//     } catch (error) {
//       console.error("Error signing up:", error);
//       setNotifMsg(`Error signing up: ${error.message}`);
//       setNotifVariant("danger");
//       setShowNotif(true);
//     }
//   };

//   const sendVerificationEmail = async () => {
//     try {
//       await sendEmailVerification(auth.currentUser);
//     } catch (error) {
//       console.error("Error sending verification email:", error);
//       throw error;
//     }
//   };

//   const handleAccountCreation = async () => {
//     try {
//       setShowVerificationMsg(true);
//     } catch (error) {
//       console.error("Error completing account creation:", error);
//       setNotifMsg(`Error completing account creation: ${error.message}`);
//       setNotifVariant("danger");
//       setShowNotif(true);
//     }
//   };

//   const handleGoogleSignUp = async () => {
//     try {
//       const provider = new GoogleAuthProvider();
//       const result = await signInWithPopup(auth, provider);
//       handleAccountCreation(result.user);
//     } catch (error) {
//       console.error("Error signing up with Google:", error);
//       setNotifMsg(`Error signing up with Google: ${error.message}`);
//       setNotifVariant("danger");
//       setShowNotif(true);
//     }
//   };

//   const handleFormSubmit = (e) => {
//     e.preventDefault();
//     if (password === confirmPassword) {
//       handleSignUp();
//     } else {
//       setNotifMsg("Passwords do not match.");
//       setNotifVariant("danger");
//       setShowNotif(true);
//     }
//   };

//   const handleCloseSignUpModal = () => {
//     setShowSignUpModal(false);
//   };

//   const handleCloseLoginModal = () => {
//     setShowLoginModal(false);
//   };

//   const handleOpenSignUpModal = () => {
//     setShowSignUpModal(true);
//     setShowLoginModal(false);
//   };

//   const handleOpenLoginModal = () => {
//     setShowLoginModal(true);
//     setShowSignUpModal(false);
//   };

//   return (
//     <>
//       <Notification
//         showNotif={showNotif}
//         setShowNotif={setShowNotif}
//         variant={notifVariant}
//       >
//         {notifMsg}
//       </Notification>

//       <Modal
//         centered
//         show={showSignUpModal}
//         onHide={handleCloseSignUpModal}
//       >
//         <Modal.Header className="justify-content-center">
//           <Modal.Title>Sign up</Modal.Title>
//         </Modal.Header>
//         <Modal.Body className="my-3">
//         {showVerificationMsg && (
//           <p className="text-center mt-3" style={{color:'red'}}>Please verify your email address.</p>
//         )}
//           <Row className="justify-content-center">
//             <Col md={10}>
//               <Form onSubmit={handleFormSubmit}>
//                 <Form.Group controlId="formBasicEmail" className="mb-3">
//                   <Form.Label>Email address</Form.Label>
//                   <Form.Control
//                     type="email"
//                     placeholder="Enter email"
//                     value={email}
//                     onChange={(e) => setEmail(e.target.value)}
//                     required
//                   />
//                 </Form.Group>
//                 <Form.Group controlId="formBasicPassword" className="mb-3">
//                   <Form.Label>Password</Form.Label>
//                   <Form.Control
//                     type="password"
//                     placeholder="Password"
//                     value={password}
//                     onChange={(e) => setPassword(e.target.value)}
//                     required
//                   />
//                 </Form.Group>
//                 <Form.Group controlId="formBasicConfirmPassword" className="mb-3">
//                   <Form.Label>Confirm Password</Form.Label>
//                   <Form.Control
//                     type="password"
//                     placeholder="Confirm Password"
//                     value={confirmPassword}
//                     onChange={(e) => setConfirmPassword(e.target.value)}
//                     required
//                   />
//                 </Form.Group>
//                 <Button variant="primary" type="submit" className="w-100">
//                   Sign up
//                 </Button>
//                 <Button variant="danger" className="w-100 mt-3" onClick={handleGoogleSignUp}>
//                   Sign up with Google
//                 </Button>
//               </Form>
//             </Col>
//           </Row>
//         </Modal.Body>
//         <Modal.Footer className="justify-content-center">
//           <p>
//             Already a member?{" "}
//             <Button variant="link" onClick={handleOpenLoginModal}>
//               Login
//             </Button>
//           </p>
//         </Modal.Footer>
//       </Modal>

//       <Modal
//         centered
//         show={showLoginModal}
//         onHide={handleCloseLoginModal}
//       >
//         <Modal.Header className="justify-content-center">
//           <Modal.Title>Login</Modal.Title>
//         </Modal.Header>
//         <Modal.Body className="my-3">
//           {/* Your login form here */}
//         </Modal.Body>
//         <Modal.Footer className="justify-content-center">
//           <p>
//             Not a member?{" "}
//             <Button variant="link" onClick={handleOpenSignUpModal}>
//               Sign up
//             </Button>
//           </p>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// }

// export default SignUp;

// import React, { useState } from "react";
// import { Col, Container, Row, Modal, Card } from "react-bootstrap";
// import Button from "react-bootstrap/Button";
// import { useWorkspace } from "../../../../contexts/WorkspaceContext";
// import sourceUrl from "../../../../api/Source.api";

// const AvatarSettings = () => {
//   const [openAvatarModel, setOpenAvatarModel] = useState(false);
//   const [avatars, setAvatars] = useState([]);
//   const { threadSettings, setThreadSettings,videoAvatar,setVideoAvatar } = useWorkspace();
//   const token = localStorage.getItem('token')

//   const handleAvatarClick = async () => {
//     try {
//       const response = await fetch(`${sourceUrl}/video/avatar`,{
//         headers:{
//           "Authorization":`Bearer ${token}`
//         }
//       });
//       if (!response.ok) {
//         throw new Error("Failed to fetch avatars");
//       }
//       const json = await response.json();
//       setAvatars(json["data"]["data"]);
//       setOpenAvatarModel(true);
//     } catch (error) {
//       console.error("Error fetching avatars:", error.message);
//       // Handle error (e.g., show error message)
//     }
//   };

//   const handleAvatarSelect = (avatar) => {
//     setOpenAvatarModel(false);
//     // Store selected avatar setting in threadSettings
//     setVideoAvatar(avatar.id);
//     setThreadSettings((prevSettings) => ({
//       ...prevSettings,
//       synthesiaAvatar: avatar, // Assuming 'name' is the property that should be stored
//     }));
//   };

//   const AvatarModel = () => (
//     <Modal
//       show={openAvatarModel}
//       onHide={() => setOpenAvatarModel(false)}
//       size="lg"
//       centered
//     >
//       <Modal.Header closeButton>
//         <Modal.Title>Avatars</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <Container fluid>
//           {avatars && avatars.length === 0 ? (
//             <p>No data available!</p>
//           ) : (
//             <Row xs={4}>
//               {avatars && avatars.map((avatar) => {
//                 return (
//                   <Col key={avatar.id}>
//                     <Card
//                       className="mb-3"
//                       onClick={() => handleAvatarSelect(avatar)}
//                       style={{ cursor: "pointer",backgroundColor:'#e3b966'}}
//                     >
//                       <Card.Img variant="top" src={avatar.image_url} />
//                       <Card.Body className="d-flex align-items-center justify-content-center">
//                         <Card.Title style={{color:'black'}}>{avatar.name}</Card.Title>
//                       </Card.Body>
//                     </Card>
//                   </Col>
//                 );
//               })}
//             </Row>
//           )}
//         </Container>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button variant="secondary" onClick={() => setOpenAvatarModel(false)}>
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );

//   return (
//     <>
//       <Button
//         variant="outline-secondary"
//         className="mb-2 d-flex align-items-center justify-content-start"
//         onClick={handleAvatarClick}
//       >
//         <i className="bi bi-person-bounding-box pe-3" />
//         Avatar{" "}
//         {threadSettings &&
//         threadSettings.synthesiaAvatar &&
//         threadSettings.synthesiaAvatar.name
//           ? `(${threadSettings.synthesiaAvatar.name})`
//           : ""}
//       </Button>
//       <AvatarModel />
//     </>
//   );
// };

// export default AvatarSettings;

import React, { useState, useEffect, useRef } from "react";
import { Grid, Card, CardContent, CardMedia, Typography, CircularProgress, Box, Input, InputAdornment, Button, Popover } from "@mui/material";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import sourceUrl from "../../../../api/Source.api";
import { defaultSettings_button_bg_color, hovered_chat_bg_color, settingsModal_bg_color_dm, uploadFiles_button_default_bg_color, uploadFiles_button_hovered_bg_color } from "../../../../constants/colors";
import SearchIcon from '@mui/icons-material/Search';
import YourAvatar from '../../../../assets/PersonalAvatarIcon/Personal Avatar.png'
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from "@mui/icons-material/Delete";
import { DeleteAvatar, handleDeleteAvatarButtonClick } from "../../../../contexts/WorkspaceUtils";
import { fetchAvatars } from "./videoSettingsAPI";
import CommanModalForConformation from "../../../Common_Components/CommanModalForConformation";
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import {Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";

const AvatarSettings = () => {
  // const [avatars, setAvatars] = useState([]);
  const theme = localStorage.getItem("theme");
  const [selectedAvatar, setSelectedAvatar] = useState(null); // Track selected avatar
  const [inputBoxValue, setInputBoxValue] = useState("");
  const [filterAvatars, setFilterAvatars] = useState(null)
  // const [personalAvatar, setPersonalAvatar] = useState(true)
  const [processingAvatarRequest, setprocessingAvatarRequest] = useState(true)
  const [debouncedValue, setDebouncedValue] = useState("");
  const [hoverForDeleteButton,setHoverForDeleteButton] = useState(false);
  const [hoveredCardIndex, setHoveredCardIndex] = useState(null);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("Delete avatar?");
  const [modalMessage, setModalMessage] = useState("You will no longer see this avatar in the list, and you will not be able to use it for generating videos. This action cannot be undone. Are you sure you want to delete this avatar?");
  const [moreoption, setMoreOption] = useState(false);
  const deleteAvatarId = useRef(null)
  const { threadSettings, setThreadSettings, setVideoAvatar, avatars, setAvatars, avatarsloading, setAvatarsLoading, showVideoSettings,showPersonalAvatarModal,setShowPersonalAvatarModal,personalAvatar,setPersonalAvatar } = useWorkspace();
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchAvatars = async () => {
      setAvatarsLoading(true)
      try {
        const response = await fetch(`${sourceUrl}/video/avatar`, {
          headers: { "Authorization": `Bearer ${token}` }
        });
        if (!response.ok) {
          throw new Error("Failed to fetch avatars");
        }
        const json = await response.json();
        setAvatars(json["data"]["data"]);

      } catch (error) {
        console.error("Error fetching avatars:", error.message);
      }
      setAvatarsLoading(false)
    };
    if(avatars?.length === 0) {
      fetchAvatars();
    }
  }, [token]);

  const handleAvatarSelect = (avatar) => {
    setSelectedAvatar(avatar); // Update selected avatar
    setVideoAvatar(avatar.id);
    setThreadSettings((prevSettings) => ({
      ...prevSettings,
      synthesiaAvatar: avatar,
    }));
  };

  const handlePersonalAvatarSelect = (avatar) => {
    setSelectedAvatar(avatar); // Update selected avatar
    setVideoAvatar(avatar.id);
    setThreadSettings((prevSettings) => ({
      ...prevSettings,
      synthesiaAvatar: avatar,
    }));
  }

  const handleConfirm =async () =>{
    const res = await handleDeleteAvatarButtonClick(deleteAvatarId.current)
    setOpenConfirmModal(false)
  }

  const handleCancel = () =>{
    deleteAvatarId.current = null
    setMoreOption(null)
    setOpenConfirmModal(false)
  }

  const handleDeleteAvatarButtonClick =async () =>{
      const res = await DeleteAvatar(deleteAvatarId.current);
      console.log(res?.data)
      if(res.status){
        fetchAvatars(setAvatars, setAvatarsLoading, setPersonalAvatar)
      }else{
        console.log('Failed to delete avatar');
      }
  }

  const customTextFieldStyles = {
    "& .MuiInputLabel-root": {
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
      letterSpacing: "0.5px",
      color: "rgba(255, 255, 255, 0.80)",
      "&.Mui-focused": {
        color: "rgba(255, 255, 255, 0.80) !important",
      },
    },
    "& .MuiInputBase-input": {
      color: "#FFFFFF",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      backgroundColor: "#232746", 
      "& fieldset": {
        borderColor: "rgba(255, 255, 255, 0.80)",
      },
      "&:hover fieldset": {
        borderColor: "rgba(255, 255, 255, 0.80)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(255, 255, 255, 0.80) !important",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent) !important",
    },
  };

  

  const handleFilter = (value) => {
    const filterValue = avatars?.filter((item, i)=> item?.name?.toLowerCase().includes(value.toLowerCase()))
    setFilterAvatars(filterValue)
  };
      // Debounce logic using useEffect
      useEffect(() => {
        if(showVideoSettings){
          const handler = setTimeout(() => {
            setDebouncedValue(inputBoxValue);
            handleFilter(inputBoxValue);
          }, []);
          return () => {
            clearTimeout(handler);
          };
        }
    
}, [inputBoxValue]);

const handleClickMoreOption = (event) => {
  setMoreOption(event.currentTarget); // Get button element
}; 

const renderFilterData = (filterAvatars) =>{
  return (
    filterAvatars?.map((avatar) => (
      <Grid
        item
        key={avatar.id}
        sx={{
          width: '140px',
          height: '174px',
          margin: 1, // Adds a margin around each grid item
          boxSizing: 'border-box', // Ensures padding is included in the width/height
        }}
      >
        <Card
          onClick={() => handleAvatarSelect(avatar)}
          sx={{
            cursor: "pointer",
            backgroundColor: defaultSettings_button_bg_color,
            border: selectedAvatar?.id === avatar.id ? "5px solid orange" : "none", // Apply border if selected
            height: '100%', // Ensure the card takes the full height
            padding:'10px',
          }}
        >
          <CardMedia
            component="img"
            alt={avatar.name}
            height="100" // Adjust the image height within the card
            image={avatar.image_url}
          />
          <CardContent sx={{ padding: '8px', textAlign: 'center'}}>
            <Typography variant="body2" component="div" color='white' fontSize='14px' fontWeight='600'>
              {avatar.name}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    ))
  )
}

const handleClose = () => {
  setMoreOption(null);
};

  return (
    <>
        <Box sx={{paddingTop:"20px",}}>
        <Typography  component="p" style={{ fontSize: '16px', fontWeight: '500', marginBottom:"20px", fontFamily:"Inter" }}>
        Select Avatar
      </Typography></Box>
       <Box sx={{marginTop:"0px", padding:"0px"}}>
         <Typography sx={{color:"#ffffff", fontFamily:"Inter", fontSize: '14px', fontStyle:"normal", fontWeight: 500, lineHeight:"20px", marginBottom:"16px" }}> Personal avatars </Typography>
         
            {/* <Card
              // onClick={() => handleAvatarSelect(avatar)}
              sx={{
                cursor: "pointer",
                backgroundColor: defaultSettings_button_bg_color,
                // border: selectedAvatar?.id === avatar.id ? "5px solid orange" : "none", // Apply border if selected
                height: '100%', // Ensure the card takes the full height
                padding:'10px',
              }}
            >
              <CardMedia
                component="img"
                alt={"pa"}
                height="100" // Adjust the image height within the card
                sx={{filter:"blur(8px)"}}
                image={YourAvatar}
              />
              <CardContent sx={{ padding: '8px', textAlign: 'center'}}>
                <Typography variant="body2" component="div" color='white' fontSize='14px' fontWeight='600'>
                Your Avatar
                </Typography>
              </CardContent>
            </Card> */}
            {
            !personalAvatar &&
            <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between", border:"1px solid rgba(255, 255, 255, 0.20)", borderRadius:"16px", padding:"16px"}}>
              <Box sx={{display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
              <Box sx={{display:"flex", flexDirection:"column", rowGap:"12px"}}>
              <Box sx={{display:"flex", flexDirection:"row", columnGap:"12px"}}>
                  <Typography component="p" sx={{color:"#ffffff", fontFamily:"Inter", fontSize: '16px', fontStyle:"normal", fontWeight: 500, lineHeight:"normal"}}> Create your personal AI avatar </Typography>
                   <Box
                                      sx={{
                                        bgcolor: "rgba(246, 190, 107, 0.20)",
                                        border: "1px solid #F6BE6B",
                                        borderRadius: "4px",
                                        margin: "0px",
                                        padding: "0px",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontFamily: "Inter",
                                          fontSize: "12px",
                                          fontStyle: "normal",
                                          fontWeight: 600,
                                          lineHeight: "16px",
                                          letterSpacing: "0.1px",
                                          color: "#F6BE6B",
                                          padding: "2px 4px",
                                          margin: "0px",
                                        }}
                                      >
                                        New
                                      </Typography>
                    </Box>
              </Box>
              <Typography sx={{color:'rgba(255, 255, 255, 0.80)', fontFamily:"Inter" ,fontSize:'14px',fontStyle:"normal", fontWeight:400, lineHeight:"normal"}}>Create lifelike, high quality videos with your personal digital AI clone </Typography>
              </Box>
              <Button
                            onClick={()=>{ setShowPersonalAvatarModal(true)}}
                            variant="contained"
                            sx={{
                              backgroundColor:
                                theme === "dark"
                                  ? uploadFiles_button_default_bg_color
                                  : "",
                              color: "black",
                              width: "156px",
                              height: "40px",
                              borderRadius: "8px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "10px 12px",
                              "&:hover": {
                                backgroundColor: uploadFiles_button_hovered_bg_color,
                              },
                            }}
                          >
                            <Box
                              sx={{
                                height: "40px",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontFamily: "Inter",
                                  fontSize: "14px",
                                  fontWeight: 600,
                                  lineHeight: "20px",
                                  letterSpacing: "0.1",
                                  textAlign: "center",
                                  color: "#17182C",
                                  gap: "8px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  textTransform: "none",
                                  padding: "10px 0px",
                                }}
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path d="M5.62498 4.2L3.74998 5.25L4.79998 3.375L3.74998 1.5L5.62498 2.55L7.49998 1.5L6.44998 3.375L7.49998 5.25L5.62498 4.2ZM14.625 11.55L16.5 10.5L15.45 12.375L16.5 14.25L14.625 13.2L12.75 14.25L13.8 12.375L12.75 10.5L14.625 11.55ZM16.5 1.5L15.45 3.375L16.5 5.25L14.625 4.2L12.75 5.25L13.8 3.375L12.75 1.5L14.625 2.55L16.5 1.5ZM10.005 9.585L11.835 7.755L10.245 6.165L8.41498 7.995L10.005 9.585ZM10.7775 5.4675L12.5325 7.2225C12.825 7.5 12.825 7.9875 12.5325 8.28L3.77998 17.0325C3.48748 17.325 2.99998 17.325 2.72248 17.0325L0.967483 15.2775C0.674983 15 0.674983 14.5125 0.967483 14.22L9.71998 5.4675C10.0125 5.175 10.5 5.175 10.7775 5.4675Z" fill="#17182C"/>
                              </svg>
                               
                                <Box sx={{ margin: "0px", padding: "0px" }}>
                                Create avatar
                                </Box>
                              </Typography>
                            </Box>
              </Button>
              </Box>
              <Box sx={{margin:"0px", padding:"0px", display:"flex", flexDirection:"column", rowGap:"8px"}}>
                    <CardMedia sx={{height:"92px", width:"98px"}}
                      component="img"
                      alt={"Your avatar"}
                      image={YourAvatar}
                    />
                    <CardContent sx={{ padding: '0px', textAlign: 'center', margin:"0px", height:"14px"}}>
                      <Typography sx={{color:"#CAC4D0",fontFamily:"Inter",  fontSize:'11px', fontStyle:"normal" , fontWeight:600, lineHeight:"normal", letterSpacing:"0.082px"}}> Your avatar</Typography>
                    </CardContent>
              </Box>
            </Box>
            }
            <Box sx={{display:"flex", flexDirection:"row", flexWrap:"wrap"}}>
            { personalAvatar &&
              personalAvatar?.map((avatar, index)=>{
                return(
              <Box>
                <Grid  onMouseEnter={()=>{ setHoverForDeleteButton(true); setHoveredCardIndex(index)}} onMouseLeave={()=>{setHoverForDeleteButton(false); setHoveredCardIndex(null)}} 
                 item
                 key={index+1}
                 sx={{
                   width: '140px',
                   height: '174px',
                   margin: 1, // Adds a margin around each grid item
                   boxSizing: 'border-box', // Ensures padding is included in the width/height
                 }}
               >          
              <Box sx={{position:"relative"}}>
              <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between", position:"absolute",  zIndex:"1000",top:"10px"}}>
               {
                (avatar.status === "validating" || avatar.status === "failed" || avatar.status === 'started' ) ? 
                 <Box sx={{display:"flex", position:"absolute", border: avatar.status == "failed" ? "1px solid rgba(221, 32, 37, 1)" : "1px solid #CAC4D0", backgroundColor: avatar.status == "failed" ? "rgba(221, 32, 37, 0.20)" : "rgba(202, 196, 208, 0.20)", width:"74px", height:"20px", borderRadius:"4px", color: avatar.status == "failed" ? "rgba(221, 32, 37, 1)" : "rgba(202, 196, 208, 1)", fontFamily:"Inter", fontSize:"12px", fontStyle:"normal", fontWeight:600, lineHeight:"16px", letterSpacing:"0.1px", top:"10px", left:"10px", zIndex:20, textAlign:"center", justifyContent:"center"}}>
                 {  avatar.status == "failed" ? "Failed" : "Processing"}
                </Box>
                :
                null
               }
               {
                hoverForDeleteButton && hoveredCardIndex === index  && 
                <Box
                // onClick={()=>{deleteAvatarId.current = avatar.id; setOpenConfirmModal(true)}}
                onClick={handleClickMoreOption}
                variant="contained"
                sx={{
                  position:"absolute",
                  zIndex:"1000",
                  top:"0px",
                  left:"100px",
                  backgroundColor: theme === "dark" ? "transparent" : "",
                  color: "black",
                  // width: "20px",
                  // height: "20px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "8px",
                  border:"",
                  cursor:"pointer",
                  "&:hover": {
                    // backgroundColor: "#CDCFDF",
                    color:"#2F355F"
                  },
                }}
              >
                {/* <DeleteIcon sx={{color:"rgba(202, 196, 208, 1)", '&:hover':{color:"#2F355F"} }}/> */}
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M11.875 16.25C11.875 16.7473 11.6775 17.2242 11.3258 17.5758C10.9742 17.9275 10.4973 18.125 10 18.125C9.50272 18.125 9.02581 17.9275 8.67417 17.5758C8.32254 17.2242 8.125 16.7473 8.125 16.25C8.125 15.7527 8.32254 15.2758 8.67417 14.9242C9.02581 14.5725 9.50272 14.375 10 14.375C10.4973 14.375 10.9742 14.5725 11.3258 14.9242C11.6775 15.2758 11.875 15.7527 11.875 16.25ZM11.875 10C11.875 10.4973 11.6775 10.9742 11.3258 11.3258C10.9742 11.6775 10.4973 11.875 10 11.875C9.50272 11.875 9.02581 11.6775 8.67417 11.3258C8.32254 10.9742 8.125 10.4973 8.125 10C8.125 9.50272 8.32254 9.02581 8.67417 8.67417C9.02581 8.32254 9.50272 8.125 10 8.125C10.4973 8.125 10.9742 8.32254 11.3258 8.67417C11.6775 9.02581 11.875 9.50272 11.875 10ZM11.875 3.75C11.875 4.24728 11.6775 4.72419 11.3258 5.07583C10.9742 5.42746 10.4973 5.625 10 5.625C9.50272 5.625 9.02581 5.42746 8.67417 5.07583C8.32254 4.72419 8.125 4.24728 8.125 3.75C8.125 3.25272 8.32254 2.77581 8.67417 2.42417C9.02581 2.07254 9.50272 1.875 10 1.875C10.4973 1.875 10.9742 2.07254 11.3258 2.42417C11.6775 2.77581 11.875 3.25272 11.875 3.75Z" fill="white"/>
                </svg>
                
              </Box>
               }
               {moreoption && <MoreOptionforAvatars moreoption={moreoption} setMoreOption={setMoreOption} handleClose={handleClose} deleteAvatarId={deleteAvatarId} avatar={avatar} setOpenConfirmModal={setOpenConfirmModal}/>}
               </Box>
               
              <Card sx={{margin:"0px", padding:"12px",width:"144px", height:"174px" ,display:"flex", flexDirection:"column", rowGap:"8px", backgroundColor: selectedAvatar?.id === avatar.id ? hovered_chat_bg_color : settingsModal_bg_color_dm,  borderRadius:"16px", outline: selectedAvatar?.id === avatar.id ? "1px solid rgba(255, 255, 255, 0.30)" : "1px solid rgba(255, 255, 255, 0.10)",cursor:'pointer',boxShadow:"none",
                '&:hover': {
                  borderRadius:"16px",
                  backgroundColor: selectedAvatar?.id !== avatar.id && hovered_chat_bg_color,
                  outline: selectedAvatar?.id !== avatar.id &&  "1px solid rgba(255, 255, 255, 0.10)"
                },}} 
                onClick={() => {
                  if(avatar?.status === 'done'){
                    handlePersonalAvatarSelect(avatar)
                  }
                }}
              >
               <CardMedia sx={{height:"120px", width:"120px", filter:avatar.status === 'done'? '' : "blur(6px)"}}
                 component="img"
                 alt={"Your avatar"}
                //  image={avatar?.status === 'done' && avatar?.image_url ? avatar?.thumbnail_url : YourAvatar}
                 image={YourAvatar}
               />
               <CardContent sx={{ padding: '0px', textAlign: 'center', margin:"0px", height:"14px"}}>
                 <Typography sx={{color:'white',fontFamily:"Inter",  fontSize:'14px', fontStyle:"normal" , fontWeight:600, lineHeight:"normal", letterSpacing:"0.082px"}}>{avatar?.name ? avatar?.name : 'Your Avatar'}</Typography>
               </CardContent>
               </Card>
             </Box>
                </Grid>
              </Box>
                )
              })
            }
            { personalAvatar &&
             <Box sx={{ display:"flex", justifyContent:"center", alignItems:"center", marginLeft:"30px"}}>
              <Box  onClick={()=>{ setShowPersonalAvatarModal(true)}} sx={{ display:"flex", justifyContent:"center", alignItems:"center", margin:"0px", padding:"8px", width:"48px", height:"48px", backgroundColor: defaultSettings_button_bg_color, borderRadius:"50%", color:"#FFFFFF", fontFamily:"Inter", fontSize: '14px', fontStyle:"normal", fontWeight: 600, lineHeight:"20px", letterSpacing:"0.1px", textTransform: "none", cursor:"pointer"}}>
                <AddIcon sx={{ width:"24px", height:"24px", color:"rgba(202, 196, 208, 1)"}}/>
              </Box>
            </Box>
            }
            </Box>
      </Box>
    <Grid>
    <Box sx={{marginTop:"24px"}}>
      <Typography sx={{color:"#ffffff", fontFamily:"Inter", fontSize: '14px', fontStyle:"normal", fontWeight: 500, lineHeight:"20px", marginBottom:"16px" }}> System avatars </Typography>
    </Box>
      {avatarsloading && (
        <Grid item xs={12}>
          <Typography>Loading avatars</Typography>
        </Grid>
      )}
      {avatarsloading === false && (avatars?.length === undefined || avatars?.length === 0 || avatars?.length === null)  ? (
        <Grid item xs={12}>
          <Typography>No data available!</Typography>
        </Grid>
      ) : 
        <Box>
        <Box sx={{ marginBottom: "20px", marginTop: "20px", display:"flex", flexDirection:"row" }}>
     { avatars.length > 0 && <Input
        type="text"
        disableUnderline={true}
        placeholder="Search Avatar"
        variant="outlined"
        sx={customTextFieldStyles}
        onChange={(e)=>{setInputBoxValue(e.target.value)}}
        value={inputBoxValue}
        style={{
          width: "100%",
          maxWidth: "180px",
          height: "48px",
          color: "#FFFFFF",
          backgroundColor: "#2F355F",
          borderRadius: "8px",
          padding:"0px 12px",
          boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.40)",
          '&:focus': {
          outline: 'none', 
         },
        }}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon style={{ color: "rgba(255, 255, 255, 0.80)" }}  />
          </InputAdornment>
        }
      />}
        </Box>
        <Grid container spacing={2}>
        {
        inputBoxValue == "" ?
        avatars?.map((avatar) => (
          <Grid
            item
            key={avatar.id}
            sx={{
              width: '140px',
              height: '174px',
              margin: 1, // Adds a margin around each grid item
              boxSizing: 'border-box', // Ensures padding is included in the width/height
            }}
          >
            <Card
              onClick={() => handleAvatarSelect(avatar)}
              sx={{
                width:"144px", height:"174px",
                cursor: "pointer",
                backgroundColor: selectedAvatar?.id === avatar.id ? hovered_chat_bg_color : settingsModal_bg_color_dm,
                outline: selectedAvatar?.id === avatar.id ? "1px solid rgba(255, 255, 255, 0.30)" : "1px solid rgba(255, 255, 255, 0.10)", // Apply border if selected
                borderRadius: selectedAvatar?.id === avatar.id ? "16px" : "16px", 
                padding:'10px',
                boxShadow:"none",
                '&:hover': {
                  borderRadius:"16px",
                  backgroundColor: selectedAvatar?.id !== avatar.id && hovered_chat_bg_color,
                  outline: selectedAvatar?.id !== avatar.id && "1px solid rgba(255, 255, 255, 0.10)"
                },
              }}
            >
              <CardMedia
                component="img"
                alt={avatar.name}
                // height="100" // Adjust the image height within the card
                image={avatar.image_url}
              />
              <CardContent sx={{ padding: '8px', textAlign: 'center'}}>
                <Typography variant="body2" component="div" color='white' fontSize='14px' fontWeight='600'>
                  {
                  avatar.name.length <= 4 ? avatar?.name : avatar.name.slice(0, 4)
                  }
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        )) : renderFilterData(filterAvatars)
        }
      </Grid>
      </Box> 
      }
    </Grid>
    <CommanModalForConformation open={openConfirmModal} modalTitle={modalTitle} modalMessage={modalMessage} handleConfirm={handleConfirm} handleCancel={handleCancel}/>
    </>
  );
  
};

const MoreOptionforAvatars = ({moreoption, setMoreOption ,handleClose, deleteAvatarId, avatar, setOpenConfirmModal}) =>{

  const handleDeleteClick = (event) =>{
    event.stopPropagation()
    setMoreOption(null)
    deleteAvatarId.current = avatar.id;
    setOpenConfirmModal(true)
  }

  return (
     <Menu 
              anchorEl={moreoption}
              open={moreoption !== null}
              // open={moreoption}
              onClose={handleClose}
              PaperProps={{
                style: {
                  backgroundColor: '#2F355F',
                  color: 'white',
                  padding: '4px',
                  borderRadius: '8px',
                  marginTop: '20px',
                  marginLeft :"20px",
                  border: '1px solid rgba(255, 255, 255, 0.2)'
                },
              }}
              anchorOrigin={{
                vertical: 'bottom',       // Open from the top of the button
                horizontal: 'right',    // Align to the right
              }}
              transformOrigin={{
                vertical: 'bottom',     // The menu will "pop" up from the bottom
                horizontal: 'left',    // The menu will align to the right
              }}
              >
                 <MenuItem  onClick={(event)=>{handleDeleteClick(event)}}  style={{padding:"8px 8px", backgroundColor: 'transparent' }} 
                 >
                <ListItemIcon style={{padding:"0px "}}>
                <DeleteIcon sx={{color:"rgba(202, 196, 208, 1)", '&:hover':{color:"#2F355F"} }}/>
                </ListItemIcon>
                <ListItemText primary="Delete Avatar" />
              </MenuItem> 
              </Menu>
  )
}

export default AvatarSettings;




const BaFlag = (props) => (
  <svg
    width="1em"
    height="0.75em"
    xmlns="http://www.w3.org/2000/svg"
    id="flag-icons-ba"
    viewBox="0 0 640 480"
    {...props}
  >
    <defs>
      <clipPath id="ba-a">
        <path fillOpacity={0.7} d="M-85.3 0h682.6v512H-85.3z" />
      </clipPath>
    </defs>
    <g
      fillRule="evenodd"
      clipPath="url(#ba-a)"
      transform="translate(80) scale(.9375)"
    >
      <path fill="#009" d="M-85.3 0h682.6v512H-85.3V0z" />
      <path fill="#FC0" d="m56.5 0 511 512.3V.3L56.5 0z" />
      <path
        fill="#FFF"
        d="M439.9 481.5 412 461.2l-28.6 20.2 10.8-33.2-28.2-20.5h35l10.8-33.2 10.7 33.3h35l-28 20.7 10.4 33zm81.3 10.4-35-.1-10.7-33.3-10.8 33.2h-35l28.2 20.5-10.8 33.2 28.6-20.2 28 20.3-10.5-33 28-20.6zM365.6 384.7l28-20.7-35-.1-10.7-33.2-10.8 33.2-35-.1 28.2 20.5-10.8 33.3 28.6-20.3 28 20.4-10.5-33zm-64.3-64.5 28-20.6-35-.1-10.7-33.3-10.9 33.2h-34.9l28.2 20.5-10.8 33.2 28.6-20.2 27.9 20.3-10.4-33zm-63.7-63.6 28-20.7h-35L220 202.5l-10.8 33.2h-35l28.2 20.4-10.8 33.3 28.6-20.3 28 20.4-10.5-33zm-64.4-64.3 28-20.6-35-.1-10.7-33.3-10.9 33.2h-34.9L138 192l-10.8 33.2 28.6-20.2 27.9 20.3-10.4-33zm-63.6-63.9 27.9-20.7h-35L91.9 74.3 81 107.6H46L74.4 128l-10.9 33.2L92.1 141l27.8 20.4-10.3-33zm-64-64 27.9-20.7h-35L27.9 10.3 17 43.6h-35L10.4 64l-11 33.3L28.1 77l27.8 20.4-10.3-33zm-64-64L9.4-20.3h-35l-10.7-33.3L-47-20.4h-35L-53.7 0l-10.8 33.2L-35.9 13l27.8 20.4-10.3-33z"
      />
    </g>
  </svg>
)

export default BaFlag

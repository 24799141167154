

const StFlag = (props) => (
  <svg
    width="1em"
    height="0.75em"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    id="flag-icons-st"
    viewBox="0 0 640 480"
    {...props}
  >
    <path fill="#12ad2b" d="M0 0h640v480H0z" />
    <path fill="#ffce00" d="M0 137.1h640V343H0z" />
    <path fill="#d21034" d="M0 0v480l240-240" />
    <g id="st-c" transform="translate(351.6 240) scale(.34286)">
      <g id="st-b">
        <path id="st-a" d="M0-200V0h100" transform="rotate(18 0 -200)" />
        <use xlinkHref="#st-a" transform="scale(-1 1)" />
      </g>
      <use xlinkHref="#st-b" transform="rotate(72)" />
      <use xlinkHref="#st-b" transform="rotate(144)" />
      <use xlinkHref="#st-b" transform="rotate(-144)" />
      <use xlinkHref="#st-b" transform="rotate(-72)" />
    </g>
    <use xlinkHref="#st-c" x={700} transform="translate(-523.2)" />
  </svg>
)

export default StFlag

import React, { useState, useRef } from "react";
import PageLayout from "../components/navFoot/PageLayout";
import {
  Box,
  Typography,
  CardMedia,
  Card,
  Button,
  Col,
  TextField,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import sourceUrl from "../api/Source.api";
import { uploadFiles_button_default_bg_color, uploadFiles_button_hovered_bg_color } from "../constants/colors";
import Pdf_Icon from "../assets/File_Icon/pdf_icon.png";
import word_logo from "../assets/File_Icon/word_logo.png";

const Career = () => {
  const theme = localStorage.getItem("theme");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const fileInputRef = useRef(null);
  const [resume, setResume] = useState(null);
  const isBelow991 = useMediaQuery ("(max-width:991px)");
  // return (
  //   <PageLayout>
  //     <h1 className="mb-4">Careers</h1>
  //     <p style={{ color: "gray" }} className="pt-3 border-top">
  //       Are you a self-driven, hardworking individual with a passion for working
  //       on cutting-edge technology, including GenAI, LLMs, GPTs, and more?{" "}
  //       <br /> If you have relevant experience in the field and share a love for
  //       our product, we'd love to hear from you!
  //     </p>
  //     <p style={{ color: "gray" }} className="pt-3 border-top">
  //       Please share your CV and resume with us at{" "}
  //       <a href="mailto:career@getbrightspot.com">career@getbrightspot.com</a>.
  //       Our team will review your application and connect with you as soon as
  //       possible.
  //     </p>
  //   </PageLayout>
  // );

    // Function to trigger file input
    const handleButtonClick = () => {
      fileInputRef.current.click();
    };

      // Function to handle file selection
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    // Check if file is selected
    if (file) {
      // Validate file type
      const allowedTypes = ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
      if (!allowedTypes.includes(file.type)) {
        alert("Please select a valid PDF or DOC file.");
        return;
      }
      setResume(file);
    }
  };

  const handleRemoveResume = () =>{
    setResume(null);
  }

  const customTextFieldStylesForInvalid = () => {
    return {
      "& .MuiInputLabel-root": {
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "24px",
        letterSpacing: "0.5px",
        color:  "rgba(255, 255, 255, 0.80)",
        "&.Mui-focused": {
          color:  "rgba(255, 255, 255, 0.80)",
        },
      },
      "& .MuiInputBase-input": {
        color: "#FFFFFF",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor:  "rgba(255, 255, 255, 0.80)",
        },
        "&:hover fieldset": {
          borderColor:  "rgba(255, 255, 255, 0.80)",
        },
        "&.Mui-focused fieldset": {
          borderColor:  "rgba(255, 255, 255, 0.80) ",
        },
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiInputLabel-root": {
        color:  "rgba(255, 255, 255, 0.80) ",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(255, 255, 255, 0.80) ",
      },
    };
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!resume) {
      alert("Please upload a resume before submitting.");
      return;
    }

    const formData = new FormData();
    formData.append("resume", resume);
    formData.append(
      "metadata",
      JSON.stringify({
        firstName,
        lastName,
        email,
        message,
      })
    );

    try {
      const response = await fetch(`${sourceUrl}/careers`, {
        method: "POST",
        headers: {
          Accept: "*/*",
        },
        body: formData,
      });

      const data = await response.json();
      console.log("Success:", data);
      setResume(null);
      setFirstName("");
      setLastName("")
      setEmail("");
      setMessage("")
      alert("Form submitted successfully!");
    } catch (error) {
      console.error("Error:", error);
      alert("Error submitting the form.");
    }
  };

  return (
    <PageLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          maxWidth: isBelow991 ? "90%" : "731px",
          margin: "auto",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontSize: isBelow991 ? "28px" : "48px",
            fontWeight: 500,
            lineHeight: "57.6px",
            letterSpacing: "-0.32px",
            margin: isBelow991 ? "154px auto 60px auto" : "245px auto 80px auto",
          }}
        >
          Careers
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", rowGap: "36px" }}>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontSize: "18px",
              fontWeight: 500,
              lineHeight: "150%",
              letterSpacing: "-0.32px",
              color: "rgba(255, 255, 255, 0.9)",
            }}
          >
            Are you a self-driven, hardworking individual with a passion for
            working on cutting-edge technology, including GenAI, LLMs, GPTs, and
            more?
          </Typography>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontSize: "18px",
              fontWeight: 500,
              lineHeight: "150%",
              letterSpacing: "-0.32px",
              color: "rgba(255, 255, 255, 0.9)",
            }}
          >
            If you have relevant experience in the field and share a love for
            our product, we'd love to hear from you!
          </Typography>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontSize: "18px",
              fontWeight: 500,
              lineHeight: "150%",
              letterSpacing: "-0.32px",
            }}
          >
            Please share your CV and resume with us at{" "}
            {/* <span style={{ textDecoration: "underline",  }}>
              career@modalx.ai
            </span>{" "} */}
            <a 
              href="mailto:career@modalx.ai"
              style={{ textDecoration: "underline", color: "inherit", cursor: "pointer" }}
            >
              career@modalx.ai
            </a>{" "}
            or using the form below. Our team will review your application and
            connect with you as soon as possible.
          </Typography>
        </Box>
        {/* Form Section  */}
        <Box
          sx={{
            display: "flex",
            justifyContent: isBelow991 ? "center" : "space-between",
            width: isBelow991 && "100%"
          }}
        >
          <Box
            sx={{
              // width: "889px",
              marginTop: "60px",
              gap: "200px",
              opacity: "0px",
              display: "flex",
              maxWidth:isBelow991 ? "90%" : "438",
              "@media (max-width : 991px)": {
                display: "grid",
                gridTemplateColumns: "repeat(1, 1fr)",
                // marginTop : '80px',
                minWidth: isBelow991 ? "100%" : "438px",
                // margin: "80px 4.4% 0px 4.4%",
                gap: "0px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
                gap: "40px",
                opacity: "0px",
              }}
            >
              <Box sx={{}}>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "24px",
                    fontWeight: "600px",
                    lineHeight: "36px",
                    textAlign: "left",
                    color: "#FFFFFF",
                    opacity: "0.9",
                    "@media (max-width : 991px)": {
                      fontSize: "20px",
                    },
                  }}
                >
                  {/* Book a demo call */}
                  Reach out to us
                </Typography>
              </Box>
              <form onSubmit={handleSubmit}>
                <Box
                  sx={{
                    width: "100%",
                    marginTop: "40px",
                    gap: "40px",
                    opacity: "0px",
                    "@media (max-width : 991px)": {
                      marginTop: "36px",
                    },
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "142px",
                      gap: "30px",
                      opacity: "0px",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        // height: "56px",
                        gap: "18px",
                        opacity: "0px",
                        display: "flex",

                        "@media (max-width : 991px)": {
                          display: "grid",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          // height: "56px",
                          gap: "18px",
                          opacity: "0px",
                        }}
                      >
                        <Box
                          sx={{
                            width: "210px",
                            height: "56px",
                            gap: "10px",
                            // borderRadius: "4px",
                            // border: "1px solid rgba(255, 255, 255, 0.6)",
                            opacity: "0px",
                            display: "flex",
                            alignItems: "center",
                            "@media (max-width : 991px)": {
                              width: "100%",
                            },
                          }}
                        >
                          <TextField
                            id="outlined-basic"
                            label="First name*"
                            variant="outlined"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            sx={() =>
                              customTextFieldStylesForInvalid()
                            }
                            style={{
                              width: "100%",
                              // maxWidth :  isBelow991 ? "100%" : "300px",
                              // height: "56px",
                              color: "#FFFFFF",
                              // backgroundColor: "#232746",
                              borderRadius: "8px",
                              boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.40)",
                              placeholderfontsize: "16px",
                            }}
                          />
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          width: "100%",
                          height: "56px",
                          gap: "18px",
                          opacity: "0px",
                          // backgroundColor : 'yellow'
                        }}
                      >
                        <Box
                          sx={{
                            width: "210px",
                            height: "56px",
                            gap: "10px",
                            // borderRadius: "4px",
                            // border: "1px solid rgba(255, 255, 255, 0.6)",
                            opacity: "0px",
                            display: "flex",
                            alignItems: "center",
                            "@media (max-width : 991px)": {
                              width: "100%",
                            },
                          }}
                        >
                          <TextField
                            id="outlined-basic"
                            label="Last name*"
                            variant="outlined"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            sx={() =>
                              customTextFieldStylesForInvalid()
                            }
                            style={{
                              width: "100%",
                              // maxWidth :  isBelow991 ? "100%" : "300px",
                              height: "56px",
                              color: "#FFFFFF",
                              // backgroundColor: "#232746",
                              borderRadius: "8px",
                              boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.40)",
                              placeholderfontsize: "16px",
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        width: "438px",
                        gap: "18px",
                        opacity: "0px",
                        marginTop: "30px",
                        display: "flex",
                        "@media (max-width : 991px)": {
                          width: "100%",
                          marginTop: "20px",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          width: "438px",
                          height: "56px",
                          gap: "10px",
                          // borderRadius: "4px",
                          // border: "1px solid rgba(255, 255, 255, 0.6)",
                          opacity: "0px",
                          display: "flex",
                          alignItems: "center",
                          "@media (max-width : 991px)": {
                            width: "100%",
                          },
                        }}
                      >
                        <TextField
                          id="outlined-basic"
                          label="Email address*"
                          variant="outlined"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          sx={() => customTextFieldStylesForInvalid()}
                          style={{
                            width: "100%",
                            // maxWidth :  isBelow991 ? "100%" : "300px",
                            height: "56px",
                            color: "#FFFFFF",
                            // backgroundColor: "#232746",
                            borderRadius: "8px",
                            boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.40)",
                            placeholderfontsize: "16px",
                          }}
                        />
                      </Box>
                    </Box>

                    <TextField multiline
                      id="outlined-basic"
                      label="Tell us a bit about yourself*"
                      variant="outlined"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      sx={() => customTextFieldStylesForInvalid()}
                      style={{
                        width: "100%",
                        marginTop: "30px",
                        // height: "56px",
                        color: "#FFFFFF",
                        // backgroundColor: "#232746",
                        borderRadius: "8px",
                        boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.40)",
                        placeholderfontsize: "16px",
                      }}
                      InputProps={{
                        style: {
                          height: "112px", // Ensures the input field fills the container height
                          padding: "20px 14px", // Adjust padding for better alignment
                          alignItems: "flex-start", // Ensures text starts from the top
                          overflow: "auto",
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          whiteSpace: "normal", // Allows label text to wrap
                          // overflow: "visible", // Ensures the entire label is visible
                        },
                      }}
                    />
                  </Box>

                   {/* Upload File Button */}
                   <Box
                    sx={{
                      display:"flex",
                      flexDirection:"row",
                      justifyContent:"space-between",
                      alignItems:"center",
                      // width: "88px",
                      marginTop: "172px",
                      gap: "8px",
                      opacity: "0px",
                      "@media (max-width : 991px)": {
                        marginTop: "226px",
                        width: "43%",
                        // marginRight : '100px'
                      },
                    }}
                  >
                    {/* Upload File Button */}
                    <Box>
                      <Button
                        onClick={handleButtonClick}
                        variant="contained"
                        sx={{
                          backgroundColor:
                            theme === "dark"
                              ? uploadFiles_button_default_bg_color
                              : "",
                          color: "black",
                          width: "202px",
                          height: "40px",
                          borderRadius: "8px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "0px",
                          "&:hover": {
                            backgroundColor:
                              uploadFiles_button_hovered_bg_color,
                          },
                        }}
                      >
                        <Box
                          sx={{
                            height: "40px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Inter",
                              fontSize: "14px",
                              fontWeight: 600,
                              lineHeight: "20px",
                              letterSpacing: "0.1",
                              textAlign: "center",
                              color: "#17182C",
                              gap: "8px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              textTransform: "none",
                              padding: "10px 0px",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="19"
                              height="18"
                              viewBox="0 0 19 18"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_7004_2614)">
                                <path
                                  d="M15.0125 7.53C14.7606 6.25329 14.0732 5.10364 13.0678 4.27743C12.0624 3.45122 10.8013 2.99971 9.5 3C7.3325 3 5.45 4.23 4.5125 6.03C3.41018 6.14912 2.39075 6.67141 1.65012 7.49649C0.909478 8.32158 0.499871 9.39126 0.5 10.5C0.5 12.9825 2.5175 15 5 15H14.75C16.82 15 18.5 13.32 18.5 11.25C18.5 9.27 16.9625 7.665 15.0125 7.53ZM14.75 13.5H5C3.3425 13.5 2 12.1575 2 10.5C2 8.9625 3.1475 7.68 4.67 7.5225L5.4725 7.44L5.8475 6.7275C6.19248 6.05614 6.71598 5.49296 7.36041 5.09996C8.00483 4.70695 8.74519 4.49935 9.5 4.5C11.465 4.5 13.16 5.895 13.5425 7.8225L13.7675 8.9475L14.915 9.03C15.4788 9.06792 16.0073 9.31799 16.3942 9.72989C16.781 10.1418 16.9975 10.6849 17 11.25C17 12.4875 15.9875 13.5 14.75 13.5ZM6.5 9.75H8.4125V12H10.5875V9.75H12.5L9.5 6.75L6.5 9.75Z"
                                  fill="black"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_7004_2614">
                                  <rect
                                    width="18"
                                    height="18"
                                    fill="white"
                                    transform="translate(0.5)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                            <Box sx={{ margin: "0px", padding: "0px" }}>
                            Upload your resume
                            </Box>
                          </Typography>
                        </Box>
                      </Button>
                      <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: "none" }}
                                accept=".pdf,.doc,.docx"
                                onChange={handleFileChange}
                              />
                    </Box>
                    {/* Uploaded File Preview */}
                    {resume && (
                        <Box mt={2} p={2} borderRadius="8px" sx={{display:"flex", flexDirection:"row",width:"200px", justifyContent:"space-between", alignItems:"center"}}>
                          <Box sx={{display:"flex", flexDirection:"row", columnGap:"10px"}}>
                          {(resume?.name.endsWith(".pdf") || resume?.name.type === "application/pdf" )&& (
                            <img
                              src={Pdf_Icon}
                              alt="logo"
                              style={{ width: "20px", height: "20px" }}
                            />
                          )}
                          {(resume?.name.endsWith(".doc") || resume?.name.endsWith(".docx") || resume?.name.type === "application/msword" )&& (
                            <img
                              src={word_logo}
                              alt="logo"
                              style={{ width: "20px", height: "20px" }}/>)}
                          <Typography variant="body1" sx={{}}>{resume.name.length > 10 ? resume.name.slice(1,10) : resume.name}</Typography>
                          </Box>
                          <IconButton 
                          onClick={handleRemoveResume}
                          >
                           <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none">
                          <path d="M2.8335 15.5C2.37516 15.5 1.9828 15.3368 1.65641 15.0104C1.33002 14.684 1.16683 14.2917 1.16683 13.8333V3H0.333496V1.33333H4.50016V0.5H9.50016V1.33333H13.6668V3H12.8335V13.8333C12.8335 14.2917 12.6703 14.684 12.3439 15.0104C12.0175 15.3368 11.6252 15.5 11.1668 15.5H2.8335ZM11.1668 3H2.8335V13.8333H11.1668V3ZM4.50016 12.1667H6.16683V4.66667H4.50016V12.1667ZM7.8335 12.1667H9.50016V4.66667H7.8335V12.1667Z" fill="#CAC4D0"/>
                          </svg>
                          </IconButton>
                        </Box>
                      )}
                  </Box>

                  {/* Send Button */}
                  <Box
                    sx={{
                      width: "89px",
                      marginTop: "40px",
                      gap: "8px",
                      opacity: "0px",
                      "@media (max-width : 991px)": {
                        marginTop: "36px",
                        width: "43%",
                        // marginRight : '100px'
                      },
                    }}
                  >
                    <Button
                      type="submit"
                      variant="outlined"
                      sx={{
                        width: "100%",
                        height: "40px",
                        gap: "0px",
                        borderRadius: "8px",
                        border: "1px solid rgba(247, 190, 105, 1)",
                        opacity: "0px",
                        textAlign: "center",
                        marginBottom: isBelow991 ? "195px" : "321px",
                        "&:hover": {
                          borderColor: "#F6BE6A",
                          backgroundColor: "#282D51", // No border color changes on hover
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Inter",
                          fontSize: "16px",
                          fontWeight: "600px",
                          lineHeight: "20px",
                          textTransform: "none",
                          letterSpacing: "0.1px",
                          textAlign: "center",
                          color: "rgba(246, 190, 106, 1)",
                          "@media (max-width : 991px)": {
                            fontSize: "14px",
                          },
                        }}
                      >
                        Submit
                      </Typography>
                    </Button>
                  </Box>

                 
                </Box>
              </form>
            </Box>
            <Box></Box>
          </Box>
        </Box>
      </Box>
    </PageLayout>
  );
};

export default Career;

import React, { useRef, useState } from "react";
import { Modal, Form } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import { Box, Dialog, DialogActions, DialogContent, FormControl, InputLabel, MenuItem, Select, TextField, Typography,Button, Tooltip, IconButton } from "@mui/material";
import { button_bg_color_for_New_Project, button_bg_color_for_New_Project_dm, oneClickModal_backgroundColor_dm, uploadFiles_button_default_bg_color, uploadFiles_button_hovered_bg_color } from "../../../../constants/colors";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import PersonalAvatarCheckIcon from "../../../../assets/PersonalAvatarCheckIcon.png";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import sourceUrl from "../../../../api/Source.api";
import CircularLoader from "../../sideBar/Track_Website/CircularLoader";
import CommanLinearStepLoader from "../../../Common_Components/CommanLinearStepLoader";
import PersonalAvatarCreationSampleVideo from "../../../../assets/PersonalAvatarCreationSampleVideo.mp4";
import CommonToast from "../../../Common_Components/CommonToast";
import { fetchAvatars } from "./videoSettingsAPI";
import FileValidator from "../../../Common_Components/FileValidator";
import { useNavigate } from "react-router-dom";

const PersonalAvatar = ({ show, handleClose }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedScript,setUploadedScript] = useState(null);
  const [avatarDetails, setAvatarDetails] = useState({ name: "", style: "" });
  const [avatarName,setAvatarName] = useState('');
  const [language,setLanguage] = useState('')
  const { showPersonalAvatarModal,setAvatars, setAvatarsLoading, setPersonalAvatar } = useWorkspace();
  const theme = localStorage.getItem("theme");
  const fileInputRef = useRef(null);
  const [consentText,setConsentText] = useState('');
  const token = localStorage.getItem('token');
  const [loader,setLoader] = useState(false);
  const [createdId,setCreatedId] = useState(null);
  const [showSampleScript,setShowSampleScript] = useState(false);
  const [isToastOpen,setIsToastOpen] = useState(false);
  const [notfMsg,setNotfMsg] = useState('');
  const [notfType,setNotfType] = useState('');
  const [showClosingWarning,setShowClosingWarning] = useState(false);
  const navigate = useNavigate();

  // Handle file upload
  const handleFileChange = (e) => {
    setUploadedFile(e.target.files[0]);
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    setAvatarDetails({ ...avatarDetails, [e.target.name]: e.target.value });
  };

  // Step navigation
  const handleNext = () => setCurrentStep((prev) => prev + 1);
  const handleBack = () => setCurrentStep((prev) => prev - 1);
  const handleFinish = () => {
    console.log("Avatar created with details:", avatarDetails, uploadedFile);
    handleClose(); // Close modal
    setCurrentStep(1); // Reset steps
  };

  const handleCloseToast = () => {
    setIsToastOpen(false);
  }

  const handleFileSelect = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setUploadedFile(file);
    setLoader(true); // Start loader when file is selected

    try {
        // Step 1: Request a presigned URL
        const response = await fetch(`${sourceUrl}/video/consent/presignedUrl`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({ id: createdId, fileName: file.name }),
        });

        if (!response.ok) throw new Error("Failed to fetch pre-signed URL");
        const res = await response.json();
        const presignedUrl = res?.data?.url;

        // Step 2: Upload file to S3
        const uploadResponse = await fetch(presignedUrl, {
            method: "PUT",
            body: file,
            headers: {
                "Content-Type": file.type
            }
        });

        if (!uploadResponse.ok) throw new Error("Failed to upload file to S3");

        // Step 3: Call the additional API before polling
        const uploadConfirmationResponse = await fetch(`${sourceUrl}/video/consent/upload`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({ id: createdId, fileName: file.name }),
        });

        if (!uploadConfirmationResponse.ok) throw new Error("Failed to confirm upload");

        const uploadConfirmationData = await uploadConfirmationResponse.json();

        // If API response status is true, start polling for status
        if (uploadConfirmationData?.status === true && uploadConfirmationData?.data?.status !== 'error') {
            pollFileStatus(createdId);
        } else {
            throw new Error("Upload confirmation failed");
        }
    } catch (error) {
        console.error("Error uploading file:", error);
        setLoader(false); // Stop loader on error
        setIsToastOpen(true);
        setNotfMsg('There is an error while uploading consent. Please try again.');
        setNotfType('error');
    }
};


// Function to poll file status every 5 seconds
const pollFileStatus = (fileId) => {
    const interval = setInterval(async () => {
        try {
            const statusResponse = await fetch(`${sourceUrl}/video/consent/${fileId}/status`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });

            if (!statusResponse.ok) throw new Error("Failed to fetch status");

            const statusData = await statusResponse.json();
            const fileStatus = statusData?.data?.status;

            if (fileStatus === "done") {
                clearInterval(interval); // Stop polling
                setLoader(false); // Stop loader
                setCurrentStep(3); // Move to next step
            }
            else if(fileStatus === "error"){
                clearInterval(interval); // Stop polling
                setLoader(false); // Stop loader
                setIsToastOpen(true);
                setNotfMsg('There is an error while uploading consent. Please try again.');
                setNotfType('error');
                setCurrentStep(2); // Move to next step
            }
        } catch (error) {
            console.error("Error fetching file status:", error);
            clearInterval(interval); // Stop polling on error
            setLoader(false);
            setIsToastOpen(true);
            setNotfMsg('There is an error while uploading consent. Please try again.');
            setNotfType('error');
        }
    }, 5000); // Poll every 5 seconds
};



const handleScriptFileSelect = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const isValidFile = await FileValidator({
        file,
        maxSize: 50 * 1024 * 1024, // 50MB
        minDuration: 60, // 10 seconds (optional)
        maxDuration: 600, // 120 seconds (optional)
        allowedTypes: ["video/mp4", "video/webm"],
      });
      
      if (!isValidFile.valid) {
        setIsToastOpen(true);
        setNotfMsg(`${isValidFile.error}`);
        setNotfType('error');
        return;
      }

    setUploadedScript(file);
    setLoader(true); // Start loader when file is selected

    try {
        // Step 1: Request a presigned URL for script file
        const response = await fetch(`${sourceUrl}/video/avatar/presignedUrl`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({ id: createdId, fileName: file.name }),
        });

        if (!response.ok) throw new Error("Failed to fetch pre-signed URL");
        const res = await response.json();
        const presignedUrl = res?.data?.url;

        // Step 2: Upload script file to S3
        const uploadResponse = await fetch(presignedUrl, {
            method: "PUT",
            body: file,
            headers: {
                "Content-Type": file.type
            }
        });

        if (!uploadResponse.ok) throw new Error("Failed to upload script file to S3");

        // Step 3: Call the additional API before polling
        const uploadConfirmationResponse = await fetch(`${sourceUrl}/video/avatar/create`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({ id: createdId}),
        });

        if (!uploadConfirmationResponse.ok) throw new Error("Failed to confirm script upload");

        const uploadConfirmationData = await uploadConfirmationResponse.json();

        // If API response status is true, start polling for status
        if (uploadConfirmationData?.status === true && uploadConfirmationData?.data?.status !== 'error') {
            fetchScriptFileStatus(createdId);
        } else {
            throw new Error("Script upload confirmation failed");
        }
    } catch (error) {
        console.error("Error uploading script file:", error);
        setLoader(false); // Stop loader on error
        setIsToastOpen(true);
        setNotfMsg('There is an error while uploading the script. Please try again.');
        setNotfType('error');
    }
};

// Function to poll script file status every 5 seconds
const fetchScriptFileStatus = async (fileId) => {
    try {
        const statusResponse = await fetch(`${sourceUrl}/video/avatar/${fileId}/status`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        if (!statusResponse.ok) throw new Error("Failed to fetch script file status");

        const statusData = await statusResponse.json();
        const fileStatus = statusData?.data?.status;

        if (fileStatus === "done" ||fileStatus === 'validating') {
            setLoader(false); // Stop loader
            setCurrentStep(5); // Move to the next step
            setIsToastOpen(true);
            setNotfMsg('Your script is being processed.');
            setNotfType('success');
        }
        else if(fileStatus === 'error'){
            setLoader(false); // Stop loader
            setCurrentStep(4); // Move to the next step
            setIsToastOpen(true);
            setNotfMsg('There is an error while uploading the script. Please try again.');
            setNotfType('error');
        }
    } catch (error) {
        console.error("Error fetching script file status:", error);
        setLoader(false);
        setIsToastOpen(true);
        setNotfMsg('There is an error while uploading the script. Please try again.');
        setNotfType('error');
    }
};



  const handleStep1 = async () => {

        if (!token) {
            console.error("Token not found");
            return;
        }

        if (!avatarName || !language) {
            console.error("Please enter avatar name and select language");
            return;
        }

        try {
            const response = await fetch(`${sourceUrl}/video/consent/create`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    avatarName,
                    language
                })
            });

            const data = await response.json();

            if (response.ok) {
                setConsentText(data?.data?.text);
                setCreatedId(data?.data?.id);
                console.log("Consent challenge created:", data);
                handleNext(); // Proceed to the next step
            } else {
                console.error("Error:", data.message);
            }
        } catch (error) {
            console.error("API call failed:", error);
        }
    };

    const handleCloseScript = () => {
        setShowSampleScript(false);
    }

    const handleCloseClosingWarningModal = () => {
        setShowClosingWarning(false);
    }

    const languages = [
        "Dutch", "Turkish", "Swedish", "Indonesian", "Filipino", "Czech", "Romanian",
        "Danish", "Malay", "Slovak", "Croatian", "English", "Polish", "German",
        "Spanish", "French", "Italian", "Portuguese"
    ];

  

  // Modal content for each step
  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <div>
            <p style={{fontSize:'16px',fontWeight:600,fontFamily:'inter'}}>Step 1: Consent</p>
            <p style={{fontSize:'14px',fontWeight:400,fontFamily:'inter',color:'rgba(255,255,255,0.8)'}}>To prevent technology misuse, we need you to confirm your identity by recording yourself reading a short consent statement. After clicking on continue, you will have 30 minutes to complete the avatar creation process. You can start again anytime.</p>
            <div style={{ width: '100%', height: '56px',display:'flex',justifyContent:'space-between', marginBottom:'36px' }}>
                <TextField
                    id="outlined-basic"
                    label="Your avatar name*"
                    variant="outlined"
                    sx={{
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                        borderColor: 'rgba(255, 255, 255, 0.8)', // border color
                        },
                        '&:hover fieldset': {
                        borderColor: 'rgba(255, 255, 255, 0.8)', // border color on hover
                        },
                        '&.Mui-focused fieldset': {
                        borderColor: 'rgba(255, 255, 255, 0.8)', // border color when focused
                        },
                    },
                    width:'340px',
                    bgcolor:'#232746'
                    }}
                    onChange={(e) => setAvatarName(e.target.value)}
                    InputLabelProps={{
                    style: { color: 'rgba(255, 255, 255, 0.8)' }, // label color
                    }}
                    InputProps={{
                    style: { color: 'rgba(255, 255, 255, 0.8)' }, // input text color
                    
                    }}
                />
                <Box sx={{maxWidth:'328px',display:'flex',gap:'8px'}}>
                   <InfoOutlinedIcon sx={{width:'16px',height:'16px'}} />
                   <Typography sx={{fontSize:'13px',fontWeight:400,fontFamily:'inter'}}>
                        This name will be used for recording your consent and will be the name of your avatar
                    </Typography> 
                </Box>
                
            </div>
            <div style={{ width: '100%', height: '56px',display:'flex',justifyContent:'space-between',marginBottom:'140px' }}>
                <FormControl fullWidth 
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            borderColor: 'rgba(255, 255, 255, 0.8)', // border color
                            },
                            '&:hover fieldset': {
                            borderColor: 'rgba(255, 255, 255, 0.8)', // border color on hover
                            },
                            '&.Mui-focused fieldset': {
                            borderColor: 'rgba(255, 255, 255, 0.8)', // border color when focused
                            },
                        },
                        width:'340px',
                        color:'rgba(255, 255, 255, 0.8)',
                        bgcolor:'#232746'
                        }}
                >
                    <InputLabel id="demo-simple-select-label"
                        sx={{
                            color:'rgba(255,255,255,0.8)',
                        }}
                    >Language</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={language}
                        label="Language"
                        onChange={(e) => setLanguage(e.target.value)}
                        MenuProps={{
                            PaperProps: {
                              sx: {
                                bgcolor: '#282D51', // Background color of the dropdown
                                '& .MuiMenuItem-root': {
                                  color: 'white', // Text color of options
                                  '&:hover': {
                                    bgcolor: 'rgba(255, 255, 255, 0.2)', // Background color on hover
                                  },
                                },
                              },
                            },
                        }}
                        sx={{
                            color:'rgba(255,255,255,1)',
                            '& .MuiSelect-icon': {
                                color: 'white', // Color of the dropdown arrow
                            },
                        }}
                    >
                        {languages.map((lang) => (
                            <MenuItem key={lang} value={lang}>
                            {lang}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Box sx={{maxWidth:'328px',display:'flex',gap:'8px'}}>
                   <InfoOutlinedIcon sx={{width:'16px',height:'16px'}} />
                   <Typography sx={{fontSize:'13px',fontWeight:400,fontFamily:'inter'}}>
                        This will be the language of your consent script which you will read out
                    </Typography> 
                </Box>
                
            </div>
            {/* <Button variant="primary" onClick={handleNext}>
              Get Started
            </Button> */}
          </div>
        );
      case 2:
        return (
          <div>
            <div 
                style={{
                    height: '110px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between', // Ensures equal spacing
                    alignItems: 'flex-start', // Aligns items to the left
                    marginBottom:'24px'
                }}
            >
                <Box 
                 sx={{
                   cursor:'pointer' 
                 }}
                 onClick={() => handleBack()}
                >
                    <ArrowBackOutlinedIcon sx={{width:'24px',height:'24px'}}/>
                </Box>
                
                <Typography sx={{fontSize:'16px',fontWeight:'600',fontFamily:'inter'}}>Record your consent</Typography>
                <Typography sx={{fontSize:'14px',fontWeight:400,fontFamily:'inter',color:'rgba(255,255,255,0.8)'}}>Record a video while reading out the following script and upload it to provide your consent. </Typography>
            </div>
            <div style={{padding:'20px',border:'1px solid rgba(255,255,255,0.2)',borderRadius:'8px',backgroundColor:'#232746',marginBottom:'36px'}}>
                {consentText}
            </div>
            <div style={{height:'34px',display:'flex',flexDirection: 'row',justifyContent: 'space-between',marginBottom:'60px'}}>
                <div style={{display:'flex',gap:'10px'}}>
                    <img src={PersonalAvatarCheckIcon} style={{width:'20px',height:'20px'}}/>
                    <p style={{fontSize:'14px',fontWeight:400,fontFamily:'inter',maxWidth:'196px'}}>Pronounce only the provided consent script, nothing else</p>
                </div>
                <div style={{display:'flex',gap:'10px'}}>
                    <img src={PersonalAvatarCheckIcon} style={{width:'20px',height:'20px'}}/>
                    <p style={{fontSize:'14px',fontWeight:400,fontFamily:'inter',maxWidth:'196px'}}>Only one person should be in the frame of the video</p>
                </div>
                <div style={{display:'flex',gap:'10px'}}>
                    <img src={PersonalAvatarCheckIcon} style={{width:'20px',height:'20px'}}/>
                    <p style={{fontSize:'14px',fontWeight:400,fontFamily:'inter',maxWidth:'196px'}}>Face the camera with your face visible at all times</p>
                </div>
            </div>
            <Box 
                sx={{
                    padding: "16px",
                    maxWidth: "345px",
                    height: "75px",
                    borderRadius: "16px",
                    border: "1px solid rgba(255,255,255,0.2)",
                    display: "flex",
                    margin: "0 auto",
                    marginBottom: "34px",
                    cursor: "pointer",
                    backgroundColor: "transparent",
                    transition: "background-color 0.3s ease",
                    "&:hover": { backgroundColor: "#373E6D" },
                    "&:active": { backgroundColor: "#2F355F" },
                }}
                onClick={() => fileInputRef.current.click()} 
            >
                {/* <Tooltip title="Attach files"
                    componentsProps={{
                        tooltip: {
                            style: {
                                color: 'var(--text_dark, #17182C)',
                                backgroundColor: '#FFFFFF',
                                fontFamily: 'Inter',
                                fontSize: '12px',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: '16px',
                            },
                        },
                    }}
                > */}
                    {/* Hidden file input */}
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={(e) => handleFileSelect(e)}
                        accept="video/*"
                    />

                    <Button
                        variant="outline-primary"
                        // onClick={() => fileInputRef.current.click()} // Trigger file input
                        className="mb-1 d-flex align-items-center justify-content-center"
                        style={{
                            width: '40px',
                            height: '40px',
                            backgroundColor: 'transparent',
                            border: 'none',
                            color: 'white',
                            padding: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <FileUploadOutlinedIcon
                            style={{
                                width: '40px',
                                height: '40px',
                                padding: '7px',
                                borderRadius: '50%',
                                color: '#CAC4D0',
                            }}
                            // onMouseEnter={(e) => {
                            //     e.target.style.backgroundColor = 'rgba(202, 196, 208, 0.08)';
                            // }}
                            // onMouseLeave={(e) => {
                            //     e.target.style.backgroundColor = 'transparent';
                            // }}
                        />
                    </Button>
                {/* </Tooltip> */}
                <div
                    style={{
                        height: '43px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                    }}
                >
                    <p style={{ fontSize: '16px', fontWeight: '600', fontFamily: 'Inter', margin: 0 }}>
                        Upload video
                    </p>
                    <p style={{ fontSize: '14px', fontWeight: '400', fontFamily: 'Inter', margin: 0 }}>
                        Record and upload your own file
                    </p>
                </div>
            </Box>
          </div>
        );
      case 3:
        return (
            <div>
                <Typography sx={{ fontSize: "16px", fontWeight: "600", fontFamily: "inter" }}>
                    Preview consent video
                </Typography>
                <Box sx={{display:'flex'}}>
                {uploadedFile ? (
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 3}}>
                    <Box sx={{
                        maxWidth:'384px',
                        maxHeight:'216px',
                        overflow:'hidden'
                    }}>
                        <video 
                            width='100%'
                            height='100%'
                            backgroundColor='red'
                            controls 
                            style={{ borderRadius: "8px", border: "1px solid rgba(255,255,255,0.2)" }}
                            >
                            <source src={URL.createObjectURL(uploadedFile)} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </Box>
        
                    <Button 
                    //   variant="contained" 
                    sx={{
                        mt: 2,
                        backgroundColor: 'transparent',
                        "&:hover": { backgroundColor: 'transparent' },
                        textTransform:'none',
                        color:'white',
                        fontSize:'16px',
                        fontWeight:600,
                        fontFamily:'inter'
                    }} 
                    onClick={() => fileInputRef.current.click()}
                    >
                    Delete and re-upload video
                    </Button>
                    <input 
                    type="file" 
                    accept="video/*" 
                    style={{ display: "none" }} 
                    ref={fileInputRef} 
                    onChange={handleFileSelect}
                    />
                </Box>
                ) : (
                <Typography sx={{ fontSize: "14px", fontWeight: 400, color: "rgba(255,255,255,0.8)", mt: 2 }}>
                    No video uploaded. Please upload your consent video.
                </Typography>
                )}
                <Box sx={{margin:'auto'}}>
                        <Box sx={{maxWidth:'299px',height:'auto',display:'flex',justifyContent:'space-between',marginBottom:'16px'}}>
                            <img src={PersonalAvatarCheckIcon} style={{width:'20px',height:'20px',marginRight:'10px'}} />
                            <Typography sx={{fontSize:'14px',fontWeight:400}}>Pronounce only the provided consent script</Typography>
                        </Box>
                        <Box sx={{maxWidth:'299px',height:'auto',display:'flex',justifyContent:'space-between',marginBottom:'16px'}}>
                            <img src={PersonalAvatarCheckIcon} style={{width:'20px',height:'20px',marginRight:'10px'}} />
                            <Typography sx={{fontSize:'14px',fontWeight:400}}>Only one person should be in the frame of the video</Typography>
                        </Box>
                        <Box sx={{maxWidth:'299px',height:'auto',display:'flex',justifyContent:'space-between',marginBottom:'16px'}}>
                            <img src={PersonalAvatarCheckIcon} style={{width:'20px',height:'20px',marginRight:'10px'}} />
                            <Typography sx={{fontSize:'14px',fontWeight:400}}>Face the camera with your face visible at all times</Typography>
                        </Box>
                        <Box sx={{maxWidth:'299px',height:'auto',display:'flex',justifyContent:'space-between',marginBottom:'16px'}}>
                            <img src={PersonalAvatarCheckIcon} style={{width:'20px',height:'20px',marginRight:'10px'}} />
                            <Typography sx={{fontSize:'14px',fontWeight:400}}>Ensure you have pronounced the name clearly</Typography>
                        </Box>
                    </Box>  
                </Box>
          </div>
        );
      case 4:
        return (
            <div>
                <Typography sx={{ fontSize: "16px", fontWeight: "600", fontFamily: "inter",marginBottom:'20px' }}>
                    Step 2: Record a sample video
                </Typography>
                <Typography sx={{fontSize:'14px',fontWeight:400,color:'rgba(255,255,255,0.8)',fontFamily:'inter',marginBottom:'24px'}}>
                    To create your AI avatar, we will use a one-minute sample video of you speaking into the camera. You can speak about any topic of your choice, such as a personal introduction. Or generate a sample script below.
                </Typography>
                    <Box 
                        sx={{
                            margin: "auto",
                            display: "grid",
                            gridTemplateColumns: "repeat(2, 1fr)", // Corrected Property Name
                            gap: "16px", // Adds space between items
                            marginBottom:'30px'
                          }}
                    >
                        <Box sx={{maxWidth:'299px',height:'auto',display:'flex',justifyContent:'start'}}>
                            <img src={PersonalAvatarCheckIcon} style={{width:'20px',height:'20px',marginRight:'10px'}} />
                            <Typography sx={{fontSize:'14px',fontWeight:400}}>You must face the camera directly</Typography>
                        </Box>
                        <Box sx={{maxWidth:'299px',height:'auto',display:'flex',justifyContent:'start'}}>
                            <img src={PersonalAvatarCheckIcon} style={{width:'20px',height:'20px',marginRight:'10px'}} />
                            <Typography sx={{fontSize:'14px',fontWeight:400}}>Record the video in a quiet, well-lit environment</Typography>
                        </Box>
                        <Box sx={{maxWidth:'299px',height:'auto',display:'flex',justifyContent:'start'}}>
                            <img src={PersonalAvatarCheckIcon} style={{width:'20px',height:'20px',marginRight:'10px'}} />
                            <Typography sx={{fontSize:'14px',fontWeight:400}}>Make sure your mouth is closed during pauses</Typography>
                        </Box>
                        <Box sx={{maxWidth:'299px',height:'auto',display:'flex',justifyContent:'start'}}>
                            <img src={PersonalAvatarCheckIcon} style={{width:'20px',height:'20px',marginRight:'10px'}} />
                            <Typography sx={{fontSize:'14px',fontWeight:400}}>The video must be at least 1 minute long</Typography>
                        </Box>
                    </Box>
                    <Typography sx={{fontSize:'14px',fontWeight:500,fontFamily:'inter',marginBottom:'20px'}}>
                         Example video
                    </Typography>
                    <Box sx={{display:'flex',gap:'61px'}}>
                        <Box sx={{
                            maxWidth:'304px',
                            maxHeight:'171px',
                            overflow:'hidden'
                        }}>
                            <video 
                                width='100%'
                                height='100%'
                                backgroundColor='red'
                                controls 
                                style={{ borderRadius: "8px", border: "1px solid rgba(255,255,255,0.2)" }}
                                >
                                <source src={PersonalAvatarCreationSampleVideo} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </Box>
                        <Box sx={{maxWidth:'345px',maxHeight:'135px'}}>
                            <Box 
                                sx={{width:'210px',height:'36px',padding:'8px 12px',display:'flex',justifyContent:'space-between',bgcolor:'#282D51',borderRadius:'8px',marginBottom:'24px', cursor:'pointer',
                                    "&:hover": { backgroundColor: "#373E6D" },
                                    "&:active": { backgroundColor: "#2F355F" },
                                }}
                            >
                                <DescriptionOutlinedIcon sx={{marginRight:'0px',width:'20px',height:'20px'}}/>
                                <Box sx={{fontSize:'14px',fontWeight:400,fontFamily:'inter'}} onClick={() => setShowSampleScript(true)}>Generate sample script</Box>
                            </Box>
                            <Box 
                                sx={{ 
                                    padding: '16px', 
                                    maxWidth: '345px', 
                                    height: '75px', 
                                    borderRadius: '16px', 
                                    border: '1px solid rgba(255,255,255,0.2)', 
                                    display: 'flex', 
                                    margin: '0 auto', 
                                    marginBottom: '34px',
                                    cursor:'pointer',
                                    transition: "background-color 0.3s ease",
                                    "&:hover": { backgroundColor: "#373E6D" },
                                    "&:active": { backgroundColor: "#2F355F" }, 
                                }} 
                                onClick={() => fileInputRef.current.click()} 
                            >
                                {/* <Tooltip title="Attach files"
                                    componentsProps={{
                                        tooltip: {
                                            style: {
                                                color: 'var(--text_dark, #17182C)',
                                                backgroundColor: '#FFFFFF',
                                                fontFamily: 'Inter',
                                                fontSize: '12px',
                                                fontStyle: 'normal',
                                                fontWeight: 500,
                                                lineHeight: '16px',
                                            },
                                        },
                                    }}
                                > */}
                                    {/* Hidden file input */}
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                        onChange={(e) => handleScriptFileSelect(e)}
                                        accept="video/*"
                                    />

                                    <Button
                                        variant="outline-primary"
                                        // onClick={() => fileInputRef.current.click()} // Trigger file input
                                        className="mb-1 d-flex align-items-center justify-content-center"
                                        style={{
                                            width: '40px',
                                            height: '40px',
                                            backgroundColor: 'transparent',
                                            border: 'none',
                                            color: 'white',
                                            padding: 0,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <FileUploadOutlinedIcon
                                            style={{
                                                width: '40px',
                                                height: '40px',
                                                padding: '7px',
                                                borderRadius: '50%',
                                                color: '#CAC4D0',
                                            }}
                                            // onMouseEnter={(e) => {
                                            //     e.target.style.backgroundColor = 'rgba(202, 196, 208, 0.08)';
                                            // }}
                                            // onMouseLeave={(e) => {
                                            //     e.target.style.backgroundColor = 'transparent';
                                            // }}
                                        />
                                    </Button>
                                {/* </Tooltip> */}
                                <div
                                    style={{
                                        height: '43px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'flex-start',
                                    }}
                                >
                                    <p style={{ fontSize: '16px', fontWeight: '600', fontFamily: 'Inter', margin: 0 }}>
                                        Upload video
                                    </p>
                                    <p style={{ fontSize: '14px', fontWeight: '400', fontFamily: 'Inter', margin: 0 }}>
                                        Record and upload your own file
                                    </p>
                                </div>
                            </Box>
                        </Box>
                    </Box>
                    
            </div>
        );
      case 5: 
        return (
            <div>
                <Typography sx={{ fontSize: "16px", fontWeight: "600", fontFamily: "inter" }}>
                        Preview consent video
                </Typography>
                <Box sx={{display:'flex'}}>
                {uploadedFile ? (
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 3}}>
                    <Box sx={{
                        maxWidth:'384px',
                        maxHeight:'216px',
                        overflow:'hidden'
                    }}>
                        <video 
                            width='100%'
                            height='100%'
                            backgroundColor='red'
                            controls 
                            style={{ borderRadius: "8px", border: "1px solid rgba(255,255,255,0.2)" }}
                            >
                            <source src={URL.createObjectURL(uploadedScript)} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </Box>
        
                    <Button 
                    //   variant="contained" 
                    sx={{
                        mt: 2,
                        backgroundColor: 'transparent',
                        "&:hover": { backgroundColor: 'transparent' },
                        textTransform:'none',
                        color:'white',
                        fontSize:'16px',
                        fontWeight:600,
                        fontFamily:'inter'
                    }} 
                    onClick={() => fileInputRef.current.click()}
                    >
                    Delete and re-upload video
                    </Button>
                    <input 
                    type="file" 
                    accept="video/*" 
                    style={{ display: "none" }} 
                    ref={fileInputRef} 
                    onChange={handleScriptFileSelect}
                    />
                </Box>
                ) : (
                <Typography sx={{ fontSize: "14px", fontWeight: 400, color: "rgba(255,255,255,0.8)", mt: 2 }}>
                    No video uploaded. Please upload your consent video.
                </Typography>
                )}
                <Box sx={{margin:'25px auto'}}>
                        <Box 
                            sx={{ 
                                maxWidth: '299px', 
                                height: 'auto', 
                                display: 'flex', 
                                alignItems: 'center', // Ensures vertical alignment
                                marginBottom: '16px' 
                            }}
                            >
                            <img alt="PA"
                                src={PersonalAvatarCheckIcon} 
                                style={{ width: '20px', height: '20px', marginRight: '10px' }} 
                            />
                            <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>
                                You must face the camera directly
                            </Typography>
                        </Box>
                        <Box sx={{maxWidth:'299px',height:'auto',display:'flex',justifyContent:'space-between',marginBottom:'16px'}}>
                            <img src={PersonalAvatarCheckIcon} style={{width:'20px',height:'20px',marginRight:'10px'}} />
                            <Typography sx={{fontSize:'14px',fontWeight:400}}>Make sure the video is recorded in a quiet, well-lit environment</Typography>
                        </Box>
                        <Box sx={{maxWidth:'299px',height:'auto',display:'flex',justifyContent:'space-between',marginBottom:'16px'}}>
                            <img src={PersonalAvatarCheckIcon} style={{width:'20px',height:'20px',marginRight:'10px'}} />
                            <Typography sx={{fontSize:'14px',fontWeight:400}}>Make sure your mouth is closed during pauses</Typography>
                        </Box>
                    </Box>  
                </Box>
          </div>
        )
      default:
        return null;
    }
  };

  return (
    <>
        <CommonToast 
            open={isToastOpen}
            onClose={handleCloseToast}
            content={notfMsg}
            bgColor="#282D51"
            fontColor="#fff"
            fontSize="14px"
            fontWeight="500"
            autoHideDuration={3000}
            iconType = {notfType !== 'error'?'success':'error'}
        />
        {/* Personal Avatar Modal */}
        <Dialog
            open={showPersonalAvatarModal}
            onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                    setShowClosingWarning(true);
                    // handleClose();
                }
            }}
            maxWidth="lg"
            fullWidth
            PaperProps={{
                sx: {
                    width: "750px",
                    maxHeight: "650px",
                    gap: "0px",
                    borderRadius: "8px",
                    bgcolor: "#282D51",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                },
            }}
        >
            <div
                style={{
                    backgroundColor: theme === "dark" ? '#1F233E' : "",
                    color: theme === "dark" ? "white" : "",
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                }}
            >
                {/* Header Section */}
                <Box
                    sx={{
                        width: "100%",
                        height: "68px",
                        padding: "20px",
                        borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
                    }}
                >
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography sx={{ fontSize: "16px", fontWeight: 600, color: "#FFFFFF" }}>
                            Create your personal AI avatar
                        </Typography>
                        <CloseIcon
                            // onClick={handleClose}
                            onClick={() => setShowClosingWarning(true)}
                            aria-label="close"
                            sx={{ color: "#FFFFFF", cursor: "pointer" }}
                        />
                    </Box>
                </Box>
                <Box sx={{padding:"0px 20px"}}>
                    <CommanLinearStepLoader currentStep={currentStep}/>
                </Box>
                {/* Content Section */}
                <DialogContent sx={{ padding: "20px", flexGrow: 1, overflowY: "auto" }}>
                    {!loader && renderStepContent()}
                    {loader && currentStep === 2 && (
                        <CircularLoader msg="We’re processing your consent video. This usually takes less than a minute." />
                    )}
                    {loader && currentStep === 4 && (
                        <CircularLoader msg="We’re processing your sample video. This usually takes less than a minute." />
                    )}
                </DialogContent>

                {/* Footer Section */}
                <Box
                    sx={{
                        display: currentStep === 2 || currentStep === 4 ? 'none' : 'flex',
                        width: "100%",
                        bgcolor: "#1F233E",
                        boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.12)",
                        padding: "16px 20px",
                        justifyContent: "flex-end",
                    }}
                >
                    <DialogActions sx={{ padding: "0px", display: "flex", gap: "16px" }}>
                        {currentStep === 5 && (
                            <Box sx={{ maxWidth: '464px', display: 'flex', gap: '8px', color: 'rgba(255, 255, 255, 0.80)' }}>
                                <InfoOutlinedIcon sx={{ width: '16px', height: '16px' }} />
                                <Typography sx={{ fontSize: '13px', fontWeight: 400 }}>
                                    This name will be used for recording your consent and will be the name of your avatar.
                                </Typography>
                            </Box>
                        )}
                        
                        {currentStep === 1 && (
                            <Button
                                variant="text"
                                sx={{
                                    backgroundColor: button_bg_color_for_New_Project,
                                    height: "40px",
                                    padding: "10px 24px",
                                    borderRadius: "4px",
                                    "&:hover": { backgroundColor: "#FFD9AA" },
                                    color: "black",
                                }}
                                onClick={handleStep1}
                            >
                                <Typography sx={{ fontSize: "16px", fontWeight: 600, color: "#17182C", textTransform: "none" }}>
                                    Continue
                                </Typography>
                            </Button>
                        )}

                        {currentStep === 3 && (
                            <Button
                                variant="text"
                                sx={{
                                    backgroundColor: button_bg_color_for_New_Project,
                                    height: "40px",
                                    padding: "10px 24px",
                                    borderRadius: "4px",
                                    "&:hover": { backgroundColor: "#FFD9AA" },
                                    color: "black",
                                }}
                                onClick={handleNext}
                            >
                                <Typography sx={{ fontSize: "16px", fontWeight: 600, color: "#17182C", textTransform: "none" }}>
                                    Submit video and proceed to next step
                                </Typography>
                            </Button>
                        )}

                        {currentStep === 5 && (
                            <Button
                                variant="text"
                                sx={{
                                    backgroundColor: button_bg_color_for_New_Project,
                                    height: "40px",
                                    padding: "10px 24px",
                                    borderRadius: "4px",
                                    "&:hover": { backgroundColor: "#FFD9AA" },
                                    color: "black",
                                }}
                                onClick={() => {
                                    handleClose();
                                    fetchAvatars(setAvatars, setAvatarsLoading, setPersonalAvatar);
                                }}
                            >
                                <Typography sx={{ fontSize: "16px", fontWeight: 600, color: "#17182C", textTransform: "none" }}>
                                    Submit video and finish
                                </Typography>
                            </Button>
                        )}
                    </DialogActions>
                </Box>
            </div>
        </Dialog>

        {/* Sample Script Modal */}
        {showSampleScript && (
            <Dialog
            open={showSampleScript}
            onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                    handleCloseScript();
                }
            }}
            maxWidth="lg"
            fullWidth
            PaperProps={{
                sx: {
                    width: "480px",
                    // maxHeight: "468px",
                    gap: "0px",
                    borderRadius: "8px",
                    bgcolor: "#1F233E",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                },
            }}
        >
            <div
                style={{
                    backgroundColor: theme === "dark" ? '#1F233E' : "",
                    color: theme === "dark" ? "white" : "",
                    display: "flex",
                    flexDirection: "column",
                    height: "100%"
                }}
            >
                {/* Header Section */}
                <Box
                    sx={{
                        width: "100%",
                        height: "68px",
                        padding: "20px",
                        borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
                    }}
                >
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography sx={{ fontSize: "16px", fontWeight: 600, color: "#FFFFFF" }}>
                            Sample script
                        </Typography>
                        <CloseIcon
                            onClick={handleCloseScript}
                            aria-label="close"
                            sx={{ color: "#FFFFFF", cursor: "pointer" }}
                        />
                    </Box>
                </Box>

                {/* Content Section */}
                <DialogContent sx={{ padding: "20px", flexGrow: 1, overflowY: "auto" }}>
                    <Typography sx={{fontSize:'14px',fontWeight:'500',fontFamily:'inter',marginBottom:'24px'}}>Record yourself narrating this script</Typography>
                    <div style={{fontSize:'16px',fontWeight:'400',width:'440px',height:'260px',padding:'20px',color:'rgba(255,255,255,0.8)',border:'1px solid rgba(255,255,255,0.2)',borderRadius:'8px',backgroundColor:'#232746',overflow:'scroll'}}>
                        Hello, everyone. Today marks the beginning of a journey—one of self-discovery. Life is a vast canvas, and within each of us lies untapped potential waiting to be revealed. In the rush of daily life, it’s essential to pause, reflect, and reconnect with what truly excites us. What sparks your passion? What sets your soul on fire?
                        Now is the time to step beyond the familiar, explore new interests, and unlock your full potential. Growth begins at the edge of your comfort zone. Challenge yourself, face your fears, and remember—the only real limits are the ones you create.
                        Celebrate every step forward, no matter how small, and see setbacks not as failures but as lessons that guide you toward something greater. Surround yourself with people who uplift and inspire you because the right community can be the key to unlocking your true potential.
                        Your journey starts now. Embrace the possibilities, push beyond the ordinary, and create a life that reflects the very best of who you are. Every obstacle is a chance to grow, every challenge an opportunity to prove your strength. So move forward with courage and determination—because the path ahead is yours to shape.
                        The question is… are you ready to begin?
                    </div>
                </DialogContent>

                {/* Footer Section */}
                <Box
                    sx={{
                        width: "100%",
                        bgcolor: "#1F233E",
                        boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.12)",
                        padding: "16px 20px",
                        display: "flex",
                        justifyContent: "center", // Center content
                    }}
                >
                    <DialogActions sx={{ padding: "0px", display: "flex", gap: "16px", justifyContent: "center", width: "100%" }}>
                        <Button
                            variant="text"
                            sx={{
                                backgroundColor: button_bg_color_for_New_Project,
                                height: "40px",
                                padding: "10px 24px",
                                borderRadius: "4px",
                                "&:hover": { backgroundColor: "#FFD9AA" },
                                color: "black",
                                fontFamily:'inter'
                            }}
                            onClick={() => setShowSampleScript(false)}
                        >
                            <Typography sx={{ fontSize: "16px", fontWeight: 600, color: "#17182C", textTransform: "none" }}>
                                Done
                            </Typography>
                        </Button>
                    </DialogActions>
                </Box>
            </div>
        </Dialog>
        )}
        {showClosingWarning &&
            <Dialog
            open={showClosingWarning}
            onClose={handleCloseClosingWarningModal}
            maxWidth="sm"
            PaperProps={{
              sx: {
                maxWidth: "480px", // Ensures modal width is capped at 480px
                width: "100%", // Makes it responsive for smaller screens
                borderRadius: "16px",
                bgcolor: "#1F233E",
                margin: "auto", // Centers the modal vertically
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              },
            }}
          >
            <IconButton
              onClick={handleCloseClosingWarningModal}
              sx={{
                position: "absolute",
                top: "20px",
                right: "20px",
                color: "white",
              }}
            >
              <CloseIcon />
            </IconButton>
            <div
              style={{
                backgroundColor: theme === "dark" ? "#1F233E" : "",
                color: theme === "dark" ? "white" : "",
                width: "100%",
                padding: "20px",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "60px",
                  height: "60px",
                  bgcolor: "#282D51",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <InfoOutlinedIcon style={{ width: "36px", height: "36px" }} />
              </Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  fontFamily: "Inter",
                  margin: "20px 0",
                  textAlign: "center",
                }}
              >
                Stop creating avatar?
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "400",
                  fontFamily: "Inter",
                  padding: "0 10%",
                  textAlign: "center",
                  marginBottom: "20px",
                  color: "rgba(255, 255, 255, 0.80)",
                }}
              >
                If you exit the avatar creation process now, you will have to start over again later. 
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "400",
                  fontFamily: "Inter",
                  padding: "0 10%",
                  textAlign: "center",
                  marginBottom: "20px",
                  color: "rgba(255, 255, 255, 0.80)",
                }}
              >
                Are you sure you want to close the process now?
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{
                    width: "90%",
                    maxWidth: "400px",
                    textTransform: "none",
                    bgcolor: button_bg_color_for_New_Project_dm,
                    "&:hover": {
                      bgcolor: "#ffd9aa",
                      border: "none",
                    },
                    color: "#17182C",
                    fontSize: "16px",
                    fontWeight: 600,
                    fontFamily: "Inter",
                  }}
                  onClick={() => {
                    setShowClosingWarning(false);
                    handleClose();
                  }}
                >
                  Yes, close avatar creation
                </Button>
                <Button
                  sx={{
                    width: "90%",
                    maxWidth: "400px",
                    textTransform: "none",
                    color: "#F6BE6A",
                    fontSize: "16px",
                    fontWeight: 600,
                    fontFamily: "Inter",
                    border: "2px solid #F6BE6A",
                  }}
                  onClick={() => {
                    setShowClosingWarning(false);
                  }}
                >
                  Continue creating avatar
                </Button>
              </Box>
            </div>
          </Dialog>
        }
    </>
  );

}

export default PersonalAvatar;
import React, { useState, useEffect } from "react";
import { Box, Typography, CardMedia, IconButton, Tooltip, useMediaQuery } from "@mui/material";
import music from "../assets/SuggestionIcon/MusicIconFormusic.png";
import image from "../assets/SuggestionIcon/PictureIconForpicture.png";
import video from "../assets/SuggestionIcon/videoIconForvideo.png";
import search from "../assets/SuggestionIcon/searchIconForsearch.png";
import blog from "../assets/SuggestionIcon/BlogIconForblog.png";
import presentation from "../assets/SuggestionIcon/presentationIconForpresentation.png";
import ImagePicture from "../assets/SuggestionIcon/imagePicture.png";
import VideoPicture from "../assets/SuggestionIcon/videoPicture.png";
import BlogPicture from "../assets/SuggestionIcon/blogPicture.png";
import PresentationPicture from "../assets/SuggestionIcon/presentationPicture.png";
import SearchPicture from "../assets/SuggestionIcon/searchPicture.png";
import MusicPicture from "../assets/SuggestionIcon/audioPicture.png";
import { useWorkspace } from "../contexts/WorkspaceContext";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { trackModalOpen } from "../config/analytics";

const NewProjectUI = () => {

  const [textToType, setTextToType] = useState("");
  const [typingIndex, setTypingIndex] = useState(0);
  const [currentButtonClick, setCurrentButtonClick] = useState();
  const [oneButtonClick, setOneButtonClick] = useState(false);
  const { input,setInput,autoTypingInPromptCompleted, setAutoTypingInPromptCompleted,setShowImageSettings,setShowVideoSettings,setShowAudioSettings,setShowPptSettings } = useWorkspace();
  const [hoveredCard, setHoveredCard] = useState(null);
  const mobileView = useMediaQuery('(max-width:992px)');

  const cardArray = [
    {id:1, Image: ImagePicture, Title: "Create an image",TitleForPromptBox:"Create an image of ", smallImage: image, SecondTitle: "Complete the prompt in the chat box below to generate the Image",settingsTitle:'Image Settings' },
    {id:2, Image: VideoPicture, Title: "Generate a video",TitleForPromptBox:"Generate a video of ", smallImage: video, SecondTitle: "Complete the prompt in the chat box below to generate the Video",settingsTitle:'Video Settings'  },
    {id:3, Image: MusicPicture, Title: "Generate audio",TitleForPromptBox:"Generate audio of ",smallImage: music, SecondTitle: "Complete the prompt in the chat box below to generate the Audio",settingsTitle:'Audio Settings'  },
    {id:4,
      Image: PresentationPicture,
      Title: "Create a presentation",
      TitleForPromptBox:"Create a presentation of ",
      smallImage: presentation,
      SecondTitle: "Complete the prompt in the chat box below to generate the presentation" 
    },
    {id:5, Image: SearchPicture, Title: "Search for anything",TitleForPromptBox:"Search for ", smallImage: search, SecondTitle: "Complete the prompt in the chat box below to Search for anything"  },
    {id:6, Image: BlogPicture, Title: "Write an article",TitleForPromptBox: "Write an article about ", smallImage: blog,  SecondTitle: "Complete the prompt in the chat box below to write an article" },
  ];

  const handleSettingsClick = async (card) => {
    if(card?.id === 1) {
      setShowImageSettings(true);
      trackModalOpen(card?.Title);
    }  else if(card?.id === 2) {
      setShowVideoSettings(true);
      trackModalOpen(card?.Title);
    }  else if(card?.id === 3) {
      setShowAudioSettings(true);
      trackModalOpen(card?.Title);
    }  else if (card?.id === 4) {
      setShowPptSettings(true);
      trackModalOpen(card?.Title);
    }
  };

  const handleButtonClick = (card) => {
    setTextToType(card.TitleForPromptBox); 
    setTypingIndex(0); 
    setInput(""); 
    setCurrentButtonClick(card);
    setOneButtonClick(true)
  };

  useEffect(() => {
    if (typingIndex < textToType.length) {
      const timer = setTimeout(() => {
        setInput((prev) => prev + textToType[typingIndex]); // Add one character
        setTypingIndex((prev) => prev + 1); // Move to the next character
      }, 100); // Typing speed (in milliseconds)

      return () =>{ clearTimeout(timer); }// Clear timeout on unmount or update
    }else if(typingIndex === textToType.length && textToType.length > 0){
      setAutoTypingInPromptCompleted(!autoTypingInPromptCompleted); // Update the global state
    }
  }, [typingIndex, textToType,]);

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center", marginBottom: 8 }}>
        <Box
          sx={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "row",
            justifyContent: "center",
            flexWrap: "wrap",
            maxWidth: "750px",
            marginTop: 7.5,
            rowGap: "40px",
            columnGap: "30px",
          }}
        >
          {cardArray.map((card, index) => {
            return (
              <>
              {
                currentButtonClick?.id === card.id  ?
                <Box
                  onClick={() => handleButtonClick(card)}
                  onMouseEnter={() => setHoveredCard(card.id)}
                  onMouseLeave={() => setHoveredCard(null)}
                  key={card.id}
                  sx={{
                    position: "relative",
                    border: "1px solid rgba(255, 255, 255, 0.20)",
                    display: "flex",
                    flexDirection:"column",
                    alignItems:"center",
                    justifyContent:"center",
                    maxWidth: "360px",
                    height: "154px",
                    gap: "20px",
                    padding: "20px",
                    borderRadius: "16px",
                    cursor:"pointer",
                  backgroundColor:"#1F233E"
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "row", alignItems:"center", columnGap:"8px", }}>
                  {!mobileView && hoveredCard === card.id && ((hoveredCard === 1) || (hoveredCard === 2) || (hoveredCard === 3)) && (
                    <Tooltip 
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#E6E0E9",
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontWeight: 500,
                            padding: "4px 8px 4px 8px",
                            borderRadius: "4px",
                            textAlign: "center",
                          },
                        },
                      }}
                      title={
                        <Box sx={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center"}}>
                        <Box sx={{fontFamily:"Inter", fontStyle:"normal", fontWeight:500, lineHeight:"16px", fontSize:"14px", color:"#17182C"}}>
                          {card?.settingsTitle}
                        </Box>
                       </Box>
                       }
                      placement="bottom"
                    >
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: 8,
                          right: 8,
                          padding: "7px",
                          borderRadius: "50%",
                          color: "#CAC4D0",
                          '&:hover': {
                            backgroundColor: "rgba(202, 196, 208, 0.08)",
                          },
                        }}
                        onClick={() => handleSettingsClick(card)}
                      >
                        <SettingsOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                    <CardMedia
                      component="img"
                      image={card.smallImage}
                      alt={card.Title}
                      sx={{ width: "24px", height: "24px" }}
                    />
                    <Typography
                      component="div"
                      style={{
                        fontFamily: "Inter",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "22.4px",
                        letterSpacing: "0.1px",
                        color: "#ffffff",
                      }}
                    >
                      {card.TitleForPromptBox}
                    </Typography>
                  </Box>
                  <Box sx={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                  <Typography
                      component="div"
                      style={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "19.6px",
                        letterSpacing: "0.1px",
                        color: "rgba(255, 255, 255, 0.8)",
                        textAlign:"center",
                      }}
                    >
                      {card.SecondTitle}
                    </Typography>
                  </Box>
                </Box>
                :
                <Box 
                  onClick={() => handleButtonClick(card)}
                  onMouseEnter={() => setHoveredCard(card.id)}
                  onMouseLeave={() => setHoveredCard(null)}
                  key={index}
                  sx={{
                    border: "1px solid rgba(255, 255, 255, 0.20)",
                    display: "flex",
                    alignItems: "center",
                    maxWidth: "360px",
                    gap: "20px",
                    padding: "20px",
                    borderRadius: "16px",
                    cursor:"pointer",
                    '&:hover':{
                      backgroundColor:"#1F233E"
                    }
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <CardMedia
                      component="img"
                      image={card.smallImage}
                      alt={card.Title}
                      sx={{ width: "24px", height: "24px" }}
                    />
                    <Typography
                      component="div"
                      style={{
                        marginTop: "10px",
                        width: "100px",
                        fontFamily: "Inter",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "22.4px",
                        letterSpacing: "0.1px",
                        color: "#CAC4D0",
                      }}
                    >
                      {card.Title}
                    </Typography>
                  </Box>
                  <Box>
                    <CardMedia
                      component="img"
                      image={card.Image}
                      alt={card.Title}
                      sx={{
                        width: "200px",
                        height: "112px",
                        borderRadius: "4px",
                      }}
                    />
                  </Box>
                </Box>
              }
              </>
            );
          })}
        </Box>
      </Box>
    </>
  );
};

export default NewProjectUI;

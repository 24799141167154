import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import Pagination from "@mui/material/Pagination";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import AddInvestorModal from "./AddInvestorModal";
import sourceUrl from "../../api/Source.api";

const Investors = ({ token }) => {
  const [investors, setInvestors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalInvestorsCount, setTotalInvestorsCount] = useState(0);
  const [searchEmail, setSearchEmail] = useState("");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchInvestors = async () => {
      try {
        setLoading(true);
        const res = await axios.get(`${sourceUrl}/admin/investors`, {
          params: { page: currentPage, pagesize: 10, email: searchEmail },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (res.data && res.data.data) {
          setInvestors(res.data.data.data);
          setTotalPages(Math.ceil(res.data.data.totalRows / 10));
          setTotalInvestorsCount(res.data.data.totalRows);
        }
      } catch (error) {
        console.error(error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchInvestors();
  }, [currentPage, searchEmail, token]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleSearchChange = (event) => {
    setSearchEmail(event.target.value);
  };

  const handleAddInvestor = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSaveInvestor = async (investorData) => {
    try {
      const response = await axios.post(
        `${sourceUrl}/admin/investors`,
        investorData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        }
      );

      if (response.data) {
        setShowModal(false);
        const res = await axios.get(`${sourceUrl}/admin/investors`, {
          params: { page: currentPage, pagesize: 10 },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setInvestors(res.data.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const theme = createTheme({
    typography: {
      fontFamily: "Inter",
    },
    components: {
      MuiPagination: {
        styleOverrides: {
          root: {
            "& .MuiPaginationItem-root": {
              color: "#ffffff",
              borderRadius: "20px",
              "&:hover": {
                backgroundColor: "rgba(179, 168, 183, 0.26)",
              },
              fontSize: "14px",
            },
            "& .MuiPaginationItem-page.Mui-selected": {
              backgroundColor: "#CAC4D0",
              color: "black",
              "&:hover": {
                backgroundColor: "#CAC4D0",
              },
            },
            "& .MuiPaginationItem-ellipsis": {
              color: "#ffffff",
            },
            "& .MuiPaginationItem-previousNext": {
              color: "#ffffff",
              borderRadius: "20px",
            },
          },
        },
      },
    },
  });

  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex justify-content-between mb-3">
          <h5 className="card-title">Total Investors Count:</h5>
          <h5 className="card-title text-primary">{totalInvestorsCount}</h5>
        </div>
        <div className="mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Search by email"
            value={searchEmail}
            onChange={handleSearchChange}
          />
        </div>
        <button className="btn btn-primary mb-3" onClick={handleAddInvestor}>
          Add New Investor
        </button>
        
        <div className="table-responsive" style={{ maxHeight: "300px", overflowY: "auto" }}>
          <table className="table table-striped table-hover">
            <thead className="thead-dark" style={{ position: "sticky", top: 0, zIndex: 1 }}>
              <tr>
                <th>Email</th>
                <th>Name</th>
                <th>Company</th>
                <th>LinkedIn</th>
                <th>Company URL</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="5" className="text-center">
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </td>
                </tr>
              ) : error ? (
                <tr>
                  <td colSpan="5" className="text-center text-danger">
                    Error loading investors
                  </td>
                </tr>
              ) : investors.length === 0 ? (
                <tr>
                  <td colSpan="5" className="text-center">
                    No investors found
                  </td>
                </tr>
              ) : (
                investors.map((investor, index) => (
                  <tr key={index}>
                    <td>{investor.email}</td>
                    <td>{`${investor.firstname} ${investor.lastname}`.trim()}</td>
                    <td>{investor.companies}</td>
                    <td>
                      {investor.linkedin_company_page ? (
                        <a href={investor.linkedin_company_page} target="_blank" rel="noopener noreferrer">
                          View
                        </a>
                      ) : (
                        "N/A"
                      )}
                    </td>
                    <td>
                      {investor.urls ? (
                        <a href={investor.urls} target="_blank" rel="noopener noreferrer">
                          Visit
                        </a>
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        
        <div className="d-flex justify-content-center mt-3">
          <ThemeProvider theme={theme}>
            <Pagination
              count={totalPages}
              page={currentPage}
              siblingCount={1}
              boundaryCount={1}
              onChange={handlePageChange}
              shape="rounded"
              size="large"
            />
          </ThemeProvider>
        </div>
      </div>
      <AddInvestorModal
        show={showModal}
        handleClose={handleCloseModal}
        handleSave={handleSaveInvestor}
      />
    </div>
  );
};

export default Investors;
import { Box, Button, CardMedia, Typography, Tooltip, Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import React, { useState, useEffect } from "react";
import RepeatIcon from "@mui/icons-material/Repeat";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ShareIcon from "@mui/icons-material/Share";
import { useWorkspace } from "../../../contexts/WorkspaceContext";
import ShareModalForSocialMedia from "./ShareModalForSocialMedia";
import { trackSearchInteraction } from "../../../config/analytics";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { useMediaQuery } from "react-responsive";
import { GetAccessToken } from "./apicall";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AdditionalOptionForLastConversationInSmallScreen from "./AdditionalOptionForLastConversationInSmallScreen";
import LimitedAdditionalOptionsExceptLastConversationsInSmallScreen from "./LimitedAdditionalOptionsExceptLastConversationsInSmallScreen";
import SpeakerIcon from "@mui/icons-material/VolumeUp";
import StopIcon from "@mui/icons-material/Stop";
import { useDailyLimitExceed } from "../../../contexts/DailyLimitExceedContext";
import { decode } from "html-entities";

function LimitedAdditionalOptionsExceptLastConversations({ message,index, onUserAction }) {
  const content = (message?.sender === "modalX" && message.content) || message?.agent
  const [clickedItems, setClickedItems] = useState({});
  const [clickedItems1, setClickedItems1] = useState({});
  const [selectedAction, setSelectedAction] = useState()
  const [moreoption, setMoreOption] = useState(false);
  const { sending, shareModalforSocialMedia, setShareModalforSocialMedia, setEditClick, setSending, setWaitThread, setCurrentThread, sendMessage, currentThread, setShowCreditRecharge, setArrayForLinksAvalible, setHasLink, setVisibleStopGenerationButton } = useWorkspace()
  // const [state , setState] = useState(message)
  const isXsOrSm = useMediaQuery({ maxWidth: 1600 });
  const isUnder991px = useMediaQuery({ maxWidth:991});
  const [isNarrating, setIsNarrating] = useState(false);
  const isLoggedIn = localStorage.getItem('isLoggedIn');
  const token  = localStorage.getItem('token');
  const{setDailylimitExceed} = useDailyLimitExceed()
  const [currentNarrate, setCurrentNarrate] = useState(null)
  // const decodeUnicode = (str) => {
  //   // const stringWithoutDoubleBackslashes = str.split("\\\")
  //   const stringNewLine = str.split("\\n").join("\n")
  //   let stringNewLineWithAnchorTag = stringNewLine.replace(/\\u[\dA-F]{4}|u[\dA-F]{4}/gi, (match) => {
  //     const hexCode = match.replace(/\\?u/g, ''); // Remove '\u' or 'u'
  //     return String.fromCharCode(parseInt(hexCode, 16)); // Convert hex code to character
  //   });
  //   const finalString = stringNewLineWithAnchorTag.replace(/\\/g, '');
  //   return finalString
  // };

  useEffect(()=>{
    return ()=>{
      setCurrentNarrate(null)
      setIsNarrating(false)
      window.speechSynthesis.cancel();
    }
  },[])

  const decodeUnicode = (str) => {
    // Ensure str is a valid string, if not, return an empty string or handle it as needed
    if (!str || typeof str !== "string") return str || "";

    // Handle new line replacements
    const stringNewLine = str.split("\\n").join("\n");

    // Handle unicode characters
    let stringNewLineWithAnchorTag = stringNewLine.replace(/\\u[\dA-F]{4}|u[\dA-F]{4}/gi, (match) => {
      const hexCode = match.replace(/\\?u/g, ''); // Remove '\u' or 'u'
      return String.fromCharCode(parseInt(hexCode, 16)); // Convert hex code to character
    });

    // Remove any remaining backslashes
    const finalString = stringNewLineWithAnchorTag.replace(/\\/g, '');

    return finalString;
  };


  // Function to decode HTML entities
  const decodeContent = (content) => {
    return decodeUnicode((content));
  };

  const removeUrlsAndBrackets = (msg) => {
    return msg.replace(/\(https?:\/\/[^']*?\.(mp3|mp4|pptx|jpg|jpeg|png|txt|ppt|pdf)\)/g, '');
  };

  // const toggleNarration = () => {
  //   if (isNarrating) {
  //     // Stop narration
  //     window.speechSynthesis.cancel();
  //   } else {
  //     // Start narration
  //     const utterance = new SpeechSynthesisUtterance(decodeUnicode(removeUrlsAndBrackets(content)));
  //     window.speechSynthesis.speak(utterance);

  //     // Stop narration when the utterance ends
  //     utterance.onend = () => {
  //       setIsNarrating(false);
  //     };
  //   }
  //   setIsNarrating(!isNarrating);
  // };

  const toggleNarration = (index) => {
    setCurrentNarrate(index)
    if (isNarrating) {
      // Stop current narration
      window.speechSynthesis.cancel();
      setIsNarrating(false);
      return;
    }
    const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/gu;
    // Split content into chunks
    const splitContentIntoChunks = (text, maxLength = 200) => {
      const cleanText = text.replace(/[*#]|--+|-/g, " ").replace(/\n/g, ". ").replace(emojiRegex, '').replace(/`/g, "").replace(/<[^>]*>/g, "").replace(/^(\{|\}|\()|(\{|\}|\))$/g, "");
      const sentences = cleanText.match(/[^.!?]+[.!?]/g) || [cleanText];
      const chunks = [];
      let currentChunk = "";
  
      sentences.forEach((sentence) => {
        if ((currentChunk + sentence).length <= maxLength) {
          currentChunk += sentence;
        } else {
          chunks.push(currentChunk);
          currentChunk = sentence;
        }
      });
  
      if (currentChunk) chunks.push(currentChunk.trim());
      return chunks;
    };
  
    // Narrate chunks sequentially
    const narrateChunks = (chunks, index = 0, targetVoice = null) => {
      if (index >= chunks.length) {
        setIsNarrating(false); // Narration complete
        setCurrentNarrate(null)
        return;
      }
  
      const utterance = new SpeechSynthesisUtterance(decodeUnicode(removeUrlsAndBrackets(chunks[index])));
  
      // Set the same voice consistently
      if (targetVoice) {
        utterance.voice = targetVoice;
      }
  
      // On utterance end, narrate the next chunk
      // utterance.onend = () => narrateChunks(chunks, index + 1, targetVoice);
  
      utterance.onend = () => {
        setTimeout(() => narrateChunks(chunks, index + 1, targetVoice), 200); // Add a delay
      };
      

      // Handle errors
      utterance.onerror = (err) => {
        setIsNarrating(false);
        setCurrentNarrate(null)
        // narrateChunks(chunks, index + 1, targetVoice); // Continue with the next chunk
      };
  
      // Speak the current chunk
      window.speechSynthesis.speak(utterance);
    };
  
    const setVoiceAndStart = () => {
      const voices = window.speechSynthesis.getVoices();
  
      // Find and select the desired voice
      const targetVoice = voices.find((voice) => voice.name === "Google US English");
  
      if (!targetVoice) {
        console.warn("Desired voice not found. Using default voice.");
      }
  
      // Start narration with the selected voice
      setIsNarrating(true);
      const chunks = splitContentIntoChunks(decodeUnicode(removeUrlsAndBrackets(content)));
      narrateChunks(chunks, 0, targetVoice);
    };
  
    // Cancel any ongoing speech synthesis before starting new narration
    window.speechSynthesis.cancel();
  
    // Handle asynchronous loading of voices
    if (window.speechSynthesis.getVoices().length === 0) {
      window.speechSynthesis.addEventListener("voiceschanged", setVoiceAndStart, { once: true });
    } else {
      setVoiceAndStart();
    }
  };

  const decodedContent = decodeContent(content);

  const handleShare = () => {
    setShareModalforSocialMedia(true)
  }

  const [copy, setCopy] = useState(false);

  // const copyFunc = () => {
  //   const cleanContent = decodedContent
  //     .replace(/[#*-]/g, '')
  //     .replace(/^\s*\d+\./gm, match => `\n${match}`)
  //     .replace(/\n{2,}/g, '\n') 
  //     .trim();
  
  //   navigator.clipboard.writeText(decode(cleanContent)).then(() => {
  //     setCopy(true);
  //     setTimeout(() => setCopy(false), 2000);
  //   });
  // };

  const copyFunc = () => {
    // Clean the content while preserving formatting
    const cleanContent = decodedContent
      .replace(/\\([#*-])/g, '$1') // Unescape special characters like #, *, -
      .replace(/^\s*\d+\./gm, match => `\n${match}`) // Ensure numbered lists start on a new line
      .replace(/\n{2,}/g, '\n') // Remove extra newlines
      .trim();
  
    // Copy the cleaned content to the clipboard
    navigator.clipboard.writeText(cleanContent).then(() => {
      setCopy(true);
      setTimeout(() => setCopy(false), 2000);
    });
  };
  

  // Toggle the clicked status for the item with the given id
  const handleClick = (id) => {
    // setClickedItems((prevState) => ({
    //   ...prevState,
    //   [id]: !prevState[id], // Toggle between true and false
    // }));
    setClickedItems(id);
  };

  const handleClick1 = (id) => {
    setClickedItems1((prevState) => ({
      ...prevState,
      [id]: !prevState[id], // Toggle between true and false
    }));
  };

  const findUrl = (msg) => {
    const urlRegex = /(https?:\/\/[^']*?\.(mp3|mp4|pptx|jpg|jpeg|png|txt|ppt))/g;
    const match = urlRegex.exec(msg);
    return match ? match[1] : null;
  };

  const getFileFormat = (url) => {
    // Extract the file extension from the URL
    const parts = url.split('.');
    if (parts.length > 1) {
      return parts[parts.length - 1]; // Return the last part as the file format
    } else {
      return ''; // Return empty string if no file extension found
    }
  };

  const extractLinks = async (inputString) => {
    setArrayForLinksAvalible([])
    // Regular expressions for each file type
    const pptRegex = /https?:\/\/[^\s]+\.pptx\b/g;
    const jpegRegex = /https?:\/\/[^\s]+\.jpeg\b/g;
    const mp4Regex = /https?:\/\/[^\s]+\.mp4\b/g;
    const mp3Regex = /https?:\/\/[^\s]+\.mp3\b/g;
    
    // Extract and push matches into their respective arrays
    const pptMatches = inputString?.match(pptRegex);
    if (pptMatches) {
      setArrayForLinksAvalible((prev)=> [...prev, "pptx"])
    }
    
    const jpegMatches = inputString?.match(jpegRegex);
    if (jpegMatches) {
      setArrayForLinksAvalible((prev)=> [...prev, "image"])
    }
    
    const mp4Matches = inputString?.match(mp4Regex);
    if (mp4Matches) {
      setArrayForLinksAvalible((prev)=> [...prev, "video"])
    }
    const mp3Matches = inputString?.match(mp3Regex);
    if (mp3Matches) {
      setArrayForLinksAvalible((prev)=> [...prev, "audio"])
    }
    
    return 
    };

  const handleSubmit = async (msg) => {
    const input = msg;
    trackSearchInteraction(input);
    setSending(true);
    setWaitThread(true);
    if (input.trim()) {
      const latestEntry = { sender: "user", content: input };
      // setInput(""); // Reset input field
      setCurrentThread((prevThread) => [...prevThread, latestEntry]);
      const entryResponse = await sendMessage(latestEntry).catch(console.error);
      if(findUrl(entryResponse.content)){
        setHasLink(true)
        await extractLinks(entryResponse.content)
        }
      if (entryResponse) {
        // Check if entryResponse contains a daily limit exceeded message
        if (
          entryResponse &&
          entryResponse.content &&
          findUrl(entryResponse.content) &&
          findUrl(entryResponse.content).includes("dailylimitexceeded")
        ) {
          // Determine the file format from the URL (assuming findUrl() returns the URL)
          const fileFormat = getFileFormat(findUrl(entryResponse.content));

          // Prepare the response message
          const responseMessage = {
            content: `Hi there,\n\nIt looks like you’ve reached your daily limit for generating ` + fileFormat + ` files. We’re really sorry about that! We know how important uninterrupted access is for you.\n\nTo keep enjoying all the great benefits and have a seamless experience, we’d love for you to consider upgrading to our premium plan.\n\nThank you so much for your understanding and support. We truly value having you with us and are excited to continue providing you with the best service possible.\n\nWarm regards,\n\nThe ModalX Team`,
            sender: 'modalX'
          };

          // Update the current thread with the response message
          setCurrentThread((prevThread) => [...prevThread, responseMessage]);
          // setShowCreditRecharge(true);
          if(isLoggedIn === null || isLoggedIn === ""){
            setDailylimitExceed(true);
          }else{
            setShowCreditRecharge(true)
          }
        } else {
          // If the response does not contain a daily limit exceeded message, proceed as usual
          setCurrentThread((prevThread) => [...prevThread, entryResponse]);
        }
        setSending(false);
      } else {
        // If there's no entryResponse, indicate processing failure
        setCurrentThread((prevThread) => [...prevThread, { content: "Unable to process your query!..", sender: 'modalX' }]);
        setSending(false);
        setVisibleStopGenerationButton (false);
      }
    }
    setWaitThread(false);
  };

  const regenerateResponse = () => {
    const promptFromUser = message?.user || currentThread[currentThread.length - 2]?.content;
    handleSubmit(promptFromUser);
  }

  const editResponse = () => {
    setEditClick(true)
  }

  const handleTempShare = () => {
    alert("coming soon!..")
  }



  // const colorMap = {
  //   1: "blue",   // Color for id 1
  //   2: "red",    // Color for id 2
  //   // You can add more ids with colors here
  // };

  const findUrls = (msg) => {
    const urlRegex = /(https?:\/\/[^']*?\.(mp4|jpg|jpeg|png))/g;
    const match = urlRegex.exec(msg);
    return match ? match[1] : null;
  };



  const messageContent = findUrls(message.content)
  const messageAgent = findUrls(message.agent)

  let shareButtonHide = ''
  if (messageContent !== null || messageAgent !== null) {
    shareButtonHide = false
  }
  else {

    shareButtonHide = true

  }


  const data1 = [
    { id: 1, image: <ThumbUpOffAltIcon />, Hovertitle: 'Good response', },
    { id: 2, image: <ThumbDownOffAltIcon />, Hovertitle: 'Bad response', },
  ];

  const data2 = [
    { id: 1, image: isNarrating?<StopIcon /> : <SpeakerIcon />, Hovertitle: isNarrating?'Stop narrating':'Narrate response', click:(()=>{ toggleNarration(index)}) },
    { id: 2, image: <ContentCopyIcon />, Hovertitle: copy ? "Copied" : 'Copy', click: copyFunc },
    // { id: 3, image: <RepeatIcon />, Hovertitle: 'Regenerate response', click: regenerateResponse },
    // { id: 4, image: <ShareIcon />, Hovertitle: 'Share', click: handleTempShare },
  ];

  const getColor = (id) => {
    if (selectedAction === 'like' && id === 1) return "blue";
    if (selectedAction === 'dislike' && id === 2) return "red";
    return "#CAC4D0"; // Default color if none match
  };

  useEffect(() => {
    // Set the selectedAction based on the message.action when the component mounts
    if (message?.action) {
      setSelectedAction(message.action);
    }
  }, [message.action]);

  // useEffect(() => {
  //   const GetAccessTokenforLikedin = async () => {
  //     const response = await GetAccessToken()
  //     // console.log(response?.data?.data)
  //     if (response?.data?.data?.access_token && response?.data?.data?.sub_id) {
  //       localStorage.setItem("accessToken", response?.data?.data?.access_token)
  //       localStorage.setItem("sub", response?.data?.data?.sub_id)
  //     }
  //   }
  //    //code for taking access token and sub from backend to post on linkdin direct
  //   if(isLoggedIn){
  //   GetAccessTokenforLikedin()
  // }, [])
  
  const handleClickMoreOption = (event) => {
    setMoreOption(event.currentTarget); // Get button element
  }; 

  return (
    <>
      <Box sx={{ display: sending ? 'none' : 'block'}}>

        {
          isUnder991px ? 
          <Box
          sx={{
            maxWidth: '600px',
            height: "48px",
            display: "flex",
            justifyContent: "space-between",
            marginLeft: message.sender ?  "12px" : '12px',
            marginTop: "12px",
          }}
        >
              <Box
                sx={{
                  width: "36px",
                  height: "36px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor : moreoption ?   "#2F355F" : "#1F233E",
                  borderRadius: "50%",
                  "&:hover": {
                    backgroundColor:  "#282D51",
                    cursor: "pointer"
                  },

                }}
              >
                <Tooltip
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#E6E0E9",
                        color: "#17182C",
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontWeight: 500,
                        padding: "4px 8px 4px 8px",
                        borderRadius: "4px",
                        maxWidth: "104px",
                        textAlign: "left",
                      },
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: 'center',
                    }}
                    onClick={handleClickMoreOption}>
                    <MoreVertIcon />
                  </Box>
                  
                </Tooltip>
              </Box>
          </Box> 
              :
              <Box style={{backgroundColor: ""}}
              sx={{
                maxWidth: '604px',
                height: "48px",
                display: "flex",
                justifyContent: "space-between",
                marginLeft: message.sender ? isXsOrSm ? "98px" : '90px' : '90px',
                marginRight: message.sender ? isXsOrSm ? "50px" : '50px' : '50px',
                // marginLeft: message.sender ? isXsOrSm ?  "10%" : '10.5%' : '8.5%',
                // backgroundColor: message.sender ?  isXsOrSm ?  "blue" : "black"  : "red",
              }}
            >
              <Box
                sx={{
                  width: "208px",
                  height: "48px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {data1.map((e) => (
                  <Box 
                    // onClick={() => handleClick(e.id)}
                    onClick={() => {
                      const action = e.id === 1 ? 'like' : 'dislike';
                      setSelectedAction(action);
                      onUserAction(action);
                      handleClick(e.id); // Call handleClick after setting the action
                    }}
                    key={e.id}
                    sx={{
                      width: "48px",
                      height: "48px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // backgroundColor: clickedItems[e.id] ? "#2F355F" : "#1F233E",
                      backgroundColor : e.id === 1 && selectedAction === 'like' || e.id === 2 && selectedAction === 'dislike' ? '#2F355F' : '#1F233E',
                      borderRadius: "50%",
                      "&:hover": {
                        backgroundColor: clickedItems[e.id] ? "" : "#282D51",
                        cursor: "pointer"
                      },
    
                    }}
                  >
                    <Tooltip
                      title={e.Hovertitle}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#E6E0E9",
                            color: "#17182C",
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontWeight: 500,
                            padding: "4px 8px 4px 8px",
                            borderRadius: "4px",
                            maxWidth: "104px",
                            textAlign: "left",
                          },
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: 'center',
                          // color: clickedItems[e.id]
                          // ? colorMap[e.id] || "blue" // Use color from colorMap or default to blue
                          // : "#CAC4D0",   
                          // color : getColor(e.id)                      
                          // "&:hover": {
                          //   color: "#FFFFFF",
                          //   cursor : "pointer"
                          // },
                        }}
                        // onClick={(e) => onUserAction(e.id === 1?'like':'dislike')}
                        // onClick={() => {
                        //   const action = e.id === 1 ? 'like' : 'dislike';
                        //   setSelectedAction(action);
                        //   onUserAction(action);
                        // }}
                      >
                        {(e.id === 1 && selectedAction === 'like') ? <ThumbUpIcon /> : (e.id === 2 && selectedAction === 'dislike') ? <ThumbDownIcon /> : e.image}
    
                      </Box>
                    </Tooltip>
                  </Box>
                ))}
                {/* {data2.map((e) => ( */}
                  <Box
                    // onClick={() => handleClick1(e.id)}
                    // onClick={(event) =>{
                    //   if(e.click){
                    //     e.click(event)
                    //   }
                    //   handleClick1(e.id)
                    // }}
                    // key={e.id}
                    onClick={()=>{toggleNarration(index)}}
                    sx={{
                      width: "48px",
                      height: "48px",
                      display: "flex",
                      justifyContent: "center",
                      // display: e.id === 4 && shareButtonHide == true ? 'none' : '',
                      alignItems: "center",
                      backgroundColor: currentNarrate === index ? "#232746" : "#1F233E", 
                      borderRadius: "50%",
                      "&:hover": {
                        backgroundColor: currentNarrate !== index ?  "#282D51" : "",
                        cursor: "pointer"
                      },
                    }}
                  >
                    <Tooltip
                      title={isNarrating ? 'Stop narrating':'Narrate response'}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#E6E0E9",
                            color: "#17182C",
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontWeight: 500,
                            padding: "4px 8px 4px 8px",
                            borderRadius: "4px",
                            textAlign: "left",
                          },
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: 'center',
                          color: "#CAC4D0",
                          // marginTop: '12px',
                          "&:hover": {
    
                          },
                        }}
                      >
                        {/* {e.image} */}
                        { isNarrating ? <StopIcon /> : <SpeakerIcon />}
                      </Box>
                    </Tooltip>
                  </Box>
                   <Box
                   onClick={copyFunc}
                  //  onClick={(event) =>{
                  //    if(e.click){
                  //      e.click(event)
                  //    }
                  //    handleClick1(e.id)
                  //  }}
                  //  key={e.id}
                   sx={{
                     width: "48px",
                     height: "48px",
                     display: "flex",
                     justifyContent: "center",
                    //  display: e.id === 4 && shareButtonHide == true ? 'none' : '',
                     alignItems: "center",
                     backgroundColor:  "#1F233E", 
                     borderRadius: "50%",
                     "&:hover": {
                       backgroundColor:  "#282D51",
                       cursor: "pointer"
                     },
                   }}
                 >
                   <Tooltip
                     title={"copy"}
                     componentsProps={{
                       tooltip: {
                         sx: {
                           bgcolor: "#E6E0E9",
                           color: "#17182C",
                           fontFamily: "Inter",
                           fontSize: "12px",
                           fontWeight: 500,
                           padding: "4px 8px 4px 8px",
                           borderRadius: "4px",
                           textAlign: "left",
                         },
                       },
                     }}
                   >
                     <Box
                       // onClick={e.click}
                       sx={{
                         display: "flex",
                         justifyContent: "center",
                         alignItems: 'center',
                         color: "#CAC4D0",
                         "&:hover": {
   
                         },
                       }}
                     >
                       {/* {e.image} */}
                       <ContentCopyIcon />
                     </Box>
                   </Tooltip>
                 </Box>
                 {/* ))} */}
              </Box>

              <Box 
                sx={{
                  width: shareButtonHide == true ? '171px' : "228px",
                  height: "48px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {/* {data2.map((e) => (
                  <Box
                    // onClick={() => handleClick1(e.id)}
                    onClick={(event) =>{
                      if(e.click){
                        e.click(event)
                      }
                      handleClick1(e.id)
                    }}
                    key={e.id}
                    sx={{
                      width: "48px",
                      height: "48px",
                      display: "flex",
                      justifyContent: "center",
                      display: e.id === 4 && shareButtonHide == true ? 'none' : '',
                      alignItems: "center",
                      backgroundColor: clickedItems1[e.id] ? "#2F355F" : "#1F233E",
                      borderRadius: "50%",
                      "&:hover": {
                        backgroundColor: clickedItems1[e.id] ? "" : "#282D51",
                        cursor: "pointer"
                      },
                    }}
                  >
                    <Tooltip
                      title={e.Hovertitle}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#E6E0E9",
                            color: "#17182C",
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontWeight: 500,
                            padding: "4px 8px 4px 8px",
                            borderRadius: "4px",
                            textAlign: "left",
                          },
                        },
                      }}
                    >
                      <Box
                        // onClick={e.click}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: 'center',
                          color: "#CAC4D0",
                          marginTop: '12px',
                          "&:hover": {
    
                          },
                        }}
                      >
                        {e.image}
                      </Box>
                    </Tooltip>
                  </Box>
                ))} */}
              </Box>

            </Box>
}

      </Box>
      <ShareModalForSocialMedia message={message} />
    {isUnder991px && <LimitedAdditionalOptionsExceptLastConversationsInSmallScreen moreoption={moreoption} setMoreOption={setMoreOption} decodedContent={decodedContent} setSelectedAction={setSelectedAction} selectedAction={selectedAction} onUserAction={onUserAction} editResponse={editResponse} message={message} copyFunc={copyFunc} handleShare={handleShare} regenerateResponse={regenerateResponse} handleClick={handleClick} isUnder991px={isUnder991px} toggleNarration={toggleNarration} isNarrating={isNarrating}/>
 } 
    </>


  );
}

export default LimitedAdditionalOptionsExceptLastConversations;
import React, { useState, useEffect } from "react";
import { Box, Typography, CardMedia, Icon, Button, useMediaQuery, Dialog, DialogTitle, DialogContent, CircularProgress } from "@mui/material";
import BasicIcon from "../assets/PricePlanIcon/BasicIcon.png";
import PremiumIcon from "../assets/PricePlanIcon/PremiumIcon.png";
import { useWorkspace } from "../contexts/WorkspaceContext";
import { useNavigate } from "react-router-dom";
import { handleAddCredit } from "../contexts/WorkspaceUtils";
import CommonToast from "./Common_Components/CommonToast";
import { useAuth } from "../contexts/AuthContext";


const PricePage = ({callPlace}) => {
  const [selectedPlan, setSelectedPlan] = useState("Monthly");
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false)
  const [content, setContent] = useState(null)
  const [iconType, setIconType] = useState(null)
  const [bgColor, setBgColor] = useState(null);
  const isBelow991 = useMediaQuery("(max-width:991px)");
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  // const {setShowSignUpModal} = useAuth()
  const {subscribe, subscribeType} = useWorkspace()

  const onClose = () =>{
    setOpen(false);
  }

  useEffect(()=>{
    if(!isLoggedIn && !subscribe){
      setSelectedPlan("Monthly")
    }else if(isLoggedIn && subscribe){
      setSelectedPlan("Annual")
    }
  },[isLoggedIn])

  const BasicPlanArray = [
    "Unlimited text queries",
    "Daily limits on video, image, audio, and presentation generation",
    "Basic AI models",
    "Limited access to advanced tools",
  ];
  const MonthlyPlusPlanArray = [
    "Extended access to AI tools",
    "Advanced AI models",
    "5 video generations per month",
    "100 image generations per month",
    "20 audio generations per month",
    "10 presentation generations per month",
    "Track up to 5 websites"
  ];
  const MonthlyProPlanArray = [
    "Unlimited access to AI tools",
    "Advanced AI models",
    "Unlimited video generation",
    "Unlimited image generation",
    "Unlimited audio generation",
    "Unlimited presentation generation",
    "Track up to 15 websites"
  ];

  const MonthlySmbPlanArray = [
    "Hire AI calling agents for your business",
    "125 minutes  of calling credit",
    "Access to relationship manager",
    "Unlimited access to AI tools",
    "Unlimited video generations",
    "Unlimited image generations",
    "Unlimited audio generations",
    "Unlimited presentation generations",
    "Track up to 15 websites"
  ];

  const HandleBaiscPlanButtonClick = (e) =>{
    e.stopPropagation()
    if(isLoggedIn){
      if(callPlace){
        navigate('/workspace');
      }
    }else{
      setContent("Please SignUp or Login ");
      setIconType('error')
      setBgColor("#282D51")
      setOpen(true)
      // setShowSignUpModal(true)
      navigate('./signup')
    }
  }

  const HandleMonthlyProPlanButtonClick =async (e) => {
    e.stopPropagation()
    if(isLoggedIn){
      const res = await handleAddCredit(setIsLoading, "1")
    }else{
      setContent("Please SignUp or Login ");
      setIconType('error')
      setBgColor("#282D51")
      setOpen(true)
      // setShowSignUpModal(true)
      navigate('./signup')
    }
  };

  const HandleMonthlyPlusPlanButtonClick =async (e) => {
    e.stopPropagation()
    if(isLoggedIn){
      const res = await handleAddCredit(setIsLoading, "2")
    }else{
      setContent("Please SignUp or Login ");
      setIconType('error')
      setBgColor("#282D51")
      setOpen(true)
      // setShowSignUpModal(true)
      navigate('./signup')
    }
  };

    const HandleMonthlySmbPlanButtonClick =async (e) => {
      e.stopPropagation()
      if(isLoggedIn){
        const res = await handleAddCredit(setIsLoading, "4")
      }else{
        setContent("Please SignUp or Login ");
        setIconType('error')
        setBgColor("#282D51")
        setOpen(true)
        // setShowSignUpModal(true)
        navigate('./signup')
      }
    };

    const handleBookCall = () => {
      window.open("https://calendly.com/makarand-modalx", "_blank");
    };

    const handleEmailClick = (e) => {
      e.preventDefault(); // Prevent default link behavior
      if(isBelow991){
        window.open("mailto:support@modalx.ai", "_blank");
      }else{
        window.open(`https://mail.google.com/mail/?view=cm&to=support@modalx.ai`, "_blank");
      }
    };


  return (
    <>
      <Box>
        <Box
          sx={{
            display: callPlace === 'workspace' ? "none" : "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            rowGap: isBelow991 ? "16px" : "28px",
            margin: isBelow991 ? "60px 0px" : "80px 0px",
          }}
        >
          <Box>
            {" "}
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize:  isBelow991 ? "28px" : "48px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "57.6px",
                letterSpacing: "-0.32px",
                color: "#FFFFFF",
                textAlign:"center"
              }}
            >
              Plans and Pricing
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: isBelow991 ? "14px" :  "18px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "24px",
                letterSpacing: "0.1px",
                color: "rgba(255, 255, 255, 0.80)",
                textAlign:"center"
              }}
            >
              Choose the right plan for your business needs
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection:  isBelow991 ? "column" : "row",
          columnGap: isBelow991 ? "0px" : "60px",
          rowGap: isBelow991 ? "40px" : "0px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* Basic Plan Card */}
        <Box  onClick={(e)=>{e.stopPropagation()}}
          sx={{
            width: "360px",
            height: "658px",
            border:
              selectedPlan === "Basic"
                ? "1px solid #FFFFFF"
                : "1px solid rgba(255, 255, 255, 0.10)",
            background: "#1F233E",
            borderRadius: "16px",
            padding: "30px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "12px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "24px",
                    letterSpacing: "0.1px",
                    color: "#FFFFFF",
                  }}
                >
                  Basic
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "24px",
                    letterSpacing: "0.1px",
                    color: "rgba(255, 255, 255, 0.80)",
                  }}
                >
                  Explore how the power of AI can help you with your business
                  needs
                </Typography>
              </Box>
              <Box sx={{ margin: "30px 0px" }}>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "24px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "24px",
                    letterSpacing: "0.1px",
                    color: "#FFFFFF",
                  }}
                >
                  Free
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "16px",
                }}
              >
                {BasicPlanArray.map((ele, index) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        columnGap: "10px",
                      }}
                    >
                      <Box sx={{ height: "20px", width: "20px" }}>
                        <CardMedia
                          sx={{ height: "20px", width: "20px" }}
                          component="img"
                          image={BasicIcon}
                          alt="Loading"
                        />
                      </Box>
                      <Box sx={{ display: "flex", width: "370px" }}>
                        <Typography
                          sx={{
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            letterSpacing: "0.1px",
                            color: "#FFFFFF",
                          }}
                        >
                          {ele}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Box>
              <Button disabled={isLoggedIn}
                  onClick={HandleBaiscPlanButtonClick}
                sx={{
                  width: "100%",
                  height: "40px",
                  textTransform: "none",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: 600,
                  textAlign: "center",
                  bgcolor: isLoggedIn ? "rgba(40, 45, 81, 1)" : "#FFFFFF",
                  color: isLoggedIn ? "#CAC4D0" : "#17182C",
                  borderRadius: "8px",
                  "&:hover": {
                    backgroundColor: isLoggedIn ? "rgba(40, 45, 81, 1)" : "#CDCFDF",
                  },
                  "&.Mui-disabled": {
                    color: "rgba(230, 224, 233, 1)", // Custom color for the disabled state
                    bgcolor:subscribe ? "rgba(230, 224, 233, 0.12)" : "rgba(40, 45, 81, 1)"
                  },
              }}
              >
              { isLoggedIn ?  !subscribe ? "Current plan" : "Get started for free" : !subscribe ? "Current plan" : "Get started for free"}
              </Button>
            </Box>
          </Box>
        </Box>
        {/*Plus Plan  Card */}
        {/* <Box onClick={(e)=>{e.stopPropagation()}}
          sx={{
            width: "360px",
            height: "554px",
            border: "1px solid rgba(255, 255, 255, 0.10)",
            background: "#1F233E",
            borderRadius: "16px",
            padding: "30px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "12px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "24px",
                    letterSpacing: "0.1px",
                    color: "#FFFFFF",
                  }}
                >
                  Plus
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "24px",
                    letterSpacing: "0.1px",
                    color: "rgba(255, 255, 255, 0.80)",
                  }}
                >
                  Boost your business growth with greater access to AI models
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                  margin: "30px 0px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "24px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "24px",
                    letterSpacing: "0.1px",
                    color: "#FFFFFF",
                  }}
                >
                  $15
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal",
                    letterSpacing: "0.1px",
                    color: "rgba(255, 255, 255, 0.80)",
                  }}
                >
                  /month
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal",
                    letterSpacing: "0.1px",
                    color: "rgba(255, 255, 255, 0.60)",
                    margin: "0px 8px",
                    display: "flex",
                    flexShrink: 0,
                  }}
                >{`(billed annually)`}</Typography>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    letterSpacing: "0.1px",
                    color: "#F6BE6B",
                    display: "flex",
                    flexShrink: 0,
                  }}
                >
                  Save 10%
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "16px",
                }}
              >
                {MonthlyPlusPlanArray.map((ele, index) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        columnGap: "10px",
                      }}
                    >
                      <Box sx={{ height: "20px", width: "20px" }}>
                        <CardMedia
                          sx={{ height: "20px", width: "20px" }}
                          component="img"
                          image={PremiumIcon}
                          alt="Loading"
                        />
                      </Box>
                      <Box sx={{ display: "flex", width: "370px" }}>
                        <Typography
                          sx={{
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            letterSpacing: "0.1px",
                            color: "#FFFFFF",
                          }}
                        >
                          {ele}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Box>
              <Button disabled={subscribeType == "2" || subscribeType == "1" && subscribe && isLoggedIn} 
                  onClick={HandleMonthlyPlusPlanButtonClick}
                sx={{
                  width: "100%",
                  height: "40px",
                  textTransform: "none",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: 600,
                  textAlign: "center",
                  color: "rgba(246, 190, 106, 1)",
                  border: subscribe  ? "" : "1px solid rgba(246, 190, 106, 1)",
                  borderRadius: "8px",
                  "&:hover": {
                    backgroundColor: "#282D51",
                  },
                  "&.Mui-disabled": {
                    color: "rgba(230, 224, 233, 1)", // Custom color for the disabled state
                    bgcolor:subscribe && subscribeType == "1" ? "rgba(230, 224, 233, 0.12)" : "rgba(40, 45, 81, 1)"
                  },
                }}
              >
               {callPlace === "workspace" ? subscribe && subscribeType == "2" ? "Current" : "Get started with Plus"  : "Get started with Plus"} 
               {isLoggedIn ? (subscribe && subscribeType == "2" ? "Current plan" : (callPlace==="workspace" ?  (subscribe && subscribeType == "1" ? "Get started with Plus" : "Upgrade to Plus" ): "Get started with Plus")) : "Get started with Plus"  }
              </Button>
            </Box>
          </Box>
        </Box> */}


        {/* Pro Plan Card */}
        <Box onClick={(e)=>{e.stopPropagation()}}
          sx={{
            width: "360px",
            height: "658px",
            border:  subscribeType === 0 || !subscribe ? "1px solid #F6BE6B" : "1px solid rgba(255, 255, 255, 0.10)",
            background: "#1F233E",
            borderRadius: "16px",
            padding: "30px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "12px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    columnGap: "12px",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "20px",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "20px",
                      letterSpacing: "0.1px",
                      color: "#FFFFFF",
                    }}
                  >
                    Pro
                  </Typography>
                 {(subscribe && subscribeType === 4) ? null : <Box
                    sx={{
                      bgcolor: "rgba(246, 190, 107, 0.20)",
                      border: "1px solid #F6BE6B",
                      borderRadius: "4px",
                      margin: "0px",
                      padding: "0px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "24px",
                        letterSpacing: "0.1px",
                        color: "#F6BE6B",
                        padding: "2px 4px",
                        margin: "0px",
                      }}
                    >
                      Popular
                    </Typography>
                  </Box>}
                </Box>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "24px",
                    letterSpacing: "0.1px",
                    color: "rgba(255, 255, 255, 0.80)",
                  }}
                >
                  Take your business growth to the next level with advanced AI
                  tools
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                  margin: "30px 0px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "24px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "24px",
                    letterSpacing: "0.1px",
                    color: "#FFFFFF",
                    textDecoration: "line-through",
                    marginRight:"12px"
                  }}
                >
                  $99
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "24px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "24px",
                    letterSpacing: "0.1px",
                    color: "#FFFFFF",
                  }}
                >
                  $50
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal",
                    letterSpacing: "0.1px",
                    color: "rgba(255, 255, 255, 0.80)",
                  }}
                >
                  /month
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "16px",
                }}
              >
                {MonthlyProPlanArray.map((ele, index) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        columnGap: "10px",
                      }}
                    >
                      <Box sx={{ height: "20px", width: "20px" }}>
                        <CardMedia
                          sx={{ height: "20px", width: "20px" }}
                          component="img"
                          image={PremiumIcon}
                          alt="Loading"
                        />
                      </Box>
                      <Box sx={{ display: "flex", width: "370px" }}>
                        <Typography
                          sx={{
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            letterSpacing: "0.1px",
                            color: "#FFFFFF",
                          }}
                        >
                          {ele}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Box>
              <Button disabled={isLoggedIn && subscribe && (subscribeType == "1" || subscribeType == "2" || subscribeType == "3" || subscribeType == "4")}
                onClick={HandleMonthlyProPlanButtonClick}
                sx={{
                  width: "100%",
                  height: "40px",
                  textTransform: "none",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: 600,
                  textAlign: "center",
                  bgcolor: "rgba(246, 190, 107, 1)",
                  color: "rgba(23, 24, 44, 1)",
                  borderRadius: "8px",
                  "&:hover": {
                        backgroundColor: "#ffd9aa", 
                  },
                  "&.Mui-disabled": {
                    color: "rgba(230, 224, 233, 1)", // Custom color for the disabled state
                    bgcolor:(subscribe && subscribeType === 4) ? "rgba(230, 224, 233, 0.12)" : "rgba(40, 45, 81, 1)"
                  },
                }}
              >
                {/* {callPlace === 'workspace' ? subscribe ? "Current plan" : "Upgrade to Pro" : subscribe ? "Current plan" : "Get started with Pro"} */}
                {isLoggedIn ? 
                subscribe && subscribeType == "1" ? "Current plan" : callPlace==="workspace" ? subscribeType === 4 ? "Get started with Pro" : "Upgrade to Pro" : "Get started with Pro" 
                :
                "Get started with Pro"  }
              </Button>
            </Box>
          </Box>
        </Box>

           {/*Smb Plan  Card */}
           <Box onClick={(e)=>{e.stopPropagation()}}
          sx={{
            width: "360px",
            height: "658px",
            border: (subscribeType === 1 || subscribeType === 4 )? "1px solid #F6BE6B" : "1px solid rgba(255, 255, 255, 0.10)",
            background: "#1F233E",
            borderRadius: "16px",
            padding: "30px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "12px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "24px",
                    letterSpacing: "0.1px",
                    color: "#FFFFFF",
                  }}
                >
                  SMB Max
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "24px",
                    letterSpacing: "0.1px",
                    color: "rgba(255, 255, 255, 0.80)",
                  }}
                >
                  Business growth to unprecedented heights with the limitless potential of cutting-edge AI tools
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                  margin: "30px 0px",
                }}
              >

              <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "24px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "24px",
                    letterSpacing: "0.1px",
                    color: "#FFFFFF",
                    textDecoration: "line-through",
                    marginRight:"12px"
                  }}
                >
                  $499
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "24px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "24px",
                    letterSpacing: "0.1px",
                    color: "#FFFFFF",
                  }}
                >
                  $149
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal",
                    letterSpacing: "0.1px",
                    color: "rgba(255, 255, 255, 0.80)",
                  }}
                >
                  /month
                </Typography>
              
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "16px",
                }}
              >
                {MonthlySmbPlanArray.map((ele, index) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        columnGap: "10px",
                      }}
                    >
                      <Box sx={{ height: "20px", width: "20px" }}>
                        <CardMedia
                          sx={{ height: "20px", width: "20px" }}
                          component="img"
                          image={PremiumIcon}
                          alt="Loading"
                        />
                      </Box>
                      <Box sx={{ display: "flex", width: "370px" }}>
                        <Typography
                          sx={{
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            letterSpacing: "0.1px",
                            color: "#FFFFFF",
                          }}
                        >
                          {ele}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Box>
              <Button  disabled={(subscribeType == "4" )&& isLoggedIn}
                  onClick={HandleMonthlySmbPlanButtonClick}
                sx={{
                  width: "100%",
                  height: "40px",
                  textTransform: "none",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: 600,
                  textAlign: "center",
                  color: "rgba(246, 190, 106, 1)",
                  border:subscribe  && subscribeType == "4" ? "" : "1px solid rgba(246, 190, 106, 1)",
                  borderRadius: "8px",
                  "&:hover": {
                    backgroundColor: "#282D51",
                  },
                  "&.Mui-disabled": {
                    color: "rgba(230, 224, 233, 1)", // Custom color for the disabled state
                    bgcolor:subscribe && subscribeType == "4" ? "rgba(230, 224, 233, 0.12)" : "rgba(40, 45, 81, 1)"
                  },
                }}
              >
                  {isLoggedIn ? (subscribe && subscribeType == "4" ? "Current plan" : (callPlace==="workspace" ?  (subscribe && (subscribeType == "1" || subscribeType == "2" || subscribeType == "3" )? "Upgrade to SMB Max" : "Upgrade to SMB Max" ): "Upgrade to SMB Max")) : "Upgrade to SMB Max"  }
              </Button>
            </Box>
          </Box>
        </Box>

      </Box>

 



      <Box  sx={{ display: "flex", justifyContent: "center", margin: isBelow991 ? "36px 0px" : "48px 0px" }}>
        <Typography  
          sx={{
            fontFamily: "Inter",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "24px",
            letterSpacing: "0.1px",
            color: "#FFFFFF",
            textAlign:"center",
          }}
        >
            Have questions? {<br/>} 
          <span onClick={handleBookCall} style={{ textDecorationLine: "underline", cursor:"pointer" }}>Book a call with us</span>{isBelow991 && <br/>} 
          <span style={{margin:"0px 8px", opacity:0.8}}>{`OR`}</span>{isBelow991 && <br/>} 
          Contact us at <span onClick={handleEmailClick} style={{ textDecorationLine: "underline", cursor:"pointer", color: "#ffffff" }}>support@modalx.ai</span>
        </Typography>
      </Box>
       
      {/* for payment success */}

      <Dialog
        open={isLoading}
        disableBackdropClick
        disableEscapeKeyDown
        PaperProps={{ style: { padding: '20px', textAlign: 'center' } }}
      >
        <DialogTitle>{"Processing..."}</DialogTitle>
        <DialogContent>
          <CircularProgress />
          <Typography variant="h6" style={{ marginTop: '15px' }}>
            Wait!.. Do not refresh the screen.
          </Typography>
        </DialogContent>
      </Dialog>    

      {/* Comman Alert  */}

      <CommonToast open={open} onClose={onClose} content={content} iconType={iconType} bgColor={bgColor}/>
    </>
  );
};

export default PricePage;

import React, { useState,useEffect } from 'react'
import {
    Box,
    Button,
    Grid,
    Typography,
    IconButton,
    InputAdornment,
    Input,
    Card,
  } from "@mui/material";
import DID_Thumbnail from "../../../assets/VideoSettings_DIDThumbnail.png";
import VideoGen_Thumbnail from "../../../assets/VideoSettings_VideoGenThumbnail.png";
import { useWorkspace } from "../../../contexts/WorkspaceContext";
import { VideoGenAPISettings } from './VideoGenSettings/VideoGenAPISettings';

const SelectVideoGenetrationPlatform = () => {
    const [videoGenerationPlatform,setVideoGenerationPlatform] = useState("");
    const {setMinDImensionPixels,setSelectedVideoGenerationPlatform,setShowVideoSettings,setShowVideoGenSettings,selectedVideoGenerationPlatform, userMediaSettings, genVideoLanguage, genVideoVoice, setGenVideoLanguage, setGenVideoAudioSettingsLoading, setGenVideoVoice,  setGenVideoVoicesLoading,setCaptionFontForVideoGen} = useWorkspace();

    const handlePlatformSelection = (platform) => {
        setVideoGenerationPlatform(platform);
        setSelectedVideoGenerationPlatform(platform);
        // if(platform === "presenter"){
        //     setShowVideoSettings(true);
        //     setTimeout(() => {
        //         setShowVideoGenSettings(false);
        //       }, 500);
            
            
        //     // trackModalOpen(true)
        //   }
        //   else if(platform === "voice-over"){
        //     setShowVideoGenSettings(true);
        //     setTimeout(() => {
        //         setShowVideoSettings(false);
        //       }, 500);
            
        //     // trackModalOpen(true)
        //   }
    }

     useEffect(() => {
        if (genVideoLanguage || genVideoVoice) {
          VideoGenAPISettings(
            setGenVideoLanguage,
            setGenVideoAudioSettingsLoading,
            setGenVideoVoice,
            setGenVideoVoicesLoading,
            setCaptionFontForVideoGen
          );
        }
      }, []);

    useEffect(() => {
      setVideoGenerationPlatform(selectedVideoGenerationPlatform)
    }, [selectedVideoGenerationPlatform])

    // useEffect(()=>{
    //   if(userMediaSettings){
    //     setMinDImensionPixels(userMediaSettings[1]?.settings?.typeOneVideoSettings?.minDimensionPixels)
    //   }
    // },[userMediaSettings])
    
  return (
    <div style={{width:'100%',height:'100%',paddingRight:'20px',paddingTop:'20px',maxWidth:'686px'}}>
        <Typography
            variant="h6"
            gutterBottom
            style={{
              fontSize: "16px",
              fontWeight: "500",
              width: "143px",
              height: "20px",
              marginBottom: "20px",
            }}
          >
           Select Video Type
          </Typography>
          <Box sx={{width:'100%',maxWidth:'636px',height:'289px',display:'flex',justifyContent:'space-between',gap:'20px'}}>
              <Box sx={{
                  width: '100%', maxWidth: '308', height: '289px', padding: '12px', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', borderRadius: '8px', backgroundColor: videoGenerationPlatform === "voice-over" ?  "#3a3f69" : "#30355f",
                  "&:hover": {
                    backgroundColor: "#373E6D", 
                    border:" 1px solid #373E6D"
                  },
                  border: `1px solid ${videoGenerationPlatform === "voice-over" ? '#cac4d0' : 'transparent'}`,
                  cursor: 'pointer'
              }}
                  onClick={() => handlePlatformSelection("voice-over")}
              >
                  <img src={VideoGen_Thumbnail} style={{ borderRadius: '6px' }} />
                  <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Video with Voice-over</Typography>
                  <Typography sx={{ fontSize: '12px', fontWeight: '400',color:'rgba(255,255,255,0.8)' }}>Generates a video with motion graphics and a voice-over with customizable captions, voice, language, and aspect ratio</Typography>
              </Box>
              <Box sx={{
                  width: '100%', maxWidth: '308', height: '289px', padding: '12px', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', borderRadius: '8px', backgroundColor: videoGenerationPlatform === "presenter" ? '#3a3f69' : '#30355f',               
                  "&:hover": {
                    backgroundColor: "#373E6D", 
                    border:" 1px solid #373E6D"
                  },
                  border: `1px solid ${videoGenerationPlatform === "presenter" ? '#cac4d0' : 'transparent'}`,
                  cursor: 'pointer'
              }}
                  onClick={() => handlePlatformSelection("presenter")}
              >
                  <img src={DID_Thumbnail} style={{ borderRadius: '6px' }} />
                  <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Video with Presenter</Typography>
                  <Typography sx={{ fontSize: '12px', fontWeight: '400',color:'rgba(255,255,255,0.8)' }}>Generates a video with a custom avatar presenting content, with customizable backgrounds, voice, and language.</Typography>
              </Box>
          </Box>
    </div>
  )
}

export default SelectVideoGenetrationPlatform
export const darkTheme_bg_color = "#171A2D";
export const darkTheme_New_bg_color = "#141626";
export const button_bg_color_for_New_Project = '#f6be6b';
export const hovered_chat_bg_color = "#282D51";
export const selected_chat_bg_color = "#1F233E";
export const prompt_Input_bg_color_for_darkmode = "#1F233E";
export const uploadFiles_button_default_bg_color = "#FFFFFF";
export const uploadFiles_button_hovered_bg_color = "#CDCFDF";
export const uploadFiles_button_clicked_bg_color = "#B7BAD5";
export const uploadFiles_button_text_color_dm = "#17182C";
export const linkUploader_bg_color = "#1F233E";
export const linkUploader_Text_color = "#FFFFFF";
export const oneClickModal_backgroundColor_dm = "#1F233E" // "#282D51";
export const fileUploader_bg_color_inside_onclickModal = "#1F233E";
export const fileUploader_bg_color_for_dm = '#1F233E';
export const settingsModal_bg_color_dm = "#1F233E";  //'#282D51';
export const selecetedSettings_Nav_bg_dm = '#373E6D';
export const hoveredSettings_Nav_bg_dm = '#2F355F';
export const selected_SettingsButton_bg_color = '#3a3f69';
export const defaultSettings_button_bg_color = '#2F355F';
export const hoveredSettings_button_bg_color = '#373E6D';
export const ImageSlider_Active_Dod = "#CAC4D0"
export const ImageSlider_Inactive_Dod_bg = "#4A5076"




export const button_bg_color_for_New_Project_dm = '#f6be6b';
export const button_bg_color_for_New_Project_lm = '';
export const hovered_chat_bg_color_dm = "#282D51";
export const hovered_chat_bg_color_lm = "";
export const selected_chat_bg_color_dm = "#1F233E";
export const selected_chat_bg_color_lm = "";
export const prompt_Input_bg_color_for_dm = "#1F233E";
export const prompt_Input_bg_color_for_lm = "";
export const landingPage_mainTitle_textColor_dm = '#F6BE6A';
export const landingPage_mainTitle_textColor_lm = '#F6BE6A';


// Chat
export const user_conversation_bg_color = "#2F355F";
export const agent_conversation_bg_color = '#1C2039';
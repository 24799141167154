import React from "react";
import { Box, Typography, Button } from "@mui/material";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import { StopResponseGeneration } from "../../contexts/WorkspaceUtils";
import { useWorkspace } from "../../contexts/WorkspaceContext";

const StopGenerationButton = () => {
  const {
    setVisibleStopGenerationButton,
    setWaitThread,
    setSending,
    setCurrentThread,
    currentStopGenerationMessageId,
    stopMessageIdRef,
    setStopMessageIDRef,
    threadId,
  } = useWorkspace();
  const handleStopGenerationButtonClick = async () => {
    setVisibleStopGenerationButton(false);
    setWaitThread(false);
    setSending(false);
    setCurrentThread((prevThread) =>
      Array.isArray(prevThread)
        ? [
            ...prevThread,
            {
              content: "**Response generation was stopped**",
              sender: "modalX",
            },
          ]
        : []
    );
    const res = await StopResponseGeneration(
      threadId,
      currentStopGenerationMessageId
    );
    if (res.status) {
      const newAray = [
        ...stopMessageIdRef.current,
        currentStopGenerationMessageId,
      ];
      stopMessageIdRef.current = newAray;
      setStopMessageIDRef((prev) => [...prev, currentStopGenerationMessageId]);
    }
  };

  return (
    <Box onClick={handleStopGenerationButtonClick}>
      <Button
        sx={{
          backgroundColor: "#2F355F",
          padding: "8px 12px",
          borderRadius: "8px",
          "&:hover": { backgroundColor: "#373E6D" },
        }}
      >
        <StopCircleOutlinedIcon
          sx={{
            width: "20px",
            height: "20px",
            color: "rgba(255, 255, 255, 0.80)",
            "&:hover": {
              backgroundColor: "rgba(202, 196, 208, 0.08)",
            },
          }}
        />
        <Typography
          style={{
            display: "flex",
            textAlign: "center",
            fontFamily: "Inter",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            color: "rgba(255, 255, 255, 0.80)",
            alignItems: "center",
            textTransform: "none",
            marginLeft: "10px",
          }}
        >
          Stop generating response
        </Typography>
      </Button>
    </Box>
  );
};

export default StopGenerationButton;

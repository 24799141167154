import { fileTypeFromBlob } from "file-type";

const getVideoDuration = (file) => {
  return new Promise((resolve, reject) => {
    const video = document.createElement("video");
    video.preload = "metadata";

    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      resolve(video.duration);
    };

    video.onerror = () => {
      reject(new Error("Error loading video metadata"));
    };

    video.src = URL.createObjectURL(file);
  });
};

const FileValidator = async ({ file, maxSize, minDuration, maxDuration, allowedTypes }) => {
  if (!file) return { valid: false, error: "No file selected" };

  // Check file size
  if (file.size > maxSize) {
    return { valid: false, error: `File size exceeds the limit of ${maxSize / (1024 * 1024)} MB` };
  }

  // Check file type
  const fileType = await fileTypeFromBlob(file);
  if (!fileType || !allowedTypes.includes(fileType.mime)) {
    return { valid: false, error: "Invalid file type" };
  }

  // If minDuration or maxDuration is provided and file is a video, check duration
  if ((minDuration || maxDuration) && fileType.mime.startsWith("video/")) {
    try {
      const duration = await getVideoDuration(file);

      if (minDuration && duration < minDuration) {
        return { valid: false, error: `The duration of the video should be at least ${minDuration} seconds.` };
      }

      if (maxDuration && duration > maxDuration) {
        return { valid: false, error: `Video duration exceeds the limit of ${maxDuration} seconds` };
      }
    } catch (error) {
      console.error("Error checking video duration:", error);
      return { valid: false, error: "Error processing video duration" };
    }
  }

  return { valid: true, error: null };
};

export default FileValidator;


import sourceUrl from "../../../../api/Source.api";
const token = localStorage.getItem('token');
const isLoggedIn = localStorage.getItem('isLoggedIn');


const fetchLanguages = async (setAudioLanguages, setAudioSettingLanguagesLoading) => {
  setAudioSettingLanguagesLoading(true)
    try {
      const response = await fetch(`${sourceUrl}/audio/language`, {
        method: 'GET',
        credentials: "include",
        headers: {
          ...(isLoggedIn ? { Authorization: `Bearer ${token}` } : {}),
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      });
      const data = await response.json();
      setAudioLanguages(data.data.data); // Assuming the response has a languages array
    } catch (error) {
      console.error('Error fetching languages:', error);
    }
    setAudioSettingLanguagesLoading(false)
  };

  const fetchVoices = async (setAudioSettingVoices,setAudioVoice, audioVoice, setAudioSettingVoicesLoading) => {
    setAudioSettingVoicesLoading(true)
    try {
      const response = await fetch(`${sourceUrl}/audio/list`, {
        method: 'GET',
        credentials: "include",
        headers: {
          ...(isLoggedIn ? { Authorization: `Bearer ${token}` } : {}),
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      });
      const data = await response.json();
      // console.log('Fetched voices:', data.data.data); // Log the response for debugging
      setAudioSettingVoices(data.data.data);
      if (data.data.data.length > 0 && !audioVoice) {
        // Set default selected voice if none is selected yet
        setAudioVoice(data.data.data[0].voice_id);
      }
    } catch (error) {
      console.error('Error fetching voices:', error);
    }
    setAudioSettingVoicesLoading(false)
  };

const AudioSettingsAPI = (setAudioLanguages,setAudioSettingVoices,setAudioVoice, audioVoice, setAudioSettingVoicesLoading,setAudioSettingLanguagesLoading  ) =>{
  fetchLanguages(setAudioLanguages, setAudioSettingLanguagesLoading);
  fetchVoices(setAudioSettingVoices,setAudioVoice, audioVoice, setAudioSettingVoicesLoading);
}

export {AudioSettingsAPI, fetchVoices, fetchLanguages}
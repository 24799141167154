
const BnFlag = (props) => (
  <svg
    width="1em"
    height="0.75em"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    id="flag-icons-bn"
    viewBox="0 0 640 480"
    {...props}
  >
    <path fill="#f7e017" d="M0 0h640v480H0z" />
    <path fill="#fff" d="M0 33.3v213.4l640 100V233.3z" />
    <path d="M0 146.7v100l640 200v-100z" />
    <g fill="#cf1126" transform="translate(-160) scale(.66667)">
      <path d="M695.7 569.7a117.2 117.2 0 0 1-49.4-17.2c-2.4-1.6-4.6-3-5-3-.4 0-.6 1.9-.6 4.1 0 6.4-2.6 9.6-9 11.3-6.2 1.6-15.6-1.6-23.2-8a68.3 68.3 0 0 0-24.7-13.5 39.9 39.9 0 0 0-28 3.6 8.7 8.7 0 0 1-2.8 1.3c-1.1 0-1-6.9.2-9 1.5-3 5.1-5.8 9.4-7.3 2.2-.8 4-1.8 4-2.3 0-.4-.8-2-1.7-3.6-2.9-5.1-1.7-10 3.4-13.9 5.2-4 14-4.6 21.7-1.7a32 32 0 0 0 4 1.4c1 0 .4-1.5-2.4-5.6-3.2-4.7-3.9-7-3.5-12.7a14.7 14.7 0 0 1 13.5-13.5c5.8-.4 9.4 1.6 18 9.7a144 144 0 0 0 86 41.6c8.3 1 24.8.5 34.5-1a156 156 0 0 0 81.8-40.8c6.4-6 9.4-7.6 14.7-7.6 4.5 0 7.7 1.4 11 5 3 3.3 4 6.4 3.6 11.5-.2 3.2-.7 4.7-2.6 7.9-2.8 4.5-2.3 5 3.2 2.8 7.6-3 16.9-1.6 21.9 3.2 4.4 4.2 4.8 8.4 1.4 14-1.3 2.1-2.3 4-2.3 4.4 0 .6 1 .8 5.5 1.6 6 1 9.5 5.4 9.5 12.2 0 2-.3 3.7-.6 3.7s-2.6-.9-5-1.9c-7-2.9-11-3.6-19.2-3.5-6.2 0-8.3.3-12.6 1.7a57.5 57.5 0 0 0-19.5 11.5c-6.4 5.7-10.4 7.5-16.6 7.4-5.8 0-9.7-1.7-11.8-5-1.1-1.8-1.3-2.8-1-6.8.2-2.6.1-4.7 0-4.7-.3 0-2.5 1.4-5 3.1A80.5 80.5 0 0 1 778 560a181.6 181.6 0 0 1-82.3 9.7z" />
      <path d="M706.3 525.2a136.4 136.4 0 0 1-97.9-55.7c-24.4-33.2-32-77.1-24.6-117.2 4-18.3 12-36.6 25.5-49.6a114.6 114.6 0 0 0-8.7 74.3c9 49.8 51 91.9 101.3 99.2 20 5.7 40.5-.4 59.5-6.5 42-14.8 74-54.6 77.8-99.1 3.3-24-.3-49.1-11.2-71 6.2 3.3 14 16.2 18.6 24.8 16 31 16.7 68.1 7.3 101.2-12.8 42.1-45 79-87.5 92.4a165.7 165.7 0 0 1-60 7.2z" />
      <g id="bn-a">
        <path d="M512 469.9c-2.5-.4-5.3 2.1-4.3 4.7 1.8 2.6 5 4 7.8 5.2a54.2 54.2 0 0 0 23.2 3.6 49.6 49.6 0 0 0 17-3c3-1 6.8-2 8-5.4 1.3-2.1-1-4.3-3.1-3.9-3 .7-6 2-9 2.9a57.7 57.7 0 0 1-20.3 2 54 54 0 0 1-14.4-4.2c-1.6-.7-3.1-1.7-4.9-1.9z" />
        <path d="M514.8 459.5c-2.5-.4-4.7 2.6-3.7 5 2 2.8 5.3 4.3 8.4 5.6a42.4 42.4 0 0 0 17 2.9h1.5a37.6 37.6 0 0 0 14.4-2.8c2.7-1.1 6.1-2.2 7.3-5.2.9-1.7.2-4.1-2-4.3-1.8 0-3.5 1.2-5.3 1.7a44.3 44.3 0 0 1-20.6 3.2c-4.4-.5-8.5-2.1-12.5-4-1.5-.7-2.8-1.8-4.5-2z" />
        <path d="M518.3 449.6c-2.2-.3-3.7 2.2-3.3 4.1.3 1.8 1.8 3 3.1 4a30 30 0 0 0 18.6 5.3h1.6a28 28 0 0 0 12-2.8c2.5-1 5.4-2.3 6.3-5.2.4-1.3.6-3.2-.9-4-1.6-.8-3.1.5-4.5 1a34 34 0 0 1-15.5 3.9 27 27 0 0 1-13.1-4c-1.5-.7-2.7-2-4.3-2.3z" />
        <path d="M481.5 302.7c-3.2 3.3-.7 9.3-1 13.5 1.8 13.2 3.9 26.5 8.8 39 6 12 18.8 18.5 26.5 29.2 2.8 5.1 1.8 11.3 2.4 17 .4 15.3.3 30.7 0 46 7 3.6 14.5 7 22.5 5.7 4.7-1.1 13.5-1.8 14.5-6.5l-1-79.5c-2.7-8.1-11-12.3-17.1-17.5a155.5 155.5 0 0 1-14.2-16.1c-2.6-4.5-12.9-6-9.2 1.6 2.2 6.7 7.7 11.6 9.1 18.6.3 3.9 5 11 1 13.2a24.5 24.5 0 0 0-10.7-10c-4.4-3.3-11.7-4.7-13.3-10.5a42.9 42.9 0 0 0-11-22c1.5-7.4 0-16.7-6.4-21.5l-.9-.2z" />
        <path d="M491.4 304.2c-3 .5-2.8 4.2-1.5 6.2a27.2 27.2 0 0 1 1.1 13.4 44.1 44.1 0 0 1 10.6 21.7c0 3 3.2 4 5.3 5.5 4.9 3.1 10.3 5.4 14.7 9.3.9 1 1.6 2 1 0-.7-2.6-1-5.4-3-7.3-2.8-3-6.2-5.6-10.2-6.4-.3-4.2-2.3-8-4.1-11.6-2-3.5-4.1-7.2-7.5-9.4 0-6.1 0-12.5-2.6-18.2-.8-1.4-2-3.1-3.8-3.2z" />
        <path d="M499.7 306.6c-2 .6-1.6 3.2-1 4.7a54 54 0 0 1 1 13.2c3.9 3 6.2 7.4 8.4 11.6 1.4 2.8 2.6 5.8 3.1 8.9 3.1 1 5.8 3 8.2 5-1-2.8-3-5-4.5-7.7s-3-5.6-3.7-8.7c-3-3.1-4.6-7.6-4-12 .2-4.7-1.3-9.6-4.5-13.2-.8-.8-1.8-1.7-3-1.8z" />
        <path d="M509.2 308c-1.2.2-1.8 1.2-2.4 2.1-.3.9.8 1.8 1 2.8a21.8 21.8 0 0 1 1.4 10.4c-.1 2.5.8 5 2 7a3.9 3.9 0 0 1 3.5-2.8c.5 0 1.4.2 1-.7-.4-4.8-1.1-9.6-2.8-14a9.6 9.6 0 0 0-2.8-4.5c-.2-.2-.6-.4-1-.3z" />
      </g>
      <use xlinkHref="#bn-a" transform="matrix(-1 0 0 1 1440 0)" />
      <path d="M715.7 476a35.6 35.6 0 0 1-29.9-24c.3-2.2 3 1.2 4.3 1.5a19 19 0 0 0 8 2.6c3.5 1.5 5.7 5 9.1 6.9 1.6 1.2 7.2 3.6 6.1-.3-1.3-2-2.2-4.6-1-7 1.8-4.1 4.7-7.7 7.7-11.2 2.1-.7 3.6 3.6 5.1 5 2.1 3.3 4.7 7.3 3.4 11.3-1.2 1.5-2 6 1.3 4.6 4-1.8 7.3-4.8 10.6-7.6 3-2 6.7-2.1 9.7-4 1.5-.3 4.4-3.1 5-1.6a44.9 44.9 0 0 1-7.4 12.3 32.1 32.1 0 0 1-18.8 10.9c-4.4.8-8.8 1-13.2.6z" />
      <path d="M731.5 460.2c.3-2.7-.3-5.4-1.7-8-2.1-4.2-5-8-8-11.9-2.8-1.6-4.3 3.7-6.1 5.2-2.9 4.3-6.5 8.7-6.7 14-1.6 2.5-4.6-2-5.9-3.5a19 19 0 0 1-4-12 50.8 50.8 0 0 1 3.6-20.6c2-5.6 5.1-11 4.8-17 .2-4.7-.7-9.7-4.4-12.8-3.6-2.8 2.3-3.4 4.1-2 3.2.3 4.9 5.5 7.8 4.2 1.1-2.7 1.4-6 3.8-8.1 2.3-3.2 4.7 1.3 5.5 3.5 1.7 1.8 0 6.5 2.6 6.6 3.2-2.3 5.5-6 9.6-6.9 1.7-1 4.5 0 2.3 1.8-3 2.9-5.6 6.4-6.2 10.7-.9 5.3.4 10.7 2.7 15.4 4.5 9.4 8 20 5.7 30.5-1 4.6-4.2 8.6-8 11.3-.5.3-1.3.3-1.5-.4z" />
      <path d="M726.7 389.6a21.2 21.2 0 0 0-5.6-7c-2.4 0-3.9 3-5.5 4.6-1.1 2.1-2.5 5.6-5.3 2.9-4.5-2.6-5.2-8.3-5.2-13-.3-7.6 2.8-14.7 5.5-21.6 1.7-4.3 1.3-9.2.2-13.6-1.3-5-5.4-8.6-8.5-12.6.2-1.5 4.2-.7 5.7-.4 3.4.9 5.4 3.8 7.9 6 1.8-.6 1-4.2 1.9-5.9 0-2.4 3.2-5.5 4.5-2.1 2 2.2 0 6.5 2.5 7.8 2.4-.9 3.6-3.5 5.8-4.7a8 8 0 0 1 7.8-.5c.9 2.2-2.6 4-3.6 6a20.4 20.4 0 0 0-3.8 18c1.4 5 3.8 9.5 4.7 14.5a40.1 40.1 0 0 1-.5 17.2c-.9 3.4-3.8 5.6-6.8 7-.8-.7-1.2-1.7-1.7-2.6z" />
      <path d="M711.6 326.9c-3.4-2.5-4.5-4.8-4.5-9.5 0-2.3.5-3.6 2-5.8 2.4-3.2 2-4.2-1.3-3.3-5.3 1.5-7.8.2-8-4.3 0-2.2.4-3.1 3.3-6.7 2.4-2.8 3.3-4.3 2.8-4.8-.5-.4-3.3 2-9 7.8a124 124 0 0 1-11.4 10.6c-9.8 6.6-19.2 7.6-23.5 2.5-2.2-2.6-2.1-4 .4-5.6a27.4 27.4 0 0 0 4.4-3.7 86 86 0 0 1 16.1-11.6c3.6-1.8 4.4-3 2.1-3-3 0-12.5 6.2-19.8 12.8-2.1 2-5.2 4.2-6.8 5a25.4 25.4 0 0 1-13.9 1c-2.2-.7-6.3-4.5-6.3-5.9 0-.3 1-1.1 2-1.8a30 30 0 0 0 4.6-3.2c5.8-5 16.8-10.3 25.5-12.2 2.8-.5 1.7-2-1.4-1.8a56 56 0 0 0-25 11.7c-8.3 6.9-20.8 6.2-24.8-1.3-.7-1.3-1.2-2.5-1-2.7a92.8 92.8 0 0 0 20.4-7.8 51.5 51.5 0 0 1 18.1-6.5c2.8-.5 3-1.9.3-2.2-3.6-.4-9 1.4-18.5 6-12.3 6.1-15.8 7.2-22.2 6.8-6-.4-9.3-1.9-14-6.4-3.2-3-7.6-10.5-6.8-11.4a63.5 63.5 0 0 0 15.8 1.3c8.3 0 10.6-.2 15-1.5a84.3 84.3 0 0 0 24-12.1 57.5 57.5 0 0 1 36.8-13.6c12.4 0 20.2 2.8 27.2 9.9 2.4 2.4 4.4 3.9 4.7 3.6.3-.3.6-4.5.7-9.3 0 0 3.7-.4 4.5.7 0 7.7 0 8.4 1.2 8.4.7 0 1.5-.8 2-2 1-2.5 5-6 9.2-8.2 9-4.5 24.7-4.7 37.3-.3a62.4 62.4 0 0 1 16.7 9.5 90.2 90.2 0 0 0 24 12c6.8 2 19 2.5 25.1 1a61.9 61.9 0 0 1 5.4-1c2.3 0-1.6 7.6-6.2 12.1-8.4 8.2-19.3 8.1-34.6-.1-9.6-5.2-21-8-21-5.2 0 .6.6 1 1.5 1 3.3 0 9.7 2.2 18.7 6.5a53.7 53.7 0 0 0 18.3 6.5c2.3 0 2.4 1.5.2 4.7-2.3 3.4-6.2 5-11.7 5-5.3 0-8.3-1.1-13-5-8-6.6-27.6-14-26.9-10 .2.7 1.1 1.2 3.2 1.5a56 56 0 0 1 23.1 11l5.9 4.3c1.1.6 1.1.8.2 2.5-1.4 2.8-5.2 4.9-9.2 5.3-5.2.6-9.8-1-14.5-5-10-8.3-19.3-14.3-22.3-14.3-2.5 0-1.4 1.4 3 3.7a79.7 79.7 0 0 1 15.8 11c2 1.9 4.3 3.7 5 4.1 1.9 1 1.8 2.4-.2 5s-5.4 3.8-9.7 3.3c-8.6-.9-15.4-5-26-16a70.7 70.7 0 0 0-8.2-7.8c-1.4 0-.5 1.9 2.2 5 3.4 3.7 4 5.8 2.7 9-1.1 2.6-3 3.3-6.8 2.2-4-1-4.6 0-2 3.1 3.8 4.9 3.3 10.7-1.5 14.8a12 12 0 0 1-3.4 2.3c-.4 0-1.4-1-2.3-2.4-3-4.6-5.7-4.6-8.7 0a53.6 53.6 0 0 1-2 3 113.1 113.1 0 0 1-3-2.2z" />
      <path d="m726.7 233-5.2 4-4.6-3.4v27.8h9.8z" />
      <path d="M694.9 204.3a88.3 88.3 0 0 1-9 32.3l11.1-10.3 7.7 9.2 8.4-9.4 8.5 8 8.2-8.3 8.5 10 7.4-8.2 12.6 9c-4.6-10-10.7-18.6-10-32.8-12.1 9-41 10.6-53.4.4z" />
      <path d="M717 197.6c-4.5 0-9.2.1-13.4 1a20.1 20.1 0 0 0-7.8 3c.3 8.6 41 12.1 51.9.2a20 20 0 0 0-8.2-3.3c-4-.8-8.6-.8-12.9-1v7.1H717v-7z" />
      <path d="M724.9 154h-6.3v49.4h6.4z" />
      <path d="m724.9 155.2-2.4 23.7 24.3 11.9-12.3-16.5 16.8-5.5zm-2.7-6.1c-3.7 0-6.4 1.4-6.4 3s2.7 3 6.4 3 6.4-1.4 6.4-3-2.7-3-6.4-3z" />
    </g>
    <g fill="#f7e017">
      <path d="M314 375.9c2.7-.9 4.2-2.5 5.3-5.6.5-1.6.9-3.2.8-3.6-.2-1-1.4-1-2.6.1-.9.7-1 1.1-.8 2.6.7 3.7-.7 4.7-7.7 5.4-.7 0-2.8 0-4.5-.3-3.4-.4-4.6 0-3.4 1a7 7 0 0 0 2.1.9c1.9.5 8.8.2 10.8-.5zm14.7-.6c.4-.4 1.7-1 3-1.5 1.7-.5 2.6-1 3.3-2.2 2.1-3 1.7-5.7-1.3-9.3-1.7-2-2.4-1.9-3.7.3-1.2 1.8-1.1 2 .5 2.4.9.2 1.7.8 2.1 1.6 1.8 3.1 1.3 5-1.4 5-2.4 0-3 .4-3.7 2l-.6 1.9c0 .6 1 .5 1.8-.2zm-4.8-3.5c.4-1.3.6-3.5.5-8a33 33 0 0 0-.3-6.4c-.4-.4-2.3.8-2.6 1.7a3 3 0 0 0 .5 2.1c.6 1.2.7 2.4.5 7-.3 6 .1 7 1.4 3.6z" />
      <path d="m312.6 369 .7-5c.1-1.7.5-3.8.7-4.7.7-2.3 0-2.9-1.8-1.6l-1.3 1 .2 3.3c.3 3-.2 8.5-.9 10.7-.2.6.1.4.9-.5.6-.7 1.3-2.2 1.5-3.3zm-10 1.6c2.4-2 2.1-5.6 2.7-8.4 0-1.9 1.2-4.1.4-5.8-2.3.4-3.7 2.6-2.5 4.7 0 2.5 0 5.2-1.3 7.4-1 1.5-4.4 1.1-4.2-1 .8-3-2.9-1.5-4.3-.7-1.1.8-3.5.9-2.6-1-.6-2.7-3.9-1-5.7-1-1.7 0-.1-3.5-2.6-3-4.5-.3-9.5.1-13.5-2.6-2.3-1.1-2-3.9-.7-5.7 1.4-2.4 1.8-5.5 4-7.4 2.3-2.1-2-1.2-3-.5-2.2 1.2-.2 4.3-2 6-1 1.8-2.4 4.2-4.8 3.9-3.5-.7-5.5-4-8-6-2.2-.5-1 3.4.2 4.2a21.9 21.9 0 0 0 7.4 3.6c2.6-.5 2.7 3 5 3.5 4 2 8.6 2.5 13.1 2.8 1.8.1.8 3.3 3.1 2.6 1.3.4 4.3-.5 4.4 1-2 2.4 1.9 2.3 3.3 2 1.9-.4 4.2-1 4.7 1.4 1.5 1.7 4.3 1.4 6.2.5l.7-.5z" />
      <path d="M262.8 350.4a23.9 23.9 0 0 0 2.4-4.2 16 16 0 0 1 2-3.6c1.2-1.2.5-2-1.4-1.6-1 .2-1.4.6-1.5 1.5a23 23 0 0 1-2.5 7c-1.7 2.5-1.7 2.6-1 2.6.3 0 1.2-.8 2-1.7zm-25-15.7c-1.9 0-2 1.2-.2 1.8 1 .3 1.7 1 2.3 2.3 1.7 3.5 2.8 4.2 7.5 4.6l3 .2.2 1.9c0 1 .3 1.8.5 1.8l2.6-1c2.2-1.2 4.3-3.8 4.3-5.5 0-1-1.8-2.2-3.4-2.2-.7 0-2 .6-3.1 1.4-3.4 2.4-7 2-9-1.2-1.5-2.6-3.2-4-4.7-4zm16.3 6.5c.9 0 1 .7.6 2a.9.9 0 0 1-1.7 0c-.4-1 .1-2 1-2zm126.5-4c-1.3 0-1.6.2-2.4 1.4-1.3 1.9-1.4 6-.2 7.4.7.9.8.9 2.3.2 2.2-.9 2.6-.8 2.5.3 0 3-4.2 8.7-8.6 11.7a9.7 9.7 0 0 0-2.4 2c-.3.8 1.3.7 3.3-.4a21 21 0 0 0 7.9-8c1.1-2.3 1.3-3 1.5-7 0-3.8 0-5-.6-6.2-.8-1.4-1-1.5-2.8-1.5h-.5zm.1 2.5c1 0 1 .2 1.2 1.6 0 1-.1 1.7-.7 2.3-.8.7-1 .7-1.6-.4-1-1.6-.4-3.5 1.1-3.5zm-20.2 28.5c3.9-2 6.2-4.1 7.6-7.2a20 20 0 0 0 1.3-3.1c0-.6-1.9-1.5-3-1.5s-1.4-.8-1-3c.5-2.1 0-4.8-1-4.8-.3 0-1 .5-1.3 1.1-.6 1-.7 1.4-.2 2.7.7 2 .1 3.6-1.7 4.9-.9.6-1.3 1.3-1.3 2l.1 1 2.1-1 2-1.2 1.1 1c.7.4 1.2 1.2 1.2 1.7 0 2.4-6.8 6.4-11.4 6.8-2.5.2-3 0-3.8-.8-.7-.7-.9-1.3-.7-2l.5-2.6c.4-2.1-.4-1.8-2 .6-1.2 2-1.6 4.1-.9 5.2.6 1 4.4 1.8 7.2 1.6 1.7 0 3.4-.5 5.2-1.4zm26-14.5c2.4-2.5 3.5-5.5 3.5-10v-3.5l2-1c2.7-1.2 5.2-3.7 5.2-5.1 0-1.4-.7-1.4-1.8.2-.9 1.1-2 1.8-6 3.7-1 .4-1.1.7-1.4 5-.2 5-1 6.8-3.7 10.2-1.7 2-1.8 2.4-.6 2.4.5 0 1.8-.9 2.8-2zm-26.7-2.8c.2-.7-1.2-1.2-1.7-.6-.3.2-.4.6-.2.9.4.6 1.6.4 1.9-.3zm36.8-9.5c.3-.8-1.1-1.3-1.7-.7-.2.3-.3.7-.1 1 .4.6 1.6.4 1.8-.3zm-44.3-25.9c-1 .5-1.5 1.5-2.1 2.3-.5.3-.1.6.1 1 1.7 1.7 2.4 4.2 3.2 6.5.8 2.7 1.8 5.6 1 8.4-.3 1-1.2 2.1-2.4 1.8-2-.1-4-.7-6-.7-1.9.1-3.3 1.8-5.1 1.6-1.2 0-1.2-2.4-2.2-1.7-.6 1.3-.3 2.7-.4 4 .2.3.8.1 1.1.2h3.7c.2 1.2.2 2.7 1 3.7 1.3.4 2.8 0 4-.5 1.2-.6 1.4-2.1 1.8-3.3.4-1.3 2-1 3-1.5a5.7 5.7 0 0 0 4-5.7c-.2-3.9-1.6-7.4-2.8-11-.5-1.6-.9-3.3-1.5-4.9l-.4-.2zm-6 21.8c1.3 0 1.9 1.6 1.6 2.7-.5 1.5-2.4.6-2.7-.5-.7-1-.3-2.3 1-2.2z" />
      <path d="M296 324.8c-.6 0-1.3.2-2 .7-3.5 2.5-4.5 5.4-2 6.6 1.7.8 1.3 1.8-1.5 3.2-4 2-7.5 1.7-14.2-1-1.6-.7-2-.4-1.7 1 .4 1.5 1.8 2.3 5.1 3 3.6 1 8 .7 10.8-.5a14 14 0 0 0 4.3-3.4l2.2-2.3 2.5.3c3.1.4 3.2.4 3.2 1.9 0 1.2 0 1.2 2.9 1.5l4.7.2c1.3 0 2 .3 2.4.9.6.7.9.8 5.6.4 4.4-.4 5.2-.4 7.2.3 1.4.4 3 .6 4.1.5 3.4-.4 8-3.1 8.7-5.1 0-.3 1.3-.7 2.7-1 3.4-.7 3.5-1.4.4-1.8a22.9 22.9 0 0 1-4.6-1.1 12.2 12.2 0 0 0-3.5-.9c-1.7 0-3.3 1-3.3 2.2 0 .7.2.8 2.3.6 1.8-.2 2.4-.1 3.4.7.7.5 1.1 1.1 1 1.3-.5.8-4.5 2.6-6.2 2.9a4.7 4.7 0 0 1-3-.5c-1.6-.8-3.8-.9-4.3-.2-.2.3-.6.1-1.3-.5l-1-1-2.4 1c-2.4 1-3.3 1-3.3-.2 0-.5-.7-.6-4.2-.3-3.9.2-4.3.1-5-.7-.7-.7-.7-1-.2-1.7.4-.8.4-1 0-1.5-.4-.4-1-.4-2.5 0-3.9 1-5 .5-5-2.5 0-1.9-1-3-2.3-3zm-1 2.8c.2 0 .4.1.7.4.3.3.5 1 .3 1.3-.3.9-2 .9-2.3 0-.2-.5 0-1 .5-1.3l.8-.4z" />
      <path d="M288 330.4c2.4-1.5 2.4-1.4 2.7-5.5.2-3 .2-3.2-.6-3.2-1.2 0-1.8 1.3-1.8 3.7 0 1.6-.2 2.3-1 3-2 2-6.8 1.1-7.5-1.3-.2-.8.1-1.6 1.1-3 2.1-3 1.7-3.8-1-1.5-1.7 1.6-2 1.6-1.7.3.3-1.3-.3-1.8-1.8-1.4-.7.2-1.2.6-1.3 1.6-.2.8-.6 1.3-1.3 1.5-1.2.3-3.2-.8-3.2-1.8 0-.7 3-4.4 6.9-8.4 1.4-1.5 2.6-3 2.6-3.1 0-.2-.8-.4-1.7-.4-1.4 0-1.8.2-1.8.8 0 .4-1.9 3-4.3 5.7-5 5.6-5.4 6.7-3 8.2a6 6 0 0 0 6.6-.2l1.6-1.1v2c0 2.5.5 3.5 2.5 4.5a8 8 0 0 0 8-.4zm104.4-34.6c-1.8 1.1-.4 3.4 0 5-.8 2-3.5 2.6-5.5 3-2.8.5-4.8 2.8-5.8 5.3-.6 1.6-2 4-3.5 1.6-1.3-1.3-3.7-2.4-5.2-.8-1.2 1.1-1.5 2.7-2 4.2-.7-1.1-1-2.8-2.4-3.2-2.4.3-1.5 3.3-.4 4.5 1 1.5 2 3.3 1 5-1 2-4 3.4-5.7 1.7-1.6-.9-.5-4-2.2-4.2-.8.6-.8 3.9-2.1 2.1-1-1.5-.4-3.6-1.6-4.9-1.3.2-2.4 2.5-2 3.7 1.8 2.4 2.6 5.4 3.3 8.3.4 1.2-.1 3.5 1 4 .7-1.9 0-4 .6-5.9 1.8-.2 3.7.6 5.5.2 2.7-.3 4.7-2.6 5.6-4.9.3-1.8 0-3.6-.1-5.4 2 .4 4.2.4 6.2 1 1 1.5-.3 3.7-.6 5.3-1 3.4-3.7 5.8-6.2 8-1.1.7-1.2 2.4.3 1.5a15 15 0 0 0 7.5-8c1.1-2.6.2-5.5 1-8.1 1-2 3.5-1.6 5.4-1.6 1.9 0 3.5-2.3 2.9-4.2-.6-2.2 1.7-3.2 3.2-4 2.1-1 3.7-3.1 3.5-5.5 0-1.3 0-3.6-1.7-3.7zm-7.3 12.5c2.2.6-.4 4.8-1.6 2.1-.4-1 .5-2.1 1.6-2.1zm-10.3 3c2.9-.1 1.8 4-.6 2.2-1.3-.7-.9-2.2.6-2.2zM270 327.6c0-.4-.2-.7-.6-.7-.7 0-1.2.7-.9 1.3.4.7 1.3.3 1.5-.6zm34-3.6c0-.7-.3-.9-.8-.8-1.1.2-1.3 1.7-.1 1.7.6 0 .9-.3.9-1zm-42-20.4c-1.3-.3-2.2.9-2.7 2-1 1.7-2.2 3.4-4 4.3-1.3.4-2.7-.1-3.9-.8-1.3-.7-1-2.3-1.6-3.4-1-.8-2.7.3-2.6 1.5-.1 1.6 1.3 2.5 2.6 3.1 1 .7 2.6 1 3 2.3 0 1.1.4 2.4 1.7 2 1.5 0 2 1.8 1.3 2.9a6.3 6.3 0 0 0-.7 4c.7.7 1.4-1 2-1.4l1-1.4c2.7.1 5.3.5 8 .4 2 0 3.5-1.2 4.7-2.6 1.8-1.8 3.2-3.9 5.1-5.4 1.4-.4.7-3-.8-2.2-1.3.5-1.7 2-2.6 2.9a31 31 0 0 1-5 5.2c-1.5.6-3.1.3-4.6 0-.6-.5 1.2-1 1.5-1.6.8-.8 1.8-1.6 2.3-2.7-.5-1-1.9-1-2.9-1-2.4.2-4.3 2.3-6.8 2.5-1.9 0-.9-2 0-2.7 1.6-2 3.4-3.8 5.1-5.7.5-.6 2.3-1.2 1.2-2-.4-.2-.9-.2-1.3-.2zm1.2 10c1.3.7-.8 1.8-1.6 1.7-1.1.3-1.2-.8-.2-1 .6-.4 1.2-.7 1.8-.7zm-3.8 2.6c.7 0 2.2.7.8 1.1-1 .8-2-.8-.8-1.1z" />
      <path d="M289.4 317.8c0-1-1.6-.8-1.8.2-.2.6 0 .7.8.6.6 0 1-.4 1-.8zm74.7-6.6c.2-.9-1-1.5-1.7-.8-.7.6 0 1.9.8 1.7.4 0 .8-.5 1-.9zM248 302.1c1.1-1 1.2-1.1.7-3.3-.8-3.1-.7-3.5.5-3.8 1.5-.3 5.3 1.7 6 3.3.8 1.3.7 1.4-.4 2.4-1.2 1.1-1.2 2.4 0 2.4 1 0 3.7-2.6 3.7-3.5 0-1.3-3-4.4-5.4-5.5a10.7 10.7 0 0 0-4.6-1c-3.1 0-3.5.7-2.7 4.2 1 4.2-.3 4.8-3.7 1.6a10.4 10.4 0 0 1-3.5-8.6c0-4 1.6-6.1 5.1-6.6 2.4-.3 2.3-.9 0-1.2-3.6-.6-6.6 1.8-7.7 6-1.3 4.7 1.6 10.4 6.7 13.3 2.7 1.5 3.7 1.6 5.3.3zm139.2-5.2c.3-.2.5-.9.5-1.4s.5-1.6 1.1-2.4c.6-.8 1-1.6 1-1.8 0-.8-1.3-.8-2.3 0-1.1.9-2 1-2 0l1.2-.9c1.6-.7 1.7-1.5.4-2.1-1.7-.8-3.5.6-3.6 3-.1 1.6 0 1.8 1.2 2.5 1.1.7 1.3 1 1.1 2-.3 1.6.4 2.2 1.4 1.1zm13-1.4c1-1 1.8-1.9 1.8-2.2 0-.3.9-1.2 1.9-2 2.8-2 3.5-4 2.2-7.3-.5-1.3-2-3-5.5-6a26 26 0 0 0-5.4-4.4c-.9 0-.7 3.4.2 3.7 1.7.6 2.8 1.3 5.4 3.7 3.2 2.8 4.6 5.5 3.8 7-.7 1.4-1.7 1-4.5-2a13.8 13.8 0 0 0-3.2-2.9c-.3 0-.5.6-.5 1.4 0 1 .5 2 2 3.5 2.3 2.6 2.5 4.1.7 5.5a5 5 0 0 1-1.4 1 33.2 33.2 0 0 0-9-10c-.3 0-.5.4-.5 1.4 0 1.3.2 1.7 1.2 2.2a38 38 0 0 1 7 7l1.8 2a9 9 0 0 0 2-1.7zm6-16.8c-.5-1.2-8.4-9.4-9.3-9.4-.4 0-.5.5-.4 1.8 0 1.6.3 1.8 1.4 2.1a20 20 0 0 1 4.6 3.7 17 17 0 0 0 3.7 3v-1.2zm-47.8 92.6a1.2 1 0 1 1-2.3 0 1.2 1 0 1 1 2.3 0zm4.2-1.4a1.2 1 0 1 1-2.4 0 1.2 1 0 1 1 2.4 0z" />
    </g>
  </svg>
)

export default BnFlag

import axios from "axios";
import sourceUrl from "../api/Source.api";
import { jwtDecode } from "jwt-decode";
import { refreshAuthToken } from "../contexts/AuthUtils";
import { InvokeMatrixApi } from "../contexts/WorkspaceUtils";

// 🔹 API Base URL
const API_BASE_URL = sourceUrl;

// 🔹 Firebase Domains to Skip
const FIREBASE_DOMAINS = ["firebaseio.com", "firebaseapp.com", "googleapis.com", "google-analytics.com"];

// 🔹 Get Tokens & User Info
const getAuthToken = () => localStorage.getItem("authToken");
const getTempToken = () => localStorage.getItem("tempToken");
const getUserId = () => localStorage.getItem("userId");

// ✅ Function to Validate Token
const isTokenValid = (token) => {
  if (!token) return false;
  try {
    const decoded = jwtDecode(token);
    return decoded.exp * 1000 > Date.now(); // Check expiration
  } catch (error) {
    console.error("Invalid token:", error);
    return false;
  }
};

// ✅ Function to Get a Valid Token (Refresh If Needed)
const getValidAuthToken = async () => {
  let authToken = getAuthToken();
  if (!isTokenValid(authToken)) {
    try {
      authToken = await refreshAuthToken();
    } catch (error) {
      console.error("Failed to refresh token:", error);
      throw error; // Handle failed refresh (e.g., force logout)
    }
  }
  return authToken;
};

// ✅ Ensure Fetch is Available
if (!window.fetch || typeof window.fetch !== "function") {
  console.warn("⚠️ window.fetch is missing, restoring original fetch!");
  window.fetch = window.__originalFetch || fetch;
}

// ✅ Store Original Fetch Safely
window.__originalFetch = window.__originalFetch || window.fetch;

// ✅ Override Fetch (Track API Calls)
window.fetch = async (url, options = {}) => {
  try {
    if (!window.__originalFetch) {
      throw new Error("window.fetch is unavailable.");
    }

    // Skip Firebase Requests
    if (FIREBASE_DOMAINS.some((domain) => url.includes(domain))) {
      return window.__originalFetch(url, options);
    }

    const authToken = await getValidAuthToken();
    const tempToken = getTempToken();
    const userId = getUserId();

    const headers = {
      "Content-Type": "application/json",
      ...(authToken ? { Authorization: `Bearer ${authToken}` } : {}),
      ...(tempToken ? { "X-Admin-Token": `{Bearer ${tempToken}}` } : {}),
      ...options.headers,
    };

    const response = await window.__originalFetch(
      url.startsWith("http") ? url : `${API_BASE_URL}${url}`,
      { ...options, headers }
    );

    // Clone response to avoid stream locking
    const clonedResponse = response.clone();
    const jsonData = await clonedResponse.json();

    // Track API Response
    if (userId && !url.includes("/add")) {
      await InvokeMatrixApi(jsonData, response, options?.method, null, url, options?.body || {}, userId);
    }

    return response;
  } catch (error) {
    console.error("Fetch error:", error);

    const userId = getUserId();
    if (userId && !url.includes("/add")) {
      await InvokeMatrixApi(error, error, options?.method, null, url, options?.body, userId);
    }

    throw error;
  }
};

// ✅ Configure Axios Globally (Skip Firebase Calls)
axios.defaults.baseURL = API_BASE_URL;
axios.defaults.headers.common["Content-Type"] = "application/json";

// 🔹 Axios Request Interceptor (Checks Token Before Each Request)
axios.interceptors.request.use(async (config) => {
  if (FIREBASE_DOMAINS.some((domain) => config.url.includes(domain))) {
    return config;
  }

  try {
    const authToken = await getValidAuthToken();
    const tempToken = getTempToken();

    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    if (tempToken) {
      config.headers["X-Admin-Token"] = `{Bearer ${tempToken}}`;
    }
  } catch (error) {
    console.error("Token validation failed:", error);
    throw error;
  }

  return config;
});

// 🔹 Axios Response Interceptor
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.error("Axios error:", error);

    const userId = getUserId();
    if (userId) {
      await InvokeMatrixApi(error, error.response, error.config?.method, null, error.config?.url, error.config?.data, userId);
    }

    return Promise.reject(error);
  }
);


// import axios from "axios";
// import sourceUrl from "../api/Source.api";
// import { jwtDecode } from "jwt-decode";
// import { refreshAuthToken } from "../contexts/AuthUtils";
// import { InvokeMatrixApi } from "../contexts/WorkspaceUtils";

// // 🔹 Your API Base URL
// const API_BASE_URL = sourceUrl;

// // 🔹 Firebase Base URL (Skip These Requests)
// const FIREBASE_DOMAINS = ["firebaseio.com", "firebaseapp.com", "googleapis.com", "google-analytics.com"];

// // 🔹 Get Tokens from Local Storage
// const getAuthToken = () => localStorage.getItem("authToken");
// const getTempToken = () => localStorage.getItem("tempToken");
// const getUserId = () => localStorage.getItem("userId");

// // ✅ Function to Validate Token
// const isTokenValid = (token) => {
//   if (!token) return false;
//   try {
//     const decoded = jwtDecode(token);
//     return decoded.exp * 1000 > Date.now(); // Check expiration
//   } catch (error) {
//     console.error("Invalid token:", error);
//     return false;
//   }
// };

// // ✅ Function to Get a Valid Token (Refresh If Needed)
// const getValidAuthToken = async () => {
//   let authToken = getAuthToken();
//   if (!isTokenValid(authToken)) {
//     try {
//       authToken = await refreshAuthToken();
//     } catch (error) {
//       console.error("Failed to refresh token:", error);
//       throw error; // Handle failed refresh (e.g., force logout)
//     }
//   }
//   return authToken;
// };


// // ✅ Override Fetch Globally (Skip Firebase Calls)
// const originalFetch = window.fetch;
// window.fetch = async (url, options = {}) => {
//   if (FIREBASE_DOMAINS.some((domain) => url.includes(domain))) {
//     return originalFetch(url, options);
//   }

//   try {
//     const authToken = await getValidAuthToken();
//     const tempToken = getTempToken();
//     const userId = getUserId();

//     const headers = {
//       "Content-Type": "application/json",
//       ...(authToken ? { Authorization: `Bearer ${authToken}` } : {}),
//       ...(tempToken ? { "X-Admin-Token": `{Bearer ${tempToken}}` } : {}),
//       ...options.headers,
//     };

//     const response = await originalFetch(
//       url.startsWith("http") ? url : `${API_BASE_URL}${url}`,
//       { ...options, headers }
//     );

//      const clonedResponse = response.clone();
//      const jsonData = await clonedResponse.json(); 
 
//      // Call InvokeMatrixApi with JSON data without affecting the original response
//      if(userId &&  !url.includes('/add')){
//        await InvokeMatrixApi(jsonData, response, options?.method, null, url, options?.body ? options?.body : {}, userId);
//      }
 
//     return response;
//   } catch (error) {
//     const userId = getUserId();
//     console.error("Fetch error:", error);
//     // console.log("response error", error);
//     if( userId && !url.includes('/add')){
//       await InvokeMatrixApi(error, error, options?.method, null, url, options?.body, userId);
//     }
//     throw error;
//   }
// };

// // ✅ Configure Axios Globally (Skip Firebase Calls)
// axios.defaults.baseURL = API_BASE_URL;
// axios.defaults.headers.common["Content-Type"] = "application/json";

// // 🔹 Axios Request Interceptor (Checks Token Before Each Request)
// axios.interceptors.request.use(async (config) => {
//   if (FIREBASE_DOMAINS.some((domain) => config.url.includes(domain))) {
//     return config;
//   }

//   try {
//     const authToken = await getValidAuthToken();
//     const tempToken = getTempToken();

//     if (authToken) {
//       config.headers.Authorization = `Bearer ${authToken}`;
//     }
//     if (tempToken) {
//       config.headers["X-Admin-Token"] = `{Bearer ${tempToken}}`;
//     }
//   } catch (error) {
//     console.error("Token validation failed:", error);
//     throw error;
//   }

//   return config;
// });

// // 🔹 Axios Response Interceptor
// axios.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     console.error("Axios error:", error);
//     return Promise.reject(error);
//   }
// );


// import axios from "axios";
// import sourceUrl from "../api/Source.api";
// import { jwtDecode } from "jwt-decode";
// import { refreshAuthToken } from "../contexts/AuthUtils";
// import { InvokeMatrixApi } from "../contexts/WorkspaceUtils";

// // 🔹 Your API Base URL
// const API_BASE_URL = sourceUrl;

// // 🔹 Firebase Base URL (Skip These Requests)
// const FIREBASE_DOMAINS = ["firebaseio.com", "firebaseapp.com", "googleapis.com", "google-analytics.com"];

// // 🔹 Get Tokens from Local Storage
// const getAuthToken = () => localStorage.getItem("authToken");
// const getTempToken = () => localStorage.getItem("tempToken");
// const getUserId = () => localStorage.getItem("userId");

// // ✅ Function to Validate Token
// const isTokenValid = (token) => {
//   if (!token) return false;
//   try {
//     const decoded = jwtDecode(token);
//     return decoded.exp * 1000 > Date.now(); // Check expiration
//   } catch (error) {
//     console.error("Invalid token:", error);
//     return false;
//   }
// };

// // ✅ Function to Get a Valid Token (Refresh If Needed)
// const getValidAuthToken = async () => {
//   let authToken = getAuthToken();
//   if (!isTokenValid(authToken)) {
//     try {
//       authToken = await refreshAuthToken();
//     } catch (error) {
//       console.error("Failed to refresh token:", error);
//       throw error; // Handle failed refresh (e.g., force logout)
//     }
//   }
//   return authToken;
// };


// // ✅ Override Fetch Globally (Skip Firebase Calls)
// const originalFetch = window.fetch;
// window.fetch = async (url, options = {}) => {
//   if (FIREBASE_DOMAINS.some((domain) => url.includes(domain))) {
//     return originalFetch(url, options);
//   }

//   try {
//     const authToken = await getValidAuthToken();
//     const tempToken = getTempToken();
//     const userId = getUserId();

//     const headers = {
//       "Content-Type": "application/json",
//       ...(authToken ? { Authorization: `Bearer ${authToken}` } : {}),
//       ...(tempToken ? { "X-Admin-Token": `{Bearer ${tempToken}}` } : {}),
//       ...options.headers,
//     };

//     const response = await originalFetch(
//       url.startsWith("http") ? url : `${API_BASE_URL}${url}`,
//       { ...options, headers }
//     );

//      const clonedResponse = response.clone();
//      const jsonData = await clonedResponse.json(); 
 
//      // Call InvokeMatrixApi with JSON data without affecting the original response
//      if(userId &&  !url.includes('/add')){
//        await InvokeMatrixApi(jsonData, response, options?.method, null, url, options?.body ? options?.body : {}, userId);
//      }
 
//     return response;
//   } catch (error) {
//     const userId = getUserId();
//     console.error("Fetch error:", error);
//     // console.log("response error", error);
//     if( userId && !url.includes('/add')){
//       await InvokeMatrixApi(error, error, options?.method, null, url, options?.body, userId);
//     }
//     throw error;
//   }
// };

// // ✅ Configure Axios Globally (Skip Firebase Calls)
// axios.defaults.baseURL = API_BASE_URL;
// axios.defaults.headers.common["Content-Type"] = "application/json";

// // 🔹 Axios Request Interceptor (Checks Token Before Each Request)
// axios.interceptors.request.use(async (config) => {
//   if (FIREBASE_DOMAINS.some((domain) => config.url.includes(domain))) {
//     return config;
//   }

//   try {
//     const authToken = await getValidAuthToken();
//     const tempToken = getTempToken();

//     if (authToken) {
//       config.headers.Authorization = `Bearer ${authToken}`;
//     }
//     if (tempToken) {
//       config.headers["X-Admin-Token"] = `{Bearer ${tempToken}}`;
//     }
//   } catch (error) {
//     console.error("Token validation failed:", error);
//     throw error;
//   }

//   return config;
// });

// // 🔹 Axios Response Interceptor
// axios.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     console.error("Axios error:", error);
//     return Promise.reject(error);
//   }
// );
import React, {useState, useEffect, useRef, useMemo} from 'react'
import {
    Box,
    Input,
    Button,
    Popover,
    MenuItem,
    InputAdornment,
    FormControl,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useWorkspace } from '../../contexts/WorkspaceContext';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const CommonDropdown = ({filteredLanguages, setFilteredLanguages}) => {
    const {
        genVideoLanguage,
        selectedGenVideoLanguage,
        setSelectedGenVideoLanguage,
    } = useWorkspace();
    
    
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchValue, setSearchValue] = useState("");

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchValue(value);
        const filtered = genVideoLanguage.filter(lang => 
            lang.languageName.toLowerCase().includes(value)
        );
        setFilteredLanguages(filtered);
    };

    const handleSelect = (language) => {
        console.log("Selecting", language)
        setSelectedGenVideoLanguage(language);
        handleClose();
    };

    const open = Boolean(anchorEl);

    return (
        <FormControl>
            <Button
                onClick={handleOpen}
                sx={{
                    color: selectedGenVideoLanguage?.languageName ? "rgba(255, 255, 255, 1)" : "rgba(202, 196, 208, 1)",
                    backgroundColor: "#2F355F",
                    borderRadius: "8px",
                    height: "42px",
                    minWidth: "120px",
                    textTransform: 'none', 
                    fontFamily:"Inter",
                    fontWeight:400,
                    padding:"0px 8px 0px 12px",
                    '&:hover': {
                        backgroundColor: "#373E6D",
                    },
                }}
            >
                {selectedGenVideoLanguage?.languageName || "English"}
                <ArrowDropDownIcon sx={{ color: "rgba(202, 196, 208, 1)", marginLeft:"auto" }} />
            </Button>

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                sx={{
                    '& .MuiPaper-root': {
                        backgroundColor: "#2F355F",
                        color: "white",
                        borderRadius: "8px",
                        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
                        maxHeight: "300px",
                        width: "180px",
                        marginTop:"4px"
                    },
                }}
            >
                <Box sx={{ p: 1, borderBottom: "1px solid rgba(255,255,255,0.1)"}}>
                    <Input 
                        fullWidth
                        value={searchValue}
                        onChange={handleSearch}
                        placeholder="Search language"
                        startAdornment={
                            <InputAdornment position="start">
                                <SearchIcon sx={{ color: "rgba(255,255,255,0.7)" }} />
                            </InputAdornment>
                        }
                        sx={{
                            color: "white",
                            '&:before, &:after': { borderBottom: 'none' },
                            '&:hover:not(.Mui-disabled):before': { borderBottom: 'none' },
                        }}
                        disableUnderline={false}
                    />
                </Box>

                <Box sx={{ overflowY: 'auto', maxHeight: 250, }}>
                    {filteredLanguages?.map((language) => (
                        <MenuItem
                            key={language.languageCode}
                            onClick={() => handleSelect(language)}
                            sx={{
                                '&:hover': { backgroundColor: "#373E6D" },
                                '&.Mui-selected': { backgroundColor: "#373E6D" },
                                py: 1,
                                fontSize: 14,
                                fontFamily:"Inter",
                                color:"#FFFFFF",
                                borderRadius:""
                            }}
                        >
                            {language.languageName}
                        </MenuItem>
                    ))}
                </Box>
            </Popover>
        </FormControl>
    );
};

const CommonDropdownForVoices = ({languageOption, setFilteredVoices, setfilterCriteria, filterCriteria }) => {
    
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchValue, setSearchValue] = useState("");
    const [ selectedGenVideoVoice, setSelectedGenVideoVoice] = useState("")
    const [ allFilterVideoVoices, setAllFilterVideoVoices] = useState([])
    const open = Boolean(anchorEl);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const {
        genVideoVoice,
        setGenVideoVoice
    } = useWorkspace();

    useEffect(()=>{
        setAllFilterVideoVoices(languageOption)
    },[languageOption])

    useEffect(()=>{
        if(filterCriteria.language === ""){
            setSelectedGenVideoVoice("")
        }
    },[filterCriteria])

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchValue(value);
        const filtered = languageOption.filter(voice => 
            voice.name.toLowerCase().includes(value)
        );
        setAllFilterVideoVoices(filtered);
    };

    // console.log("searchValue", searchValue)

    const handleSelect = (voice) => {
        setfilterCriteria(prevFilter => ({
            ...prevFilter, 
            language: voice?.language  
          }))
        setSelectedGenVideoVoice(voice)
        handleClose();
    };
    
    return (
        <FormControl>
            <Button
                onClick={handleOpen}
                sx={{
                    color:  selectedGenVideoVoice?.name ? "rgba(255, 255, 255, 1)"  : "rgba(202, 196, 208, 1)",
                    backgroundColor: "#2F355F",
                    borderRadius: "8px",
                    height: "42px",
                    minWidth: "120px",
                    textTransform: 'none',
                    fontFamily:"Inter",
                    fontWeight:400,
                    '&:hover': {
                        backgroundColor: "#373E6D",
                    },
                }}
            >
                {selectedGenVideoVoice?.name || "Language"}
                <ArrowDropDownIcon sx={{ color:  selectedGenVideoVoice?.name ? "rgba(255, 255, 255, 1)"  :  "rgba(202, 196, 208, 1)", marginLeft:"auto" }} />
            </Button>

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                sx={{
                    '& .MuiPaper-root': {
                        backgroundColor: "#2F355F",
                        color: "white",
                        borderRadius: "8px",
                        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
                        maxHeight: "300px",
                        width: "180px",
                        marginTop:"4px"
                    },
                }}
            >
                <Box sx={{ p: 1, borderBottom: "1px solid rgba(255,255,255,0.1)" }}>
                    <Input
                        fullWidth
                        value={searchValue}
                        onChange={handleSearch}
                        placeholder="Search language"
                        startAdornment={
                            <InputAdornment position="start">
                                <SearchIcon sx={{ color: "rgba(255,255,255,0.7)" }} />
                            </InputAdornment>
                        }
                        sx={{
                            color: "white",
                            '&:before, &:after': { borderBottom: 'none' },
                            '&:hover:not(.Mui-disabled):before': { borderBottom: 'none' },
                        }}
                        disableUnderline={false}
                    />
                </Box>

                <Box sx={{ overflowY: 'auto', maxHeight: 250 }}>
                    {allFilterVideoVoices?.map((voice) => (
                        <MenuItem
                            key={voice.id}
                            onClick={() => handleSelect(voice)}
                            sx={{
                                '&:hover': { backgroundColor: "#373E6D" },
                                '&.Mui-selected': { backgroundColor: "#373E6D" },
                                py: 1,
                                fontSize: 14,
                            }}
                        >
                            {voice.name}
                        </MenuItem>
                    ))}
                </Box>
            </Popover>
        </FormControl>
    );
};

const CommonDropdownForGender = ({genderOption, setFilteredVoices, setfilterCriteria, filterCriteria}) => {
    const {
        genVideoVoice,
        setGenVideoVoice
    } = useWorkspace();
    
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchValue, setSearchValue] = useState("");
    const [selectedGender, setSelectedGender] = useState();
    const [allFilterVoiceGender, setAllFilterVoiceGender] = useState([]);
    const open = Boolean(anchorEl);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(()=>{
        setAllFilterVoiceGender(genderOption)
    },[genderOption])

    useEffect(()=>{
        if(filterCriteria.gender === ""){
            setSelectedGender("")
        }
    },[filterCriteria])

    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchValue(value);
        const filtered = genderOption.filter(voice => 
            voice.name.toLowerCase().includes(value)
        );
        setAllFilterVoiceGender(filtered);
    };

    const handleSelect = (genderObject) => {
        setfilterCriteria(prevFilter => ({
            ...prevFilter, 
            gender: genderObject?.gender  
          }))
        setSelectedGender(genderObject)
        handleClose();
    };

    

    return (
        <FormControl>
            <Button
                onClick={handleOpen}
                sx={{
                    color: selectedGender?.name ? "rgba(255, 255, 255, 1)" : "rgba(202, 196, 208, 1)",
                    backgroundColor: "#2F355F",
                    borderRadius: "8px",
                    height: "42px",
                    minWidth: "120px",
                    textTransform: 'none',
                    fontFamily:"Inter",
                    fontWeight:400,
                    '&:hover': {
                        backgroundColor: "#373E6D",
                    },
                }}
            >
                {selectedGender?.name || "Gender"}
                <ArrowDropDownIcon sx={{ color: selectedGender?.name ? "rgba(255, 255, 255, 1)" :  "rgba(202, 196, 208, 1)", marginLeft:"auto" }} />
            </Button>

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                sx={{
                    '& .MuiPaper-root': {
                        backgroundColor: "#2F355F",
                        color: "white",
                        borderRadius: "8px",
                        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
                        maxHeight: "300px",
                        width: "180px",
                        marginTop:"4px"
                    },
                }}
            >
                <Box sx={{ p: 1, borderBottom: "1px solid rgba(255,255,255,0.1)" }}>
                    <Input
                        fullWidth
                        value={searchValue}
                        onChange={handleSearch}
                        placeholder="Search language"
                        startAdornment={
                            <InputAdornment position="start">
                                <SearchIcon sx={{ color: "rgba(255,255,255,0.7)" }} />
                            </InputAdornment>
                        }
                        sx={{
                            color: "white",
                            '&:before, &:after': { borderBottom: 'none' },
                            '&:hover:not(.Mui-disabled):before': { borderBottom: 'none' },
                        }}
                        disableUnderline={false}
                    />
                </Box>

                <Box sx={{ overflowY: 'auto', maxHeight: 250 }}>
                    {allFilterVoiceGender?.map((ele) => (
                        <MenuItem
                            key={ele.id}
                            onClick={() => handleSelect(ele)}
                            sx={{
                                '&:hover': { backgroundColor: "#373E6D" },
                                '&.Mui-selected': { backgroundColor: "#373E6D" },
                                py: 1,
                                fontSize: 14,
                            }}
                        >
                            {ele.name}
                        </MenuItem>
                    ))}
                </Box>
            </Popover>
        </FormControl>
    );
};


const CommonDropdownForCountry = ({countryOption, setFilteredVoices, setfilterCriteria, filterCriteria}) => {
    const {
        genVideoVoice,
        setGenVideoVoice
    } = useWorkspace();
    
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchValue, setSearchValue] = useState("");
    const [selectedCountry, setSelectedCountry] = useState();
    const [allFilterVoiceCountry, setAllFilterVoiceCountry] = useState([]);
    const open = Boolean(anchorEl);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(()=>{
        setAllFilterVoiceCountry(countryOption)
    },[countryOption])

    useEffect(()=>{
        if(filterCriteria.country === ""){
            setSelectedCountry("")
        }
    },[filterCriteria])

    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchValue(value);
        const filtered = countryOption.filter(country => 
            country.name.toLowerCase().includes(value)
        );
        setAllFilterVoiceCountry(filtered);
    };

    const handleSelect = (countryObject) => {
        setfilterCriteria(prevFilter => ({
            ...prevFilter, 
            country: countryObject?.country  
          }))
        setSelectedCountry(countryObject)
        handleClose();
    };

    

    return (
        <FormControl>
            <Button
                onClick={handleOpen}
                sx={{
                    color: selectedCountry?.name ? "rgba(255, 255, 255, 1)" : "rgba(202, 196, 208, 1)",
                    backgroundColor: "#2F355F",
                    borderRadius: "8px",
                    height: "42px",
                    minWidth: "120px",
                    textTransform: 'none',
                    fontWeight:400,
                    fontFamily:"Inter",
                    '&:hover': {
                        backgroundColor: "#373E6D",
                    },
                }}
            >
                {selectedCountry?.name || "Country"}
                <ArrowDropDownIcon sx={{ color: selectedCountry?.name ? "rgba(255, 255, 255, 1)" : "rgba(202, 196, 208, 1)", marginLeft:"auto" }} />
            </Button>

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                sx={{
                    '& .MuiPaper-root': {
                        backgroundColor: "#2F355F",
                        color: "white",
                        borderRadius: "8px",
                        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
                        maxHeight: "300px",
                        width: "180px",
                        marginTop:"4px"
                    },
                }}
            >
                <Box sx={{ p: 1, borderBottom: "1px solid rgba(255,255,255,0.1)" }}>
                    <Input
                        fullWidth
                        value={searchValue}
                        onChange={handleSearch}
                        placeholder="Search language"
                        startAdornment={
                            <InputAdornment position="start">
                                <SearchIcon sx={{ color: "rgba(255,255,255,0.7)" }} />
                            </InputAdornment>
                        }
                        sx={{
                            color: "white",
                            '&:before, &:after': { borderBottom: 'none' },
                            '&:hover:not(.Mui-disabled):before': { borderBottom: 'none' },
                        }}
                        disableUnderline={false}
                    />
                </Box>

                <Box sx={{ overflowY: 'auto', maxHeight: 250 }}>
                    {allFilterVoiceCountry?.map((ele) => (
                        <MenuItem
                            key={ele.id}
                            onClick={() => handleSelect(ele)}
                            sx={{
                                '&:hover': { backgroundColor: "#373E6D" },
                                '&.Mui-selected': { backgroundColor: "#373E6D" },
                                py: 1,
                                fontSize: 14,
                            }}
                        >
                            {ele.name}
                        </MenuItem>
                    ))}
                </Box>
            </Popover>
        </FormControl>
    );
};

const CommonDropdownForVoiceTone = ({voiceToneOption, setFilteredVoices, setfilterCriteria, filterCriteria}) => {
    const {
        genVideoVoice,
        setGenVideoVoice
    } = useWorkspace();
    
    
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchValue, setSearchValue] = useState("");
    const [selectedVoiceTone, setSelectedVoiceTone] = useState();
    const [ allFilterVoiceQuality, setAllFilterVoiceQuality] = useState([])
    const open = Boolean(anchorEl);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(()=>{
        setAllFilterVoiceQuality(voiceToneOption)
    },[voiceToneOption])

    useEffect(()=>{
        if(filterCriteria.quality === ""){
            setSelectedVoiceTone("")
        }
    },[filterCriteria])

    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchValue(value);
        const filtered = voiceToneOption.filter(q => 
             q.quality.toLowerCase().includes(value)
        );
        setAllFilterVoiceQuality(filtered);
    };

    const handleSelect = (qualityObj) => {
        setfilterCriteria(prevFilter => ({
            ...prevFilter, 
            quality: qualityObj?.quality 
          }))
        setSelectedVoiceTone(qualityObj)
        handleClose();
    };

    

    return (
        <FormControl>
            <Button
                onClick={handleOpen}
                sx={{
                    color: selectedVoiceTone?.name ? "rgba(255, 255, 255, 1)" : "rgba(202, 196, 208, 1)",
                    backgroundColor: "#2F355F",
                    borderRadius: "8px",
                    height: "42px",
                    minWidth: "120px",
                    textTransform: 'none', 
                    fontWeight:400,
                    fontFamily:"Inter",
                    '&:hover': {
                        backgroundColor: "#373E6D",
                    },
                }}
            >
                {selectedVoiceTone?.name || "Voice Tone"}
                <ArrowDropDownIcon sx={{ color:  selectedVoiceTone?.name ? "rgba(255, 255, 255, 1)" :  "rgba(202, 196, 208, 1)", marginLeft:"auto" }} />
            </Button>

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                sx={{
                    '& .MuiPaper-root': {
                        backgroundColor: "#2F355F",
                        color: "white",
                        borderRadius: "8px",
                        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
                        maxHeight: "300px",
                        width: "180px",
                        marginTop:"4px"
                    },
                }}
            >
                <Box sx={{ p: 1, borderBottom: "1px solid rgba(255,255,255,0.1)" }}>
                    <Input
                        fullWidth
                        value={searchValue}
                        onChange={handleSearch}
                        placeholder="Search language"
                        startAdornment={
                            <InputAdornment position="start">
                                <SearchIcon sx={{ color: "rgba(255,255,255,0.7)" }} />
                            </InputAdornment>
                        }
                        sx={{
                            color: "white",
                            '&:before, &:after': { borderBottom: 'none' },
                            '&:hover:not(.Mui-disabled):before': { borderBottom: 'none' },
                        }}
                        disableUnderline={false}
                    />
                </Box>

                <Box sx={{ overflowY: 'auto', maxHeight: 250 }}>
                    {allFilterVoiceQuality?.map((qualityObj) => (
                        <MenuItem
                            key={qualityObj?.id}
                            onClick={() => handleSelect(qualityObj)}
                            sx={{
                                '&:hover': { backgroundColor: "#373E6D" },
                                '&.Mui-selected': { backgroundColor: "#373E6D" },
                                py: 1,
                                fontSize: 14,
                            }}
                        >
                            {qualityObj?.name}
                        </MenuItem>
                    ))}
                </Box>
            </Popover>
        </FormControl>
    );
};


export { CommonDropdown, CommonDropdownForVoices, CommonDropdownForGender, CommonDropdownForCountry, CommonDropdownForVoiceTone}
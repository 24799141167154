
const AwFlag = (props) => (
  <svg
    width="1em"
    height="0.75em"
    xmlns="http://www.w3.org/2000/svg"
    id="flag-icons-aw"
    viewBox="0 0 640 480"
    {...props}
  >
    <defs>
      <clipPath id="aw-a">
        <path fillOpacity={0.7} d="M0 0h288v216H0z" />
      </clipPath>
    </defs>
    <g clipPath="url(#aw-a)" transform="scale(2.2222)">
      <path fill="#39c" d="M0 0v216h324V0H0z" />
      <path fill="#ff0" d="M0 144v12h324v-12H0zm0 24v12h324v-12H0z" />
    </g>
    <path fill="#9cc" d="m142.7 28 2.9 3-3-3zm-3 6 3 3-3-3m5.9 0 3 3-3-3z" />
    <path fill="#ccf" d="m139.7 37 3 2.9-3-3m5.9 0 3 3-3-3z" />
    <path fill="#6cc" d="m136.7 42.8 3 3-3-3z" />
    <path fill="#c66" d="m142.7 42.8 2.9 3-3-3z" />
    <path fill="#6cc" d="m148.6 42.8 2.9 3-3-3z" />
    <path fill="#ccf" d="m136.7 45.8 3 3-3-3zm11.9 0 2.9 3-3-3z" />
    <path fill="#fcc" d="m139.7 48.7 3 3-3-3m5.9 0 3 3-3-3z" />
    <path fill="#6cc" d="m133.8 51.7 3 3-3-3z" />
    <path
      fill="#c00"
      stroke="#fff"
      strokeWidth={3.7}
      d="m142.2 34-20.7 78.5L42.8 134l78.4 20.5 21 78.4 20.9-78.4 78.4-21-78.4-20.9-21-78.4z"
    />
    <path fill="#6cc" d="m151.5 51.7 3 3-3-3z" />
    <path fill="#9cf" d="m133.8 54.6 3 3-3-3m17.7 0 3 3-3-3z" />
    <path fill="#fcc" d="m136.7 57.6 3 3-3-3m11.9 0 2.9 3-3-3z" />
    <path fill="#69c" d="m130.8 60.5 3 3-3-3z" />
    <path fill="#c33" d="m137.7 62.5 1 2-1-2m11.8 0 1 2-1-2z" />
    <path fill="#69c" d="m154.5 60.5 3 3-3-3z" />
    <path fill="#9cf" d="m130.8 63.5 3 3-3-3m23.7 0 3 3-3-3z" />
    <path fill="#fcc" d="m133.8 66.4 3 3-3-3m17.7 0 3 3-3-3z" />
    <path fill="#69c" d="m127.9 69.4 3 3-3-3zm29.5 0 3 3-3-3z" />
    <path fill="#9cc" d="m127.9 72.3 3 3-3-3m29.5 0 3 3-3-3z" />
    <path fill="#cff" d="m127.9 75.3 3 3-3-3m29.5 0 3 3-3-3z" />
    <path fill="#69c" d="m125 78.3 2.9 2.9-3-3z" />
    <path fill="#fcc" d="m130.8 78.3 3 2.9-3-3m23.7 0 3 3-3-3z" />
    <path fill="#69c" d="m160.4 78.3 3 2.9-3-3z" />
    <path fill="#9cc" d="m125 81.2 2.9 3-3-3z" />
    <path fill="#c33" d="m131.8 83.2 1 2-1-2m23.6 0 1 2-1-2z" />
    <path fill="#9cc" d="m160.4 81.2 3 3-3-3z" />
    <path fill="#cff" d="m125 84.2 2.9 3-3-3m35.5 0 3 3-3-3z" />
    <path fill="#fcc" d="m127.9 87.1 3 3-3-3m29.5 0 3 3-3-3z" />
    <path fill="#9cc" d="m122 90 3 3-3-3z" />
    <path fill="#c33" d="m128.9 92 1 2-1-2m29.5 0 1 2-1-2z" />
    <path fill="#9cc" d="m163.3 90 3 3-3-3z" />
    <path fill="#ccf" d="m122 93 3 3-3-3m41.3 0 3 3-3-3z" />
    <path fill="#fcc" d="m125 96 2.9 3-3-3m35.5 0 3 3-3-3z" />
    <path fill="#9cc" d="m119 99 3 2.9-3-3z" />
    <path fill="#c33" d="m126 100.9.9 2-1-2m35.4 0 1 2-1-2z" />
    <path fill="#9cc" d="m166.3 99 3 2.9-3-3z" />
    <path fill="#ccf" d="m119 101.9 3 3-3-3m47.3 0 3 3-3-3z" />
    <path fill="#fcc" d="m122 104.8 3 3-3-3m41.3 0 3 3-3-3z" />
    <path fill="#9cc" d="m116 107.8 3 3-3-3z" />
    <path fill="#c33" d="m122 107.8 3 3-3-3m41.3 0 3 3-3-3z" />
    <path fill="#9cc" d="m169.2 107.8 3 3-3-3m-62 3 3 2.9-3-3z" />
    <path fill="#ccf" d="m110.2 110.7 3 3-3-3m65 0 2.9 3-3-3z" />
    <path fill="#9cc" d="m178 110.7 3 3-3-3m-79.6 3 3 3-3-3z" />
    <path fill="#ccf" d="m101.3 113.7 3 3-3-3z" />
    <path fill="#fcc" d="m113.1 113.7 3 3-3-3z" />
    <path fill="#c33" d="m116 113.7 3 3-3-3m53.2 0 3 3-3-3z" />
    <path fill="#fcc" d="m172.2 113.7 3 3-3-3z" />
    <path fill="#ccf" d="m184 113.7 3 3-3-3z" />
    <path fill="#9cc" d="m187 113.7 2.9 3-3-3z" />
    <path fill="#69c" d="m86.6 116.6 3 3-3-3z" />
    <path fill="#9cc" d="m89.5 116.6 3 3-3-3z" />
    <path fill="#cff" d="m92.5 116.6 3 3-3-3z" />
    <path fill="#fcc" d="m104.3 116.6 3 3-3-3z" />
    <path fill="#c33" d="m109.2 117.6 2 1-2-1m67.9 0 2 1-2-1z" />
    <path fill="#fcc" d="m181 116.6 3 3-3-3z" />
    <path fill="#cff" d="m192.8 116.6 3 3-3-3z" />
    <path fill="#9cc" d="m195.8 116.6 3 3-3-3z" />
    <path fill="#69c" d="m198.7 116.6 3 3-3-3m-121 3 3 3-3-3z" />
    <path fill="#9cc" d="m80.7 119.6 3 3-3-3z" />
    <path fill="#cff" d="m83.6 119.6 3 3-3-3z" />
    <path fill="#fcc" d="m95.4 119.6 3 3-3-3z" />
    <path fill="#c33" d="m100.3 120.6 2 1-2-1m85.6 0 2 1-2-1z" />
    <path fill="#fcc" d="m189.9 119.6 3 3-3-3z" />
    <path fill="#cff" d="m201.7 119.6 3 3-3-3z" />
    <path fill="#9cc" d="m204.6 119.6 3 3-3-3z" />
    <path fill="#69c" d="m207.6 119.6 3 3-3-3m-138.8 3 3 2.9-3-3z" />
    <path fill="#9cf" d="m71.8 122.5 3 3-3-3z" />
    <path fill="#fcc" d="m86.6 122.5 3 3-3-3z" />
    <path fill="#c33" d="m91.5 123.5 2 1-2-1m103.3 0 2 1-2-1z" />
    <path fill="#fcc" d="m198.7 122.5 3 3-3-3z" />
    <path fill="#9cf" d="m213.5 122.5 3 3-3-3z" />
    <path fill="#69c" d="m216.4 122.5 3 3-3-3z" />
    <path fill="#6cc" d="m60 125.5 3 3-3-3z" />
    <path fill="#9cf" d="m63 125.5 2.9 3-3-3z" />
    <path fill="#fcc" d="m74.8 125.5 2.9 3-3-3zm135.8 0 2.9 3-3-3z" />
    <path fill="#9cf" d="m222.3 125.5 3 3-3-3z" />
    <path fill="#6cc" d="m225.3 125.5 3 3-3-3m-174.2 3 3 2.9-3-3z" />
    <path fill="#ccf" d="m54 128.4 3 3-3-3z" />
    <path fill="#fcc" d="m65.9 128.4 3 3-3-3z" />
    <path fill="#c33" d="m70.8 129.4 2 1-2-1m144.7 0 2 1-2-1z" />
    <path fill="#fcc" d="m219.4 128.4 3 3-3-3z" />
    <path fill="#ccf" d="m231.2 128.4 3 3-3-3z" />
    <path fill="#6cc" d="m234.2 128.4 3 3-3-3z" />
    <path fill="#9cc" d="m42.3 131.4 3 3-3-3z" />
    <path fill="#ccf" d="m45.2 131.4 3 3-3-3z" />
    <path fill="#fcc" d="m57 131.4 3 3-3-3zm171.3 0 3 3-3-3z" />
    <path fill="#ccf" d="m240 131.4 3 3-3-3z" />
    <path fill="#9cc" d="m243 131.4 3 3-3-3m-206.6 3 3 2.9-3-3z" />
    <path fill="#c66" d="m51.1 134.3 3 3-3-3zm183 0 3 3-3-3z" />
    <path fill="#9cc" d="m249 134.3 2.9 3-3-3m-206.6 3 3 3-3-3z" />
    <path fill="#ccf" d="m45.2 137.3 3 3-3-3z" />
    <path fill="#fcc" d="m57 137.3 3 3-3-3m171.3 0 3 3-3-3z" />
    <path fill="#ccf" d="m240 137.3 3 3-3-3z" />
    <path fill="#9cc" d="m243 137.3 3 3-3-3z" />
    <path fill="#6cc" d="m51.1 140.3 3 2.9-3-3z" />
    <path fill="#ccf" d="m54 140.3 3 2.9-3-3z" />
    <path fill="#fcc" d="m65.9 140.3 3 2.9-3-3z" />
    <path fill="#c33" d="m70.8 141.2 2 1-2-1m144.7 0 2 1-2-1z" />
    <path fill="#fcc" d="m219.4 140.3 3 2.9-3-3z" />
    <path fill="#ccf" d="m231.2 140.3 3 2.9-3-3z" />
    <path fill="#6cc" d="m234.2 140.3 3 2.9-3-3m-174.2 3 3 3-3-3z" />
    <path fill="#9cf" d="m63 143.2 2.9 3-3-3z" />
    <path fill="#fcc" d="m74.8 143.2 2.9 3-3-3zm135.8 0 2.9 3-3-3z" />
    <path fill="#9cf" d="m222.3 143.2 3 3-3-3z" />
    <path fill="#6cc" d="m225.3 143.2 3 3-3-3z" />
    <path fill="#69c" d="m68.8 146.2 3 2.9-3-3z" />
    <path fill="#9cf" d="m71.8 146.2 3 2.9-3-3z" />
    <path fill="#fcc" d="m86.6 146.2 3 2.9-3-3z" />
    <path fill="#c33" d="m91.5 147.1 2 1-2-1m103.3 0 2 1-2-1z" />
    <path fill="#fcc" d="m198.7 146.2 3 2.9-3-3z" />
    <path fill="#9cf" d="m213.5 146.2 3 2.9-3-3z" />
    <path fill="#69c" d="m216.4 146.2 3 2.9-3-3m-138.7 3 3 3-3-3z" />
    <path fill="#9cc" d="m80.7 149.1 3 3-3-3z" />
    <path fill="#cff" d="m83.6 149.1 3 3-3-3z" />
    <path fill="#fcc" d="m95.4 149.1 3 3-3-3z" />
    <path fill="#c33" d="m100.3 150 2 1-2-1m85.6 0 2 1-2-1z" />
    <path fill="#fcc" d="m189.9 149.1 3 3-3-3z" />
    <path fill="#cff" d="m201.7 149.1 3 3-3-3z" />
    <path fill="#9cc" d="m204.6 149.1 3 3-3-3z" />
    <path fill="#69c" d="m207.6 149.1 3 3-3-3m-121 3 2.9 2.9-3-3z" />
    <path fill="#9cc" d="m89.5 152 3 3-3-3z" />
    <path fill="#cff" d="m92.5 152 3 3-3-3z" />
    <path fill="#fcc" d="m104.3 152 3 3-3-3z" />
    <path fill="#c33" d="m109.2 153 2 1-2-1m67.9 0 2 1-2-1z" />
    <path fill="#fcc" d="m181 152 3 3-3-3z" />
    <path fill="#cff" d="m192.8 152 3 3-3-3z" />
    <path fill="#9cc" d="m195.8 152 3 3-3-3z" />
    <path fill="#69c" d="m198.7 152 3 3-3-3z" />
    <path fill="#9cc" d="m98.4 155 3 3-3-3z" />
    <path fill="#ccf" d="m101.3 155 3 3-3-3z" />
    <path fill="#fcc" d="m113.1 155 3 3-3-3z" />
    <path fill="#c33" d="m116 155 3 3-3-3m53.2 0 3 3-3-3z" />
    <path fill="#fcc" d="m172.2 155 3 3-3-3z" />
    <path fill="#ccf" d="m184 155 3 3-3-3z" />
    <path fill="#9cc" d="m187 155 2.9 3-3-3m-79.7 3 3 3-3-3z" />
    <path fill="#ccf" d="m110.2 158 3 3-3-3m65 0 2.9 3-3-3z" />
    <path fill="#9cc" d="m178 158 3 3-3-3m-62 3 3 2.9-3-3z" />
    <path fill="#c33" d="m122 161 3 2.9-3-3m41.3 0 3 3-3-3z" />
    <path fill="#9cc" d="m169.2 161 3 2.9-3-3z" />
    <path fill="#fcc" d="m122 163.9 3 3-3-3m41.3 0 3 3-3-3z" />
    <path fill="#ccf" d="m119 166.8 3 3-3-3z" />
    <path fill="#c33" d="m126 168.8.9 2-1-2m35.4 0 1 2-1-2z" />
    <path fill="#ccf" d="m166.3 166.8 3 3-3-3z" />
    <path fill="#9cc" d="m119 169.8 3 3-3-3m47.3 0 3 3-3-3z" />
    <path fill="#fcc" d="m125 172.7 2.9 3-3-3m35.5 0 3 3-3-3z" />
    <path fill="#ccf" d="m122 175.7 3 3-3-3z" />
    <path fill="#c33" d="m128.9 177.6 1 2-1-2m29.5 0 1 2-1-2z" />
    <path fill="#ccf" d="m163.3 175.7 3 3-3-3z" />
    <path fill="#9cc" d="m122 178.6 3 3-3-3m41.3 0 3 3-3-3z" />
    <path fill="#fcc" d="m127.9 181.6 3 3-3-3m29.5 0 3 3-3-3z" />
    <path fill="#cff" d="m125 184.5 2.9 3-3-3z" />
    <path fill="#c33" d="m131.8 186.5 1 2-1-2m23.6 0 1 2-1-2z" />
    <path fill="#cff" d="m160.4 184.5 3 3-3-3z" />
    <path fill="#9cc" d="m125 187.5 2.9 3-3-3m35.5 0 3 3-3-3z" />
    <path fill="#69c" d="m125 190.4 2.9 3-3-3z" />
    <path fill="#fcc" d="m130.8 190.4 3 3-3-3m23.7 0 3 3-3-3z" />
    <path fill="#69c" d="m160.4 190.4 3 3-3-3z" />
    <path fill="#cff" d="m127.9 193.4 3 3-3-3zm29.5 0 3 3-3-3z" />
    <path fill="#9cc" d="m127.9 196.3 3 3-3-3m29.5 0 3 3-3-3z" />
    <path fill="#69c" d="m127.9 199.3 3 3-3-3m29.5 0 3 3-3-3z" />
    <path fill="#fcc" d="m133.8 202.2 3 3-3-3m17.7 0 3 3-3-3z" />
    <path fill="#9cf" d="m130.8 205.2 3 3-3-3z" />
    <path fill="#c33" d="m137.7 207.2 1 2-1-2m11.8 0 1 2-1-2z" />
    <path fill="#9cf" d="m154.5 205.2 3 3-3-3z" />
    <path fill="#69c" d="m130.8 208.2 3 2.9-3-3m23.7 0 3 3-3-3z" />
    <path fill="#fcc" d="m136.7 211.1 3 3-3-3m11.9 0 2.9 3-3-3z" />
    <path fill="#9cf" d="m133.8 214 3 3-3-3zm17.7 0 3 3-3-3z" />
    <path fill="#6cc" d="m133.8 217 3 3-3-3m17.7 0 3 3-3-3z" />
    <path fill="#fcc" d="m139.7 220 3 3-3-3m5.9 0 3 3-3-3z" />
    <path fill="#ccf" d="m136.7 222.9 3 3-3-3m11.9 0 2.9 3-3-3z" />
    <path fill="#6cc" d="m136.7 225.9 3 3-3-3z" />
    <path fill="#c66" d="m142.7 225.9 2.9 3-3-3z" />
    <path fill="#6cc" d="m148.6 225.9 2.9 3-3-3z" />
    <path fill="#ccf" d="m139.7 231.8 3 3-3-3m5.9 0 3 3-3-3z" />
    <path fill="#9cc" d="m139.7 234.7 3 3-3-3m5.9 0 3 3-3-3m-3 6 3 2.9-3-3z" />
  </svg>
)

export default AwFlag

import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Button,
  FormControl,
  IconButton,
  Tooltip,
} from "@mui/material";
// import { fetchLanguages } from "./VideoGenAPISettings";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import FaceIcon from "@mui/icons-material/Face";
import Face3Icon from "@mui/icons-material/Face3";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import sourceUrl from "../../../../api/Source.api";
import { avatarVoiceLanguages } from "../../../../constants/avatarVoiceLanguages";
import {
  DropdownForDIDLanguage,
  DropdownForGender,
  DropdownForVoices,
  DropdownForVoiceTone,
} from "./DropDownButtons";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Pagination from "@mui/material/Pagination";
import maleIcon from "../../../../assets/Icon/male.png";
import femaleIcon from "../../../../assets/Icon/female.png";

const LanguageArrayForFilter = [{ id: 0, name: "All", language: "All" }];

const GenderArrayForFilter = [
  { id: 0, name: "All", gender: "All" },
  { id: 1, name: "Male", gender: "Male" },
  { id: 2, name: "Female", gender: "Female" },
];

const VoiceToneArrayForFilter = [
  { id: 0, name: "All", description: "All" },
  { id: 2, name: "basic", description: "basic" },
];

const AccentArrayForFilter = [{ id: 0, value: "All", accent: "All" }];

const LanguageAndVoices = () => {
  const {
    audioSettingVoices,
    setVideoVoice,
    languages,
    setLanguages,
    voices,
    setVoices,
    voiceloading,
    setVoiceLoading,
  } = useWorkspace();

  const [filteredLanguages, setFilteredLanguages] =
    useState(avatarVoiceLanguages);
  const [filteredVoices, setFilteredVoices] = useState(voices);
  const [languageOption, setLanguageOption] = useState(LanguageArrayForFilter);
  const [genderOption, setGenderOption] = useState(GenderArrayForFilter);
  const [accentOption, setAccentOption] = useState(AccentArrayForFilter);
  const [playingVoice, setPlayingVoice] = useState(null);
  // const [audioElement, setAudioElement] = useState(null);
  const audioElement = useRef(null);
  const [currentPageForVoices, setCurrentPageForVoices] = useState(1);
  const [selectedDidVideoVoice, setselectedDidVideoVoice] = useState();
  const [filterCriteria, setfilterCriteria] = useState({
    gender: "",
    language: "",
    accent: "",
  });
  const itemsPerPage = 20;
  const indexOfLastItem = currentPageForVoices * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredVoices?.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredVoices.length / 20);
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (
      voiceloading == null &&
      (languages?.length === 0 || voices?.length === 0)
    ) {
      setVoiceLoading(true);
      fetchVoices();
    }
  }, []);

  const fetchVoices = async () => {
    try {
      const response = await fetch(`${sourceUrl}/video/avatarVoice`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch voices");
      }
      const json = await response.json();
      const voiceData = json.data.data;
      setLanguages(avatarVoiceLanguages);
      setVoices(voiceData);
    } catch (error) {
      console.error("Error fetching voices:", error.message);
    }
    setVoiceLoading(false);
  };

  console.log("filterCriteria", filterCriteria);
  // console.log("currentItems", currentItems);

  useEffect(() => {
    function updateFilterArray(apiData, key, filterArray) {
      const existingValues = new Set(filterArray?.map((item) => item[key]));
      let currentId =
        filterArray.length > 0
          ? Math.max(...filterArray?.map((item) => item.id)) + 1
          : 1;

      const newValues = [];

      apiData?.forEach((item) => {
        // ✅ Check if key exists at the root level
        if (item[key] && !existingValues.has(item[key])) {
          existingValues.add(item[key]);
          newValues.push({
            id: currentId++,
            name: item[key],
            [key]: item[key],
          });
        }

        // ✅ Check if key exists inside `languages`
        if (item.languages) {
          item.languages
            .filter((lang) => lang[key]) // Ensure key exists
            .forEach((lang) => {
              const value = lang[key];
              if (value && !existingValues.has(value)) {
                existingValues.add(value);
                newValues.push({
                  id: currentId++,
                  name: value,
                  [key]: value,
                });
              }
            });
        }
      });

      return [...filterArray, ...newValues];
    }

    // Extract unique languages
    const updatedArray = updateFilterArray(
      voices,
      "language",
      LanguageArrayForFilter
    );
    setLanguageOption(updatedArray);

    const updatedAccentArray = updateFilterArray(
      voices,
      "accent",
      AccentArrayForFilter
    );
    setAccentOption(updatedAccentArray);
  }, [voices]);

  useEffect(() => {
    const filteredData = voices?.filter((item) => {
      const matchesLanguage =
        !filterCriteria.language ||
        item.languages?.some(
          (lang) => lang.language === filterCriteria.language
        ); // ✅ Check inside languages[]

      const matchesGender =
        !filterCriteria.gender ||
        (item.gender &&
          item.gender.toLowerCase() === filterCriteria.gender.toLowerCase());

      const matchesAccent =
        !filterCriteria.accent ||
        item.accent === filterCriteria.accent || // ✅ Check at root level
        item.languages?.some((lang) => lang.accent === filterCriteria.accent); // ✅ Check inside languages[]

      return matchesLanguage && matchesGender && matchesAccent;
    });

    const excludedData = voices?.filter((item) => !filteredData.includes(item));
    const updatedVoices = filteredData?.concat(excludedData);

    setFilteredVoices(updatedVoices);
  }, [filterCriteria, voiceloading]);

  const handleVoiceSelectClick = (voiceOption) => {
    // console.log("voiceOption", voiceOption)
    setselectedDidVideoVoice(voiceOption);
    setVideoVoice(voiceOption?.id);
  };

  const handleVoiceSelect = (voice) => {
    if (playingVoice === voice?.voice_id) {
      if (audioElement.current) {
        audioElement.current.pause();
        audioElement.current.currentTime = 0; // Reset audio to start
        audioElement.current = null;
      }
      setPlayingVoice(null);
    } else {
      if (audioElement.current) {
        audioElement.current.pause();
        audioElement.current.currentTime = 0; // Reset audio to start
        audioElement.current = null;
      }
      const newAudioElement = new Audio(voice?.previewUrl);

      // When audio ends, reset the playingVoice state
      newAudioElement.onended = () => {
        setPlayingVoice(null);
      };

      newAudioElement
        .play()
        .catch((error) => console.error("Playback error:", error));

      setPlayingVoice(voice?.voice_id);
      audioElement.current = newAudioElement;
    }
  };

  useEffect(() => {
    return () => {
      if (audioElement.current) {
        audioElement.current.pause();
        audioElement.current.currentTime = 0;
        audioElement.current.src = "";
        audioElement.current = null;
      }
      setPlayingVoice(null);
    };
  }, []);

  const handleClearButtonClick = () =>{
    setfilterCriteria({
      gender: "",
      language: "",
      accent: "",
    })
  }

  const handlePageChange = (event, value) => {
    setCurrentPageForVoices(value); // Update the current page when a page is clicked
  };

      // Custom theme for Material UI Pagination
      const paginationTheme = createTheme({
        typography: {
          fontFamily: "Inter",
        },
        components: {
          MuiPagination: {
            styleOverrides: {
              root: {
                "& .MuiPaginationItem-root": {
                  color: "#ffffff",
                  borderRadius: "20px",
                  "&:hover": {
                    backgroundColor: "rgba(179, 168, 183, 0.26)",
                  },
                  fontSize: "14px",
                },
                "& .MuiPaginationItem-page.Mui-selected": {
                  backgroundColor: "#CAC4D0",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "#CAC4D0",
                  },
                },
                "& .MuiPaginationItem-ellipsis": {
                  color: "#ffffff",
                },
                "& .MuiPaginationItem-previousNext": {
                  color: "#ffffff",
                  borderRadius: "20px",
                },
              },
            },
          },
        },
      });

  return (
    <Box sx={{ padding: "20px 20px 0px 0px" }}>
      {voiceloading ? (
        <Box>Loading...</Box>
      ) : (
        <Box>
          <Typography
            variant="h6"
            gutterBottom
            style={{
              fontSize: "16px",
              fontWeight: "500",
              marginBottom: "8px",
              fontFamily: "Inter",
              fontStyle: "normal",
              lineHeight: "20px",
              color: "#ffffff",
            }}
          >
            Video language
          </Typography>
          <Typography
            //  gutterBottom
            style={{
              fontSize: "14px",
              fontWeight: "400",
              marginBottom: "20px",
              fontFamily: "Inter",
              fontStyle: "normal",
              lineHeight: "20px",
              color: "rgba(255, 255, 255, 0.80)",
            }}
          >
            Select the language for your video’s script
          </Typography>

          <Box sx={{ marginBottom: "40px" }}>
            <DropdownForDIDLanguage
              filteredLanguages={filteredLanguages}
              setFilteredLanguages={setFilteredLanguages}
            />
          </Box>
          <Typography
            style={{
              fontSize: "16px",
              fontWeight: 500,
              marginBottom: "8px",
              fontFamily: "Inter",
              fontStyle: "normal",
              lineHeight: "20px",
              color: "rgba(255, 255, 255, 1)",
            }}
          >
            AI Narrator
          </Typography>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "400",
              marginBottom: "20px",
              fontFamily: "Inter",
              fontStyle: "normal",
              lineHeight: "20px",
              color: "rgba(255, 255, 255, 0.80)",
            }}
          >
            Choose from the most advanced AI voices in various languages and
            accents
          </Typography>
          <FormControl
            variant="standard"
            sx={{
              display: "flex",
              width: "auto",
              flexDirection: "row",
              alignItems:"center",
              columnGap: "12px",
              marginBottom: "36px",
              "& .MuiInput-underline:before": {
                borderBottom: "none",
              },
              "& .MuiInput-underline:after": {
                borderBottom: "none",
              },
              "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                borderBottom: "none",
              },
            }}
          >
            <Box>
              <DropdownForVoices
                languageOption={languageOption}
                setFilteredVoices={setLanguageOption}
                setfilterCriteria={setfilterCriteria}
                filterCriteria={filterCriteria}
              />
            </Box>
            <Box>
              <DropdownForGender
                genderOption={genderOption}
                setFilteredVoices={setFilteredVoices}
                setfilterCriteria={setfilterCriteria}
                filterCriteria={filterCriteria}
              />
            </Box>
            <Box>
              <DropdownForVoiceTone
                voiceToneOption={accentOption}
                setFilteredVoices={setFilteredVoices}
                setfilterCriteria={setfilterCriteria}
                filterCriteria={filterCriteria}
              />
            </Box>
            <Box onClick={handleClearButtonClick}>
              <Typography
                sx={{
                  fontFamily:"Inter",
                  fontStyle:"normal",
                  fontSize:"14px",
                  fontWeight:500,
                  lineHeight:"20px",
                  color:"#FFFFFF",
                  cursor:"pointer",
                }}
              >
                {Object.values(filterCriteria).some((value) => value !== "") &&
                  "Clear filters"}
              </Typography>
            </Box>
          </FormControl>
          <Box
            sx={{
              width: "100%",
              maxHeight: "160px",
              overflowY: "scroll",
              display: "flex",
              flexDirection: "column",
              rowGap: "16px",
              alignItems: "center",
            }}
          >
            {voiceloading ? (
              <Box>Loading</Box>
            ) : (
              currentItems?.map((voiceObj) => {
                return (
                  <Box
                    onClick={() => handleVoiceSelectClick(voiceObj)}
                    sx={{
                      width: "100%",
                      border:
                        selectedDidVideoVoice?.name === voiceObj?.name
                          ? "1px solid rgba(255, 255, 255, 0.30)"
                          : "1px solid rgba(255, 255, 255, 0.10)",
                      padding: "12px 16px",
                      borderRadius: "16px",
                      display: "flex",
                      justifyContent: "space-between",
                      cursor: "pointer",
                      backgroundColor:
                      selectedDidVideoVoice?.name === voiceObj?.name
                          ? "#282D51"
                          : "transparent",
                      "&:hover": {
                        backgroundColor: "#282D51",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Tooltip
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: "#E6E0E9",
                              color: "#17182C",
                              fontFamily: "Inter",
                              fontSize: "12px",
                              fontWeight: 500,
                              padding: "4px 8px 4px 8px",
                              borderRadius: "4px",
                              textAlign: "center",
                            },
                          },
                        }}
                        title={
                          playingVoice === voiceObj.voice_id
                            ? `Stop voice`
                            : `Preview voice`
                        }
                      >
                        <IconButton
                          disabled={!voiceObj?.previewUrl}
                          onClick={(e) => {
                            handleVoiceSelect(voiceObj);
                            e.stopPropagation();
                          }}
                          sx={{
                            color:
                              playingVoice === voiceObj?.voice_id
                                ? "rgba(23, 26, 45, 1)"
                                : "rgba(23, 26, 45, 1)",
                            backgroundColor:
                              playingVoice === voiceObj?.voice_id
                                ? "white"
                                : "white",
                            transition:
                              "background-color 0.3s ease, transform 0.2s ease",
                            "&:hover": {
                              backgroundColor: "white",
                            },
                            "&:disabled": {
                              color: "rgba(230, 224, 233, 1)",
                              bgcolor: "rgba(230, 224, 233, 0.12)",
                            },
                            width: "30px",
                            height: "30px",
                            padding: "12px",
                            borderRadius: "50%",
                          }}
                        >
                          {playingVoice === voiceObj?.voice_id ? (
                            <PauseIcon sx={{}} />
                          ) : (
                            <PlayArrowIcon sx={{}} />
                          )}
                        </IconButton>
                      </Tooltip>
                      <Box
                        sx={{
                          border: "1px solid rgba(255, 255, 255, 0.10)",
                          height: "30px",
                          margin: "0px 12px",
                        }}
                      ></Box>
                      <IconButton sx={{ padding: "0px" }}>
                        {voiceObj.gender === "Male" ? (
                          <Box
                            component="img"
                            src={maleIcon}
                            alt="Male"
                            sx={{ width: 20, height: 20 }}
                          />
                        ) : (
                          <Box
                            component="img"
                            src={femaleIcon}
                            alt="Female"
                            sx={{ width: 20, height: 20 }}
                          />
                        )}
                      </IconButton>
                      <Typography
                        sx={{
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight:
                            selectedDidVideoVoice?.name === voiceObj?.name
                              ? 400
                              : 500,
                          lineHeight: "20px",
                          letterSpacing: "0.1px",
                          fontSize: "14px",
                          color: "#fff",
                          marginLeft: "8px",
                        }}
                      >
                        {voiceObj?.name}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        columnGap: "12px",
                      }}
                    >
                      {voiceObj?.accent ? (
                        <Tooltip
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: "#E6E0E9",
                                color: "#17182C",
                                fontFamily: "Inter",
                                fontSize: "12px",
                                fontWeight: 500,
                                padding: "4px 8px 4px 8px",
                                borderRadius: "4px",
                                textAlign: "center",
                              },
                            },
                          }}
                          title={`Accent: ${voiceObj?.accent}`}
                        >
                          <Button
                            sx={{
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontSize: "14px",
                              fontWeight: 400,
                              lineHeight: "140%",
                              letterSpacing: "0.1px",
                              color: "rgba(255, 255, 255, 1)",
                              textWrap: "nowrap",
                              backgroundColor: "#2F355F",
                              width: "auto",
                              padding: "8px",
                              textTransform: "none",
                              whiteSpace: "nowrap",
                              borderRadius: "8px",
                            }}
                          >
                            {voiceObj?.accent}
                          </Button>
                        </Tooltip>
                      ) : null}
                      {voiceObj?.languages[0]?.language ? (
                        <Tooltip
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: "#E6E0E9",
                                color: "#17182C",
                                fontFamily: "Inter",
                                fontSize: "12px",
                                fontWeight: 500,
                                padding: "4px 8px 4px 8px",
                                borderRadius: "4px",
                                textAlign: "center",
                              },
                            },
                          }}
                          title={`Language: ${voiceObj?.languages[0]?.language}`}
                        >
                          <Button
                            sx={{
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontSize: "14px",
                              fontWeight: 400,
                              lineHeight: "140%",
                              letterSpacing: "0.1px",
                              color: "rgba(255, 255, 255, 1)",
                              textWrap: "nowrap",
                              backgroundColor: "#2F355F",
                              width: "auto",
                              padding: "8px",
                              textTransform: "none",
                              whiteSpace: "nowrap",
                              borderRadius: "8px",
                            }}
                          >
                            {voiceObj?.languages[0]?.language}
                          </Button>
                        </Tooltip>
                      ) : null}
                    </Box>
                  </Box>
                );
              })
            )}
            <div className="d-flex justify-content-center mt-3">
              <ThemeProvider theme={paginationTheme}>
                <Pagination
                  count={totalPages}
                  page={currentPageForVoices}
                  siblingCount={1}
                  boundaryCount={1}
                  onChange={handlePageChange}
                  shape="rounded"
                  size="large"
                />
              </ThemeProvider>
            </div>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default LanguageAndVoices;

import React from "react";
import {
  Box,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import successIcon from "../../assets/greencheckIcon.png";
import { useNavigate } from "react-router-dom";

const VerifiedEmail = () => {
  const isBelow991 = useMediaQuery("(max-width:991px)");
    const navigate = useNavigate();

  const handleCancelClick = () => {
    navigate("/")
  };
  const handleCloseResendEmailModal = () => {
    navigate("/")
  };

  const handleOpenGmail = () => {
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    if (isMobile) {
    window.location.href = "googlegmail://inbox";
    setTimeout(() => {
      window.location.href = "https://mail.google.com/mail/u/0/#inbox";
    }, 500);
    } else {
      window.open("https://mail.google.com/", "_blank");
    }
  }

  return (
    <>
      <div
        style={{
          zIndex: 70,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: isBelow991 ? "" : "100vw",
          height: isBelow991 ? "" : "100vh",
          left: "0%",
          top: "0%",
        }}
      >
        <div
          onClick={handleCloseResendEmailModal}
          style={{
            width: "100%",
            height: isBelow991 ? "" : "100%",
            position: isBelow991 ? "static" : "absolute",
            backgroundColor: "#101220",
            //   left: "50%",
            //   top: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            onClick={(event) => event.stopPropagation()}
            sx={{
              width: isBelow991 ? "100%" : "480px",
              // maxHeight: "488px",
              backgroundColor: isBelow991 ? "#171A2D" : "#1F233E",
              borderRadius: "16px",
              border: isBelow991 ? null : "1px solid rgba(255, 255, 255, 0.10)",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "64px",
                display: "flex",
                justifyContent: "end",
                padding: isBelow991
                  ? "36px 20px 0px 20px"
                  : "20px 20px 0px 20px",
                alignItems: "center",
              }}
            >
              <Box
                onClick={handleCancelClick}
                sx={{
                  width: "28px",
                  height: "28px",
                }}
              >
                <IconButton
                  aria-label="back"
                  sx={{
                    color: "#CDCDCD",
                    padding: "0px 0px",
                  }}
                >
                  <CloseIcon
                    sx={{
                      width: "28px",
                      height: "28px",
                      padding: "0px 0px",
                    }}
                  />
                </IconButton>
              </Box>
            </Box>

            <Box
              sx={{
                padding: isBelow991
                  ? "60px 24px 40px 24px"
                  : "0px 20px 20px 20px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={successIcon}
                    alt="mailIcon"
                    style={{ width: "40px", height: "40px" }}
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  marginTop: "20px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "20px",
                    fontWeight: 600,
                    lineHeight: "20px",
                    alignSelf: "stretch",
                    textAlign: "center",
                    margin: "0px 0px",
                  }}
                >
                  {"Email verified"}
                </Typography>
              </Box>

              <Box
                sx={{
                  marginTop: "20px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "20px",
                    textAlign: "center",
                    maxWidth: "300px",
                  }}
                >
                  {`You will now receive files generated by ModalX on your email address`}
                </Typography>
              </Box>

              <Box
                sx={{
                  marginTop: "40px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "#F6BE6B",
                    color: "#17182C",
                    padding: "10px 24px 10px 24px",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "20px",
                    letterSpacing: "0.1px",
                    textAlign: "center",
                    width: "300px",
                    margin: "auto",
                    textTransform: "none",
                    borderRadius:"8px",
                    "&:hover": {
                      bgcolor: "#ffd9aa",
                      border: "none",
                    },
                  }}
                  onClick={handleCancelClick}
                >
                  {"Done"}
                </Button>
              </Box>

              <Box
                sx={{
                  marginTop: "24px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "#FFFFFF",
                    color: "#17182C",
                    padding: "10px 24px 10px 24px",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "20px",
                    letterSpacing: "0.1px",
                    textAlign: "center",
                    width: "300px",
                    margin: "auto",
                    textTransform: "none",
                    borderRadius:"8px",
                    "&:hover": {
                      bgcolor: "#CDCFDF",
                      border: "none",
                    },
                  }}
                  onClick={handleOpenGmail}
                >
                  <Box sx={{ marginRight: "8px" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="14"
                      viewBox="0 0 19 14"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_3329_28710)">
                        <path
                          d="M4.59092 13.93V6.75515L2.43409 4.71949L0.5 3.58984V12.6638C0.5 13.3644 1.0502 13.93 1.7273 13.93H4.59092Z"
                          fill="#4285F4"
                        />
                        <path
                          d="M14.4092 13.9299H17.2728C17.9519 13.9299 18.5001 13.3622 18.5001 12.6637V3.58984L16.3094 4.88372L14.4092 6.75508V13.9299Z"
                          fill="#34A853"
                        />
                        <path
                          d="M4.59085 6.75608L4.29736 3.95267L4.59085 1.26953L9.49993 5.06796L14.409 1.26953L14.7373 3.80781L14.409 6.75608L9.49993 10.5545L4.59085 6.75608Z"
                          fill="#EA4335"
                        />
                        <path
                          d="M14.4092 1.2697V6.75625L18.5001 3.59094V1.90274C18.5001 0.336993 16.7676 -0.555598 15.5547 0.383418L14.4092 1.2697Z"
                          fill="#FBBC04"
                        />
                        <path
                          d="M0.5 3.59097L2.38149 5.04683L4.59092 6.75628V1.26973L3.44539 0.383451C2.23039 -0.555637 0.5 0.337026 0.5 1.90271V3.59097Z"
                          fill="#C5221F"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3329_28710">
                          <rect
                            width="18"
                            height="14"
                            fill="white"
                            transform="translate(0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </Box>
                  Go to Gmail
                </Button>
              </Box>
            </Box>
          </Box>
          {/* <CustomizedSnackbars
              open={open}
              setOpen={setOpen}
              notifMsg={notifMsg}
              notifVariant={notifVariant}
              setShowNotif={setShowNotif}
            /> */}
        </div>
      </div>
    </>
  );
};

export default VerifiedEmail;

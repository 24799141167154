import React, { useEffect, useState } from "react";
import PageLayout from "../components/navFoot/PageLayout";
import LandingPageContents from "../components/LandingPageContents";
import { darkTheme_bg_color } from "../constants/colors";
import { Box, IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import SmsFailedSharpIcon from "@mui/icons-material/SmsFailedSharp";

const EmailVerificationPageFailed = ({ showLogin = false }) => {
  const [showFiledModal, setShowFailedModal] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (showFiledModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showFiledModal]);

  const handleNavigation = (event) => {
    event.stopPropagation();
    navigate("/");
  };

  return (
    <>
      <div
        style={{
          backgroundColor: darkTheme_bg_color,
          fontFamily: "Inter, sans-serif",
          position: "relative",
          minHeight: "100vh",
        }}
      >
        <PageLayout showLogin={showLogin}>
          <div>
            <LandingPageContents />
          </div>
        </PageLayout>
      </div>
      <div
        onClick={(event) => {
          handleNavigation(event);
        }}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(23, 24, 44, 0.95)", // Dark semi-transparent background
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 10000, // Ensure modal is above everything
        }}
      >
        <div onClick={(e) => e.stopPropagation()}>
          <FailedModal showFiledModal={showFiledModal} />
        </div>
      </div>
    </>
  );
};

export default EmailVerificationPageFailed;

const FailedModal = ({ showFiledModal }) => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate("/");
  };

  return (
    <>
      {showFiledModal && (
        <Box
          sx={{
            zIndex: 100000,
            width: "480px",
            height: "250px",
            backgroundColor: "#282D51",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            position: "absolute",
            borderRadius: "8px",
            opacity: 100,
          }}
        >
          {/* section1  */}

          <Box
            sx={{
              width: "480px",
              height: "68px",
              Border: "0px, 0px, 1px, 0px",
              justifyContent: "space-between",
              padding: "20px",
            }}
          >
            <Box
              sx={{
                width: "440px",
                height: "28px",

                display: "flex",
                justifyContent: "end",
              }}
            >
              <Box
                sx={{
                  width: "28px",
                  height: "28px",
                }}
              >
                <IconButton
                  aria-label="close"
                  onClick={handleNavigation}
                  sx={{
                    color: "rgba(255, 255, 255, 1)",
                  }}
                >
                  <CloseIcon
                    sx={{
                      width: "16.33px",
                      height: "16.33px",
                    }}
                  />
                </IconButton>
              </Box>
            </Box>
          </Box>

          {/* section 2 */}

          <Box
            sx={{
              width: "400px",
              height: "160px",
              position: "absolute",
              top: "40px",
              left: "40px",
              gap: "20px",
              opacity: "0px",
            }}
          >
            <Box
              sx={{
                width: "40px",
                height: "40px",
              }}
            >
              {" "}
              <SmsFailedSharpIcon
                sx={{
                  color: "rgba(221, 32, 37, 1)",

                  width: "40px",
                  height: "40px",
                }}
              />
            </Box>

            <Box
              sx={{
                width: "400px",
                height: "100px",

                gap: "20px",
              }}
            >
              <Box
                sx={{
                  marginTop: "20px",
                  width: "400px",
                  height: "20px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "20px",
                    fontWeight: 600,
                    lineHeight: "20px",
                    textAlign: "left",
                  }}
                >
                  Email Verification Failed
                </Typography>
              </Box>

              <Box
                sx={{
                  marginTop: "20px",
                  width: "400px",
                  height: "50px",
                }}
              >
                <Typography
                  sx={{
                    headingLarge: {
                      fontFamily: "Inter",
                      fontSize: "20px",
                      fontWeight: 600,
                      lineHeight: "20px",
                      textAlign: "left",
                    },
                  }}
                >
                  Something went wrong while processing. Please try again. If
                  the problem persists, contact us on support@modalx.ai
                </Typography>
              </Box>
            </Box>
          </Box>

          {/* <Button
              variant="contained"
              sx={{
                backgroundColor: "rgba(246, 190, 107, 1)",
                color: "rgba(23, 24, 44, 1)",
                width: "400px",
                height: "40px",
                position: "absolute",
                top: "240px",
                left: "40px",
                padding: "10px 24px 10px 24px",
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "20px",
                letterSpacing: "0.1px",
                textAlign: "center",
                textTransform: "none",
                "&:hover": {
                  bgcolor: "#ffd9aa",
                },
              }}
              onClick={handleNavigation}
            >
              Try again
            </Button> */}
        </Box>
      )}
    </>
  );
};

// import React,{ useState } from 'react'
// import { Col, Container, Row } from 'react-bootstrap';
// import ImageRatio from './ImageRatio';
// import ImageModel from './ImageModel';
// import ImageFormat from './ImageFormat';

// const ImageSettings = () => {
//     const [openImgSet, setOpenImgSet] = useState(false);
//     const handleClick = () => {
//       console.log("Synthesia Settings Clicked");
//       setOpenImgSet(!openImgSet);
//     };
  
//     return (
//       <Container fluid className="m-0 p-0">
//         <Row
//           className="m-0 p-0"
//           style={{ cursor: "pointer" }}
//           onClick={handleClick}
//         >
//           <Col xs={10} className="m-0 px-0 pb-1">
//             <strong className="text-secondary" style={{fontSize:'.95rem'}}>Image Settings</strong>
//           </Col>
//           <Col
//             xs={2}
//             className="m-0 p-0 d-flex align-items-center justify-content-center"
//           >
//             {!openImgSet ? (
//               <i className="bi bi-caret-down" />
//             ) : (
//               <i className="bi bi-caret-up" />
//             )}
//           </Col>
//         </Row>
//         {openImgSet && (
//           <Row className="m-0 p-0" style={{display:'flex',flexDirection:'column',gap:'10px'}}>
//             {/* Use AvatarSettings component here */}
//             <ImageRatio />
//             <ImageModel />
//             <ImageFormat />
//           </Row>
//         )}
//       </Container>
//     );
// }

// export default ImageSettings


import React, { useState, useEffect } from "react";
import { Modal, Tabs, Tab, Box, Typography, IconButton, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ImageRatio from "./ImageRatio";
import ImageModel from "./ImageModel";
import ImageFormat from "./ImageFormat";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import { button_bg_color_for_New_Project, hoveredSettings_Nav_bg_dm, selecetedSettings_Nav_bg_dm, settingsModal_bg_color_dm, uploadFiles_button_default_bg_color, uploadFiles_button_hovered_bg_color } from "../../../../constants/colors";
import { SettingsAdjustment, trackSearchInteraction } from "../../../../config/analytics";
import CommonToast from "../../../Common_Components/CommonToast";
import sourceUrl from "../../../../api/Source.api";
import { useNavigate } from "react-router-dom";
import { containsMediaFile } from "../../../../contexts/WorkspaceUtils";

const ImageSettings = () => {
  const [activeTab, setActiveTab] = useState('ratio');
  const {setImageModel,setImageFormat,setImageRatio,userMediaSettings, showImageSettings, setShowImageSettings, imageFormat, imageModel,imageRatio,selectedOneClickButtonFromWebtrackNotificationMsg,setSelectedOneClickButtonFromWebtrackNotificationMsg,setThreadId,setIswebTrackClicked,setCurrentWebsiteLinkedId,clearThread,setHistoryFiles,setThreadFiles,setSending,setWaitThread,currentThread,setCurrentThread,sendMessage,setNotificationTextFromWebTracking,notificationTextFromWebTracking,stopMessageIdRef, setVisibleStopGenerationButton  } = useWorkspace();
  const theme = localStorage.getItem('theme');
  const [imageSettingsSaved,setImageSettingsSaved] = useState(false);
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  useEffect(() => {
    if (showImageSettings) {
      setActiveTab('ratio'); // Set default tab
    }
  }, [showImageSettings]);

  useEffect(()=>{
    if(userMediaSettings){
    setImageRatio(userMediaSettings[2]?.settings?.aspectRatio)
    setImageFormat(userMediaSettings[2]?.settings?.outputFormat)
    setImageModel(userMediaSettings[2]?.settings?.style)
    }
  },[userMediaSettings])

  const handleClose = () => {
    setShowImageSettings(false); // Close the modal by updating the context state
  };

  const handleCloseGenerate = () => {
    setShowImageSettings(false);
    setSelectedOneClickButtonFromWebtrackNotificationMsg('');
    setNotificationTextFromWebTracking('');
  }

  const handleSave = () => {
    // Implement save functionality here
    SettingsAdjustment("Image_settings", {imageFormat:imageFormat,imageModel: imageModel, imageRatio: imageRatio});
    console.log('Settings saved');
    setImageSettingsSaved(true);
    setShowImageSettings(false);
  };

  const removeUrlsAndBrackets = (msg) => {
    return msg.replace(/\[.*?\]\((?:https?:\/\/[^\s\)\]]+|Link)\)/g, '');
  };
  

  const handleSaveAndGenerate = () => {
    setIswebTrackClicked(false);
    setCurrentWebsiteLinkedId(null);
    navigate("/workspace");
    SettingsAdjustment("Image_settings", {imageFormat:imageFormat,imageModel: imageModel, imageRatio: imageRatio});
    console.log('Settings saved');
    setImageSettingsSaved(true);
    setShowImageSettings(false);
    handleSubmit(removeUrlsAndBrackets(notificationTextFromWebTracking));
    setSelectedOneClickButtonFromWebtrackNotificationMsg('');
    setNotificationTextFromWebTracking('');
  }

  const handleCloseToast = () => {
    setImageSettingsSaved(false);
  };

  const createThreadId = async () => {
    try {
      const response = await fetch(`${sourceUrl}/threads`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
      });
      const responseData = await response.json();
      if (responseData.status) {
        const newThreadId = responseData.data.match(/(.*)/)[1];
        setThreadId(newThreadId);
        localStorage.setItem('threadId', newThreadId);
        return newThreadId;
      } else {
        throw new Error("Thread creation failed");
      }
    } catch (error) {
      console.error("Thread creation error:", error);
      return null;
    }
  };

  const handleSubmit = async (input) => {
    setSending(true);
    setVisibleStopGenerationButton(true)
    setWaitThread(true);
    if (input?.trim()) {
      const latestEntry = { sender: "user", content: input };
      // setCurrentThread((prevThread) => {
      //   const updatedThread = prevThread[prevThread.length - 1]?.sender === "user"
      //     ? prevThread.slice(0, -1) // Remove the last entry if it's from the user
      //     : prevThread;
      //   return [...updatedThread, latestEntry];
      // })
      if(currentThread[currentThread.length - 1]?.sender !== "user"){
        setCurrentThread((prevThread) => [...prevThread, latestEntry]);
      }
      
      const entryResponse = await sendMessage(latestEntry).catch(console.error);
      // if(findUrl(entryResponse.content)){
      //   setHasLink(true)
      //   await extractLinks(entryResponse.content)
      //   }
      if (entryResponse) {
        const url = findUrl(entryResponse.content);
        if (url && url.includes("dailylimitexceeded")) {
          const fileFormat = getFileFormat(url);
          const responseMessage = {
            content: `Hi there,\n\nIt looks like you’ve reached your daily limit for generating `+ fileFormat +` files...`,
            sender: 'modalX',
          };
          setCurrentThread((prevThread) => [...prevThread, responseMessage]);
        }else if(stopMessageIdRef.current.includes(entryResponse.unique_id)){
          setCurrentThread((prevThread)=>[...prevThread ])
          setVisibleStopGenerationButton(false);
        }
         else {
          // setCurrentThread((prevThread) => [...prevThread, entryResponse]);
          if(containsMediaFile(entryResponse.content)){
            setCurrentThread((prevThread) => [...prevThread, entryResponse]);
          }else{
            setCurrentThread((prevThread) => [...prevThread, entryResponse]);
            setVisibleStopGenerationButton(false);
          }
        }
        setSending(false);
      } else {
        setCurrentThread((prevThread) => [...prevThread, { content: "Unable to process your query!..", sender: 'modalX' }]);
        setSending(false);
        setVisibleStopGenerationButton(false);
      }
    }
  
    setWaitThread(false);
  };

  const findUrl = (msg) => {
    const urlRegex = /(https?:\/\/[^']*?\.(mp3|mp4|pptx|jpg|jpeg|png|txt|ppt))/g;
    const match = urlRegex.exec(msg);
    return match ? match[1] : null;
  };

  const getFileFormat = (url) => {
    // Extract the file extension from the URL
    const parts = url.split('.');
    if (parts.length > 1) {
      return parts[parts.length - 1]; // Return the last part as the file format
    } else {
      return ''; // Return empty string if no file extension found
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'ratio':
        return <ImageRatio />;
      case 'model':
        return <ImageModel />;
      case 'format':
        return <ImageFormat />;
      default:
        return <ImageRatio />;
    }
  };

  return (
    <div>
      <CommonToast 
        open={imageSettingsSaved}
        onClose={handleCloseToast}
        content="Image settings saved"
        bgColor="#282D51"
        fontColor="#fff"
        fontSize="14px"
        fontWeight="500"
        autoHideDuration={3000}
        iconType = 'success'
      />
      <Modal
      open={showImageSettings}
      onClose={handleClose}
      aria-labelledby="image-settings-title"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
       
      }}
    >
      <Box
        sx={{
          width: '720px',
          height: '636px',
          backgroundColor: 'background.paper',
          boxShadow: 24,
          // p: 4,
          display: 'flex',
          flexDirection: 'column',
          bgcolor: theme === 'dark' ? settingsModal_bg_color_dm : '',
          borderRadius:'8px',
          
        }}
      >
        <Typography
          id="image-settings-title"
          variant="h6"
          component="h2"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            // mb: 2,
            borderBottom: `1px solid ${theme === 'dark' ? '#535774' : ''}`,
            padding:'20px',
            fontFamily: 'Inter, sans-serif',
          }}
          style={{fontSize:'20px',fontWeight:'600',color:theme === 'dark'?'#FFFFFF':''}}
        >
          Image Settings
          <IconButton
            aria-label="close"
            onClick={selectedOneClickButtonFromWebtrackNotificationMsg === ''?handleClose:handleCloseGenerate}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Typography>
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={activeTab}
            onChange={(event, newValue) => setActiveTab(newValue)}
            sx={{
              borderRight: 1,
              borderColor: theme === 'dark' ? '#535774' : '',
              minWidth: '150px',
              display: 'flex',
              flexDirection: 'column',
              width:'223px',
            }}
            TabIndicatorProps={{
              style: {
                display: 'none', // Hide the default indicator (blue underline)
              },
            }}
          >
            <Tab
              label="Image Ratio"
              value="ratio"
              sx={{
                backgroundColor: activeTab === 'ratio' ? selecetedSettings_Nav_bg_dm : 'transparent', // Grey background for selected tab
                '&:hover': {
                  backgroundColor: theme === 'dark' ? hoveredSettings_Nav_bg_dm : '', // Light grey on hover
                },
                color: theme === 'dark' ? 'white' : 'black',
                textTransform: 'none',
                fontSize: '14px',
                margin:'20px 20px 4px 20px',
                borderRadius:'8px'
              }}
              style={{color:'#FFFF'}}
            />
            <Tab
              label="Image Style"
              value="model"
              sx={{
                backgroundColor: activeTab === 'model' ? selecetedSettings_Nav_bg_dm : 'transparent', // Grey background for selected tab
                '&:hover': {
                  backgroundColor: theme === 'dark' ? hoveredSettings_Nav_bg_dm : '', // Light grey on hover
                },
                color: theme === 'dark' ? 'white' : 'black',
                textTransform: 'none',
                fontSize: '14px',
                margin:'0 20px 4px 20px',
                borderRadius:'8px'
              }}
              style={{color:'#FFFF'}}
            />
            <Tab
              label="Image Format"
              value="format"
              sx={{
                backgroundColor: activeTab === 'format' ? selecetedSettings_Nav_bg_dm : 'transparent', // Grey background for selected tab
                '&:hover': {
                  backgroundColor: theme === 'dark' ? hoveredSettings_Nav_bg_dm : '', // Light grey on hover
                },
                color: theme === 'dark' ? 'white' : 'black',
                textTransform: 'none',
                fontSize: '14px',
                margin:'0 20px 4px 20px',
                borderRadius:'8px'
              }}
              style={{color:'#FFFF'}}
            />
          </Tabs>
          <Box
            sx={{
              flexGrow: 1,
              pl: 3,
              overflowY: 'auto',
              maxHeight: '430px',
              width:'457px'
            }}
          >
            {renderContent()}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            // mt: 2,
            p: 2,
            borderTop: `1px solid ${theme === 'dark' ? '#444' : '#ccc'}`, // Border top added
          }}
        >
          <Button
            onClick={selectedOneClickButtonFromWebtrackNotificationMsg === ''?handleClose:handleCloseGenerate}
            sx={{
              mr: 1,
              backgroundColor: theme === 'dark' ? uploadFiles_button_default_bg_color : '',
              '&:hover': {
                backgroundColor: uploadFiles_button_hovered_bg_color,
              },
              color: '#17182C',
              fontSize: '16px',
              fontWeight: '600',
              textTransform: 'none',
              fontFamily: 'Inter, sans-serif',
            }}
          >
            Close
          </Button>
          <Button
            variant="contained"
            onClick={selectedOneClickButtonFromWebtrackNotificationMsg === ''?handleSave:handleSaveAndGenerate}
            sx={{
              backgroundColor: theme === 'dark' ? button_bg_color_for_New_Project : '',
              '&:hover': {
                backgroundColor: '#FFD9AA',
              },
              color: '#17182C',
              fontSize: '16px',
              fontWeight: '600',
              textTransform: 'none',
              fontFamily: 'Inter, sans-serif',
            }}
          >
            {selectedOneClickButtonFromWebtrackNotificationMsg === ''?'Save':'Save and generate image'}
          </Button>
        </Box>
      </Box>
    </Modal>
    </div>
    
  );
};

export default ImageSettings;
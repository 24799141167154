

const PeFlag = (props) => (
  <svg
    width="1em"
    height="0.75em"
    xmlns="http://www.w3.org/2000/svg"
    id="flag-icons-pe"
    viewBox="0 0 640 480"
    {...props}
  >
    <path fill="#D91023" d="M0 0h640v480H0z" />
    <path fill="#fff" d="M213.3 0h213.4v480H213.3z" />
  </svg>
)

export default PeFlag

import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col, } from "react-bootstrap";
import styled, { keyframes } from "styled-components";
// import  black_logo  from "../../../assets/modalX_robot_icon_black.png";
import black_logo from "../../../assets/ModalX_-orange_Icon.png";
import  white_logo  from "../../../assets/modalX_robot_icon_white.png";
import StopGenerationButton from "../../Common_Components/StopGenerationButton";
import {Box,} from '@mui/material'
// Define the blinking animation
const blink = keyframes`
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
`;

// Styled component for the dot
const Dot = styled.div`
  width: 2px;
  height: 5px;
  border-radius: 50%;
  background-color: #343a40; /* Blue color, you can change it as needed */
  margin: 0 5px;
  animation: ${blink} 1s infinite alternate; /* Apply the blinking animation */
`;

const TypingIndicator = () => {
  const theme = localStorage.getItem('theme');
  const [hovered, setHovered] = useState(false);
  const messagesEndRef = useRef(null);

    useEffect(() => {
      // Scroll to the bottom of the container whenever currentThread changes
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, [hovered]);

  const HandleMouseLeave = () =>{
    setTimeout(()=>{
      setHovered(false)
    },500)
  }

  return (
    <Container fluid >
      <Row style={{display:'flex',flexDirection:'row',justifyContent:'start',alignItems:'start',marginRight:'10px'}}>
      <Col xs={1} className="d-flex flex-column justify-content-start align-items-center p-0 m-0">
          {/* <i className="bi bi-robot fs-4" /> */}
          <img style={{width:'48px',height:'48px', color : 'red'}} src={black_logo}/>
        </Col>
        {/* Three blinking dots */}
        <Box  onMouseEnter={()=>{setHovered(true)}} onMouseLeave={HandleMouseLeave}  sx={{display:"flex", flexDirection:"column", alignItems:"start", width:"400px",   rowGap:"46px"}}>
        <Dot style={{backgroundColor : '#282D51', width: '431px' , height : '28px',borderRadius : '8px'}} />
        <Box ref={messagesEndRef} sx={{marginLeft:"5px", display: hovered ? "flex" : "none"}}>
        <StopGenerationButton />
        </Box>
        </Box>
        {/* <Col><Dot /></Col>
        <Col><Dot /></Col>
        <Col><Dot /></Col> */}
      </Row>
    </Container>
    
  );
};

export default TypingIndicator;

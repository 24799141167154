import React from 'react';
import { Typography } from '@mui/material';

const Automation = () => {
  return (
    <div>
      <Typography variant="h4">Automation</Typography>
      {/* Automation content goes here */}
    </div>
  );
};

export default Automation;
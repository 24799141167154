import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Card,
  Grid,
  Typography,
  InputAdornment,
  Input,
  Button,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Pagination from "@mui/material/Pagination";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import { fetchLanguages } from "./VideoGenAPISettings";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import FaceIcon from "@mui/icons-material/Face";
import Face3Icon from "@mui/icons-material/Face3";
import SearchIcon from "@mui/icons-material/Search";
import maleIcon from '../../../../assets/Icon/male.png'
import femaleIcon from '../../../../assets/Icon/female.png'
import {
  CommonDropdown,
  CommonDropdownForCountry,
  CommonDropdownForGender,
  CommonDropdownForVoices,
  CommonDropdownForVoiceTone,
} from "../../../Common_Components/CommonDropdown";
import GetCountryFlagByName from "./GetCountryFlagByName";


const LanguageArrayForFilter = [
  { id: 0, name: "All", language: "All" },
];

const GenderArrayForFilter = [
  { id: 0, name: "All", gender: "All" },
  { id: 1, name: "Male", gender: "Male" },
  { id: 2, name: "Female", gender: "Female" },
];

const CountryArrayForFilter = [
  { id: 0, name: "All", country: "All" },
];

const VoiceToneArrayForFilter = [
  { id: 0, name: "All", quality: "All" },
];

const LanguageAndVoice = () => {



  const theme = localStorage.getItem("theme");
  const {
    genVideoLanguage,
    setGenVideoLanguage,
    selectedGenVideoLanguage,
    setSelectedGenVideoLanguage,
    setGenVideoAudioSettingsLoading,
    genVideoVoice,
    setGenVideoVoice,
    selectedGenVideoVoice,
    setSelectedGenVideoVoice,
    genVideoVoicesLoading,
  } = useWorkspace();
  const [selectedLanguage, setSelectedLanguage] = useState({});
  const [playingVoice, setPlayingVoice] = useState(null);
  // const [audioElement, setAudioElement] = useState(null);
  const audioElement = useRef(null);
  const [inputBoxValueForLanguage, setInputBoxValueForLanguage] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");
  const [filteredLanguages, setFilteredLanguages] = useState(genVideoLanguage);
  const [filteredVoices, setFilteredVoices] = useState(genVideoVoice);
  const [languageOption, setLanguageOption] = useState(LanguageArrayForFilter);
  const [genderOption, setGenderOption] = useState(GenderArrayForFilter);
  const [countryOption, setCountryOption] = useState(CountryArrayForFilter);
  const [voiceToneOption, setVoiceToneOption] = useState(VoiceToneArrayForFilter);
  const itemsPerPage = 20;
  const [currentPageForVoices, setCurrentPageForVoices] = useState(1);
  const [filterCriteria, setfilterCriteria] = useState({
    language: "",
    gender: "",  
    country: "",
    quality: ""
  });
  const indexOfLastItem = currentPageForVoices * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredVoices?.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredVoices?.length/20)

  useEffect(() => {
    if (genVideoLanguage?.length === 0) {
      fetchLanguages(setGenVideoLanguage, setGenVideoAudioSettingsLoading);
    }
  }, [setGenVideoLanguage, genVideoLanguage]);

  useEffect(() => {
    function updateFilterArray(apiData, filterArray, key) {
      const existingValues = new Set(filterArray.map((item) => item[key]));
      let currentId = filterArray?.length > 0 ? Math.max(...filterArray.map(item => item.id)) + 1 : 1;
  
      const newValues = [];
      apiData?.forEach((item) => {
        const value = item[key]; // Directly access key from the object
  
        if (value && !existingValues.has(value)) {
          existingValues.add(value); // Add to the set to avoid duplicates
          newValues.push({
            id: currentId++, // Assign incrementing ID
            name: value,
            [key]: value,
          });
        }
      });
  
      return [...filterArray, ...newValues];
    }
  
    const updatedLanguageArray = updateFilterArray(genVideoVoice, LanguageArrayForFilter, "language");
    setLanguageOption(updatedLanguageArray);
  
    const updatedGenderArray = updateFilterArray(genVideoVoice, GenderArrayForFilter, "gender");
    setGenderOption(updatedGenderArray);
  
    const updatedCountryArray = updateFilterArray(genVideoVoice, CountryArrayForFilter, "country");
    setCountryOption(updatedCountryArray);
  
    const updatedQualityArray = updateFilterArray(genVideoVoice, VoiceToneArrayForFilter, "quality");
    setVoiceToneOption(updatedQualityArray);
  
    // console.log("Updated Language Array:", updatedQualityArray);
  }, [genVideoVoice]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputBoxValueForLanguage);
    }, 300); // Adjust debounce time as needed
    return () => clearTimeout(handler);
  }, [inputBoxValueForLanguage]);

  const menuProps = {
    PaperProps: {
      sx: {
        backgroundColor: "#2F355F",
        color: "#2F355F",
        padding: 0, // Remove padding at top and bottom
        borderRadius: "8px",
        // Hide scrollbar while allowing scrolling
        "&::-webkit-scrollbar": {
          display: "none",
        },
        overflowY: "auto", // Enable scrolling without scrollbar
        scrollbarWidth: "none", // Hide scrollbar in Firefox
        // Remove padding and spacing for each item in the dropdown
        "& .MuiMenuItem-root": {
          padding: "8px 16px", // Adjust padding as needed
        },
      },
    },
  };

    // Custom theme for Material UI Pagination
    const paginationTheme = createTheme({
      typography: {
        fontFamily: "Inter",
      },
      components: {
        MuiPagination: {
          styleOverrides: {
            root: {
              "& .MuiPaginationItem-root": {
                color: "#ffffff",
                borderRadius: "20px",
                "&:hover": {
                  backgroundColor: "rgba(179, 168, 183, 0.26)",
                },
                fontSize: "14px",
              },
              "& .MuiPaginationItem-page.Mui-selected": {
                backgroundColor: "#CAC4D0",
                color: "black",
                "&:hover": {
                  backgroundColor: "#CAC4D0",
                },
              },
              "& .MuiPaginationItem-ellipsis": {
                color: "#ffffff",
              },
              "& .MuiPaginationItem-previousNext": {
                color: "#ffffff",
                borderRadius: "20px",
              },
            },
          },
        },
      },
    });

  const handleVideoGenLanguageChange = (event) => {
    const selectedLangCode = event.target.value;
    const newLang = genVideoLanguage.find(
      (lang) => lang.languageCode === selectedLangCode
    );
    setSelectedGenVideoLanguage(newLang);
    setSelectedLanguage(newLang);
  };

  const handleVoiceSelect = (voice) => {
    console.log(voice, "voice========================");
    if (!voice?.url) {
      console.error("Invalid audio source:", voice);
      return; // Exit if the URL is invalid
    }
  
    // Create a test audio element to check if the format is supported
    const testAudio = document.createElement("audio");
    if (!testAudio.canPlayType("audio/mpeg")) {
      console.error("Audio format not supported in this browser.");
      alert("This audio format is not supported in your browser.");
      return;
    }
    if (playingVoice === voice?.id) {
      audioElement?.current?.pause();
      setPlayingVoice(null);
    } else {
      if (audioElement.current) {
        audioElement?.current?.pause();
      }
      const newAudioElement = new Audio(voice?.url);
      newAudioElement?.play();
      setPlayingVoice(voice?.id);
      audioElement.current = newAudioElement
    }
  };

  const handleVoiceSelectClick = (voice) => {
    // console.log(voice, "voice========================");
    setSelectedGenVideoVoice(voice);
  };

  useEffect(() => {
    return () => {
      if (audioElement.current) {
        audioElement.current.pause();
        audioElement.current.currentTime = 0;
        audioElement.current.src = "";
        audioElement.current = null;
      }
      setPlayingVoice(null);
    };
  }, []);

  useEffect(()=>{
    // Function to get filtered and excluded data
  const filteredData = genVideoVoice?.filter(item =>
    (!filterCriteria.language || item.language === filterCriteria.language) &&
    (!filterCriteria.gender || item.gender === filterCriteria.gender) &&
    (!filterCriteria.country || item.country === filterCriteria.country) &&
    (!filterCriteria.quality || item.quality === filterCriteria.quality)
  );

  // console.log("filterCriteria filteredData", filteredData)
  const excludedData = genVideoVoice?.filter(item => !filteredData.includes(item));
  // console.log("filterCriteria excludedData", excludedData)
  const updatedVoices = filteredData?.concat(excludedData);
  setFilteredVoices(updatedVoices)
  
  },[filterCriteria, genVideoVoice])

  const handleClearButtonClick = () =>{
    setfilterCriteria({
      language: "",
      gender: "",
      country: "",
      quality: ""
    })
  }

  const handlePageChange = (event, value) => {
    setCurrentPageForVoices(value); // Update the current page when a page is clicked
  };

  return (
    <Box sx={{ padding: "20px 20px 0px 0px" }}>
      <Typography
        variant="h6"
        gutterBottom
        style={{
          fontSize: "16px",
          fontWeight: "500",
          marginBottom: "8px",
          fontFamily: "Inter",
          fontStyle: "normal",
          lineHeight: "20px",
          color: "#ffffff",
        }}
      >
        Video language
      </Typography>
      <Typography
        //  gutterBottom
        style={{
          fontSize: "14px",
          fontWeight: "400",
          marginBottom: "20px",
          fontFamily: "Inter",
          fontStyle: "normal",
          lineHeight: "20px",
          color: "rgba(255, 255, 255, 0.80)",
        }}
      >
        Select the language for your video’s script and voice-over
      </Typography>

      <Box sx={{ marginBottom: "40px" }}>
        <CommonDropdown
          filteredLanguages={filteredLanguages}
          setFilteredLanguages={setFilteredLanguages}
        />
      </Box>
      <Typography
        style={{
          fontSize: "16px",
          fontWeight: 500,
          marginBottom: "8px",
          fontFamily: "Inter",
          fontStyle: "normal",
          lineHeight: "20px",
          color: "rgba(255, 255, 255, 1)",
        }}
      >
        AI Narrator
      </Typography>
      <Typography
        style={{
          fontSize: "14px",
          fontWeight: "400",
          marginBottom: "20px",
          fontFamily: "Inter",
          fontStyle: "normal",
          lineHeight: "20px",
          color: "rgba(255, 255, 255, 0.80)",
        }}
      >
        Choose from the most advanced AI voices in various languages and accents
      </Typography>
      <FormControl
        variant="standard"
        sx={{
          display: "flex",
          width: "auto",
          flexDirection: "row",
          alignItems:"center",
          columnGap: "12px",
          marginBottom: "36px",
          "& .MuiInput-underline:before": {
            borderBottom: "none",
          },
          "& .MuiInput-underline:after": {
            borderBottom: "none",
          },
          "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderBottom: "none",
          },
        }}
      >
        <Box>
          <CommonDropdownForVoices
            languageOption={languageOption}
            setFilteredVoices={setLanguageOption}
            setfilterCriteria={setfilterCriteria}
            filterCriteria={filterCriteria}
          />
        </Box>

        <Box>
          <CommonDropdownForGender
            genderOption={genderOption}
            setFilteredVoices={setFilteredVoices}
            setfilterCriteria={setfilterCriteria}
            filterCriteria={filterCriteria}
          />
        </Box>

        <Box>
          <CommonDropdownForCountry
            countryOption={countryOption}
            setFilteredVoices={setFilteredVoices}
            setfilterCriteria={setfilterCriteria}
            filterCriteria={filterCriteria}
          />
        </Box>

        <Box>
          <CommonDropdownForVoiceTone
            voiceToneOption={voiceToneOption}
            setFilteredVoices={setFilteredVoices}
            setfilterCriteria={setfilterCriteria}
            filterCriteria={filterCriteria}
          />
        </Box>
        <Box onClick={handleClearButtonClick}>
          <Typography sx={{fontFamily:"Inter", fontStyle:"normal", fontSize:"14px", fontWeight:500, lineHeight:"20px", color:"#FFFFFF", cursor:"pointer"}}>{Object.values(filterCriteria).some(value => value !== "") && "Clear filters"}</Typography>
        </Box>
      </FormControl>
      <Box
        sx={{
          width: "100%",
          maxHeight: "160px",
          overflowY: "scroll",
          display: "flex",
          flexDirection: "column",
          rowGap: "16px",
          alignItems:"center"
        }}
      >
        {
          genVideoVoicesLoading ? 
          <Box>Loading</Box>
          :
          currentItems?.map((voiceOption) => {
            return (
              <Box
                onClick={() => handleVoiceSelectClick(voiceOption)}
                sx={{
                  width: "100%",
                  border: selectedGenVideoVoice?.name === voiceOption?.name ? "1px solid rgba(255, 255, 255, 0.30)" : "1px solid rgba(255, 255, 255, 0.10)",
                  padding: "12px 16px",
                  borderRadius: "16px",
                  display: "flex",
                  justifyContent: "space-between",
                  cursor:"pointer",
                  backgroundColor:
                    selectedGenVideoVoice?.name === voiceOption?.name
                      ? "#282D51"
                      : "transparent",
                  "&:hover": {
                    backgroundColor: "#282D51",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Tooltip
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "#E6E0E9",
                          color: "#17182C",
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontWeight: 500,
                          padding: "4px 8px 4px 8px",
                          borderRadius: "8px",
                          textAlign: "center",
                        },
                      },
                    }}
                    title={
                      playingVoice === voiceOption.id
                        ? `Stop voice`
                        : `Preview voice`
                    }
                  >
                    <IconButton
                      onClick={(e) => {
                        handleVoiceSelect(voiceOption);
                        e.stopPropagation();
                      }}
                      sx={{
                        color:
                          playingVoice === voiceOption.id
                            ? "rgba(23, 26, 45, 1)"
                            : "rgba(23, 26, 45, 1)",
                        backgroundColor:
                          playingVoice === voiceOption.id ? "white" : "white",
                        transition:
                          "background-color 0.3s ease, transform 0.2s ease",
                        "&:hover": {
                          backgroundColor: "white",
                          // transform: 'scale(1.1)', // Subtle zoom on hover
                        },
                        width: "30px",
                        height: "30px",
                        padding: "12px",
                        borderRadius: "50%",
                        // color: "rgba(23, 26, 45, 1)",
                        // backgroundColor: "white",
                      }}
                    >
                      {playingVoice === voiceOption.id ? (
                        <PauseIcon sx={{}} />
                      ) : (
                        <PlayArrowIcon sx={{}} />
                      )}
                    </IconButton>
                  </Tooltip>
                  <Box
                    sx={{
                      border: "1px solid rgba(255, 255, 255, 0.10)",
                      height: "30px",
                      margin: "0px 12px",
                    }}
                  ></Box>
                  <IconButton
                    // onClick={(e) => {handleVoiceSelect(language);  e.stopPropagation() }}
                    sx={{
                      padding: "0px",
                    }}
                  >
                    {voiceOption.gender === "Male" ? (
                    <Box
                    component="img"
                    src={maleIcon}
                    alt="Male"
                    sx={{ width: 20, height: 20 }}
                  />
                ) : (
                  <Box
                    component="img"
                    src={femaleIcon}
                    alt="Female"
                    sx={{ width: 20, height: 20 }}
                  />
                    )}
                  </IconButton>
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: selectedGenVideoVoice?.name === voiceOption?.name ?  500 : 400,
                      lineHeight: "20px",
                      letterSpacing: "0.1px",
                      fontSize: "14px",
                      color: "#fff",
                      marginLeft: "8px",
                    }}
                  >
                    {voiceOption.name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    columnGap: "12px",
                  }}
                >
                  <Tooltip
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "#E6E0E9",
                          color: "#17182C",
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontWeight: 500,
                          padding: "4px 8px 4px 8px",
                          borderRadius: "8px",
                          textAlign: "center",
                        },
                      },
                    }}
                    title={`Voice tone: ${voiceOption.quality}`}
                  >
                    <Button
                      sx={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "140%",
                        letterSpacing: "0.1px",
                        color: "rgba(255, 255, 255, 1)",
                        textWrap: "nowrap",
                        backgroundColor: "#2F355F",
                        width: "auto",
                        padding: "8px",
                        textTransform: "none",
                        whiteSpace: "nowrap",
                        borderRadius:"8px"
                      }}
                    >
                     {` ${voiceOption.quality}`}
                    </Button>
                  </Tooltip>
                  <Tooltip
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "#E6E0E9",
                          color: "#17182C",
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontWeight: 500,
                          padding: "4px 8px 4px 8px",
                          borderRadius: "8px",
                          textAlign: "center",
                          whiteSpace: "nowrap",
                        },
                      },
                    }}
                    title={`Language: ${voiceOption.language} (${voiceOption.country})`}
                  >
                    <Button
                      sx={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "140%",
                        letterSpacing: "0.1px",
                        color: "rgba(255, 255, 255, 1)",
                        textWrap: "nowrap",
                        backgroundColor: "#2F355F",
                        width: "auto",
                        padding: "8px",
                        textTransform: "none",
                        gap:"10px",
                        alignItems:"center",
                        textAlign: "center",
                        borderRadius:"8px"
                      }}
                    >
                      {/* <CountryFlag code={code} /> */}
                      <GetCountryFlagByName countryName={voiceOption.country} />
                      {voiceOption.language}
                    </Button>
                  </Tooltip>
                </Box>
              </Box>
            );
          })
        }
        {/* <Box sx={{display:"flex", flexDirection:"row", columnGap:"10px", alignItems:"center", justifyContent:"center", textAlign:"center"}}>
          <Button onClick={prevPage} disabled={currentPageForVoices == 1} sx={{color:"#ffffff", fontFamily:"Inter", fontStyle:"normal", fontSize:"14px", '&:disabled':{
            color:"#ffffff",
            opacity:0.5
          }}}>Prev</Button>
          <Typography>{currentPageForVoices}</Typography>
          <Button onClick={nextPage} sx={{color:"#ffffff", fontFamily:"Inter", fontStyle:"normal", fontSize:"14px"}}>Next</Button>
        </Box> */}
         <div className="d-flex justify-content-center mt-3">
                  <ThemeProvider theme={paginationTheme}>
                    <Pagination
                      count={totalPages} // Total number of pages
                      page={currentPageForVoices} // Current page
                      siblingCount={1} // Show one previous and one upcoming page
                      boundaryCount={1} // Show first and last page buttons
                      onChange={handlePageChange} // Handle page change
                      shape="rounded" // Rounded buttons
                      size="large" // Larger buttons
                    />
                  </ThemeProvider>
                </div>
      </Box>
    </Box>
  );
};

export default LanguageAndVoice;

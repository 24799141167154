// src/utils/AuthUtils.js
import { jwtDecode } from "jwt-decode";
import { auth } from "../config/firebase.config";

let refreshTimer = null;

const logTokenInfo = (token) => {
  if (!token) {
    console.log("No token available");
    return;
  }
  
  try {
    const decoded = jwtDecode(token);
    const now = Date.now();
    const expiresIn = (decoded.exp * 1000 - now) / 1000; // in seconds
    console.log(`Token expires in: ${expiresIn.toFixed(1)} seconds`);
    console.log("Token payload:", decoded);
  } catch (e) {
    console.error("Failed to decode token:", e);
  }
};

export const refreshAuthToken = async (force = false) => {
  console.log("refreshAuthToken called", { force });
  
  const user = auth.currentUser;
  if (!user) {
    console.log("No user logged in");
    return null;
  }

  try {
    // Clear any existing timer
    if (refreshTimer) {
      clearTimeout(refreshTimer);
      refreshTimer = null;
    }

    // Get fresh token (force refresh if requested)
    const idToken = await user.getIdToken(force);
    localStorage.setItem("token", idToken);
    
    console.log("New token obtained");
    logTokenInfo(idToken);

    // Decode token to schedule next refresh
    const decodedToken = jwtDecode(idToken);
    const expiryTime = decodedToken.exp * 1000;
    const now = Date.now();
    const timeUntilExpiry = expiryTime - now;
    
    // Refresh 5 minutes before expiry (with minimum 30 seconds buffer)
    const refreshThreshold = Math.max(5 * 60 * 1000, 30000);
    let refreshTime = timeUntilExpiry - refreshThreshold;

    // If we're already within the threshold, refresh immediately
    if (refreshTime <= 0) {
      console.log("Token nearing expiry, refreshing now");
      return refreshAuthToken(true);
    }

    console.log(`Scheduling refresh in ${Math.floor(refreshTime / 1000)} seconds`);
    refreshTimer = setTimeout(() => {
      console.log("Auto-refreshing token...");
      refreshAuthToken(true);
    }, refreshTime);

    return idToken;
  } catch (error) {
    console.error("Token refresh error:", error);
    
    // Retry after 1 minute if error occurs
    refreshTimer = setTimeout(() => {
      console.log("Retrying failed token refresh...");
      refreshAuthToken(true);
    }, 60000);
    
    return null;
  }
};

export const clearAuthRefreshTimer = () => {
  if (refreshTimer) {
    console.log("Clearing refresh timer");
    clearTimeout(refreshTimer);
    refreshTimer = null;
  }
};

import React from 'react'
import AFflag from '../../../Common_Components/flags/AfFlag';
import ALflag from '../../../Common_Components/flags/AlFlag';
import DZflag from '../../../Common_Components/flags/DzFlag';
import ASflag from '../../../Common_Components/flags/AsFlag';
import ADflag from '../../../Common_Components/flags/AdFlag';
import AOflag from '../../../Common_Components/flags/AoFlag';
import AIflag from '../../../Common_Components/flags/AiFlag';
import ARflag from '../../../Common_Components/flags/ArFlag';
import AMflag from '../../../Common_Components/flags/AmFlag';
import AWflag from '../../../Common_Components/flags/AwFlag';
import AUflag from '../../../Common_Components/flags/AuFlag';
import ATflag from '../../../Common_Components/flags/AtFlag';
import AZflag from '../../../Common_Components/flags/AzFlag';
import BSflag from '../../../Common_Components/flags/BsFlag';
import BHflag from '../../../Common_Components/flags/BhFlag';
import BDflag from '../../../Common_Components/flags/BdFlag';
import BBflag from '../../../Common_Components/flags/BbFlag';
import BYflag from '../../../Common_Components/flags/ByFlag';
import BEflag from '../../../Common_Components/flags/BeFlag';
import BZflag from '../../../Common_Components/flags/BzFlag';
import BJflag from '../../../Common_Components/flags/BjFlag';
import BMflag from '../../../Common_Components/flags/BmFlag';
import BTflag from '../../../Common_Components/flags/BtFlag';
import BOflag from '../../../Common_Components/flags/BoFlag';
import BAflag from '../../../Common_Components/flags/BaFlag';
import BWflag from '../../../Common_Components/flags/BwFlag';
import BVflag from '../../../Common_Components/flags/BvFlag';
import BRflag from '../../../Common_Components/flags/BrFlag';
import IOflag from '../../../Common_Components/flags/IoFlag';
import BNflag from '../../../Common_Components/flags/BnFlag';
import BGflag from '../../../Common_Components/flags/BgFlag';
import BFflag from '../../../Common_Components/flags/BfFlag';
import KHflag from '../../../Common_Components/flags/KhFlag';
import CMflag from '../../../Common_Components/flags/CmFlag';
import CAflag from '../../../Common_Components/flags/CaFlag';
import CVflag from '../../../Common_Components/flags/CvFlag';
import KYflag from '../../../Common_Components/flags/KyFlag';
import CFflag from '../../../Common_Components/flags/CfFlag';
import TDflag from '../../../Common_Components/flags/TdFlag';
import CLflag from '../../../Common_Components/flags/ClFlag';
import CNflag from '../../../Common_Components/flags/CnFlag';
import CXflag from '../../../Common_Components/flags/CxFlag';
import CCflag from '../../../Common_Components/flags/CcFlag';
import COflag from '../../../Common_Components/flags/CoFlag';
import KMflag from '../../../Common_Components/flags/KmFlag';
import CGflag from '../../../Common_Components/flags/CgFlag';
import CKflag from '../../../Common_Components/flags/CkFlag';
import CRflag from '../../../Common_Components/flags/CrFlag';
import HRflag from '../../../Common_Components/flags/HrFlag';
import CUflag from '../../../Common_Components/flags/CuFlag';
import CYflag from '../../../Common_Components/flags/CyFlag';
import CZflag from '../../../Common_Components/flags/CzFlag';
import DKflag from '../../../Common_Components/flags/DkFlag';
import DJflag from '../../../Common_Components/flags/DjFlag';
import DMflag from '../../../Common_Components/flags/DmFlag';
import DOflag from '../../../Common_Components/flags/DoFlag';
import ECflag from '../../../Common_Components/flags/EcFlag';
import EGflag from '../../../Common_Components/flags/EgFlag';
import SVflag from '../../../Common_Components/flags/SvFlag';
import GQflag from '../../../Common_Components/flags/GqFlag';
import ERflag from '../../../Common_Components/flags/ErFlag';
import EEflag from '../../../Common_Components/flags/EeFlag';
import ETflag from '../../../Common_Components/flags/EtFlag';
import FKflag from '../../../Common_Components/flags/FkFlag';
import FOflag from '../../../Common_Components/flags/FoFlag';
import FJflag from '../../../Common_Components/flags/FjFlag';
import FIflag from '../../../Common_Components/flags/FiFlag';
import FRflag from '../../../Common_Components/flags/FrFlag';
import GFflag from '../../../Common_Components/flags/GfFlag';
import PFflag from '../../../Common_Components/flags/PfFlag';
import GAflag from '../../../Common_Components/flags/GaFlag';
import GMflag from '../../../Common_Components/flags/GmFlag';
import GEflag from '../../../Common_Components/flags/GeFlag';
import DEflag from '../../../Common_Components/flags/DeFlag';
import GHflag from '../../../Common_Components/flags/GhFlag';
import GIflag from '../../../Common_Components/flags/GiFlag';
import GRflag from '../../../Common_Components/flags/GrFlag';
import GLflag from '../../../Common_Components/flags/GlFlag';
import GDflag from '../../../Common_Components/flags/GdFlag';
import GPflag from '../../../Common_Components/flags/GpFlag';
import GUflag from '../../../Common_Components/flags/GuFlag';
import GTflag from '../../../Common_Components/flags/GtFlag';
import GGflag from '../../../Common_Components/flags/GgFlag';
import GNflag from '../../../Common_Components/flags/GnFlag';
import GWflag from '../../../Common_Components/flags/GwFlag';
import GYflag from '../../../Common_Components/flags/GyFlag';
import USflag from '../../../Common_Components/flags/UsFlag';
import INflag from '../../../Common_Components/flags/InFlag';
import GBflag from '../../../Common_Components/flags/GbFlag';
import ZAflag from '../../../Common_Components/flags/ZaFlag';
import NGflag from '../../../Common_Components/flags/NgFlag';
import ITflag from '../../../Common_Components/flags/ItFlag';
import SAflag from '../../../Common_Components/flags/SaFlag';
import NLflag from '../../../Common_Components/flags/NlFlag';
import HUflag from '../../../Common_Components/flags/HuFlag';
import IDflag from '../../../Common_Components/flags/IdFlag';
import IRflag from '../../../Common_Components/flags/IrFlag';
import IQflag from '../../../Common_Components/flags/IqFlag';
import IEflag from '../../../Common_Components/flags/IeFlag';
import IMflag from '../../../Common_Components/flags/ImFlag';
import ILflag from '../../../Common_Components/flags/IlFlag';
import CIflag from '../../../Common_Components/flags/CiFlag';
import JMflag from '../../../Common_Components/flags/JmFlag';
import JPflag from '../../../Common_Components/flags/JpFlag';
import JEflag from '../../../Common_Components/flags/JeFlag';
import JOflag from '../../../Common_Components/flags/JoFlag';
import KZflag from '../../../Common_Components/flags/KzFlag';
import KEflag from '../../../Common_Components/flags/KeFlag';
import KIflag from '../../../Common_Components/flags/KiFlag';
import KWflag from '../../../Common_Components/flags/KwFlag';
import KGflag from '../../../Common_Components/flags/KgFlag';
import LAflag from '../../../Common_Components/flags/LaFlag';
import LVflag from '../../../Common_Components/flags/LvFlag';
import LBflag from '../../../Common_Components/flags/LbFlag';
import LSflag from '../../../Common_Components/flags/LsFlag';
import LRflag from '../../../Common_Components/flags/LrFlag';
import LYflag from '../../../Common_Components/flags/LyFlag';
import LIflag from '../../../Common_Components/flags/LiFlag';
import LTflag from '../../../Common_Components/flags/LtFlag';
import LUflag from '../../../Common_Components/flags/LuFlag';
import MOflag from '../../../Common_Components/flags/MoFlag';
import MKflag from '../../../Common_Components/flags/MkFlag';
import MGflag from '../../../Common_Components/flags/MgFlag';
import MWflag from '../../../Common_Components/flags/MwFlag';
import MYflag from '../../../Common_Components/flags/MyFlag';
import MVflag from '../../../Common_Components/flags/MvFlag';
import MLflag from '../../../Common_Components/flags/MlFlag';
import MTflag from '../../../Common_Components/flags/MtFlag';
import MHflag from '../../../Common_Components/flags/MhFlag';
import MQflag from '../../../Common_Components/flags/MqFlag';
import MRflag from '../../../Common_Components/flags/MrFlag';
import MUflag from '../../../Common_Components/flags/MuFlag';
import YTflag from '../../../Common_Components/flags/YtFlag';
import MXflag from '../../../Common_Components/flags/MxFlag';
import FMflag from '../../../Common_Components/flags/FmFlag';
import MDflag from '../../../Common_Components/flags/MdFlag';
import MCflag from '../../../Common_Components/flags/McFlag';
import MNflag from '../../../Common_Components/flags/MnFlag';
import MEflag from '../../../Common_Components/flags/MeFlag';
import MSflag from '../../../Common_Components/flags/MsFlag';
import MAflag from '../../../Common_Components/flags/MaFlag';
import MZflag from '../../../Common_Components/flags/MzFlag';
import MMflag from '../../../Common_Components/flags/MmFlag';
import NAflag from '../../../Common_Components/flags/NaFlag';
import NRflag from '../../../Common_Components/flags/NrFlag';
import NPflag from '../../../Common_Components/flags/NpFlag';
import NCflag from '../../../Common_Components/flags/NcFlag';
import NZflag from '../../../Common_Components/flags/NzFlag';
import NIflag from '../../../Common_Components/flags/NiFlag';
import NEflag from '../../../Common_Components/flags/NeFlag';
import NUflag from '../../../Common_Components/flags/NuFlag';
import NFflag from '../../../Common_Components/flags/NfFlag';
import MPflag from '../../../Common_Components/flags/MpFlag';
import NOflag from '../../../Common_Components/flags/NoFlag';
import OMflag from '../../../Common_Components/flags/OmFlag';
import PKflag from '../../../Common_Components/flags/PkFlag';
import PWflag from '../../../Common_Components/flags/PwFlag';
import PAflag from '../../../Common_Components/flags/PaFlag';
import PGflag from '../../../Common_Components/flags/PgFlag';
import PYflag from '../../../Common_Components/flags/PyFlag';
import PEflag from '../../../Common_Components/flags/PeFlag';
import PHflag from '../../../Common_Components/flags/PhFlag';
import PNflag from '../../../Common_Components/flags/PnFlag';
import PLflag from '../../../Common_Components/flags/PlFlag';
import PTflag from '../../../Common_Components/flags/PtFlag';
import PRflag from '../../../Common_Components/flags/PrFlag';
import QAflag from '../../../Common_Components/flags/QaFlag';
import REflag from '../../../Common_Components/flags/ReFlag';
import ROflag from '../../../Common_Components/flags/RoFlag';
import RUflag from '../../../Common_Components/flags/RuFlag';
import RWflag from '../../../Common_Components/flags/RwFlag';
import SHflag from '../../../Common_Components/flags/ShFlag';
import KNflag from '../../../Common_Components/flags/KnFlag';
import LCflag from '../../../Common_Components/flags/LcFlag';
import PMflag from '../../../Common_Components/flags/PmFlag';
import VCflag from '../../../Common_Components/flags/VcFlag';
import WSflag from '../../../Common_Components/flags/WsFlag';
import SMflag from '../../../Common_Components/flags/SmFlag';
import STflag from '../../../Common_Components/flags/StFlag';
import SNflag from '../../../Common_Components/flags/SnFlag';
import RSflag from '../../../Common_Components/flags/RsFlag';
import SCflag from '../../../Common_Components/flags/ScFlag';
import SLflag from '../../../Common_Components/flags/SlFlag';
import SGflag from '../../../Common_Components/flags/SgFlag';
import SKflag from '../../../Common_Components/flags/SkFlag';
import SIflag from '../../../Common_Components/flags/SiFlag';
import SBflag from '../../../Common_Components/flags/SbFlag';
import SOflag from '../../../Common_Components/flags/SoFlag';
import GSflag from '../../../Common_Components/flags/GsFlag';
import KRflag from '../../../Common_Components/flags/KrFlag';
import SSflag from '../../../Common_Components/flags/SsFlag';
import ESflag from '../../../Common_Components/flags/EsFlag';
import LKflag from '../../../Common_Components/flags/LkFlag';
import SDflag from '../../../Common_Components/flags/SdFlag';
import SRflag from '../../../Common_Components/flags/SrFlag';
import SJflag from '../../../Common_Components/flags/SjFlag';
import SEflag from '../../../Common_Components/flags/SeFlag';
import CHflag from '../../../Common_Components/flags/ChFlag';
import SYflag from '../../../Common_Components/flags/SyFlag';
import TJflag from '../../../Common_Components/flags/TjFlag';
import TZflag from '../../../Common_Components/flags/TzFlag';
import THflag from '../../../Common_Components/flags/ThFlag';
import TLflag from '../../../Common_Components/flags/TlFlag';
import TGflag from '../../../Common_Components/flags/TgFlag';
import TKflag from '../../../Common_Components/flags/TkFlag';
import TOflag from '../../../Common_Components/flags/ToFlag';
import TTflag from '../../../Common_Components/flags/TtFlag';
import TNflag from '../../../Common_Components/flags/TnFlag';
import TRflag from '../../../Common_Components/flags/TrFlag';
import TMflag from '../../../Common_Components/flags/TmFlag';
import TVflag from '../../../Common_Components/flags/TvFlag';
import UGflag from '../../../Common_Components/flags/UgFlag';
import UAflag from '../../../Common_Components/flags/UaFlag';
import AEflag from '../../../Common_Components/flags/AeFlag';
import UYflag from '../../../Common_Components/flags/UyFlag';
import UZflag from '../../../Common_Components/flags/UzFlag';
import VUflag from '../../../Common_Components/flags/VuFlag';
import VEflag from '../../../Common_Components/flags/VeFlag';
import VNflag from '../../../Common_Components/flags/VnFlag';
import WFflag from '../../../Common_Components/flags/WfFlag';
import EHflag from '../../../Common_Components/flags/EhFlag';
import YEflag from '../../../Common_Components/flags/YeFlag';
import ZMflag from '../../../Common_Components/flags/ZmFlag';
import ZWflag from '../../../Common_Components/flags/ZwFlag';
import countryData from '../../../../constants/countryName.json'


 const countryFlags = {
  AF: AFflag,
  AL: ALflag,
  DZ: DZflag,
  AS: ASflag,
  AD: ADflag,
  AO: AOflag,
  AI: AIflag,
  AR: ARflag,
  AM: AMflag,
  AW: AWflag,
  AU: AUflag,
  AT: ATflag,
  AZ: AZflag,
  BS: BSflag,
  BH: BHflag,
  BD: BDflag,
  BB: BBflag,
  BY: BYflag,
  BE: BEflag,
  BZ: BZflag,
  BJ: BJflag,
  BM: BMflag,
  BT: BTflag,
  BO: BOflag,
  BA: BAflag,
  BW: BWflag,
  BV: BVflag,
  BR: BRflag,
  IO: IOflag,
  BN: BNflag,
  BF: BFflag,
  KH: KHflag,
  CM: CMflag,
  CA: CAflag,
  CV: CVflag,
  KY: KYflag,
  CF: CFflag,
  TD: TDflag,
  CL: CLflag,
  CN: CNflag,
  CX: CXflag,
  CC: CCflag,
  CO: COflag,
  KM: KMflag,
  CG: CGflag,
  CK: CKflag,
  CR: CRflag,
  HR: HRflag,
  CU: CUflag,
  CY: CYflag,
  CZ: CZflag,
  DK: DKflag,
  DJ: DJflag,
  DM: DMflag,
  DO: DOflag,
  EC: ECflag,
  EG: EGflag,
  SV: SVflag,
  GQ: GQflag,
  ER: ERflag,
  EE: EEflag,
  ET: ETflag,
  FK: FKflag,
  FO: FOflag,
  FJ: FJflag,
  FI: FIflag,
  FR: FRflag,
  GF: GFflag,
  PF: PFflag,
  GA: GAflag,
  GM: GMflag,
  GE: GEflag,
  DE: DEflag,
  GH: GHflag,
  GI: GIflag,
  GR: GRflag,
  GL: GLflag,
  GD: GDflag,
  GP: GPflag,
  GU: GUflag,
  GT: GTflag,
  GG: GGflag,
  GN: GNflag,
  GW: GWflag,
  GY: GYflag,
  US: USflag,
  IN: INflag,
  GB: GBflag,
  ZA: ZAflag,
  NG: NGflag,
  IT: ITflag,
  SA: SAflag,
  BG: BGflag,
  NL: NLflag,
  HU: HUflag,
  ID: IDflag,
IR: IRflag,
IQ: IQflag,
IE: IEflag,
IM: IMflag,
IL: ILflag,
CI: CIflag,
JM: JMflag,
JP: JPflag,
JE: JEflag,
JO: JOflag,
KZ: KZflag,
KE: KEflag,
KI: KIflag,
KW: KWflag,
KG: KGflag,
LA: LAflag,
LV: LVflag,
LB: LBflag,
LS: LSflag,
LR: LRflag,
LY: LYflag,
LI: LIflag,
LT: LTflag,
LU: LUflag,
MO: MOflag,
MK: MKflag,
MG: MGflag,
MW: MWflag,
MY: MYflag,
MV: MVflag,
ML: MLflag,
MT: MTflag,
MH: MHflag,
MQ: MQflag,
MR: MRflag,
MU: MUflag,
YT: YTflag,
MX: MXflag,
FM: FMflag,
MD: MDflag,
MC: MCflag,
MN: MNflag,
ME: MEflag,
MS: MSflag,
MA: MAflag,
MZ: MZflag,
MM: MMflag,
NA: NAflag,
NR: NRflag,
NP: NPflag,
NC: NCflag,
NZ: NZflag,
NI: NIflag,
NE: NEflag,
NU: NUflag,
NF: NFflag,
MP: MPflag,
NO: NOflag,
OM: OMflag,
PK: PKflag,
PW: PWflag,
PA: PAflag,
PG: PGflag,
PY: PYflag,
PE: PEflag,
PH: PHflag,
PN: PNflag,
PL: PLflag,
PT: PTflag,
PR: PRflag,
QA: QAflag,
RE: REflag,
RO: ROflag,
RU: RUflag,
RW: RWflag,
SH: SHflag,
KN: KNflag,
LC: LCflag,
PM: PMflag,
VC: VCflag,
WS: WSflag,
SM: SMflag,
ST: STflag,
SN: SNflag,
RS: RSflag,
SC: SCflag,
SL: SLflag,
SG: SGflag,
SK: SKflag,
SI: SIflag,
SB: SBflag,
SO: SOflag,
GS: GSflag,
KR: KRflag,
SS: SSflag,
ES: ESflag,
LK: LKflag,
SD: SDflag,
SR: SRflag,
SJ: SJflag,
SE: SEflag,
CH: CHflag,
SY: SYflag,
TJ: TJflag,
TZ: TZflag,
TH: THflag,
TL: TLflag,
TG: TGflag,
TK: TKflag,
TO: TOflag,
TT: TTflag,
TN: TNflag,
TR: TRflag,
TM: TMflag,
TV: TVflag,
UG: UGflag,
UA: UAflag,
AE: AEflag,
UY: UYflag,
UZ: UZflag,
VU: VUflag,
VE: VEflag,
VN: VNflag,
WF: WFflag,
EH: EHflag,
YE: YEflag,
ZM: ZMflag,
ZW: ZWflag, 
};


const GetCountryFlagByName = ({countryName}) => {
  const country = countryData.find((c) => c.name.toLowerCase() == countryName.toLowerCase());


  if (!country) {
    return <p></p>;
  }

  const FlagComponent = countryFlags[country.code];

  return (
    <div>
       {FlagComponent ? <FlagComponent /> : <p></p>}
    </div>
  )
}

export default GetCountryFlagByName


const TmFlag = (props) => (
  <svg
    width="1em"
    height="0.75em"
    xmlns="http://www.w3.org/2000/svg"
    id="flag-icons-tm"
    viewBox="0 0 640 480"
    {...props}
  >
    <path fill="#00843d" d="M0 0h640v480H0Z" />
    <path
      fill="#fff"
      d="M357.6 53A59.7 59.7 0 1 1 295 153.7a59.7 59.7 0 0 0 62.3-100.1ZM301 97l6.4-20.8-17.7 12.9h21.9l-17.8-12.9Zm0-30.6 6.4-20.8-17.7 12.9h21.9l-17.8-13.2ZM331.2 82l6.4-20.8-17.8 12.9h22L324 61.2Zm-61 30.3 6.9-20.8-17.8 12.8h22l-17.8-12.8Zm30.3 15.4 6.8-20.7-17.7 12.8h21.9L293.7 107z"
    />
    <path fill="#d22630" d="M86.6 0h129.6v480H86.6Z" />
    <g fill="#383739">
      <path d="M118 0h-4.2l-8.3 7.6-3-3.4h3l-.8-3h-4.9l-10.2 9V14h5v-3l2.2 2.2-5.3 4.6L94 20l-7.5 6.8v5L99 20.3l-2.2-2.6 4.9-4.6L97.5 9l2.7-3 5.6 5.2ZM87 0v8.7L89.5 6V2.3h3L95.3 0Zm22.6 0h-3l.4 2.3z" />
      <path
        fill="#ffc72c"
        d="M117.6 0h-3.8l-8 7.6-3.7-3.8h3.4l-.8-2.7h-4.5l-9.9 9.5v3h3.8V9.8l3.4 3.4-5.3 4.6 2.3 2.2-7.6 7.6v3.8L98.3 20 96 17.8l5.3-4.6-4.5-4.1 3.8-3.4 5.2 5.3 11.4-11Zm-31 0v8.3l2.2-2.6V1.9h3.4L94.5 0Zm22.6 0h-1.8v1.5z"
      />
    </g>
    <g fill="#383739">
      <path d="M86.6 55.6v4.9l7.2 6.8-2.7 2.2 5.3 5-2.3 1.8v-3h-4.5v3.8l10.2 9.5h5l.7-3.8H102l3-2.7 8.3 7.6-8.3 6.8-3-2.7h3l-.8-3.7h-4.9l-9.8 10.2v3.7h5v-3.7l2.2 2.2-5.3 4.6 2.6 2.6-7.5 6.8v4.6L99 107.7l-2.2-2.6 4.9-4.6-4.2-4.1 2.7-2.7 5.6 4.6 12.5-11.4-12.5-11.3-5.6 5.3-2.7-2.7 4.2-4.1-5-5L99 67 87 55.6zm0 22.6v18.2l2.6-3v-3.8h3l2.7-2.3-2.7-2.3h-3v-3.7zm20.8 6.5v4.9l2.6-2.3z" />
      <path
        fill="#ffc72c"
        d="M86.6 56.3v3.8l7.5 7.2-2.2 2.2 5.3 5-3.4 2.6v-3.4H90v3l9.8 9.5h4.5l.8-2.7h-3.8l4.2-3.8 8.7 7.6-8.7 7.6-3.8-3.8h3.4l-.8-2.3h-4.5l-9.8 9v3.1h3.8v-3.8l3.4 3.4-5.3 4.6 2.2 2.6-7.5 7.2v3.8l11.3-11.4-2.3-2.2 5.3-4.6-4.5-4.1 3.8-3.4 5.6 5.3 11.8-11-12.1-11-5.7 5.3-3.4-3 4.5-4.1-5.3-5 2.7-2.2zm0 22.7v16.6l2.2-2.2v-3.8h3.4l2.3-2.3-2.3-1.9h-3.4v-3.8zm21.1 6.4v3.4l2-1.5Z"
      />
    </g>
    <g fill="#383739">
      <path d="M86.6 145.9v5.3l7.2 6.8-2.7 2.3 5.3 4.5-2.3 2.3v-3h-4.5v3.7l10.2 9h5l.7-3H102l3-3 8.3 7.2-8.3 7.6-3-3.4h3l-.8-3.4h-4.9l-10.2 9.4v3.8h5.3v-3l2.3 1.9-5.3 4.9L94 198l-7.5 6.9v4.9L99 198.4l-2.2-2.6 4.9-5-4.2-3.7 2.7-3 5.6 4.9 12.5-11.4-12.5-11.3-5.6 5.3-2.7-3 4.2-3.8-5-5 2.3-2.2-12-11.3zm0 22.7v17.7l2.6-2.6v-3.8h3l2.7-2.3-2.7-2.6h-3v-3.4zm20.8 6.4v5l2.6-2.7z" />
      <path
        fill="#ffc72c"
        d="M86.6 146.3v3.7l7.5 7.6L92 160l5.3 4.5-3.4 3v-3.7H90v3.7l9.8 8.7h4.5l.8-2.6h-3.8l4.2-3.8 8.7 7.6-8.7 7.9-3.8-3.8h3.4l-.8-2.6h-4.5l-9.8 9.4v3h3.8v-3.7l3.4 3-5.3 4.9 2.2 2.3-7.5 7.5v3.8l11.3-11.3-2.3-2.3 5.3-5-4.5-3.7 3.8-3.8 5.6 6 11.8-11.3-12.1-10.5-5.7 5.2-3.4-3.4 4.5-3.7-5.3-5 2.7-2.2zm0 22.6v17l2.2-2.6v-3.8h3.4l2.3-1.9-2.3-2.2h-3.4v-3.8l-2.2-2.3zm21.1 6.8v3.4l2-1.8Z"
      />
    </g>
    <g fill="#383739">
      <path d="M86.6 236.2v4.6l7.2 6.8-2.7 2.2 5.3 5-2.3 2.2v-3h-4.5v3.8l10.2 9h5l.7-3.4H102l3-2.6 8.3 7.2-8.3 7.1-3-3h3l-.8-3.4h-4.9l-10.2 9.5v3.8h5.3v-3l2.3 1.8-5.3 5L94 288l-7.5 6.8v5L99 288.3l-2.2-2.7 4.9-4.9-4.2-3.8 2.7-3 5.6 5.3 12.5-11.3-12.5-11.4-5.6 5-2.7-2.7 4.2-3.8-5-5.3 2.3-2.2-12-11.4zm0 22.7v17.8l2.6-3v-3.5h3L95 268l-2.7-2.7h-3V262zm20.8 6.4v5l2.6-2.7z" />
      <path
        fill="#ffc72c"
        d="M86.6 236.6v3.8l7.5 7.5-2.2 2 5.3 4.8-3.4 3V254H90v3.4l9.8 9h4.5l.8-2.6h-3.8l4.2-3.8 8.7 7.6-8.7 8-3.8-3.9h3.4l-.8-2.6h-4.5l-9.8 9v3.5h3.8v-3.8l3.4 3-5.3 5L94 288l-7.5 7.6v3.7L97.9 288l-2.3-2.3 5.3-4.9-4.5-3.8 3.8-3.7 5.6 5.6 11.8-11.3-12.1-10.6-5.7 5.3-3.4-3.4 4.5-3.8-5.3-5.3 2.7-2.2zm0 22.7v17l2.2-2.7V270h3.4l2.3-2-2.3-2.2h-3.4V262l-2.2-2.2zm21.1 6.8v3.4l2-2Z"
      />
    </g>
    <g fill="#383739">
      <path d="M86.6 326.6v4.5l7.2 6.8-2.7 2.3 5.3 4.9-2.3 1.9v-3h-4.5v3.7l10.2 9.5h5l.7-3.4H102l3-3 8.3 7.5-8.3 7.2-3-3h3l-.8-3.4h-4.9l-10.2 9.4v3.8h5.3v-3.4l2.3 2.2-5.3 5 2.6 2.2-7.5 6.8v4.6L99 378.3l-2.2-2.2 4.9-5-4.2-4.1 2.7-2.7 5.6 5.3 12.5-11.3-12.5-11.3-5.6 4.9-2.7-2.7 4.2-4.1-5-5L99 338zm0 22.6V367l2.6-3v-3.4h3l2.7-2.7-2.7-2.2h-3v-3.8zm20.8 6.5v4.5l2.6-2.3z" />
      <path
        fill="#ffc72c"
        d="M86.6 327v3.7L94 338l-2.2 2.3 5.3 4.9-3.4 3v-3.8H90v3.4l9.8 9h4.5l.8-2.6h-3.8l4.2-3.7 8.7 7.5-8.7 8-3.8-3.8h3.4l-.8-2.7h-4.5l-9.8 9.1v3.4h3.8v-3.8l3.4 3-5.3 5 2.2 2.2-7.5 7.6v3.4L97.9 378l-2.3-2 5.3-4.9-4.5-4.1 3.8-3.4 5.6 5.3 11.8-11-12.1-11-5.7 5.4-3.4-3 4.5-4.2-5.3-5 2.7-2.2Zm0 22.6v17l2.2-2.3v-3.7h3.4l2.3-2.3-2.3-1.9h-3.4v-3.8l-2.2-2.6zm21.1 6.4v3.8l2-1.9Z"
      />
    </g>
    <g fill="#383739">
      <path d="M184.5 0h4.5l7.6 7.6 3.8-3.8h-3l.7-3h4.9l10.2 9v3.8H208v-3l-2.3 2.3 5.3 4.5-2.6 2.3 7.6 6.8v4.9L203.4 20l2.2-2.6-4.9-4.5 4.2-4.2-2.7-3-5.6 5.6Zm31.7 0v8.7l-3-2.7V2.3h-3L207.5 0Zm-23.8 0h3l-.3 2.3z" />
      <path
        fill="#ffc72c"
        d="M185.2 0h3l8.8 7.6 3.7-3.8h-3.4l.8-2.7h4.5l9.9 9.5v3h-3.8V9.8l-3 3.4 4.9 4.6-2.3 2.6 7.6 7.2v3.8L204.5 20l2.3-2.2-5.3-4.6 4.5-4.1-3.8-3.4-4.9 5.6L185 0Zm31 0v8.3l-2.6-2.6V1.9h-3.4l-2-1.9Zm-23.4 0h2.3v1.5z"
      />
      <path d="M216.2 55.6v4.9l-7.5 6.8 2.6 2.2-5.3 5 2.3 1.8v-3h4.5v3.8l-10.2 9.5h-4.9l-.7-3.8h3l-3-2.7-8 7.6 7.6 6.8 3.4-2.7h-3l.7-3.7h5l10.1 9.8v3.8H208v-3.4l-2.3 2.2 5.3 4.6-2.6 2.6 7.6 6.8v4.6l-12.5-11.4 2.2-2.6-4.9-4.6 4.2-4.1-2.7-2.7-5.6 4.6L184 86.9l12.5-11.3 5.6 5.3 2.7-2.7-4.2-4.1 5-5-2.3-2.2 12.5-11.3zm0 22.6v18.2l-3-3v-3.8h-3l-2.7-2.3 2.7-2.3h3v-3.7zm-21.1 6.5.3 2.6-.3 2.3-2.7-2.3z" />
      <path
        fill="#ffc72c"
        d="M216.2 56.3v3.8l-8.3 6.8 2.3 2.3-5 4.9 3 2.6v-3.4h3.9v3l-9.9 9.5h-4.5l-.7-2.7h3.7l-4.1-3.7-8.7 7.5 8.7 7.6 3.8-3.8H197l.7-2.3h4.5l9.9 9.1v3h-3.8v-3.7l-3 3.4 4.9 4.5-2.3 2.6 7.6 7.2v3.8L204 107l2.3-2.3-5.3-4.5 4.5-4.2-3.7-3.4-5.3 5.3-12.1-11 12-11 5.7 5.4 3.4-3-4.5-4.2 5.3-5-2.6-2.2zm0 22.7v16.6l-2.6-2.2v-3.8h-3.4l-2-2.3 2-1.9h3.4v-3.8zm-21.5 6.4.4 2v1.4l-2.3-1.5Z"
      />
      <path d="M216.2 145.9v5.3l-7.5 6.8 2.6 2.3-5.3 4.5 2.3 2.3v-3h4.5v3.7l-10.2 9h-4.9l-1.1-3h3l-3-3-7.6 6.8 7.6 7.6 3.4-3.4h-3l.7-3.4h5l10.1 9.4v3.8H208v-3l-2.3 1.9 5.3 4.9-2.6 2.3 7.6 6.8v4.9L203.4 198l2.2-2.6-4.9-5 4.2-3.7-2.7-3-5.6 5.3-12.5-11.4 12.5-11.3 5.6 5.3 2.7-3-4.2-3.8 5-5-2.3-2.2 12.5-11.3zm0 22.7v17.7l-3-2.6v-3.8h-3l-2.7-2.3 2.7-2.6h3v-3.4zm-21.1 6.4.3 2.3-.3 2.6-2.7-2.6z" />
      <path
        fill="#ffc72c"
        d="M216.2 146.3v3.7l-8 7.6 2.4 2.3-5 4.5 3 3v-3.7h3.9v3.7l-9.9 8.7h-4.5l-.8-2.6h3.8l-4.5-3.4-8.7 7.5 8.7 8 3.8-3.8H197l.7-2.7h4.5l9.9 9.5v3h-3.8v-3.8l-3 3 4.9 5-2.3 2.2 7.6 7.6v3.8L204 198l2.3-2.2-5.3-5 4.5-3.7-3.7-3.8-5.3 5.7-12.1-11.4 12-10.5 5.7 5.2 3.4-3.4-4.5-3.7 5.3-5-2.6-2.2zm0 22.6v17l-2.6-2.6v-3.8h-3.4l-2-1.9 2-2.2h3.4v-3.8l2.6-2.3zm-21.5 6.8.4 1.6v1.8l-2.3-1.8Z"
      />
      <path d="M216.2 236.2v4.6l-7.5 6.8 2.6 2.2-5.3 5 2.3 2.2v-3h4.5v3.8l-10.2 9h-4.9l-.7-3.4h3l-3-2.6-8 7.2 7.6 7.1 3.4-3h-3l.7-3.4h5l10.1 9.5v3.8H208v-3l-2.3 1.8 5.3 5-2.6 2.2 7.6 6.8v5l-12.5-11.4 2.2-2.7-4.9-4.9 4.2-3.8-2.7-3-5.6 5.3L184 268l12.5-11.4 5.6 5 2.7-2.7-4.2-3.8 5-5.3-2.3-2.2 12.5-11.4zm0 22.7v17.8l-3-3v-3.5h-3l-2.7-2.2 2.7-2.7h3V262zm-21.1 6.4.3 2.3-.3 2.6-2.7-2.6z" />
      <path
        fill="#ffc72c"
        d="M216.2 236.6v3.8l-8 7.5 2.4 2-5 4.8 3 3V254h3.9v3.4l-9.9 9h-4.5l-.8-2.6h3.8L197 260l-8.7 7.6 8.7 8 3.7-3.9h-3.4l.8-2.6h4.5l9.9 9v3.5h-3.8v-3.8l-3 3 4.9 5-2.3 2.2 7.6 7.6v3.7L204.5 288l2.3-2.3-5.3-4.9 4.5-3.8-3.8-3.7-5.2 5.6-12.1-11.3 12-10.6 5.7 5.3 3.4-3.4-4.5-3.8 5.3-5.3-2.7-2.2zm0 22.7v17l-2.6-2.7V270h-3.4l-2-2 2-2.2h3.4V262l2.6-2.2zm-21.5 6.8.4 1.5v1.9l-2.3-2Z"
      />
      <path d="M216.2 326.6v4.5l-7.5 6.8 2.6 2.3-5.3 4.9 2.3 1.9v-3h4.5v3.7l-10.2 9.5h-4.9l-.7-3.4h3l-3-3-8 7.5 7.6 7.2 3.4-3h-3l.7-3.4h5l10.1 9.4v3.8H208v-3.4l-2.3 2.2 5.3 5-2.6 2.2 7.6 6.8v4.6l-12.5-11.4 2.2-2.2-4.9-5 4.2-4.1-2.7-2.7-5.6 5.3-12.5-11.3 12.5-11.3 5.6 4.9 2.7-2.7-4.2-4.1 5-5-2.3-2.2zm0 22.6V367l-3-3v-3.4h-3l-2.7-2.7 2.7-2.2h3v-3.8zm-21.1 6.5.3 2.2-.3 2.3-2.7-2.3z" />
      <path
        fill="#ffc72c"
        d="M216.2 327v3.7L208 338l2.3 2.3-5 4.9 3 3v-3.8h3.9v3.4l-9.9 9h-4.5l-.7-2.6h3.7l-4.1-3.7-8.7 7.5 8.7 8 3.8-3.8H197l.7-2.7h4.5l9.9 9.1v3.4h-3.8v-3.8l-3 3 4.9 5-2.3 2.2 7.6 7.6v3.4L204 378l2.3-2-5.3-4.9 4.5-4.1-3.7-3.4-5.3 5.3-12.1-11 12-11 5.7 5.4 3.4-3-4.5-4.2 5.3-5-2.6-2.2zm0 22.6v17l-2.6-2.3v-3.7h-3.4l-2-2.3 2-1.9h3.4v-3.8l2.6-2.6zm-21.5 6.4.4 2v1.8l-2.3-1.9Z"
      />
    </g>
    <path
      fill="#ffc72c"
      d="M96.8 424.8c.7 2.7 3 4.6 3.8 7.6.7-3 1.5-5.7 1-8.7-.7-2.3-2.6-3.8-2.6-6.4-2.2 1.8-2.6 4.5-2.2 7.5zm-.4 11.4a17.8 17.8 0 0 0 7.2 5.6c-.8-2.2 0-3.8 1.5-5.6 1.1-2 1.9-3.8.7-6.5a14.4 14.4 0 0 1-1.8 3.4 10 10 0 0 0-2.3 5.3c-1.5-1.1-1.9-2.6-2.7-4.1a12.9 12.9 0 0 0-5.2-5.3c.7 2.6 1 4.9 2.6 7.2zm10.2 7.5-.8 3.8-4.1-3c-1.9-2-4.2-2.7-5.7-5a9 9 0 0 0 3.8 7.2l8 5c-.4-2.7.7-5 1.4-7.6a6.8 6.8 0 0 0-1.5-6c0 1.8-.7 3.7-1.1 5.2zm5.3 8.7 3 5.3c-2.6-.8-4.9-2.3-7.5-3.8-2.7-1.5-5.7-2.2-8.4-4.5a13 13 0 0 0 9.1 9c3.4.8 6 .4 9.8 2a26.5 26.5 0 0 0-2.6-7.6l-2.3-5.3a5.7 5.7 0 0 0-1.1 5zm9.8 7.2a12.1 12 0 0 0 3.8 3.8c-3.8 0-6.8-2-10.6-2.3-2.2 0-4.5.8-6.8.4 3 1.9 5.7 3.8 9 3.8 4.2.7 8.4 0 13.3 0a17.2 17.2 0 0 1-4.9-5.7c-1.9-3-3.8-5-7.2-5.3l3.8 5.3zm13.3 6c-6.5 1.2-12.9 2.7-19.7 2.7a30.3 30.3 0 0 0 22.3.4c3.8-1.2 7.2-2.3 10.2-2 4.5 0 9.8 2.3 15.9 8.4l2.3-1.9a36.3 36.3 0 0 0-31-7.6z"
    />
    <path
      fill="#383739"
      d="M102.8 56.3 92.2 50V34.8l10.6-6.5V20l9.5-5.6h8l15-8.7h31.8l15.1 8.7h7.6l10.6 5.6v8.3l10.2 6.5v15l-10.2 6.5v8l-9.9 6h-8l-15 8.3h-31.8l-15.1-8.3H113l-10.6-6z"
    />
    <path
      fill="#00843d"
      d="m135.3 6-15 8.7h-7.6l-9.9 5.7v8l-10.2 6.4v15l10.2 6.1v8.4l9.9 5.6h7.5l15.1 8.3h31.8l15.1-8.3h7.6l9.8-5.6v-8.4l10.2-6V34.8l-10.2-6.5v-7.9l-9.8-5.7h-7.6L167.1 6Zm.8 3.4h30.2l15.2 8.4h7.5l7.6 4.1v8.3l10.2 6.5V48l-10.6 6.4v8l-7.2 4.5h-7.5l-15.2 8.3h-30.2L121 67h-7.6l-7.6-4.5v-8L95.6 48V36.7l10.6-6.5V22l7.6-4.1h7.5z"
    />
    <path
      fill="#ffc72c"
      d="M151.2 42.3V9.4h-15.1l-14.8 8.7h-7.5l-7.6 3.8v8.3L96 36.7V42h55.2v32.8h15.1l15.2-8.3h7.5l6.8-4.1V54l10.6-6.4v-5.3z"
    />
    <path
      fill="#fff"
      d="M96 42.3v5.3l10.6 6.4v8.4l6.8 3.7h8l15 8.7h14.8V42h55.2v-5.3l-10.6-6.5v-7.5L189 18h-7.5l-15.2-8.7h-15v33Z"
    />
    <g fill="#383739">
      <path d="m118.7 21.2-2.6-2.7-2.3 1.5 2.6 2.7-3.7 1.9h7.5l6 6 2-2.3-6.1-5.6v-3.8Z" />
      <path d="m130.8 20-2.6-2.2-2.3 1.5 2.6 2.2-3.8 2h7.6l6 6 2-2.3-5.7-5.7v-3.7zm-12.9 7.6-2.6-2.3-2.6 2 2.6 2.2-3.4 1.9h7.6l6 6 1.9-2.3-6-5.6v-3.8z" />
      <path d="M151.2 9.4h-6.4l-3.4 3h-6.8l-4.6 2.7 1.2 2.7 4.1-2.3h2l6.7 7.6 7.2-4.2Z" />
      <path
        fill="#d22630"
        d="m116 18.9 2.3 2.3-1.9 1.1-2.2-1.9zm6.1.8v3l5.7 5.6-1.5 1.6-6-5.7h-6.1l7.5-5zm6-1.6 2.3 2-1.9 1.4-2.2-2.2Zm6.1 0V22l5.7 5.3-1.5 1.9-6-6.4h-6.1l7.5-4.6z"
      />
      <path
        fill="#d22630"
        d="m115.3 26 2.3 2-2 1.1-2.2-1.9Zm5.7 0v4l6 5.2-1.5 1.6-6-5.7h-6Zm30.2-16.6h-6.4l-3.4 3.8H135l-4.6 2 .8 2.2L135 15h2.6l6 7.6 7.6-3.8Z"
      />
      <path d="m118.7 63.5-2.6 2.3-2.3-1.5 2.6-2.3-3.7-2.3h7.5l6-5.7 2 2.3L122 62v3.8z" />
      <path d="m130.8 64.3-2.6 2.6-2.3-1.5 2.6-2.3-3.8-2.2h7.6l6-5.7 2 1.9-5.7 5.6v3.8zm-12.9-7.6-2.6 2.3-2.6-1.6 2.6-2.2-3.4-2.3h7.6l6-5.7 1.9 2-6 5.6v3.8z" />
      <path d="M151.2 75.2h-6.4l-3.4-3.4h-6.8l-4.6-2.3 1.2-3 4.1 2.3h2l6.7-7.6 7.6 4.6z" />
      <path
        fill="#d22630"
        d="m116 65.4 2.3-2.3-1.9-1.1-2.2 2.3zm6.1-.4v-3.4l5.7-5.7-1.5-1.5-6 5.7h-6.1l7.5 5zm6 1.5 2.3-2.2-1.9-1.2-2.2 2.3zm6.1-.4v-3.7l5.7-5.3-1.5-1.5-6 5.6h-6.1l7.5 5z"
      />
      <path
        fill="#d22630"
        d="m115.3 58.6 2.3-2.3-2-1.1-2.2 2.2Zm5.7-.4v-3.4l6-5.7-1.5-1.5-6 5.7h-6Zm30.2 16.6h-6.4l-3.4-3.4H135l-4.6-2.2.8-2 3.8 2h2.6l6.4-7.6 7.2 4.2z"
      />
      <path d="m114.5 31.7-3-1-4.1 2.6v.7l-6.5 8.3 6.5 8v.7l3.7 3 3.4-1-1.8-1.6v-2.3l.7-.7h3.4V36.3H113l-.3-.8V33Z" />
      <path
        fill="#d22630"
        d="m107.7 33.6 3.8-2.6 2.3.7-1.5 1.2v2.6l.7 1.2h3.4V48H113l-.7 1.1v2.7l1.5.7-2.3 1.2-3.8-2.7v-.7l-6.4-8 6.4-8.3Z"
      />
    </g>
    <g fill="#383739">
      <path d="m183.7 21.2 2.7-2.7 2.2 1.5-2.2 2.7 3 1.9h-7.2l-6 6-2-2.3 6.1-5.6v-3.8Z" />
      <path d="m171.6 20 2.7-2.2 2.2 1.5-2.2 2.2 3 2H170l-6 6-2-2.3 6.1-5.7v-3.7zm12.9 7.6 2.6-2.3 2.7 2-2.7 2.2 3.4 1.9H183l-5.7 6.4-1.9-2.3 6-5.6V26z" />
      <path d="M151.2 9.4h6.4l3.8 3h6.4l5 2.7-1.2 2.7-4.5-2.3h-1.9l-6.8 7.6-7.2-4.2Z" />
      <path
        fill="#d22630"
        d="m186.4 18.9-2.3 2.3 1.9 1.1 2.3-1.9zm-5.7.8v3l-6 5.6 1.5 1.6 6-5.7h6l-7.5-5zm-6.8-1.6-2.3 2 2 1.4 2.2-2.2Zm-5.7 0V22l-5.6 5.3 1.5 1.9 6-6.4h6l-7.5-4.6z"
      />
      <path
        fill="#d22630"
        d="m187.1 26-2.2 2 1.8 1.1 2.3-1.9Zm-5.6 0v4l-6.1 5.2 1.5 1.6 6-5.7h6.1ZM151.2 9.5h6.4l3.4 3.8h6.5l4.5 2-.8 2.2-3.7-2.3h-2.3l-6.8 7.6-7.2-3.8Z"
      />
      <path d="m183.7 63.5 2.7 2.3 2.2-1.5-2.2-2.3 3-2.3h-7.2l-6-5.7-2 2.3 6.1 5.7v3.8z" />
      <path d="m171.6 64.3 2.7 2.6 2.2-1.5-2.2-2.3 3-2.2H170l-6-5.7-2 1.9 6.1 5.6v3.8zm12.9-7.6L187 59l2.7-1.6-2.7-2.2 3.4-2.3H183l-6-5.7-2 2 6 5.6v3.8z" />
      <path d="M151.2 75.2h6.4l3.8-3.4h6.4l5-2.3-1.2-3-4.5 2.3h-1.9l-6.8-7.6-7.2 4.6z" />
      <path
        fill="#d22630"
        d="M186.4 65.4 184 63l2-1 2.3 2.3zm-5.7-.4v-3.4l-6-5.7 1.5-1.5 6 5.7h6zm-6.4 1.5-2.3-2.2 1.9-1.2 2.3 2.3zm-5.7-.4v-3.7l-6-5L164 56l6 5.7h6l-7.5 5z"
      />
      <path
        fill="#d22630"
        d="m187.1 58.6-2.2-2.3 1.8-1.1 2.3 2.2zm-5.6-.4v-3.4l-6.1-5.7 1.5-1.5 6 5.7h6.1Zm-30.3 16.6h6.4l3.4-3.4h6.5l4.5-2.2-.8-2-3.7 2h-2.3l-6.8-7.6-7.2 4.2z"
      />
      <path d="m187.9 31.7 3-1 4.2 2.6v.7l6.4 8.3-6.4 8v.7l-3.8 3-3.4-1 1.9-1.6v-2.3l-.8-.7h-3.4V36.3h3.8l.4-.8V33Z" />
      <path
        fill="#d22630"
        d="m194.7 33.6-3.8-2.6-2.3.7 1.5 1.2v2.6l-.7 1.2H186V48h3.4l.7 1.1v2.7l-1.5.7 2.3 1.2 3.8-2.7v-.7l6.4-8-6.4-8.3Z"
      />
    </g>
    <path
      fill="#383739"
      d="m108.9 49.1-1.5-1.5V47l-3.8-4.6 3.8-4.5V37l1.5-1.5 3.4 2.7h-2l2.7 2.6h6l5-3.8v-9.8h11.7l14-8.7v-2.2l-2.3-1.2-2.6 2v1l-2-2.6 3.9-3 4.1-2.7h1.5l3.8 2.7h.8l3.8 3-2 2.6-.3-1-2.7-2h-.7l-2.3 1.5V19l14 8.7h12.1v9.8l4.5 3.8h6l2.7-2.6h-1.5l3-2.7 1.6 1.5v.8l3.7 4.5-3.7 4.5v.8l-1.6 1.5-3-2.6h1.5l-2.6-2.7h-6l-4.6 3.8v9.8h-12.5l-14 8.3v2.3l2.3 1.5h.4l2.6-1.5v-1.1l2 2.6-3.5 2.7h-.4l-4.1 3H150l-3.8-3h-.8l-3.7-3 2.2-2.3v1.1l2.7 1.5h.4l2.2-1.5v-2.3l-14-8.3h-11.7V48l-4.9-3.8h-6L110 47h1.9z"
    />
    <path
      fill="#ffc72c"
      d="m151.2 70 1.9 1-1.9 1.2zm-4.5 1.8 3.8 3h.7v-2.2L149 71l2.3-1.6V65l-15.1-9h-11v-9.1l-5.6-4.6h-8.7l-2 2.7-2.2-2.7H104l3.7 4.2v.7l1.2 1.6 2.2-2h-1v-.3l2.6-3h6.4l5.3 3.7v9.9h11.3l14.4 8.7v2.6l-2.3 1.5h-1.1l-2.3-1.5v-.7l-1.5 1.5 3 2.6zm-37.8-31.7 1.5 1.9H107zm42.3-27.6-1.5 1.1 1.5 1.1zM192 42.7l1.5 1.5 2-1.9zm-36.3-30.2-3.7-3h-.8v2.2l2.3 2-2.3 1.4V19l15.1 9h11v9.9L183 42h8.7l1.8-2.3 2.3 2.3h2.7l-3.8-3.8V37l-1.2-1-2.2 1.8h1.1l-2.6 3.4h-6.5l-4.9-3.8V27.2h-12l-14-8.3v-2.6l2.2-1.6h1.1l2.3 1.6v.7l1.5-1.9-3-2.6z"
    />
    <path
      fill="#fff"
      d="m151.2 72.2-1.5-1.1 1.5-1.2zm-40.8-29.9-1.5 2-2-2zm45.3 29.5-3.7 3h-.8v-2.2l2.3-1.5-2.3-1.6V65l15.1-9h11v-9.1l5.7-4.6h8.7l1.8 2.7 2.3-2.7h2.7l-3.8 4.2v.7l-1.2 1.6-2.2-2h1.1v-.3l-2.6-3h-6.5l-4.9 3.7v9.9h-12L152 65.8v2.6l2.2 1.5h1.2l2.2-1.5v-.7l1.5 1.5-3 2.6zm37.8-31.7L192 42h3.4zm-46.8-27.6 3.8-3h.7v2.2l-2.3 2 2.3 1.4V19l-15.1 9h-11v9.9l-5.6 3.8h-8.7l-2-2.3-2.2 2.3H104l3.7-3.8v-1.1l1.2-1.2 2.2 2h-1l2.6 3.3h6.4l5.3-3.8v-9.8h11.3l14.8-8.3v-2.6l-2.3-1.6H147l-2.3 1.6v.7l-1.5-1.9 3-2.6h.8zm4.5 2.6 1.9-1.1-1.9-1.1z"
    />
    <path
      fill="#00843d"
      d="M151.2 42.3V19.7l-15.1 9h-10.6v9.1l-5.3 4.2h31v22.6l15.1-9H177v-8.7l5.3-4.6z"
    />
    <path
      fill="#d22630"
      d="m120.2 42.3 5.3 4.6v8.7h10.6l15.1 9V42h31l-5.3-4.2v-9h-10.6l-15-9.1v22.6z"
    />
    <path
      fill="#383739"
      d="m135.3 31.4-2.6-2.7-2.3 2 2.3 2.2-3 1.9h7.1l6 6 2-2.2-6-5.7V29zm31.8 21.9 2.6 2.3 2.7-1.6-2.7-2.2 3-2.3h-6.4l-6.4-5.7-1.9 2 6 5.6v3.8zm-31.8 0-2.6 2.3-2.3-1.6 2.3-2.2-3-2.3h7.1l6-5.7 2 2-6 5.6v3.8zm31.8-22 2.6-2.6 2.7 2-2.7 2.2 3 1.9h-6.4l-6.4 6-1.9-2.2 6-5.7V29z"
    />
    <path
      fill="#d22630"
      d="m169.7 55.2-2.2-2.3 1.9-1.1 2.2 2.2zm-5.6-.4v-3.4l-6-5.7 1.4-1.5 6 5.7h6.1zm-31.8-25.3 2.3 1.9-2 1.1-2.2-1.9zm5.7 0v3.8l6 5.3-1.5 1.5-6-5.7h-6l7.5-4.5z"
    />
    <path
      fill="#00843d"
      d="m169.7 29.5-2.2 1.9 1.9 1.1 2.2-1.9zm-5.6 0v3.8l-6 5.3 1.4 1.5 6-5.7h6.1zm-31.8 26 2.3-2.2-2-1.1-2.2 2.2zm5.7-.3v-3.4l6-5.7-1.5-1.5-6 5.7h-6z"
    />
    <path fill="#383739" d="M167 42.3 151.3 53l-15.9-10.6 16-10.6Z" />
    <path
      fill="#fff"
      d="M151.2 32.1 136.1 42h30.2l-15-9.9v20l15-9.8h-30.2l15.1 9.9z"
    />
    <path
      fill="#383739"
      d="M155.4 45.7v3h-8v-3h-5.6v-7.1h5.6v-3h7.6v3h5.7V46z"
    />
    <path
      fill="#d22630"
      d="M147.4 36v3h-5.6v3h9.4v-6zm3.8 6h9.5v-3H155v-3h-3.8zm-9.4.3v3.4h5.6v2.7h3.8v-6zm9.4 6h3.8v-2.6h5.7v-3.4h-9.5z"
    />
    <path
      fill="#383739"
      d="M178.4 162.9v-4.5h12.9v-5h12.5v-41.9h-12.5v-5h-12.9V102h-12.8v-4.5h-28.8v4.5H124v4.6h-13v4.9H98.7v42H111v5.2h13v3.8h12.8v4.6h28.8v-4.6z"
    />
    <path
      fill="#ffc72c"
      d="M137.2 97.9v4.1h-12.5v4.6H112v4.9H99.4v41.6H112v4.5h12.8v5h12.9v4.5h28v-4.6h12.8V158h12.9v-5H204v-41.5h-12.8V107h-13.6v-4.6h-12.9V98zm3.8 3.4h20.8v4.5h12.8v4.6h12.9v4.9h12.9v34.4h-12.9v5.3h-12.9v3.7h-12.8v4.6h-21.2v-4.6h-12.8V155h-12.9v-5h-12.5v-34.3H115v-5h12.9v-4.9h12.8z"
    />
    <path
      fill="#d22630"
      d="M128.5 158.7v-4.5h-13.2v-4.5h-12.9v-34h12.9v-5h13.2v-4.5h12.9v-4.5h20.4v4.1h12.8v4.6h12.9v4.9h12.9v34h-12.9v4.5h-13.6v5H161v4.9h-20.4v-5z"
    />
    <path
      fill="#383739"
      d="m131.2 109.6-3.4-2.6v4.9l-3 1.9v-3l-3 3-3.1-3v3l-3.8-2v4.2l-2.6 2.3v-2.6l-3.8 2.2-3-2.2v2.6L102 116v13.3h-3.8v6.8h3.8v13.2l3.4-2.3v3l3.4-2.6 3.4 2.6v-3l2.6 2.3v4.5l3.8-2.2v3l3-3 3 3v-3.4l3 1.9v4.9l3.5-2.7v3.4l3.4-3 3 3V155l3 2.2v5.3l4.6-4.1v4.5l6-3.8 6 3.8v-4.2l4.6 3.8v-4.9l3-2.3v3.4l3-3 3.8 3V155l3 2.6v-5l3-1.8v3l3.1-3 3 3v-2.6l3.8 2.2V149l2.6-2.3v3l3.8-2.6 3 2.7v-3l3.5 2.2v-12.8h3.7v-6.5h-3.7v-13.2l-3.4 2.3V116l-3.5 2.3-3.4-2.3v2.7l-2.6-2.3v-4.1l-3.8 1.8v-3l-3 3-3-3v3l-3-1.8v-5l-3.5 2.7v-3.4l-3 3-3.8-3v3.4l-2.6-2.3v-4.9l-4.5 3.8V102l-6 3.8-6.1-3.8v3.8l-4.6-3.8v5l-3 2.2v-3.4l-3 3-3.4-3z"
    />
    <path
      fill="#00843d"
      d="M105.8 119v-3l3 2.3 3.1-2.3v3l3-2.6v32.1l-3-2.2v2.6l-3-2.3-3 2.3v-2.6l-3.5 2.2v-13.2h-3.7v-5.7h3.7v-13.2zm25.8-12 3 2.6 2.6-2.6v3.4l3.4-2.7V157l-3.4-2.3v3.4l-2.6-2.7-3 2.7v-3.4l-3.4 2.6v-49.1l3.4 2.3zm39.3 0-2.7 2.6-3-2.6v3.4l-3.4-2.7V157l3.4-2.3v3.4l3-2.7 2.7 2.7v-3.4l3.4 2.6v-49.1l-3.4 2.3zm25.7 12v-3l-3 2.3-3-2.3v3l-3.1-2.6v32.1l3-2.2v2.6l3-2.3 3 2.3v-2.6l3.5 2.2v-13.2h3.8v-5.7H200v-13.2z"
    />
    <path
      fill="#ffc72c"
      d="m119 111.9 2.7 2.2 2.7-2.2v2.6l3.8-2.2v40.4l-3.8-2.3v3l-2.7-2.6-2.6 2.6v-3l-3.8 2.3v-40.4l3.8 2.2zm26.5-5-4.5-3.7v59l4.5-4.2v4.5l5.7-3.8 5.7 3.8v-4.9l4.5 3.8v-57.8l-4.5 3.7V102l-5.7 3.8-5.7-3.8zm37.8 5-2.6 2.2-2.6-2.2v2.6l-3.8-2.2v40.4l3.8-2.3v3l2.6-2.6 3 2.6v-3l3.4 2.3v-40.4l-3.8 2.2z"
    />
    <g fill="#383739">
      <path d="m107.4 126.6 1.5.8c.3 0 1.1 0 1.5-.8 1.1-1.1 1.1-3.4 1.1-4.9l-1.9 1.9-.7-1.9-1.2 1.9-1.5-1.9c0 1.5 0 3.8 1.2 5zm13.6-3.4-1.2-1.1v-2l-2.2 2.4 3.7 3.7 4.6-3.7-2.3-2.3v1.9l-1.1 1.1v-3.8l1.9-1.5-2.7-2.2-2.6 2.2 1.9 2zm12.4-3.8-.7-1.1V116l-2.7 2.3 4.6 4.2 3.8-3.8-2.3-2.7v2.3l-.8.8v-3.8l2-1.2-2.7-2.2-2.7 2.2 1.5 1.6z" />
      <path
        fill="#fff"
        d="m108.9 122.5.7 1.5 1.5-1.5c0 1.8-.3 3.4-1 3.7a1.5 1.5 0 0 1-2.4 0c-.7-.3-1.1-1.9-1.1-3.7l1.5 1.5zm26-7.2v4.9l1.6-1.9v-1.1l1.5 1.5-3.8 3.8-3.8-3.8 2-1.5v1.5l1.4 1.9v-5l-1.5-1 2.3-2 1.9 2z"
      />
      <path
        fill="#d22630"
        d="M122.1 119.4v4.6l1.9-1.5v-1.6l1.5 1.6-3.8 3.7-3.8-3.7 1.6-1.6v1.6l1.5 1.5v-4.6l-1.5-1.5 2.2-1.5 2 1.5z"
      />
      <path d="m195 126.6-1.5.8c-.3 0-.7 0-1-.8-1.6-1.1-1.6-3.4-1.6-4.9l1.9 1.9.7-1.9 1.2 1.9 1.5-1.9c0 1.5 0 3.8-1.1 5zm-13.2-3.4.8-1.1v-2l2.3 2.4-3.8 3.7-4.6-3.7 2.3-2.3v1.9l1.1 1.1v-3.8l-1.8-1.5 2.6-2.2 2.6 2.2-1.5 2zm-12.8-3.8.7-1.1V116l2.7 2.3-4.2 4.2-4.5-3.8 2.6-2.7v2.3l.8.8v-3.8l-1.5-1.2 2.6-2.2 2.7 2.2-2 1.6z" />
      <path
        fill="#fff"
        d="m193.5 122.5-.7 1.5-1.5-1.5c0 1.8.4 3.4 1.1 3.7a1.5 1.5 0 0 0 2.3 0c.7-.3 1.1-1.9 1.1-3.7l-1.1 1.5zm-26-7.2v4.9l-1.5-1.9v-1.1l-1.6 1.5 3.8 3.8 3.8-3.8-1.9-1.5v1.5l-1.5 1.9v-5l1.5-1-1.9-2-2.2 2z"
      />
      <path
        fill="#d22630"
        d="M180.3 119.4v4.6l-1.5-1.5v-1.6l-1.5 1.6 3.8 3.7 3.8-3.7-2-1.6v1.6l-1.4 1.5v-4.6l1.5-1.5-2.3-1.5-1.9 1.5z"
      />
      <path d="m156.1 121-1.1-2.3 3.4-3-3.4-2.7v1.9l-1.1 1.1v-3.7l3-2-5.7-4.5-5.7 3.8 3 2.7v3.4l-1-1.2v-1.9l-3.5 2.7 3.8 3-1.5 1.9z" />
      <path
        fill="#d22630"
        d="m147 120.6 1.2-2-3.8-3 2.7-1.8v1.1l1.8 1.9v-4.5l-2.6-2 5-3.7 4.8 3.8-2.6 1.9v4.9l1.9-2v-1l2.6 1.8-3.8 3 1.5 2z"
      />
      <path d="m148.6 110.4 2.6 1.9 2.7-2-2.7-1.8Z" />
      <path fill="#00843d" d="m151.2 108.9 1.9 1.5-1.9 1.1-1.9-1.1z" />
    </g>
    <g fill="#383739">
      <path d="M107.4 138.3a1.9 1.9 0 0 1 1.5-.7c.3 0 1.1 0 1.5.7 1.1 1.2 1.1 3.4 1.1 5l-1.9-1.6-.7 1.5-1.2-1.5-1.5 1.5c0-1.5 0-3.7 1.2-4.9zm13.6 3.4-1.2 1.2v1.9l-2.2-2.3 3.7-3.8 4.6 3.8-2.3 2.3v-2l-1.1-1v3.7l1.9 1.5-2.7 2.3-2.6-2.3 1.9-1.9zm12.4 3.8-.7 1.1v2.3l-2.7-2.3 4.6-4.1 3.8 4.1-2.3 2.3v-2.3l-.8-.7v3.8l2 1.1-2.7 2.3-2.7-2.3 1.5-1.5z" />
      <path
        fill="#fff"
        d="m108.9 142.5.7-1.5 1.5 1.5c0-1.9-.3-3.4-1-3.8a1.5 1.5 0 0 0-2.4 0c-.7.4-1.1 1.9-1.1 3.8l1.5-1.5zm26 7.2v-5l1.6 2v1.5l1.5-1.6-3.8-3.7-3.8 3.7 2 1.6v-1.6l1.4-1.8v4.9l-1.5 1.1 2.3 1.9 1.9-1.9z"
      />
      <path
        fill="#d22630"
        d="M122.1 145.5V141l1.9 1.5v1.5l1.5-1.5-3.8-3.8-3.8 3.8 1.6 1.5v-1.5l1.5-1.5v4.5l-1.5 1.5 2.2 1.5 2-1.5z"
      />
      <path d="M195 138.3a1.9 1.9 0 0 0-1.5-.7c-.3 0-.7 0-1 .7-1.6 1.2-1.6 3.4-1.6 5l1.9-1.6.7 1.5 1.2-1.5 1.5 1.5c0-1.5 0-3.7-1.1-4.9zm-13.2 3.4.8 1.2v1.9l2.3-2.3-3.8-3.8-4.6 3.8 2.3 2.3v-2l1.1-1v3.7l-1.8 1.5 2.6 2.3 2.6-2.3-1.5-1.9zm-12.8 3.8.7 1.1v2.3l2.7-2.3-4.2-4.1-4.5 4.1 2.6 2.3v-2.3l.8-.7v3.8l-1.5 1.1 2.6 2.3 2.7-2.3-2-1.5z" />
      <path
        fill="#fff"
        d="m193.5 142.5-.7-1.5-1.5 1.5c0-1.9.4-3.4 1.1-3.8a1.5 1.5 0 0 1 2.3 0c.7.4 1.1 1.9 1.1 3.8l-1.1-1.5zm-26 7.2v-5l-1.5 2v1.5l-1.6-1.6 3.8-3.7 3.8 3.7-1.9 1.6v-1.6l-1.5-1.8v4.9l1.5 1.1-1.9 1.9-2.2-1.9z"
      />
      <path
        fill="#d22630"
        d="M180.3 145.5V141l-1.5 1.5v1.5l-1.5-1.5 3.8-3.8 3.8 3.8-2 1.5v-1.5l-1.4-1.5v4.5l1.5 1.5-2.3 1.5-1.9-1.5z"
      />
      <path d="m156.1 144.4-1.1 1.9 3.4 3-3.4 2.6V150l-1.1-1v3.7l3 1.9-5.7 4.1-5.7-3.7 3-2.7V149l-1 1.1v2l-3.5-2.7 3.8-3-1.5-2z" />
      <path
        fill="#d22630"
        d="m147 144.8 1.2 1.5-3.8 3 2.7 1.9V150l1.8-1.8v4.5l-2.6 1.9 5 3.8 4.8-3.8-2.6-2v-4.4l1.9 1.8v1.2l2.6-2-3.8-3 1.5-1.4z"
      />
      <path d="m148.6 155 2.6-2.3 2.7 1.9-2.7 1.9Z" />
      <path fill="#00843d" d="m151.2 156 1.9-1.4-1.9-1.2-1.9 1.2Z" />
    </g>
    <path
      fill="#383739"
      d="M196.6 130.4h-5.3v-1.9h-17.4l-12.1-7.6h-21.2l-12 7.6H111v2.3h-5.3v3.8h5.3v2.6h17.4l12.1 7.6h21.2l12-7.6h17.5v-2.3h5.3z"
    />
    <path
      fill="#d22630"
      d="m140.6 121-12 7.5h-17v2.3h-5.4v3.4h5.3v2.2h17l12.1 7.6h21.2l12-7.6H191v-2.2h5.7v-3.4H191v-2.3h-17l-12.1-7.6zm0 1h20.8l11.4 7.3v1h12v4.6h-12.4v1.2l-11 6.8H141l-11.3-6.8v-1.2h-12.1v-4.5H130v-1.1z"
    />
    <path
      fill="#d22630"
      d="m161.4 122 10.6 7.6v.8h12.5v3.8H172v1.5l-10.6 7.2H141l-10.6-7.2v-1.1H118v-3.8h12.5v-1.5L141 122z"
    />
    <g fill="#383739">
      <path d="m135.7 136.4 4.5 3.8 3-2.6-1.4-1.2-1.6.8-1-.8 17.3-13.2h-3.4zm10.2 5.3h3.4l17.4-13.2-4.5-3.8-3 2.7 1.8 1.1 1.2-.8 1.1.8zm7.6 0h3l-17.4-13.2 1.1-.8 1.6.8 1.5-1.1-3-2.3-4.6 3.4Zm9.8-5.3-1.1.8-1.2-.8-1.9 1.2 3 2.2 4.6-3.4-17.4-13.2h-3.8zm-31-3.7 3 1.9 3-2-3-2.2zm15.9-.4 3 2.3 3-2-3-2.2Zm22 0-3.1 2.3-3-2 3-2.2z" />
      <path
        fill="#ffc72c"
        d="m166.3 136.4-4.1 3-2.7-1.8 1.5-.8 1.2.8 1.5-1.2-17-12.8h2.6zm-10.6 5h-2.6l-17-12.9 4.1-3 2.7 1.9-1.1.7-1.6-.7-1.5 1.1zm-6.4 0h-2.6l17-12.9-1.5-1.1-1.2.7-1.5-.7 2.7-2 3.8 3.1zm3.8-17.8h2.6l-17 12.8 1.5 1.2 1.6-.8 1 .8-2.6 1.9-3.7-3z"
      />
      <path
        fill="#00843d"
        d="m135.3 130.8 2.3 1.9-2.3 1.5-2.6-1.5zm16 0 2.6 1.9-2.7 1.5-2.6-1.5zm15.8 0-2.3 1.9 2.3 1.5 2.6-1.5z"
      />
    </g>
    <path
      fill="#383739"
      d="m96.4 219.2-1.1 1.9 2.2 1.5-2.2 1.9 1.1 1.5 2.6.4 2.3-1.9 6.4 3.8v-3l-3.7-2.7 3.7-2.3V217l-6.4 3.8-2.3-1.5zm0-11.3-1.1 1.9 2.2 1.5-2.2 1.9 1.1 1.5 2.6.4 2.3-2 6.4 3.8v-3l-3.7-2.6 3.7-2.3v-3.4l-6.4 3.8-2.3-1.5zm0 29.5-1.1-1.6 2.2-1.8-2.2-1.6 1.1-1.8H99l2.3 1.5 6.4-3.8v3.4L104 234l3.7 2.6v3l-6.4-3.8-2.3 2zM206 219.2l1.2 1.9-2.3 1.5 2.3 1.9-1.2 1.5-2.6.4-2.3-1.9-6.4 3.8v-3l3.8-2.7-3.8-2.3V217l6.4 3.8 2.3-1.5zm0-11.3 1.2 1.9-2.3 1.5 2.3 1.9-1.2 1.5-2.6.4-2.3-2-6.4 3.8v-3l3.8-2.6-3.8-2.3v-3.4l6.4 3.8 2.3-1.5zm0 29.5 1.2-1.6-2.3-1.8 2.3-1.6-1.2-1.8h-2.6L201 232l-6.4-3.8v3.4l3.8 2.3-3.8 2.6v3l6.4-3.8 2.3 2zM181.5 189v4.5h3.4v2.3h3.4V192h-3v-3Zm9.8 7.5v3.8h3.8v1.9h3v-3.8h-3v-1.9ZM121 189v4.5h-3.4v2.3h-3.4V192h3v-3Zm60.5 68v-4.5h3.4v-2h3.4v3.9h-3v3zm-60.5 0v-4.5h-3.4v-2h-3.4v3.9h3v3zm70.3-7.6V245h3.8v-2.3h3v3.8h-3v3ZM111 196.5v3.8h-3.7v1.9h-3v-3.8h3v-1.9Zm0 53.3v-4.5h-3.7V243h-3v3.8h3v3zm53-60.8-2.7-2.7-2.6 2.3 2.2 2.3-3 2.6h4.2l1.5-1.5 1.5 1.5h5L167 191l2.3-2.3-2.7-2.3zm-12.9 0-2.6-2.7-2.7 2.3 2.3 2.3-2.7 2.6h4.2l1.5-1.5 1.5 1.5h4.2l-2.7-2.6 2.3-2.3-2.6-2.3zm-12.5 0 2.7-2.7 2.3 2.3-2.3 2.3 3 2.6h-4.2l-1.5-1.5-1.5 1.5h-4.9l3-2.6-2.2-2.3 2.6-2.3 2.7 2.7zm25 68-2.3 3-2.6-2.6 2.2-1.9-3-3h4.2l1.5 1.5 1.5-1.5h5l-3.1 3 2.3 1.9-2.7 2.6zm-12.5 0-2.6 3-2.7-2.6 2.3-1.9-2.7-3h4.2l1.5 1.5 1.5-1.5h4.2l-2.7 3 2.3 1.9-2.6 2.6zm-12.5 0 2.7 3 2.3-2.6-2.3-1.9 3-3h-4.2l-1.5 1.5-1.5-1.5h-4.9l3 3-2.2 1.9 2.6 2.6 2.7-2.6z"
    />
    <path
      fill="#00843d"
      d="M107.4 225.3v2.6l-6-3.8-2.4 2h-2.2l-1.2-1.6 2.3-1.9-2.3-1.5 1.2-1.5H99l2.3 1.9 6-3.8v2.3l-4.1 2.6zm0-11.4v2.7l-6-3.8-2.4 1.9h-2.2l-1.2-1.5 2.3-2-2.3-1.4 1.2-1.5H99l2.3 1.8 6-3.7v2.2l-4.1 2.7zm0 17.4V229l-6 3.8-2.4-2.2h-2.2l-1.2 1.5L98 234l-2.3 1.8 1.2 1.2H99l2.3-1.5 6 3.7v-2.6l-4.1-2.6zm87.7-6v2.6l6-3.8 2.7 2h1.8l1.2-1.6-2.3-1.9 2.3-1.5-1.2-1.5h-1.5l-2.6 1.9-6-3.8v2.3l4.1 2.6zm0-11.4v2.7l6-3.8 2.7 1.9h1.8l1.2-1.5-2.3-2 2.3-1.4-1.2-1.5h-1.5l-2.6 1.8-6-3.7v2.2l4.1 2.7zm0 17.4V229l6 3.8 2.7-2.2h1.8l1.2 1.5-2.3 1.9 2.3 1.8-1.2 1.2h-1.5l-2.6-1.5-6 3.7v-2.6l4.1-2.6zm-9.9-42.3v3h3v3h-3v-2.2h-3.7V189zm10.2 7.5v2.7h2.7v3h-2.7v-1.9H192v-3.8zm-78.2-7.5v3h-3v3h2.6v-2.2h3.8V189zm68 67.6v-3.4h3v-3h-3v1.9h-3.7v3.8zm-67.3 0v-3.4h-3v-3h2.7v1.9h3.7v3.8zm77.5-7.5v-3h2.7v-3h-2.7v2.2H192v3.8zm-87.7-52.6v3h-3v3h2.7v-2.2h3.4v-3.8zm0 53v-3h-3v-3.1h2.7v2.3h3.4v3.7zm53.7-62.8 2.3 2.7 2.6-2.7 2.3 1.9-2.3 2.3 2.7 2.2h-3.8l-1.5-1.5-1.5 1.5h-3.4l2.6-2.2-2.3-2.3zm-12.8 0 2.6 2.7 2.7-2.7 1.8 1.9-2.2 2.3 2.6 2.2h-3.4l-1.5-1.5-1.5 1.5h-3.8l3-2.2-2.2-2.3zm-7.6 0-2.3 2.7-2.6-2.7-2.3 1.9 2.3 2.3-2.3 2.2h3l2-1.5 1.4 1.5h3.5L141 191l2.3-2.3zm20.4 72.6 2.3-2.3 2.6 2.3 2.3-2-2.3-1.8 2.7-2.6h-3.8l-1.5 1.5-1.5-1.5h-3.4l2.6 2.6-2.3 1.9zm-12.8 0 2.6-2.3 2.7 2.3 1.8-2-2.2-1.8 2.6-2.6h-3.4l-1.5 1.5-1.5-1.5h-3.8l3 2.6-2.2 1.9zm-7.6 0-2.3-2.3-2.6 2.3-2.3-2 2.3-1.8-2.3-2.6h3l2 1.5 1.4-1.5h3.5l-2.7 2.6 2.3 1.9z"
    />
    <path
      fill="#383739"
      d="M117.2 245.3v7.6h68v-7.6h9.9v-45h-9.9v-7.5h-68v7.5h-9.8v45z"
    />
    <path
      fill="#ffc72c"
      d="M117.6 193.1v7.6h-9.9v44.2h9.5v7.6h68v-7.6h9.9v-44.6h-9.9v-7.5h-68zm4.5 3h58.2v7.2l2.7 1.6h9v35.5h-9l-2.7 1.9v7.1h-58.2V242l-2.6-1.5h-9.1V205h9l2.7-1.6v-7.5z"
    />
    <path
      fill="#d22630"
      d="M122.5 196.5v6.5l8.7-6.5zm49.1 0 8.3 6.5v-6.5zm-60.5 8.7v6.5l8-6zm72.2 0 8.4 6.5v-6.5zm8.4 28.8-8.4 6h8.4zm-81 0v6h8.4zm11.4 8.6v6.5h9zm57.8 0-8.7 6.5h8.7z"
    />
    <path
      fill="#00843d"
      d="m170.9 196.5 20.8 15.2v21.9l-20.8 15h-39.3l-20.8-15v-22l20.8-15.4z"
    />
    <path
      fill="#383739"
      d="m115.7 235.8-2.3-2.6 2.3-2.6-2.3-2.7 2.3-2.6-2.3-2.7 2.3-2.6-2.3-2.7 2.3-2.6-2.3-2.3 2.3-3h71l2.3 3-2.3 2.3 2.3 2.6-2.3 2.7 2.3 2.6-2.3 2.7 2.3 2.6-2.3 2.7 2.3 2.6-2.3 2.6zm49.1-35.9-3-2.6-2.7 2.3-2.6-2.3-2.6 2.3-2.7-2.3-2.6 2.3-2.7-2.3-2.6 2.3-2.7-2.3-2.6 2.6v45.4l2.6 2.6 2.7-2.2 2.6 2.2 2.7-2.2 2.6 2.2 2.7-2.2 2.6 2.2 2.6-2.2 2.7 2.2 2.6-2.2z"
    />
    <g fill="#ffc72c">
      <path d="m158.8 200.3-2.3-2.3-2.6 2v45.3l2.6 2.3 2.3-2.3zm-10.2 0-2.7-2.3-2.2 2v45.3l2.2 2.3 2.7-2.3z" />
      <path d="m116 235.8-2.2-2.6 2.3-2.3h70.3l2.2 2.3-2.2 2.6zm70.4-15.5 2.2 2.3-2.2 2.7H116l-2.3-2.7 2.3-2.3zm0-5.6 2.2-2.3-2.2-2.6H116l-2.3 2.6 2.3 2.3z" />
    </g>
    <g fill="#d22630">
      <path d="m164 200.3-2.2-2.3-2.7 2v45.3l2.7 2.3 2.3-2.3zm-20.3 0L141 198l-2.3 2v45.3l2.3 2.3 2.7-2.3z" />
      <path d="m116 230.6-2.2-2.7 2.3-2.3h70.3l2.2 2.3-2.2 2.7zm0-10.6-2.2-2.7 2.3-2.2h70.3l2.2 2.2-2.2 2.7z" />
      <path d="m153.9 245.7-2.7 1.9-2.3-2.3v-45l2.3-1.9 2.7 2Z" />
    </g>
    <path
      fill="#383739"
      d="M175 200.7h-47.6v3.8H121v36.3h6v3.7h47.6v-3.7h6.9v-36.3h-6.1v-3.8z"
    />
    <path
      fill="#d22630"
      d="M127.8 201v3.9h-6v35.5h6v3.8h4.5l-9.8-8.4v-26.4l9.8-8.3h-4.9zm42 0 9.8 8.8v26.4l-9.5 7.6h5V240h6v-35.5h-6v-3.8h-5z"
    />
    <g fill="#00843d">
      <path d="m133 201-9.8 8.8v26l9.9 8.4h36.3l9.8-8.4v-26.4l-9.8-8.3zm.8 1.6h34.8l9.5 7.5v25l-9.5 7.5h-34.8l-9.4-7.5v-24.6z" />
      <path d="m142.9 235.5 1.5.7.4 2-2 1.4 3.1 2.7h-3l-1.9-2-1.9 2h-3l2.6-2.7-1.9-1.5v-1.9l2-.7 1.8 1.5zm10.2 0 1.5.7.4 2-1.9 1.4 3 2.7h-3l-1.9-2-1.9 2h-2.6l2.6-2.7-1.9-1.5v-1.9l2-.7 1.8 1.5zm6.4 0-1.5.7-.4 2 2 1.4-2.7 2.7h2.6l2-2 1.8 2h3l-3-2.7 1.9-1.5v-1.9l-1.9-.7-1.9 1.5zM143 210l1.5-.7.4-1.9-2-1.9 3.1-2.6h-3l-1.9 1.9-1.9-2h-3l2.6 2.7-1.9 1.9v1.9l2 .7 1.8-1.8zm10.2 0 1.5-.7.4-1.9-1.9-1.9 3-2.6h-3l-1.9 1.9-1.9-2h-2.6l2.6 2.7-1.9 1.9v1.9l2 .7 1.8-1.8zm6.4 0-1.5-.7-.4-1.9 2-1.9-2.7-2.6h2.6l2 1.9 1.8-2h3l-3 2.7 1.9 1.9v1.9l-1.9.7-1.9-1.8zm18.2 2.7-1.5 1.5 1.5 1.1v2.3l-2.7-1.9-1.5 1.5h-1.9l-.7-1.5 1.5-1.5-1.5-1.5.7-1.5h2l1.4 1.5 2.7-1.9zm0 8.7-1.5 1.1 1.5 1.5v2l-2.7-2-1.5 1.5h-1.9l-.7-1.5 1.5-1.5-1.5-1.5.7-1.1h2l1.4 1.1 2.7-1.9Zm0 11.3-1.5-1.5 1.5-1.5v-1.9l-2.7 1.9-1.5-1.5h-1.9l-.7 1.5 1.5 1.5-1.5 1.5.7 1.2h2l1.4-1.2 2.7 2zm-53-20 2 1.5-2 1.1v2.3l2.7-1.9 1.5 1.5h1.9l.8-1.5-1.6-1.5 1.6-1.5-.8-1.5H129l-1.5 1.5-2.7-1.9Zm0 8.7 2 1.1-2 1.5v2l2.7-2 1.5 1.5h1.9l.8-1.5-1.6-1.5 1.6-1.5-.8-1.1H129l-1.5 1.1-2.7-1.9Zm0 11.3 2-1.5-2-1.5v-1.9l2.7 1.9 1.5-1.5h1.9l.8 1.5-1.6 1.5 1.6 1.5-.8 1.2H129l-1.5-1.2-2.7 2Z" />
    </g>
    <path
      fill="#d22630"
      d="m133.8 203-8.7 7.5 2.3 2 1.5-1.6h2.3l.7 1.9-1.5 1.5 1.5 1.5-.7 1.5h-2.7l-1.5-1.1-2.6 1.9v.7l2.6 2 1.5-1.6 2.3.4.8 1.5-1.6 1.5 1.6 1.5-.8 2h-2.3l-1.5-1.6-2.6 2.3v.3l2.6 2.3 1.5-1.5h2.3l.8 1.9-1.6 1.5 1.6 1.5-.8 1.5h-2.3l-1.5-1.1-2.3 1.9 8.7 7.5h2l3-3-2-1.5v-2.3l2.3-.7 2 1.5 1.8-1.5 2 .7.3 2.3-2 1.5 3.1 2.7 2.7-2.7-2-1.5v-2.3l2.3-.7 2 1.5 1.8-1.5 2 .7.3 2.3-1.9 1.5 2.6 2.7 3-2.7-1.8-1.5v-2.3l2.2-.7 2 1.5 1.8-1.5 2 .7.3 2.3-1.9 1.5 3 2.7h2l8.6-7.6-2.3-1.5-1.5 1.5-2.2-.4-.8-1.5 1.5-1.5-1.5-1.5.8-1.5 2.2-.4 1.5 1.5 2.7-2.3v-.3l-2.7-2.3-1.5 1.5H171l-.8-1.9 1.5-1.5-1.5-1.5.8-1.5h2.2l1.5 1.1 2.7-1.9v-.7l-2.7-2-1.5 1.6-2.2-.4-.8-1.5 1.5-1.5-1.5-1.5.8-1.5 2.2-.4 1.5 1.5 2.3-1.9-8.7-7.5h-1.9l-3 2.6 1.9 1.9v2.3l-2.3.7-1.9-1.5-1.9 1.5-1.8-.7-.4-2.3 1.9-1.9-2.7-2.6-3 2.6 1.9 1.9v2.3l-2.3.7-1.9-1.5-1.9 1.5-1.8-.7-.4-2.3 1.9-1.9-2.7-2.6-3 2.6 1.9 1.9v2.3l-2.3.7-1.9-1.5-1.9 1.5-1.9-.7-.3-2.3 1.9-1.9-3-2.6zm-9 18.9v1.8l1-1z"
    />
    <path
      fill="#fff"
      d="m141 241.1-1.5 1.2h3zm10.2 0-1.5 1.2h3zm10.2 0-1.5 1.2h3zm-22-37.8 1.6 1.2 1.5-1.5zm10.3 0 1.5 1.2 1.5-1.5zm10.2 0 1.5 1.2 1.5-1.5zm17.8 10.2-1.2.8 1.2.8zm0 8.4-1.2.7 1.2 1.1zm0 8.7-1.2.7 1.2.8zm-53-17.4v1.9l1.2-.8zm0 8.7v1.8l1.2-1zm0 8.3v1.9l1.2-.8z"
    />
    <g fill="#383739">
      <path d="M160 220v-3h-3.1v-3.5h-11.4v3.4h-3v3h-4.1v5.7h4.1v3h3v3.5H157v-3.8h3v-2.7h4.2V220z" />
      <path
        fill="#ffc72c"
        d="M151.2 214v3.3l-4.9 2.7v2.6h-7.6v-2.3h3.8v-3h3.4v-3.8zm0 17.7v-3.8l5-2.6V223h7.5v2.3h-3.8v3h-3.4v3.4z"
      />
      <path
        fill="#fff"
        d="M156.1 222.6V220l-4.9-2.7v-3.8h5.3v3.8h3v3h4.2v2.3zm-17.4 0h7.6v2.7l5 3v3.4h-5.4v-3.8h-3v-2.6h-4.2z"
      />
      <path fill="#d22630" d="M146.7 223v2.3l4.5 2.6v-5Z" />
      <path fill="#00843d" d="m151.2 217.7-4.5 2.6v2.3h4.5Z" />
      <path fill="#d22630" d="M155.7 222.6v-2.3l-4.5-2.6v5z" />
      <path fill="#00843d" d="m151.2 228 4.5-2.7V223h-4.5Z" />
    </g>
    <path
      fill="#d22630"
      d="M110 283v9.5h-8.7v8.4h-9V325h9v8.7h8.3v9.1h32.5l5.7 3.8-2.6 1.5h-11.4l-1.1-1.1h11l-3-3h-15.2l6.8 7.1h14l5-3.4 4.8 3h14l6.8-7.1h-15.1l-3 3h10.6l-.8 1.1h-11.7l-2.7-1.9 5.7-3.4h32.5v-9h8.7V325h9v-24h-9v-8.4h-8.3v-9.4h-32.5l-5.7-3.8 2.7-1.5h11.3l1.1 1.1h-11l3.1 3h15.1l-6.8-7.1h-14l-4.9 3-4.9-3h-14l-6.8 7.5h15.1l3-3.4h-10.5l.7-1.1h11.7l2.7 1.9-5.7 3.4z"
    />
    <path
      fill="#ffc72c"
      d="M160 342.4h32v-9h8.4v-8.7h9v-23.5h-9v-8.7h-8.7v-9h-32.2L146 275h-13.6l-6 6.5h14l2.2-2.3h-10.2l1.5-1.9h11.7l15.2 9.5H189v9h9v8.7h9.2v16.7H198v8.7h-9v9.4h-27.7l-15.1 9h-12.1l-1.5-1.8h10.2l-2.3-2.3h-14l6 6.4h14zm-18.6-3h-27.6V330H105v-8.7h-9v-16.7h9V296h8.3v-8.7H141l15.1-9.4h12.1l1.5 1.9h-10.2l2.3 2.3h14L170 275h-14l-13.2 8.4h-32.5v9H102v8.7h-9v23.5h9v8.7h8.8v9h32.5l13.2 8.3h13.6l6-6.4h-14l-2.2 2.3h10.2l-1.5 1.9h-11.7z"
    />
    <path
      fill="none"
      d="m151.2 281.2-9.4 6h-27.6v9.5h-8.7v8.7h-9.1v15.9h9v8.7h8.8v9.4h27.6l9.4 5.7 9.5-5.7h27.6V330h9v-8.7h9.1v-16h-9v-8.6h-9.1v-9.5h-27.6zm0 2.3 9 5.6h26.2v9.5h8.7v8.3h9v12h-9v8.8h-8.7v8.7h-26.5l-8.7 6-9-6H116v-9h-8.7v-8.8h-9.1v-12h9v-8h8.8V289h26.4z"
    />
    <path
      fill="#00843d"
      d="M186 327.3v9h-25.7l-9 5.7-9.2-5.6h-25.7v-9h-8.7v-8.8h-9.4v-11.3h9v-8.7h8.8v-9h25.7l9-5.4 9.1 5.3h25.7v9h8.7v8.8h9.8v11.3h-9v8.7z"
    />
    <g fill="#383739">
      <path d="M119.8 324v8.6h14.4l-14.4-9.5zm0-22v-9h14.4l-14.4 9.4Zm62.8 22v8.6h-14.4l14.4-9.5zm0-22v-9h-14.4l14.4 9.4z" />
      <path
        fill="#d22630"
        d="m120.2 324.3 12.9 8.3h-12.9zm0-22.7 12.9-8.3h-12.9zm62 22.7-12.8 8.3h12.8zm0-22.7-12.8-8.3h12.8z"
      />
    </g>
    <g fill="#383739">
      <path d="M138 310.3v-4.2h-1.5l-7.6-4.5-7.6 4.5h-1.5v4.6h-3.7v5.3h3.7v4.5h1.5l7.6 4.5 7.6-4.5h1.5V316h5.3v-6Z" />
      <path
        fill="#d22630"
        d="m128.5 301.6 7.6 4.2h-14.4zm7.6 18.5-7.6 4.2-6.8-4.2zm-1.9-9.4v4.5h-8.7v-4.5z"
      />
      <path
        fill="#ffc72c"
        d="M137.6 315.6v4.1h-8.7v-4.1zm-9 0v4.1h-8.8v-4.1zm14.3-5v4.6h-8.3v-4.5zm-17.8 0v4.6h-8.7v-4.5Zm12.5-4.5v4.2h-8.7v-3.8zm-9 0v4.2h-8.8v-3.8z"
      />
      <path d="M122.9 311.8v2.3H119v-2.3zm3.4-4.5v2.2h-3.8v-2.2zm9 0v2.2h-3.7v-2.2zm-3.7 4.5v2.3h-3.8v-2.3zm9 0v2.3h-3.8v-2.3zm-5.3 4.5v2.3h-3.7v-2.3zm-9 0v2.3h-3.8v-2.3z" />
      <path
        fill="#fff"
        d="M122.5 312.2H119v1.5h3.4zm3.8-4.5h-3.8v1.5h3.8zm8.7 0h-3.4v1.5h3.4zm-3.8 4.5h-2.7v1.5h3zm9 0h-3v1.5h3zm-5.2 4.5h-3.4v1.5h3.4zm-9.1 0h-3v1.5h3z"
      />
    </g>
    <g fill="#383739">
      <path d="M164.4 310.3v-4.2h1.6l7.5-4.5 7.6 4.5h1.9v4.6h3.4v5.3h-3.8v4.5h-1.1l-7.6 4.5-7.6-4.5h-1.5V316h-5.3v-6Z" />
      <path
        fill="#d22630"
        d="m173.9 301.6-7.6 4.2h14zm-7.6 18.5 6.8 4.2 7.2-4.2zm1.5-9.4v4.5h8.7v-4.5z"
      />
      <path
        fill="#ffc72c"
        d="M164.8 315.6v4.1h8.7v-4.1zm9 0v4.1h8.8v-4.1zm-14.3-5v4.6h8.7v-4.5zm17.8 0v4.6h8.7v-4.5Zm-12.5-4.5v4.2h8.7v-3.8zm9 0v4.2h8.8v-3.8z"
      />
      <path d="M180 311.8v2.3h3.7v-2.3zm-3.8-4.5v2.2h3.7v-2.2zm-9.1 0v2.2h3.8v-2.2zm3.8 4.5v2.3h3.7v-2.3zm-9.1 0v2.3h3.8v-2.3zm5.3 4.5v2.3h3.8v-2.3zm9 0v2.3h3.8v-2.3z" />
      <path
        fill="#fff"
        d="M180 312.2h3.3v1.5H180zm-3.5-4.5h3v1.5h-3zm-9 0h3.4v1.5h-3.4zm3.7 4.5h3v1.5h-3zm-9 0h3v1.5h-2.6Zm5.3 4.5h3.4v1.5h-3.4zm9 0h3v1.5h-3z"
      />
    </g>
    <g fill="#383739">
      <path d="M160.3 302.7v4.6h-1.5l-7.6 4.9-7.5-5H142v-4.5h-4.5v-4.9h4.5v-4.5h1.6l7.5-5 7.6 5h1.5v4.5h4.5v4.6Z" />
      <path
        fill="#ffc72c"
        d="m151.2 311.8 6.8-4.5h-13.6zM158 293l-6.8-3.8-6.8 3.8z"
      />
      <path
        fill="#d22630"
        d="M160 297.8v-4.5h-8.4v4.5zm-8.8 0v-4.5h-8.7v4.5zm13.2 4.6v-3.8h-8.7v3.8zm-9 0v-3.8h-8v3.8zm-8.7 0v-3.8H138v3.8zm13.2 4.5v-4.2h-8.3v4.2zm-8.7 0v-4.2h-8.7v4.2z"
      />
      <path d="M148.6 294.4v2.3h-3.8v-1.9Zm9 0v2.3H154v-1.9ZM144 299v2.6h-3.8v-3Zm9.1 0v2.6h-3.8v-3Zm9 0v2.6h-3.7v-3Zm-13.5 4.9v2.2h-3.8V304zm9 0v2.2H154V304z" />
      <path
        fill="#fff"
        d="M148.2 294.8h-3v1.5h3zm9 0h-3v1.5h3zm-13.5 4.5h-3v2h3Zm9 0h-3v2h3zm9.1 0h-3v2h3zm-13.6 4.6h-3v1.9h3zm9 0h-3v1.9h3z"
      />
    </g>
    <g fill="#383739">
      <path d="M160.3 328v5h-1.5l-7.6 4.5-7.5-4.5H142v-5h-4.5v-4.9h4.5v-4.5h1.6l7.5-4.9 7.6 5h1.5v4.4h4.5v5z" />
      <path
        fill="#ffc72c"
        d="m151.2 337.1 6.8-4.1h-13.6zm6.8-18.9-6.8-3.7-6.8 3.7z"
      />
      <path
        fill="#d22630"
        d="M160 323.1V319h-8.4v4.1zm-8.8 0V319h-8.7v4.1zm13.2 4.6v-4.2h-8.7v4.2zm-9 0v-4.2h-8v4.2zm-8.7 0v-4.2H138v4.2zm13.2 4.9v-4.5h-8.3v4.5Zm-8.7 0v-4.5h-8.7v4.5Z"
      />
      <path d="M148.6 319.7v2.7h-3.8v-2.7zm9 0v2.7H154v-2.7ZM144 325v2.3h-3.8V325Zm9.1 0v2.3h-3.8V325Zm9 0v2.3h-3.7V325Zm-13.5 3.8v2.3h-3.8v-2.3Zm9 0v2.3H154v-2.3Z" />
      <path
        fill="#fff"
        d="M148.2 320.1h-3v2h3zm9 0h-3v2h3zm-13.5 5h-3v1.5h3Zm9 0h-3v1.5h3zm9.1 0h-3v1.5h3zm-13.6 4.5h-3v1.5h3zm9 0h-3v1.5h3z"
      />
    </g>
    <path
      fill="#383739"
      d="m102 383.6 34-18.1h30.3l34 18.1v29.9l-34 17.7h-30.2l-34-17.7z"
    />
    <path
      fill="#00843d"
      d="m136 365.9-33.6 17.7v29.5l33.7 18.1h30.2l33.7-18.1v-29.5L166.7 366zm.5 2.2h29.8l31.8 17v26.5l-31.8 17h-29.5l-31.7-17V385z"
    />
    <path
      fill="#ffc72c"
      d="M151.2 398.4v30.2h15.1l31.4-17v-12.9h-46.5v-30.2h-15.1l-31.4 16.6v13.3z"
    />
    <path
      fill="#fff"
      d="M151.2 398.4v30.2h-15.1l-31.4-17v-12.9h46.5v-30.2h15.1l31.4 16.6v13.3z"
    />
    <path
      fill="#383739"
      d="M153.9 426.7V418l-2.7 2-2.6-2v8.7h-9.1l6-4.5v-6l-9-5.7h-6v-24.2h5.6l9-5.7v-6L140 370h9v8.7l2.7-1.5 2.6 1.5v-8.3h9.1l-6 4.5v6l9 5.7h6.5v24.2h-6.5l-9 5.7v6l6 4.6z"
    />
    <path
      fill="#ffc72c"
      d="M154.2 370.4v9l-3-1.8v2.6l14 8.7h4.5v9.5h2.3V387h-6l-9.5-6v-6.8l5.3-3.8zm-23.4 28.3v11.4h5.3l9.4 6v6.5l-5.3 3.7h7.6v-9l3 1.8v-2.6l-14-8.7h-4.5v-9z"
    />
    <path
      fill="#fff"
      d="m140.6 370.4 5.3 3.8v6.8l-9.4 5.6h-5.7V398h1.9v-8.7h4.5l14-8.7V378l-3 1.8v-9.4zm29.1 28.3v9.1h-4.5l-14 8.7v2.6l3-1.8v9h7.6l-5.3-3.7v-6.9l9.5-6h6v-11.3Z"
    />
    <path
      fill="#d22630"
      d="M133 407.4h4.2l14 9.1 14-9h4.2v-18.2h-4.2l-14-8.7-14 8.7h-3.8z"
    />
    <path
      fill="#383739"
      d="M160.7 402.9h3v-1.5h-2.3v-2.7h5v6.5h-4.6v4.5h-9.5v-6.4h4.2v2.6H155v1.2h3.8v-2.3l-17.4-10.6h-3v1.1h2.2v2.7h-4.5v-6.4h4.5V387h9.5v6.4h-3.8v-2.6h1.1v-1.1h-3.7v2.2zm-1.6-11v-2.2H155v1.1h1.5v2.6h-4.2V387h9.5v4.6h4.5v6.4h-4.9v-2.7h2.3v-1.1h-3l-17 10.2v2.3h3.7v-1.2H146V403h4.2v6.4h-9.5v-4.5h-4.5v-6.4h4.9v2.6h-2.3v1.5h3z"
    />
    <path
      fill="#fff"
      d="M159.5 404.4v3H155v-1.9h1.5v-1.8h-3.4v5.6h8.7v-4.5h4.5V399h-3.7v2h1.8v2.2H161l-17.7-11.4v-2.6h4.9v1.9h-1.5v1.9h3.8v-5.7h-9.1v4.5h-4.6v5.7h3.8v-1.9h-1.9v-1.9h3.4zm1.2-10.6h3.7v2h-2.6v1.8h4.5V392h-4.5v-4.5h-8.7v5.7h3.4v-2H155v-1.8h5v3l-17.9 11h-3.4V401h2.3v-1.9h-3.8v5.7h4.2v4.5h8.7v-5.6h-3.4v1.8h1.5v2h-5v-3z"
    />
    <path
      fill="#383739"
      d="M190.5 399.9v9.4h-3.8V406h-4.1v3.4h-4.2V400h3.8v3.4h4.2V400zm-14.3 12.4v9.5h-3.8v-3.4h-4.2v3.4h-3.8v-9.5h3.8v3.4h3.8v-3.4zm14.3-15v-9.9h-3.8v3.4h-4.1v-3.4h-4.2v9.5h3.8v-3.5h4.2v3.8zm-78.6 2.6v9.4h3.8V406h4.1v3.4h4.2V400h-3.8v3.4h-4.1V400zm0-3v-9.5h3.8v3.4h4.1v-3.4h4.2v9.5h-3.8v-3.5h-4.1v3.8zm64.3-12.5v-9.5h-3.8v3.4h-4.2V375h-3.8v9.5h3.8V381h3.8v3.8zm-50 28v9.4h3.8v-3.4h4.2v3.4h4.2v-9.5h-3.8v3.4h-4.2v-3.4zm0-28v-9.5h3.8v3.4h4.2V375h4.2v9.5h-3.8V381h-4.2v3.8zm52.2-6.4v7.1h13.3zM124 419v-7.5h-13.2zm0-41.5v7.5h-13.2zm54.4 41.5v-7.5h13.3z"
    />
    <path
      fill="#d22630"
      d="M186.7 405.5v3.8h3.4v-9h-3.4v3.4h-4.5v-3.4h-3.8v8.6h3.8v-3.4zM172.4 418v3.8h3.4v-9h-3.4v3.3h-4.6v-3.4h-3.4v8.7h3.4V418zm14.3-26.8v-3.4h3.4v8.7h-3.4V393h-4.5v3.8h-3.8v-9.1h3.8v3.4zm-71 14.3v3.8h-3.4v-9h3.4v3.4h4.5v-3.4h3.8v8.6h-3.8v-3.4zm0-14.3v-3.4h-3.4v8.7h3.4V393h4.5v3.8h3.8v-9.1h-3.8v3.4zm56.7-12.5v-3.4h3.4v8.7h-3.4v-3.4h-4.6v3.8h-3.4v-9h3.4v3.3zM130 418v3.8h-3.4v-9h3.4v3.3h4.6v-3.4h3.8v8.7h-3.8V418zm0-39.3v-3.4h-3.4v8.7h3.4v-3.4h4.6v3.8h3.8v-9h-3.8v3.3z"
    />
    <path fill="#ffc72c" d="M178.8 378.3v6.5h11.3zm-66.9 34 11.3 6v-6z" />
    <path fill="#fff" d="m123.6 378.3-11.3 6.5h11.3zm55.2 34v6l11.3-6z" />
    <path
      fill="#ffc72c"
      d="M205.6 424.8c-.7 2.7-3 4.6-3.4 7.6-1-3-1.8-5.7-1-8.7 0-2.3 2.2-3.8 2.6-6.4 1.8 1.8 2.2 4.5 1.8 7.5zm.4 11.4a17.8 17.8 0 0 1-6.8 5.6c.4-2.2-.7-3.8-1.9-5.6-1.1-2-1.9-3.8-.7-6.5a14.4 14.4 0 0 0 1.9 3.4c1.1 1.5 2.2 3 2.2 5.3 1.5-1.1 2-2.6 2.7-4.1a12.9 12.9 0 0 1 5.3-5.3c-.8 2.6-1.2 4.9-2.7 7.2zm-9.8 7.5.4 3.8 4.1-3c2-2 4.2-2.7 5.7-5a9.5 9.4 0 0 1-3.8 7.2l-7.5 5c0-2.7-.8-5-2-7.6a6.8 6.8 0 0 1 2-6c0 1.8.3 3.7 1.1 5.2zm-5.7 8.7-3 5.3c2.6-.8 5-2.3 7.6-3.8 2.6-1.5 5.6-2.2 8.3-4.5-1.2 4.9-4.6 7.5-9 9-3.1.8-6.1.4-10 2a26.5 26.5 0 0 1 2.7-7.6l2.7-5.3c.7 1.5 1.1 3 .7 5zm-9.8 7.2a12.1 12 0 0 1-3.8 3.8c3.8 0 6.8-2 10.6-2.3 2.3 0 4.5.8 6.8.4-2.6 1.9-5.7 3.8-9 3.8-4.2.7-8.4 0-13 0 2.3-1.6 3.5-3.8 4.6-5.7 2-3 3.8-5 7.2-5.3-1.5 1.5-2.3 3.8-3.4 5.3zm-13.2 6c6.4 1.2 12.8 2.7 19.6 2.7a30.3 30.3 0 0 1-22.3.4c-3.8-1.2-7.2-2.3-10.2-2-4.1 0-9.8 2.3-15.9 8.4l-2.2-1.9c8.7-7.6 20-9.5 31-7.6z"
    />
  </svg>
)

export default TmFlag

import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import videoIcon from "../../../../assets/Button/videoIcon.png";
import musicIcon from "../../../../assets/Button/Music Library.png";
import pictureIcon from "../../../../assets/Button/Picture.png";
import presentationIcon from "../../../../assets/Button/presentation.png";
import { useDailyLimitExceed } from '../../../../contexts/DailyLimitExceedContext';
import { containsMediaFile } from '../../../../contexts/WorkspaceUtils';


// Styled components defined outside the RenderAssetsForUserSolutions component
// Styled components outside of the render function to avoid recreation on each render
const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  gap: 12px;
  background-color: #1F233E;
  border-radius: 8px;
  cursor: pointer;
  max-width: 180px;
  min-width: 180px;
`;

const AudioIcon = styled.div`
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-size: 20px;
  margin-right: 8px;
`;

const getFileIcon = (description, title) => {
  // Define title-to-icon mapping
  const titleToIconMap = {
    "Image": pictureIcon,
    "Infographic": pictureIcon,
    "Video": videoIcon,
    "Presentation": presentationIcon,
    "Audio": musicIcon,
    "Podcast episode": musicIcon,
    "Blog post": presentationIcon,
    "Text translation": presentationIcon,
    "E-book or whitepaper": presentationIcon,
    "Social media content series": pictureIcon,
    "Email newsletter template": presentationIcon,
  };

  // Normalize title for matching
  const normalizedTitle = title?.toLowerCase();

  // Find icon based on title
  const icon =
    Object.keys(titleToIconMap).find((key) =>
      normalizedTitle?.includes(key.toLowerCase())
    ) || videoIcon; // Default to presentationIcon if no match

  return titleToIconMap[icon];
};


const RenderAssetsForChallengeSolutions = ({ assets }) => {
  const [loading, setLoading] = useState(true);
  const isUnder991px = useMediaQuery({ maxWidth: 991 });
  const isUnder600 = useMediaQuery({maxWidth: 600})
  const {setSending,setWaitThread,setCurrentThread,sendMessage,setShowCreditRecharge,stopMessageIdRef, setVisibleStopGenerationButton} = useWorkspace();
  const{setDailylimitExceed} = useDailyLimitExceed()
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const [hoveredCardIndex, setHoveredCardIndex] = React.useState(false);
  useEffect(() => {
    // Simulate loading state
    setTimeout(() => {
      setLoading(false);
    }, 100); // Example: delay loading for 2 seconds
  }, []);

  const findUrl = (msg) => {
    const urlRegex =
      /(https?:\/\/[^']*?\.(mp3|mp4|pptx|jpg|jpeg|png|txt|ppt))/g;
    const match = urlRegex.exec(msg);
    return match ? match[1] : null;
  };

  const getFileFormat = (url) => {
    // Extract the file extension from the URL
    const parts = url.split(".");
    if (parts.length > 1) {
      return parts[parts.length - 1]; // Return the last part as the file format
    } else {
      return ""; // Return empty string if no file extension found
    }
  };

  const handleSubmit = async (input) => {
    setSending(true);
    setVisibleStopGenerationButton(true)
    setWaitThread(true);
    if (input.trim()) {
      const latestEntry = { sender: "user", content: input };
      setCurrentThread((prevThread) => [...prevThread, latestEntry]);
      const entryResponse = await sendMessage(latestEntry).catch(console.error);
      if (entryResponse) {
        // Check if entryResponse contains a daily limit exceeded message
        if (
          entryResponse &&
          entryResponse.content &&
          findUrl(entryResponse.content) &&
          findUrl(entryResponse.content).includes("dailylimitexceeded")
        ) {
          // Determine the file format from the URL (assuming findUrl() returns the URL)
          const fileFormat = getFileFormat(findUrl(entryResponse.content));

          // Prepare the response message
          const responseMessage = {
            content:
              `Hi there,\n\nIt looks like you’ve reached your daily limit for generating ` +
              fileFormat +
              ` files. We’re really sorry about that! We know how important uninterrupted access is for you.\n\nTo keep enjoying all the great benefits and have a seamless experience, we’d love for you to consider upgrading to our premium plan.\n\nThank you so much for your understanding and support. We truly value having you with us and are excited to continue providing you with the best service possible.\n\nWarm regards,\n\nThe ModalX Team`,
            sender: "modalX",
          };

          // Update the current thread with the response message
          setCurrentThread((prevThread) => [...prevThread, responseMessage]);
          // setShowCreditRecharge(true);
          if(isLoggedIn === null || isLoggedIn === ""){
            setDailylimitExceed(true);
          }else{
            setShowCreditRecharge(true)
          }
        }else if(stopMessageIdRef.current.includes(entryResponse.unique_id)){
          setCurrentThread((prevThread)=>[...prevThread ])
          setVisibleStopGenerationButton(false);
        }
         else {
          // If the response does not contain a daily limit exceeded message, proceed as usual
          // setCurrentThread((prevThread) => [...prevThread, entryResponse]);
          if(containsMediaFile(entryResponse.content)){
            // If the response does not contain a daily limit exceeded message, proceed as usual
            setCurrentThread((prevThread) => [...prevThread, entryResponse]);
          }else{
            setCurrentThread((prevThread) => [...prevThread, entryResponse]);
            setVisibleStopGenerationButton(false);
          }
        }
        setSending(false);
      } else {
        // If there's no entryResponse, indicate processing failure
        setCurrentThread((prevThread) => [
          ...prevThread,
          { content: "Unable to process your query!..", sender: "modalX" },
        ]);
        setSending(false);
        setVisibleStopGenerationButton(false);
      }
    }
    setWaitThread(false);
  };

  const handleCardClick = (description) => {
    handleSubmit(description); // Pass the description to the handleSubmit function
  };

  return (
    // <div style={{ marginLeft: isUnder991px ? '10px' : '95px', display: isUnder991px?'block':'flex', gap: '20px',marginTop:'20px',marginBottom:'20px' }}>
    //   {loading ? (
    //     Array(5).fill().map((_, index) => (
    //       <Box key={index} style={{ width: '192px', height: '122px', backgroundColor: '#282D51', borderRadius: '8px' }} />
    //     ))
    //   ) : (
    //     assets?.map((item, index) => (
    //       <Card key={index} onClick={() => handleCardClick(item?.description)}
    //           style={{
    //             width: isUnder991px ? '100%' : '192px',
    //             display: 'flex',
    //             flexDirection: isUnder991px ? 'row' : 'column',
    //             alignItems: isUnder991px ? 'center' : 'flex-start',
    //             backgroundColor:'red',
    //             boxSizing: 'border-box',
    //           }}
    //       >
    //         <div style={{ display: 'flex', alignItems: 'center' }}>
    //         <AudioIcon>
    //             <img src={getFileIcon(item?.description, item?.title) || videoIcon} alt="asset icon" style={{ width: '100%' }} />
    //           </AudioIcon>
    //         </div>
    //         <div style={{ overflow: 'hidden' }}>
    //           <p style={{ fontSize: '12px', fontWeight: '400', color: 'rgba(255,255,255,0.7)' }}>
    //             {item?.description?.length > 50 ? `${item?.description?.substring(0, 40)}...` : item?.description}
    //           </p>
    //         </div>
    //       </Card>
    //     ))
    //   )}
    // </div>
    <>
      <p style={{marginLeft:isUnder600?'2.6%':isUnder991px?'2%':'88px',color:'rgba(255,255,255,0.8)',marginTop:'24px',fontSize:isUnder991px?'14px':'16px',fontFamily:'inter'}}>Suggested</p>
      <div
        style={{
          marginLeft: isUnder991px ? '10px' : '88px',
          display: isUnder991px ? 'block' : 'grid',
          gridTemplateColumns: '1fr',
          gap: '20px',
          marginTop: '16px',
          marginBottom: '20px',
          fontFamily:'inter'
        }}
      >
        {loading ? (
          Array(5)
            .fill()
            .map((_, index) => (
              <Box
                key={index}
                style={{
                  width: isUnder991px ? '100%' : '600px',
                  height: '122px',
                  backgroundColor: '#282D51',
                  borderRadius: '8px',
                }}
              />
            ))
        ) : (
          assets?.map((item, index) => (
            <Card
              key={index}
              onClick={() => handleCardClick(item?.description)}
              onMouseEnter={() => setHoveredCardIndex(index)} // Set the hovered card index
              onMouseLeave={() => setHoveredCardIndex(null)} // Reset hover state
              style={{
                width: isUnder991px?'90%':'600px', // Force full width
                maxWidth: '604px', // Override any default max-width
                display: 'flex',
                flexDirection: 'row',
                alignItems: isUnder991px ? 'center' : 'flex-start',
                boxSizing: 'border-box', // Ensure padding doesn't break the layout
                gap:'8px',
                marginBottom:'10px',
                backgroundColor: index === hoveredCardIndex ? '#282D51' : '#171a2d',
                border:'1px solid rgba(255,255,255,0.2)',
                borderRadius:'16px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  margin:'auto'
                }}
              >
                {/* <AudioIcon> */}
                  <img
                    src={getFileIcon(item?.description, item?.title) || videoIcon}
                    alt="asset icon"
                    style={{ width: '24px',height:'24px'}}
                  />
                {/* </AudioIcon> */}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center', // Center vertically
                  alignItems: isUnder991px ? 'flex-start' : 'center', // Center horizontally on larger screens
                  height: '100%', // Take up available height
                  overflow: 'hidden',
                  flexGrow: 1, // Allow this container to grow
                }}
              >
                <p
                  style={{
                    fontSize:isUnder991px?'14px':'16px',
                    fontWeight: '400',
                    color: 'rgba(255,255,255)',
                    textAlign: isUnder991px ? 'left' : 'start', // Responsive alignment
                    margin: 0, // Remove default margin for p tag
                  }}
                >
                  {item?.description}
                </p>
              </div>
            </Card>
          ))
        )}
      </div>
    </>
  );
};

export default RenderAssetsForChallengeSolutions;




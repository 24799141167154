// import { Box } from '@mui/material'
// import React from 'react'
// import Image from "../../assets/LandingPage_Items/Landingpage_Modalx_FlowDiagram.png";
// import { darkTheme_bg_color } from '../../constants/colors';

// const HowModalX_Works = () => {
//   return (
//     <div style={{textAlign:'center'}}>
//       <div style={{height:'182px',display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center',marginBottom:'100px'}}>
//         <p style={{textAlign:'center',color:'#F6BE6B'}}>How ModalX Works</p>
//         <p style={{fontSize:'48px'}}>Your AI-Powered Growth Engine, Simplified</p>
//         <p>Discovery, Personalization, Action</p>
//       </div>
//       <div style={{ height: '638px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
//         <p style={{ margin: 0 }}>Give ModalX context by uploading multiple files or links at once</p>
//         <img src={Image} alt="ModalX" style={{ maxHeight: '100%', objectFit: 'contain' }} />
//         <p style={{ margin: 0 }}>ModalX generates outputs in one click, in multiple possible formats</p>
//       </div>

//     </div>
//   )
// }

// export default HowModalX_Works

import React, { useState, useRef } from "react";
import {
  Box,
  useMediaQuery,
  Typography,
  CardMedia,
  Modal,
} from "@mui/material";
import Image from "../../assets/LandingPage_Items/Landingpage_Modalx_FlowDiagram.png";
import { darkTheme_bg_color } from "../../constants/colors";
import video_logo from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/video_logo.png";
import pdf_logo from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/pdf_logo.png";
import audio_logo from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/mp3_logo.png";
import mov_logo from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/mov_logo.png";
import youtube_logo from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/youtube_logo.png";
import excel_logo from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/xlsx_logo.png";
import powerpoint_logo from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/powerpoint_logo.png";
import word_logo from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/word_logo.png";
import video from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/video.png";
import image from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/image.png";
import speech from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/speech.png";
import presentation from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/presentation.png";
import blog from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/blog.png";
import translation from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/translation.png";
import modalXIconWithLine from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/linesWithLogo.png";
import fullimageforsmallscreen from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/IconForSmallScreen.png";
import CommanHoverModal from "../Common_Components/CommanHoverModal";
import image1 from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Image/image1.webp";
import image2  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Image/image2.webp";
import image3  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Image/image3.webp";
import image4  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Image/image4.webp";
import image5  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Image/image5.webp";
import video1 from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/video/video1.webp";
import video2 from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/video/video2.webp";
import video3 from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/video/video3.webp";
import video4 from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/video/video4.webp";
import video5 from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/video/video5.webp";
import ppt1 from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Presentation/presentation1.webp";
import ppt2 from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Presentation/presentation2.webp";
import ppt3 from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Presentation/presentation3.webp";
import ppt4 from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Presentation/presentation4.webp";
import ppt5 from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Presentation/presentation5.webp";
import audio1 from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Audio/audio1.webp";
import audio2  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Audio/audio2.webp";
import audio3  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Audio/audio3.webp";
import audio4  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Audio/audio4.webp";
import audio5  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Audio/audio5.webp";
import blog1 from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Blog/blog1.webp";
import blog2  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Blog/blog2.webp";
import blog3  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Blog/blog3.webp";
import blog4  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Blog/blog4.webp";
import blog5  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Blog/blog5.webp";
import translate1 from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Translation/Translation1.webp";
import translate2  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Translation/Translation2.webp";
import translate3  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Translation/Translation3.webp";
import translate4  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Translation/Translation4.webp";
import translate5  from "../../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Translation/Translation5.webp";

const Capabilities = () => {
  const [hoveredCard, setHoveredCard] = useState(null);
  const [open, setOpen] = useState(false);
  const [modalPosition, setModalPosition] = useState({
    top: 0,
    left: 0,
    right: 0,
    botton: 0,
  });
  const timeoutRef = useRef(null);
  const isBelow991 = useMediaQuery("(max-width:991px)");

  const Left_Side_logo = [
    { icon: video_logo, name: "video_logo" },
    { icon: pdf_logo, name: "pdf_logo" },
    { icon: audio_logo, name: "audio_logo" },
    { icon: mov_logo, name: "mov_logo" },
    { icon: youtube_logo, name: "youtube_logo" },
    { icon: excel_logo, name: "excel_logo" },
    { icon: powerpoint_logo, name: "powerpoint_logo" },
    { icon: word_logo, name: "word_logo" },
  ];

  const Right_Side_logo = [
    {
      id: 1,
      icon: video,
      name: "Video",
      imageArray: [
        { id: 1, name: "image", url: video1 },
        { id: 2, name: "image", url: video2 },
        { id: 3, name: "image", url: video3 },
        { id: 4, name: "image", url: video4 },
        { id: 5, name: "image", url: video5 },
      ],
      title: "Video Generation",
      description: "Generate video at scale for diverse audiences.",
      url: "/videogallery",
    },
    {
      id: 2,
      icon: speech,
      name: "Speech",
      imageArray: [
        { id: 1, name: "image", url: audio1 },
        { id: 2, name: "image", url: audio2 },
        { id: 3, name: "image", url: audio3 },
        { id: 4, name: "image", url: audio4 },
        { id: 5, name: "image", url: audio5 },
      ],
      title: "Speech Generation",
      description:
        "Generate realistic speech in multiple languages seamlessly.",
      url: "/audiogallery",
    },
    {
      id: 3,
      icon: blog,
      name: "Blog",
      imageArray: [
        { id: 1, name: "image", url: blog1 },
        { id: 2, name: "image", url: blog2 },
        { id: 3, name: "image", url: blog3 },
        { id: 4, name: "image", url: blog4 },
        { id: 5, name: "image", url: blog5 },
      ],
      title: "Blog Generation",
      description: "Generate engaging blogs that deliver value",
      url: "/blogarticle",
    },
    {
      id: 4,
      icon: translation,
      name: "translation",
      imageArray: [
        { id: 1, name: "image", url: translate1 },
        { id: 2, name: "image", url: translate2 },
        { id: 3, name: "image", url: translate3 },
        { id: 4, name: "image", url: translate4 },
        { id: 5, name: "image", url: translate5 },
      ],
      title: "Translation",
      description:
        "Get accurate translations while keeping the original context",
      url: "",
    },
    {
      id: 5,
      icon: image,
      name: "Image",
      imageArray: [
        { id: 1, name: "image", url: image1 },
        { id: 2, name: "image", url: image2 },
        { id: 3, name: "image", url: image3 },
        { id: 4, name: "image", url: image4 },
        { id: 5, name: "image", url: image5 },
      ],
      title: "Image Generation",
      description:
        "Generate copyright-free images that effectively communicate your ideas.",
      url: "/imagegallery",
    },
    {
      id: 6,
      icon: presentation,
      name: "Presentation",
      imageArray: [
        { id: 1, name: "image", url: ppt1 },
        { id: 2, name: "image", url: ppt2 },
        { id: 3, name: "image", url: ppt3 },
        { id: 4, name: "image", url: ppt4 },
        { id: 5, name: "image", url: ppt5 },
      ],
      title: "Presentation Generation",
      description: "Generate impactful presentations that deliver value",
      url: "/presentationgallery",
    },
  ];

  const handleHover = (event, card) => {
    clearTimeout(timeoutRef.current); // Clear previous timeout to avoid flickering
    const rect = event.currentTarget.getBoundingClientRect(); // Get card position
    timeoutRef.current = setTimeout(() => {

      const scrollTop = window.scrollY; // Current vertical scroll position
      const scrollLeft = window.scrollX; // Current horizontal scroll position

      const modalOffsetX = 300; // Offset for the modal's horizontal position
      const modalOffsetY = -160; // Offset for the modal's vertical position
    
       // Calculate the modal position relative to the document
      const modalTop = rect.top + scrollTop + modalOffsetY;
      const modalLeft = rect.left + scrollLeft + modalOffsetX;
    
      setModalPosition({
        top: modalTop,
        left: modalLeft,
      });
      setHoveredCard(card);
      setOpen(true);
    }, 500);
  };

  const handleLeave = () => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setOpen(false);
      setHoveredCard(null);
    }, 200);
  };

  const handleModalHover = (isHovering) => {
    if (!open) return;
    if (isHovering) {
      clearTimeout(timeoutRef.current); // Keep modal open
    } else {
      timeoutRef.current = setTimeout(() => {
        setHoveredCard(null);
        setOpen(false); // Close modal when mouse leaves modal
      }, 500);
    }
  };

  return (
    <Box
      sx={{
        textAlign: "center",
        // background: `linear-gradient(to bottom, #1e223d, ${darkTheme_bg_color})`,
        background: `linear-gradient(180deg, #1F233E 16.27%, #171A2D 75.09%)`,
        padding: "60px 0 140px 0", // Adjust padding as needed
      }}
    >
      <Box
        sx={{
          height: "182px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: isBelow991 ? "60px" : "100px",
        }}
      >
        <p style={{ color: "#F6BE6B", fontSize: isBelow991 ? "12px" : "16px" }}>
          Capabilities
        </p>
        <p style={{ fontSize: isBelow991 ? "28px" : "48px", color: "#ffffff"}}>
          Multiple Outputs for Any Input
        </p>
        <p
          style={{
            color: "rgba(255, 255, 255, 0.6)",
            fontSize: isBelow991 ? "14px" : "",
          }}
        >
          ModalX’s Multimodal capabilities{" "}
        </p>
      </Box>
      <Box
        sx={{
          height: isBelow991 ? "343px" : "638px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            margin: 0,
            fontSize: isBelow991 ? "12px" : "16px",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "20px",
            textAlign: "center",
            color: "rgba(255, 255, 255, 0.80)",
          }}
        >
          Give ModalX context by uploading multiple files or links at once. The
          more ModalX knows, the better it can serve
        </Typography>
        {isBelow991 ? (
          <img
            src={Image}
            alt="ModalX"
            style={{ maxHeight: "100%", objectFit: "contain", width: "80%" }}
          />
        ) : (
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: "13.5px",
              }}
            >
              {Left_Side_logo.map((item, idx) => {
                return (
                  <Box
                    key={item.name}
                    sx={{
                      height: "48px",
                      width: "48px",
                      backgroundColor: "#1E223D",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position:"relative"
                    }}
                  >
                    <CardMedia
                      sx={{
                        width: "24px",
                        height: "24px",
                        objectFit: "contain",
                      }}
                      component="img"
                      image={item.icon}
                      alt="Loading"
                    />
                  </Box>
                );
              })}
            </Box>
            <Box>
              <CardMedia
                sx={{ marginTop: "20px", width: "492px" }}
                component="img"
                image={modalXIconWithLine}
                alt="Loading"
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: "42px",
                marginTop: "15px",
              }}
            >
              {Right_Side_logo.map((item, idx) => {
                return (
                  <Box onMouseLeave={handleLeave}>
                    <Box
                      onMouseEnter={(e) => handleHover(e, item)}
                      key={item.id}
                      sx={{
                        height: "40px",
                        width: "148px",
                        backgroundColor: "#1E223D",
                        display: "flex",
                        columnGap: "6px",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "8px 16px",
                        borderRadius: "4px",
                        "&:hover": { backgroundColor: "rgba(40, 45, 81, 1)" },
                      }}
                    >
                      <CardMedia
                        sx={{ width: "24px", height: "24px" }}
                        component="img"
                        image={item.icon}
                        alt="Loading"
                      />
                      <Typography
                        sx={{
                          margin: 0,
                          fontSize: isBelow991 ? "12px" : "16px",
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight: 700,
                          lineHeight: "20px",
                          textAlign: "center",
                          color: "rgba(255, 255, 255, 0.80)",
                        }}
                      >
                        {item.name}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}
        <Typography
          sx={{
            margin: 0,
            fontSize: isBelow991 ? "12px" : "16px",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "24px",
            textAlign: "center",
            color: "rgba(255, 255, 255, 0.80)",
          }}
        >
          ModalX generates outputs in one click, in multiple possible formats
        </Typography>
      </Box>
      <CommanHoverModal
        open={open}
        handleLeave={handleLeave}
        modalPosition={modalPosition}
        hoveredCard={hoveredCard}
        handleModalHover={handleModalHover}
      />
    </Box>
  );
};

export default Capabilities;

import React, { useState } from "react";
import { handleMasterAccess } from "../../contexts/WorkspaceUtils";
import { useNavigate } from "react-router-dom";
import CommonToast from "../Common_Components/CommonToast";

const UserMasterAccess = () => {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [content, setContent] = useState("");
    const [iconType, setIconType] = useState("success");
    const [bgColor, setBgColor] = useState("#282D51")

     const handleEmailChange = (e) => {
        setEmail(e.target.value);
      };

      const onClose = () => {
        setOpen(false);
      };

      const handleMasterAccessClick =async () =>{
        try{
          const res =await handleMasterAccess (email)
          console.log(res);
          if(res?.status){
              localStorage.setItem('tempToken', res?.data?.temp_token)
              navigate('/workspace')
          }else{
            // console.log(res?.response?.data?.error?.message)
            setOpen(true);
            setIconType('error');
            setContent(res?.response?.data?.error?.message);
         }
        }catch(e){
        console.error(e , "error");
        setOpen(true);
        setIconType('error');
        setContent("Master Login Failed");
        }
      
      }

  return (
    <>
    <div style={{ padding: "20px", margin: "auto" }}>
        <div className="mb-3">
        <label htmlFor="email" className="form-label">Email address</label>
        <input
          type="email"
          className="form-control"
          id="email"
          value={email}
          onChange={handleEmailChange}
          placeholder="Enter email"
          style={{ maxWidth: '400px' }}
        />
      </div>
      <button className="btn btn-primary" onClick={handleMasterAccessClick} disabled={email===""}>Access</button>
   </div>
   <CommonToast
        open={open}
        onClose={onClose}
        content={content}
        iconType={iconType}
        bgColor={bgColor}
      />
   </>
  )
}

export default UserMasterAccess
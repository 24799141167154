
const BmFlag = (props) => (
  <svg
    width="1em"
    height="0.75em"
    xmlns="http://www.w3.org/2000/svg"
    id="flag-icons-bm"
    viewBox="0 0 640 480"
    {...props}
  >
    <path fill="#cf142b" d="M0 0h640v480H0z" />
    <path
      fill="#fff"
      d="M559.7 130.6v161.6c0 43.1-86.2 57.2-86.2 57.2s-86.4-14-86.4-57.4V130.6h172.5z"
    />
    <path
      fill="#2f8f22"
      d="M559.7 292.2c0 43.1-86.2 57.2-86.2 57.2s-86.4-14-86.4-57.4c0 0 0-3.5 1.8-5.4 0 0-1 7.1 4.5 12.6 0 0-4.3-7.8 0-15.3 0 0-1.7 9.8 4.4 15.3 0 0-3.3-7.9.4-16.7 0 0-1.8 14.3 4.7 17.3 0 0 1.8-8.4-.8-13.6 0 0 4.5 1.8 4.3 13.8 0 0 1.4-1.8 1.8-10.5 0 0 .2 10 3.5 12.3 0 0 1.2-1-.3-5.5-1.6-4.4.6-6 1-6 0 0-.8 5 3.4 8.8 0 0-1.8-7.9.7-9 0 0-.6 6.7 4.8 8.1 0 0 .3-1.9-.8-4 0 0-1-2.5-.3-4.5 0 0 1.7 6 4 7 0 0-1.4-3.6 0-7 0 0 .2 5 4.7 7.1 0 0-3-4-1.9-8.2l28.7 1.4 15 .7 44.7-3 7.6-6.7s3.1 4.1-1.8 10.8c0 0 4.8-.8 6.3-8.3 0 0 2 4.1-.7 8.8 0 0 5.3-5.4 6-11.3 0 0 2.1 5.8-2.9 12 0 0 4.4-1.6 6.3-8.1 0 0 1.6 4-2.7 9.5 0 0 8.1-4.1 7.9-13 0 0 3.3 4.8-.5 11.6 0 0 4-3.7 4.5-9.3 0 0 2.3 2.5-.2 9.4 0 0 5-4.8 5.8-9.9 0 0 1 4.8-3.2 10.8 0 0 2.9-.8 5.7-6.6 0 0 .7 2.4-1.8 6.6 0 0 2.8-.5 4.6-5.9 0 0 .3 3.2-.5 6 0 0 2.1-1.3 2.6-7.3a9 9 0 0 0 1.2 4.4v.8z"
    />
    <path
      fill="#d40000"
      stroke="#000"
      strokeLinejoin="round"
      strokeWidth={1.2}
      d="M515.4 198.7s-3 .6-7-.6c-3.8-1.2-5.3-.7-6.5 0 0 0 1.5-3.1-2.3-5.6 0 0 1.2 3.2-.5 4.6 0 0-.7.7-1.6-.3 0 0-1.3-1.5-2.8-2.4 0 0 3.3-1.2 2.7-4.5-.6-3.3-2.4-3.6-3.3-4.1a6 6 0 0 0 0 2.4s-3.5-2 1.2-4.7c4.8-2.7 4-4.4 3.1-5.8a13.1 13.1 0 0 0-3.5-3.6s1 1.6.7 2.9c-.1 1.2-2.4 2-2-.1.5-2.4 0-2 0-4.3 0 0 4.2 1.4 6-2.8 0 0 1.6-4.3-3.8-6.4 0 0 1.3 1.8.7 3 0 0-1.2 2.2-2.5.6-1.3-1.7-2.2-2.2-2.1-4.2 0 0 4.9.7 3.6-4.7 0 0-.9 3.6-7.2-1.2 0 0 4.2-4.2 2.5-7.6 0 0-.5-1.5-4.9-.7 0 0 3.8-2.4 2.3-4.5 0 0-.9-1.3-4.6.4 0 0 1.5-2.3-2.1-5 0 0-2.4 1.2-3.6 2.4 0 0-2.4-3-4-4.3 0 0-2.8 1.1-3.5 4.3 0 0-1.3-1.5-4.3-2.4 0 0-1.4 2.8.5 5 0 0-1.4 0-4-1.1 0 0-2.8-1.2-2.3 1.1.3 2.4.6 3 1.2 4.2 0 0-6.5-1.5-6.2 2a8.8 8.8 0 0 0 3 6.2s-3.5 4.8-6.6 1.2c0 0-1.2 1.2 1.2 4.2 0 0 2.4 2.5.3 4.2 0 0-2.5 2-3.7-2 0 0-4.1 4.1.8 7.2 0 0 3 1.8 6.3-1 0 0-1 8-4 6.4 0 0-1.9-1.2 1.4-2.9 0 0-4.8-.6-5.5 4 0 0-.6 3.5 3.5 5 0 0 3.1 1.2 0 3.6 0 0-2.5 1.7-.9 4.4 0 0 1.8 2.8-2.9 3.2 0 0-2.4 0-3.3-.4 0 0-1 2-.4 4 0 0-2.3-1.7-7.5.2-5.2 2-4.9.6-5.2 1.2l-1.6 2.4s2.8 3.6 2.9 3.3l-.6 4 1.4.6 10.8-4.8 11-5.7 8.9.4 5.6 1.3 7.2.6 5.4-2.8h7.6l8.4 4.2 9.5 5.7 5 1 3.9-.3v-7.8z"
    />
    <path
      fill="#d40000"
      stroke="#000"
      strokeLinejoin="round"
      strokeWidth={1.2}
      d="M428.2 207.7s4.2 2.2 6-.3c0 0 2.2-4.4-2.8-6 0 0 2.8-3.2-.2-6.2 0 0-1.6-1.6-4.2-.5 0 0-1.2-2.3-3.7-2.2 0 0-2.4 0-3 2.5 0 0-3.2-1.2-4.9.6 0 0-3 3.2 1.1 5.8l2.9.3 2.8-1.4 3.3.9s-.9 3.2 2.7 6.5z"
    />
    <path
      fill="#64b4d1"
      stroke="#000"
      strokeWidth={1.2}
      d="M521.6 200.1a6.7 6.7 0 0 1 6.9 3.8c2.6 6.2-3 9.6-3 9.6.4 1.3.5 3.2.5 3.2 7.8 1 6.4 9.8 6.4 9.8l-2.6-2.2c-4.5-1.8-9.3 2.2-12.6 8.6-3.4 6.6-1.8 9.5-1.2 17.3.6 7.7 13 12.4 13 12.4l-9.7 25c-3.8 10-12 5.9-14.3 3.7-2.3-2-2.9-.8-4 0-1.1 1 5.3 6-6.5 10.8-11.7 4.7-13.7 8.3-15.7 9.5-2 1.2-10 .5-10.8-.6-1-1-.4-1-3.5-2.9-3-1.7-8.2-3.5-13.8-6.2-5.6-2.7-5.4-6.2-5.3-6.9 0-.7 1.9-6.5-4.7-1.9-6.5 4.7-12.1-2.2-12.1-2.2-1.2-1.7-6.8-16.4-6.8-16.4a92.4 92.4 0 0 0-4.3-12s-.4.8 4.5-2c4.8-2.7 7-7.3 8.7-11.9 1.7-4.6 0-12.5-.6-14-.6-1.6-4.2-8.9-8.7-10.4-4.4-1.6-7.5 2.4-7.5 2.4s-1.3-9 6.6-10c0 0 0-1.8.4-3.1 0 0-5.6-3.4-3-9.6 0 0 1.8-4.3 6.8-3.8l-1.1 2.4s-1.2 12.6 17.2 4.2c18.5-8.6 18-10.2 28.7-4.8l7.6-.1s11-5.3 14.7-3c3.5 2.3 16.3 9.4 16.3 9.4s12.4 4.7 14.9-4z"
    />
    <path
      fill="#fff"
      stroke="#00247d"
      strokeMiterlimit={10}
      strokeWidth={1.2}
      d="M465.8 255s-.6-3.9-1.2-6.4c0 0-1.5-3.9 1-6.8l2.8-3.3s1.8-2.4 4-2.7c0 0 2.3 0 2.4-.5.3-.5 2.8-4.5 8.6 0 0 0 1.8-3 4.8-3.6 0 0 3-.8 4.5 1.4 0 0 3.5-2.6 6.4 1.7 0 0 4.1-2.4 7.2 2.3 0 0 4-2 6.4 2.1 2.5 4.3 2 6 2 6l2 6.8 6.5 8-15.2 5.8h-7l-13.7 3.6-24.3 1.9-6.7-8 9.6-8.3z"
    />
    <path
      fill="#d40000"
      stroke="#000"
      strokeLinejoin="round"
      strokeWidth={1.2}
      d="M445.3 295.5s-3.6.1-5.5 1.2c-1.8.8-3.3 1.8-5.4 3.2 0 0-1 1.3-5.2.5 0 0-7.2-1.7-7.2 4 0 0-8.8.6-5.2 8.3 0 0 2.3 6 7.3 1.8 0 0-3.2 4.5 3 6.3 0 0 4.4 1.2 5.8-3.5 0 0 .7-1.8-1-4 0 0 2.1-.4 3.6-2.7 0 0-4.6 5.8.5 8 0 0 6.3 1.5 6.6-5 0 0-.6-3.2 2-4.3 0 0 5-1.2 7-6.6 0 0-7-3.9-6.3-7.2zM430.6 238s-5.6-2.5-8.4 0c0 0-3.5-2.2-7.5 0 0 0-3.7 2.4-6.3 5.2 0 0-1.8 1.5-1.2 6.4 0 0 1 3.5.5 5.1 0 0-1.2-.2-3.7 2.8 0 0-3.1 3.5-6 .3 0 0 1 4.6 6.1 3.8 0 0-2.5 2-.3 7 0 0 1.6 3.6-1 8.2 0 0 4.5-1.8 4.4-7.2 0 0-.5-3.6 1-6 0 0-1.5 2.2 1.6 7.1 0 0 2.4 3.6.5 7.2 0 0 4.4-1.6 4-7-.4-5.4-3-3.2-1.4-8.2 0 0 .5 2.6 1.8 4a7.2 7.2 0 0 1 2.4 7s2.6-3.4 2-6.8a13.1 13.1 0 0 0-1.3-4.4l8.4-4.8 4.3-7.5z"
    />
    <path
      fill="none"
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={1.2}
      d="M417.8 259.5s-3.5-.2-4-3m-6.1-1.8s1.2 0 2.4 1.2c0 0 .7 1.2 1.9 1"
    />
    <path d="M423 249.2s-1.8 0-3-.8c0 0-.9-.7-1.5.4 0 0-1 1.6.8 2.3 0 0 2.2 1.2-1.2 3.4 0 0 4-1.6 2.4-3.6 0 0-1.8-1.2-1.2-1.7 0 0 .2-.4 1 0 .6.6 2 .4 2.7 0z" />
    <path
      fill="#784421"
      stroke="#000"
      strokeWidth={1.2}
      d="m477.6 259 2.1-.6-14-48h-.4z"
    />
    <path d="M431.2 312.3s-3.2-1.9-6 .3c0 0 .4-1.2 2.4-1.7 0 0 1.2-3.2 4.3-2.9 0 0-1.3 1.6-3 2.8 0 0 1.8.2 2.4 1.5z" />
    <path
      fill="#64b4d1"
      stroke="#00247d"
      strokeMiterlimit={2.6}
      strokeWidth={1.2}
      d="M419.7 319.8s-5.2-3.2-2.3-8.7c0 0 .8-1.5 2.3-1.2 0 0 3.3 1 .8 5.5 0 0-1.4 2.9-.8 4.5zm10.5 5.3s-7.4-3.4-5.1-9.2c0 0 .7-2 2.4-1.8 0 0 2.8.2 2.3 4 0 0-.9 3.6.4 7z"
    />
    <path d="M428.7 303.6s-2.3 1.7-3.1 2.8c0 0-1-1.2-2.2-1.8 0 0 1.5-.3 2.2.3 0 0 1.2-1 3-1.3z" />
    <path
      fill="#64b4d1"
      stroke="#00247d"
      strokeMiterlimit={2.6}
      strokeWidth={1.2}
      d="M435.2 323.6s6-1.4 4.8-7.5c0 0-.6-2.6-3-2.2 0 0-3 .8-1 4.6 0 0 1.1 2.6-.8 5.1z"
    />
    <path
      fill="#f5ce00"
      stroke="#000"
      strokeMiterlimit={2.6}
      strokeWidth={1.2}
      d="m461.6 196.7 5.7 1.9s5.4 2.3 12.3 0l5.2-2-3.5 5.4v2.9l2.1 3.2s-1.3.6-5.4-2c0 0-4.4-3.4-9.6 0 0 0-3 2-5.4 2l3.5-3.8-1.3-3.5-3.6-4.3z"
    />
    <path
      fill="#fff"
      d="M437.5 316.5s.2-.4 0-.5l-.5.2s-.6 1 0 2.4c0 0 .5.9.3 1.8v.4s.3-.1.3-.4c0 0 .4-.8-.2-1.8 0 0-.6-1.4.1-2.1z"
    />
    <path
      fill="#d40000"
      stroke="#000"
      strokeLinejoin="round"
      strokeWidth={1.2}
      d="M518 207.7s-4.2 2.2-6-.3c0 0-2.1-4.4 2.9-6 0 0-2.8-3.2.1-6.2 0 0 1.6-1.6 4.2-.5 0 0 1.2-2.3 3.7-2.2 0 0 2.4 0 3 2.5 0 0 3.3-1.2 5 .6 0 0 2.8 3.2-1.2 5.8l-2.9.3-2.9-1.4-3 .9s.8 3.2-3 6.5z"
    />
    <path
      fill="#f5ce00"
      stroke="#000"
      strokeMiterlimit={2.6}
      strokeWidth={1.2}
      d="M484.8 204.4s0 .8-.8 1c0 0-1 .3-1.5-.9v-.3c0-.5-.3-2 1.4-3.3 0 0 2.6-2.1 7.6.5a192 192 0 0 0 14.8 7.5s3.1 1.6 7.9 1.8c0 0 6.5.5 9.3-4.1 0 0 2.1-3.5 0-5.8 0 0-.9-1-2.3-.8a3 3 0 0 0-1.8 1.2s-1 1.4.1 2.5c0 0 1.6 1 2.2-1 0 .2.5 1.7-.4 2.9 0 0-4 6-14.9-.1l-14.3-8.1s-7.1-3.7-11.6 2.1c0 0-3.4 4.8 1.1 7.9 0 0 3.4 2 5.4-1.2 0 0 1.8-3.1-1-4.4 0 0-2.4-1.2-3.3 1.2-1 2.4 1.8 3 2 1.3 0 0 0-.4.3 0z"
    />
    <path
      fill="#e4cb5e"
      stroke="#000"
      strokeMiterlimit={2.6}
      strokeWidth={1.2}
      d="M428 229.2v-9c0-.8 0-1.2 1.2-2.2 1-.8 2.1-2.3 3.7 1.7 0 0 3.2-3.5 4.2-4 0 0 2-1.5 3.3.7 0 0 1.6-2.6 3-3.2 0 0 3.2-2 3.3 4.2l2.5-2.4s2-1.5 4.2.7c0 0 3.5 3.6 4 4.6 0 0 .8 1 1 2.7 0 0 0 2 1 3 0 0 1.1 1 2.2 1.2 0 0 2.5.1 3.6 2.6 0 0 .3-.5 1.6 11.2v21.5l-14.4 17.1-23.1-6.6-9.2-3.9-2.2-6.6 9-5.9 4.8-13-1.5-9.3-2.2-5.2z"
    />
    <path
      fill="#784421"
      stroke="#000"
      strokeWidth={1.2}
      d="m491.6 240.4 1.2-1.5 2.5-2s4 10.4 4.2 12.7v3.2s6 1.6 7.2 10.7l-5 9.2-7.8-4.4-2.3-1.5v-26.3z"
    />
    <path
      fill="#fff"
      d="M418.3 312.4s.2-.3 0-.6l-.5.4s-1.5 1.7-.2 4.4c0 0 0 .4.3.2 0 0 .3 0 0-.3 0 0-1.1-2.4.4-4z"
    />
    <path
      fill="none"
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={1.2}
      d="M441.7 310.6s.1-.8-.4-1.6c0 0-.3-.6-.2-1.5m5.6-90.1s0 2.3.7 4c.6 1.6 2.9 4.5 3 5.9"
    />
    <path
      fill="#d40000"
      stroke="#000"
      strokeLinejoin="round"
      strokeWidth={1.2}
      d="M501 295.5s3.6.1 5.5 1.2c2 .8 3.4 1.8 5.5 3.2 0 0 1 1.3 5.2.5 0 0 7.2-1.7 7.2 4 0 0 8.7.6 5.2 8.3 0 0-2.4 6-7.4 1.8 0 0 3.2 4.5-3 6.3 0 0-4.4 1.2-5.7-3.5 0 0-.8-1.8 1-4 0 0-2.1-.4-3.5-2.7 0 0 4.5 5.8-.5 8 0 0-6.3 1.5-6.7-5 0 0 .5-3.2-2-4.3 0 0-5-1.2-7.2-6.6 0 0 7.2-3.9 6.5-7.2z"
    />
    <path
      fill="none"
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={1.2}
      d="m443.8 227.4 6 47.8m-4.5-47.9 6.6 44.6m-5.5-44.8 7.8 44.2"
    />
    <path
      fill="#784421"
      stroke="#000"
      strokeWidth={1.2}
      d="m458.6 267.5 2.2-.7-14-48h-.5z"
    />
    <path
      fill="none"
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={1.2}
      d="m450.7 225.3 14.1 35.5m-15-35 13.6 37"
    />
    <path
      fill="#fff"
      d="M427.6 321.6s-1.7-1.5-1.4-4.3c0 0 0-.6-.3-.7 0 0-.3-.1-.3.6 0 0-.5 2.9 1.5 4.5 0 0 .1.3.4.2 0 0 .2 0 0-.3z"
    />
    <path
      fill="none"
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={1.2}
      d="m451.5 224.8 14.3 32.4m-18.3-30.4 9 43.7"
    />
    <path
      fill="#f5ce00"
      stroke="#000"
      strokeMiterlimit={2.6}
      strokeWidth={1.2}
      d="M520 259c-6.3-3.6-7.7-11.4-7.7-11.4a24.6 24.6 0 0 1 1.6-14.3c4-8.6 11.1-10.3 11.1-10.3s-6.9 4.8-8.8 11.2c0 0-1.4 5.5-.6 10.7.8 5.5.5 4.1 1.8 8.3l2.6 6z"
    />
    <path
      fill="#784421"
      stroke="#000"
      strokeWidth={1.2}
      d="m492.7 238.7-26.6 8.5-1.4 12c-6.3 9.1-19.2 10.7-19.2 10.7l10 10.7 19 4 10.2-7.8 9.2-8.4c-1-4.5-.5-11.2-.5-11.2 0-1.2.5-3.8.5-3.8s-1.5-10.7-1.2-14.5z"
    />
    <path
      fill="none"
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={1.2}
      d="M465 256.2s17.6-5.5 28-10m-36.4 20s22-4 37.3-12.8l5.6-3.8"
    />
    <path
      fill="none"
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={1.2}
      d="m499.5 252.8-6 4.4s-24.5 11.2-43.2 11.6m43.1-3.6S474.6 275 464 275"
    />
    <path
      fill="#d40000"
      stroke="#000"
      strokeLinejoin="round"
      strokeWidth={1.2}
      d="M515.8 238s5.6-2.5 8.4 0c0 0 3.6-2.2 7.6 0 0 0 3.6 2.4 6.2 5.2 0 0 1.8 1.5 1.2 6.4 0 0-1 3.5-.3 5.1 0 0 1.1-.2 3.5 2.8 0 0 3.3 3.5 6 .3 0 0-.8 4.6-6 3.8 0 0 2.4 2 .3 7 0 0-1.6 3.6 1 8.2 0 0-4.6-1.8-4.4-7.2 0 0 .4-3.6-1-6 0 0 1.4 2.2-1.7 7.1 0 0-2.4 3.6-.4 7.2 0 0-4.5-1.6-4-7 .3-5.4 3-3.2 1.3-8.2 0 0-.4 2.6-1.7 4a7.2 7.2 0 0 0-2.4 7s-2.6-3.4-2-6.8c.6-3.5 1.2-4.4 1.2-4.4l-8.3-4.8-4.2-7.5z"
    />
    <path
      fill="#fff"
      stroke="#00247d"
      strokeMiterlimit={10}
      strokeWidth={1.2}
      d="M420.6 268.4s.8-3.7 4.3-2.4c0 0 1.2-6 7.7-6.2 6.5-.2 6.8 6.4 6.8 6.8 0 0 2-2.8 5.1-2.5 0 0 5.5-.3 3.6 8.5l1 1.2s4.1-9.9 12.7-7.4c0 0 8.4 2.6 3 10.7 0 0 4.2 5.5 7.6 5 3.6-.5 6.7-1.6 10.2-7.8 3.6-6.2 11.7-7.2 13.7-6.8 2 .3 3.7 1.8 4 3.2 0 0 4.3-14.3 19.8-12.2l6.2 3.3 2.4 1-3.6 10-8.4 19.2-6.6 2-6.8-3.9-2.4 1.2v5.9l-9 6.4-6.2 2.5-6.8 4.8-1.7 4.2s-3.7-1.7-8 0l-1.4-3.6-4-3.7-15.6-7.4-2.7-9-2.9-1.3-3.2 3.6-4.5.6-7-4.8-7.4-21z"
    />
    <path
      fill="none"
      stroke="#000"
      strokeMiterlimit={2.6}
      strokeWidth={1.2}
      d="M488.2 198s-6-.2-5.7 6"
    />
    <path
      fill="#f5ce00"
      stroke="#000"
      strokeMiterlimit={2.6}
      strokeWidth={1.2}
      d="M527 263.3c-10.2-3.2-13-14.3-13-14.3a25.5 25.5 0 0 1 1.2-15.5c4.8-11 12.7-11 12.7-11a4 4 0 0 1 4.5 3.2c.4 2.4-1.3 3.3-1.3 3.3-2.7 1.5-4.5-.5-4.5-.5-1.2-1.6-.3-2.9-.3-2.9.7-1 2-.4 2-.4 1.3.2 1 1.6 1 1.6s.4-1.6-1.2-1.8c0 0-3.2-.7-6.8 3.8 0 0-4.7 6.1-4.7 14.1 0 0-.6 14.8 14.7 18.6 0 0-1.6 2.4-4.6 11.1 0 0-3.3 11.2-6.4 17.3 0 0-4.3 8.6-13.9 5.1 0 0-5.6-2.5-5.6-6.5 0 0-.3-3.8 3-4 0 0 3.3-.3 3.3 2.5 0 0 0 3-3.5 2.4 0 0-1.2-.3-1.1-1.5 0 0 .2-1.2 1.8-.6 1.5.6 0 0 0-.1 0 0-.6-.3-1.2 0 0 0-.6 0-.6 1 0 0 0 .6.7 1.1l1.7.2s1 2 3 2.7a8 8 0 0 0 7.1-1.2 10.7 10.7 0 0 0 3-4.3 113.3 113.3 0 0 0 5.2-13.5s1.8-5.7 3.4-8.7z"
    />
    <path
      fill="#f5ce00"
      stroke="#000"
      strokeMiterlimit={2.6}
      strokeWidth={1.2}
      d="M498 291.4s2-.2 2 1.5c0 0-.1 2.4-3.2 1.9 0 0-3-.6-2-4 0 0 .8-2.3 3.6-2 0 0 2.8 0 4 3.8 0 0 1 3.3-.7 6-1.8 3-6.2 5-8.4 6 0 0-8.7 3.3-11.3 5.3 0 0-4 2.9-2.2 5.6 0 0 .7 1.1 1.7 1.1 0 0 1.2 0 1.3-1.2 0 0 0 .7-.7 1 0 0-.8.4-1.7-.3 0 0-1-1-.2-2.4 0 0 1-1.6 3.6-.6 0 0 2 1.1 1.2 3.3 0 0-.9 2.1-3.4 2 0 0-1.9 0-3.2-1.2-2-2.1-2-6-.2-8 0 0 1.5-2.1 4.7-3.6l9-3.5c2.6-1.1 4.9-2.3 6.4-4.1 0 0 1.3-1.4 1.8-4 0 0 .4-2-.8-2.4l-1.2-.3z"
    />
    <path
      fill="none"
      stroke="#000"
      strokeMiterlimit={2.6}
      strokeWidth={1.2}
      d="M498.2 291.4s2.1-.4 3.5 2.2c0 0 .7 1.5.8 2.5m.7-9s-2.1.3-1 3.4c1.1 2.9 3 3.7 3.5 4.2"
    />
    <path
      fill="#f5ce00"
      stroke="#000"
      strokeMiterlimit={2.6}
      strokeWidth={1.2}
      d="M461.6 204.4s0 .8 1 1c0 0 .8.2 1.3-.9v-.3c0-.5.3-2-1.2-3.3 0 0-2.8-2.1-7.8.5 0 0-4 2-6.7 3.6 0 0-7.1 3.8-8 4 0 0-3.2 1.6-8 1.8 0 0-6.5.5-9.2-4.1 0 0-2.2-3.5 0-5.8 0 0 .8-1 2.2-.8.6.1 1.2.4 1.8 1.2 0 0 1 1.4-.1 2.5 0 0-1.6 1-2.2-1 0 .2-.3 1.6.5 3 0 0 4 5.9 14.8-.3l14.3-8s7.2-3.7 11.6 2c0 0 3.4 4.8-1 8 0 0-3.3 2-5.5-1.2 0 0-1.6-3 1-4.4 0 0 2.4-1.2 3.5 1.2 1 2.4-2 3-2.2 1.3 0 0 0-.4-.1 0zm-35.2 54.7c6.3-3.5 7.8-11.5 7.8-11.5 1.4-8-1.7-14.3-1.7-14.3-4-8.6-11-10.3-11-10.3s6.8 4.8 8.7 11.2c0 0 1.6 5.5.6 10.7-.8 5.5-.5 4.1-1.8 8.3z"
    />
    <path
      fill="none"
      stroke="#000"
      strokeMiterlimit={2.6}
      strokeWidth={1.2}
      d="M458.4 198s6-.2 5.6 6"
    />
    <path
      fill="#f5ce00"
      stroke="#000"
      strokeMiterlimit={2.6}
      strokeWidth={1.2}
      d="M419.3 263.3c10.3-3.2 13-14.3 13-14.3a25.5 25.5 0 0 0-1.1-15.5c-4.8-11-12.7-11-12.7-11a4 4 0 0 0-4.5 3.2c-.3 2.4 1.3 3.3 1.3 3.3 2.8 1.5 4.6-.5 4.6-.5 1.1-1.6.2-2.9.2-2.9-.7-1-2-.4-2-.4-1.2.2-1 1.6-1 1.6s-.3-1.6 1.2-1.8c0 0 3.2-.7 6.8 3.8 0 0 4.8 6.1 4.8 14.1 0 0 .6 14.8-14.8 18.6 0 0 1.7 2.4 4.6 11.1 0 0 3.4 11.2 6.6 17.3 0 0 4.2 8.6 13.7 5.1 0 0 5.6-2.5 5.6-6.5 0 0 .4-3.8-3-4 0 0-3.3-.3-3.3 2.5 0 0 0 3 3.6 2.4 0 0 1.2-.3 1-1.5 0 0-.2-1.2-1.7-.6-1.6.6 0 0 0-.1 0 0 .5-.3 1.1 0 0 0 .6 0 .6 1 0 0 0 .6-.7 1.1l-1.5.2s-1 2-3.2 2.7a7.5 7.5 0 0 1-7-1.2 10.7 10.7 0 0 1-3-4.3 113.5 113.5 0 0 1-5.3-13.5s-1.8-5.7-3.2-8.7l-.6-1.2z"
    />
    <path
      fill="#f5ce00"
      stroke="#000"
      strokeMiterlimit={2.6}
      strokeWidth={1.2}
      d="M448.5 291.4s-2-.2-2 1.5c0 0 0 2.4 3.2 1.9 0 0 3-.6 1.9-4 0 0-.7-2.3-3.6-2 0 0-2.7 0-4 3.8 0 0-.9 3.3.8 6 1.8 3 6.2 5 8.3 6 0 0 8.7 3.3 11.4 5.3 0 0 3.9 2.9 2.1 5.6 0 0-.6 1.1-1.7 1.1 0 0-1.2 0-1.3-1.2 0 0 0 .7.7 1 0 0 .9.4 1.7-.3 0 0 1-1 .2-2.4 0 0-1-1.6-3.4-.6 0 0-2.2 1.1-1.2 3.3 0 0 .7 2.1 3.2 2 0 0 2 0 3.2-1.2 2-2.1 2-6 .4-8 0 0-1.7-2.1-4.8-3.6-1.9-.6-5.5-2.1-9-3.5-2.7-1.1-5-2.3-6.5-4.1 0 0-1.3-1.4-1.8-4 0 0-.3-2 .9-2.4l1.2-.3z"
    />
    <path
      fill="none"
      stroke="#000"
      strokeMiterlimit={2.6}
      strokeWidth={1.2}
      d="M448.2 291.4s-2.1-.4-3.4 2.2c0 0-.7 1.5-.9 2.5m-.7-9s2.2.3 1.1 3.4c-1.2 2.9-3 3.7-3.6 4.2m27.4-91.1s5.2-3.3 10.4.3m-39.7 31.3s.7-3-2.6-8c0 0-2.2-4.7-3.2-7.5m7.4-3.3 2.3 6.6 3 6.8"
    />
    <path
      fill="none"
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={1.2}
      d="M441.2 238.3s2 2.1 1.8 5.6m-8.1-7.9s2.1 1 1.9 6.8c0 0-.1 4 3 6.2m-5.3 1.4s6.7-.7 7.2 3.5c0 0 0 4 2.4 4.3 0 0 3.3.3 4 3.6m-11.4-6.8s1.2 1.7 2.4 2.6m6.2-7s1.6 2.7 2 4.1m5.3-35s1.6 2.1 3.1 3m3.1 9.6s3.4 1.6 3 10c0 0-.6 5 .9 7.7"
    />
    <path
      fill="#784421"
      stroke="#000"
      strokeWidth={1.2}
      d="m443.1 226.6.4.8s6 .3 9.1-3.8l-.3-.8s-5.3-.3-9 3.8z"
    />
    <path
      fill="none"
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={1.2}
      d="M464.7 258.5s20.6-6 28.6-9.6"
    />
    <path
      fill="#784421"
      stroke="#000"
      strokeWidth={1.2}
      d="m462.5 220.5-.2-.6 10.1-4 .3.8z"
    />
    <path
      fill="none"
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={1.2}
      d="m463.4 220.1 6.8 33.3m-5.6-33.6 7.7 33m-6.4-33.6 8.4 32.9m-5-34.1 11.5 24.6m-10-25 12.3 24.2M471.8 217l13.4 24.3m-15.5 12.4 5.2-1.6m-9.1-1.6s15.2-4.5 27-9.2M465.4 252s19.5-5 27.2-8.8m-21.2 24.6 1 3.3 2.8-1-1-3.1m5-1.3 1 3 2.8-.9-1-3m-16-17.6-.2-4.2 25.5-7.7 1.3 3.6m-16 .8 1.3 3.8m7.6-6.4 1.6 3.6m.5-4.3 1.6 3.3m.4-4 1.6 3.6m0-4 2.8-1.6 1.2 3.1m-2.6-2.3 1.4 3.3"
    />
    <path
      fill="none"
      stroke="#00247d"
      strokeMiterlimit={10}
      strokeWidth={1.2}
      d="M429.5 283.1s-5-5.1 0-10c0 0-5.5-2.6-4.6-7m13.6 21s-5.3.7-3.7-9.4c0 0-2.2 4-2.9 6a4.7 4.7 0 0 0 2 5.4c.9.6 5.5 1.8 7-1.2m-8.3-16.2s-2.1 1.9-.6 5.4m2.8-5s.2 3.5 2 5.4m-.5-6.7s0 4 2.9 6.6m-1.5-8s0 4.5 3.4 7.4m13.5-.7s1.6-3.9 5.2-3.6c0 0-1.7.8-1.7 2.5 0 0-.2 3 2.9 3.2 0 0 2.5.4 3.8-1"
    />
    <path
      fill="none"
      stroke="#00247d"
      strokeMiterlimit={10}
      strokeWidth={1.2}
      d="M463.1 293.1s-8.9-4.2-8-10.1c0 0 .4-3.6 4-5.4m-2 3.8s-.8 2.5 1 4.3m1.1-6s-1.2 2.8.7 4.8m1.5-6.3s-1.4 2.4.2 4.5m-8.4 11s4.8 4.3 8.6 4.3m-10.1-2.9s4.5 3.6 7.4 4.2m-9-3.2s5.5 4.9 9 5.2m13.7 4.6s-5.5 3-1.2 6.2m-2.4-9.4 9-4.6m-6.7 6.2s9-5.5 11.7-6.7m-7 7.2s7.7-5.2 11.9-7.5m-6.8 7.1s5.3-4 10.5-7.1m1.7-5.1s-2.6-3.9-11.2 0c0 0 3.3-2.9 9.5-5.2m8.5-16.3s.8 3.1 0 5"
    />
    <path
      fill="none"
      stroke="#00247d"
      strokeMiterlimit={10}
      strokeWidth={1.2}
      d="M486.5 285.7s1.8-2.6 6.3-4c0 0 1.2 3 4.8 2.5 0 0 5.2-1.1 3.6-7 0 0-1.1-4.2-7.2-4.7m18.1-7.3s6.1 1.2 6 6.1c0 0 .6 7.6-7.4 9m6.6-12s1.6-3.7 5.5-5.2m-4.6-2.2s1.7 2.8 2.8 3.1m-3.6-1s1.5 2.1 2 2.5m-2.6-.5a8 8 0 0 0 1.5 1.6M485 235.8s1.2-2 4.3-1.2m12.1 15.8s2-7.8 8.5-5m-2.6-.6s.6-3.6-1-5.5m-3.6 5.2s.9 1.5.1 2.7"
    />
    <path
      fill="#64b4d1"
      stroke="#00247d"
      strokeMiterlimit={2.6}
      strokeWidth={1.2}
      d="M516.2 325s7.4-3.3 5.1-9.1c0 0-.7-2-2.4-1.8 0 0-2.8.1-2.3 4 0 0 1 3.8-.5 7z"
    />
    <path
      fill="#fff"
      d="M518.8 321.6s1.7-1.5 1.5-4.3c0 0-.2-.6 0-.7 0 0 .4-.1.4.6 0 0 .5 2.9-1.4 4.5 0 0-.1.3-.4.2 0 0-.2 0 0-.3z"
    />
    <path
      fill="#64b4d1"
      stroke="#00247d"
      strokeMiterlimit={2.6}
      strokeWidth={1.2}
      d="M526.7 319.8s5.2-3.2 2.4-8.7c0 0-.9-1.5-2.4-1.2 0 0-3.2 1-.7 5.5 0 0 1.2 2.9.7 4.5z"
    />
    <path
      fill="#fff"
      d="M528 312.4s-.2-.3 0-.6l.4.4s1.5 1.7.3 4.4c0 0-.1.4-.5.2v-.3s1.2-2.4-.2-4z"
    />
    <path
      fill="#64b4d1"
      stroke="#00247d"
      strokeMiterlimit={2.6}
      strokeWidth={1.2}
      d="M511 323.6s-5.8-1.4-4.7-7.5c0 0 .6-2.6 3.1-2.2 0 0 3 .8 1 4.6 0 0-1.1 2.6.7 5.1z"
    />
    <path
      fill="#fff"
      d="M508.8 316.5v-.5s.4 0 .5.2c0 0 .6 1 .1 2.4 0 0-.5.9-.2 1.8 0 0 0 .3-.3.4l-.2-.4s-.3-.8.2-1.8c0 0 .6-1.4 0-2.1z"
    />
    <path d="M515.2 312.3s3.4-1.9 6 .3c0 0-.2-1.2-2.4-1.7 0 0-1-3.2-4.2-2.9 0 0 1.2 1.6 3 2.8 0 0-1.8.2-2.4 1.5zm2.4-8.7s2.4 1.7 3.2 2.8c0 0 .9-1.2 2-1.8 0 0-1.2-.3-2 .3 0 0-1.1-1-3-1.3z" />
    <path
      fill="none"
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={1.2}
      d="M504.8 310.6s-.3-.8.3-1.6c0 0 .4-.6.2-1.5m23.4-48s3.5-.2 4-3m6-1.8s-1.2 0-2.2 1.2c0 0-.9 1.2-2 1"
    />
    <path d="M523.6 249.2s1.8 0 2.7-.8c0 0 1.1-.7 1.7.4 0 0 1 1.6-.8 2.3 0 0-2.2 1.2 1.3 3.4 0 0-4.2-1.6-2.4-3.6 0 0 1.8-1.2 1-1.7 0 0-.2-.4-.9 0-.7.6-2.1.4-2.6 0z" />
    <path
      fill="#64b4d1"
      stroke="#00247d"
      strokeMiterlimit={2.6}
      strokeWidth={1.2}
      d="M526 205.6s.8-1.2.5-4.4c-.4-3.2 2.6-3.7 3.6-2.8 0 0 1.2 1 0 3.1a8.3 8.3 0 0 1-4.1 4.1z"
    />
    <path
      fill="#fff"
      d="M527 203s1.2-1.1.6-3.6l.2-.2h.3s.6 2.8-.6 4.2c0 0-.2.3-.5 0z"
    />
    <path
      fill="#64b4d1"
      stroke="#00247d"
      strokeMiterlimit={2.6}
      strokeWidth={1.2}
      d="M525.1 204.4s0-1.4-2.3-3.6c-2.4-2.4-.5-4.4 1-4.4 0 0 1.3 0 2 2.3a8.3 8.3 0 0 1-.7 5.7z"
    />
    <path
      fill="#fff"
      d="M524.8 201.4s0-1.6-2.2-3.3v-.4h.4s2.3 1.8 2.3 3.8c0 0 0 .3-.3.3 0 0-.2 0-.2-.4z"
    />
    <path
      fill="#64b4d1"
      stroke="#00247d"
      strokeMiterlimit={2.6}
      strokeWidth={1.2}
      d="M522 203.3s-.7-1-3.5-2.1c-2.9-1-2.2-3.5-1-4 0 0 1.3-.5 2.6 1.1a7.6 7.6 0 0 1 1.8 5z"
    />
    <path
      fill="#fff"
      d="M520.6 201.3s-.6-1.4-3.2-2.1c0 0-.2 0-.2-.3 0 0 0-.2.3-.1 0 0 2.9.6 3.7 2.4l-.1.3s-.3 0-.4-.2z"
    />
    <path
      fill="#64b4d1"
      stroke="#00247d"
      strokeMiterlimit={2.6}
      strokeWidth={1.2}
      d="M523.7 204.4s-1.3.5-4.4-1c-3.1-1.5-4.4 1.1-3.8 2.3 0 0 .5 1.6 2.8 1.2a8.3 8.3 0 0 0 5.4-2.4z"
    />
    <path
      fill="#fff"
      d="M516.6 205.4s1.4 1 3.9 0h.3s0 .2-.2.3c0 0-2.6 1.2-4.4.1 0 0-.2 0 0-.3h.4z"
    />
    <path
      fill="none"
      stroke="#000"
      strokeMiterlimit={2.6}
      strokeWidth={1.2}
      d="M519.3 194.7s1.4.8 1.2 2.5"
    />
    <path
      fill="none"
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={1.2}
      d="M526 195s.6 1.9 0 3"
    />
    <path
      fill="#64b4d1"
      stroke="#00247d"
      strokeMiterlimit={2.6}
      strokeWidth={1.2}
      d="M420.2 205.6s-.8-1.2-.5-4.4c.4-3.4-2.5-3.7-3.5-2.8 0 0-1.2 1-.2 3.1a8.3 8.3 0 0 0 4.2 4.1z"
    />
    <path
      fill="#fff"
      d="M419.1 203s-1-1.1-.6-3.6v-.2h-.4s-.5 2.8.7 4.2c0 0 .2.3.3 0z"
    />
    <path
      fill="#64b4d1"
      stroke="#00247d"
      strokeMiterlimit={2.6}
      strokeWidth={1.2}
      d="M421.2 204.4s0-1.4 2.4-3.6c2.3-2.4.4-4.4-1-4.4 0 0-1.6 0-2 2.3a8.3 8.3 0 0 0 .6 5.7z"
    />
    <path
      fill="#fff"
      d="M421.5 201.4s0-1.6 2-3.3c0 0 .3-.3 0-.4h-.2s-2.4 1.8-2.4 3.8c0 0 0 .3.4.3 0 0 .2 0 .2-.4z"
    />
    <path
      fill="#64b4d1"
      stroke="#00247d"
      strokeMiterlimit={2.6}
      strokeWidth={1.2}
      d="M424.3 203.3s.6-1 3.5-2.1c2.8-1 2-3.5.9-4 0 0-1.2-.5-2.6 1.1a7.6 7.6 0 0 0-1.8 5z"
    />
    <path
      fill="#fff"
      d="M425.6 201.3s.6-1.4 3.2-2.1c0 0 .2 0 .2-.3 0 0 0-.2-.2-.1 0 0-2.9.6-3.7 2.4v.3s.4 0 .5-.2z"
    />
    <path
      fill="#64b4d1"
      stroke="#00247d"
      strokeMiterlimit={2.6}
      strokeWidth={1.2}
      d="M422.5 204.4s1.4.5 4.4-1c3-1.5 4.4 1.1 4 2.3 0 0-.7 1.6-3 1.2a8.3 8.3 0 0 1-5.4-2.4z"
    />
    <path
      fill="#fff"
      d="M429.6 205.4s-1.3 1-3.9 0h-.2v.3s2.8 1.2 4.5.1c0 0 .2 0 0-.3h-.4z"
    />
    <path
      fill="none"
      stroke="#000"
      strokeMiterlimit={2.6}
      strokeWidth={1.2}
      d="M427 194.7s-1.5.8-1.2 2.5"
    />
    <path
      fill="none"
      stroke="#000"
      strokeMiterlimit={10}
      strokeWidth={1.2}
      d="M420.2 195s-.6 1.9 0 3"
    />
    <path d="M486.7 177.6s1 2.7.6 5.2c-.3 2.4.2 2.9 1 3.4.7.3 2.4-.4 2.5-2.2 0 0 2 3.8-1.8 6.3 0 0-2.7 1.7-5-.4-.8-.9-1.2-3-.7-5 0 0 .6-2.7-.5-5.5 0 0 1.6 1.6 1.2 4.9 0 0-.8 6 3.6 5.6 0 0 3 0 3-3.6 0 0-1.1 1.2-2.4.8a2.4 2.4 0 0 1-1.8-2.8c.1-1.6.6-4.3.3-6.7zM482 187s-.4 4.8-4.6 5.9c0 0 .6-.9-.3-3.3 0 0-1-1.2-1-3.3 0 0-1.2 1 .3 3.8 1 1.7 0 3.6-.1 3.7-.4.1 6.9-1 5.6-6.7zm-5.6-4.5s-1.1-1.2-1.2-3.8c0-2.7-.5-3.4-.9-3.6 0 0 .5 2.3.4 3.6a7 7 0 0 0 .4 3s-2.4.3-3.9-3c-1.3-3.3-2.7-3-3.4-3 0 0 .8.1 2.4 3 1.6 3 2.1 3.7 6.2 3.7zm-10.8-7s1.2 3.2 1.4 4.8c0 0-3.1-1-4-3.5 0 0-3.2 1-2 4.7 0 0-2.9-1-4.2-3.2 0 0 1.6 1.2 3.1 1.7 0 0-.2-3.1 3.6-4.2 0 0 .6 2.7 2.6 3.3zm5-5.4s1.2 1.5 3.4 1.5a3.6 3.6 0 0 0 3.2-1.5s-.1 2.6-3.2 2.7c0 0-3.3 0-3.3-2.7zm-13.4 18.5 2 1.9s1.2 1.2 2-.3c0 0 1.3-2.4 2.9-2.2 0 0-1.2.7-2.4 3 0 0-.2.7-1.2.8-.5 0-1 .2-1.8-.7 0 0-.7-.9-1.5-2.4zm16.7-29.4c-3.2 0-.5 2.4-.5 2.4 0 4-3.1 5.4-5.3 4.2-2.2-1.2-.4-4-.4-4s-2.4 1.5-1 3.9c1.6 2.4 5.7 1.2 7.2-.9 1.5 2.2 5.7 3.3 7.1.9 1.7-2.4-.8-4-.8-4s1.8 2.9-.5 4c-2.1 1.3-5.4 0-5.2-4 0 0 2.7-2.5-.5-2.5zm2-4.4s1.2 2 1 5c0 0 .9-3-1-4.9zm-10 4.7s-.3-2.2-2.4-2.4c0 0 1.9 1.3 2.4 2.4zm16 0s.3-2.2 2.5-2.4c0 0-1.8 1.3-2.4 2.4zm-19.2 3.2s1.6.5 2.6-.7zm-5.4-13.1s1.9-.1 3.8 2.7c0 0-1.9 1-2.4 1.6 0 0 0-1 1-1.7 0 0-.4-1.4-2.4-2.5zm32 0s-2-.1-3.9 2.7c0 0 2 1 2.4 1.6 0 0 0-1-1-1.7 0 0 .4-1.4 2.5-2.5zm-14.7 2.2s1.7.5 3.8-.7c0 0 2.6-1.4 4.3 0 0 0-1.4-.7-4.3.7 0 0-2.7 1.6-3.8 0z" />
    <path fill="#fff" d="M476.4 153.6s1.4-2.5 6-2c0 0-1.3 3.6-6 2z" />
    <ellipse cx={479.4} cy={152.8} fill="#784421" rx={0.8} ry={1.1} />
    <ellipse cx={479.4} cy={152.8} rx={0.5} ry={0.7} />
    <path d="M466.8 148.1s2.8.3 4.6 1.2c0 0 1.8 1.2 3.8-.3 0 0 2.2-1.4 3.7-3.1 0 0-3.5 2.3-4.8 2.7 0 0-1.2-1-1.5-2.3 0 0 0-1 2-2.7 0 0-2.5.8-3 2.9a7.2 7.2 0 0 0 1.4 2.3s-.4.3-1.6-.6c0 0-2.8-.8-4.6 0zm7.2 17c-2.4 2.1 0 1.9 0 1.9s2.4.2 0-2zm-1.4-13.2s-1.8.5-4-.7c0 0-2.6-1.4-4.3 0 0 0 1.5-.7 4.3.7 0 0 2.9 1.6 4 0z" />
    <path fill="#fff" d="M470.8 153.6s-1.5-2.5-6-2c0 0 1.2 3.6 6 2z" />
    <ellipse cx={467.7} cy={152.8} fill="#784421" rx={0.8} ry={1.1} />
    <ellipse cx={467.7} cy={152.8} rx={0.5} ry={0.7} />
    <path
      fill="none"
      stroke="#000"
      strokeWidth={1.2}
      d="M560 130.6v161.6c0 43.1-86.2 57.2-86.2 57.2s-86.4-14-86.4-57.2V130.6h172.7z"
    />
    <path fill="#006" d="M0 0h320v240H0z" />
    <path
      fill="#fff"
      d="m37.5 0 122 90.5L281 0h39v31l-120 89.5 120 89V240h-40l-120-89.5L40.5 240H0v-30l119.5-89L0 32V0z"
    />
    <path
      fill="#c8102e"
      d="M212 140.5 320 220v20l-135.5-99.5zm-92 10 3 17.5-96 72H0zM320 0v1.5l-124.5 94 1-22L295 0zM0 0l119.5 88h-30L0 21z"
    />
    <path fill="#fff" d="M120.5 0v240h80V0zM0 80v80h320V80z" />
    <path fill="#c8102e" d="M0 96.5v48h320v-48zM136.5 0v240h48V0z" />
  </svg>
)

export default BmFlag

import React from 'react';
import { Typography } from '@mui/material';

const Usage = () => {
  return (
    <div>
      <Typography variant="h4">Usage</Typography>
      {/* Usage content goes here */}
    </div>
  );
};

export default Usage;
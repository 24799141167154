import React, { useState, useEffect } from "react";
import { button_bg_color_for_New_Project, settingsModal_bg_color_dm, uploadFiles_button_default_bg_color, uploadFiles_button_hovered_bg_color } from '../../../../constants/colors'
import { Modal, Box, Typography, IconButton, Button, TextField, FormHelperText, InputLabel, MenuItem, Select, FormControl, Checkbox } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import { New_Project_Button_bg_color } from "../../../../Provider/Color_Provider";
import successIcon from "../../../../assets/greencheckIcon.png";
import errorIcon from "../../../../assets/error.png";
import Info from "../../../../assets/info.png";
import axios from "axios";
import sourceUrl from "../../../../api/Source.api";
import ErrorIcon from '@mui/icons-material/Error';
import { useMediaQuery } from "react-responsive";
import CommonToast from "../../../Common_Components/CommonToast";

const TrackWebsiteModal = ({onAddWebClicked}) => {

  const theme = localStorage.getItem('theme');
  const [window, setWindow] = useState(1);
  const [websitelink, setWebSiteLink] = useState("");
  const [saveas, setSaveAs] = useState("");
  const [checkforupdates, setCheckforupdates] = useState(15);
  const { openWebsiteTrackModal, setOpenWebsiteTrackModal } = useWorkspace()
  const token = localStorage.getItem('token')
  const isHeightUnder700px = useMediaQuery({ maxHeight : 749 });
  const isHeightUnder600px = useMediaQuery({ maxHeight : 649 });
  const isWidthUnder992px = useMediaQuery({ maxWidth : 992 });
  const [errorMessage,setErrorMessage] = useState()
  const [openCommanModalAfterWebsiteLinkEnter,setOpenCommanModalAfterWebsiteLinkEnter] = useState(false);
  const [openCommanModalforSecondWindow,setOpenCommanModalforSecondWindow] = useState(false);
  const [websiteLinkError, setWebSiteLinkError] = useState(false);
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  function isValidURL(url) {
    const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(:[0-9]{1,5})?(\/.*)?$/;
    return urlRegex.test(url);
  }

  const handleNextButtonClick = () => {
    if (websitelink === "") {
      setOpenCommanModalAfterWebsiteLinkEnter(true)
    }else if(isValidURL(websitelink)){
      setWindow(1)
    }else{
      setWebSiteLinkError(true)
    }
  }

  useEffect(() => {
    function isValidUrl(url) {
      // Regular expression to check for a valid URL pattern
      const urlPattern = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
      return urlPattern.test(url);
    }
  
    function getMainSiteName(url) {
      // Remove protocol (http, https, etc.) and 'www.' prefix if present
      let domain = url.replace(/(^\w+:|^)\/\//, '').replace(/^www\./, '');
      // Set the main site name to state
      setSaveAs(domain.split('.')[0]);
    }
  
    // Run only if websitelink is a valid URL
    if (websitelink && isValidUrl(websitelink)) {
      getMainSiteName(websitelink);
    }
  }, [websitelink]);

  const handleAddWebsiteButtonClick = async (websiteLink, displayName, frequencyInMins) => {
    if(!saveas.length || !checkforupdates){
      setOpenCommanModalforSecondWindow(true)
      return null;
    }
    if(!isValidURL(websitelink)){
      setWebSiteLinkError(true)
      return null;
    }
    const res = await InvokeAddWebsiteApi(websiteLink, displayName, frequencyInMins);
    if(res?.data?.status){
      setWindow(2);
      onAddWebClicked();
      // setOpenWebsiteTrackModal(false)
      setWebSiteLink('')
      setSaveAs('')
      setCheckforupdates('')
    }
  };
  
  const InvokeAddWebsiteApi = async (websiteLink, displayName, frequencyInMins) => {
    try {
      const response = await axios.post(
        `${sourceUrl}/v1/websiteTrackings`,
        { websiteLink, displayName, frequencyInMins },
        {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-type": "application/json",
          },
        }
      );
      return response.data; // axios returns the data directly
    } catch (error) {
      if(error?.response?.data?.error?.message === 'url not exists'){
        setErrorMessage('Invalid URL');
      }
      else{
        setErrorMessage(error?.response?.data?.error?.message);
      }
      
      setWindow(3);
      console.error("Error in API call", error?.response?.data?.error?.message);
      return null; // or handle error based on your logic
    }
  };
  

  const HandleCloseButtonClick = () => {
    setErrorMessage('');
    setOpenWebsiteTrackModal(false)
    setWindow(1)
  }

  const handleSubmitViewwebpagesummary = () => {
    setOpenWebsiteTrackModal(false)
    setWindow(1);
  }

  const handleCheckForUpdateSelect = (event) => {
    setCheckforupdates(event.target.value);
  };

  const handleRetryAddWebsite = () => {
    setErrorMessage('');
    setWindow(1);
  }

  const handleCloseToast = () => {
    setOpenCommanModalAfterWebsiteLinkEnter(false);
    setOpenCommanModalforSecondWindow(false)
  };

  const customTextFieldStyles = (isInvalid) => {
  return( { "& .MuiInputLabel-root": {
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
      letterSpacing: "0.5px",
      color: "rgba(255, 255, 255, 0.80)",
      "&.Mui-focused": {
        color: isInvalid ? "#F2B8B5 !important" : "rgba(255, 255, 255, 0.80)",
      },
    },
    "& .MuiInputBase-input": {
      color: "#FFFFFF",
    },
    "& #outlined-basic-label": {
      color: isInvalid ? "#F2B8B5 !important" : "rgba(255, 255, 255, 0.80)",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: isInvalid ? "#F2B8B5" : "rgba(255, 255, 255, 0.80)",
      },
      "&:hover fieldset": {
        borderColor: isInvalid ? "#F2B8B5" : "rgba(255, 255, 255, 0.80)",
      },
      "&.Mui-focused fieldset": {
        borderColor: isInvalid ? "#F2B8B5 !important" : "rgba(255, 255, 255, 0.80) ",
      },
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiInputLabel-root": {
      color: isInvalid ? "#F2B8B5 !important" : "rgba(255, 255, 255, 0.80) ",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: isInvalid ? "#F2B8B5 !important" : "rgba(255, 255, 255, 0.80) ",
    },})
  };

  const customTextFieldStylesforDropDownButton = {
    "& .MuiInputLabel-root": {
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
      letterSpacing: "0.5px",
      color: "rgba(255, 255, 255, 0.80)",
      "&.Mui-focused": {
        color: "rgba(255, 255, 255, 0.80) !important",
      },
    },
    "& .MuiInputBase-input": {
      color: "#FFFFFF",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(255, 255, 255, 0.80)",
      },
      "&:hover fieldset": {
        borderColor: "rgba(255, 255, 255, 0.80)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(255, 255, 255, 0.80) !important",
      },
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiInputLabel-root": {
      color: "rgba(255, 255, 255, 0.80) !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(255, 255, 255, 0.80) !important",
    },
  };




  return (
    <>
      <CommonToast 
        open={openCommanModalAfterWebsiteLinkEnter}
        onClose={handleCloseToast}
        content="Please Enter WebSite URL"
        bgColor="#282D51"
        fontColor="#fff"
        fontSize="14px"
        fontWeight="500"
        autoHideDuration={4000}
        iconType="error"
    />
        <CommonToast 
        open={openCommanModalforSecondWindow}
        onClose={handleCloseToast}
        content="Please fill all the fileds."
        bgColor="#282D51"
        fontColor="#fff"
        fontSize="14px"
        fontWeight="500"
        autoHideDuration={4000}
        iconType="error"
    />
    <Modal open={openWebsiteTrackModal}
      // onClose={handleClose}
      aria-labelledby="audio-settings-title"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          width: window === 2 || window === 3 ? '480px' : '748px',
          backgroundColor: 'background.paper',
          boxShadow: 24,
          display: 'flex',
          flexDirection: 'column',
          bgcolor: theme === 'dark' ? settingsModal_bg_color_dm : '',
          borderRadius: '8px',
        }}
      >
        <Typography
          id="audio-settings-title"
          variant="h6"
          component="h2"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height:"68px",
            // mb: 2,
            borderBottom: `1px solid ${theme === 'dark' ? '#535774' : ''}`,
            padding: '20px',
            font:"Inter",
          }}
          style={{ fontSize: '20px', fontWeight: '600', color: theme === 'dark' ? '#FFFFFF' : '' }}
        >
          Add a website for tracking
          <IconButton
            aria-label="close"
            onClick={HandleCloseButtonClick}
            sx={{
              // color: (theme) => theme.palette.grey[500],
              color : '#FFFFFF'
            }}
          >
            <CloseIcon />
          </IconButton>
        </Typography>

        <Box sx={{ display: "flex", flexDirection: "column", padding: ((isHeightUnder700px && window ===1) || (isHeightUnder600px) && window===0) ? "10px 20px 10px 20px"  : "20px 40px 20px 40px" }}>
          {/* {
            window === 0 &&
            <Box sx={{ overflow : ((isHeightUnder700px && window ===1) || (isHeightUnder600px) && window===0) ? "scroll" : "",height: ((isHeightUnder700px && window ===1) || (isHeightUnder600px) && window===0) ? "330px" : ""}}>
              <Box>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "20px",
                    fontWeight: 500,
                    lineHeight: "20px",
                    textAlign: "left", 
                    color: "#FFFFFF",
                    marginBottom: "24px",
                  }}>
                  Stay up to date with ModalX
                </Typography>
              </Box>

              <Box
                sx={{
                  // top: "36px",
                  opacity: "0px",
                }}>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "24px",
                    textAlign: "left",
                    color: "#FFFFFF",
                  }}>
                  You can track any website with ModalX. Just enter the URL, and ModalX will notify you whenever there is a change on the webpage, along with a summary of the update!
                </Typography>
              </Box>

              <Box sx={{ marginBottom: "40px", }}>
              <TextField
                type="weblink"
                onChange={(e) => {setWebSiteLinkError(false); setWebSiteLink(e.target.value)}}
                value={websitelink}
                id="outlined-basic"
                label="Website Link"
                variant="outlined"
                sx={customTextFieldStyles(websiteLinkError)}
                InputLabelProps={{
                  style: { color: "rgba(255, 255, 255, 0.6)" },
                }}
                style={{
                  width: "100%",
                  maxWidth: "100%",
                  height: "56px",
                  color: "#FFFFFF",
                  backgroundColor: "#232746",
                  borderRadius: "8px",
                  boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.40)",
                  marginTop: "40px",
                }}
              />

                <FormHelperText
                  sx={{
                    color: websiteLinkError ? "#F2B8B5" : "var(--M3-sys-dark-on-surface-variant, var(--Schemes-Outline-Variant, #CAC4D0))",
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "16px",
                    margin: "4px 0px 0px 16px",
                    alignSelf: "start",
                  }}
                >
                 {
                  websiteLinkError ? "Enter a valid URL" :
                  `eg. https://home.treasury.gov/policy-issues/tax-policy`
                 }
                </FormHelperText>
              </Box>

              <Box sx={{
                display: "flex",
                flexDirection: "row",
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "140%",
                textAlign: "left",
                gap: "8px",
                color: "rgba(255, 255, 255, 0.80)",
              }}>
                <Box>
                  <img src={Info} alt="info" />
                </Box>

                <Box sx={{

                }}>
                  <Typography sx={{
                    fontFamily : 'Inter',
                    fontSize : '12px',
                    fontWeight : 400,
                    lineHeight : '16.8px',
                    letterSpacing : '0.10000000149011612px',
                    textAlign : 'left', 
                    color : '#FFFFFF',
                    opacity : '0.80',
                  }}>
                  {`ModalX will track changes in text content on individual webpages such as example.com/policy, or example.com (home page). It will not track changes across the entire website ‘example.com’ or changes in styles or layouts.`}
                  </Typography>

                </Box>
              </Box>
            </Box>
          } */}
          {
            window === 1 &&
            <Box sx={{ overflow : ((isHeightUnder700px && window ===1) || (isHeightUnder600px) && window===0) ? "scroll" : "",height: ((isHeightUnder700px && window ===1) || (isHeightUnder700px) && window===0) ? "330px" : "", }}>
              <Box>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "20px",
                    fontWeight: 500,
                    lineHeight: "20px",
                    textAlign: "left",
                    color: "#FFFFFF",
                    marginBottom: "20px",
                  }}>
                  Stay up to date with ModalX
                </Typography>
              </Box>

              <Box
                sx={{
                  opacity: "0px",
                }}>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "24px",
                    textAlign: "left",
                    color: "#FFFFFF",
                  }}>
                  You can track any website with ModalX. Just enter the URL, and ModalX will notify you whenever there is a change on the webpage, along with a summary of the update!
                </Typography>
              </Box>
              <Box sx={{ marginBottom: 3, mt:5}}>
                <TextField
                  type="weblink"
                  // onChange={(e) => setWebSiteLink(e.target.value)}
                  onChange={(e) => {setWebSiteLinkError(false); setWebSiteLink(e.target.value); }}
                  value={websitelink}
                  id="outlined-basic"
                  label="Website Link"
                  variant="outlined"
                  sx={customTextFieldStyles(websiteLinkError) }
                  // sx={{...customTextFieldStyles(false),mt:5}}
                  style={{
                    width: "100%",
                    maxWidth: "100%",
                    height: "56px",
                    color: "#FFFFFF",
                    backgroundColor: "#232746",
                    borderRadius: "8px",
                    boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.40)",
                    placeholderfontsize: "16px",

                  }}
                />
                <FormHelperText
                  sx={{
                    color: websiteLinkError ? "#F2B8B5" : "var(--M3-sys-dark-on-surface-variant, var(--Schemes-Outline-Variant, #CAC4D0))",
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "16px",
                    margin: "4px 0px 0px 16px",
                    alignSelf: "start",
                  }}
                >
                 {
                  websiteLinkError ? "Enter a valid URL" :
                  `eg. https://home.treasury.gov/policy-issues/tax-policy`
                 }
                </FormHelperText>
              </Box>

              <Box sx={{ minWidth: "100%", marginBottom: 5 }}><FormControl fullWidth>
                <InputLabel id="demo-simple-select-label"   sx={{
                        color: "rgba(255, 255, 255, 0.80)",
                        "&.Mui-focused": {
                          color: "rgba(255, 255, 255, 0.80) !important",
                        }
                      }} >Check for updates:</InputLabel>
                <Select sx={{
                  ...customTextFieldStylesforDropDownButton, 
                  "& .MuiSelect-select": {
                    backgroundColor: '#282D51',
                  },
                  '.MuiSelect-icon': {
                      color: '#ffffff', 
                    },
                }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={checkforupdates}
                  label="Check for updates:"
                  onChange={handleCheckForUpdateSelect}
                >
                  <MenuItem sx={{
                    bgcolor: '#2F355F', marginTop: '-8px', marginBottom: '-8px', color: "white", height: '56px',
                    '&:hover': {
                      bgcolor: '#393F69'
                    },
                    '&.Mui-selected': {
                      bgcolor: '#282D51 !important', // Ensure selected background color stays
                    },
                  }} value={120}>Every 2 Hours</MenuItem>
                  <MenuItem sx={{
                    bgcolor: '#2F355F', marginTop: '-8px', marginBottom: '-8px', color: "white", height: '56px',
                    '&:hover': {
                      bgcolor: '#393F69'
                    },
                    '&.Mui-selected': {
                      bgcolor: '#393F69 !important', // Ensure selected background color stays
                    },
                  }} value={1440}>Every 1 day</MenuItem>
                  <MenuItem sx={{
                    bgcolor: '#2F355F', marginTop: '-8px', marginBottom: '-8px', color: "white", height: '56px',
                    '&:hover': {
                      bgcolor: '#393F69'
                    },
                    '&.Mui-selected': {
                      bgcolor: '#282D51 !important', // Ensure selected background color stays
                    },
                  }} value={10080}>Every 7 days</MenuItem>
                  <MenuItem sx={{
                    bgcolor: '#2F355F', marginTop: '-8px', marginBottom: '-8px', color: "white", height: '56px',
                    '&:hover': {
                      bgcolor: '#393F69'
                    },
                    '&.Mui-selected': {
                      bgcolor: '#393F69 !important', // Ensure selected background color stays
                    },
                  }} value={43200}>Every 30 days</MenuItem>
                </Select></FormControl></Box>

              <Box sx={{ marginBottom: 5, }}>
                <TextField
                  type="weblink"
                  onChange={(e) => setSaveAs(e.target.value)}
                  value={saveas}
                  id="outlined-basic"
                  label="Save As"
                  variant="outlined"
                  sx={customTextFieldStyles(false)}
                  style={{
                    width: "100%",
                    maxWidth: "100%",
                    height: "56px",
                    color: "#FFFFFF",
                    backgroundColor: "#232746",
                    borderRadius: "8px",
                    boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.40)",
                    placeholderfontsize: "16px",
                  }}
                /></Box>

              <Box sx={{
                display: "flex",
                flexDirection: "row",
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "140%",
                textAlign: "left",
                gap: "8px",
                color: "rgba(255, 255, 255, 0.80)",
              }}>
                <Box>
                  <img src={Info} alt="info" style={{width:"16px", height:"16px"}} />
                </Box>

                <Box sx={{

                }}>
                  <Typography sx={{
                    fontFamily : 'Inter',
                    fontSize : '12px',
                    fontWeight : 400,
                    lineHeight : '16.8px',
                    letterSpacing : '0.10000000149011612px',
                    textAlign : 'left', 
                    color : '#FFFFFF',
                    opacity : '0.80',
                  }}>
                  {`ModalX will track changes in text content on individual webpages such as example.com/policy, or example.com (home page). It will not track changes across the entire website ‘example.com’ or changes in styles or layouts.`}
                  </Typography>

                </Box>
              </Box>


               {/*  <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: "40px", padding: "0px", gap: "12px" }}>
                <Checkbox {...label} sx={{
                  padding: "0px", margin: "0px", color: "#CAC4D0",
                  '&.Mui-checked': { color: "#CAC4D0", },
                }} checked={true}/>
                <Box sx={{ padding: "0px" }}>{`Receive email notifications about webpage updates`}</Box>
              </Box>*/}
            </Box>
          }

          {
            window === 2 && (
              <Box
                onClick={(event) => event.stopPropagation()}
                sx={{}}>
                <Box sx={{}}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      style={{
                        width: "40px",
                        height: "40px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={successIcon} alt="success" style={{width:'40px',height:'40px'}} />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      width: "100%",
                      marginTop: "20px",
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "20px",
                        fontWeight: 600,
                        lineHeight: "20px",
                        alignSelf: "stretch",
                        textAlign: "center",
                        margin: "0px 0px",
                      }}
                    >
                      Website added
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      marginTop: "20px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "20px",
                        textAlign: "center",
                      }}
                    >
                      ModalX will track changes on this webpage and notify you here whenever there is an update
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      marginTop: "40px",
                      width: "100%",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        bgcolor: New_Project_Button_bg_color,
                        color: "rgba(23, 24, 44, 1)",
                        padding: "10px 24px 10px 24px",
                        fontFamily: "Inter",
                        fontSize: "16px",
                        fontWeight: 600,
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                        textAlign: "center",
                        width: "100%",
                        textTransform: "none",
                        "&:hover": {
                          bgcolor: "#ffd9aa",
                        },
                      }}
                    onClick={handleSubmitViewwebpagesummary}
                    >
                      View webpage summary
                    </Button>
                  </Box>
                </Box>
              </Box>
            )
          }

          {
            window === 3 && (
              <Box
                onClick={(event) => event.stopPropagation()}
                sx={{}}>
                <Box sx={{}}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      style={{
                        width: "40px",
                        height: "40px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {/* <ErrorIcon sx={{color:'red',width:'40px',height:'40px'}}/> */}
                      <img src={errorIcon} alt="error" style={{width:'40px',height:'40px'}} />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      width: "100%",
                      marginTop: "20px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "20px",
                        fontWeight: 600,
                        lineHeight: "20px",
                        alignSelf: "stretch",
                        textAlign: "center",
                        margin: "0px 0px",
                      }}
                    >
                      Website could not be added
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      marginTop: "20px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "20px",
                        textAlign: "center",
                      }}
                    >
                      {errorMessage !== ''?errorMessage : 'An error occurred while adding the website for tracking. Please try adding it again.'}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      marginTop: "40px",
                      width: "100%",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        bgcolor: New_Project_Button_bg_color,
                        color: "rgba(23, 24, 44, 1)",
                        padding: "10px 24px 10px 24px",
                        fontFamily: "Inter",
                        fontSize: "16px",
                        fontWeight: 600,
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                        textAlign: "center",
                        width: "100%",
                        textTransform: "none",
                        "&:hover": {
                          bgcolor: "#ffd9aa",
                        },
                      }}
                    onClick={handleRetryAddWebsite}
                    >
                      Retry adding website
                    </Button>
                  </Box>
                </Box>
              </Box>
            )
          }

        </Box>

        {
          ( window == 0 || window == 1 ) &&
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              boxShadow: "0px -4px 8px 0px rgba(0, 0, 0, 0.12)",
              p: 2,
            }}
          >
            <Button
              onClick={HandleCloseButtonClick}
              sx={{
                mr: 1,
                backgroundColor: theme === 'dark' ? uploadFiles_button_default_bg_color : '',
                '&:hover': {
                  backgroundColor: uploadFiles_button_hovered_bg_color,
                },
                color: '#17182C',
                fontSize: '16px',
                fontWeight: '600',
                textTransform: 'none',
                fontFamily: 'Inter, sans-serif',
              }}
            >
              Close
            </Button>
            {
              window === 0 ? <Button
                variant="contained"
                onClick={handleNextButtonClick}
                sx={{
                  backgroundColor: theme === 'dark' ? button_bg_color_for_New_Project : '',
                  '&:hover': {
                    backgroundColor: '#FFD9AA',
                  },
                  color: '#17182C',
                  fontSize: '16px',
                  fontWeight: '600',
                  textTransform: 'none',
                  fontFamily: 'Inter, sans-serif',
                }}
              >Next
              </Button> :
              <Button
                  variant="contained"
                  onClick={() => handleAddWebsiteButtonClick(websitelink,saveas,checkforupdates)}
                  sx={{
                    backgroundColor: theme === 'dark' ? button_bg_color_for_New_Project : '',
                    '&:hover': {
                      backgroundColor: '#FFD9AA',
                    },
                    color: '#17182C',
                    fontSize: '16px',
                    fontWeight: 600,
                    textTransform: 'none',
                    fontFamily: 'Inter, sans-serif',
                  }}
              >Add website</Button>
            }

          </Box>
        }
      </Box>
    </Modal>
    </>
  )
}

export default TrackWebsiteModal
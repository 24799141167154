import React from 'react'
import { Box } from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';

const CommanLinearStepLoader = ({currentStep}) => {

    const customStylesForProgressBar = () =>{
        return (
        { 
           backgroundColor:"#4A5076",
          "& .MuiInputLabel-root": {
          fontFamily: "Inter",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "24px",
          letterSpacing: "0.5px",
          color:  "rgba(255, 255, 255, 0.80)",
          },
          '& .MuiLinearProgress-bar': {
          borderRadius: 3, // Rounded corners for the progress bar
          backgroundColor: '#CAC4D0', // Progress bar color
        },
      
       }
        )
      }
 
      return (
        <Box sx={{ display: 'flex', flexDirection:"row", justifyContent:"space-between", alignItems: 'center', height:"48px", width:"100%" }}>
            {
            currentStep == 1 &&
            <Box  width={"100%"} sx={{ display: 'flex', flexDirection:"row", justifyContent:"space-between"}}>
            <Box width={"49%"} sx={{ display: 'flex', flexDirection:"row", justifyContent:"space-between"}}>
                <Box width={"49%"}>
                 <LinearProgress variant="determinate" value = {100} sx={()=>customStylesForProgressBar()}/>
                </Box>
                <Box width={"49%"}>
                 <LinearProgress variant="determinate" value = {0} sx={()=>customStylesForProgressBar()}/>
                </Box>
            </Box>
            <Box width={"49%"}>
                <LinearProgress variant="determinate" value = {0} sx={()=>customStylesForProgressBar()}/>
            </Box>
            </Box>
            }
            { 
            ( currentStep == 2 || currentStep == 3 ) &&
            <Box width={"100%"} sx={{ display: 'flex', flexDirection:"row", justifyContent:"space-between"}}>
            <Box width={"49%"}>
                <LinearProgress variant="determinate" value = {100} sx={()=>customStylesForProgressBar()}/>
            </Box>
             <Box width={"49%"}>
                <LinearProgress variant="determinate" value = {0} sx={()=>customStylesForProgressBar()}/>
            </Box>
            </Box>
            }
             { 
             currentStep == 4 &&
             <Box  width={"100%"} sx={{ display: 'flex', flexDirection:"row", justifyContent:"space-between"}}>
            <Box width={"49%"}>
                 <LinearProgress variant="determinate" value = {100} sx={()=>customStylesForProgressBar()}/>
             </Box>
             <Box width={"49%"} sx={{ display: 'flex', flexDirection:"row", justifyContent:"space-between"}}>
                 <Box width={"49%"}>
                  <LinearProgress variant="determinate" value = {100} sx={()=>customStylesForProgressBar()}/>
                 </Box>
                 <Box width={"49%"}>
                  <LinearProgress variant="determinate" value = {0} sx={()=>customStylesForProgressBar()}/>
                 </Box>
             </Box>
             </Box>
            }
            { 
             currentStep == 5 &&
             <Box  width={"100%"} sx={{ display: 'flex', flexDirection:"row", justifyContent:"space-between"}}>
            <Box width={"49%"}>
                 <LinearProgress variant="determinate" value = {100} sx={()=>customStylesForProgressBar()}/>
             </Box>
             <Box width={"49%"} sx={{ display: 'flex', flexDirection:"row", justifyContent:"space-between"}}>
                 <Box width={"49%"}>
                  <LinearProgress variant="determinate" value = {100} sx={()=>customStylesForProgressBar()}/>
            </Box>
             </Box>
             </Box>
            }
        </Box>
      );
}

export default CommanLinearStepLoader
import React, { useState, useEffect } from "react";
import {
  Box,
  Input,
  Button,
  Popover,
  MenuItem,
  InputAdornment,
  FormControl,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const DropdownForAudio = ({ filteredLanguages, setFilteredLanguages }) => {
  const { Audiolanguages, audioLanguage, setAudioLanguage } = useWorkspace();

  // console.log("filteredLanguages", filteredLanguages);

  const [anchorEl, setAnchorEl] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchValue(value);

    const filtered = Audiolanguages?.filter((lang) =>
      // console.log(lang)
      lang.name.toLowerCase().includes(value)
    );
    setFilteredLanguages(filtered);
  };

  const handleSelect = (language) => {
    // console.log("Selecting", language)
    setAudioLanguage(language.name);
    handleClose();
  };

  const open = Boolean(anchorEl);

  return (
    <FormControl>
      <Button
        onClick={handleOpen}
        sx={{
          color: audioLanguage?.name ? "rgba(255, 255, 255, 1)" : "rgba(202, 196, 208, 1)",
          backgroundColor: "#2F355F",
          borderRadius: "8px",
          height: "42px",
          minWidth: "120px",
          textTransform: "none",
          fontFamily: "Inter",
          fontWeight: 400,
          padding: "0px 8px 0px 12px",
          "&:hover": {
            backgroundColor: "#373E6D",
          },
        }}
      >
        {audioLanguage?.name || "English"}
        <ArrowDropDownIcon
          sx={{ color: "rgba(202, 196, 208, 1)", marginLeft: "auto" }}
        />
      </Button>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#2F355F",
            color: "white",
            borderRadius: "8px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
            maxHeight: "300px",
            width: "180px",
            marginTop: "4px",
          },
        }}
      >
        <Box sx={{ p: 1, borderBottom: "1px solid rgba(255,255,255,0.1)" }}>
          <Input
            fullWidth
            value={searchValue}
            onChange={handleSearch}
            placeholder="Search language"
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon sx={{ color: "rgba(255,255,255,0.7)" }} />
              </InputAdornment>
            }
            sx={{
              color: "white",
              "&:before, &:after": { borderBottom: "none" },
              "&:hover:not(.Mui-disabled):before": { borderBottom: "none" },
            }}
            disableUnderline={false}
          />
        </Box>

        <Box sx={{ overflowY: "auto", maxHeight: 250 }}>
          {filteredLanguages?.map((language) => (
            <MenuItem
              key={language.id}
              onClick={() => handleSelect(language)}
              sx={{
                "&:hover": { backgroundColor: "#373E6D" },
                "&.Mui-selected": { backgroundColor: "#373E6D" },
                py: 1,
                fontSize: 14,
                fontFamily: "Inter",
                color: "#FFFFFF",
                borderRadius: "",
              }}
            >
              {language?.name}
            </MenuItem>
          ))}
        </Box>
      </Popover>
    </FormControl>
  );
};

const DropdownForGender = ({
  genderOption,
  setFilteredVoices,
  setfilterCriteria,
  filterCriteria
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [selectedGender, setSelectedGender] = useState();
  const [allFilterVoiceGender, setAllFilterVoiceGender] = useState([]);
  const open = Boolean(anchorEl);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setAllFilterVoiceGender(genderOption);
  }, [genderOption]);

  useEffect(()=>{
    if(filterCriteria.gender === ""){
      setSelectedGender("")
    }
},[filterCriteria])

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchValue(value);
    const filtered = genderOption.filter((voice) =>
      voice.name.toLowerCase().includes(value)
    );
    setAllFilterVoiceGender(filtered);
  };

  const handleSelect = (genderObject) => {
    setfilterCriteria((prevFilter) => ({
      ...prevFilter,
      gender: genderObject?.gender,
    }));
    setSelectedGender(genderObject);
    handleClose();
  };

  return (
    <FormControl>
      <Button
        onClick={handleOpen}
        sx={{
          color: selectedGender?.name  ? "rgba(255, 255, 255, 1)" :  "rgba(202, 196, 208, 1)",
          backgroundColor: "#2F355F",
          borderRadius: "8px",
          height: "42px",
          minWidth: "120px",
          textTransform: "none",
          fontFamily: "Inter",
          fontWeight:400,
          "&:hover": {
            backgroundColor: "#373E6D",
          },
        }}
      >
        {selectedGender?.name || "Gender"}
        <ArrowDropDownIcon
          sx={{ color: selectedGender?.name  ? "rgba(255, 255, 255, 1)" : "rgba(202, 196, 208, 1)", marginLeft: "auto" }}
        />
      </Button>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#2F355F",
            color: "white",
            borderRadius: "8px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
            maxHeight: "300px",
            width: "180px",
            marginTop: "4px",
          },
        }}
      >
        <Box sx={{ p: 1, borderBottom: "1px solid rgba(255,255,255,0.1)" }}>
          <Input
            fullWidth
            value={searchValue}
            onChange={handleSearch}
            placeholder="Search language"
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon sx={{ color: "rgba(255,255,255,0.7)" }} />
              </InputAdornment>
            }
            sx={{
              color: "white",
              "&:before, &:after": { borderBottom: "none" },
              "&:hover:not(.Mui-disabled):before": { borderBottom: "none" },
            }}
            disableUnderline={false}
          />
        </Box>

        <Box sx={{ overflowY: "auto", maxHeight: 250 }}>
          {allFilterVoiceGender?.map((ele) => (
            <MenuItem
              key={ele.id}
              onClick={() => handleSelect(ele)}
              sx={{
                "&:hover": { backgroundColor: "#373E6D" },
                "&.Mui-selected": { backgroundColor: "#373E6D" },
                py: 1,
                fontSize: 14,
              }}
            >
              {ele.name}
            </MenuItem>
          ))}
        </Box>
      </Popover>
    </FormControl>
  );
};

const DropdownForAccent = ({
  accentOption,
  setFilteredVoices,
  setfilterCriteria,
  filterCriteria
}) => {
  // console.log("accentOption", accentOption)

  const [anchorEl, setAnchorEl] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [selectedAccent, setSelectedAccent] = useState();
  const [allFilterVoiceCountry, setAllFilterVoiceCountry] = useState([]);
  const open = Boolean(anchorEl);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setAllFilterVoiceCountry(accentOption);
  }, [accentOption]);

  useEffect(()=>{
    if(filterCriteria.accent === ""){
      setSelectedAccent("")
    }
},[filterCriteria])

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchValue(value);
    const filtered = accentOption.filter((ele) =>
      ele.accent.toLowerCase().includes(value)
    );
    setAllFilterVoiceCountry(filtered);
  };

  const handleSelect = (accentObject) => {
    console.log("Selecting", accentObject);
    setfilterCriteria((prevFilter) => ({
      ...prevFilter,
      accent: accentObject?.accent,
    }));
    setSelectedAccent(accentObject);
    handleClose();
  };

  return (
    <FormControl>
      <Button
        onClick={handleOpen}
        sx={{
          color: selectedAccent?.accent  ? "rgba(255, 255, 255, 1)" : "rgba(202, 196, 208, 1)",
          backgroundColor: "#2F355F",
          borderRadius: "8px",
          height: "42px",
          minWidth: "120px",
          textTransform: "none",
          fontFamily: "Inter",
          fontWeight:400,
          "&:hover": {
            backgroundColor: "#373E6D",
          },
        }}
      >
        {selectedAccent?.accent || "Accent"}
        <ArrowDropDownIcon
          sx={{ color:  selectedAccent?.accent  ? "rgba(255, 255, 255, 1)" :  "rgba(202, 196, 208, 1)", marginLeft: "auto" }}
        />
      </Button>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#2F355F",
            color: "white",
            borderRadius: "8px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
            maxHeight: "300px",
            width: "180px",
            marginTop: "4px",
          },
        }}
      >
        <Box sx={{ p: 1, borderBottom: "1px solid rgba(255,255,255,0.1)" }}>
          <Input
            fullWidth
            value={searchValue}
            onChange={handleSearch}
            placeholder="Search language"
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon sx={{ color: "rgba(255,255,255,0.7)" }} />
              </InputAdornment>
            }
            sx={{
              color: "white",
              "&:before, &:after": { borderBottom: "none" },
              "&:hover:not(.Mui-disabled):before": { borderBottom: "none" },
            }}
            disableUnderline={false}
          />
        </Box>

        <Box sx={{ overflowY: "auto", maxHeight: 250 }}>
          {allFilterVoiceCountry?.map((ele) => (
            <MenuItem
              key={ele.id}
              onClick={() => handleSelect(ele)}
              sx={{
                "&:hover": { backgroundColor: "#373E6D" },
                "&.Mui-selected": { backgroundColor: "#373E6D" },
                py: 1,
                fontSize: 14,
              }}
            >
              {ele.accent}
            </MenuItem>
          ))}
        </Box>
      </Popover>
    </FormControl>
  );
};

const DropdownForVoiceTone = ({
  voiceToneOption,
  setFilteredVoices,
  setfilterCriteria,
  filterCriteria
}) => {
  // console.log("voiceToneOption===", voiceToneOption);

  const [anchorEl, setAnchorEl] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [selectedVoiceTone, setSelectedVoiceTone] = useState();
  const [allFilterVoiceTone, setAllFilterVoiceTone] = useState([]);
  const open = Boolean(anchorEl);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
      setAllFilterVoiceTone(voiceToneOption);
  }, [voiceToneOption]);

    useEffect(()=>{
      if(filterCriteria.description === ""){
        setSelectedVoiceTone("")
      }
  },[filterCriteria])

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchValue(value);
    const filtered = voiceToneOption.filter((q) =>
      q.description.toLowerCase().includes(value)
    );
    setAllFilterVoiceTone(filtered);
  };

  const handleSelect = (qualityObj) => {
    // console.log("qualityObj", qualityObj)
    setfilterCriteria((prevFilter) => ({
      ...prevFilter,
      description : qualityObj?.description,
    }));
    setSelectedVoiceTone(qualityObj);
    handleClose();
  };

  return (
    <FormControl>
      <Button
        onClick={handleOpen}
        sx={{
          color: selectedVoiceTone?.name  ? "rgba(255, 255, 255, 1)" :  "rgba(202, 196, 208, 1)",
          backgroundColor: "#2F355F",
          borderRadius: "8px",
          height: "42px",
          minWidth: "120px",
          textTransform: "none",
          fontFamily: "Inter",
          fontWeight:400,
          "&:hover": {
            backgroundColor: "#373E6D",
          },
        }}
      >
        {selectedVoiceTone?.name || "Voice Tone"}
        <ArrowDropDownIcon
          sx={{ color: selectedVoiceTone?.name  ? "rgba(255, 255, 255, 1)" :   "rgba(202, 196, 208, 1)", marginLeft: "auto" }}
        />
      </Button>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#2F355F",
            color: "white",
            borderRadius: "8px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
            maxHeight: "300px",
            width: "180px",
            marginTop: "4px",
          },
        }}
      >
        <Box sx={{ p: 1, borderBottom: "1px solid rgba(255,255,255,0.1)" }}>
          <Input
            fullWidth
            value={searchValue}
            onChange={handleSearch}
            placeholder="Search language"
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon sx={{ color: "rgba(255,255,255,0.7)" }} />
              </InputAdornment>
            }
            sx={{
              color: "white",
              "&:before, &:after": { borderBottom: "none" },
              "&:hover:not(.Mui-disabled):before": { borderBottom: "none" },
            }}
            disableUnderline={false}
          />
        </Box>

        <Box sx={{ overflowY: "auto", maxHeight: 250 }}>
          {allFilterVoiceTone?.map((voicetoneObj) => (
            <MenuItem
              key={voicetoneObj?.id}
              onClick={() => handleSelect(voicetoneObj)}
              sx={{
                "&:hover": { backgroundColor: "#373E6D" },
                "&.Mui-selected": { backgroundColor: "#373E6D" },
                py: 1,
                fontSize: 14,
              }}
            >
              {voicetoneObj?.description}
            </MenuItem>
          ))}
        </Box>
      </Popover>
    </FormControl>
  );
};

export {
  DropdownForAudio,
  DropdownForGender,
  DropdownForAccent,
  DropdownForVoiceTone,
};

import React from 'react';
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// Icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ContactsIcon from '@mui/icons-material/Contacts';
import SettingsIcon from '@mui/icons-material/Settings';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import PieChartIcon from '@mui/icons-material/PieChart';
import BusinessIcon from '@mui/icons-material/Business';

import ModalXLogo from "../../../../assets/sidebarIcon/Sidebarcompanylogo.png";
import { useNavigate } from 'react-router-dom';

// Import components
import Dashboard from './VoiceAgent_Components/Dashboard';
import Contacts from './VoiceAgent_Components/Contacts';
import VoiceAgents from './VoiceAgent_Components/VoiceAgents';
import Automation from './VoiceAgent_Components/Automation';
import LLMAgent from './VoiceAgent_Components/LLMAgent';
import Usage from './VoiceAgent_Components/Usage';
import BusinessProfile from './VoiceAgent_Components/BusinessProfile';

const Sidebar = styled(Box)(({ theme }) => ({
  width: '282px',
  height: '1024px',
  borderRight: '1px solid rgba(255, 255, 255, 0.10)',
  background: '#141626',
  color: '#CAC4D0',
  padding: theme.spacing(2),
}));

const Content = styled(Box)({
  flexGrow: 1,
  padding: '24px',
});

const SidebarButton = styled(ListItemButton)({
  borderRadius: '4px',
  padding: '8px 12px',
  marginBottom: '4px',
  '&.Mui-selected': {
    backgroundColor: 'rgba(255, 255, 255, 0.10)',
  },
  '&.Mui-selected:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
  },
});

const SidebarText = styled(ListItemText)({
  '& .MuiTypography-root': {
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '20px',
  },
});

const SectionTitle = styled(Typography)({
  color: 'rgba(255, 255, 255, 0.80)',
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '20px',
  marginTop: '16px',
  marginBottom: '8px',
  paddingLeft: '12px',
});

const components = {
  0: Dashboard,
  1: Contacts,
  2: VoiceAgents,
  3: Automation,
  4: LLMAgent,
  5: Usage,
  6: BusinessProfile
};

const VoiceAgent = () => {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const navigate = useNavigate();

  const handleListItemClick = (index) => {
    setSelectedIndex(index);
  };

  const SelectedComponent = components[selectedIndex];

  return (
    <Box display="flex">
      <Sidebar>
        {/* Logo */}
        <Box sx={{ width: "140px", height: "40px", marginBottom: '24px' }}>
          <img width={"140px"} height={"40px"} src={ModalXLogo} alt="logo" />
        </Box>

        {/* Back to Workspace */}
        <List>
          <ListItem disablePadding>
            <SidebarButton onClick={() => navigate('/workspace')}>
              <ListItemIcon sx={{ minWidth: '24px', color: 'inherit' }}>
                <ArrowBackIcon sx={{ fontSize: '20px' }} />
              </ListItemIcon>
              <SidebarText primary="Back to workspace" />
            </SidebarButton>
          </ListItem>

          {/* Dashboard */}
          <ListItem disablePadding>
            <SidebarButton
              selected={selectedIndex === 0}
              onClick={() => handleListItemClick(0)}
            >
              <ListItemIcon sx={{ minWidth: '24px', color: 'inherit' }}>
                <DashboardIcon sx={{ fontSize: '20px' }} />
              </ListItemIcon>
              <SidebarText primary="Dashboard" />
            </SidebarButton>
          </ListItem>

          {/* CUSTOMERS Section */}
          <SectionTitle>CUSTOMERS</SectionTitle>
          <ListItem disablePadding>
            <SidebarButton
              selected={selectedIndex === 1}
              onClick={() => handleListItemClick(1)}
            >
              <ListItemIcon sx={{ minWidth: '24px', color: 'inherit' }}>
                <ContactsIcon sx={{ fontSize: '20px' }} />
              </ListItemIcon>
              <SidebarText primary="Contacts" />
            </SidebarButton>
          </ListItem>

          {/* COMMUNICATION Section */}
          <SectionTitle>COMMUNICATION</SectionTitle>
          <ListItem disablePadding>
            <SidebarButton
              selected={selectedIndex === 2}
              onClick={() => handleListItemClick(2)}
            >
              <ListItemIcon sx={{ minWidth: '24px', color: 'inherit' }}>
                <RecordVoiceOverIcon sx={{ fontSize: '20px' }} />
              </ListItemIcon>
              <SidebarText primary="Voice agents" />
            </SidebarButton>
          </ListItem>
          
          <ListItem disablePadding>
            <SidebarButton
              selected={selectedIndex === 3}
              onClick={() => handleListItemClick(3)}
            >
              <ListItemIcon sx={{ minWidth: '24px', color: 'inherit' }}>
                <SmartToyIcon sx={{ fontSize: '20px' }} />
              </ListItemIcon>
              <SidebarText primary="Automation" />
            </SidebarButton>
          </ListItem>

          {/* AI MODELS Section */}
          <SectionTitle>AI MODELS</SectionTitle>
          <ListItem disablePadding>
            <SidebarButton
              selected={selectedIndex === 4}
              onClick={() => handleListItemClick(4)}
            >
              <ListItemIcon sx={{ minWidth: '24px', color: 'inherit' }}>
                <RecordVoiceOverIcon sx={{ fontSize: '20px' }} />
              </ListItemIcon>
              <SidebarText primary="LLM Agent" />
            </SidebarButton>
          </ListItem>

          {/* ACCOUNT Section */}
          <SectionTitle>ACCOUNT</SectionTitle>
          <ListItem disablePadding>
            <SidebarButton
              selected={selectedIndex === 5}
              onClick={() => handleListItemClick(5)}
            >
              <ListItemIcon sx={{ minWidth: '24px', color: 'inherit' }}>
                <RecordVoiceOverIcon sx={{ fontSize: '20px' }} />
              </ListItemIcon>
              <SidebarText primary="Usage" />
            </SidebarButton>
          </ListItem>
          
          <ListItem disablePadding>
            <SidebarButton
              selected={selectedIndex === 6}
              onClick={() => handleListItemClick(6)}
            >
              <ListItemIcon sx={{ minWidth: '24px', color: 'inherit' }}>
                <BusinessIcon sx={{ fontSize: '20px' }} />
              </ListItemIcon>
              <SidebarText primary="Business Profile" />
            </SidebarButton>
          </ListItem>
        </List>
      </Sidebar>

      <Content>
        <SelectedComponent />
      </Content>
    </Box>
  );
};

export default VoiceAgent;
import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Typography,
  Box,
  Button,
  IconButton,
  CardMedia,
  useMediaQuery,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Pagination from "@mui/material/Pagination";

const userDetails = [
  {
    id: 386,
    email: "shabhankhan123456@gmail.com",
    firstName: "Shaban",
    lastName: "Khan",
    phoneNumber: "9044945896",
    onboardingStatus: true,
  },
  {
    id: 387,
    email: "faheem@gmail.com",
    firstName: "Shaban",
    lastName: "Khan",
    phoneNumber: "9889446402",
    onboardingStatus: true,
  },
];
const UserAccessManager = ({errorForUserManager,setErrorForUserManager,userForUserManager,loadingForUserManager,currentPageForUserManager,setCurrentPagesForUserManager,totalPagesForUserManager,totalUsersCountForUserManager}) => {
  const [search, setSearch] = useState(""); // Input value
  const [filteredUsers, setFilteredUsers] = useState([]); // Filtered list
  const [userExists, setUserExists] = useState(null); // Track if user exists
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [hover, setHover] = useState(false);

  // Fetch user data on component mount
  useEffect(() => {
    setFilteredUsers(userForUserManager);
  }, []);

  // Filtering Logic
  useEffect(() => {
    let tempFiltered = userForUserManager;

    // Filter based on email first
    if (email?.trim() !== "") {
      tempFiltered = tempFiltered?.filter((user) =>
        user?.email?.toLowerCase().includes(email?.toLowerCase())
      );
    }

    // Further filter based on phone number
    if (phone?.trim() !== "") {
      tempFiltered = tempFiltered?.filter((user) =>
        user?.phone_number?.includes(phone)
      );
    }

    setFilteredUsers(tempFiltered);
  }, [email, phone, userForUserManager]);

  // Handle giving access to new user
  const handleGiveAccess = () => {
    if (!search.trim()) return;
    console.log("Giving access to:", search);
    // Add logic to give access via API
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handlePageChange = (event, value) => {
    setCurrentPagesForUserManager(value); // Update the current page when a page is clicked
  };

  const theme = createTheme({
      typography: {
        fontFamily: "Inter",
      },
      components: {
        MuiPagination: {
          styleOverrides: {
            root: {
              "& .MuiPaginationItem-root": {
                color: "#ffffff",
                borderRadius: "20px",
                "&:hover": {
                  backgroundColor: "rgba(179, 168, 183, 0.26)",
                },
                fontSize: "14px",
              },
              "& .MuiPaginationItem-page.Mui-selected": {
                backgroundColor: "#CAC4D0",
                color: "black",
                "&:hover": {
                  backgroundColor: "#CAC4D0",
                },
              },
              "& .MuiPaginationItem-ellipsis": {
                color: "#ffffff",
              },
              "& .MuiPaginationItem-previousNext": {
                color: "#ffffff",
                borderRadius: "20px",
              },
            },
          },
        },
      },
    });

  return (
    <div style={{ padding: "20px", margin: "auto" }}>
      <Box sx={{ maxWidth: "500px", margin: "auto" }}>
        <h2>Admin Panel - User Check</h2>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          maxWidth: "80%",
          justifyContent: "space-between",
          margin: "50px auto",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", maxWidth: "50%" }}>
          <h5 style={{ display: "flex" }}>Search by Email</h5>
          <input
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={handleEmailChange}
            style={{
              padding: "10px",
              minWidth: "300px",
              marginBottom: "10px",
              height: "50px",
              borderRadius: "10px",
              border: "none",
            }}
          />
          <br />
          {/* <Button
    //    onClick={handleSubscription}  
      sx={{ padding: '5px 20px', cursor: 'pointer',
      border : 'none' , borderRadius : '5px' ,
      backgroundColor: 'green',
      color:"white",
      '&:hover':{
        backgroundColor:"darkgreen"
      } 
       }}>
        Submit
      </Button> */}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", maxWidth: "45%" }}>
          <h5 style={{ display: "flex" }}>Search by Phone Number</h5>
          <input
            type="number"
            placeholder="Enter Phone Number"
            value={phone}
            onChange={handlePhoneChange}
            style={{
              padding: "10px",
              minWidth: "300px",
              marginBottom: "10px",
              height: "50px",
              borderRadius: "10px",
              border: "none",
            }}
          />
          <br />
          {/* <Button
    //    onClick={handleSubscription} 
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
      
      style={{ padding: '5px 20px', cursor: 'pointer',
      border : 'none' , borderRadius : '5px' ,
      backgroundColor: hover ? 'darkgreen' : 'green',
      color:"white"  
       }}>
        Submit
      </Button> */}
        </Box>
        {/* <Button
          onClick={handleGiveAccess}
          sx={{
            padding: "5px 20px",
            cursor: "pointer",
            border: "none",
            borderRadius: "5px",
            maxHeight: "fit-content",
            backgroundColor: "green",
            color: "white",
            "&:hover": {
              backgroundColor: "darkgreen",
            },
          }}
        >
          Give Access
        </Button> */}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          margin: "auto",
          width: "fit-content",
        }}
      >
        <h2>Temporary Users List</h2>
      </Box>
      {/* {search && ( */}
      {/* <>
        {filteredUsers.length > 0 ? (
          <Box>
            {filteredUsers.map((user) => (
              <Box
                key={user.id}
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontSize: "16px",
                }}
              >
                {user.email} - {user.phone_number}
              </Box>
            ))}
          </Box>
        ) : (
          <Typography sx={{ color: "red" }}>No user found.</Typography>
        )}
      </> */}
      {/* )} */}

       <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between mb-3">
                <h5 className="card-title">Total Users Count:</h5>
                <h5 className="card-title text-primary">{totalUsersCountForUserManager}</h5>
              </div>
              <div className="overflow-auto" style={{ maxHeight: "400px" }}>
                {loadingForUserManager ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "100%" }}
                  >
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only"></span>
                    </div>
                  </div>
                ) : (
                  <ul className="list-group">
                    <div style={{display:"flex", flexDirection:"row"}}>
                      <li  className="list-group-item" style={{width:"300px"}}>User Email</li>
                      <li  className="list-group-item" style={{width:"180px"}}>Phone</li>
                      <li  className="list-group-item"  style={{width:"180px"}}>Inbound Call Count</li>
                      <li  className="list-group-item"  style={{width:"200px"}}>OutBound Call Count</li>
                    </div>
                    {filteredUsers?.map((user, index) => (
                      <div style={{display:"flex", flexDirection:"row"}}>
                      <li key={index} className="list-group-item" style={{width:"300px"}}>
                        {user?.email}
                      </li>
                      <li key={index} className="list-group-item" style={{width:"180px"}}>
                      {user?.phone_number}
                    </li>
                    <li key={index} className="list-group-item" style={{width:"180px"}}>
                      {user?.inbound_call_count}
                    </li>
                    <li key={index} className="list-group-item" style={{width:"200px"}}>
                      {user?.outbound_call_count}
                    </li>
                    </div>
                    ))}
                  </ul>
                )}
              </div>
              <div className="d-flex justify-content-center mt-3">
                <ThemeProvider theme={theme}>
                  <Pagination
                    count={totalPagesForUserManager} // Total number of pages
                    page={currentPageForUserManager} // Current page
                    siblingCount={1} // Show one previous and one upcoming page
                    boundaryCount={1} // Show first and last page buttons
                    onChange={handlePageChange} // Handle page change
                    shape="rounded" // Rounded buttons
                    size="large" // Larger buttons
                  />
                </ThemeProvider>
              </div>
            </div>
          </div>
    </div>
  );
};

export default UserAccessManager;

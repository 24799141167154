import React from 'react';
import { Typography } from '@mui/material';

const VoiceAgents = () => {
  return (
    <div>
      <Typography variant="h4">Voice Agents</Typography>
      {/* Voice Agents content goes here */}
    </div>
  );
};

export default VoiceAgents;
import { Box, Dialog, IconButton, Typography, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";

const CommanModalForConformation = ({
  open,
  modalTitle,
  modalMessage,
  handleConfirm,
  handleCancel,
}) => {
  const theme = localStorage.getItem('theme');
  return (
    <>
      <Dialog
        open={open}
        close={handleCancel}
        PaperProps={{
          sx: {
            width: "720px", // Increase the width as needed
            maxWidth:"720px",
            borderRadius: "8px", // Adjust if you want to change the border radius
          },
        }}
      >
        <Box
          sx={{
            width: "720px",
            maxWidth:"720px",
            // height: "200px",
            borderRadius: "8px",
            border: "1px solid rgba(255, 255, 255, 0.2)",
            bgcolor: "#171A2D",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              // justifyContent: "center",
              display: "flex",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "28px",
                marginTop: "20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0px 20px 0px 20px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: 600,
                  lineHeight: "20px",
                  textAlign: "left",
                  color: "#FFFFFF",
                }}
              >
                {modalTitle}
              </Typography>
              <Box
                sx={{
                  color: "#FFFFFF",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={handleCancel}
              >
                <CloseIcon />
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              width: "100%",
              padding: "0px 20px 0px 20px",
              display: "flex",
              justifyContent: "center",
              margin: "auto",
              marginTop: "20px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "20px",
                textAlign: "left",
                color: "rgba(255, 255, 255, 0.80)",
              }}
            >
              {modalMessage}
            </Typography>
          </Box>
          <Box
            sx={{
              marginTop: "20px",
              width: "100%",
              height: "72px",
              display: "flex",
              justifyContent: "right",
            }}
          >
            <Box
              sx={{
                width: "auto",
                height: "40px",
                marginRight: "16px",
                marginTop: "20px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                onClick={handleCancel}
                sx={{
                  width: "103px",
                  height: "40px",
                  textTransform: "none",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: 600,
                  textAlign: "center",
                  backgroundColor: theme === "dark" ? "" : "",
                  color: "rgba(255, 255, 255, 1)",
                  "&:hover": {
                    color:"rgba(255, 255, 255, 0.8)",
                    backgroundColor:"transparent"
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleConfirm}
                sx={{
                  width: "auto",
                  height: "40px",
                  textTransform: "none",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: 600,
                  textAlign: "center",
                  bgcolor: "#DD2025",
                  color: "#FFFFFF",
                  padding:"10px 24px",
                  flexWrap:"nowrap",
                  borderRadius:"8px",
                  "&:hover": {
                    backgroundColor: "#E6E0E91F",
                  },
                }}
              >
                Yes, delete avatar
              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default CommanModalForConformation;

import React, { useRef, useState } from 'react'
import { Box, Typography, Button, useMediaQuery, CardMedia, IconButton, Input, TextField, Dialog, DialogContent, FormControlLabel, Checkbox, Select, MenuItem, InputAdornment, Link } from "@mui/material";
import PageLayout from '../components/navFoot/PageLayout'
import ModalX from "../assets/LandingPage_Items/CallingAgent/Vic.png";
import CallVic from "../assets/LandingPage_Items/CallingAgent/CallVic.png";
import Whatsapp from "../assets/LandingPage_Items/CallingAgent/WhatsappVic.png";
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import Modalx_Logo from "../assets/Modalx_Dark_Logo.png";
import Picture from "../assets/Button/Picture.png";
import PDF from "../assets/Button/PDF.png";
import Music_Library from "../assets/Button/Music Library.png";
import Blog from "../assets/Button/Blog.png";
import presentation from "../assets/Button/presentation.png";
import search from "../assets/Button/search.png";
import translate from "../assets/Button/translate.png";
import videoIcon from "../assets/Button/videoIcon.png";
import image1 from '../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Image/image1.webp';
import image2 from '../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Image/image2.webp';
import image3 from '../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Image/image3.webp';
import image4 from '../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Image/image4.webp';
import image5 from '../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Image/image4.webp';
import video1 from '../assets/LandingPage_Items/LandingPageCapabilitiesIcon/video/video1.webp';
import video2 from '../assets/LandingPage_Items/LandingPageCapabilitiesIcon/video/video2.webp';
import video3 from '../assets/LandingPage_Items/LandingPageCapabilitiesIcon/video/video3.webp';
import video4 from '../assets/LandingPage_Items/LandingPageCapabilitiesIcon/video/video4.webp';
import video5 from '../assets/LandingPage_Items/LandingPageCapabilitiesIcon/video/video5.webp';
import audio1 from "../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Audio/audio1.webp";
import audio2  from "../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Audio/audio2.webp";
import audio3  from "../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Audio/audio3.webp";
import audio4  from "../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Audio/audio4.webp";
import audio5  from "../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Audio/audio5.webp";
import ppt1 from "../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Presentation/presentation1.webp";
import ppt2 from "../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Presentation/presentation2.webp";
import ppt3 from "../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Presentation/presentation3.webp";
import ppt4 from "../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Presentation/presentation4.webp";
import ppt5 from "../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Presentation/presentation5.webp";
import blog1 from "../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Blog/blog1.webp";
import blog2  from "../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Blog/blog2.webp";
import blog3  from "../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Blog/blog3.webp";
import blog4  from "../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Blog/blog4.webp";
import blog5  from "../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Blog/blog5.webp";
import translate1 from "../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Translation/Translation1.webp";
import translate2  from "../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Translation/Translation2.webp";
import translate3  from "../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Translation/Translation3.webp";
import translate4  from "../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Translation/Translation4.webp";
import translate5  from "../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Translation/Translation5.webp";
import report1  from "../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Report/report1.webp";
import report2  from "../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Report/report2.webp";
import report3  from "../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Report/report3.webp";
import report4  from "../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Report/report1.webp";
import report5  from "../assets/LandingPage_Items/LandingPageCapabilitiesIcon/Report/report2.webp";
import web1  from "../assets/LandingPage_Items/LandingPageCapabilitiesIcon/websearch/websearch1.webp";
import web2  from "../assets/LandingPage_Items/LandingPageCapabilitiesIcon/websearch/websearch2.webp";
import web3  from "../assets/LandingPage_Items/LandingPageCapabilitiesIcon/websearch/websearch3.webp";
import web4  from "../assets/LandingPage_Items/LandingPageCapabilitiesIcon/websearch/websearch1.webp";
import web5  from "../assets/LandingPage_Items/LandingPageCapabilitiesIcon/websearch/websearch2.webp";
import CheckedIcon from "../assets/LandingPage_Items/How It Works/Checked.png";
import CommanHoverModal from '../components/Common_Components/CommanHoverModal';
import ModalX_X_Icon from "../assets/LandingPage_Items/CallingAgent/ModalX_X_Icon.png";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { button_bg_color_for_New_Project_dm } from '../constants/colors';
import User1 from "../assets/LandingPage_Items/CallingAgent/image1.png";
import User2 from "../assets/LandingPage_Items/CallingAgent/image2.png";
import User3 from "../assets/LandingPage_Items/CallingAgent/image3.png";
import User4 from "../assets/LandingPage_Items/CallingAgent/image4.png";
import User5 from "../assets/LandingPage_Items/CallingAgent/image5.png";
import QR_Code from "../assets/LandingPage_Items/CallingAgent/QR Code New.png";
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import CallingAgentHeroImage from "../assets/LandingPage_Items/CallingAgent/CallingAgentHeroImageNew.png";
import CallingAgentHeroImageBelow991 from "../assets/LandingPage_Items/CallingAgent/CallingAgentHeroImageBelow991New.png";
import CloseIcon from "@mui/icons-material/Close";
import { MuiOtpInput } from "mui-one-time-password-input";
import EllipseGradient from "../assets/LandingPage_Items/CallingAgent/Ellipse Gradient.png";
import { requestCallBack, sendOtp, verifyOtp } from './CallingAgentApis';
import GetCountryFlagByName from '../components/workspace/settingBar/VideoGenSettings/GetCountryFlagByName';
import CommonToast from '../components/Common_Components/CommonToast';

const grids = [
    { id: 1,image:Picture, title: "Create an image", icon: CheckedIcon, name:"Image",
    imageArray: [
      { id: 1, name: "image", url: image1 },
      { id: 2, name: "image", url: image2 },
      { id: 3, name: "image", url: image3 },
      { id: 4, name: "image", url: image4 },
      { id: 5, name: "image", url: image5 },
    ],
    description: "Generate copyright-free images that effectively communicate your ideas.",
    url: "/imagegallery",
  },
    { id: 2,image:videoIcon, title: "Generate a video", icon: CheckedIcon, name:"Video",
      imageArray: [
        { id: 1, name: "image", url: video1 },
        { id: 2, name: "image", url: video2 },
        { id: 3, name: "image", url: video3 },
        { id: 4, name: "image", url: video4 },
        { id: 5, name: "image", url: video5 },
      ],
      description: "Generate video at scale for diverse audiences.",
      url: "/videogallery",
     },
    { id: 3,image:translate, title: "Translate a document", icon: CheckedIcon,name: "translation", 
      imageArray: [
        { id: 1, name: "image", url: translate1 },
        { id: 2, name: "image", url: translate2 },
        { id: 3, name: "image", url: translate3 },
        { id: 4, name: "image", url: translate4 },
        { id: 5, name: "image", url: translate5 },
      ], 
    description:"Get accurate translations while keeping the original context",
    url: "/workspace",
    },
    { id: 4,image:presentation, title: "Create a presentation", icon: CheckedIcon,  name: "Presentation",
      imageArray: [
        { id: 1, name: "image", url: ppt1 },
        { id: 2, name: "image", url: ppt2 },
        { id: 3, name: "image", url: ppt3 },
        { id: 4, name: "image", url: ppt4 },
        { id: 5, name: "image", url: ppt5 },
      ],
      description: "Generate impactful presentations that deliver value",
      url: "/presentationgallery", 
    },
    { id: 5,image:Modalx_Logo, title: "MODALX", icon: CheckedIcon,}, // Non-clickable grid
    { id: 6,image:search, title: "Find anything on the web", icon: CheckedIcon, name:"Search", 
      imageArray: [
        { id: 1, name: "image", url: web1 },
        { id: 2, name: "image", url: web2 },
        { id: 3, name: "image", url: web3 },
        { id: 4, name: "image", url: web4 },
        { id: 5, name: "image", url: web5 },
      ],
      description:"Get any information you want from the web through genuine sources",
      url:"/workspace",
    },
    { id: 7,image:Music_Library, title: "Generate audio", icon: CheckedIcon, name:"Speech",
      imageArray: [
        { id: 1, name: "image", url: audio1 },
        { id: 2, name: "image", url: audio2 },
        { id: 3, name: "image", url: audio3 },
        { id: 4, name: "image", url: audio4 },
        { id: 5, name: "image", url: audio5 },
      ],
      description: "Generate realistic audio in multiple languages seamlessly.",
      url: "/audiogallery" },
    { id: 8,image:Blog, title: "Write a blog", icon: CheckedIcon,name: "Blog", 
      imageArray: [
        { id: 1, name: "image", url: blog1 },
        { id: 2, name: "image", url: blog2 },
        { id: 3, name: "image", url: blog3 },
        { id: 4, name: "image", url: blog4 },
        { id: 5, name: "image", url: blog5 },
      ],
      description: "Generate engaging blogs that deliver value",
      url: "/blogarticle",
    },
    { id: 9,image:PDF, title: "Generate a report", icon: CheckedIcon,name: "Report", 
      imageArray: [
        { id: 1, name: "image", url: report1 },
        { id: 2, name: "image", url: report2 },
        { id: 3, name: "image", url: report3 },
        { id: 4, name: "image", url: report4 },
        { id: 5, name: "image", url: report5 },
      ],
      description: "Generate engaging report that deliver value",
      url: "/workspace",
     },
  ];

  const features = [
    {
      id: "1",
      image:User1,
      name:"Hanif Joshaghani",
      position:"CEO @ Symend"
    },
    {
      id: "2",
      image:User2,
      name:"Jeremy Barber",
      position:"CEO @ Combine Venture Builder"
    },
    {
      id: "3",
      image:User3,
      name:"Vikram Shekhawat",
      position:"CEO @ ModalX"
    },
    {
      id: "4",
      image:User4,
      name:"Anna Miller",
      position:"Head of PR @ Opus Ventures"
    },
    {
        id: "5",
        image:User5,
        name:"Jacob Giani",
        position:"CEO @ VNM"
    },
  ];
  
  
  
  const countryDatas = [
    // { id: 1, name: "Afghanistan", code: "AF", mobileCode: "+93" },
    // { id: 2, name: "Albania", code: "AL", mobileCode: "+355" },
    // { id: 3, name: "Algeria", code: "DZ", mobileCode: "+213" },
    // { id: 4, name: "Andorra", code: "AD", mobileCode: "+376" },
    // { id: 5, name: "Angola", code: "AO", mobileCode: "+244" },
    // { id: 6, name: "Antigua and Barbuda", code: "AG", mobileCode: "+1-268" },
    // { id: 7, name: "Argentina", code: "AR", mobileCode: "+54" },
    // { id: 8, name: "Armenia", code: "AM", mobileCode: "+374" },
    // { id: 9, name: "Australia", code: "AU", mobileCode: "+61" },
    // { id: 10, name: "Austria", code: "AT", mobileCode: "+43" },
    // { id: 11, name: "Azerbaijan", code: "AZ", mobileCode: "+994" },
    // { id: 12, name: "Bahamas", code: "BS", mobileCode: "+1-242" },
    // { id: 13, name: "Bahrain", code: "BH", mobileCode: "+973" },
    // { id: 14, name: "Bangladesh", code: "BD", mobileCode: "+880" },
    // { id: 15, name: "Barbados", code: "BB", mobileCode: "+1-246" },
    // { id: 16, name: "Belarus", code: "BY", mobileCode: "+375" },
    // { id: 17, name: "Belgium", code: "BE", mobileCode: "+32" },
    // { id: 18, name: "Belize", code: "BZ", mobileCode: "+501" },
    // { id: 19, name: "Benin", code: "BJ", mobileCode: "+229" },
    // { id: 20, name: "Bhutan", code: "BT", mobileCode: "+975" },
    // { id: 21, name: "Bolivia", code: "BO", mobileCode: "+591" },
    // { id: 22, name: "Bosnia and Herzegovina", code: "BA", mobileCode: "+387" },
    // { id: 23, name: "Botswana", code: "BW", mobileCode: "+267" },
    // { id: 24, name: "Brazil", code: "BR", mobileCode: "+55" },
    // { id: 25, name: "Brunei", code: "BN", mobileCode: "+673" },
    // { id: 26, name: "Bulgaria", code: "BG", mobileCode: "+359" },
    // { id: 27, name: "Burkina Faso", code: "BF", mobileCode: "+226" },
    // { id: 28, name: "Burundi", code: "BI", mobileCode: "+257" },
    // { id: 29, name: "Cabo Verde", code: "CV", mobileCode: "+238" },
    // { id: 30, name: "Cambodia", code: "KH", mobileCode: "+855" },
    // { id: 31, name: "Cameroon", code: "CM", mobileCode: "+237" },
    { id: 32, name: "Canada", code: "CA", mobileCode: "+1" },
    // { id: 33, name: "Central African Republic", code: "CF", mobileCode: "+236" },
    // { id: 34, name: "Chad", code: "TD", mobileCode: "+235" },
    // { id: 35, name: "Chile", code: "CL", mobileCode: "+56" },
    // { id: 36, name: "China", code: "CN", mobileCode: "+86" },
    // { id: 37, name: "Colombia", code: "CO", mobileCode: "+57" },
    // { id: 38, name: "Comoros", code: "KM", mobileCode: "+269" },
    // { id: 39, name: "Congo (Congo-Brazzaville)", code: "CG", mobileCode: "+242" },
    // { id: 40, name: "Congo (Congo-Kinshasa)", code: "CD", mobileCode: "+243" },
    // { id: 41, name: "Costa Rica", code: "CR", mobileCode: "+506" },
    // { id: 42, name: "Croatia", code: "HR", mobileCode: "+385" },
    // { id: 43, name: "Cuba", code: "CU", mobileCode: "+53" },
    // { id: 44, name: "Cyprus", code: "CY", mobileCode: "+357" },
    // { id: 45, name: "Czech Republic", code: "CZ", mobileCode: "+420" },
    // { id: 46, name: "Denmark", code: "DK", mobileCode: "+45" },
    // { id: 47, name: "Djibouti", code: "DJ", mobileCode: "+253" },
    // { id: 48, name: "Dominica", code: "DM", mobileCode: "+1-767" },
    // { id: 49, name: "Dominican Republic", code: "DO", mobileCode: "+1-809" },
    // { id: 50, name: "Ecuador", code: "EC", mobileCode: "+593" },
    // { id: 51, name: "Egypt", code: "EG", mobileCode: "+20" },
    // { id: 52, name: "El Salvador", code: "SV", mobileCode: "+503" },
    // { id: 53, name: "Equatorial Guinea", code: "GQ", mobileCode: "+240" },
    // { id: 54, name: "Eritrea", code: "ER", mobileCode: "+291" },
    // { id: 55, name: "Estonia", code: "EE", mobileCode: "+372" },
    // { id: 56, name: "Eswatini (Swaziland)", code: "SZ", mobileCode: "+268" },
    // { id: 57, name: "Ethiopia", code: "ET", mobileCode: "+251" },
    // { id: 58, name: "Fiji", code: "FJ", mobileCode: "+679" },
    // { id: 59, name: "Finland", code: "FI", mobileCode: "+358" },
    // { id: 60, name: "France", code: "FR", mobileCode: "+33" },
    // { id: 61, name: "Gabon", code: "GA", mobileCode: "+241" },
    // { id: 62, name: "Gambia", code: "GM", mobileCode: "+220" },
    // { id: 63, name: "Georgia", code: "GE", mobileCode: "+995" },
    // { id: 64, name: "Germany", code: "DE", mobileCode: "+49" },
    // { id: 65, name: "Ghana", code: "GH", mobileCode: "+233" },
    // { id: 66, name: "Greece", code: "GR", mobileCode: "+30" },
    // { id: 67, name: "Grenada", code: "GD", mobileCode: "+1-473" },
    // { id: 68, name: "Guatemala", code: "GT", mobileCode: "+502" },
    // { id: 69, name: "Guinea", code: "GN", mobileCode: "+224" },
    // { id: 70, name: "Guinea-Bissau", code: "GW", mobileCode: "+245" },
    // { id: 71, name: "Guyana", code: "GY", mobileCode: "+592" },
    // { id: 72, name: "Haiti", code: "HT", mobileCode: "+509" },
    // { id: 73, name: "Honduras", code: "HN", mobileCode: "+504" },
    // { id: 74, name: "Hungary", code: "HU", mobileCode: "+36" },
    // { id: 75, name: "Iceland", code: "IS", mobileCode: "+354" },
    // { id: 76, name: "India", code: "IN", mobileCode: "+91" },
    // { id: 77, name: "Indonesia", code: "ID", mobileCode: "+62" },
    // { id: 78, name: "Iran", code: "IR", mobileCode: "+98" },
    // { id: 79, name: "Iraq", code: "IQ", mobileCode: "+964" },
    // { id: 80, name: "Ireland", code: "IE", mobileCode: "+353" },
    // { id: 81, name: "Israel", code: "IL", mobileCode: "+972" },
    // { id: 82, name: "Italy", code: "IT", mobileCode: "+39" },
    // { id: 83, name: "Jamaica", code: "JM", mobileCode: "+1-876" },
    // { id: 84, name: "Japan", code: "JP", mobileCode: "+81" },
    // { id: 85, name: "Jordan", code: "JO", mobileCode: "+962" },
    // { id: 86, name: "Kazakhstan", code: "KZ", mobileCode: "+7" },
    // { id: 87, name: "Kenya", code: "KE", mobileCode: "+254" },
    // { id: 88, name: "Kiribati", code: "KI", mobileCode: "+686" },
    // { id: 89, name: "Kuwait", code: "KW", mobileCode: "+965" },
    // { id: 90, name: "Kyrgyzstan", code: "KG", mobileCode: "+996" },
    // { id: 91, name: "Laos", code: "LA", mobileCode: "+856" },
    // { id: 92, name: "Latvia", code: "LV", mobileCode: "+371" },
    // { id: 93, name: "Lebanon", code: "LB", mobileCode: "+961" },
    // { id: 94, name: "Lesotho", code: "LS", mobileCode: "+266" },
    // { id: 95, name: "Liberia", code: "LR", mobileCode: "+231" },
    // { id: 96, name: "Libya", code: "LY", mobileCode: "+218" },
    // { id: 97, name: "Liechtenstein", code: "LI", mobileCode: "+423" },
    // { id: 98, name: "Lithuania", code: "LT", mobileCode: "+370" },
    // { id: 99, name: "Luxembourg", code: "LU", mobileCode: "+352" },
    // { id: 100, name: "Madagascar", code: "MG", mobileCode: "+261" },
    // { id: 101, name: "Malawi", code: "MW", mobileCode: "+265" },
    // { id: 102, name: "Malaysia", code: "MY", mobileCode: "+60" },
    // { id: 103, name: "Maldives", code: "MV", mobileCode: "+960" },
    // { id: 104, name: "Mali", code: "ML", mobileCode: "+223" },
    // { id: 105, name: "Malta", code: "MT", mobileCode: "+356" },
    // { id: 106, name: "Marshall Islands", code: "MH", mobileCode: "+692" },
    // { id: 107, name: "Mauritania", code: "MR", mobileCode: "+222" },
    // { id: 108, name: "Mauritius", code: "MU", mobileCode: "+230" },
    // { id: 109, name: "Mexico", code: "MX", mobileCode: "+52" },
    // { id: 110, name: "Micronesia", code: "FM", mobileCode: "+691" },
    // { id: 111, name: "Moldova", code: "MD", mobileCode: "+373" },
    // { id: 112, name: "Monaco", code: "MC", mobileCode: "+377" },
    // { id: 113, name: "Mongolia", code: "MN", mobileCode: "+976" },
    // { id: 114, name: "Montenegro", code: "ME", mobileCode: "+382" },
    // { id: 115, name: "Morocco", code: "MA", mobileCode: "+212" },
    // { id: 116, name: "Mozambique", code: "MZ", mobileCode: "+258" },
    // { id: 117, name: "Myanmar", code: "MM", mobileCode: "+95" },
    // { id: 118, name: "Namibia", code: "NA", mobileCode: "+264" },
    // { id: 119, name: "Nauru", code: "NR", mobileCode: "+674" },
    // { id: 120, name: "Nepal", code: "NP", mobileCode: "+977" },
    // { id: 121, name: "Netherlands", code: "NL", mobileCode: "+31" },
    // { id: 122, name: "New Zealand", code: "NZ", mobileCode: "+64" },
    // { id: 123, name: "Nicaragua", code: "NI", mobileCode: "+505" },
    // { id: 124, name: "Niger", code: "NE", mobileCode: "+227" },
    // { id: 125, name: "Nigeria", code: "NG", mobileCode: "+234" },
    // { id: 126, name: "North Korea", code: "KP", mobileCode: "+850" },
    // { id: 127, name: "North Macedonia", code: "MK", mobileCode: "+389" },
    // { id: 128, name: "Norway", code: "NO", mobileCode: "+47" },
    // { id: 129, name: "Oman", code: "OM", mobileCode: "+968" },
    // { id: 130, name: "Pakistan", code: "PK", mobileCode: "+92" },
    // { id: 131, name: "Palau", code: "PW", mobileCode: "+680" },
    // { id: 132, name: "Panama", code: "PA", mobileCode: "+507" },
    // { id: 133, name: "Papua New Guinea", code: "PG", mobileCode: "+675" },
    // { id: 134, name: "Paraguay", code: "PY", mobileCode: "+595" },
    // { id: 135, name: "Peru", code: "PE", mobileCode: "+51" },
    // { id: 136, name: "Philippines", code: "PH", mobileCode: "+63" },
    // { id: 137, name: "Poland", code: "PL", mobileCode: "+48" },
    // { id: 138, name: "Portugal", code: "PT", mobileCode: "+351" },
    // { id: 139, name: "Qatar", code: "QA", mobileCode: "+974" },
    // { id: 140, name: "Romania", code: "RO", mobileCode: "+40" },
    // { id: 141, name: "Russia", code: "RU", mobileCode: "+7" },
    // { id: 142, name: "Rwanda", code: "RW", mobileCode: "+250" },
    // { id: 143, name: "Saint Kitts and Nevis", code: "KN", mobileCode: "+1-869" },
    // { id: 144, name: "Saint Lucia", code: "LC", mobileCode: "+1-758" },
    // { id: 145, name: "Saint Vincent and the Grenadines", code: "VC", mobileCode: "+1-784" },
    // { id: 146, name: "Samoa", code: "WS", mobileCode: "+685" },
    // { id: 147, name: "San Marino", code: "SM", mobileCode: "+378" },
    // { id: 148, name: "Sao Tome and Principe", code: "ST", mobileCode: "+239" },
    // { id: 149, name: "Saudi Arabia", code: "SA", mobileCode: "+966" },
    // { id: 150, name: "Senegal", code: "SN", mobileCode: "+221" },
    // { id: 151, name: "Serbia", code: "RS", mobileCode: "+381" },
    // { id: 152, name: "Seychelles", code: "SC", mobileCode: "+248" },
    // { id: 153, name: "Sierra Leone", code: "SL", mobileCode: "+232" },
    // { id: 154, name: "Singapore", code: "SG", mobileCode: "+65" },
    // { id: 155, name: "Slovakia", code: "SK", mobileCode: "+421" },
    // { id: 156, name: "Slovenia", code: "SI", mobileCode: "+386" },
    // { id: 157, name: "Solomon Islands", code: "SB", mobileCode: "+677" },
    // { id: 158, name: "Somalia", code: "SO", mobileCode: "+252" },
    // { id: 159, name: "South Africa", code: "ZA", mobileCode: "+27" },
    // { id: 160, name: "South Korea", code: "KR", mobileCode: "+82" },
    // { id: 161, name: "South Sudan", code: "SS", mobileCode: "+211" },
    // { id: 162, name: "Spain", code: "ES", mobileCode: "+34" },
    // { id: 163, name: "Sri Lanka", code: "LK", mobileCode: "+94" },
    // { id: 164, name: "Sudan", code: "SD", mobileCode: "+249" },
    // { id: 165, name: "Suriname", code: "SR", mobileCode: "+597" },
    // { id: 166, name: "Sweden", code: "SE", mobileCode: "+46" },
    // { id: 167, name: "Switzerland", code: "CH", mobileCode: "+41" },
    // { id: 168, name: "Syria", code: "SY", mobileCode: "+963" },
    // { id: 169, name: "Tajikistan", code: "TJ", mobileCode: "+992" },
    // { id: 170, name: "Tanzania", code: "TZ", mobileCode: "+255" },
    // { id: 171, name: "Thailand", code: "TH", mobileCode: "+66" },
    // { id: 172, name: "Timor-Leste", code: "TL", mobileCode: "+670" },
    // { id: 173, name: "Togo", code: "TG", mobileCode: "+228" },
    // { id: 174, name: "Tonga", code: "TO", mobileCode: "+676" },
    // { id: 175, name: "Trinidad and Tobago", code: "TT", mobileCode: "+1-868" },
    // { id: 176, name: "Tunisia", code: "TN", mobileCode: "+216" },
    // { id: 177, name: "Turkey", code: "TR", mobileCode: "+90" },
    // { id: 178, name: "Turkmenistan", code: "TM", mobileCode: "+993" },
    // { id: 179, name: "Tuvalu", code: "TV", mobileCode: "+688" },
    // { id: 180, name: "Uganda", code: "UG", mobileCode: "+256" },
    // { id: 181, name: "Ukraine", code: "UA", mobileCode: "+380" },
    // { id: 182, name: "United Arab Emirates", code: "AE", mobileCode: "+971" },
    // { id: 183, name: "United Kingdom", code: "GB", mobileCode: "+44" },
    { id: 184, name: "United States", code: "US", mobileCode: "+1" },
    // { id: 185, name: "Uruguay", code: "UY", mobileCode: "+598" },
    // { id: 186, name: "Uzbekistan", code: "UZ", mobileCode: "+998" },
    // { id: 187, name: "Vanuatu", code: "VU", mobileCode: "+678" },
    // { id: 188, name: "Vatican City", code: "VA", mobileCode: "+379" },
    // { id: 189, name: "Venezuela", code: "VE", mobileCode: "+58" },
    // { id: 190, name: "Vietnam", code: "VN", mobileCode: "+84" },
    // { id: 191, name: "Yemen", code: "YE", mobileCode: "+967" },
    // { id: 192, name: "Zambia", code: "ZM", mobileCode: "+260" },
    // { id: 193, name: "Zimbabwe", code: "ZW", mobileCode: "+263" }
  ];
  

const CallingAgent = ({place}) => {
    const isBelow991 = useMediaQuery("(max-width:991px)");
    const isBelow750 = useMediaQuery("(max-width:750px)");
    const isBetween992And1300 = useMediaQuery('(min-width:991px) and (max-width:1300px)');
    const [hoveredCard, setHoveredCard] = useState(null);
    const [open, setOpen] = useState(false);
    const [modalPosition, setModalPosition] = useState({
        top: 0,
        left: 0,
        right: 0,
        botton: 0,
    });
    const timeoutRef = useRef(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const totalBoxes = 6; // Total number of boxes in the carousel
    const visibleBoxes = 3; // Number of boxes visible at once
    const boxWidth = 386; // Width of each box in pixels (including margin)
    const [openVerifyModal, setOpenVerifyModal] = useState(false);
    const [step, setStep] = useState(1);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [otp, setOtp] = useState("");
    const [email, setEmail] = useState("");
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState(
        countryDatas.find((country) => country.name === "Canada")
      );
    const [idFromVerifyOtp,setIdFromVerifyOtp] = useState(null);
    const [searchCountry, setSearchCountry] = useState('');
    const [allFilterCountry, setAllFilterCountry] = useState(countryDatas);
      const [notifVariant, setNotifVariant] = useState("");
      const [notifMsg, setNotifMsg] = useState("");
    const [showNotif, setShowNotif] = useState(false);

    const handleNumberChange = (e) => {
        setPhoneNumber(e.target.value);
      };
    
      const handleCountryCodeChange = (country) => {
        // console.log("Country", country)
        setSelectedCountry(country);
      };
    
      const handleSearch = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        setSearchCountry(searchTerm);
        const filteredCountries = countryDatas.filter((country) =>
          country.name.toLowerCase().includes(searchTerm)
        );
        setAllFilterCountry(filteredCountries);
      };

    // const handleCountryChange = (event) => {
    //     const country = countryCodes.find(c => c.id === event.target.value);
    //     setSelectedCountry(country);
    //   };

    const CheckPhoneNumber = (number) =>{
        console.log("number", number)
        const phoneNumberRegex = /^\+?[1-9]\d{6,14}$/;
        return phoneNumberRegex.test(number)
      }

    const handleOpen = async () => {
        const number = `${selectedCountry?.mobileCode}${phoneNumber}`
        if(phoneNumber== "" || !CheckPhoneNumber(number)){
            console.log("Phone number", phoneNumber)
            setNotifMsg("Mobile number is required and must be valid.");
            setNotifVariant("error");
            setShowNotif(true);
            return;
          }
        if(phoneNumber){
            try{
                const response = await sendOtp({ phoneNumber:`${selectedCountry?.mobileCode}${phoneNumber}`, resend: false });
                if(response.status){
                    setOpenVerifyModal(true)
                }else if(response.status){
                    setNotifMsg("Mobile number is required and must be valid.");
                    setNotifVariant("error");
                    setShowNotif(true);
                    return;  
                }  
            }catch(error){
                console.log("error",error)
                setNotifMsg(error?.response?.data?.error?.message);
                setNotifVariant("error");
                setShowNotif(true);
                return;  
            }
        }
    };

    const handleCloseToast = () => {
        setShowNotif(false);
      };

    const handleResendOtp = async () => {
        if(phoneNumber){
            try{
            const response = await sendOtp({ phoneNumber:`${selectedCountry?.mobileCode}${phoneNumber}`, resend: true });
            if(response.status){
                setNotifMsg("Resend OTP Successfully!");
                setNotifVariant("success");
                setShowNotif(true);
            }
            }catch(error){
                setNotifMsg(error?.response?.data?.error?.message);
                setNotifVariant("error");
                setShowNotif(true);
                return;   
            }
        }
    };

    const handleClose = () => {
        setOpenVerifyModal(false);
        setStep(1);
        setPhoneNumber("");
        setOtp("");
        setEmail("");
        setTermsAccepted(false);
    };

    const handleVerifyOtp = async () => {
        if (otp.length === 6) {
            try {
                const response = await verifyOtp({ 
                    phoneNumber: `${selectedCountry?.mobileCode}${phoneNumber}`, 
                    otp 
                });
                
                if (response.status) {
                    setIdFromVerifyOtp(response?.data?.id); // Changed from response?.error?.data to response?.data?.id
                    if (response?.data?.is_email_verified && response?.data?.email) {
                        setEmail(response.data.email);
                    }
                    setStep(2);
                } else {
                    // Access the error message from the correct path
                    const errorMessage = response.error?.message || "Failed to verify OTP";
                    setNotifMsg(errorMessage);
                    setNotifVariant("error");
                    setShowNotif(true);
                    return;
                }
            } catch(e) {
                console.log("error", e);
                // Handle both axios error structure and other errors
                const errorMessage = e?.response?.data?.error?.message || 
                                   e?.message || 
                                   "An unexpected error occurred";
                setNotifMsg(errorMessage);
                setNotifVariant("error");
                setShowNotif(true);
                return;  
            }
        }
    };

    const handleSubmitRequest = async () => {
        if (termsAccepted && email) {
            try{
                const response = await requestCallBack({id:idFromVerifyOtp, phoneNumber:`${selectedCountry?.mobileCode}${phoneNumber}` , email});
                if(response.status){
                    setStep(3);
                }
            }catch(error){
                setNotifMsg(error?.response?.data?.error?.message);
                setNotifVariant("error");
                setShowNotif(true);
                return;  
            }
        }else{
            setNotifMsg("Please Accept the terms and enter your email address");
            setNotifVariant("error");
            setShowNotif(true);
            return; 
        }
    };

    const handlePrevClick = () => {
        if (currentIndex > 0) {
            setCurrentIndex((prevIndex) => prevIndex - 1);
        }
    };

    const handleNextClick = () => {
        if (currentIndex < totalBoxes - visibleBoxes) {
            setCurrentIndex((prevIndex) => prevIndex + 1);
        }
    };
    const handleHover = (event, card) => {
        clearTimeout(timeoutRef.current); // Clear previous timeout to avoid flickering
        const rect = event.currentTarget.getBoundingClientRect(); // Get card position
        timeoutRef.current = setTimeout(() => {
    
          const scrollTop = window.scrollY; // Current vertical scroll position
          const scrollLeft = window.scrollX; // Current horizontal scroll position
    
          const modalOffsetX = 100; // Offset for the modal's horizontal position
          const modalOffsetY = -340; // Offset for the modal's vertical position
        
           // Calculate the modal position relative to the document
          const modalTop = rect.top + scrollTop + modalOffsetY;
          const modalLeft = rect.left + scrollLeft + modalOffsetX;
        
          setModalPosition({
            top: modalTop,
            left: modalLeft,
          });
          setHoveredCard(card);
          setOpen(true);
        }, 500);
      };
    
      const handleLeave = () => {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
          setOpen(false);
          setHoveredCard(null);
        }, 200);
      };
      const handleModalHover = (isHovering) => {
        if (!open) return;
        if (isHovering) {
          clearTimeout(timeoutRef.current); // Keep modal open
        } else {
          timeoutRef.current = setTimeout(() => {
            setHoveredCard(null);
            setOpen(false); // Close modal when mouse leaves modal
          }, 500);
        }
      };

      const HandleDesktopCallButtonClick = () =>{
        setShowNotif(true);
        setNotifMsg("You trying to call Vic Please Try With Your Mobile")    
        setNotifVariant("error")
    }

    const HandleThisLinkClick = () => {
        window.open('https://whatsapp.com/channel/0029VaXYA6T7oQhloKVq431K', '_blank')
    }

  return (
    <>
    <PageLayout>
        <Box display="flex" flexDirection='column' justifyContent="center" bgcolor='#101220' height='100%' width={"100%"}>

            <Box display="flex" justifyContent="center">
                <img src={ModalX} alt="" style={{maxWidth:isBelow991?'333px':'1240px',maxHeight:'280px',marginTop:'80px',}} />
            </Box>

            <Box
                sx={{
                    maxWidth: "480px",
                    height: "365px",
                    position: "absolute",
                    top: "230px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    overflow:'hidden'
                }}
            >
                <img src={EllipseGradient} alt="Gradient Ellipse" style={{ maxWidth: "100%", height: "auto"}} />
            </Box> 
            <Typography
                sx={{
                    maxWidth: "1240px",
                    textAlign: "center",
                    fontFamily: "Inter",
                    fontSize: isBelow991?'40px':"80px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "100%", // 80px
                    letterSpacing: "-2.4px",
                    background: "linear-gradient(90deg, #F6BE6B 0%, rgba(246, 190, 107, 0.60) 100%)",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    margin: "0 auto",
                    marginTop:'20px'
                }}
            >
                The World’s Most Capable AI
            </Typography>
            <Typography
                sx={{
                    textAlign: "center",
                    fontFamily: "Inter",
                    fontSize: isBelow991?'16px':"24px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal",
                    letterSpacing: "0.1px",
                    opacity: 0.8,
                    background: "linear-gradient(90deg, rgba(255, 255, 255, 0.80) 0%, rgba(153, 153, 153, 0.80) 100%)",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    margin: "0 auto",
                    marginTop:'30px'
                }}
            >
                Meet Vic, powered by the best-in-class AI tools
            </Typography>
            <Box sx={{margin:'auto',marginTop:'46px'}}>
                <img alt="" src={isBelow991?CallingAgentHeroImageBelow991:CallingAgentHeroImage} style={{maxWidth:isBelow991?'358px':'950px',maxHeight:isBelow991?'444px':'515px'}}/>
            </Box>
            <Box>
                {
                    place == "india" ? 
                    // <Box sx={{display:"flex", flexDirection:"column", width:"430px", justifyContent:"center", alignSelf:"center", margin:"auto", marginTop:isBelow991 ? "40px" : "" }}>
                    // <Typography sx={{fontFamily:"Inter", fontSize:"16px", fontWeight:600, textAlign:"center", marginBottom:isBelow991 ? "20px" : "20px", }}>Call ModalX Now</Typography>
                    // <Box sx={{display:"flex", flexDirection: isBelow991 ? "column" : "row", rowGap:isBelow991? "16px" : "0px", justifyContent: isBelow991 ? "center" : "space-between", margin: isBelow991 && "auto"}}>
                    // <Button 
                    //                sx={{
                    //                  width: isBelow991 ? "350px" : "207px",
                    //                  height: "40px",
                    //                  textTransform: "none",
                    //                  fontFamily: "Inter",
                    //                  fontSize: "16px",
                    //                  fontWeight: 600,
                    //                  textAlign: "center",
                    //                  color: "rgba(246, 190, 106, 1)",
                    //                  border: "1px solid rgba(246, 190, 106, 1)",
                    //                  borderRadius: "8px",
                    //                  "&:hover": {
                    //                    backgroundColor: "#282D51",
                    //                  },
                    //                }}
                    //              >
                    //               English: 1800-123-456
                    // </Button>
                    // <Button 
                    //                sx={{
                    //                 width: isBelow991 ? "350px" : "207px",
                    //                  height: "40px",
                    //                  textTransform: "none",
                    //                  fontFamily: "Inter",
                    //                  fontSize: "16px",
                    //                  fontWeight: 600,
                    //                  textAlign: "center",
                    //                  color: "rgba(246, 190, 106, 1)",
                    //                  border: "1px solid rgba(246, 190, 106, 1)",
                    //                  borderRadius: "8px",
                    //                  "&:hover": {
                    //                    backgroundColor: "#282D51",
                    //                  },
                    //                }}
                    //              >
                    //              Hindi: 1800-123-789
                    // </Button>
                    // </Box>
                    // </Box>
                    null
                    :
                    isBelow991 ? 
                    <a href="tel:+18254508662" style={{ textDecoration: "none" }}> 
                    <Box
                       sx={{
                           display: "flex",
                           flexDirection: "row",
                           justifyContent: "center",
                           alignItems: "center",
                           padding: "12px 16px",
                           gap: "8px",
                           alignSelf: "stretch",
                           borderRadius: "8px",
                           border: "1px solid rgba(255, 255, 255, 0.20)",
                           background: "#F6BE6B",
                           boxShadow: "0px 0px 24px 0px rgba(253, 215, 157, 0.60)",
                           color: "#17182C",
                           textAlign: "center",
                           fontFamily: "Inter",
                           fontSize: "16px",
                           fontStyle: "normal",
                           fontWeight: 600,
                           lineHeight: "20px", // 100%
                           letterSpacing: "0.1px",
                           maxWidth: isBelow991 ? '350px' : '386px',
                           margin:'auto',
                           marginTop:isBelow991?'60px':'0px',
                           cursor: "pointer"
                       }}
                   >
                       <LocalPhoneOutlinedIcon sx={{ fontSize: "24px" }} />
                       Call Vic on +18254508662        
                   </Box> 
                    </a>
                    :
                    <Box onClick={HandleDesktopCallButtonClick}
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "12px 16px",
                        gap: "8px",
                        alignSelf: "stretch",
                        borderRadius: "8px",
                        border: "1px solid rgba(255, 255, 255, 0.20)",
                        background: "#F6BE6B",
                        boxShadow: "0px 0px 24px 0px rgba(253, 215, 157, 0.60)",
                        color: "#17182C",
                        textAlign: "center",
                        fontFamily: "Inter",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "20px", // 100%
                        letterSpacing: "0.1px",
                         maxWidth: isBelow991 ? '350px' :'386px',
                        margin:'auto',
                        marginTop:isBelow991?'60px':'0px',
                        cursor: "pointer"
                    }}
                >
                    <LocalPhoneOutlinedIcon sx={{ fontSize: "24px" }} />
                    Call Vic on +18254508662       
                    </Box> 
            
            }

                {  place == "india" ?  null :
                <Typography
                    sx={{
                    color: "rgba(255, 255, 255, 0.80)",
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "16px", // 133.333%
                    textAlign:'center',
                    margin:'12px auto'
                    }}
                >
                    OR
                </Typography>
                } 
                <Box
                    sx={{
                        maxWidth: isBelow991 ? '350px' : "386px",
                        display: "flex",
                        flexDirection:'column',
                        margin:'auto',
                    }}
                >
                    <Box sx={{
                        display:'flex',
                        flexDirection: "row", // Ensure items are in a single row
                        alignItems: "center", // Align vertically
                        gap: "10px", // Maintain spacing
                        maxHeight:'56px',
                        margin:'auto',
                        marginTop:'0px',
                        }}
                    >
                        <TextField
                            type="tel"
                            onChange={handleNumberChange}
                            value={phoneNumber}
                            label="Phone number*"
                            variant="outlined"
                            placeholder="Let Vic call you"
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start" sx={{ padding: "0px" }}>
                                    <Select
                                    value={selectedCountry?.id || ''}
                                    variant="standard"
                                    style={{ width: '80px', height: "40px", padding: "0px" }}
                                    renderValue={(selectedId) => {
                                        const country = countryDatas.find(c => c.id === selectedId);
                                        return (
                                        <Box sx={{ display: "flex", flexDirection: "row", columnGap: "10px", alignItems: "center", textAlign: "center" }}>
                                            <GetCountryFlagByName countryName={country?.name} />
                                            <p style={{ margin: "0px", color: "rgba(255, 255, 255, 0.80)" }}>{country?.mobileCode}</p>
                                        </Box>
                                        );
                                    }}
                                    sx={{
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                        },
                                        '&:hover:not(.Mui-disabled):before': {
                                        border: 'none',
                                        },
                                        '&::before': {
                                        borderBottom: 'none',
                                        },
                                        '&::after': {
                                        borderBottom: 'none',
                                        },
                                        color: "rgba(255, 255, 255, 0.80)",
                                        '& .MuiSvgIcon-root': {
                                        color: '#CAC4D0', // Dropdown icon color
                                        },
                                      }}
                                      MenuProps={{
                                        PaperProps: {
                                          sx: {
                                            backgroundColor: '#282D51',
                                            color: 'white',
                                            // Add other custom styles here
                                          },
                                        },
                                      }}
                                    SelectProps={{
                                        MenuProps: {
                                        PaperProps: {
                                            sx: {
                                            bgcolor: '#282D51',
                                            padding: "0px",
                                            '& .MuiMenuItem-root.Mui-selected': {
                                                bgcolor: '#282D51',
                                                color: 'white',
                                                '&:hover': {
                                                bgcolor: '#393F69',
                                                },
                                            },
                                            },
                                        },
                                        onClose: (event) => {
                                            if (event?.target?.id === "search-country-input") {
                                            event.stopPropagation();
                                            }
                                        }
                                        },
                                    }}
                                    >
                                    <Box sx={{ border: "", height: "90px", width: "200px", overflowX: "hidden", overflowY: "hidden", color: "#282D51", bgcolor: "#282D51", padding: "0px" }}>
                                      {/*  <Box sx={{ p: 1, borderBottom: "1px solid rgba(255,255,255,0.1)" }}>
                                        <Input
                                            value={searchCountry}
                                            onChange={handleSearch}
                                            placeholder="Search country"
                                            onMouseDown={(e) => e.stopPropagation()}
                                            onClick={(e) => e.stopPropagation()}
                                            startAdornment={
                                            <InputAdornment position="start">
                                                <SearchIcon sx={{ color: "rgba(255,255,255,0.7)" }} />
                                            </InputAdornment>
                                            }
                                            sx={{
                                            color: "white",
                                            '&:before, &:after': { borderBottom: 'none' },
                                            '&:hover:not(.Mui-disabled):before': { borderBottom: 'none' },
                                            }}
                                            disableUnderline={false}
                                        />
                                        </Box> */}
                                        {allFilterCountry.map((country) => (
                                        <MenuItem
                                            onClick={() => handleCountryCodeChange(country)}
                                            key={country?.id}
                                            value={country}
                                            sx={{
                                            bgcolor: country?.id === selectedCountry?.id ? "#373E6D" : '#282D51',
                                            marginTop: '-8px',
                                            marginBottom: '-8px',
                                            width: '200px',
                                            height: '56px',
                                            '&:hover': {
                                                bgcolor: '#393F69'
                                            },
                                            "&.Mui-selected": {
                                                backgroundColor: "#373E6D !important",
                                                color: "white",
                                            },
                                            }}
                                        >
                                            <Box sx={{ display: 'flex' }}>
                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: "row",
                                                width: '24px',
                                                height: '24px',
                                                marginRight: '12px',
                                                columnGap: "10px"
                                            }}>
                                                <GetCountryFlagByName countryName={country?.name} />
                                                <Typography sx={{
                                                display: 'block',
                                                color: "white",
                                                fontFamily: 'Inter',
                                                fontSize: '14px',
                                                textAlign: 'left',
                                                opacity: '80%'
                                                }}>{country.name} </Typography>
                                                <Typography component='span'
                                                sx={{
                                                    color: "white",
                                                    fontFamily: 'Inter',
                                                    fontSize: '14px',
                                                    textAlign: 'left',
                                                    opacity: '80%',
                                                    marginLeft: '5px'
                                                }}>{country.mobileCode}</Typography>
                                            </Box>
                                            </Box>
                                        </MenuItem>
                                        ))}
                                    </Box>
                                    </Select>
                                </InputAdornment>
                                )
                            }}
                            sx={{
                                width: "100%",
                                maxWidth: "340px",
                                height: "56px",
                                color: "#FFFFFF",
                                backgroundColor: "#171A2D",
                                borderRadius: "8px",
                                boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.40)",
                                '& .MuiInputLabel-root': {
                                color: 'rgba(255, 255, 255, 0.80)', // Label text color
                                fontFamily: 'Inter',
                                fontSize: '16px',
                                fontWeight: 400,
                                },
                                '& .MuiOutlinedInput-root': {
                                '& input': {
                                    color: "white", // Ensures input text remains white
                                    fontFamily: 'Inter',
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    cursor: "text", // Ensures cursor appears in the input field
                                },
                                '& input::placeholder': {
                                    color: "rgba(255, 255, 255, 0.80)",
                                    opacity: 1,
                                },
                                '& fieldset': {
                                    border: "1px solid rgba(255, 255, 255, 0.40)", // Default border color
                                    borderRadius: "8px",
                                },
                                '&:hover fieldset': {
                                    borderColor: "rgba(255, 255, 255, 0.40)", // No change on hover
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: "rgba(255, 255, 255, 0.80)", // Border color when focused
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: 'rgba(255, 255, 255, 0.80)', // Keeps the label color consistent
                                }
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: 'rgba(255, 255, 255, 1)', // Change to any color you want
                                },
                            }}  
                        />
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                alignSelf: "stretch",
                                borderRadius: "8px",
                                border: "1px solid #F7BE69",
                                padding: "12px 16px",
                                maxWidth: "106px",
                                flexShrink: 0, // Prevent button from shrinking
                                gap:'8px',
                                cursor:'pointer',
                                height:'56px',
                            }}
                            onClick={handleOpen}
                        >
                            <Typography
                                sx={{
                                color: "#F6BE6A",
                                textAlign: "center",
                                fontFamily: "Inter",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "20px",
                                letterSpacing: "0.1px",
                                }}
                            >
                                Submit
                            </Typography>
                            <ArrowForwardOutlinedIcon sx={{ fontSize: "20px", color: "#F6BE6A" }} />
                        </Box>
                    </Box>
                    <Typography
                        sx={{
                            color: "#CAC4D0", // Applying the color directly
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "16px", // 133.333%
                            maxWidth:isBelow991 ? "194px" :'236px',
                            marginLeft:'16px',
                            marginTop:'4px'
                        }}
                    >
                        Enter your phone number above, Vic will give you a call
                    </Typography>
                </Box>
            </Box>
            <Typography
            sx={{
                fontFamily: "Inter",
                fontSize: isBelow750?'24px':"40px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal",
                letterSpacing: "0.1px",
                opacity: 0.8,
                background: "linear-gradient(90deg, rgba(255, 255, 255, 0.80) 0%, rgba(153, 153, 153, 0.80) 100%)",
                backgroundClip: "text",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                textAlign: "center",
                marginTop:'100px'
            }}
            >
            Simply call or message Vic to Interact
            </Typography>
            <Box sx={{
                    maxWidth: '1280px',
                    gap: '40px',  // Reduced gap for better spacing on small screens
                    display: 'flex',
                    flexWrap: 'wrap',  // Allows items to wrap on smaller screens
                    margin: 'auto',
                    justifyContent: 'center',  // Center align for better responsiveness
                    alignItems: 'center',
                    marginTop: '60px',
                }}
            >
                {/* <Box sx={{padding:'36px 75px 0px 48px',borderRadius:'36px',border:'1px solid rgba(255,255,255,0.2)',maxWidth:'600px'}} display='flex' flexDirection='column' justifyContent='center' >
                    <Box display="flex" alignItems="center">
                        <Box
                            sx={{
                            width: "40px",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "#171A2D",
                            border: "1px solid rgba(255, 255, 255, 0.10)",
                            borderRadius: "50%", // Optional: If you want a circular icon background
                            marginRight: "16px",
                            }}
                        >
                            <LocalPhoneOutlinedIcon sx={{ fill: "white" }} />
                        </Box>
                        <Typography
                            sx={{
                            color: "#FFF",
                            fontFamily: "Inter",
                            fontSize: "36px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "normal",
                            letterSpacing: "0.1px",
                            }}
                        >
                            Call ModalX
                        </Typography>
                    </Box>
                    <Typography
                        sx={{
                            color: "rgba(255, 255, 255, 0.80)",
                            fontFamily: "Inter",
                            fontSize: "20px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "24px", // 120% of font size
                            letterSpacing: "0.1px",
                            marginTop:'14px'
                        }}
                    >
                        Directly talk to ModalX about anything you need
                    </Typography>
                    <img src={CallVic} style={{maxWidth:'450px',marginLeft:'20px',marginTop:'37px'}} />
                </Box> */}
                <Box>
                    <img src={CallVic} alt="" style={{maxWidth:isBelow750?'350px':'600px',maxHeight:'648px'}} />
                </Box>
                
                {/* <Box sx={{padding:'36px 36px 0px 48px',borderRadius:'36px',border:'1px solid rgba(255,255,255,0.2)',maxWidth:'600px'}} display='flex' flexDirection='column' justifyContent='center' >
                    <Box display="flex" alignItems="center">

                        <Box
                            sx={{
                            width: "40px",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "#171A2D",
                            border: "1px solid rgba(255, 255, 255, 0.10)",
                            borderRadius: "50%", // Circular background
                            marginRight: "16px",
                            }}
                        >
                            <QuestionAnswerOutlinedIcon sx={{ fill: "white" }} />
                        </Box>

                        <Typography
                            sx={{
                            color: "#FFF",
                            fontFamily: "Inter",
                            fontSize: "36px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "normal",
                            letterSpacing: "0.1px",
                            }}
                        >
                            Text or WhatsApp
                        </Typography>

                        <Box
                            sx={{
                            color: "#F6BE6B",
                            textAlign: "center",
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "16px",
                            letterSpacing: "0.1px",
                            display: "flex",
                            padding: "4px",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "8px",
                            border: "1px solid #F6BE6B",
                            borderRadius: "4px",
                            maxHeight: "24px",
                            marginLeft: "16px",
                            }}
                        >
                            Coming soon
                        </Box>
                    </Box>
                    <Typography
                        sx={{
                            color: "rgba(255, 255, 255, 0.80)",
                            fontFamily: "Inter",
                            fontSize: "20px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "24px", // 120% of font size
                            letterSpacing: "0.1px",
                            marginTop:'14px'
                        }}
                    >
                        Message ModalX anytime. Get responses in seconds
                    </Typography>
                    <img src={Whatsapp} style={{maxWidth:'600px',maxHeight:'648px'}} />
                </Box> */}
                <Box>
                    <img src={Whatsapp} alt="" style={{maxWidth:isBelow750?'350px':'600px',maxHeight:'648px'}} />
                </Box>
            </Box>
            {
                isBelow991 ? 
                <a href="tel:+18254508662" style={{ textDecoration: "none" }}> 
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "12px 15px",
                    gap: "8px",
                    alignSelf: "stretch",
                    borderRadius: "8px",
                    border: "1px solid rgba(255, 255, 255, 0.20)",
                    background: "#F6BE6B",
                    boxShadow: "0px 0px 24px 0px rgba(253, 215, 157, 0.60)",
                    color: "#17182C",
                    textAlign: "center",
                    fontFamily: "Inter",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "20px", // 100%
                    letterSpacing: "0.1px",
                    maxWidth:'229px',
                    margin:'auto',
                    marginTop:'80px',
                    cursor:'pointer'
                }}
            >
                <LocalPhoneOutlinedIcon sx={{ fontSize: "24px" }} />
                Call Vic now
            </Box></a>
                :
                <Box onClick={HandleDesktopCallButtonClick}
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "12px 15px",
                    gap: "8px",
                    alignSelf: "stretch",
                    borderRadius: "8px",
                    border: "1px solid rgba(255, 255, 255, 0.20)",
                    background: "#F6BE6B",
                    boxShadow: "0px 0px 24px 0px rgba(253, 215, 157, 0.60)",
                    color: "#17182C",
                    textAlign: "center",
                    fontFamily: "Inter",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "20px", // 100%
                    letterSpacing: "0.1px",
                    maxWidth:'229px',
                    margin:'auto',
                    marginTop:'80px',
                    cursor:'pointer'
                }}
            >
                <LocalPhoneOutlinedIcon sx={{ fontSize: "24px" }} />
                Call Vic now
            </Box>
            }
            
            <Typography
                sx={{
                    fontFamily: "Inter",
                    fontSize: isBelow991?'24px':"40px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    letterSpacing: "0.1px",
                    opacity: 0.8,
                    background: "linear-gradient(90deg, rgba(255, 255, 255, 0.80) 0%, rgba(153, 153, 153, 0.80) 100%)",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    margin:'auto',
                    marginTop:'80px',
                    textAlign:"center"
                }}
            >
                One AI Agent for All your Business Needs
            </Typography>
            <Box
                sx={{
                    maxWidth:isBetween992And1300 || isBelow750 ?"596px":'681px',
                    height: "auto",
                    maxHeight: isBelow750?'auto':"206px",
                    display: "grid",
                    gridTemplateColumns: isBelow750?"repeat(2, 1fr)":"repeat(3, 1fr)",
                    gap: isBelow991 ? '10px':"30px", // Gap between grids
                    justifyContent: "center",
                    justifyItems: "center",
                    "@media (max-width : 991px)": {
                    width: "100%",
                    padding: "0px 7.5% 0px 7.5%",
                    },
                    marginLeft:isBelow750?'': !isBelow991?'30px':'',
                    margin: "auto",
                    marginTop: "80px",
                }}
                >
                {grids.map((grid) =>
                    grid.id === 5 ? ( // Special styling for the 5th grid
                    <Box
                        key={grid.id}
                        sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "auto",
                        gridColumn: isBelow750 ? "1 / span 2" : "auto",
                        borderRadius:'50%',
                        marginTop:'-30px',
                        marginBottom:'-30px'
                        }}
                        src={ModalX_X_Icon}
                    >
                        <Box
                        component="img"
                        src={ModalX_X_Icon} // Replace with the appropriate image
                        alt="Special Grid Image"
                        sx={{
                            width: "120px",
                            height: "120px",
                        }}
                        />
                    </Box>
                    ) : (
                    <Box onMouseLeave={handleLeave}>
                    <Box  onMouseEnter={(e) => handleHover(e, grid)}
                        key={grid.id}
                        sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        minHeight: isBelow750 ? "36px" : "50px",
                        padding: isBetween992And1300 || isBelow750 ?'7px' : "12px",
                        borderRadius: "12px",
                        border: "1px solid rgba(255, 255, 255, 0.2)",
                        bgcolor: "1F233E",
                        cursor: "pointer",
                        "&:hover": { bgcolor: "#1F233E" },
                        marginLeft: isBetween992And1300 || isBelow750?'' : grid.id === 1 ? "39px" : grid.id === 7 ? "73px" : "12px",
                        marginRight: isBetween992And1300 || isBelow750?'' : grid.id === 3 ? "39px" : grid.id === 9 ? "73px" : "12px",
                        minWidth: isBelow750?'112px':"138px", // Minimum width for the grid
                        width: "auto", // Allow width to adjust based on content
                        }}
                        component={Button}
                    >
                        <Box
                        component="img"
                        src={grid.image}
                        alt={`${grid.title} Icon`}
                        sx={{
                            width: isBelow991?'20px':"24px",
                            height: isBelow991?'20px':"24px",
                            marginRight: isBelow991?'7px': "12px",
                        }}
                        />
                        <Typography
                        sx={{
                            color: "white",
                            fontFamily:"Inter",
                            fontSize: isBelow991?'12px':"14px",
                            fontWeight: 500,
                            lineHeight: "24px",
                            whiteSpace: "nowrap",
                            textTransform: "none",
                        }}
                        >
                        {grid.title}
                        </Typography>
                    </Box>
                    </Box>
                    )
                )}
            </Box>
            <Typography
                sx={{
                    fontFamily: "Inter",
                    fontSize: isBelow991?'24px':"40px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    letterSpacing: "0.1px",
                    opacity: 0.8,
                    background: "linear-gradient(90deg, rgba(255, 255, 255, 0.80) 0%, rgba(153, 153, 153, 0.80) 100%)",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    // margin:'auto',
                    padding:"0px 10px",
                    marginTop:'100px',
                    textAlign:"center"
                }}
            >
                Used by the best in the business
            </Typography>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                mt={4}
                sx={{ marginTop: isBelow991?'40px':"120px",marginLeft:isBelow991?'16px':'30px',marginRight:isBelow991?'30px':'' }}
            >
                <Box
                    overflow="auto"
                    // width={`${visibleBoxes * boxWidth + 162}px`}
                    display="flex"
                    position="relative"
                    sx={{
                        scrollBehavior: 'smooth', 
                        '&::-webkit-scrollbar': {
                        display: 'none', 
                        },
                        '-ms-overflow-style': 'none', 
                        'scrollbar-width': 'none', 
                    }}
                >
                    <Box
                        display="flex"
                        transition="transform 0.5s ease-in-out" // Changed the transition to ease-in-out
                        sx={{
                        transform: `translateX(-${currentIndex * boxWidth}px)`,
                        transitionProperty: "transform",
                        transitionDuration: "0.5s",
                        transitionTimingFunction: "ease-in-out", // Smooth transition
                        columnGap:isBelow991 ? '40px': "60px",
                        }}
                    >
                        {features.map((feature) => (
                        <Box position={"relative"}
                            key={feature.id}
                            sx={{
                            width: "240px",
                            height: "240px",
                            borderRadius: "36px",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            bgcolor: "#1F233E",
                            "&:hover": {
                                backgroundColor: "#282D51CC",
                                cursor: "pointer",
                            },
                            }}
                        >
                            <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                width: "240px",
                                height: "240px",
                                justifyContent: "space-evenly", // Ensures equal spacing between children
                                // height: '100%', // Adjust to the desired height
                                textAlign: "center",
                            }}
                            >
                                <img src={feature?.image} alt="" style={{width: "240px",
                            height: "240px",}}/>
                            </Box>
                            <Box sx={{position:"absolute", top:"184px",left:"15px", display:"flex", flexDirection:"column", rowGap:"8px" }}>
                               <Box sx={{color:"#FFFFFF", fontFamily:"Inter", fontSize:"16px", fontStyle:"normal", fontWeight:500, lineHeight:"16px"}}>{feature?.name}</Box> 
                               <Box sx={{color:"#FFFFFF", opacity:0.8 , fontFamily:"Inter", fontSize:"14px", fontStyle:"normal", fontWeight:500, lineHeight:"16px"}}>{feature?.position}</Box> 
                            </Box>
                        </Box>
                        ))}
                    </Box>
                </Box>
            </Box>
            <Box
                display="flex"
                justifyContent="center"
                sx={{
                maxWidth: "112px",
                height: "48px",
                display: "flex",
                justifyContent: "space-between",
                margin: "48px auto",
                gap:'16px'
                }}
            >
                <IconButton
                onClick={handlePrevClick}
                disabled={currentIndex === 0}
                disableRipple
                sx={{
                    bgcolor: "#1F233E",
                    color: "white",
                    "&:hover": {
                    backgroundColor: "#1B1E36",
                    },
                    "&:disabled": {
                    bgcolor: "#1F233E",
                    color: "grey",
                    },
                    width: "48px",
                    height: "49px",
                }}
                >
                <ChevronLeftIcon />
                </IconButton>
                <IconButton
                onClick={handleNextClick}
                disabled={currentIndex >= totalBoxes - visibleBoxes}
                disableRipple
                sx={{
                    bgcolor: "#1F233E",
                    color: "white",
                    "&:hover": {
                    backgroundColor: "#1B1E36",
                    },
                    "&:disabled": {
                    bgcolor: "#1F233E",
                    color: "grey",
                    },
                    width: "48px",
                    height: "49px",
                }}
                >
                <KeyboardArrowRightIcon />
                </IconButton>
            </Box>
            <Typography
                sx={{
                    fontFamily: "Inter",
                    fontSize: isBelow991?'24px':"40px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    letterSpacing: "0.1px",
                    opacity: 0.8,
                    background: "linear-gradient(90deg, rgba(255, 255, 255, 0.80) 0%, rgba(153, 153, 153, 0.80) 100%)",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    margin:'auto',
                    marginTop:'60px',
                    textAlign:"center",
                    marginBottom: isBelow991 ? "0px" : '60px',
                    maxWidth : isBelow991 ? "350px" : "full"
                }}
            >
                Ready to experience the future?
            </Typography>
            {
                    place == "india" ? 
                    // <Box sx={{display:"flex", flexDirection:"column", width:"430px", justifyContent:"center", alignSelf:"center", margin:"auto", marginTop:isBelow991 ? "40px" : "" }}>
                    // <Typography sx={{fontFamily:"Inter", fontSize:"16px", fontWeight:600, textAlign:"center", marginBottom:isBelow991 ? "20px" : "20px", }}>Call ModalX Now</Typography>
                    // <Box sx={{display:"flex", flexDirection: isBelow991 ? "column" : "row", rowGap:isBelow991? "16px" : "0px", justifyContent: isBelow991 ? "center" : "space-between", margin: isBelow991 && "auto"}}>
                    // <Button 
                    //                sx={{
                    //                  width: isBelow991 ? "350px" : "207px",
                    //                  height: "40px",
                    //                  textTransform: "none",
                    //                  fontFamily: "Inter",
                    //                  fontSize: "16px",
                    //                  fontWeight: 600,
                    //                  textAlign: "center",
                    //                  color: "rgba(246, 190, 106, 1)",
                    //                  border: "1px solid rgba(246, 190, 106, 1)",
                    //                  borderRadius: "8px",
                    //                  "&:hover": {
                    //                    backgroundColor: "#282D51",
                    //                  },
                    //                }}
                    //              >
                    //               English: 1800-123-456
                    // </Button>
                    // <Button 
                    //                sx={{
                    //                 width: isBelow991 ? "350px" : "207px",
                    //                  height: "40px",
                    //                  textTransform: "none",
                    //                  fontFamily: "Inter",
                    //                  fontSize: "16px",
                    //                  fontWeight: 600,
                    //                  textAlign: "center",
                    //                  color: "rgba(246, 190, 106, 1)",
                    //                  border: "1px solid rgba(246, 190, 106, 1)",
                    //                  borderRadius: "8px",
                    //                  "&:hover": {
                    //                    backgroundColor: "#282D51",
                    //                  },
                    //                }}
                    //              >
                    //              Hindi: 1800-123-789
                    // </Button>
                    // </Box>
                    // </Box>
                    null
                    :
                                // <Box
                                //     sx={{
                                //         display: "flex",
                                //         flexDirection: "row",
                                //         justifyContent: "center",
                                //         alignItems: "center",
                                //         padding: "12px 16px",
                                //         gap: "8px",
                                //         alignSelf: "stretch",
                                //         borderRadius: "8px",
                                //         border: "1px solid rgba(255, 255, 255, 0.20)",
                                //         background: "#F6BE6B",
                                //         boxShadow: "0px 0px 24px 0px rgba(253, 215, 157, 0.60)",
                                //         color: "#17182C",
                                //         textAlign: "center",
                                //         fontFamily: "Inter",
                                //         fontSize: "16px",
                                //         fontStyle: "normal",
                                //         fontWeight: 600,
                                //         lineHeight: "20px", // 100%
                                //         letterSpacing: "0.1px",
                                //         maxWidth:'360px',
                                //         margin:'auto',
                                //         marginTop:'64px'
                                //     }}
                                // >
                                //     <LocalPhoneOutlinedIcon sx={{ fontSize: "24px" }} />
                                //     Call ModalX on 1800-123-456
                                // </Box>
                                isBelow991 ? 
                                <a href="tel:+18254508662" style={{ textDecoration: "none" }}> 
                                <Box
                                   sx={{
                                       display: "flex",
                                       flexDirection: "row",
                                       justifyContent: "center",
                                       alignItems: "center",
                                       padding: "12px 16px",
                                       gap: "8px",
                                       alignSelf: "stretch",
                                       borderRadius: "8px",
                                       border: "1px solid rgba(255, 255, 255, 0.20)",
                                       background: "#F6BE6B",
                                       boxShadow: "0px 0px 24px 0px rgba(253, 215, 157, 0.60)",
                                       color: "#17182C",
                                       textAlign: "center",
                                       fontFamily: "Inter",
                                       fontSize: "16px",
                                       fontStyle: "normal",
                                       fontWeight: 600,
                                       lineHeight: "20px", // 100%
                                       letterSpacing: "0.1px",
                                       width: isBelow991 ? '350px' : '386px',
                                       margin:'auto',
                                       marginTop:isBelow991?'60px':'0px',
                                       cursor: "pointer"
                                   }}
                               >
                                   <LocalPhoneOutlinedIcon sx={{ fontSize: "24px" }} />
                                   Call Vic on +18254508662        
                               </Box> 
                                </a>
                                :
                                <Box onClick={HandleDesktopCallButtonClick}
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "12px 16px",
                                    gap: "8px",
                                    alignSelf: "stretch",
                                    borderRadius: "8px",
                                    border: "1px solid rgba(255, 255, 255, 0.20)",
                                    background: "#F6BE6B",
                                    boxShadow: "0px 0px 24px 0px rgba(253, 215, 157, 0.60)",
                                    color: "#17182C",
                                    textAlign: "center",
                                    fontFamily: "Inter",
                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    lineHeight: "20px", // 100%
                                    letterSpacing: "0.1px",
                                    width: isBelow991 ? '350px' :'386px',
                                    margin:'auto',
                                    marginTop:isBelow991?'60px':'0px',
                                    cursor: "pointer"
                                }}
                            >
                                <LocalPhoneOutlinedIcon sx={{ fontSize: "24px" }} />
                                Call Vic on +18254508662       
                                </Box> 
                        
                        }
        
            <Box
                sx={{
                    maxWidth: isBelow991 ? '350px' : "386px",
                    display: "flex",
                    flexDirection:'column',
                    margin:'auto',
                }}
            >
                <Box sx={{
                    display:'flex',
                    flexDirection: "row", // Ensure items are in a single row
                    alignItems: "center", // Align vertically
                    gap: "10px", // Maintain spacing
                    maxHeight:'56px',
                    margin:'auto',
                    marginTop:'60px',
                    }}
                >
                    <TextField
                        type="tel"
                        onChange={handleNumberChange}
                        value={phoneNumber}
                        label="Phone number*"
                        variant="outlined"
                        placeholder="Let Vic call you"
                        InputProps={{
                            startAdornment: (
                            <InputAdornment position="start" sx={{ padding: "0px" }}>
                                <Select
                                value={selectedCountry?.id || ''}
                                variant="standard"
                                style={{ width: '80px', height: "40px", padding: "0px" }}
                                renderValue={(selectedId) => {
                                    const country = countryDatas.find(c => c.id === selectedId);
                                    return (
                                    <Box sx={{ display: "flex", flexDirection: "row", columnGap: "10px", alignItems: "center", textAlign: "center" }}>
                                        <GetCountryFlagByName countryName={country?.name} />
                                        <p style={{ margin: "0px", color: "rgba(255, 255, 255, 0.80)" }}>{country?.mobileCode}</p>
                                    </Box>
                                    );
                                }}
                                sx={{
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                    },
                                    '&:hover:not(.Mui-disabled):before': {
                                    border: 'none',
                                    },
                                    '&::before': {
                                    borderBottom: 'none',
                                    },
                                    '&::after': {
                                    borderBottom: 'none',
                                    },
                                    color: "rgba(255, 255, 255, 0.80)",
                                    '& .MuiSvgIcon-root': {
                                    color: '#CAC4D0', // Dropdown icon color
                                    },
                                }}
                                MenuProps={{
                                    PaperProps: {
                                      sx: {
                                        backgroundColor: '#282D51',
                                        color: 'white',
                                        // Add other custom styles here
                                      },
                                    },
                                  }}
                                SelectProps={{
                                    MenuProps: {
                                    PaperProps: {
                                        sx: {
                                        bgcolor: '#282D51',
                                        padding: "0px",
                                        '& .MuiMenuItem-root.Mui-selected': {
                                            bgcolor: '#282D51',
                                            color: 'white',
                                            '&:hover': {
                                            bgcolor: '#393F69',
                                            },
                                        },
                                        },
                                    },
                                    onClose: (event) => {
                                        if (event?.target?.id === "search-country-input") {
                                        event.stopPropagation();
                                        }
                                    }
                                    },
                                }}
                                >
                                <Box sx={{ border: "", height: "90px", width: "200px", overflowX: "hidden", overflowY: "hidden", color: "#282D51", bgcolor: "#282D51", padding: "0px" }}>
                                    {/* <Box sx={{ p: 1, borderBottom: "1px solid rgba(255,255,255,0.1)" }}>
                                    <Input
                                        value={searchCountry}
                                        onChange={handleSearch}
                                        placeholder="Search country"
                                        onMouseDown={(e) => e.stopPropagation()}
                                        onClick={(e) => e.stopPropagation()}
                                        startAdornment={
                                        <InputAdornment position="start">
                                            <SearchIcon sx={{ color: "rgba(255,255,255,0.7)" }} />
                                        </InputAdornment>
                                        }
                                        sx={{
                                        color: "white",
                                        '&:before, &:after': { borderBottom: 'none' },
                                        '&:hover:not(.Mui-disabled):before': { borderBottom: 'none' },
                                        }}
                                        disableUnderline={false}
                                    />
                                    </Box> */}
                                    {allFilterCountry.map((country) => (
                                    <MenuItem
                                        onClick={() => handleCountryCodeChange(country)}
                                        key={country?.id}
                                        value={country}
                                        sx={{
                                        bgcolor: country?.id === selectedCountry?.id ? "#373E6D" : '#282D51',
                                        marginTop: '-8px',
                                        marginBottom: '-8px',
                                        width: '200px',
                                        height: '56px',
                                        '&:hover': {
                                            bgcolor: '#393F69'
                                        },
                                        "&.Mui-selected": {
                                            backgroundColor: "#373E6D !important",
                                            color: "white",
                                        },
                                        }}
                                    >
                                        <Box sx={{ display: 'flex' }}>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: "row",
                                            width: '24px',
                                            height: '24px',
                                            marginRight: '12px',
                                            columnGap: "10px"
                                        }}>
                                            <GetCountryFlagByName countryName={country?.name} />
                                            <Typography sx={{
                                            display: 'block',
                                            color: "white",
                                            fontFamily: 'Inter',
                                            fontSize: '14px',
                                            textAlign: 'left',
                                            opacity: '80%'
                                            }}>{country.name} </Typography>
                                            <Typography component='span'
                                            sx={{
                                                color: "white",
                                                fontFamily: 'Inter',
                                                fontSize: '14px',
                                                textAlign: 'left',
                                                opacity: '80%',
                                                marginLeft: '5px'
                                            }}>{country.mobileCode}</Typography>
                                        </Box>
                                        </Box>
                                    </MenuItem>
                                    ))}
                                </Box>
                                </Select>
                            </InputAdornment>
                            )
                        }}
                        sx={{
                            width: "100%",
                            maxWidth: "340px",
                            height: "56px",
                            color: "#FFFFFF",
                            backgroundColor: "#171A2D",
                            borderRadius: "8px",
                            boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.40)",
                            '& .MuiInputLabel-root': {
                            color: 'rgba(255, 255, 255, 0.80)', // Label text color
                            fontFamily: 'Inter',
                            fontSize: '16px',
                            fontWeight: 400,
                            },
                            '& .MuiOutlinedInput-root': {
                            '& input': {
                                color: "white", // Ensures input text remains white
                                fontFamily: 'Inter',
                                fontSize: '16px',
                                fontWeight: 400,
                                cursor: "text", // Ensures cursor appears in the input field
                            },
                            '& input::placeholder': {
                                color: "rgba(255, 255, 255, 0.80)",
                                opacity: 1,
                            },
                            '& fieldset': {
                                border: "1px solid rgba(255, 255, 255, 0.40)", // Default border color
                                borderRadius: "8px",
                            },
                            '&:hover fieldset': {
                                borderColor: "rgba(255, 255, 255, 0.40)", // No change on hover
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: "rgba(255, 255, 255, 0.80)", // Border color when focused
                            },
                            '& .MuiInputLabel-root.Mui-focused': {
                                color: 'rgba(255, 255, 255, 0.80)', // Keeps the label color consistent
                            }
                            },
                            '& .MuiInputLabel-root.Mui-focused': {
                                color: 'rgba(255, 255, 255, 1)', // Change to any color you want
                            },
                        }}  
                    />
                    <Box
                        sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "12px 16px",
                        gap: "8px",
                        alignSelf: "stretch",
                        borderRadius: "8px",
                        border: "1px solid rgba(255, 255, 255, 0.20)",
                        background: "#F6BE6B",
                        boxShadow: "0px 0px 24px 0px rgba(253, 215, 157, 0.60)",
                        color: "#17182C",
                        textAlign: "center",
                        fontFamily: "Inter",
                        fontSize: "16px",
                        fontWeight: 600,
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                        maxWidth: "106px",
                        flexShrink: 0, // Prevent button from shrinking
                        cursor:"pointer"
                        }}
                        onClick={handleOpen}
                    >
                        Submit
                        <ArrowForwardOutlinedIcon sx={{ fontSize: "18px" }} />
                    </Box>
                </Box>
                <Typography
                    sx={{
                        color: "#CAC4D0", // Applying the color directly
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "16px", // 133.333%
                        maxWidth: isBelow991 ? '194px' : "236px",
                        marginLeft:'16px',
                        marginTop:'4px',
                        marginBottom:"72px",
                    }}
                >
                    Enter your phone number above, Vic will give you a call
                </Typography>
            </Box>

            <Box sx={{
                maxWidth:'360px',
                maxHeight:'304px',
                borderRadius:'36px',
                border:'1px solid rgba(255,255,255,0.2)',
                padding:'24px 55px',
                margin:'auto',
                marginBottom:'60px'
             }}
            >
                <Box>
                <Typography
                    sx={{
                        color: "#FFF",
                        fontFamily: "Inter",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "16px", // 100% as per your requirement
                        marginBottom:'20px'
                    }}
                >
                    Join ModalX on WhatsApp
                </Typography>
                <Typography
                    sx={{
                        color: "rgba(255, 255, 255, 0.80)",
                        textAlign: "center",
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "16px", // 114.286%
                    }}
                >
                    Scan the QR code or use{" "}
                    <Box onClick={HandleThisLinkClick}
                        component="a"
                        href="#"
                        sx={{
                        color: "rgba(255, 255, 255, 0.80)",
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "16px",
                        textDecoration: "underline solid",
                        textDecorationSkipInk: "auto",
                        textDecorationThickness: "auto",
                        textUnderlineOffset: "auto",
                        textUnderlinePosition: "from-font",
                        }}
                    >
                        this link
                    </Box>
                </Typography>
                <Box
                    sx={{
                        width: "158.182px",
                        height: "158.182px",
                        flexShrink: 0,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "8px", // Optional: If you want rounded corners
                        margin:'auto',
                        marginTop:'24px'
                    }}
                >
                    <Box
                        component="img"
                        src={QR_Code}
                        alt="QR Code"
                        sx={{
                        width: "100%", // Makes the image fit the container
                        height: "100%",
                        objectFit: "contain", // Ensures it scales correctly inside the box
                        }}
                    />
                </Box>
                </Box>
            </Box>
            <Box sx={{borderTop:" 1px solid rgba(255, 255, 255, 0.10)"}}></Box>
        </Box>
        <CommanHoverModal 
            open={open}
            handleLeave={handleLeave}
            modalPosition={modalPosition}
            hoveredCard={hoveredCard}
            handleModalHover={handleModalHover}
        />
        <Dialog
            open={openVerifyModal}
            onClose={handleClose}
            maxWidth= {isBelow991 ? "xl" :"sm"}
            fullWidth
            sx={{
                "& .MuiPaper-root": {
                borderRadius: "16px", // Ensures rounded corners
                border: isBelow991 ? "none" : "1px solid rgba(255, 255, 255, 0.10)",
                backgroundColor: isBelow991 ? "#101220" : "#1F233E", // Ensures background color applies correctly
                width: isBelow991 ?  "100%" : "auto",
                height: isBelow991 ?  "100%" : "auto",
                margin: isBelow991 && "0px",
                maxHeight: isBelow991 ?  "100vh" : "auto",
                },
            }}
            >
            <IconButton
                onClick={handleClose}
                sx={{
                position: "absolute",
                top: "16px",
                right: "16px",
                color: "white",
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent
                sx={{
                color: "white",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                p: !isBelow991 && 4,
                // maxWidth: isBelow991 ? "350px" : "auto",
                margin: isBelow991 && "auto",
                }}
            >
                {step === 1 && (
                <>
                    <Typography
                        style={{
                            color: "#FFF",
                            fontFamily: "Inter",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "140%",
                            maxWidth: isBelow991 ? "350px" : "auto",
                            marginTop: isBelow991 && "50px" 
                        }}
                    >
                        Verify phone number
                    </Typography>
                    <Typography
                        style={{
                            width: "440px",
                            color: "rgba(255, 255, 255, 0.80)",
                            textAlign: "center",
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "18px",
                            marginTop:'20px',
                            maxWidth: isBelow991 ? "350px" : "auto",
                        }}
                    >
                        Enter the verification code sent by text on {phoneNumber}
                    </Typography>
                    <div className="custom-otp-container">
                    <MuiOtpInput
                        value={otp}
                        onChange={setOtp}
                        length={6}
                        separator={<span>-</span>}
                        sx={{
                            "& .MuiOtpInput-Box": {
                                display: "flex",
                                "& > div:not(:last-child)": {
                                    marginRight: "16px", // Add spacing between OTP fields
                                },
                            },
                        }}
                        TextFieldsProps={{
                            sx: {
                            maxWidth: isBelow991 ? "350px" : "auto",
                            borderRadius: "4px 4px 0px 0px",
                            display: "flex",
                            padding: "4px 0px",
                            justifyContent: "center",
                            alignItems: "center",
                            bgcolor: "#232746",
                            width: isBelow991? "40px" : "56px",
                            height: "56px",
                            border: "1px solid rgba(255,255,255,0.8)",
                            marginTop: "40px",
                            "& .MuiOtpInput-Box": {
                                gap: "8px", // Adjust spacing between OTP input fields
                            },
                            "& .MuiOutlinedInput-root": {
                                border: "none", // Remove default outline
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                                display: "none", // Hide default outline
                            },
                            "& .MuiInputBase-root": {
                                border: "none",
                            },
                            "& .Mui-focused": {
                                border: "1px solid rgba(255,255,255,0.8) !important",
                            },
                            "& input": {
                                textAlign: "center",
                                fontSize: "16px",
                                fontWeight: 400,
                                fontFamily: "Inter",
                                lineHeight: "24px",
                                letterSpacing: "0.5px",
                                color: "rgba(255, 255, 255, 0.80)",
                                caretColor: "white", // Allow selection and typing
                            },
                            },
                        }}
                        renderInput={(props) => (
                            <TextField
                            {...props}
                            inputProps={{
                                ...props.inputProps,
                                style: {
                                textAlign: "center",
                                fontSize: "16px",
                                fontWeight: 400,
                                fontFamily: "Inter",
                                lineHeight: "24px",
                                letterSpacing: "0.5px",
                                color: "rgba(255, 255, 255, 0.80)",
                                },
                            }}
                            value={props.value || "—"} // Show "—" when empty
                            variant="standard" // Remove extra outline
                            />
                        )}
                    />
                    </div>
                    <Button
                        sx={{
                            // maxWidth: isBelow991 ? "350px" : "auto",
                            display: "flex",
                            padding: "10px 24px 10px 16px",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "8px",
                            alignSelf: "stretch",
                            
                            backgroundColor: "#F6BE6B", // Normal state
                            "&:hover": {
                            backgroundColor: "#FFD9AA", // Hover state
                            },
                            "&:active": {
                            backgroundColor: "#FFCE91", // Pressed state
                            },
                            borderRadius: "8px", // If rounded corners are needed
                            boxShadow: "none", // Remove MUI default shadow
                            marginTop:  '40px',
                            maxWidth : isBelow991 ? "350px" : "auto"
                            // margin: isBelow991 && "auto"
                        }}
                        onClick={handleVerifyOtp}
                    >
                    <Typography
                        sx={{
                        color: "#17182C",
                        textAlign: "center",
                        fontFamily: "Inter",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                        textTransform:'none'
                        }}

                    >
                        Submit and verify
                    </Typography>
                </Button>
                <Typography
                    sx={{
                        color: "#FFF",
                        fontFamily: "Inter",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "20px", // 125%
                        marginTop:'20px',
                        cursor:'pointer'
                    }}
                    onClick={handleResendOtp}
                >
                    Resend code
                </Typography>
                </>
                )}

                {step === 2 && (
                <>
                <Typography
                        style={{
                            color: "#FFF",
                            fontFamily: "Inter",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "140%",
                            maxWidth: isBelow991 ? "350px" : "auto",
                            marginTop: isBelow991 && "50px" 
                        }}
                    >
                        Confirm email address
                    </Typography>
                    <TextField
                        label="Email address"
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{
                            my: 2,
                            maxWidth: isBelow991 ? "350px" : "auto",
                            bgcolor: "#232746",
                            marginTop: isBelow991 ? "70px" : "50px", 
                            marginBottom: "4px", 
                            "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                                border: "1px solid rgba(255,255,255,0.8) !important", // Ensures a single border
                            },
                            "&:hover fieldset": {
                                border: "1px solid rgba(255,255,255,0.8) !important", // Prevents extra border on hover
                            },
                            "&.Mui-focused fieldset": {
                                border: "1px solid rgba(255,255,255,0.8) !important", // Removes blue border on focus
                            },
                            },
                            "& .MuiInputBase-input": {
                            color: "rgba(255, 255, 255, 0.80)",
                            fontFamily: "Inter",
                            fontSize: "16px",
                            fontWeight: 400,
                            lineHeight: "24px",
                            letterSpacing: "0.5px",
                            },
                            "& .MuiInputLabel-root": {
                            color: "rgba(255, 255, 255, 0.80)",
                            fontFamily: "Inter",
                            fontSize: "16px",
                            fontWeight: 400,
                            lineHeight: "24px",
                            letterSpacing: "0.5px",
                            },
                            "& .MuiInputLabel-root.Mui-focused": {
                            color: "rgba(255, 255, 255, 0.80)", // Keeps label color consistent
                            },
                            width:'400px',
                           
                        }}
                    />
                    <Typography
                        sx={{
                            flex: !isBelow991 && "1 0 0",
                            color: "#CAC4D0",
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "16px", // 133.333%
                            maxWidth: isBelow991 ? "350px" : '368px',
                            marginBottom: isBelow991 ? "40px" : "56px"
                        }}
                    >
                        ModalX will send you generated files on this email address.
                    </Typography>
                   

                    <FormControlLabel sx={{ maxWidth: isBelow991 ? "350px" : '400px',textAlign:'start'}} 
                    control={<Checkbox checked={termsAccepted} onChange={(e) => setTermsAccepted(e.target.checked)} sx={{color:'white'}} />}
                    label={
                        <Typography
                            sx={{
                                color: "#FFF",
                                fontFamily: "Inter",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "20px",
                                letterSpacing: "0.5px",
                            }}
                            >
                            I have read and I accept the{" "}
                            <Link
                                href="/terms-of-use"
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{
                                color: "#FFF",
                                textDecoration: "underline",
                                textDecorationStyle: "solid",
                                textDecorationSkipInk: "none",
                                textDecorationThickness: "auto",
                                textUnderlineOffset: "auto",
                                textUnderlinePosition: "from-font",
                                }}
                            >
                                terms of use
                            </Link>{" "}
                            and{" "}
                            <Link
                                href="/privacy-policy"
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{
                                color: "#FFF",
                                textDecoration: "underline",
                                textDecorationStyle: "solid",
                                textDecorationSkipInk: "none",
                                textDecorationThickness: "auto",
                                textUnderlineOffset: "auto",
                                textUnderlinePosition: "from-font",
                                }}
                            >
                                privacy policy
                            </Link>
                        </Typography>
                    }
                    />
                    <Button
                        sx={{
                            display: "flex",
                            padding: "10px 24px 10px 16px",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "8px",
                            alignSelf: "stretch",
                            backgroundColor: "#F6BE6B", // Normal state
                            "&:hover": {
                            backgroundColor: "#FFD9AA", // Hover state
                            },
                            "&:active": {
                            backgroundColor: "#FFCE91", // Pressed state
                            },
                            borderRadius: "8px", // If rounded corners are needed
                            boxShadow: "none", // Remove MUI default shadow
                            marginTop:'40px'
                        }}
                        onClick={handleSubmitRequest}
                    >
                        <Typography
                            sx={{
                            color: "#17182C",
                            textAlign: "center",
                            fontFamily: "Inter",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                            textTransform:'none'
                            }}

                        >
                            Submit and Request Call
                        </Typography>
                    </Button>
                </>
                )}

                {step === 3 && (
                <>
                    {/* <CheckCircleIcon sx={{ fontSize: 50, color: "green", my: 2, marginTop: isBelow991 && "50px" }} /> */}
                    <Box sx={{marginTop: isBelow991 ? "50px" : "20px", marginBottom: isBelow991 ? "20px" : "20px",   maxWidth: isBelow991 ? "350px" : "auto" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                    <g clip-path="url(#clip0_3057_36196)">
                    <circle cx="20" cy="20" r="20" fill="#9EFFCF"/>
                    <path d="M20 0C9 0 0 9 0 20C0 31 9 40 20 40C31 40 40 31 40 20C40 9 31 0 20 0ZM16 30L6 20L8.82 17.18L16 24.34L31.18 9.16L34 12L16 30Z" fill="#23A565"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_3057_36196">
                    <rect width="40" height="40" fill="white"/>
                    </clipPath>
                    </defs>
                    </svg>
                    </Box>
                    <Typography
                        variant="h6"
                        sx={{
                            color: "#FFF",
                            fontFamily: "Inter",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "140%", // 22.4px
                            maxWidth: isBelow991 ? "350px" : "auto",
                            marginBottom:"20px"
                        }}
                    >
                        Phone number confirmed
                    </Typography>
                    <Typography
                        sx={{
                            width: "440px",
                            color: "rgba(255, 255, 255, 0.80)",
                            textAlign: "center",
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "18px", // 128.571%
                            maxWidth: isBelow991 ? "350px" : "auto",
                        }}
                    >
                        You will receive a call from your dedicated AI agent within a minute or two
                    </Typography>
                    <Button
                        sx={{
                            display: "flex",
                            padding: "10px 24px 10px 16px",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "8px",
                            alignSelf: "stretch",
                            backgroundColor: "#F6BE6B", // Normal state
                            "&:hover": {
                            backgroundColor: "#FFD9AA", // Hover state
                            },
                            "&:active": {
                            backgroundColor: "#FFCE91", // Pressed state
                            },
                            borderRadius: "8px", // If rounded corners are needed
                            boxShadow: "none", // Remove MUI default shadow
                            marginTop:'74px'
                        }}
                        onClick={handleClose}
                    >
                        <Typography
                            sx={{
                            color: "#17182C",
                            textAlign: "center",
                            fontFamily: "Inter",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "20px",
                            letterSpacing: "0.1px",
                            textTransform:'none'
                            }}

                        >
                            Done
                        </Typography>
                    </Button>
                </>
                )}
            </DialogContent>
        </Dialog>
    </PageLayout>
          <CommonToast 
            open={showNotif}
            onClose={handleCloseToast}
            content={notifMsg}
            bgColor="#282D51"
            fontColor="#fff"
            fontSize="14px"
            fontWeight="500"
            autoHideDuration={3000}
            iconType = {notifVariant}
          />
          </>
  )
}

export default CallingAgent
import React, { useState } from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import { Box, Button } from '@mui/material';
import he from 'he';
import black_logo from "../../../../assets/updatedModalxLogo.png";
import white_logo from "../../../../assets/modalX_robot_icon_white.png";
import LinkedIn_Icon from "../../../../assets/LinkedInIconForUserInfo.png";
import EditUserDetails from './EditUserDetails';
import { agent_conversation_bg_color, user_conversation_bg_color } from '../../../../constants/colors';
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import { InvokeOnBoardingStatusAPI } from '../../../../contexts/WorkspaceUtils';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';

const Default_UserInfo = ({ message, numOfResp }) => {
  const [theme, setTheme] = useState(localStorage.getItem('theme'));
  const [openEditModal, setOpenEditModal] = useState(false);
  const contentToCheck = message?.content || message?.agent;
  const { sendMessage, setWaitThread, setSending, setCurrentThread, getChallenges, setOnBoardingData } = useWorkspace();
  const email = localStorage.getItem("userEmail");

  // Parse the contentToCheck into an object
  const parseContent = (content) => {
    try {
      return JSON.parse(content); // Try parsing as JSON
    } catch (error) {
      // If parsing fails, fall back to the old format
      return null;
    }
  };

  const parsedContent = parseContent(contentToCheck);
  const isJsonResponse = !!parsedContent; // Check if the response is JSON

  // Function to extract information based on a list of possible keys
  const extractInfo = (possibleKeys, content) => {
    if (!content) return 'Not provided'; // Handle null or undefined content
    const lines = content.split('\n');
    
    for (const line of lines) {
      const trimmedLine = line.trim();
      for (const key of possibleKeys) {
        const regex = new RegExp(`${key}:?\\s*(.*)`, 'i'); // Regular expression to match keys with/without colons
        const match = trimmedLine.match(regex);
        if (match) {
          return match[1].replace(/\*\*/g, '').trim(); // Remove ** from extracted value
        }
      }
    }
    
    return 'Not provided';
  };

// Function to extract the company description
const extractCompanyDescription = (content) => {
  const regex = /Company Description:\s*(.*)/;
  const match = content.match(regex);
  if (match && match[1]) {
    return match[1].trim();
  }
  return 'Not Provided';
};

  // Extract details based on the response format
  const name = isJsonResponse ? parsedContent['Name'] : extractInfo(['Name:'], contentToCheck);
  const companyName = isJsonResponse ? parsedContent['Company Name'] : extractInfo(['Company Name:', 'Company:', 'Organisation Name:'], contentToCheck);
  const role = isJsonResponse ? parsedContent['Role'] : extractInfo(['Role of the Person:', 'Role:', 'Position:'], contentToCheck);
  const linkedIn = isJsonResponse ? parsedContent['Contact and Social Links']?.['LinkedIn Profile'] : extractInfo(['LinkedIn Profile:', 'LinkedIn:', 'LinkedIn URL:'], contentToCheck);
  const companyDescription = isJsonResponse ? parsedContent['Company Description'] : extractCompanyDescription(contentToCheck);

  const showAll = isJsonResponse ? parsedContent['Show All'] === true : false;

  const firstSentence = name && role && companyName && name !== 'Not Provided' && role !== 'Not Provided' && companyName !== 'Not Provided' && name !== 'N/A' && role !== 'N/A' && companyName !== 'N/A' && name !== 'Not provided' && role !== 'Not provided' && companyName !== 'Not provided' && name !== 'Not available' && role !== 'Not available' && companyName !== 'Not available' ? `It’s great to have you on ModalX, ${name}! I can see that you are a ${role} at ${companyName}. Am I correct?` : 'It’s great to have you on ModalX!';

  const decodeContent = (content) => {
    return he.decode(content);
  };

  const handleEditClick = () => {
    setOpenEditModal(true);
  };

  const handleYesClick = async (prompt) => {
    setWaitThread(true);
    setSending(true);
    try {
      const latestEntry = { sender: "user", content: prompt };
      setCurrentThread((prevThread) => [...prevThread, latestEntry]);

      const entryResponse = await getChallenges(latestEntry, true, { email: email, firstName: name.split(" ")[0], lastName: name.split(" ")[1], linkedinProfile: linkedIn, role: role, companyWebsite: companyName });
      if (entryResponse) {
        setCurrentThread((prevThread) => [...prevThread, entryResponse]);
      } else {
        setCurrentThread((prevThread) => [
          ...prevThread,
          { content: "Unable to process your query!", sender: "modalX" }
        ]);
      }
      const res = await InvokeOnBoardingStatusAPI();
      setOnBoardingData(res);
    } catch (error) {
      console.error("Error sending initial message:", error);
    } finally {
      setWaitThread(false);
      setSending(false);
    }
  };

  // Function to render all key-value pairs as cards
  // Function to render all key-value pairs as cards, excluding the "Show All" key
  const renderAllDetails = (details) => {
    return Object.keys(details).map((key, index) => {
      // Skip rendering the "Show All" key
      if (key === 'Show All') {
        return null;
      }
  
      const value = details[key];
  
      // Skip rendering if the value is empty, null, undefined, or "Not Provided"
      if (
        value === null ||
        value === undefined ||
        value === "" ||
        value === "Not Provided" ||
        value === "[Not Provided]" ||
        (Array.isArray(value) && value.length === 0) ||
        (typeof value === 'object' && Object.keys(value).length === 0)
      ) {
        return null;
      }
  
      if (typeof value === 'object' && !Array.isArray(value)) {
        return (
          <div key={index} style={{ marginTop: '10px', paddingLeft: '0px' }}>
            <Card.Title style={{ fontSize: '16px', fontWeight: '600', color: 'rgba(255,255,255,0.8)',marginBottom: '20px' }}>
              {key}
            </Card.Title>
            <Row style={{ display: 'flex', flexWrap: 'wrap', gap: '16px', marginLeft: '0px' }}>
              {renderAllDetails(value)} {/* Recursively render nested objects */}
            </Row>
          </div>
        );
      } else {
        return (
          <Col xs="auto" key={index} style={{ flex: '1 1 auto', margin: '0', padding: '0', minWidth: '200px' }}>
            <Card
              style={{
                backgroundColor: 'transparent',
                padding: '12px',
                borderRadius: '8px',
                display: 'inline-block',
                width: '100%',
                maxWidth: '100%',
                height: '100%',
              }}
            >
              <Card.Title style={{ fontSize: '16px', fontWeight: '600', color: 'rgba(255,255,255,0.8)' }}>
                {key}
              </Card.Title>
              <Card.Text style={{ fontSize: '16px' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </Card.Text>
            </Card>
          </Col>
        );
      }
    });
  };

  return (
    <>
      {(message.sender === "user" || message.user) && (
        <Col xs={11} className="text-start" style={{ marginBottom: '20px', paddingRight: '70px', paddingLeft: '60px', marginLeft: '',}}>
          <Row style={{ backgroundColor: '', marginRight: '-120px' }}>
            <Col style={{ margin: 'auto', maxWidth: '604px', marginRight: '38px', backgroundColor: user_conversation_bg_color, padding: '12px', borderRadius: '8px' }}>
              {message.user && typeof message.user === 'string' && message.user.split("\n").map((line, index) => (
                <div key={index} style={{ wordBreak: "break-word", backgroundColor: '', textAlign: 'end' }}>
                  {decodeContent(line)}
                </div>
              ))}
            </Col>
            <Col xs={1} style={{ backgroundColor: '#1F233E', width: '48px', height: '48px', display: 'flex', alignItems: 'center', marginLeft: '-30px', borderRadius: '24px' }}>
              <i className="bi bi-person fs-4" style={{ margin: 'auto' }} />
            </Col>
          </Row>
        </Col>
      )}

      {contentToCheck && (
        <Row className="justify-content-start" style={{ backgroundColor: '', }}>
          <Col xs={11} className="text-start" style={{ backgroundColor: '', display: 'flex', marginLeft: '0px' }}>
            <Col xs={1} style={{ marginLeft: '10px', backgroundColor: '', width: '48px', height: '48px', borderRadius: '24px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <img style={{ width: '48px', height: '48px' }} src={theme === 'dark' ? black_logo : white_logo} alt="ModalX Icon" className="img-fluid" />
            </Col>
            <Row>
              <Col xs={11} style={{ margin: 'auto', marginBottom: '40px', marginLeft: '25px', backgroundColor: '' }} className={contentToCheck.length > 55 ? "text-start" : 'text-end'}>
                <Row>
                  <div style={{ wordBreak: "break-word", marginBottom: '20px', backgroundColor: agent_conversation_bg_color, width: 'auto', maxWidth: '604px', padding: '12px 12px 12px 12px', borderRadius: '8px' }}>
                    {firstSentence}
                  </div>
                  <Row style={{ display: 'flex', gap: '24px', flexWrap: 'wrap', marginLeft: '0px' }}>
                    {/* Render Company only if companyName is not empty */}
                    {companyName && companyName.trim() !== "" && (
                      <Col xs="auto" style={{ minWidth: '120px', padding: '0' }}>
                        <Card style={{ backgroundColor: 'transparent', padding: '12px', borderRadius: '5px', margin: '0' }}>
                          <Card.Title style={{ fontSize: '14px', fontWeight: '600', color: 'rgba(255,255,255,0.8)', marginBottom: '8px' }}>Company</Card.Title>
                          <Card.Text style={{ fontSize: '16px', margin: '0' }}>{companyName}</Card.Text>
                        </Card>
                      </Col>
                    )}

                    {/* Render Role only if role is not empty */}
                    {role && role.trim() !== "" && (
                      <Col xs="auto" style={{ minWidth: '120px', padding: '0' }}>
                        <Card style={{ backgroundColor: 'transparent', padding: '12px', borderRadius: '8px', margin: '0' }}>
                          <Card.Title style={{ fontSize: '14px', fontWeight: '600', color: 'rgba(255,255,255,0.8)', marginBottom: '8px' }}>Role</Card.Title>
                          <Card.Text style={{ fontSize: '16px', margin: '0' }}>{role}</Card.Text>
                        </Card>
                      </Col>
                    )}

                    {/* Render LinkedIn only if linkedIn is not empty and valid */}
                    {linkedIn && linkedIn.trim() !== "" && linkedIn !== "Not Provided" && linkedIn !== "[Not Provided]" && (
                      <Col xs="auto" style={{ minWidth: '120px', padding: '0' }}>
                        <a href={linkedIn} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                          <Card style={{ backgroundColor: 'transparent', padding: '12px', borderRadius: '8px', margin: '0' }}>
                            <Card.Title style={{ fontSize: '14px', fontWeight: '600', color: 'rgba(255,255,255,0.8)', marginBottom: '8px' }}>
                              LinkedIn
                            </Card.Title>
                            <Card.Text style={{ fontSize: '16px', display: 'flex', alignItems: 'center', margin: '0' }}>
                              <img src={LinkedIn_Icon} alt="LinkedIn Icon" style={{ width: '20px', height: '20px', marginRight: '20px' }} />
                              {linkedIn}
                            </Card.Text>
                          </Card>
                        </a>
                      </Col>
                    )}
                  </Row>
                  <div style={{ fontSize: '16px', display: 'flex', gap: '20px', marginTop: '42px' }}>
                    <p>{companyName}</p>
                    <p style={{ color: 'rgba(255,255,255,0.8)' }}>Overview</p>
                  </div>

                  {showAll && isJsonResponse ? (
                    <Row style={{ marginTop: '10px', gap: '24px', display: 'flex', flexWrap: 'wrap', marginLeft: '0px' }}>
                      {renderAllDetails(parsedContent)}
                    </Row>
                  ) : (
                    <Row style={{ marginTop: '10px', gap: '24px', display: 'flex', flexWrap: 'wrap', marginLeft: '0px' }}>
                      {companyDescription}
                      {/* Render other details as per the old logic */}
                    </Row>
                  )}

                  <Row style={{ marginTop: '20px' }}>
                    <Col style={{ display: 'flex', gap: '20px', justifyContent: 'start' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          width: '147px',
                          height: '36px',
                          bgcolor: '#282D51',
                          '&:hover': {
                            bgcolor: '#373E6D',
                          },
                          '&.Mui-disabled': {
                            color: 'rgba(255,255,255,0.4)',
                            bgcolor: '#282D51',
                          },
                          textAlign: 'center',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: '8px',
                          gap: '10px',
                          cursor: numOfResp >= 3 ? 'not-allowed' : 'pointer',
                        }}
                        onClick={() => {
                          if (numOfResp < 3) {
                            handleYesClick(
                              'Thank you for confirming the details. Let me set things up for you'
                            );
                          }
                        }}
                        disabled={numOfResp >= 3}
                      >
                        <DoneIcon sx={{ width: '20px', height: '20px' }} />
                        <Box
                          sx={{
                            fontSize: '14px',
                            fontWeight: 400,
                            fontFamily: 'inter',
                          }}
                        >
                          Yes, that's me
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: 'flex',
                          width: '147px',
                          height: '36px',
                          bgcolor: '#282D51',
                          '&:hover': {
                            bgcolor: '#373E6D',
                          },
                          '&.Mui-disabled': {
                            color: 'rgba(255,255,255,0.4)',
                            bgcolor: '#282D51',
                          },
                          textAlign: 'center',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: '8px',
                          gap: '10px',
                          cursor: numOfResp >= 3 ? 'not-allowed' : 'pointer',
                        }}
                        onClick={() => { !(numOfResp >= 3) && handleEditClick() }}
                        disabled={numOfResp >= 3}
                      >
                        <EditIcon sx={{ width: '20px', height: '20px' }} />
                        <Box
                          sx={{
                            fontSize: '14px',
                            fontWeight: 400,
                            fontFamily: 'inter',
                          }}
                        >
                          No, edit details
                        </Box>
                      </Box>
                    </Col>
                  </Row>

                  {openEditModal && (
                    <EditUserDetails
                      open={openEditModal}
                      onClose={() => setOpenEditModal(false)}
                      firstName={name.split(" ")[0]}
                      lastName={name.split(" ")[1]}
                      companyName={companyName}
                      role={role}
                      linkedIn={linkedIn}
                    />
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

export default Default_UserInfo;

// import React, { useState } from 'react';
// import { Col, Row, Card } from 'react-bootstrap';
// import { Box, Button } from '@mui/material';
// import he from 'he';
// import black_logo from "../../../../assets/updatedModalxLogo.png";
// import white_logo from "../../../../assets/modalX_robot_icon_white.png";
// import LinkedIn_Icon from "../../../../assets/LinkedInIconForUserInfo.png";
// import EditUserDetails from './EditUserDetails'; // Import the EditUserDetails component
// import { agent_conversation_bg_color, uploadFiles_button_clicked_bg_color, uploadFiles_button_default_bg_color, uploadFiles_button_hovered_bg_color, user_conversation_bg_color } from '../../../../constants/colors';
// import { useWorkspace } from "../../../../contexts/WorkspaceContext";
// import { InvokeOnBoardingStatusAPI } from '../../../../contexts/WorkspaceUtils';
// import DoneIcon from '@mui/icons-material/Done';
// import EditIcon from '@mui/icons-material/Edit';

// const Default_UserInfo = ({ message,numOfResp }) => {
//   const [theme, setTheme] = useState(localStorage.getItem('theme'));
//   const [openEditModal, setOpenEditModal] = useState(false);
//   const contentToCheck = message?.content || message?.agent;
//   const {sendMessage,setWaitThread,setSending,setCurrentThread,getChallenges,setOnBoardingData} = useWorkspace();
//   const email = localStorage.getItem("userEmail");

//   // const extractInfo = (possibleKeys, content) => {
//   //   const lines = content.split('\n');
//   //   for (const line of lines) {
//   //     const trimmedLine = line.trim();
//   //     for (const key of possibleKeys) {
//   //       if (trimmedLine.startsWith(`${key}:`)) {
//   //         return trimmedLine.replace(`${key}:`, '').trim();
//   //       }
//   //     }
//   //   }
//   //   return 'Not provided';
//   // };

//   const extractInfo = (possibleKeys, content) => {
//     if (!content) return 'Not provided'; // Handle null or undefined content
//     const lines = content.split('\n');
    
//     for (const line of lines) {
//       const trimmedLine = line.trim();
//       for (const key of possibleKeys) {
//         const regex = new RegExp(`${key}:?\\s*(.*)`, 'i'); // Regular expression to match keys with/without colons
//         const match = trimmedLine.match(regex);
//         if (match) {
//           return match[1].replace(/\*\*/g, '').trim(); // Remove ** from extracted value
//         }
//       }
//     }
    
//     return 'Not provided';
//   };
  
  

//   // const extractCompanyDetails = (content) => {
//   //   const companyInfoKey = 'Company Info';
//   //   const lines = content.split('\n');
//   //   let foundCompanyInfo = false;
//   //   let extractedDetails = '';
  
//   //   for (const line of lines) {
//   //     const trimmedLine = line.trim();
  
//   //     // Look for the "Company Info" key
//   //     if (trimmedLine.startsWith(companyInfoKey)) {
//   //       foundCompanyInfo = true;
//   //       continue;
//   //     }
  
//   //     // Once "Company Info" is found, extract the first descriptive line or sentence
//   //     if (foundCompanyInfo) {
//   //       if (trimmedLine.startsWith('- Description:')) {
//   //         // Extract the text after the "- Description:" key
//   //         extractedDetails = trimmedLine.replace('- Description:', '').trim();
//   //         // Stop after getting the first line or sentence.
//   //         break;
//   //       }
//   //     }
//   //   }
  
//   //   return extractedDetails || 'Not provided';
//   // };

//   const extractCompanyDetails = (content) => {
//     const companyInfoKey = 'Company Info';
//     const lines = content.split('\n');
//     let foundCompanyInfo = false;
//     let extractedDetails = {};
  
//     for (const line of lines) {
//       const trimmedLine = line.trim();
  
//       // Look for the "Company Info" key to start extracting
//       if (trimmedLine.toLowerCase().includes(companyInfoKey.toLowerCase())) {
//         foundCompanyInfo = true;
//         continue;
//       }
  
//       // If company info section starts, extract until empty line or new section starts
//       if (foundCompanyInfo) {
//         if (trimmedLine === '') break; // Stop at blank line
  
//         // Match formats like: "- **Key**: Value" or "Key: Value"
//         const regex = /-?\s?\*\*(.+?)\*\*\s*:\s*(.+)/;
//         const simpleRegex = /(.+?):\s*(.+)/; // Handle simpler "Key: Value"
        
//         const match = trimmedLine.match(regex) || trimmedLine.match(simpleRegex);
//         if (match) {
//           const key = match[1].replace(/\*\*/g, '').trim(); // Clean ** from key
//           const value = match[2].replace(/\*\*/g, '').trim(); // Clean ** from value
//           extractedDetails[key] = value;
//         }
//       }
//     }
  
//     return Object.keys(extractedDetails).length ? extractedDetails : 'Not provided';
//   };
  
  
  
//   const extractCompanyDescription = (contentToCheck) => {
//     let companyDescription = '';
  
//     // Extract the content of 'Company Info'
//     const companyInfoSection = contentToCheck.match(/Company Info:\s*((?:-.*(?:\n|$))*)/);
  
//     if (companyInfoSection) {
//       const companyInfoContent = companyInfoSection[1].trim();
  
//       // Check for specific keys
//       const keys = ['description', 'details', 'about'];
//       let specificKeyFound = false;
  
//       keys.forEach(key => {
//         const keyPattern = new RegExp(`${key}:\\s*([\\s\\S]*?)(?:\\n|$)`, 'i');
//         const keyMatch = companyInfoContent.match(keyPattern);
  
//         if (keyMatch) {
//           companyDescription = keyMatch[1].trim();
//           specificKeyFound = true;
//         }
//       });
  
//       // If no specific keys are found, ensure to return an empty string
//       if (!specificKeyFound) {
//         // Check if companyInfoContent has sentences with '-' prefix
//         const hasSentences = /-\s/.test(companyInfoContent);
//         if (!hasSentences) {
//           companyDescription = '';
//         }
//       }
//     }
  
//     return companyDescription;
//   };
  
//   // console.log("9999999999999999999999999999999",numOfResp)
  
  

//   // const  name = extractInfo(['Name'],contentToCheck)
//   // const companyName = extractInfo(['Company Name', 'Company', 'Organisation Name'], contentToCheck);
//   // const role = extractInfo(['Role of the Person', 'Role', 'Position'], contentToCheck);
//   // const linkedIn = extractInfo(['LinkedIn Profile', 'LinkedIn', 'LinkedIn URL'], contentToCheck);

//   const removeLeadingColon = (value) => value.startsWith(':') ? value.slice(1).trim() : value;

//   const name = removeLeadingColon(extractInfo(['Name:'], contentToCheck));
//   const companyName = removeLeadingColon(extractInfo(['Company Name:', 'Company:', 'Organisation Name:'], contentToCheck));
//   const role = removeLeadingColon(extractInfo(['Role of the Person:', 'Role:', 'Position:'], contentToCheck));
//   const linkedIn = removeLeadingColon(extractInfo(['LinkedIn Profile:', 'LinkedIn:', 'LinkedIn URL:'], contentToCheck));


//   // console.log("999999999999999999999999999",companyName)

//   const industry = extractInfo(['Industry', 'Sector'], contentToCheck);
//   const founded = extractInfo(['Founded', 'Established'], contentToCheck);
//   const teamSize = extractInfo(['Team Size', 'Employees', 'Employee Count'], contentToCheck);
//   const revenue = extractInfo(['Revenue (FY23)', 'Revenue'], contentToCheck);
//   const profit = extractInfo(['Profit (FY23)', 'Profit'], contentToCheck);
//   const fundingRaised = extractInfo(['Funding Raised', 'Total Funding'], contentToCheck);

//   // const companyDetails = extractInfo(['Company Details', 'Organisation Details', 'Organisation Information','Company Info'], contentToCheck);

//   const companyDetails = extractCompanyDetails(contentToCheck);

//   // console.log("00000000000000000000000000000000000000",companyDetails)

//   const companyDescription = extractCompanyDescription(contentToCheck);

//   const firstSentence = name && role && companyName && name !== 'Not Provided' && role !== 'Not Provided' && companyName !== 'Not Provided' && name !==  'N/A' && role !==  'N/A' && companyName !== 'N/A' && name !== 'Not provided' && role !== 'Not provided' && companyName !== 'Not provided' && name !== 'Not available' && role !== 'Not available' && companyName !== 'Not available' ? `It’s great to have you on ModalX, ${name}! I can see that you are a ${role} at ${companyName}. Am I correct?` : 'It’s great to have you on ModalX!'

//   const decodeContent = (content) => {
//     return he.decode(content);
//   };

//   const handleEditClick = () => {
//     setOpenEditModal(true);
//   };


//   const handleYesClick = async (prompt) => {
//     setWaitThread(true);
//     setSending(true);
//     try {
//       const latestEntry = { sender: "user", content: prompt };
//       setCurrentThread((prevThread) => [...prevThread, latestEntry]);

//       const entryResponse = await getChallenges(latestEntry,true,{email:email,firstName:name.split(" ")[0],lastName:name.split(" ")[1],linkedinProfile:linkedIn,role:role,companyWebsite:companyName});
//       if (entryResponse) {
//         setCurrentThread((prevThread) => [...prevThread, entryResponse]);
//       } else {
//         setCurrentThread((prevThread) => [
//           ...prevThread, 
//           { content: "Unable to process your query!", sender: "modalX" }
//         ]);
//       }
//       const res = await InvokeOnBoardingStatusAPI();
//       setOnBoardingData(res);
//     } catch (error) {
//       console.error("Error sending initial message:", error);
//     } finally {
//       setWaitThread(false);
//       setSending(false);
//     }
//   };


//   return (
//     <>
//       {(message.sender === "user" || message.user) && (
//         <Col xs={11} className="text-start" style={{ marginBottom: '20px', paddingRight: '70px', paddingLeft: '60px' , marginLeft : '' }}>
//           <Row style={{backgroundColor : '', marginRight : '-120px'}}>
//             {/* <Col xs={1} className="d-flex flex-column justify-content-start align-items-center p-0 m-0" style={{ backgroundColor: '#1F233E', borderRadius: '50%', width: '48px', height: '48px' }}>
//               <i className="bi bi-person fs-4" style={{ margin: 'auto' }} />
//             </Col> */}
//             <Col  style={{ margin: 'auto', maxWidth : '604px',marginRight : '38px',backgroundColor:user_conversation_bg_color,padding:'12px',borderRadius:'8px' }}>
//               {message.user && typeof message.user === 'string' && message.user.split("\n").map((line, index) => (
//                 <div key={index} style={{ wordBreak: "break-word", backgroundColor : '', textAlign : 'end'  }}>
//                   {decodeContent(line)}
//                 </div>
//               ))}
//             </Col>
//             <Col xs={1}  style={{ backgroundColor: '#1F233E', width: '48px', height: '48px',display : 'flex',
//               alignItems : 'center', marginLeft : '-30px', borderRadius : '24px'
//              }}>
//               <i className="bi bi-person fs-4" style={{ margin: 'auto',  }} />
//             </Col>
//           </Row>
//         </Col>
//       )}

//       {contentToCheck && (
//         <Row className="justify-content-start" style={{ backgroundColor : '',}}>

//           <Col xs={11} className="text-start" style={{ backgroundColor : '', display : 'flex',marginLeft : '0px'}}>
//           <Col xs={1} style={{marginLeft : '10px', backgroundColor : '' , width : '48px', height : '48px',borderRadius : '24px',
//             display : 'flex', alignItems : 'center', justifyContent : 'center'
//           }} >
//                 <img style={{width : '48px', height : '48px'}} src={theme === 'dark' ? black_logo : white_logo} alt="ModalX Icon" className="img-fluid" />
//               </Col>
//             <Row>
//               <Col xs={11} style={{ margin: 'auto', marginBottom: '40px', marginLeft : '25px',backgroundColor : '' }} className={contentToCheck.length > 55 ? "text-start" : 'text-end'}>
//                 <Row>
//                   <div style={{ wordBreak: "break-word", marginBottom: '20px',backgroundColor:agent_conversation_bg_color,width:'auto',maxWidth:'604px',padding:'12px 0 12px 12px',borderRadius:'8px' }}>
//                     {firstSentence}
//                   </div>
//                   <Row style={{ display: 'flex', gap: '24px', flexWrap: 'wrap',marginLeft:'0px' }}>
//                     <Col xs="auto" style={{ minWidth: '120px', padding: '0' }}>
//                       <Card style={{ backgroundColor: 'transparent', padding: '12px', borderRadius: '5px', margin: '0' }}>
//                         <Card.Title style={{ fontSize: '14px', fontWeight: '600', color: 'rgba(255,255,255,0.8)', marginBottom: '8px' }}>Company</Card.Title>
//                         <Card.Text style={{ fontSize: '16px', margin: '0' }}>{companyName}</Card.Text>
//                       </Card>
//                     </Col>
//                     <Col xs="auto" style={{ minWidth: '120px', padding: '0' }}>
//                       <Card style={{ backgroundColor: 'transparent', padding: '12px', borderRadius: '5px', margin: '0' }}>
//                         <Card.Title style={{ fontSize: '14px', fontWeight: '600', color: 'rgba(255,255,255,0.8)', marginBottom: '8px' }}>Role</Card.Title>
//                         <Card.Text style={{ fontSize: '16px', margin: '0' }}>{role}</Card.Text>
//                       </Card>
//                     </Col>
//                     <Col xs="auto" style={{ minWidth: '120px', padding: '0' }}>
//                       {linkedIn && linkedIn !== "Not Provided" && linkedIn !== "[Not Provided]" ? (
//                         <a href={linkedIn} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
//                           <Card style={{ backgroundColor: 'transparent', padding: '12px', borderRadius: '5px', margin: '0' }}>
//                             <Card.Title style={{ fontSize: '14px', fontWeight: '600', color: 'rgba(255,255,255,0.8)', marginBottom: '8px' }}>
//                               LinkedIn
//                             </Card.Title>
//                             <Card.Text style={{ fontSize: '16px', display: 'flex', alignItems: 'center', margin: '0' }}>
//                               <img src={LinkedIn_Icon} alt="LinkedIn Icon" style={{ width: '20px', height: '20px', marginRight: '20px' }} />
//                               {linkedIn}
//                             </Card.Text>
//                           </Card>
//                         </a>
//                       ) : (
//                         <Card style={{ backgroundColor: 'transparent', padding: '12px', borderRadius: '5px', margin: '0' }}>
//                           <Card.Title style={{ fontSize: '14px', fontWeight: '600', color: 'rgba(255,255,255,0.8)', marginBottom: '8px' }}>
//                             LinkedIn
//                           </Card.Title>
//                           <Card.Text style={{ fontSize: '16px', display: 'flex', alignItems: 'center', margin: '0' }}>
//                             <img src={LinkedIn_Icon} alt="LinkedIn Icon" style={{ width: '20px', height: '20px', marginRight: '20px' }} />
//                             {linkedIn}
//                           </Card.Text>
//                         </Card>
//                       )}
//                     </Col>
//                   </Row>
//                   {/* Only show fields with values that are not "Not provided" */}
//                   <div style={{ fontSize: '16px', display: 'flex', gap: '20px', marginTop: '42px' }}>
//                     <p>{companyName}</p>
//                     <p style={{ color: 'rgba(255,255,255,0.8)' }}>Overview</p>
//                   </div>

//                   {/* <Row style={{ marginTop: '10px', gap: '5px', display: 'flex', flexWrap: 'nowrap' }}>
//                     {industry !== 'Not provided' && (
//                       <Col xs={4} style={{ flex: 1 }}>
//                         <Card style={{ backgroundColor: 'transparent', padding: '12px', borderRadius: '5px' }}>
//                           <Card.Title style={{ fontSize: '16px', fontWeight: '600', color: 'rgba(255,255,255,0.8)' }}>Industry</Card.Title>
//                           <Card.Text style={{ fontSize: '16px' }}>{industry}</Card.Text>
//                         </Card>
//                       </Col>
//                     )}
//                     {founded !== 'Not provided' && (
//                       <Col xs={4} style={{ flex: 1 }}>
//                         <Card style={{ backgroundColor: 'transparent', padding: '12px', borderRadius: '5px' }}>
//                           <Card.Title style={{ fontSize: '16px', fontWeight: '600', color: 'rgba(255,255,255,0.8)' }}>Founded</Card.Title>
//                           <Card.Text style={{ fontSize: '16px' }}>{founded}</Card.Text>
//                         </Card>
//                       </Col>
//                     )}
//                     {teamSize !== 'Not provided' && (
//                       <Col xs={4} style={{ flex: 1 }}>
//                         <Card style={{ backgroundColor: 'transparent', padding: '12px', borderRadius: '5px' }}>
//                           <Card.Title style={{ fontSize: '16px', fontWeight: '600', color: 'rgba(255,255,255,0.8)' }}>Team Size</Card.Title>
//                           <Card.Text style={{ fontSize: '16px' }}>{teamSize}</Card.Text>
//                         </Card>
//                       </Col>
//                     )}
//                     {revenue !== 'Not provided' && (
//                       <Col xs={4} style={{ flex: 1 }}>
//                         <Card style={{ backgroundColor: 'transparent', padding: '12px', borderRadius: '5px' }}>
//                           <Card.Title style={{ fontSize: '16px', fontWeight: '600', color: 'rgba(255,255,255,0.8)' }}>Revenue (FY23)</Card.Title>
//                           <Card.Text style={{ fontSize: '16px' }}>{revenue}</Card.Text>
//                         </Card>
//                       </Col>
//                     )}
//                     {profit !== 'Not provided' && (
//                       <Col xs={4} style={{ flex: 1 }}>
//                         <Card style={{ backgroundColor: 'transparent', padding: '12px', borderRadius: '5px' }}>
//                           <Card.Title style={{ fontSize: '16px', fontWeight: '600', color: 'rgba(255,255,255,0.8)' }}>Profit (FY23)</Card.Title>
//                           <Card.Text style={{ fontSize: '16px' }}>{profit}</Card.Text>
//                         </Card>
//                       </Col>
//                     )}
//                     {fundingRaised !== 'Not provided' && (
//                       <Col xs={4} style={{ flex: 1 }}>
//                         <Card style={{ backgroundColor: 'transparent', padding: '12px', borderRadius: '5px' }}>
//                           <Card.Title style={{ fontSize: '16px', fontWeight: '600', color: 'rgba(255,255,255,0.8)' }}>Funding Raised</Card.Title>
//                           <Card.Text style={{ fontSize: '16px' }}>{fundingRaised}</Card.Text>
//                         </Card>
//                       </Col>
//                     )}
//                   </Row> */}
//                   {companyDescription}
//                   {companyDetails !== 'Not provided' && (
//                     <Row style={{ marginTop: '10px', gap: '24px', display: 'flex', flexWrap: 'wrap',marginLeft:'0px'}}>
//                       {Object.keys(companyDetails).map((key, index) => {
//                         // Remove leading "-" from the key if it exists
//                         const cleanedKey = key.startsWith('- ') ? key.substring(2) : key;
//                         const value = companyDetails[key];
//                         if (value !== 'N/A'&& value !== 'Not provided' && value.length <= 22) {
//                           return (
//                             <Col xs="auto" key={index} style={{ flex: '0 1 auto', margin: '0',padding:'0' }}>
//                               <Card
//                                 style={{
//                                   backgroundColor: 'transparent',
//                                   padding: '12px',
//                                   borderRadius: '5px',
//                                   display: 'inline-block',
//                                   width: 'auto', // Adjust width based on content
//                                   maxWidth: '100%' // Ensure it doesn't exceed container width
//                                 }}
//                               >
//                                 <Card.Title style={{ fontSize: '16px', fontWeight: '600', color: 'rgba(255,255,255,0.8)' }}>
//                                   {cleanedKey}
//                                 </Card.Title>
//                                 <Card.Text style={{ fontSize: '16px' }}>
//                                   {value}
//                                 </Card.Text>
//                               </Card>
//                             </Col>
//                           );
//                         }
//                         return null;
//                       })}
//                     </Row>
//                   )}
//                   {/* Buttons */}
//                   <Row style={{ marginTop: '20px' }}>
//                     <Col style={{ display: 'flex', gap: '20px', justifyContent: 'start' }}>
//                       {/* "Yes, that's me" button */}
//                       <Box
//                         sx={{
//                           display: 'flex',
//                           width: '147px',
//                           height: '36px',
//                           bgcolor: '#282D51',
//                           '&:hover': {
//                             bgcolor: '#373E6D',
//                           },
//                           '&.Mui-disabled': {
//                             color: 'rgba(255,255,255,0.4)', // Adjust the color for the disabled state
//                             bgcolor: '#282D51', // Adjust the background color for the disabled state
//                           },
//                           textAlign: 'center',
//                           justifyContent: 'center',
//                           alignItems: 'center',
//                           borderRadius: '8px',
//                           gap: '10px',
//                           cursor: numOfResp >= 3 ? 'not-allowed' : 'pointer',
//                         }}
//                         onClick={() => {
//                           if (numOfResp < 3) {
//                             handleYesClick(
//                               'Thank you for confirming the details. Let me set things up for you'
//                             );
//                           }
//                         }}
//                         disabled={numOfResp >= 3}
//                       >
//                         <DoneIcon sx={{ width: '20px', height: '20px' }} />
//                         <Box
//                           sx={{
//                             fontSize: '14px',
//                             fontWeight: 400,
//                             fontFamily: 'inter',
//                           }}
//                         >
//                           Yes, that's me
//                         </Box>
//                       </Box>

//                       {/* "No, edit details" button */}
//                       <Box
//                         sx={{
//                           display: 'flex',
//                           width: '147px',
//                           height: '36px',
//                           bgcolor: '#282D51',
//                           '&:hover': {
//                             bgcolor: '#373E6D',
//                           },
//                           '&.Mui-disabled': {
//                             color: 'rgba(255,255,255,0.4)', // Adjust the color for the disabled state
//                             bgcolor: '#282D51', // Adjust the background color for the disabled state
//                           },
//                           textAlign: 'center',
//                           justifyContent: 'center',
//                           alignItems: 'center',
//                           borderRadius: '8px',
//                           gap: '10px',
//                           cursor: numOfResp >= 3 ? 'not-allowed' : 'pointer',
//                         }}
//                         // onClick={handleEditClick}
//                         onClick={() => {!(numOfResp >= 3) && handleEditClick()}}
//                         disabled={numOfResp >= 3}
//                       >
//                         <EditIcon sx={{ width: '20px', height: '20px' }} />
//                         <Box
//                           sx={{
//                             fontSize: '14px',
//                             fontWeight: 400,
//                             fontFamily: 'inter',
//                           }}
//                         >
//                           No, edit details
//                         </Box>
//                       </Box>
//                     </Col>
//                   </Row>


//                   {/* Edit Modal */}
//                   {openEditModal && (
//                     <EditUserDetails
//                       open={openEditModal}
//                       onClose={() => setOpenEditModal(false)}
//                       firstName={name.split(" ")[0]}
//                       lastName={name.split(" ")[1]}
//                       companyName={companyName}
//                       role={role}
//                       linkedIn={linkedIn}
//                       // Pass other details as props if needed
//                     />
//                   )}
//                 </Row>
//               </Col>
//               {/* <Col xs={1} className="d-flex flex-column justify-content-start align-items-center p-0 m-0">
//                 <img src={theme === 'dark' ? black_logo : white_logo} alt="ModalX Icon" className="img-fluid" />
//               </Col> */}
//             </Row>
//           </Col>
//         </Row>
//       )}
//     </>
//   );
// };

// export default Default_UserInfo;








import React, { createContext, useContext, useState, useEffect } from "react";
import { auth } from "../config/firebase.config";

// User = {
//   id: string,
//   email: string,
//   firstName: string,
//   createdAt: Date,
//   theme: string,
//   picture: imageUrl,
// };

const AuthContext = createContext(null);

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [showResendModal, setShowResendModal] = useState(false);
  const [menuOpen, setMenuOpen]  = useState(false);
  const [toggleLogout, setToggleLogout] = useState(false)
  const [logoutAndShare , setLogoutAndShare] = useState(false)
  const [allDelete, setAllDelete] = useState(false)
  const [currentWindow, setCurrentWindow] = React.useState(1);

  async function getUser() {
    try {
      // const userResponse = await fetch("/profile", {
      //   method: "GET",
      //   credentials: "include",
      // });
      // const responseData = await userResponse.json();
      // const user = await responseData.result;
      const user = auth.currentUser.emailVerified ;
      setUser(user)
      console.log(user);
      if (!user) setUser(null);
      else {
        setUser(user);
      }
    } catch (err) {
      console.log("error getting userData");
      setUser(null);
    }
  }

  const logout = async () => {
    try {
      // Clear user state
      setUser(null);

      // Clear all cookies
      document.cookie.split(";").forEach((cookie) => {
        const cookieName = cookie.split("=")[0].trim();
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      });

      // Perform additional logout actions if needed
    } catch (error) {
      console.log("Error occurred during logout:", error);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <AuthContext.Provider value={{toggleLogout, setToggleLogout, user, setUser, logout,showLoginModal, setShowLoginModal,showSignUpModal, setShowSignUpModal,showVerificationModal,setShowVerificationModal,showResetPasswordModal,setShowResetPasswordModal,showResendModal, setShowResendModal ,logoutAndShare , setLogoutAndShare,allDelete, setAllDelete,menuOpen, setMenuOpen, currentWindow, setCurrentWindow }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

import React, { useState } from "react";
import { Modal, CloseButton } from "react-bootstrap";
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import { useWorkspace } from "../../../contexts/WorkspaceContext";
import {
  button_bg_color_for_New_Project,
  oneClickModal_backgroundColor_dm,
  settingsModal_bg_color_dm,
} from "../../../constants/colors";
import CloseIcon from "@mui/icons-material/Close";

const VideoTutorialModal = ({ Title, videoSrc }) => {
  const theme = localStorage.getItem("theme");
  const { setShowTutorialModal, showTutorialModal } = useWorkspace();
  const handleClose = () => setShowTutorialModal(false);

  return (
    <div>
      <Dialog  open={showTutorialModal}
       onClose={handleClose}
       maxWidth="lg"
       fullWidth
       PaperProps={{
         sx: {
           width: "750px",
           // height: "673px",
           gap: "0px",
           borderRadius: "8px",
           //  border: "1px solid #CAC4D0",
           opacity: "0px",
           bgcolor: "#282D51",
           margin: "",
         },
       }} >
                <div
                  style={{
                    backgroundColor:
                      theme === "dark" ? oneClickModal_backgroundColor_dm : "",
                    color: theme === "dark" ? "white" : "",
                  }}
                >

                 <Box
                            sx={{
                              width: "100%",
                              height: "68px",
                              padding: "20px",
                              gap: "10px",
                              borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
                              opacity: "0px",
                            }}
                          >
                            <Box
                              sx={{
                                height: "28px",
                                opacity: "0px",
                                justifyContent: "space-between",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  // width: "248px",
                                  height: "20px",
                                  gap: "0px",
                                  opacity: "0px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Inter",
                                    fontSize: "20px",
                                    fontWeight: 600,
                                    lineHeight: "20px",
                                    textAlign: "left",
                                    color: "#FFFFFF",
                                  }}
                                >
                                  {`How to ${Title} on ModalX`}
                                </Typography>
                              </Box>
                
                              <Box
                                sx={{
                                  width: "28px",
                                  height: "28px",
                                  margin: "0px",
                                  padding: "0px",
                                }}
                              >
                                <CloseIcon
                                  onClick={handleClose}
                                  aria-label="close"
                                  sx={{
                                    gap: "0px",
                                    opacity: "0px",
                                    color: "#FFFFFF",
                                    "&:hover": {
                                      cursor: "pointer",
                                    },
                                  }}
                                />
                              </Box>
                            </Box>
                  </Box>
                  
                    <DialogContent sx={{ margin: "auto", padding: "0px 20px 0px 20px", border: "" }}>
                    <video id="introVideo" width="100%" controls autoPlay muted>
                    <source src={videoSrc} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                    </DialogContent>

                   <Box
                                sx={{
                                  display: "flex",
                                  width: "100%",
                                  bgcolor: oneClickModal_backgroundColor_dm, // "#282D51",
                                  border: "",
                                  // boxShadow: "0px -4px 8px 0px rgba(0, 0, 0, 0.12)",
                                  boxShadow: " 0px -4px 8px 0px rgba(0, 0, 0, 0.12)",
                                  padding: "16px 20px",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Box
                                  sx={{
                                    // width: "230px",
                                    // height: "40px",
                                    right: "16px",
                                    top: "16px",
                                    gap: "16px",
                                    opacity: "0px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    border: "",
                                  }}
                                >
                                  <DialogActions sx={{padding:"0px", }}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        // border: "",
                                        gap: "16px",
                                        padding:"0px",
                                        
                                      }}
                                    >
                                      <Button
                                        onClick={handleClose}
                                        variant="contained"
                                        sx={{
                                          backgroundColor: button_bg_color_for_New_Project,
                                          height: "40px",
                                          padding: "10px 24px",
                                          borderRadius: "4px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          margin:"auto",
                                          "&:hover": {
                                            backgroundColor: "#FFD9AA",
                                          },
                                          color: "black",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            fontFamily: "Inter",
                                            fontSize: "16px",
                                            fontWeight: 600,
                                            lineHeight: "20px",
                                            letterSpacing: "0.1px",
                                            textAlign: "center",
                                            color: "#17182C",
                                            textTransform: "none",
                                          }}
                                        >
                                          Done
                                        </Typography>
                                      </Button>
                                    </Box>
                                  </DialogActions>
                                </Box>
                    </Box>

      {/* <Modal show={showTutorialModal} onHide={handleClose} centered size="lg">
        <Modal.Header
          style={{ backgroundColor: "dark" ? settingsModal_bg_color_dm : "" }}
        >
          <Modal.Title>{`How to ${Title} on ModalX`}</Modal.Title>
          <CloseButton onClick={handleClose} />
        </Modal.Header>
        <Modal.Body
          style={{
            textAlign: "center",
            backgroundColor: "dark" ? settingsModal_bg_color_dm : "",
          }}
        >
          <video id="introVideo" width="100%" controls autoPlay muted>
            <source src={videoSrc} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            backgroundColor: "dark" ? settingsModal_bg_color_dm : "",
          }}
        >
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{
              backgroundColor:
                theme === "dark" ? button_bg_color_for_New_Project : "",
              "&:hover": {
                backgroundColor: "#FFD9AA",
              },
              color: "#17182C",
              fontSize: "16px",
              fontWeight: "600",
              textTransform: "none",
              fontFamily: "Inter, sans-serif",
            }}
          >
            Done
          </Button>
        </Modal.Footer>
      </Modal> */}
      </div>
      </Dialog>
    </div>
  );
};

export default VideoTutorialModal;

import React from 'react'
import { Box, Button, CardMedia, Typography, Tooltip, Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import { useWorkspace } from '../../contexts/WorkspaceContext';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const AutoScrollButton = ({messagesEndRef, setIsAtBottom}) => {

    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      setIsAtBottom(true)
    };


  return (
    <>
    { 
     <button
              onClick={scrollToBottom}
              style={{
                width:"32px",
                height:"32px",
                display:"flex",
                justifyContent:"center",
                alignItems:"center",
                position: "fixed",
                bottom: "110px",
                left: "50%",
                transform: "translateX(-50%)",
                border:"1px solid rgba(255, 255, 255, 0.10)",
                backgroundColor: "#1F233E",
                borderRadius:"80px",
                color: "white",
                cursor: "pointer",
                zIndex:975,
                '&:hover':{
                  backgroundColor: "#282D51",
                  cursor: "pointer",
                }
              }}>
    
              <KeyboardArrowDownIcon color="#CAB4D0"/>
          </button>
    }
    </>
  )
}

export default AutoScrollButton
import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Button,
  FormControl,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
// import { fetchLanguages } from "./VideoGenAPISettings";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import FaceIcon from "@mui/icons-material/Face";
import Face3Icon from "@mui/icons-material/Face3";
import {
  DropdownForAccent,
  DropdownForAudio,
  DropdownForGender,
  DropdownForVoiceTone,
} from "../AudioSettings/DropDownButton";
import { AudioSettingsAPI, fetchLanguages, fetchVoices } from "./AudioSettingsAPI";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Pagination from "@mui/material/Pagination";
import maleIcon from "../../../../assets/Icon/male.png";
import femaleIcon from "../../../../assets/Icon/female.png";

const GenderArrayForFilter = [
  { id: 0, name: "All", gender: "All" },
  { id: 1, name: "Male", gender: "Male" },
  { id: 2, name: "Female", gender: "Female" },
];

const VoiceToneArrayForFilter = [{ id: 0, name: "All", description: "All" }];

const AccentArrayForFilter = [{ id: 0, name: "All", accent: "All" }];

const LanguageAndVoiceSettings = () => {
  const {
    Audiolanguages,
    selectedGenVideoVoice,
    genVideoVoicesLoading,
    audioSettingVoices,
    audioVoice,
    setAudioVoice,
    setAudioLanguages,
    setAudioSettingLanguagesLoading,
    setAudioSettingVoices,
    setAudioSettingVoicesLoading,
    audioSettingVoicesloading
  } = useWorkspace();
  const [playingVoice, setPlayingVoice] = useState(null);
  const [filteredLanguages, setFilteredLanguages] = useState(Audiolanguages);
  const [filteredVoices, setFilteredVoices] = useState(audioSettingVoices);
  const [genderOption, setGenderOption] = useState(GenderArrayForFilter);
  // const [audioElement, setAudioElement] = useState(null);
  const audioElement = useRef(null);
  const [accentOption, setAccentOption] = useState(null);
  const [voiceToneOption, setVoiceToneOption] = useState(null);
  const [currentPageForVoices, setCurrentPageForVoices] = useState(1);
  const [filterCriteria, setfilterCriteria] = useState({
    gender: "",
    description: "",
    accent: "",
  });
  const itemsPerPage = 20;
  const indexOfLastItem = currentPageForVoices * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredVoices?.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredVoices?.length / 20);

  useEffect(() => {
    if(Audiolanguages == null || audioVoice== null){
      setAudioSettingVoicesLoading(true)
      setAudioSettingLanguagesLoading(true)
      AudioSettingsAPI(setAudioLanguages,setAudioSettingVoices,setAudioVoice, audioVoice, setAudioSettingVoicesLoading,setAudioSettingLanguagesLoading  )
    }
}, [Audiolanguages,audioVoice]);

  useEffect(() => {
    function updateFilterArray(apiData, filterArray, key) {
      const existingValues = new Set(filterArray.map((item) => item[key]));
      let currentId =
        filterArray?.length > 0
          ? Math.max(...filterArray.map((item) => item.id)) + 1
          : 1;

      const newValues = [];
      apiData?.forEach((item) => {
        const value = item.labels?.[key]; // Extract value from nested object

        if (value && !existingValues.has(value)) {
          existingValues.add(value); // Add to the set to avoid duplicates
          newValues.push({
            id: currentId++, // Assign incrementing ID
            name: value,
            [key]: value,
          });
        }
      });

      return [...filterArray, ...newValues];
    }

    // const updatedGenderArray = updateFilterArray(
    //   audioSettingVoices,
    //   GenderArrayForFilter,
    //   "gender"
    // );
    // setGenderOption(updatedGenderArray);
    const updatedAccentArray = updateFilterArray(
      audioSettingVoices,
      AccentArrayForFilter,
      "accent"
    );
    setAccentOption(updatedAccentArray);

    const updatedQualityArray = updateFilterArray(
      audioSettingVoices,
      VoiceToneArrayForFilter,
      "description"
    );
    setVoiceToneOption(updatedQualityArray);

    // console.log("ccentOption", updatedAccentArray)
    // console.log("accentOption", updatedQualityArray)
    // console.log("accentOption", updatedGenderArray)
  }, [audioSettingVoices]);

  useEffect(() => {
    // console.log("filterCriteria audioSettingVoices", audioSettingVoices)
    // Function to get filtered and excluded data
    const filteredData = audioSettingVoices?.filter(
      (item) =>
        (!filterCriteria.gender ||
          item?.labels?.gender === filterCriteria.gender) &&
        (!filterCriteria.description ||
          item?.labels?.description === filterCriteria.description) &&
        (!filterCriteria.accent ||
          item?.labels?.accent === filterCriteria.accent)
    );

    // console.log("filterCriteria filteredData", filteredData)
    const excludedData = audioSettingVoices?.filter(
      (item) => !filteredData.includes(item)
    );
    // console.log("filterCriteria excludedData", excludedData)
    const updatedVoices = filteredData?.concat(excludedData);
    setFilteredVoices(updatedVoices);
  }, [filterCriteria]);

  useEffect(() => {
    // Function to get filtered and excluded data
    const filteredData = audioSettingVoices?.filter((item) => {
      return (
        (filterCriteria.gender === "All" ||
          !filterCriteria.gender ||
          item.labels.gender === filterCriteria.gender) &&
        (filterCriteria.accent === "All" ||
          !filterCriteria.accent ||
          item.labels.accent === filterCriteria.accent) &&
        (filterCriteria.description === "All" ||
          !filterCriteria.description ||
          item.labels.description === filterCriteria.description)
      );
    });

    const excludedData = audioSettingVoices?.filter(
      (item) => !filteredData.includes(item)
    );

    const updatedVoices = filteredData?.concat(excludedData);
    setFilteredVoices(updatedVoices);
  }, [filterCriteria, audioSettingVoices]);

  const handlefetchdata = async () => {
    await fetchLanguages(setAudioLanguages, setAudioSettingLanguagesLoading);
    await fetchVoices(
      setAudioSettingVoices,
      setAudioVoice,
      audioVoice,
      setAudioSettingVoicesLoading
    );
  };

  useEffect(() => {
    if (Audiolanguages == null || audioSettingVoices == null) {
      setAudioSettingLanguagesLoading(true);
      setAudioSettingVoicesLoading(true);
      handlefetchdata();
    }
  }, [setAudioSettingVoicesLoading]);

  const handleVoiceSelectClick = (voice) => {
    // console.log(voice, "voice========================");
    setAudioVoice(voice.voice_id);
  };

  const handleVoiceSelect = (voice) => {
    if (playingVoice === voice?.voice_id) {
      if (audioElement.current) {
        audioElement.current.pause();
        audioElement.current.currentTime = 0; // Reset audio to start
        audioElement.current = null;
      }
      setPlayingVoice(null);
    } else {
      if (audioElement.current) {
        audioElement.current.pause();
        audioElement.current.currentTime = 0; // Reset audio to start
        audioElement.current = null;
      }
      const newAudioElement = new Audio(voice?.previewURL);

      // When audio ends, reset the playingVoice state
      newAudioElement.onended = () => {
        setPlayingVoice(null);
      };

      newAudioElement
        .play()
        .catch((error) => console.error("Playback error:", error));

      setPlayingVoice(voice?.voice_id);
      audioElement.current = newAudioElement;
    }
  };

  const handleClearButtonClick = () => {
    setfilterCriteria({
      gender: "",
      description: "",
      accent: "",
    });
  };

  const paginationTheme = createTheme({
    typography: {
      fontFamily: "Inter",
    },
    components: {
      MuiPagination: {
        styleOverrides: {
          root: {
            "& .MuiPaginationItem-root": {
              color: "#ffffff",
              borderRadius: "20px",
              "&:hover": {
                backgroundColor: "rgba(179, 168, 183, 0.26)",
              },
              fontSize: "14px",
            },
            "& .MuiPaginationItem-page.Mui-selected": {
              backgroundColor: "#CAC4D0",
              color: "black",
              "&:hover": {
                backgroundColor: "#CAC4D0",
              },
            },
            "& .MuiPaginationItem-ellipsis": {
              color: "#ffffff",
            },
            "& .MuiPaginationItem-previousNext": {
              color: "#ffffff",
              borderRadius: "20px",
            },
          },
        },
      },
    },
  });

  const handlePageChange = (event, value) => {
    setCurrentPageForVoices(value); // Update the current page when a page is clicked
  };

  useEffect(() => {
    return () => {
      if (audioElement.current) {
        audioElement.current.pause();
        audioElement.current.currentTime = 0;
        audioElement.current.src = "";
        audioElement.current = null;
      }
      setPlayingVoice(null);
    };
  }, []);

  return (
    <Box sx={{ padding: "20px 20px 0px 0px" }}>
      {Audiolanguages == null || audioSettingVoices == null ? (
        <Box>Loading...</Box>
      ) : (
        <Box>
          <Typography
            variant="h6"
            gutterBottom
            style={{
              fontSize: "16px",
              fontWeight: "500",
              marginBottom: "8px",
              fontFamily: "Inter",
              fontStyle: "normal",
              lineHeight: "20px",
              color: "#ffffff",
            }}
          >
            Audio language
          </Typography>
          <Typography
            //  gutterBottom
            style={{
              fontSize: "14px",
              fontWeight: "400",
              marginBottom: "20px",
              fontFamily: "Inter",
              fontStyle: "normal",
              lineHeight: "20px",
              color: "rgba(255, 255, 255, 0.80)",
            }}
          >
            Select the language for your audio script
          </Typography>

          <Box sx={{ marginBottom: "40px" }}>
            <DropdownForAudio
              filteredLanguages={filteredLanguages}
              setFilteredLanguages={setFilteredLanguages}
            />
          </Box>
          <Typography
            style={{
              fontSize: "16px",
              fontWeight: 500,
              marginBottom: "8px",
              fontFamily: "Inter",
              fontStyle: "normal",
              lineHeight: "20px",
              color: "rgba(255, 255, 255, 1)",
            }}
          >
            AI Narrator
          </Typography>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "400",
              marginBottom: "20px",
              fontFamily: "Inter",
              fontStyle: "normal",
              lineHeight: "20px",
              color: "rgba(255, 255, 255, 0.80)",
            }}
          >
            Choose from the most advanced AI voices in various languages and
            accents
          </Typography>
          <FormControl
            variant="standard"
            sx={{
              display: "flex",
              width: "auto",
              flexDirection: "row",
              alignItems:"center",
              columnGap: "12px",
              marginBottom: "36px",
              "& .MuiInput-underline:before": {
                borderBottom: "none",
              },
              "& .MuiInput-underline:after": {
                borderBottom: "none",
              },
              "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                borderBottom: "none",
              },
            }}
          >
            <Box>
              <DropdownForGender
                genderOption={genderOption}
                setFilteredVoices={setFilteredVoices}
                setfilterCriteria={setfilterCriteria}
                filterCriteria={filterCriteria}
              />
            </Box>
            <Box>
              <DropdownForVoiceTone
                voiceToneOption={voiceToneOption}
                setFilteredVoices={setFilteredVoices}
                setfilterCriteria={setfilterCriteria}
                filterCriteria={filterCriteria}
              />
            </Box>
            <Box>
              <DropdownForAccent
                accentOption={accentOption}
                setFilteredVoices={setFilteredVoices}
                setfilterCriteria={setfilterCriteria}
                filterCriteria={filterCriteria}
              />
            </Box>
            <Box onClick={handleClearButtonClick}>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "20px",
                  color: "#FFFFFF",
                  cursor: "pointer",
                }}
              >
                {Object.values(filterCriteria).some((value) => value !== "") &&
                  "Clear filters"}
              </Typography>
            </Box>
          </FormControl>
          <Box
            sx={{
              width: "100%",
              maxHeight: "160px",
              overflowY: "scroll",
              display: "flex",
              flexDirection: "column",
              rowGap: "16px",
              alignItems: "center",
            }}
          >
            {audioSettingVoicesloading ? (
              <Box>Loading</Box>
            ) : (
              currentItems?.map((voiceOption) => {
                return (
                  <Box
                    key={voiceOption?.id}
                    onClick={() => handleVoiceSelectClick(voiceOption)}
                    sx={{
                      width: "100%",
                      border:
                        audioVoice == voiceOption.voice_id
                          ? "1px solid rgba(255, 255, 255, 0.30)"
                          : "1px solid rgba(255, 255, 255, 0.10)",
                      padding: "12px 16px",
                      borderRadius: "16px",
                      display: "flex",
                      justifyContent: "space-between",
                      cursor: "pointer",
                      backgroundColor:
                        audioVoice === voiceOption.voice_id
                          ? "#282D51"
                          : "transparent",
                      "&:hover": {
                        backgroundColor: "#282D51",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Tooltip
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: "#E6E0E9",
                              color: "#17182C",
                              fontFamily: "Inter",
                              fontSize: "12px",
                              fontWeight: 500,
                              padding: "4px 8px 4px 8px",
                              borderRadius: "4px",
                              textAlign: "center",
                            },
                          },
                        }}
                        title={
                          playingVoice === voiceOption.voice_id
                            ? `Stop voice`
                            : `Preview voice`
                        }
                      >
                        <IconButton
                          onClick={(e) => {
                            handleVoiceSelect(voiceOption);
                            e.stopPropagation();
                          }}
                          sx={{
                            color:
                              playingVoice === voiceOption.voice_id
                                ? "rgba(23, 26, 45, 1)"
                                : "rgba(23, 26, 45, 1)",
                            backgroundColor:
                              playingVoice === voiceOption.voice_id
                                ? "white"
                                : "white",
                            transition:
                              "background-color 0.3s ease, transform 0.2s ease",
                            "&:hover": {
                              backgroundColor: "white",
                              // transform: 'scale(1.1)', // Subtle zoom on hover
                            },
                            width: "30px",
                            height: "30px",
                            padding: "12px",
                            borderRadius: "50%",
                            // color: "rgba(23, 26, 45, 1)",
                            // backgroundColor: "white",
                          }}
                        >
                          {playingVoice === voiceOption.voice_id ? (
                            <PauseIcon sx={{}} />
                          ) : (
                            <PlayArrowIcon sx={{}} />
                          )}
                        </IconButton>
                      </Tooltip>
                      <Box
                        sx={{
                          border: "1px solid rgba(255, 255, 255, 0.10)",
                          height: "30px",
                          margin: "0px 12px",
                        }}
                      ></Box>
                      <IconButton
                        // onClick={(e) => {handleVoiceSelect(language);  e.stopPropagation() }}
                        sx={{ padding: "0px" }}
                      >
                        {voiceOption.labels.gender === "Male" ? (
                           <Box
                           component="img"
                           src={maleIcon}
                           alt="Male"
                           sx={{ width: 20, height: 20 }}
                         />
                        ) : (
                          <Box
                          component="img"
                          src={femaleIcon}
                          alt="Female"
                          sx={{ width: 20, height: 20 }}
                        />
                        )}
                      </IconButton>
                      <Typography
                        sx={{
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight:
                            audioVoice == voiceOption.voice_id ? 500 : 400,
                          lineHeight: "20px",
                          letterSpacing: "0.1px",
                          fontSize: "14px",
                          color: "#fff",
                          marginLeft: "8px",
                        }}
                      >
                        {voiceOption.name}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        columnGap: "12px",
                      }}
                    >
                      <Tooltip
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: "#E6E0E9",
                              color: "#17182C",
                              fontFamily: "Inter",
                              fontSize: "12px",
                              fontWeight: 500,
                              padding: "4px 8px 4px 8px",
                              borderRadius: "4px",
                              textAlign: "center",
                            },
                          },
                        }}
                        title={`Age: ${voiceOption.labels.age}`}
                      >
                        <Button
                          sx={{
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "140%",
                            letterSpacing: "0.1px",
                            color: "rgba(255, 255, 255, 1)",
                            textWrap: "nowrap",
                            backgroundColor: "#2F355F",
                            width: "auto",
                            padding: "8px",
                            textTransform: "none",
                            whiteSpace: "nowrap",
                            borderRadius: "8px",
                          }}
                        >
                          {voiceOption.labels.age}
                        </Button>
                      </Tooltip>
                      <Tooltip
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: "#E6E0E9",
                              color: "#17182C",
                              fontFamily: "Inter",
                              fontSize: "12px",
                              fontWeight: 500,
                              padding: "4px 8px 4px 8px",
                              borderRadius: "4px",
                              textAlign: "center",
                            },
                          },
                        }}
                        title={`Voice tone: ${voiceOption.labels.description}`}
                      >
                        <Button
                          sx={{
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "140%",
                            letterSpacing: "0.1px",
                            color: "rgba(255, 255, 255, 1)",
                            textWrap: "nowrap",
                            backgroundColor: "#2F355F",
                            width: "auto",
                            padding: "8px",
                            textTransform: "none",
                            whiteSpace: "nowrap",
                            borderRadius: "8px",
                          }}
                        >
                          {voiceOption?.labels?.description}
                        </Button>
                      </Tooltip>
                      <Tooltip
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: "#E6E0E9",
                              color: "#17182C",
                              fontFamily: "Inter",
                              fontSize: "12px",
                              fontWeight: 500,
                              padding: "4px 8px 4px 8px",
                              borderRadius: "4px",
                              textAlign: "center",
                              whiteSpace: "nowrap",
                            },
                          },
                        }}
                        title={`Accent: ${voiceOption?.labels?.accent}`}
                      >
                        <Button
                          sx={{
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "140%",
                            letterSpacing: "0.1px",
                            color: "rgba(255, 255, 255, 1)",
                            textWrap: "nowrap",
                            backgroundColor: "#2F355F",
                            width: "auto",
                            padding: "8px",
                            textTransform: "none",
                            borderRadius: "8px",
                          }}
                        >
                          {voiceOption?.labels?.accent}
                        </Button>
                      </Tooltip>
                    </Box>
                  </Box>
                );
              })
            )}
            <div className="d-flex justify-content-center mt-3">
              <ThemeProvider theme={paginationTheme}>
                <Pagination
                  count={totalPages}
                  page={currentPageForVoices}
                  siblingCount={1}
                  boundaryCount={1}
                  onChange={handlePageChange}
                  shape="rounded"
                  size="large"
                />
              </ThemeProvider>
            </div>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default LanguageAndVoiceSettings;

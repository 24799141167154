// import React, { useState, useEffect } from "react";
// import { Col, Button, Offcanvas, Row } from "react-bootstrap";
// import { useMediaQuery } from "react-responsive";
// import SidebarContent from "./SidebarContent"; // Import the SidebarContent component
// import { useWorkspace } from "../../../contexts/WorkspaceContext";
// import { SelectedChat_bg_color } from "../../../Provider/Color_Provider";
// import { hovered_chat_bg_color_dm, selected_chat_bg_color_dm } from "../../../constants/colors";
// import { Box } from "@mui/material";
// const Sidebar = () => {
//   const {Today,Yesterday,ThisWeek,ThisMonth,PrevMonth,setShowLeftsideBar,showLeftsideBar} = useWorkspace();
//   const [showSidebar, setShowSidebar] = useState(false);
//   const [userToggled, setUserToggled] = useState(false);
//   const isXsOrSm = useMediaQuery({ maxWidth: 1500 });
  

//   // useEffect(() => {
//   //   if (!userToggled) {
//   //     setShowSidebar(!isXsOrSm);
//   //   }
//   // }, [isXsOrSm, userToggled]);

//   useEffect(() => {
//     if(!showLeftsideBar){
//       setShowSidebar(false);
//       setUserToggled(true)
//     }
//   }, [showLeftsideBar])
  

//   useEffect(() => {
//     if((Today.length > 0 || Yesterday.length > 0 || ThisWeek.length > 0 || ThisMonth.length > 0 || PrevMonth.length > 0 ) && !userToggled){
//       setShowSidebar(true);
//       setShowLeftsideBar(true);
//     }
//   }, [Today,Yesterday,ThisWeek,ThisMonth,PrevMonth])

//   useEffect(() => {
//     if(isXsOrSm){
//       setShowSidebar(false);
//     }
//     else{
//       setShowSidebar(true)
//     }
//   }, [isXsOrSm])
  
  
//   const toggleSidebar = () => {
//     setShowSidebar(!showSidebar);
//     setShowLeftsideBar(!showSidebar)
//     setUserToggled((prev) => !prev);
//   };

//   const sidebarToggle = (
//     <Col
//       style={{
//         maxWidth: "1px",
//         minWidth: "1px",
//         backgroundColor:'#FFFFFF',
//         opacity:'10%'

//       }}
//       className="m-0 p-0 d-flex justify-content-start"
//     >
//       <Button
//         variant="Secondary"
//         className="p-0 m-0 border-0 bg-transparent"
//         onClick={toggleSidebar}
//       >
//         {showSidebar ? (
//           // <div
//           //   style={{
//           //     width: "25px",
//           //     minWidth: "25px",
//           //     maxWidth: "25px",
//           //     display: "flex",
//           //     alignItems: "center",
//           //     justifyContent: "center",
//           //   }}
//           // >
//           // <i className="bi bi-chevron-compact-left p-0 m-0 fs-4" />
//           <></>
//         ) : (
//           // </div>
//           // <div
//           //   style={{
//           //     width: "25px",
//           //     minWidth: "25px",
//           //     maxWidth: "25px",
//           //     display: "flex",
//           //     alignItems: "center",
//           //     justifyContent: "center",
//           //     backgroundColor:selected_chat_bg_color_dm,
//           //     color:'rgba(255,255,255,0.8)'
//           //   }}
//           // >
//           // <i className="bi bi-chevron-compact-right p-0 m-0 fs-4" />
//           // </div>
//           <Box 
//             sx={{
//               width:'25px',
//               display:'flex',
//               alignItems: "center",
//               justifyContent: "center",
//               backgroundColor:selected_chat_bg_color_dm,
//               color:'rgba(255,255,255,0.8)',
//               '&:hover': {
//                 bgcolor: hovered_chat_bg_color_dm,
//                 color:'#FFFFFF'
//               }
//             }}>
//             <i className="bi bi-chevron-compact-right p-0 m-0 fs-4" />
//           </Box>
//         )}
//       </Button>
//     </Col>
//   );

//   return (
//     <>
//       {isXsOrSm ? (
//         <Offcanvas
//           show={showSidebar}
//           onHide={toggleSidebar}
//           placement="start"
//           style={{
//             maxWidth: "285px",
//             minWidth: "285px",
//             overflowY: "auto",
//             display: "flex",
//             border: "none",
//             flexDirection: "column",
//           }}
//           className="p-0 m-0"
//         >
//           <Offcanvas.Body className="p-0 m-0">
//             <Row style={{ height: "100vh" }} className="p-0 m-0">
//               <>
//                 <SidebarContent />
//                 {showSidebar && sidebarToggle}
//               </>
//             </Row>
//           </Offcanvas.Body>
//         </Offcanvas>
//       ) : (
//         <>{showSidebar && <SidebarContent />}</>
//       )}
//       {sidebarToggle}
//     </>
//   );
// };

// export default Sidebar;


import React, { useState, useEffect } from "react";
import { Col, Offcanvas, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import SidebarContent from "./SidebarContent";
import { useWorkspace } from "../../../contexts/WorkspaceContext";
import { darkTheme_New_bg_color, hovered_chat_bg_color_dm, selected_chat_bg_color_dm } from "../../../constants/colors";
import { Box, Tooltip,  Button, Typography, Menu, MenuItem, ListItemIcon, ListItemText, Divider, Popover } from "@mui/material";
import { ButtonClick, CreateNewProjectConfirmation, ExpandCollapseSidebar, NavigationClick, trackButtonEvent } from "../../../config/analytics";
import ModalXLogo from '../../../assets/sidebarIcon/smallsidebarcompanylogo.png'
import CachedRoundedIcon from '@mui/icons-material/CachedRounded';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { HoveredChat_bg_color } from "../../../Provider/Color_Provider";
import sourceUrl from "../../../api/Source.api";
import { useNavigate } from "react-router-dom";
import { useCreditRecharge } from "../../../contexts/CreditRechageContext";
import deleteIcon from '../../../assets/DeleteIcons.png'
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { useAuth } from "../../../contexts/AuthContext";
import magicIcon from '../../../assets/mdi_magic.png';
import ServicesForDefaultWindow from "../message/ServicesForDefaultWindow";

const Sidebar = () => {
  const { Today, Yesterday, ThisWeek, ThisMonth, PrevMonth,setCurrentHistoryId,userInfo, setShowLeftsideBar,setThreadId,setThreadFiles, setHistoryFiles, showLeftsideBar, showSidebar, setShowSidebar,currentThread,setShowSuggested, setIswebTrackClicked, setCurrentWebsiteLinkedId, clearThread, setReloadForSummary, subscribe, setShowTutorialModal } = useWorkspace();
  // const [showSidebar, setShowSidebar] = useState(false);
  const [userToggled, setUserToggled] = useState(false);
  const isXsOrSm = useMediaQuery({ maxWidth: 1280 });
  const mobileView = useMediaQuery({maxWidth: 992});
  const theme = localStorage.getItem("theme");
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const userEmail = localStorage.getItem("userEmail");
  const [moreoption, setMoreOption] = useState(false);
  const [initials, setInitials] = useState(null);
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const { logout, toggleLogout, setToggleLogout, allDelete, setAllDelete } = useAuth();
    const [quickGenerateModalAnchor, setQuickGenerateModalAnchor] = useState(null);
    const isQuickGenerateModalOpen = Boolean(quickGenerateModalAnchor);

  useEffect(() => {
    if (!showLeftsideBar) {
      setShowSidebar(false);
      setUserToggled(true);
    }
  }, [showLeftsideBar]);

    // Function to update state on resize
    const handleResize = () => {
      setShowSidebar(window.innerWidth > 992);
    };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // useEffect(() => {
  //   if ((Today.length > 0 || Yesterday.length > 0 || ThisWeek.length > 0 || ThisMonth.length > 0 || PrevMonth.length > 0) && !userToggled && !mobileView) {
  //     setShowSidebar(true);
  //     setShowLeftsideBar(true);
  //   }
  // }, [Today, Yesterday, ThisWeek, ThisMonth, PrevMonth]);

  // useEffect(() => {
  //   if (isXsOrSm || mobileView) {
  //     setShowSidebar(false);
  //   } else {
  //     setShowSidebar(true);
  //   }
  // }, [isXsOrSm, mobileView]);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
    ExpandCollapseSidebar(showSidebar ? "hideSideBar" : "ShowSideBar" )
    setShowLeftsideBar(!showSidebar);
    setUserToggled((prev) => !prev);
    if (currentThread?.length === 0){
      setShowSuggested(true)
    }
  };

  const createThreadId = async () => {
    try {
      const response = await fetch(`${sourceUrl}/threads`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
      });

      const responseData = await response.json();

      if (responseData.status) {
        const newThreadId = responseData.data.match(/(.*)/)[1];
        setThreadId(newThreadId);
        localStorage.setItem('threadId', newThreadId); // Store the new threadId in localStorage
        return newThreadId;
      } else {
        throw new Error("Thread creation failed");
      }
    } catch (error) {
      console.error("Thread creation error:", error);
      return null;
    }
  }

    const handleNewChat = async () => {
      if (window.innerWidth < 1281) {
        setShowLeftsideBar(false)
      }
      ButtonClick("new_project_click", "new Project")
      setIswebTrackClicked(false);
      setCurrentWebsiteLinkedId(null);
      setShowSuggested(true)
      clearThread();
      setThreadFiles([]);
      setHistoryFiles([]);
      localStorage.removeItem("current_thread_id");
      localStorage.removeItem("current_history_id");
      const newThreadId = await createThreadId();
      setThreadId(newThreadId);
      localStorage.setItem('current_thread_id',newThreadId);
      navigate(`/workspace/${newThreadId}`)
      setReloadForSummary(true)
      CreateNewProjectConfirmation(newThreadId)
      trackButtonEvent("button_click_New_Project", {
        button_name: "New Project",
        button_position: "Top-left",
      });
    };

    const HandleTrackWebSiteClick = async () =>{
      navigate('/workspace/trackwithmodalx')
        setIswebTrackClicked(true);
        setCurrentHistoryId(null)
      setCurrentWebsiteLinkedId(null)
    }

    const handleToggle = () => {
      setToggleLogout(true)
    }

    const AlldeleteFunc = async () => {
      setAllDelete(true)
      // try {
      //   const response = await fetch(`${sourceUrl}/threads/deleteall`, {
      //     method: "DELETE",
      //     headers: {
      //       "Content-Type": "application/json",
      //       "Authorization": `Bearer ${token}`
      //     },
      //   });
  
      //   const responseData = await response.json();
  
      //   if (responseData.status) {
      //     handleNewChat();
      //   } else {
      //     alert("Delete all projects operation has been failed");
      //   }
      // } catch (error) {
      //   console.error("Thread creation error:", error);
      //   return null;
      // }
      // setAllDelete(false);
    }

        const getInitials = (firstName, lastName, userEmail) => {
          if (firstName || lastName) {
            const firstInitial = firstName ? firstName.charAt(0).toUpperCase() : "";
            const lastInitial = lastName ? lastName.charAt(0).toUpperCase() : "";
            return `${firstInitial}${lastInitial}`;
          }
      
          if (userEmail) {
            const namePart = userEmail.split("@")[0]; 
            return namePart.charAt(0).toUpperCase();
          }
          return "X"; // Default if no name or email is provided
        };
    
    
          useEffect(()=>{
            setInitials(getInitials(userInfo?.firstName, userInfo?.lastName , userEmail));
          },[userInfo])


  // console.log("showSidebar", showSidebar)

  const sidebarToggle = (
    <>
      <Col
        style={{
          maxWidth: "1px",
          minWidth: "1px",
          backgroundColor: "#FFFFFF",
          opacity: "10%",
        }}
        className="m-0 p-0 d-flex justify-content-start"
      />
      {
         !showSidebar && !mobileView &&
      <Box sx={{width:"72px", height:"100%", borderRight:"1px solid rgba(255, 255, 255, 0.10)", bgcolor:darkTheme_New_bg_color, margin:"auto", display:"flex", flexDirection:"column", justifyContent:"space-between", padding:"20px 12px"}}>
      
            <Box>
            <Box sx={{display:"flex", marginTop:"6px"}}>
              <img width={"48px"} height={"48px"}  src={ModalXLogo} alt={"logo"}/>
            </Box>
             <Tooltip placement="left" componentsProps={{
                                                                tooltip: {
                                                                    sx: {
                                                                        bgcolor: "#E6E0E9",
                                                                        color: "#17182C",
                                                                        fontFamily: "Inter",
                                                                        fontSize: "12px",
                                                                        fontWeight: 500,
                                                                        padding: "4px 8px 4px 8px",
                                                                        borderRadius: "4px",
                                                                        textAlign: "center",
                                                                    },
                                                                },
                                                            }}
                                                            title="Open sidebar">
            <Box  sx={{width:"40px", height:"40px", display:"flex", justifyContent:"center", alignItems:"center",cursor:"pointer", borderRadius:"50%", '&:hover':{backgroundColor:"#1F233E"}, margin:"30px auto 0px auto" }} onClick={()=>{setShowSidebar(true)}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g opacity="0.8">
            <path d="M1.81783 10.2857L12.2074 10.2857M12.2074 10.2857L8.05159 14.8571M12.2074 10.2857L8.05159 5.71429M16.3633 16V4.57143" stroke="#CAC4D0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            </svg>
            </Box>
            </Tooltip>

            <Tooltip  placement="left"  componentsProps={{
                                                                tooltip: {
                                                                    sx: {
                                                                        bgcolor: "#E6E0E9",
                                                                        color: "#17182C",
                                                                        fontFamily: "Inter",
                                                                        fontSize: "12px",
                                                                        fontWeight: 500,
                                                                        padding: "4px 8px 4px 8px",
                                                                        borderRadius: "4px",
                                                                        textAlign: "center",
                                                                    },
                                                                },
                                                            }}
                                                            title="New Project">
            <Box  onClick={handleNewChat} sx={{ display:"flex", justifyContent:"center", alignItems:"center" ,width:"48px", height:"48px", marginTop:"36px", border:"1px solid rgba(255, 255, 255, 0.12)", borderRadius:"50%", backgroundColor:"#171A2D",cursor:"pointer",  '&:hover':{backgroundColor:"#1F233E"}}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path d="M6 8H0V6H6V0H8V6H14V8H8V14H6V8Z" fill="#CAC4D0"/>
            </svg>
            </Box>
            </Tooltip>
            </Box>

            <Box>
            <Tooltip  placement="left"  componentsProps={{
                                                                tooltip: {
                                                                    sx: {
                                                                        bgcolor: "#E6E0E9",
                                                                        color: "#17182C",
                                                                        fontFamily: "Inter",
                                                                        fontSize: "12px",
                                                                        fontWeight: 500,
                                                                        padding: "4px 8px 4px 8px",
                                                                        borderRadius: "4px",
                                                                        textAlign: "center",
                                                                    },
                                                                },
                                                            }}
                                                            title="Quick Generate">
              <Box   onClick={(event) => setQuickGenerateModalAnchor(event.currentTarget)} sx={{display:"flex", width:"48px", height:"48px", marginBottom:"12px", border:"1px solid rgba(255, 255, 255, 0.12)", borderRadius:"8px", justifyContent:"center", alignItems:"center", cursor:"pointer", '&:hover':{backgroundColor:"#282D51"}}}>
              <img src={magicIcon} alt="" style={{ width: '24px', height: '24px' }} /> 
              </Box>
            </Tooltip>

            <Tooltip  placement="left"  componentsProps={{
                                                                tooltip: {
                                                                    sx: {
                                                                        bgcolor: "#E6E0E9",
                                                                        color: "#17182C",
                                                                        fontFamily: "Inter",
                                                                        fontSize: "12px",
                                                                        fontWeight: 500,
                                                                        padding: "4px 8px 4px 8px",
                                                                        borderRadius: "4px",
                                                                        textAlign: "center",
                                                                    },
                                                                },
                                                            }}
                                                            title="Track with ModalX">
              <Box  onClick={HandleTrackWebSiteClick} sx={{display:"flex", width:"48px", height:"48px", border:"1px solid rgba(255, 255, 255, 0.12)", borderRadius:"8px", justifyContent:"center", alignItems:"center", cursor:"pointer", '&:hover':{backgroundColor:"#282D51"}}}>
              <CachedRoundedIcon />
              </Box>
            </Tooltip>


        <Popover
        open={isQuickGenerateModalOpen}
        anchorEl={quickGenerateModalAnchor}
        onClose={() => {setShowTutorialModal(false);setQuickGenerateModalAnchor(null)}}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            backgroundColor: 'transparent', // Remove default white background
            boxShadow: 'none', // Remove default box-shadow if needed
          },
        }}
      >
        <Box
          sx={{
            borderRadius: '8px',
            border: '1px solid rgba(255, 255, 255, 0.10)',
            background: '#1F233E',
            boxShadow: '0px 2px 20px 0px rgba(0, 0, 0, 0.25)',
            display: 'inline-flex',
            minWidth: '112px',
            maxWidth: '280px',
            padding: '12px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '8px',
          }}
        >
          <ServicesForDefaultWindow quickGenerateModalAnchor={quickGenerateModalAnchor} setQuickGenerateModalAnchor={setQuickGenerateModalAnchor} isQuickGenerateModalOpen={isQuickGenerateModalOpen}/>
        </Box>
      </Popover>
              <Box>

            <Box sx={{display:"flex", marginTop:"24px"}}>
              <Button
                  sx={{
                    minWidth:"48px",
                    width:"48px",
                    height:"48px",
                    display:"flex",
                    flexDirection:"row",
                    alignItems:"center",
                    textTransform: "none",
                    borderRadius:"8px",
                    overflow: 'hidden',
                    padding:"0px",
                    gap: "10px",
                    color: theme === "dark" ? "#FFFFFF" : "black",
                    bgcolor: moreoption ? "#1F233E" : "",
                    "&:hover": { bgcolor: HoveredChat_bg_color },
                  }}
          onClick={(event)=> setMoreOption(event.currentTarget)}
        >
        <Box sx={{display:"flex"}}>
        <Box sx={{display:"flex", color:"#17182C",bgcolor: "#CEC6E2",width:"24px",height:"24px",borderRadius:"50%"}}>
        <Box sx={{ fontFamily:"Inter", fontStyle:"normal", fontSize:"14px", fontWeight:600, letterSpacing:"0.1px",textAlign: "center",width:"24px",height:"24px"}}>{initials}</Box>
        </Box>
        </Box>
        <Box sx={{marginLeft:"", margin:"0px", padding:"0px", position:"absolute", top:"40%", left:"40%"}}>
          {
            moreoption ?  <ArrowDropUpIcon sx={{color:"white"}} /> : <ArrowDropDownIcon sx={{color:"white"}}/> 
          }
        </Box>
              </Button>
            </Box>

              </Box>
            </Box>
      </Box>
      }






      {/* <Button
        variant="Secondary"
        className="p-0 m-0 border-0 bg-transparent"
        onClick={toggleSidebar}
        style={{display : mobileView ? 'none' : 'block'}}
      >
        {showSidebar ? (
          <></>
        ) : (
          <Box
            sx={{
              width: '25px',
              display: 'flex',
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: selected_chat_bg_color_dm,
              color: 'rgba(255,255,255,0.8)',
              border:"1px solid red",
              '&:hover': {
                bgcolor: hovered_chat_bg_color_dm,
                color: '#FFFFFF',
              },
            }}
          >
            <i className="bi bi-chevron-compact-right p-0 m-0 fs-4" />
          </Box>
        )}
      </Button> */}
      
    </>
  );

    return (
    <>
      {isXsOrSm ? (
        <Offcanvas
          show={showSidebar}
          onHide={toggleSidebar}
          placement="start"
          style={{
            maxWidth: "283px",
            // minWidth: "284px",
            overflowY: "auto",
            display: "flex",
            border: "none",
            flexDirection: "column",
          }}
          className="p-0 m-0"
        >
          <Offcanvas.Body className="p-0 m-0">
            <Row style={{ height: "100vh" }} className="p-0 m-0">
              <>
                <SidebarContent  quickGenerateModalAnchor={quickGenerateModalAnchor} setQuickGenerateModalAnchor={setQuickGenerateModalAnchor} isQuickGenerateModalOpen={isQuickGenerateModalOpen}/>
                {showSidebar && sidebarToggle}
              </>
            </Row>
          </Offcanvas.Body>
        </Offcanvas>
      ) : (
        <>{showSidebar && <SidebarContent quickGenerateModalAnchor={quickGenerateModalAnchor} setQuickGenerateModalAnchor={setQuickGenerateModalAnchor} isQuickGenerateModalOpen={isQuickGenerateModalOpen}/>}</>
      )}
      {sidebarToggle}
      <Box sx={{position:"relative"}}>
      <SmallModalForLogout  moreoption={moreoption} setMoreOption={setMoreOption} handleToggle={handleToggle} AlldeleteFunc={AlldeleteFunc} initials={initials}  subscribe={subscribe}/>
      </Box>
    </>
  );
};

export default Sidebar;


const SmallModalForLogout = ({moreoption, setMoreOption, handleToggle, AlldeleteFunc, initials, subscribe}) =>{

  const {userInfo} = useWorkspace();
  const { upgradePlan } = useCreditRecharge()

  const handleClose = () =>{
    setMoreOption(!moreoption)
  }

  return(
    <div>
      <Menu
       anchorEl={moreoption}
       open={Boolean(moreoption)}
       onClose={handleClose}
       PaperProps={{
         style: {
          position: "absolute",
          backgroundColor: '#1F233E',
          color: 'white',
          padding: '4px',
          borderRadius: '8px',
          border: '1px solid rgba(255, 255, 255, 0.2)',
          marginLeft:"5px"
         },
       }}
       anchorOrigin={{
        vertical: 'top',       // Open from the top of the button
        horizontal: 'left',  // Align horizontally to the center of the button
      }}
      transformOrigin={{
        vertical: 'bottom',    // The menu will "pop" up from the bottom of its anchor point
        horizontal: 'center',  // Align horizontally to the center of the menu
      }}
      >
        <Box 
        sx={{
          display:"flex",
          alignItems:"center",
          flexDirection:"row",
          rowGap:"12px",
          borderRadius:"4px",
          padding:"6px 8px 6px 8px",
        }}>
        <Box sx={{display:"flex", justifyContent:"center", alignItems:"center",width:"30px",height:"30px",color:"#17182C",bgcolor:"#CEC6E2",borderRadius:"50%" }}>
        <Box sx={{ fontFamily:"Inter", fontStyle:"normal", fontSize:"14px", fontWeight:600,lineHeight:"20px", letterSpacing:"0.1px" }}>{initials}</Box>
        </Box>
        <Box>
        <Typography sx={{marginLeft:"12px", fontFamily:"Inter", fontSize:"14px", fontWeight:500 ,fontStyle:"normal", lineHeight:"20px",letterSpacing:"0.1px" ,marginBottom:"2px"}}>{userInfo?.firstName && userInfo?.lastName ? `${userInfo?.firstName} ${userInfo?.lastName}` : `${userInfo?.email.split('@')[0]}`}</Typography>
        {
          subscribe ?       
          <Box>
          <Typography sx={{fontFamily:"Inter", fontStyle:"normal", fontSize:"14px", fontWeight:400, color:"#F6BE6B", marginLeft:"12px", lineHeight:"20px", letterSpacing:"0.1px"}}>ModalX Pro</Typography>
          </Box>
          :
        <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between" ,alignItems:"center",marginLeft:"12px",fontFamily:"Inter", fontStyle:"normal", fontSize:"14px"}}>
        <Typography sx={{fontWeight:400, color:"rgba(255, 255, 255, 0.60)", fontFamily: "Inter", fontStyle: "normal",fontSize: "14px"}}>Free plan</Typography>
        <Typography sx={{ fontWeight:600, color:"#F6BE6B", marginLeft:"10px", cursor:"pointer", fontFamily: "Inter", fontStyle: "normal",fontSize: "14px"}} onClick={()=>{NavigationClick("Upgrade Plan"); upgradePlan()}}>Upgrade</Typography>
        </Box>
        }
        </Box>
        </Box>

        <Divider sx={{borderColor:"#ffffff"}}/>

        <MenuItem onClick={AlldeleteFunc}
          sx={{
            padding:"16px 12px",
            borderRadius:"4px",
            marginTop:"4px",
            '&:hover': {
              backgroundColor: '#2F355F', 
            },
          }}>
        <ListItemIcon >
        <img alt="del" src={deleteIcon} style={{height:"20px", width:"20px"}}/>
        </ListItemIcon>
        <ListItemText primaryTypographyProps={{
      sx: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontSize: "14px",
      },
    }} primary="Delete all projects" />
        </MenuItem>

        <MenuItem  onClick={handleToggle}
          sx={{
            padding:"16px 12px",
            borderRadius:"4px",
            '&:hover': {
              backgroundColor: '#2F355F', // Change the background color on hover
            },
          }}>
        <ListItemIcon>
        <LogoutOutlinedIcon sx={{color:"#ffffff"}} />
        </ListItemIcon>
        <ListItemText sx={{width:"174px"}} primaryTypographyProps={{
      sx: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontSize: "14px",
      },
    }} primary="Log out" />
        </MenuItem>
      </Menu>
    </div>
  )
}



const SendIcon = ({ style }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="40px"
    viewBox="0 -960 960 960"
    width="40px"
    // fill="#ECB576"
    style={{ ...style, padding: "8px", borderRadius: "50%" }}
  >
    <path d="M120-160v-640l760 320-760 320Zm80-120 474-200-474-200v140l240 60-240 60v140Zm0 0v-400 400Z" />
  </svg>
);

export default SendIcon;

// import React from 'react'
// import Image_1 from "../assets/Aboutus/Image_1.png";
// import PageLayout from '../components/navFoot/PageLayout';
// import RequestDemoNearFooter from '../components/LandingPage/RequestDemoNearFooter';

// const Aboutus = () => {
//   return (
//     <PageLayout>
//         <div style={{marginTop:'156px'}}>
//             <div style={{maxWidth:'1072px',height:'188px',display:'flex',flexDirection:'column',justifyContent:'space-between',textAlign:'center',margin:'auto'}}>
//                 <p style={{ textAlign: 'center', color: '#F6BE6B' }}>About Us</p>
//                     <p style={{ fontSize: '48px'}}>
//                         World’s best AI Productivity Tool backed by Multimodal Generative AI
//                     </p>
//             </div>
//             <div style={{maxWidth:'900px',height:'60px',margin:'80px auto'}}>
//                 <p style={{fontSize:'20px',color:'rgba(255,255,255,0.8)'}}>ModalX provides adaptability for your generative AI requirements by merging our array of cutting-edge open models with self-hosting advantages.</p>
//             </div>
//             <div style={{maxWidth:'900px',height:'60px',margin:'80px auto'}}>
//                 <p style={{fontSize:'20px',color:'rgba(255,255,255,0.8)'}}>We're not just AI; we're your AI running mate. ModalX is a multimodal platform that understands your business, predicts your challenges, and delivers tailored AI solutions. From strategy to execution, we've got you covered.</p>
//             </div>
//             <div style={{maxWidth:'1072px',maxHeight:'605px',margin:'80px auto'}}>
//                 <img src={Image_1}/>
//             </div>
//             <div>
//                 <p style={{fontSize:'48px',textAlign:'center'}}>About Our Team</p>
//                 <div style={{margin:'80px auto',maxWidth:'380px',height:'128px',display:'flex',justifyContent:'space-between',color:'rgba(255,255,255,0.9)'}}>
//                     <p style={{fontSize:'24px'}}>Founders</p>
//                     <div>
//                         <p>Jeremy Barber</p>
//                         <p>Hanif Joshaghani</p>
//                         <p>Peh Keong Teh</p>
//                         <p>Vikram Shekhawat</p>
//                     </div>
//                 </div>
//             </div>
//             <div>
//                 <RequestDemoNearFooter />
//             </div>
//         </div>
//     </PageLayout>
//   )
// }

// export default Aboutus

import React, { useState } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import Image_1 from "../assets/Aboutus/Image_1.png";
import PageLayout from "../components/navFoot/PageLayout";
import RequestDemoNearFooter from "../components/LandingPage/RequestDemoNearFooter";
import User1 from "../assets/LandingPage_Items/founder/image3.png";
import User2 from "../assets/LandingPage_Items/founder/image2.png";
import User3 from "../assets/LandingPage_Items/founder/image1.png";
import User4 from "../assets/LandingPage_Items/founder/image4.png";

const features = [
  {
    id: "1",
    image: User1,
    name:"Vikram Shekhawat",
    linkdin:"https://www.linkedin.com/in/vikramshekhawat/"
  },
  {
    id: "2",
    image: User2,
    name:"Peh Keong Teh",
    linkdin:"https://www.linkedin.com/in/peh-keong-teh-7899331b/"
  },
  {
    id: "3",
    image: User3,
    name:"Hanif Joshaghani",
    linkdin:"https://www.linkedin.com/in/hanif-joshaghani/"
  },
  {
    id: "4",
    image: User4,
    name:"Jeremy Barber",
    linkdin:"https://www.linkedin.com/in/jeremy-barber/"
  },
];

const Aboutus = () => {
  const isBelow991 = useMediaQuery("(max-width:991px)");
  const isBelow1200 = useMediaQuery("(max-width:1200px)");
  const [currentIndex, setCurrentIndex] = useState(0);
  const visibleBoxes = 3; // Number of boxes visible at once
  const boxWidth = 386;

  const handleLinkedinClick = (feature) => {
    if (feature?.linkdin) {
      window.open(feature.linkdin, "_blank");
    } else {
      console.error("LinkedIn link is missing");
    }
  };
  
  return (
    <PageLayout>
      <Box sx={{ marginTop: isBelow991 ? "80px" : "156px" }}>
        <Box
          sx={{
            maxWidth: "1072px",
            height: "188px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            textAlign: "center",
            margin: "auto",
            width: isBelow991 ? "90%" : "100%",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              color: "#F6BE6B",
              fontSize: isBelow991 ? "12px" : "16px",
            }}
          >
            About Us
          </Typography>
          <Typography sx={{ fontSize: isBelow991 ? "28px" : "48px" }}>
            World’s best AI Productivity Tool backed by Multimodal Generative AI
          </Typography>
        </Box>
        <Box
          sx={{
            maxWidth: "900px",
            height: "60px",
            margin: isBelow991 ? "60px auto" : "80px auto",
            width: isBelow991 ? "80%" : "100%",
          }}
        >
          <Typography
            sx={{
              fontSize: isBelow991 ? "16px" : "20px",
              color: "rgba(255,255,255,0.8)",
            }}
          >
            ModalX provides adaptability for your generative AI requirements by
            merging our array of cutting-edge open models with self-hosting
            advantages.
          </Typography>
        </Box>
        <Box
          sx={{
            maxWidth: "900px",
            height: "60px",
            margin: "80px auto",
            width: isBelow991 ? "80%" : "100%",
          }}
        >
          <Typography
            sx={{
              fontSize: isBelow991 ? "16px" : "20px",
              color: "rgba(255,255,255,0.8)",
            }}
          >
            We're not just AI; we're your AI running mate. ModalX is a
            multimodal platform that understands your business, predicts your
            challenges, and delivers tailored AI solutions. From strategy to
            execution, we've got you covered.
          </Typography>
        </Box>
        <Box
          sx={{
            maxWidth: "1072px",
            maxHeight: "605px",
            margin: isBelow991 ? "60px auto" : "80px auto",
            width: isBelow991 ? "80%" : "100%",
            paddingTop: "60px",
          }}
        >
          <img
            src={Image_1}
            alt="About Us"
            style={{ width: "100%", height: "auto" }}
          />
        </Box>
        <Box>
          <Typography
            sx={{ fontSize: isBelow991 ? "28px" : "48px", textAlign: "center" }}
          >
            About Our Team
          </Typography>
          <Box
            sx={{
              margin: isBelow991 ? "60px auto 120px auto" : "80px auto",
              maxWidth: "1140px",
              // height: "316px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              color: "rgba(255,255,255,0.9)",
              width: isBelow991 ? "80%" : "100%",
            }}
          >
            <Typography
              sx={{
                fontSize: isBelow991 ? "20px" : "24px",
                fontFamily: "Inter",
                fontWeight: 600,
                fontStyle: "normal",
                lineHeight: "36px",
                color: "rgba(255, 255, 255, 0.90)",
              }}
            >
              Founders
            </Typography>
            {/* <Box sx={{display:"flex", flexDirection:"column", fontSize:"16px", fontFamily:"Inter",fontWeight:600,fontStyle:"normal",  lineHeight:"32px", color:"rgba(255, 255, 255, 0.90)"}}>
              <Typography sx={{ fontSize:"16px", fontFamily:"Inter",fontWeight:600,fontStyle:"normal",  lineHeight:"32px", color:"rgba(255, 255, 255, 0.90)"}}>Jeremy Barber</Typography>
              <Typography sx={{ fontSize:"16px", fontFamily:"Inter",fontWeight:600,fontStyle:"normal",  lineHeight:"32px", color:"rgba(255, 255, 255, 0.90)"}}>Hanif Joshaghani</Typography>
              <Typography sx={{ fontSize:"16px", fontFamily:"Inter",fontWeight:600,fontStyle:"normal",  lineHeight:"32px", color:"rgba(255, 255, 255, 0.90)"}}>Peh Keong Teh</Typography>
              <Typography sx={{ fontSize:"16px", fontFamily:"Inter",fontWeight:600,fontStyle:"normal",  lineHeight:"32px", color:"rgba(255, 255, 255, 0.90)"}}>Vikram Shekhawat</Typography>
            </Box> */}
            <Box>
              <Box
                display="flex"
                flexDirection={isBelow991 ? "column" : "row"}
                alignItems="center"
                justifyContent="space-between"
                rowGap={isBelow991 && "40px"}
                mt={4}
                sx={{
                  maxWidth: "1140px",
                  width: isBelow1200 ? "100%" : "1140px",
                  marginTop: isBelow991 ? "40px" : "40px",
                }}
              >
                {features.map((feature) => (
                  <Box
                    key={feature.id}
                    sx={{
                      width: "240px",
                      height: "240px",
                      borderRadius: "36px",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      bgcolor: "#1F233E",
                      "&:hover": {
                        backgroundColor: "#282D51CC",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        position:"relative",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "240px",
                        height: "240px",
                        justifyContent: "space-evenly", // Ensures equal spacing between children
                        // height: '100%', // Adjust to the desired height
                        textAlign: "center",
                      }}>
                      <img
                        alt={""}
                        src={feature?.image}
                        style={{ width: "240px", height: "240px" }}
                      />
                      <Box sx={{display:"flex", position:"absolute", flexDirection:"row", justifyContent:"space-between", width:"200px", top:"200px", alignItems:"center"}}>
                        <Box>{feature?.name}</Box>
                        <Box sx={{ cursor: "pointer"}} onClick={()=>{handleLinkedinClick(feature)}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M19 3C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19ZM18.5 18.5V13.2C18.5 12.3354 18.1565 11.5062 17.5452 10.8948C16.9338 10.2835 16.1046 9.94 15.24 9.94C14.39 9.94 13.4 10.46 12.92 11.24V10.13H10.13V18.5H12.92V13.57C12.92 12.8 13.54 12.17 14.31 12.17C14.6813 12.17 15.0374 12.3175 15.2999 12.5801C15.5625 12.8426 15.71 13.1987 15.71 13.57V18.5H18.5ZM6.88 8.56C7.32556 8.56 7.75288 8.383 8.06794 8.06794C8.383 7.75288 8.56 7.32556 8.56 6.88C8.56 5.95 7.81 5.19 6.88 5.19C6.43178 5.19 6.00193 5.36805 5.68499 5.68499C5.36805 6.00193 5.19 6.43178 5.19 6.88C5.19 7.81 5.95 8.56 6.88 8.56ZM8.27 18.5V10.13H5.5V18.5H8.27Z" fill="white"/>
                        </svg>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>

        <Box>
          <RequestDemoNearFooter />
        </Box>
      </Box>
    </PageLayout>
  );
};

export default Aboutus;
